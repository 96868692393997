import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import RaisedButton from 'material-ui/RaisedButton';
import { runConfirmedAction, closeConfirmationDialog } from '@actions/confirmation-actions';
import { dialogStyles } from '@constants/mui-theme';

class ActionConfirmationDialog extends Component {
  handleCancel = () => this.props.closeConfirmationDialog();

  handleSubmit = () => {
    const { action } = this.props;
    if (action) {
      this.props.runConfirmedAction(action);
    }
    this.props.closeConfirmationDialog();
  };

  getActions = () => {
    const { confirmationType } = this.props.params ? this.props.params : {};
    return [
      <FlatButton
        key="no"
        label={confirmationType === 'discard' ? 'CANCEL' : 'NO'}
        onClick={this.handleCancel}
      />,
      <RaisedButton
        key="yes"
        label={confirmationType === 'discard' ? 'DISCARD' : 'YES'}
        onClick={this.handleSubmit}
        labelColor={dialogStyles.buttons.attention.color}
        backgroundColor={dialogStyles.buttons.attention.backgroundColor}
        style={dialogStyles.buttons.attention}
      />
    ];
  };

  getOk = () => ([
    <FlatButton
      key="ok"
      label="OK"
      onClick={this.handleCancel}
    />
  ]);

  render() {
    const { visible, title, action, question } = this.props;
    return (
      <Dialog
        actions={action ? this.getActions() : this.getOk()}
        contentStyle={dialogStyles.mediumDialog}
        style={{zIndex: 5000}}
        open={visible}
        title={title}
      >
        { question }
      </Dialog>
    );
  }
}

ActionConfirmationDialog.propTypes = {
  action: PropTypes.object,
  closeConfirmationDialog: PropTypes.func,
  confirmationType: PropTypes.string,
  params: PropTypes.object,
  question: PropTypes.string,
  runConfirmedAction: PropTypes.func,
  title: PropTypes.string,
  visible: PropTypes.bool
};

const mapStateToProps = state => {
  const { visible, action, title, question, params } = state.confirmation;
  return { visible, action, title, question, params };
};

export default connect(mapStateToProps, { closeConfirmationDialog, runConfirmedAction })(ActionConfirmationDialog);
