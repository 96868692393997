import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dialog, SelectField, MenuItem, RaisedButton } from 'material-ui';
import { setAgency } from '@actions/auth-actions';
import { dialogStyles, styleGuide } from '@constants/mui-theme';
import { filterAgencies, isAppAdmin } from '@utils/permission-utils';

class AgencySelect extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      agency: null
    };
  }
  handleSubmit() {
    this.props.setAgency(this.state.agency);
  }
  handleChange(event, index, value) {
    this.setState({agency: value});
  }
  render() {
    const { agency, agencyId, agencyType } = this.props;
    if (!agency || !agencyType) {
      return null;
    }
    const actions = [
      <RaisedButton
        key={'submit-agency'}
        label="GET STARTED"
        primary
        disabled={!this.state.agency}
        onClick={this.handleSubmit}
      />
    ];
    return (
      <Dialog
        actions={actions}
        contentStyle={dialogStyles.smallDialog}
        open={!agencyId && !isAppAdmin()}
        title="Welcome to Coordinate!"
      >
        Please select your agency to get started.
        <SelectField
          floatingLabelText="Agency name"
          value={this.state.agency}
          onChange={this.handleChange}
          {...styleGuide.select}
        >
          {filterAgencies(agency).map(agencyItem => (
            <MenuItem key={agencyItem.id} value={agencyItem.id} primaryText={agencyItem.name} />
          ))}
        </SelectField>
      </Dialog>
    );
  }
}

AgencySelect.propTypes = {
  agency: PropTypes.object,
  agencyId: PropTypes.number,
  agencyType: PropTypes.object,
  setAgency: PropTypes.func
};

const mapStateToProps = state => {
  const { agency, agency_type: agencyType } = state.dataTypes;
  const { agencyId } = state.auth.user;
  return { agency, agencyType, agencyId };
};

export default connect(mapStateToProps, { setAgency })(AgencySelect);
