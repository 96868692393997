/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/display-name */
/* eslint-disable max-depth */
import React from 'react';
import * as R from 'ramda';
import { includes, get } from 'lodash';
import debounce from 'lodash.debounce';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import Divider from 'material-ui/Divider';
import { List, ListItem } from 'material-ui/List';
import MenuItem from 'material-ui/MenuItem';
import Paper from 'material-ui/Paper';
import DataDetail from '@components/data-detail';
import OverlapListItem from '@components/data-detail/list-items/overlap-list-item';
import MoreButton from '@components/data-detail/list-items/overlap-list-item/more-button';
import MainEntityListItem from '@components/data-detail/list-items/main-entity-list-item';
import MapLegend from '@components/entity/info/map/map-legend';
import ViewMap from '@components/entity/info/map/view-map';
import FloatingBar from '@components/map/floating-bar';
import MapTray from '@components/map/tray';
import { getEntityType } from '@constants/config';
import { detailStyles } from '@constants/mui-theme';
import FilePreview from '@shared/files/file-preview';
import FilterDropDown from '@shared/filter-dropdown';
import OverlapRevokeDialog from '@shared/dialogs/overlap-revoke-dialog';
import OverlapStatusDialog from '@shared/dialogs/overlap-status-dialog';
import {
  getEntityStatus,
  FRONTEND_CONFLICT_STATUS
} from '@utils/data-detail/conflicts';
import './data-detail.scss';

const CONFLICT_FILTERS = {
  OPEN: 'open',
  OPPORTUNITIES: 'opportunities',
  PENDING: 'pending',
  RESOLVED: 'resolved'
};

const DEFAULT_FILTERS = {
  [CONFLICT_FILTERS.OPEN]: true,
  [CONFLICT_FILTERS.OPPORTUNITIES]: true,
  [CONFLICT_FILTERS.PENDING]: true,
  [CONFLICT_FILTERS.RESOLVED]: false
};

class ListDetail extends DataDetail {
  constructor(props) {
    super(props);
    this.state = {
      filter: {...DEFAULT_FILTERS},
      highlightGeometry: true,
      scrolled: false,  // When the page is loaded with a focused entity, we must scroll into
                        // that entity on the list, something that also happens if we update the component,
                        // so this flag avoids that, so we don't scroll into view, if we already scrolled at least once.
      sticky: false  // Sets the sticky flag so the overlap dropdown item in the list becomes sticky while scrolling.
    };
    this.updateViewport = debounce(this.updateViewport.bind(this), 600);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    // If the tray was closed, clear it on the state too:
    if (
      this.props.trays && nextProps.trays &&
      this.props.trays.length !== nextProps.trays.length &&
      nextProps.trays.length === 0
    ) {
      this.clearTray();
    }
    super.UNSAFE_componentWillReceiveProps(nextProps);
  }

  componentDidUpdate = () => {
    const { dialogOpen, location } = this.props;
    // Check if the 'focus' entity id exists in the current
    // overlap list, if it isn't, we should show the warning
    // dialog.
    if (!dialogOpen) {
      const id = this.getUrlParamEntityId('focus');
      if (id) {
        const entities = this.getAllEntities();
        if (entities) {
          const entity = entities.find(proj => proj.id === id);
          if (!entity) {
            const searchParams = new URLSearchParams(location.search);
            const paramType = searchParams.get('type');
            if (paramType) {
              const message = `This ${paramType} is no longer part of the overlap.`;
              this.props.openDialog(paramType, location.pathname, message);
            }
          }
        }
      }
    }
    const selectedEntity = this.getSelectedEntity();
    if (selectedEntity) {
      const id = this.getItemId(selectedEntity);
      const element = document.getElementById(id);
      if (element) {
        if (!this.state.scrolled) {
          scrollIntoView(element, { scrollMode: 'if-needed' });
        }
      }
    }
  };

  getItemId = entity => `overlap-list-item-${entity.id}-${this.isSelected(entity.id) ? 'selected' : ''}`;

  // All ListDetail based component displays a list of entities, with a main entity.
  // Thus, this is the name of that entity within the data.
  getMainEntityTypeName = () => null;  // Implement in subclass

  getMainEntity = () => R.path(['data', this.getMainEntityTypeName()], this.props);

  getOverlappingEntities = () => {
    const { data } = this.props;
    if (data && data.conflicts && data.opportunities) {
      return [].concat(data.conflicts, data.opportunities);
    }
    return [];
  };

  getAllEntities = () => {
    const mainEntity = this.getMainEntity();
    const overlapping = this.getOverlappingEntities();
    if (mainEntity && overlapping) {
      return [mainEntity, ...overlapping];
    }
    return null;
  };

  getEntities = () => {
    const { data } = this.props;
    if (data && this.getMainEntity() && data.conflicts && data.opportunities) {
      return [].concat(this.getMainEntity(), this.filterEntities([].concat(data.conflicts, data.opportunities)));
    }
    return null;
  };

  // Return only the entities shown on the current map boundaries:
  getBoundsEntities = () => {
    if (window.mapInstance) {
      const entities = this.getEntities();
      if (entities) {
        const mapBounds = window.mapInstance.getBounds();
        if (mapBounds) {
          return entities.filter(({ bounds }) => mapBounds.intersects(bounds));
        }
      }
    }
    return null;
  };

  getUrlParamEntityId = key => {
    const { location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const value = searchParams.get(key);
    if (value) {
      return parseInt(value, 10);
    }
    return null;
  };

  // Return the entity specified by the id in the URL params.
  // (i.e. ?focus=1234 or ?open=1234).
  getUrlParamEntity = key => {
    const id = this.getUrlParamEntityId(key);
    if (id) {
      const entities = this.getAllEntities();
      if (entities) {
        return entities.find(proj => proj.id === id);
      }
    }
    return null;
  };

  // Return selected entity (or the main one if none is selected):
  getSelectedEntity = () => this.getUrlParamEntity('focus') || this.getMainEntity();

  getSelectedSegmentIds = () => R.pipe(R.propOr([], 'segments'), R.pluck('id'))(this.getSelectedEntity());

  getSegments = () => {
    const entities = this.getEntities();
    if (entities) {
      const mainEntity = this.getMainEntity();
      // Entities may have more than one segment, we must display them all:
      return [].concat(...entities.map((entity, entityIndex) => entity.segments.map((segment, index) => ({
        ...segment,
        // Inject the agency type, so we can display the icon.
        agency_type: entity.agency_type,
        // Inject the entity for the tray.
        entity,
        // Only show the marker and tray for the first segment for each entity:
        showMarker: index === 0,
        mainEntitySegments: mainEntity.segments,
        overlapType: {
          isLead: entityIndex === 0,
          isOpen: this.conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolve),
          isOpportunity: entity.overlap_type === 'Opportunity',
          isPending: this.conflictFilter(entity, FRONTEND_CONFLICT_STATUS.pending),
          isResolved: this.conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolved)
        }
      }))));
    }
    return null;
  };

  isSelected = id => {
    const entity = this.getSelectedEntity();
    return entity ? entity.id === id : false;
  };

  getMainListItem = entity => (
    <MainEntityListItem
      {...entity}
      dataType={entity.type_name}
      groups={this.props.groups}
      isSelected={this.isSelected(entity.id)}
      onClick={() => this.selectEntity(entity)}
    />
  );

  renderMoreButton = (entityType, entity) => (
    <MoreButton
      detailsDataType={this.getDataType()}
      entityType={entityType}
      entity={entity}
      groups={this.props.groups}
    />
  );

  // Render a single list item
  getListItem = (entity, showResolution, isOpportunity) => {
    const mainEntity = this.getMainEntity();
    return (
      <ListItem
        id={this.getItemId(entity)}
        innerDivStyle={detailStyles.listItem}
        key={entity.id}
        style={this.isSelected(entity.id) ? detailStyles.selectedItem : {}}
      >
        <OverlapListItem
          dataType={entity.type_name}
          isOpportunity={isOpportunity}
          isSelected={this.isSelected(entity.id)}
          onClick={() => this.selectEntity(entity)}
          {...entity}
          mainAgency={mainEntity.agency}
          mainId={mainEntity.id}
          mainOverlapStatus={mainEntity.overlap_status}
          mainEntityType={mainEntity.type_name}
          moreButton={this.renderMoreButton(entity.entity_type, entity)}
          showResolution={showResolution}
          highlightGeometry={this.state.highlightGeometry}
          toggleHighlightGeometry={this.toggleHighlightGeometry}
        />
      </ListItem>
    );
  };

  selectionRenderer = (values, menuItems) => {
    const totalCount = this.buildMenuItems().length;
    return `${menuItems.length === totalCount ? 'All ' : ''}${menuItems.length} selected`;
  };

  getItemToolbar = entities => {
    const { sticky } = this.state;
    const overlapCount = entities.length - 1;
    const title = `${overlapCount} overlaps`;
    const items = this.buildMenuItems();
    const value = items.filter(item => get(this.state.filter, item.id, true)).map(item => item.id);
    return (
      <div styleName={`details-content-list-subheader ${sticky ? 'details-toolbar-sticky' : ''}`}>
        <div styleName="details-content-list-subheader-title">
          {title}
        </div>
        <div styleName="details-content-list-subheader-filter">
          {items.length > 0 && (
            <FilterDropDown
              anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
              isValueSelected
              multiple
              onChange={this.filterChange}
              selectionRenderer={this.selectionRenderer}
              value={value}
            >
              {items.map(item => this.buildMenuItem(item))}
            </FilterDropDown>
          )}
        </div>
        <Divider />
      </div>
    );
  };

  getListItems = () => {
    const { conflicts, opportunities } = this.props.data;
    const listItems = [].concat(
      this.getNestedItems(conflicts, true, false),
      this.getNestedItems(opportunities, true, true)
    );
    if (listItems.length === 0) {
      return (
        <div styleName="details-content-no-overlaps">
          No overlaps found
        </div>
      );
    }
    return listItems;
  };

  getNestedItems = (entities, showResolution, isOpportunity) => this.filterEntities(entities).map(
    entity => this.getListItem(entity, showResolution, isOpportunity)
  );

  isLeadEntityVisible = (mainEntity, entities) => entities.filter(entity => entity.id === mainEntity.id);

  getOpenConflicts = entities => entities.filter(entity => this.conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolve));

  getOpportunities = entities => entities.filter(entity => entity.overlap_type === 'Opportunity');

  getPendingConflicts = entities => entities.filter(entity => this.conflictFilter(entity, FRONTEND_CONFLICT_STATUS.pending));

  getResolvedConflicts = entities => entities.filter(entity => this.conflictFilter(entity, FRONTEND_CONFLICT_STATUS.resolved));

  // For each overlapping entity option in the filter dropdown, include all overlapping entities of that type
  // if that entity type is selected to be included in the list.
  getNonActionableOverlappingEntitiesByType = entities => {
    const nonActionableOverlappingEntityTypes = this.getNonActionableOverlappingEntityTypes();
    const filteredEntities = [];
    nonActionableOverlappingEntityTypes.forEach(overlappingEntityType => {
      if (get(this.state.filter, overlappingEntityType, true)) {
        filteredEntities.push(entities.filter(entity => entity.type_name === overlappingEntityType));
      }
    });
    return [].concat(...filteredEntities);
  };

  // Filter entities based on the selected filter.
  filterEntities = entities => entities && [
    ...((get(this.state.filter, CONFLICT_FILTERS.OPEN, true) && this.getOpenConflicts(entities)) || []),
    ...((get(this.state.filter, CONFLICT_FILTERS.OPPORTUNITIES, true) && this.getOpportunities(entities)) || []),
    ...((get(this.state.filter, CONFLICT_FILTERS.PENDING, true) && this.getPendingConflicts(entities)) || []),
    ...((get(this.state.filter, CONFLICT_FILTERS.RESOLVED, true) && this.getResolvedConflicts(entities)) || []),
    ...this.getNonActionableOverlappingEntitiesByType(entities)
  ];

  conflictFilter = (entity, filterStatus) => {
    const mainEntity = this.getMainEntity();
    if (!mainEntity || !entity) {
      return false;
    }
    const nonActionableOverlappingEntityTypes = this.getNonActionableOverlappingEntityTypes();
    if (includes(nonActionableOverlappingEntityTypes, entity.type_name)) {
      return false;
    }
    const { status } = getEntityStatus(mainEntity.overlap_status, entity.overlap_status);
    return entity.overlap_type === 'Conflict' && status === filterStatus;
  };

  // Build on/off attributes to display overlap types on the map's legend dynamically.
  buildLegendOverlapTypes = () => {
    const entities = this.getBoundsEntities();
    if (entities) {
      const mainEntity = this.getMainEntity();
      if (mainEntity) {
        const isLeadVisible = this.isLeadEntityVisible(mainEntity, entities).length > 0;
        return {
          lead: isLeadVisible,
          open: this.getOpenConflicts(entities).length > 0,
          opportunity: this.getOpportunities(entities).length > 0,
          pending: this.getPendingConflicts(entities).length > 0,
          resolved: this.getResolvedConflicts(entities).length > 0,
          // If the main entity is not the selected one, then other one is,
          // thus the overlapping geometry will be enabled.
          overlapping: isLeadVisible && !this.isSelected(mainEntity.id)
        };
      }
    }
    return {};
  };

  getMainEntityType = () => {
    const mainEntity = this.getMainEntity();
    return getEntityType(mainEntity.type_name);
  };

  isMainEntityAllowResolution = () => {
    const type = this.getMainEntityType();
    return type && type.overlaps['allow-resolution'];
  };

  isMainEntityAllowOpportunities = () => {
    const type = this.getMainEntityType();
    return type && type.overlaps['allow-opportunities'];
  };

  // Return a unique list of entity types of all entities overlapping the main entity
  // that doesn't have enabled the resolution workflow.
  getNonActionableOverlappingEntityTypes = () => {
    const mainEntity = this.getMainEntity();
    const overlappingEntities = this.getOverlappingEntities();

    // Get a unique list of all entity types in all overlapping entities:
    const entityTypeNames = [...new Set(Object.values(overlappingEntities).map(entity => entity.type_name))];
    // Get entityTypes and remove types unknown to UI.
    let entityTypes = entityTypeNames.map(typeName => getEntityType(typeName)).filter(entity => entity);

    // And also remove the ones that allows resolution.
    if (getEntityType(mainEntity.type_name).overlaps['allow-resolution']) {
      entityTypes = entityTypes.filter(entityType => !entityType.overlaps['allow-resolution']);
    }
    return entityTypes.map(entityType => entityType.name);
  };

  buildNonActionableEntityMenuItems = () => this.getNonActionableOverlappingEntityTypes().map(entityType => ({
    id: entityType,
    name: getEntityType(entityType).label
  }));

  buildMenuItems = () => [
    {id: CONFLICT_FILTERS.OPEN, name: 'Open conflicts'},
    {id: CONFLICT_FILTERS.OPPORTUNITIES, name: 'Opportunities'},
    {id: CONFLICT_FILTERS.PENDING, name: 'Pending conflicts'},
    {id: CONFLICT_FILTERS.RESOLVED, name: 'Resolved conflicts'},
    ...this.buildNonActionableEntityMenuItems()
  ].filter(item => {
    if (!this.isMainEntityAllowResolution() &&
      includes([CONFLICT_FILTERS.OPEN, CONFLICT_FILTERS.PENDING, CONFLICT_FILTERS.RESOLVED], item.id)
    ) {
      return false;
    }
    if (!this.isMainEntityAllowOpportunities() && item.id === CONFLICT_FILTERS.OPPORTUNITIES) {
      return false;
    }
    return true;
  });

  // eslint-disable-next-line react/display-name
  buildMenuItem = item => (
    <MenuItem
      checked={this.getChecked(item)}
      key={item.id}
      insetChildren
      label={item.name}
      primaryText={item.name}
      value={item.id}
    />
  );

  filterChange = (event, key, value) => {
    const itemIds = this.buildMenuItems().map(item => item.id);
    const newFilter = {};
    itemIds.forEach(id => {
      newFilter[id] = includes(value, id);
    });
    this.setState({ filter: newFilter });
  };

  getChecked = item => get(this.state.filter, item.id, true);

  getMainItemHeight = () => {
    const mainEntity = this.getMainEntity();
    return document.getElementById(`list-item-${mainEntity.id}`).offsetHeight;
  };

  setSticky = event => this.setState({sticky: event.target.scrollTop > this.getMainItemHeight(), scrolled: true});

  getDrawer = () => {
    const entities = this.getEntities();
    if (entities) {
      const { location } = this.props;
      const { opportunities } = this.props.data;
      const mainEntity = this.getMainEntity();
      return (
        <Paper styleName="details-content" zDepth={2} key="drawer">
          <div styleName="details-content-list-body-wrapper" onScroll={this.setSticky}>
            <div styleName="details-content-list-body">
              <List style={detailStyles.list}>
                {this.getMainListItem(mainEntity)}
                {this.getItemToolbar(entities)}
                {this.getListItems()}
              </List>
            </div>
          </div>
          <MapTray location={location} />
          <OverlapRevokeDialog />
          <OverlapStatusDialog
            mainEntity={mainEntity}
            opportunities={opportunities}
          />
        </Paper>
      );
    }
    return null;
  };

  isOverlap = () => this.getDataType() === 'overlap';

  updateViewport = () => this.forceUpdate();

  getMap = () => (
    <div styleName="details-map-content" key="map">
      <FilePreview />
      {this.isOverlap() && <FloatingBar tools={false} />}
      {this.isOverlap() && (
        <MapLegend
          overlapTypes={this.buildLegendOverlapTypes()}
          mainEntity={this.getMainEntity()}
        />
      )}
      <ViewMap
        isOverlap={this.isOverlap()}
        highlightGeometry={this.state.highlightGeometry}
        segments={this.getSegments()}
        selectedSegmentIds={this.getSelectedSegmentIds()}
        selectEntity={this.selectEntity}
        onBoundsChanged={this.updateViewport}
      />
    </div>
  );

  buildSelectQuery = entity => {
    const { id } = entity;
    const query = { focus: id, type: entity.entity_type };
    if (this.isSelected(id)) {
      query.open = id;
    }
    return query;
  };

  selectEntity = entity => {
    const { pathname, search } = location;
    const searchParams = new URLSearchParams(search);
    const query = new URLSearchParams({
      ...Object.fromEntries(searchParams),
      ...this.buildSelectQuery(entity)
    });
    this.props.push(`${pathname}?${query.toString()}`);
  };

  clearTray = () => {
    const { pathname, search } = location;
    const searchParams = new URLSearchParams(search);
    searchParams.delete('open');
    this.props.push(`${pathname}?${searchParams.toString()}`);
  };

  toggleHighlightGeometry = () => this.setState({ highlightGeometry: !this.state.highlightGeometry });
}

export default ListDetail;
