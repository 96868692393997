import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { deleteWorkflowTaskType } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const DeleteWorkflowTaskTypeDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const { id, taskType = {}, workflowName } = dialogParams[dialog.DELETE_WORKFLOW_TASK_TYPE] || {};
  const isDialogActive = activeDialogs[dialog.DELETE_WORKFLOW_TASK_TYPE];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.DELETE_WORKFLOW_TASK_TYPE));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    () => dispatch(deleteWorkflowTaskType(id, taskType)),
    [dispatch, id, taskType]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Remove task type?
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{
          paddingBottom: '1rem'  // Fix for unwanted DialogContent scrollbars.
        }}>
          Task type "{taskType.name}" will no longer be available to create new tasks in any workflow cycles of "{workflowName}".
          However, you can still work with tasks that already exist.
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="secondary"
          onClick={onDelete}
          variant="contained"
        >
          REMOVE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWorkflowTaskTypeDialog;
