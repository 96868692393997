import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import Paper from 'material-ui/Paper';
import { toggleMarkerModal } from '@actions/map-actions';
import * as colors from '@constants/colors';
import LegendIcon from '@icons/map-legend/legend-icon';
import OverlappingIcon from '@icons/map-legend/overlapping-icon';
import '../../../map/legend/legend.scss';

class MapLegend extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.legendOpen !== nextProps.legendOpen) {
      if (nextProps.legendOpen && this.props.markerModalOpen) {
        this.props.toggleMarkerModal();
      }
    }
  }

  getLeadLabel = () => {
    const { mainEntity } = this.props;
    if (mainEntity && mainEntity.entity_type) {
      return `${capitalize(mainEntity.entity_type)} ${mainEntity.id}`;
    }
    return null;
  };

  getItems = () => {
    const { overlapTypes } = this.props;
    const list = [];
    if (overlapTypes.lead) {
      const leadLabel = this.getLeadLabel();
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapLead} />, label: leadLabel });
    }
    if (overlapTypes.open) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapOpen} />, label: 'Open Conflict' });
    }
    if (overlapTypes.pending) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapPending} />, label: 'Pending Conflict' });
    }
    if (overlapTypes.resolved) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapResolved} />, label: 'Resolved Conflict' });
    }
    if (overlapTypes.opportunity) {
      list.push({ icon: <LegendIcon color={colors.dotmapsOverlapOpportunity} />, label: 'Opportunity' });
    }
    if (overlapTypes.overlapping) {
      list.push({ icon: <OverlappingIcon />, label: 'Overlapping Geometry' });
    }
    if (list.length === 0) {
      return (
        <div styleName="map-legend-list-group">
          <div styleName="map-legend-empty">
            No records shown
          </div>
        </div>
      );
    }
    return (
      <div styleName="map-legend-list-group">
        {list.map(item => (
          <div styleName="map-legend-list-item" key={item.label}>
            <div styleName="icon">
              {item.icon}
            </div>
            <div styleName="text">
              {item.label}
            </div>
          </div>
        ))}
      </div>
    );
  };

  render() {
    const { legendOpen } = this.props;
    if (!legendOpen) {
      return null;
    }
    return (
      <Paper styleName="map-legend-container" zDepth={0}>
        <div styleName="map-legend-list">
          {this.getItems()}
        </div>
      </Paper>
    );
  }
}

MapLegend.propTypes = {
  legendOpen: PropTypes.bool,
  mainEntity: PropTypes.object,
  markerModalOpen: PropTypes.bool,
  overlapTypes: PropTypes.object,
  toggleMarkerModal: PropTypes.func
};

const mapStateToProps = state => {
  const { legendOpen } = state.map;
  const { markerModalOpen } = state.map.markers;
  return { legendOpen, markerModalOpen };
};

export default connect(mapStateToProps, {toggleMarkerModal})(MapLegend);
