/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGoogleMap } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Toggle from 'material-ui/Toggle';
import { togglePois } from '@actions/map-actions';
import { getMapStylers } from '@utils/map-utils';
import './markers-modal.scss';

const MarkersModal = ({ small }) => {
  const dispatch = useDispatch();
  const map = useGoogleMap();
  const { markerModalOpen, poisOn } = useSelector(state => state.map.markers);
  const { transit } = useSelector(state => state.map);

  useEffect(() => {
    map.setOptions({ styles: getMapStylers(poisOn, transit) });
  }, [map, poisOn, transit]);

  const onToggle = () => dispatch(togglePois());

  if (!markerModalOpen) {
    return null;
  }

  return (
    <Paper styleName={`container ${small ? 'small' : ''}`} zDepth={0}>
      <div>Show or hide markers</div>
      <div>
        <Toggle label="Places" name="places" toggled={poisOn} onToggle={onToggle} />
      </div>
    </Paper>
  );
};

MarkersModal.propTypes = {
  small: PropTypes.bool
};

export default memo(MarkersModal);
