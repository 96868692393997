/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { push } from 'connected-react-router';

import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import EntityOverlapSection from './entity-overlap-section';

import { getEntityFiltersChanged } from '@selectors/entities-selector';
import { toggleOnlyShowOverlaps } from '@actions/map-actions';

import styles from './overlaps-tab.scss';

const OverlapsTab = ({
  conflicts = [],
  detailUrl,
  entityType,
  opportunities = [],
  tabActive
}) => {
  const dispatch = useDispatch();
  const agencyId = useSelector(state => state.auth.user?.agencyId);
  const onlyShowOverlaps = useSelector(state => state.map?.onlyShowOverlaps);
  const entityFiltersChanged = useSelector(state => getEntityFiltersChanged(state, { type: entityType }));

  const agencyOverlaps = useMemo(() => {
    if (agencyId) {
      const all = [...conflicts, ...opportunities];
      return all.filter(entity => entity.agency === agencyId);
    }
    return [];
  }, [conflicts, opportunities, agencyId]);

  const otherConflicts = useMemo(() => {
    if (agencyId && conflicts) {
      return conflicts.filter(entity => entity.agency !== agencyId);
    }
    return conflicts;
  }, [conflicts, agencyId]);

  const otherOpportunities = useMemo(() => {
    if (agencyId && opportunities) {
      return opportunities.filter(entity => entity.agency !== agencyId);
    }
    return opportunities;
  }, [opportunities, agencyId]);
  const handleToggleShowOverlaps = useCallback(
    () => dispatch(toggleOnlyShowOverlaps()),
    [dispatch]
  );
  const handleGoToConflictDetail = useCallback(
    () => dispatch(push(detailUrl)),
    [detailUrl, dispatch]
  );

  return (
    <div className={styles.tabContainer}>
      <div className={styles.overlapSection}>
        {((conflicts.length > 0) || (opportunities.length > 0)) &&
          entityFiltersChanged &&
          <div className={styles.filterWarning}>
            <ErrorIcon classes={{root: styles.filterWarningIcon}}/>
            <div>Some items may be hidden on the map because filters are applied.</div>
          </div>
        }
        <div className={styles.overlapToggle}>
          <FormControlLabel
            control={
              <Switch
                onChange={handleToggleShowOverlaps}
                checked={onlyShowOverlaps}
                color="primary"
              />
            }
            label="Only show overlaps on map"
            classes={{
              root: styles.toggle,
              label: styles.text
            }}
          />
        </div>
        <EntityOverlapSection
          emptyMsg="There are no overlaps with your agency."
          showTitle
          title="My agency's entities in overlap"
          list={agencyOverlaps}
        />
        <EntityOverlapSection
          emptyMsg="There are no conflicts."
          showTitle
          title="Conflicts"
          list={otherConflicts}
        />
        {otherOpportunities &&
          <EntityOverlapSection
            emptyMsg="There are no opportunities."
            showTitle
            title="Opportunities"
            list={otherOpportunities}
          />
        }
      </div>
      {tabActive &&
        <Button
          onClick={handleGoToConflictDetail}
          variant="contained"
          color="primary"
          fullWidth
          classes={{root: styles.overlapButton}}
        >
          VIEW OVERLAPS DETAIL
        </Button>
      }
    </div>
  );
};

OverlapsTab.propTypes = {
  conflicts: PropTypes.array,
  detailUrl: PropTypes.string,
  entityType: PropTypes.string,
  opportunities: PropTypes.array,
  tabActive: PropTypes.bool
};

export default memo(OverlapsTab);
