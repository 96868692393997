import React, { useCallback, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { updateTask } from '@actions/workflow-actions';
import * as colors from '@constants/colors';
import * as dialog from '@constants/dialogs';
import { dialogStyles, spinnerStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DotmapsLoader from '@shared/dotmaps-loader';
import { pluralize } from '@utils/shared-utils';
import { buildFieldValues, buildSegmentIds } from '@utils/workflow-utils';

const AssignTaskDialog = ({ assignTempTaskId, params, taskId }) => {
  const dispatch = useDispatch();
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { cycle } = useSelector(state => state.workflow);
  const { data } = useSelector(state => state.dataDetail);
  const { task_type } = useSelector(state => state.dataTypes);
  const { errors } = useSelector(state => state);
  const { loading } = dialogParams[dialog.ASSIGN_TASK] || {};
  const isDialogActive = activeDialogs[dialog.ASSIGN_TASK];
  const agencies = data?.activities?.length || 0;
  const hasErrors = errors?.length > 0;

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.ASSIGN_TASK));
    },
    [dispatch]
  );

  const onSubmit = useCallback(
    () => {
      const { taskTypeId } = params;
      const { id, workflow } = cycle;
      const taskType = task_type[taskTypeId];
      const taskData = R.omit([
        // All these are built below, so omit them from the form object.
        'name', 'task_type', 'cycle', 'status', 'workflow', 'segments',
        // Omit all fields starting with '_':
        ...Object.keys(data).filter(key => key[0] === '_'),
        // Also omit all number properties (the sections), since
        // we are bundling all custom fields into a single
        // 'field_values' field with buildFieldValues():
        ...Object.keys(data).filter(key => parseInt(key, 10) > 0)
      ], data);

      const task = {
        ...taskData, // Besides all fields below, 'taskData' may contain
                     // other task fields (all the core fields).
        owner: data.owner && data.owner.length === 1 ? data.owner[0] : null,
        // Remove the 'all' entry since we need all numbers (and if it's there it means 'all'
        // individual agency ids were already selected).
        activities: data.activities && data.activities.filter(agency => agency !== 'all'),
        segments: buildSegmentIds(data),
        field_values: buildFieldValues(data),
        name: data.name || taskType.name,
        task_type: taskTypeId,
        cycle: id,
        workflow,
        tempTaskId: taskId // Needed to move attachments over to real task ID on save
                           // will be removed from data before request.
      };
      dispatch(updateTask(task));
      dispatch(assignTempTaskId());
    },
    [assignTempTaskId, cycle, data, dispatch, params, taskId, task_type]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>Assign task</div>
      </DialogTitle>
      <DialogContent>
        <div style={{
          paddingBottom: '1rem'  // Fix for unwanted DialogContent scrollbars.
        }}>
          <div>
            The task will be assigned to {agencies} {pluralize(agencies, 'agency', 'agencies')}.
            We will send them a notification immediately.
          </div>
        </div>
        {hasErrors && (
          <div style={{ color: colors.dotmapsError }}>
            {errors[0]?.response?.data?.task_type}
          </div>
        )}
        {loading && !hasErrors && (
          <div style={spinnerStyles}>
            <DotmapsLoader color={colors.dotmapsBlue} display={loading} />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading && !hasErrors} onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          disabled={loading}
          onClick={onSubmit}
          style={{ marginLeft: '0.5rem' }}
          variant="contained"
        >
          ASSIGN
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AssignTaskDialog.propTypes = {
  assignTempTaskId: PropTypes.func,
  params: PropTypes.object,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default memo(AssignTaskDialog);
