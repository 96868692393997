import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InsertDriveFileTwoToneIcon from '@material-ui/icons/InsertDriveFileTwoTone';
import DeleteIcon from '@material-ui/icons/Delete';
// Upgrade node: it's 'GetApp' in MUI v4, we can use 'Download' on v5 (both looks exactly the same).
import DownloadIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import {
  bulkDeleteAttachments,
  deleteAttachment,
  getAttachments,
  showFilePreview
} from '@actions/attachment-actions';
import FilePreview from '@shared/files/file-preview';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { downloadAttachment } from '@components/attachments/actions';
import * as dialog from '@constants/dialogs';
import { FILES_TAB_COLUMNS as columns } from '@constants/table';
import { useCanEditAttachments } from '@hooks/permission-hooks';
import DetailsTableHeader from '@shared/details-table-header';
import BulkDeleteFilesDialog from '@shared/dialogs/bulk-delete-files-dialog';
import DeleteFileDialog from '@shared/dialogs/delete-file-dialog';
import EditFileDialog from '@shared/dialogs/edit-file-dialog';
import Table from '@shared/table';
import FilesUploadButton from './files-upload-button';

const Files = () => {
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  const { attachments, loading } = useSelector(state => state.attachments);
  const dispatch = useDispatch();
  const rows = useMemo(() => attachments[`entity${data.id}`] || [], [attachments, data.id]);
  const [checked, setChecked] = useState([]);

  const setAll = useCallback(
    value => setChecked(new Array(rows?.length).fill(value)),
    [rows]
  );

  // Initialize checkbox state with "unchecked":
  useEffect(() => {
    if (rows?.length > 0) {
      setAll(false);
    }
  }, [rows, setAll]);

  const isAllChecked = checked.length > 0 && checked.every(isChecked => isChecked);
  const isAnyChecked = checked.some(isChecked => isChecked);

  const onToggleAll = () => {
    if (isAllChecked) {
      setAll(false);
    } else {
      setAll(true);
    }
  };

  const onToggle = index => {
    setChecked(prevChecked => {
      const updatedChecked = [...prevChecked];
      updatedChecked[index] = !updatedChecked[index];
      return updatedChecked;
    });
  };


  // Load entity attachments:
  useEffect(() => {
    const fetchAttachments = async () => dispatch(getAttachments('entity', data.id));
    fetchAttachments();
  }, [data.id, dispatch]);  // eslint-disable-line react-hooks/exhaustive-deps

  const { canEdit, canUpload } = useCanEditAttachments(data.agency, dataType);

  // eslint-disable-next-line no-unused-vars
  const onRowClick = (event, row, index) => dispatch(showFilePreview('entity', data.id, index));

  const editFile = row => dispatch(openDashboardDialog(dialog.EDIT_FILE, { data: row }));

  const bulkRemoveFiles = () => {
    const selectedIds = rows.filter((row, index) => checked[index]).map(row => row.id);
    const params = {
      ids: selectedIds,
      bulkDeleteFiles: (...args) => dispatch(bulkDeleteAttachments(...args))
    };
    dispatch(openDashboardDialog(dialog.BULK_DELETE_FILES, params));
  };

  const removeFile = row => {
    const params = {
      filename: row.file_name,
      index: row.id,
      deleteFile: (...args) => dispatch(deleteAttachment(...args))
    };
    dispatch(openDashboardDialog(dialog.DELETE_FILE, params));
  };

  const headerActions = [
    { icon: DeleteIcon, label: 'Bulk Delete', onClick: bulkRemoveFiles }
  ];

  const actions = [
    { icon: DownloadIcon, label: 'Download', onClick: downloadAttachment },
    { icon: EditIcon, label: 'Edit', onClick: editFile }
  ];

  if (canEdit) {
    actions.push({ icon: DeleteIcon, label: 'Delete', onClick: removeFile });
  }

  const tableProps = {
    actions,
    checked, checkedProps: { isAllChecked, isAnyChecked }, onToggleAll, onToggle,  // Checkbox props.
    columns,
    empty: {
      icon: <InsertDriveFileTwoToneIcon htmlColor="#E0E0E0" style={{ fontSize: '6rem' }} />,
      message: 'No files uploaded yet',
      subText: <span>Upload files to share with other team members<br />and keep records of documents</span>
    },
    header: (
      <DetailsTableHeader
        actions={headerActions}
        button={(
          <Fragment>
            {canUpload && <FilesUploadButton objectId={data.id} />}
          </Fragment>
        )}
        checked={checked}
        count={rows.length}
        label="file"
      />
    ),
    loading,
    onRowClick,
    rows,
    style: { maxWidth: '55rem' }
  };

  return (
    <Fragment>
      <Table {...tableProps} />
      {checked?.filter(item => item)?.length > 0 && <BulkDeleteFilesDialog />}
      {rows.length > 0 && <DeleteFileDialog />}
      {rows.length > 0 && <EditFileDialog />}
      <FilePreview />
    </Fragment>
  );
};

export default Files;
