import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FlatButton from 'material-ui/FlatButton';
import { actionButton, actionButtonDisabled } from '@constants/mui-theme';
import './action-button.scss';

// Simple implementation of enabled and disabled styles for generic action button used throughout dotmaps.
const ActionButton = ({ children, disabled, ...other }) => {
  const baseProps = disabled ? actionButtonDisabled : actionButton;
  const composedProps = R.mergeDeepRight(baseProps, other);
  return (
    <FlatButton styleName="action-button-shadow" {...composedProps} disabled={disabled}>
      {children}
    </FlatButton>
  );
};

ActionButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool
};

export default ActionButton;
