import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import { downloadCSVExport } from '@actions/data-table-actions';
import { overlayTextColor } from '@constants/colors';
import { tooltipStyles } from '@constants/mui-theme';
import { getFilters } from '@selectors/data-table-selector';
import LoaderIconWrapper from '@shared/loader-icon-wrapper';
import './action-items.scss';

class ActionItems extends Component {
  state = { open: false };

  close = () => this.setState({ open: false });

  exportCSV = () => this.props.downloadCSVExport(this.props.dataType, this.props.subType, this.props.filters);

  handleMenuChange = (event, menuItem) => {
    this.props.returnAction(menuItem.props.primaryText);
  };

  open = event => this.setState({ open: true, anchorEl: event.currentTarget });

  render() {
    return (
      <div styleName="action-items">
        <LoaderIconWrapper>
          <IconButton onClick={this.exportCSV} tooltip="Export" tooltipStyles={tooltipStyles}>
            <FontIcon
              className="material-icons"
              color={overlayTextColor}
            >
              file_download
            </FontIcon>
          </IconButton>
        </LoaderIconWrapper>
      </div>
    );
  }
}

ActionItems.propTypes = {
  dataType: PropTypes.string,
  downloadCSVExport: PropTypes.func,
  filters: PropTypes.object,
  returnAction: PropTypes.func,
  subType: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const filters = getFilters(state, props);
  return { filters };
};

export default connect(mapStateToProps, { downloadCSVExport })(ActionItems);

export { ActionItems as PureActionItems };
