import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import CheckIcon from '@material-ui/icons/Check';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';

import styles from './menu-item.scss';

const FilterChipMenuItem = ({option, checked, onSelect, classes = {}, ...passThroughProps}) => {
  const handleClick = useCallback(() => {
    onSelect(option.value);
  }, [option, onSelect]);

  const {root: rootClasses, ...passThroughClasses} = classes;

  return (
    <MenuItem
      key={option.value}
      onClick={handleClick}
      classes={{
        root: classNames(styles.item, rootClasses),
        ...passThroughClasses
      }}
      {...passThroughProps}
    >
      <div className={styles.checkArea}>
        {checked &&
          <CheckIcon color="inherit"/>
        }
      </div>
      {('avatar' in option) && option.avatar}
      {('icon' in option) &&
        <div
          className={styles.iconArea}
        >
          <img src={option.icon} alt="" />
        </div>
      }
      <ListItemText
        primary={option.label}
        secondary={option.description} 
        classes={{
          root: styles.itemText,
          primary: styles.primary,
          secondary: styles.secondary
        }}
      />
    </MenuItem>
  );
};

FilterChipMenuItem.propTypes = {
  checked: PropTypes.bool,
  classes: PropTypes.object,
  icon: PropTypes.string,
  onSelect: PropTypes.func,
  option: PropTypes.shape({
    avatar: PropTypes.object,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.object
    ]).isRequired,
    icon: PropTypes.string
  }).isRequired
};

export default FilterChipMenuItem;
