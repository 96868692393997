import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Divider from 'material-ui/Divider';
import { popTray } from '@actions/map-actions';
import { dotmapsGreen } from '@constants/colors';
import {
  BaseTray,
  baseTrayMapDispatchToProps,
  baseTrayMapEntityProps,
  baseTrayMapStateToProps
} from '@components/map/tray/base-tray';
import Header from '@components/map/tray/header';
import DetailsTableBlock from '@shared/details-table-block';
import DotmapsLoader from '@shared/dotmaps-loader';
import '../tray.scss';

class Layer extends BaseTray {
  getAttrs = layer => {
    const fieldList = [];
    for (const field in layer) {
      if (Object.prototype.hasOwnProperty.call(layer, field)) {
        fieldList.push(<DetailsTableBlock key={field} fields={[{label: field, value: layer[field] }]} />);
      }
    }
    return fieldList;
  };

  goBack = () => this.props.popTray();

  render() {
    const { entity, pending } = this.props;
    if (pending) {
      return (
        <div styleName="map-tray-body-wrapper">
          <Header />
          <DotmapsLoader color={dotmapsGreen} display small />;
        </div>
      );
    }
    if (!entity) {
      return null;
    }

    return (
      <div styleName="map-tray-body-wrapper">
        <Header title={entity.layerType} />
        <Divider />
        <div styleName="map-tray-body">
          <div styleName="map-tray-content">
            { this.getAttrs(entity) }
          </div>
        </div>
      </div>
    );
  }
}

Layer.propTypes = {
  entity: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  pending: PropTypes.bool,
  popTray: PropTypes.func
};

const mapStateToProps = state => {
  const props = baseTrayMapStateToProps(state);
  const { entity, pending } = baseTrayMapEntityProps(props, 'layer');
  return { entity, pending, ...props };
};

const mapDispatchToProps = {
  ...baseTrayMapDispatchToProps,
  popTray
};

export default connect(mapStateToProps, mapDispatchToProps)(Layer);

export { Layer as PureLayer };
