import React from 'react';
import PropTypes from 'prop-types';

import EntityCard from '@shared/list-card/entity-card';
import ListHeader from '@shared/ui-library/list/list-header';

import styles from './entity-overlap-section.scss';

const EntityOverlapSection = ({
  emptyMsg,
  list,
  showTitle,
  title
}) => {
  const count = list && list.length ? list.length : 0;
  return (
    <div className={styles.overlapSectionContainer}>
      <ul className={styles.entityList} >
        {showTitle && (
          <ListHeader
            text={title}
            count={count}
            className={styles.listHeader}
          />
        )}
        {count > 0 && 
          list.map(entity => <EntityCard key={entity.id} entity={entity} />)
        }
        {count === 0 && (
          <li className={styles.noResults}>
            {emptyMsg}
          </li>
        )}
      </ul>
    </div>
  );
};

EntityOverlapSection.propTypes = {
  emptyMsg: PropTypes.string,
  list: PropTypes.array,
  showTitle: PropTypes.bool,
  title: PropTypes.string
};

export default EntityOverlapSection;
