import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { resetDatatableFilter } from '@actions/data-table-actions';
import { overlayTextColor } from '@constants/colors';
import { getTopBarConfig } from '@constants/config';
import AddIcon from '@material-ui/icons/Add';
import ActionIconButton from '@shared/action-icon-button';
import FlexSeparator from '@shared/helpers/flex-separator';
import SourceLink from '@shared/source-link';
import { getCustomComponent } from '@utils/data-table/shared';
import { canAccessEntity, isReadOnly } from '@utils/permission-utils';
import SearchAutocomplete from './search-autocomplete';
import ActionItems from './action-items';
import './data-table-top-bar.scss';

class DataTableTopBar extends Component {
  handleAction = menuItemText => {
    switch (menuItemText) {
    case 'Reset filters':
      this.props.resetDatatableFilter(this.props.dataType, this.props.subType);
      break;
    default:
      break;
    }
  };
  getConfig = () => {
    const { dataType, subType } = this.props;
    const config = getTopBarConfig()[dataType];
    if (config.nested) {
      return config[subType];
    }
    return config;
  };
  isLinkEnabled = config => {
    const { permission } = config;
    if (permission) {
      const { dataType, subType } = this.props;
      const type = dataType === subType ? dataType : subType;
      return canAccessEntity(null, type, permission) && !isReadOnly(type);
    }
    return false;
  };
  getLink = () => {
    const config = this.getConfig();
    const link = R.pathOr(false, ['link'], config);
    if (link && this.isLinkEnabled(config)) {
      return (
        <SourceLink style={{ textDecoration: 'none' }} to={link.to}>
          <ActionIconButton
            {
              ...{
                customIcon: <AddIcon htmlColor={overlayTextColor} />,
                link,
                tooltip: 'Add'
              }
            }
          />
        </SourceLink>
      );
    }
    return null;
  };
  render() {
    const { dataType, subType } = this.props;
    const config = this.getConfig();
    const showSearch = R.pathOr(false, ['search'], config);
    return (
      <div styleName="data-table-top-bar-component">
        {showSearch && <SearchAutocomplete {...{ dataType, subType }} />}
        {!showSearch && <FlexSeparator />}
        {this.getLink()}
        {R.pathOr(false, ['component'], config) &&
          getCustomComponent(config.component)}
        <ActionItems dataType={dataType} subType={subType} returnAction={this.handleAction} />
      </div>
    );
  }
}

DataTableTopBar.propTypes = {
  dataType: PropTypes.string,
  resetDatatableFilter: PropTypes.func,
  subType: PropTypes.string
};

export default connect(null, { resetDatatableFilter })(DataTableTopBar);

export { DataTableTopBar as PureDataTableTopBar };
