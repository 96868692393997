/*eslint-disable no-nested-ternary*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import BreadCrumb from './breadcrumb';
import DataTableTopBar from './data-table-top-bar';
import DetailsTopBar from './details-top-bar';
import { toggleFilterTopBar } from '@actions/dashboard-actions';
import { dotmapsBlue } from '@constants/colors';
import { getDashboardBackgroundColor, getTopBarConfig } from '@constants/config';
import { labeizeDataType } from '@utils/dashboard-details-utils';
import { getDataType } from '@utils/dashboard-utils';
import {
  buildFilterToggleIcon,
  countDashboardFiltersInUse,
  isDetailsPage,
  showFilterBar
} from '@utils/filter-utils';
import './top-bar.scss';

class TopBar extends Component {
  getPath = () => {
    const { location: { pathname } } = this.props;
    return pathname.split('/');
  };

  getPathDataType = () => R.pathOr('', [3], this.getPath());

  getDetailName = () => {
    const page = R.pathOr(null, [2], this.getPath());
    if (page === 'details') {
      return this.props.detailName;
    }
    const pathDataType = this.getPathDataType();
    if (pathDataType === 'overlaps') {
      const { params: { dataType } } = this.props;
      return labeizeDataType(dataType);
    }
    return null;
  };

  getBreadCrumbProps = () => {
    const { params: { dataType } } = this.props;
    const pathDataType = this.getPathDataType();
    const detailName = this.getDetailName();
    return { dataType: pathDataType, subType: dataType, detailName };
  };

  isGroup = () => this.getPathDataType() === 'group';
  isActivity = () => this.getPathDataType() === 'activity';
  isTask = () => this.getPathDataType() === 'task';

  getTopBarFiltering = () => {
    const { params: { dataType: subType } } = this.props;
    if (this.isGroup()) {
      return getTopBarConfig().group[subType].filtering;
    } else if (this.isActivity()) {
      return getTopBarConfig().activity[subType].filtering;
    } else if (this.isTask()) {
      return getTopBarConfig().task[subType].filtering;
    }
    return getTopBarConfig()[subType].filtering;
  };

  getFilterToggleIcon = () => {
    if (!isDetailsPage(this.props.location) && this.getTopBarFiltering()) {
      const { filtersInUse } = this.props;
      return buildFilterToggleIcon(filtersInUse, this.props.toggleFilterTopBar, dotmapsBlue);
    }
    return null;
  };

  render() {
    const {
      params: { dataId, dataType: subType },
      location: { search }
    } = this.props;
    if (showFilterBar(this.props) && this.getTopBarFiltering()) {
      return null;
    }
    const dataType = getDataType(this.props.params, this.props.location);
    const path = this.getPath();
    const page = path[2];
    const edit = search === '?edit' || path[path.length - 1] === 'new';
    const style = {
      backgroundColor: getDashboardBackgroundColor()
    };
    return (
      <div styleName="dashboard-top-bar-container" style={style}>
        <BreadCrumb {...this.getBreadCrumbProps()} />
        {page === 'data' && <DataTableTopBar {...{ dataType, subType }} />}
        {page === 'details' &&
          <DetailsTopBar {...{ dataType, dataId, edit }} location={this.props.location} />}
        {this.getFilterToggleIcon()}
      </div>
    );
  }
}

TopBar.propTypes = {
  detailName: PropTypes.string,
  filterTopBarOpen: PropTypes.bool,
  filtersInUse: PropTypes.number,
  location: PropTypes.object,
  params: PropTypes.object,
  toggleFilterTopBar: PropTypes.func
};

const mapStateToProps = (state, props) => {
  const dataType = getDataType(props.params, props.location);
  const { params: { dataType: subType } } = props;
  const filtersInUse = countDashboardFiltersInUse(state, dataType, subType);
  const { filterTopBarOpen } = state.dashboard;

  // Get details name:
  let detailName = null;
  const { data } = state.dashboard.details[dataType] || {};
  if (data) {
    detailName = data.name && data.name.trim() || data.email || '';
  }
  return { filterTopBarOpen, filtersInUse, detailName };
};

export default connect(mapStateToProps, {toggleFilterTopBar})(TopBar);

export { TopBar as PureTopBar };
