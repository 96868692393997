import { includes } from 'lodash';
import {
  DIALOG_DISMISS,
  DIALOG_OPEN
} from '@constants/action-types';
import { getEntitiesListConfig } from '@constants/config';
import { push } from 'connected-react-router';

const buildDialogMessage = (dataType, message) => {
  return message || `This ${dataType} no longer exists.`;
};

export const openDialog = (dataType, redirectPath = null, message = null) => dispatch => {
  const text = buildDialogMessage(dataType, message);
  dispatch({ type: DIALOG_OPEN, payload: { text, redirectPath }});
};

const parseLocation = () => {
  const parts = window.location.pathname.split('/');
  if (parts.length > 1) {
    const path = parts[1];
    if (path === 'map') {
      // A 404 error on the map page (i.e. /map/?permit=1234)
      // must redirec to the map page (stripping parameters):
      return '/map';
    } else
      if (includes(getEntitiesListConfig(), path) || path === 'overlap') {
        // A not found entity or overlap must redirect to the listing page for that data type.
        // For overlap, we also redirect to the listing page if the overlap is not found,
        // however if what's not found is a overlapping entity (the focused parameter),
        // then we must handle that on the data-details code (since we don't catch a
        // 404 error in that case).
        return `/list/data/${path}`;
      }
  }
  return null;
};

export const dismissDialog = redirectPath => dispatch => {
  dispatch({ type: DIALOG_DISMISS });
  // If the redirect path was specified, use it:
  if (redirectPath) {
    dispatch(push(redirectPath));
  } else {
    // Else infer it from the existing location:
    const redirectUrl = parseLocation();
    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  }
};
