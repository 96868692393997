import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import DownloadIcon from '@icons/download-icon';
import { useCanEditAttachments } from '@hooks/permission-hooks';
import DetailCard from '@components/attachments/detail-card';
import { downloadAttachment, deleteAttachments } from '@components/attachments/actions';
import DeleteFileDialog from '@shared/dialogs/delete-file-dialog';
import FileUpload from '@shared/files/file-upload';
import TrayTabHeader from '@shared/tray-tab-header';
import { pluralize } from '@utils/shared-utils';
import './files-tab.scss';

const FilesTab = ({
  attachments,
  dataType,
  entity
}) => {
  const { canEdit, canUpload } = useCanEditAttachments(entity.agency, entity.type_name);

  const getActions = () => {
    return canEdit ? [{
      icon: <DeleteIcon />,
      label: 'Delete',
      onClick: deleteAttachments
    },
    {
      icon: <DownloadIcon />,
      label: 'Download',
      onClick: downloadAttachment
    }] : [{
      icon: <DownloadIcon />,
      label: 'Download',
      onClick: downloadAttachment
    }];
  };

  const num = attachments.length;
  const label = (
    <div styleName="files-title">
      <div styleName="files-title-label">
        {pluralize(num, 'file')}
      </div>
      <div styleName="files-title-label-count">
        {num}
      </div>
    </div>
  );

  return (
    <div styleName="files-tab-container">
      <TrayTabHeader
        title={label}
        actionButton={(
          <Fragment>
            {canUpload && <FileUpload contentType={dataType} objectId={entity.id} />}
          </Fragment>
        )}
      />
      {num === 0 && (
        <div styleName="files-empty">
          No files uploaded
        </div>
      )}
      {attachments.map((attachment, index) => {
        return (
          <DetailCard
            attachment={attachment}
            menuActions={getActions()}
            key={index}
            infoIndex={index}
            objectType={dataType}
            objectId={entity.id}
            showSubHeader
          />
        );
      })}
      {num !== 0 && <DeleteFileDialog />}
    </div>
  );
};

FilesTab.propTypes = {
  attachments: PropTypes.array,
  dataType: PropTypes.string,
  entity: PropTypes.object
};

export default FilesTab;
