import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { includes } from 'lodash';
import Divider from 'material-ui/Divider';
import MenuItem from 'material-ui/MenuItem';
import { filterDropDownStyles } from '@constants/mui-theme';
import FilterDropDown from '@shared/filter-dropdown';
import { buildFilterMenuItem, categoryToInt } from '@utils/filter-utils';
import { shallowEqual } from '@utils/react-utils';
import { BaseFilter } from './base-filter';

const parseInts = R.map(categoryToInt);
const parseIDs = R.pipe(R.map(value => value.id), parseInts);

// Base class for top bar filters that uses a DropDown:
export class BaseDropDownFilter extends BaseFilter {
  constructor(props) {
    super(props);
    this.buildInitialState = this.buildInitialState.bind(this);
    this.buildMenuItem = this.buildMenuItem.bind(this);
    this.buildPromotedState = this.buildPromotedState.bind(this);
    this.getItems = this.getItems.bind(this);
    this.getDisplayName = this.getDisplayName.bind(this);
    this.getPrimaryTextIcon = this.getPrimaryTextIcon.bind(this);
    this.getChecked = this.getChecked.bind(this);
    this.handleAll = this.handleAll.bind(this);
    this.selectionRenderer = this.selectionRenderer.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!shallowEqual(nextProps.filter, this.props.filter)) {
      const defaultState = this.buildInitialState(nextProps);
      this.setState(defaultState);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.filter, this.props.filter) ||
      !shallowEqual(nextProps.items, this.props.items) ||
      !shallowEqual(nextProps.name, this.props.name) ||
      this.state !== nextState
    );
  }
  buildStateFromProps(props) {
    const { filter } = props;
    const filters = !R.isNil(filter) ? filter.split(',') : [];
    return { selected: parseInts(filters) };
  }
  getDefaultState() {
    return this.buildInitialState(this.props);
  }
  buildInitialState(props) {
    const stateFromProps = this.buildStateFromProps(props);
    const promotedState = this.buildPromotedState(stateFromProps);
    return {
      ...stateFromProps,
      ...promotedState,
      modified: false
    };
  }
  getItems() {
    return R.values(this.props.items);
  }
  buildPromotedState(fromState) {
    // Show a promoted section if there's any selected value:
    if (fromState.selected.length > 0) {
      const items = this.getItems();
      const checked = (state, item) => includes(state.selected, item.id);
      const promotedItems = items.filter(item => checked(fromState, item));
      const demotedItems = items.filter(item => !checked(fromState, item));
      return {
        demotedItems,
        promotedItems,
        showPromoted: true
      };
    }
    return { showPromoted: false };
  }
  filterClose() {
    if (this.state.modified) {
      // Only set the new filters, if anything was modified.
      super.filterClose();
    }
    this.setState(this.buildPromotedState(this.state));
  }
  negateFilterIfEmpty = () => false;
  getNegatedFilterName = () => {
    const { name } = this.props;
    return name.replace(new RegExp('__in$'), '__not_in');
  };
  getFilters() {
    if (this.negateFilterIfEmpty()) {
      if (!this.state.selected || this.state.selected.length === 0) {
        const items = this.getItems();
        const allItems = parseIDs(items);
        return {
          [this.getNegatedFilterName()]: allItems.join(','),
          [this.props.name]: ''  // Set also the filter name to empty, so it gets removed.
        };
      }
    }
    return {
      [this.getNegatedFilterName()]: '',  // Set the negated filter to empty for deletion.
      [this.props.name]: this.state.selected.join(',')
    };
  }
  filterChange(event, key, values) {
    this.setState({ selected: values, modified: true });
  }
  selectionRenderer(values, menuItems) {
    const allItems = this.getItems();
    const items = menuItems.map(item => item.props.label || item.props.primaryText);
    if (!R.isEmpty(items)) {
      let label = 'All';
      if (allItems.length !== items.length) {
        label = items.join(', ');
      }
      return `${this.getDisplayName()}: ${label}`;
    }
    return `${this.getDisplayName()}`;
  }
  // Return the dropdown label prefix.
  getDisplayName() {
    return null;  // Implement in subclass
  }
  // Implement in subclass if we want to add an icon to the menu item.
  getPrimaryTextIcon(item) {  // eslint-disable-line no-unused-vars
    return null;
  }
  getChecked(item) {
    return includes(this.state.selected, item.id);
  }
  // Handle the 'SELECT ALL / UNSELECT ALL' option.
  //
  // If any item is selected, this option will 'SELECT ALL' items.
  // However if all items are selected, this option will 'UNSELECT ALL' of them.
  // When all are unselected, we select them all.
  handleAll() {
    const items = this.getItems();
    const allItems = parseIDs(items);
    const selectedItems = R.values(items).filter(item => this.getChecked(item));
    let selected = [];
    // Only select all if any item is selected (but not all of them)
    // or if none is selected.
    if ((selectedItems.length > 0 &&
         selectedItems.length !== allItems.length) ||
        selectedItems.length === 0) {
      selected = allItems;
    }
    // Else use the empty [] default (unselect all):
    this.setState({ selected, modified: true });
  }
  buildMenuItem(primaryTextIcon, item) {
    return buildFilterMenuItem(this.getChecked(item), primaryTextIcon, item);
  }
  render() {
    const items = this.getItems();
    if (!items) {
      // Wait until data is loaded before rendering.
      return null;
    }
    const { demotedItems, promotedItems, showPromoted } = this.state;
    const allItemValues = items;

    return (
      <div>
        <FilterDropDown
          isValueSelected={!R.isEmpty(this.state.selected)}
          maxHeight={filterDropDownStyles.maxHeight}
          onChange={this.filterChange}
          onClose={this.filterClose}
          selectionRenderer={this.selectionRenderer}
          value={this.state.selected}
        >
          <MenuItem
            key="all"
            primaryText="SELECT ALL"
            onClick={this.handleAll}
            style={filterDropDownStyles.selectAll}
          />
          {/* When the dropdown is opened, it must show the selected options in a promoted place: */}
          {showPromoted && promotedItems.map(item => this.buildMenuItem(this.getPrimaryTextIcon, item))}
          {showPromoted && !R.isEmpty(promotedItems) && !R.isEmpty(demotedItems) && <Divider />}
          {showPromoted && demotedItems.map(item => this.buildMenuItem(this.getPrimaryTextIcon, item))}

          {/* When working normally, show all value without putting them on the promotion zone: */}
          {!showPromoted && allItemValues.map(item => this.buildMenuItem(this.getPrimaryTextIcon, item))}
        </FilterDropDown>
      </div>
    );
  }
}

BaseDropDownFilter.propTypes = {
  filter: PropTypes.string,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  name: PropTypes.string
};

export const baseMapStateToProps = (state, { type, name, target }) => {
  const items = state.dataTypes[type];
  const filter = state[target].filters[name];
  return { name, items, filter };
};
