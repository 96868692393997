import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearLogs, fetchLogsData } from '@actions/logs-actions';
import { getDashboardBackgroundColor } from '@constants/config';
import LogItem from '@shared/comments/log-item';
import DotmapsLoader from '@shared/dotmaps-loader';
import './body.scss';

class List extends Component {
  UNSAFE_componentWillMount() {
    this.initialLoad(this.props.activity);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reload || nextProps.activity.id !== this.props.activity.id) {
      this.initialLoad(nextProps.activity);
    } else {
      // 'scrollTop' will only be set when we reach the containers end, so if it changes
      // load the next batch of log entries:
      const { activity, loading, next, scrollTop } = nextProps;
      if (activity && !loading && next && scrollTop !== this.props.scrollTop) {
        this.props.fetchLogsData('workflow', 'taskactivity', activity.id, next, { filters: 'comments_only_filters' });
      }
    }
  }

  initialLoad = activity => {
    this.props.clearLogs();
    this.props.fetchLogsData('workflow', 'taskactivity', activity.id, null, { filters: 'comments_only_filters' });
  };

  render() {
    const { loading, next } = this.props;
    const { items } = this.props;
    return (
      <div styleName="list-container">
        {items.map((item, index) => <LogItem key={index} item={item} />)}
        <DotmapsLoader color={getDashboardBackgroundColor()} display={loading && !next} />
        {/* When we load more through scrolling, show the indicator at the bottom. */}
        {loading && next && (
          <div styleName="spinner">
            <DotmapsLoader color={getDashboardBackgroundColor()} display={loading && next} />
          </div>
        )}
      </div>
    );
  }
}

List.propTypes = {
  activity: PropTypes.object,
  clearLogs: PropTypes.func,
  fetchLogsData: PropTypes.func,
  items: PropTypes.array,
  loading: PropTypes.bool,
  next: PropTypes.string,
  reload: PropTypes.bool,
  scrollTop: PropTypes.number
};

const mapStateToProps = state => {
  const { list: items, loading, next, reload } = state.logs;
  return { items, loading, next, reload };
};

const mapDispatchToProps = { clearLogs, fetchLogsData };

export default connect(mapStateToProps, mapDispatchToProps)(List);
