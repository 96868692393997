import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { getFiltersGlobal } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import { BaseDialog } from '@shared/dialogs';
import DialogCloseButton from '@shared/dialogs/dialog-close-button';
import FormattedDatePicker from '@shared/formatted-date-picker';
import { generateDateFilters, generateSingleDateFilters } from '@utils/date-utils';

class BaseDateRangeDialog extends BaseDialog {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  resetFilters = () => {
    const { end_date__gte, start_date__lte } = getFiltersGlobal();
    this.setState({
      start: moment(end_date__gte).toDate(),
      end: moment(start_date__lte).toDate(),
      contain: false
    });
  };

  getDefaultState = () => {
    const { start, end, contain } = this.props;
    return {
      start: start && moment(start).toDate(),
      end: end && moment(end).toDate(),
      contain
    };
  };

  getFilters = () => {
    if (this.isMultiple()) {
      return generateDateFilters(this.state);
    }
    const { fieldName = 'created' } = this.props.dialogParams || {};
    return generateSingleDateFilters(this.state, fieldName);
  };

  handleStartChange = start => {
    const momentDate = start && moment(start).toDate();
    this.setState({ start: momentDate });
  };

  handleEndChange = end => {
    const momentDate = end && moment(end).toDate();
    this.setState({ end: momentDate });
  };

  toggleContain = () => this.setState({ contain: !this.state.contain });

  // Implement in subclass:
  getDataTypeDisplayName = () => null;

  isMultiple = () => true;

  showContain = () => true;

  getId = prefix => `${prefix}_picker`;

  closeDialog = () => this.props.closeDialog(dialog.DATE_RANGE);

  render() {
    const { start, end, contain } = this.state;
    const { activeDialogs } = this.props;
    if (!activeDialogs[dialog.DATE_RANGE]) {
      return null;
    }
    return (
      <Dialog
        actions={this.getActions()}
        open={activeDialogs[dialog.DATE_RANGE]}
        contentStyle={dialogStyles.smallDialog}
        title="Custom date range"
      >
        <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
          <DialogCloseButton onClose={this.handleCancel} />
          <FormattedDatePicker
            clearable
            hintText="From"
            floatingLabelText="From"
            fullWidth
            value={start}
            id={this.getId('dialog_from')}
            onChange={this.handleStartChange}
            maxDate={end || null}
          />
          <FormattedDatePicker
            clearable
            floatingLabelText="To"
            hintText="To"
            fullWidth
            value={end}
            id={this.getId('dialog_to')}
            onChange={this.handleEndChange}
            minDate={start || null}
          />
          {this.showContain() &&
            <Checkbox
              checked={contain}
              label={`Show only fully contained ${this.getDataTypeDisplayName()}s`}
              labelStyle={{ fontSize: '0.8em' }}
              onCheck={this.toggleContain}
              style={dialogStyles.form.item}
            />
          }
        </MuiPickersUtilsProvider>
      </Dialog>
    );
  }
}

BaseDateRangeDialog.propTypes = {
  contain: PropTypes.bool,
  end: PropTypes.string,
  start: PropTypes.string
};

export default BaseDateRangeDialog;
