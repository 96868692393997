import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Status from './status';
import Response from './response';
import Comments from './comments';
import './content.scss';

const Divider = () => <div styleName="activities-divider" />;

const Content = ({
  activity,
  data,
  error,
  scrollTop,
  viewType
}) => {
  const { task_type } = useSelector(state => state.dataTypes);
  const { task_type: taskTypeId } = data;
  const taskType = task_type[taskTypeId];
  return (
    <div styleName="activities-content">
      <Status activity={activity} task={data} viewType={viewType} />
      <Divider />
      {/* Only show the response section if it's enable the the current task type. */}
      {taskType.response && (
        <Response
          activity={activity}
          error={error}
          task={data}
          taskType={taskType}
          viewType={viewType}
        />
      )}
      {taskType.response && <Divider />}
      <Comments activity={activity} data={data} scrollTop={scrollTop} />
    </div>
  );
};

Content.propTypes = {
  activity: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.object,
  scrollTop: PropTypes.number,
  viewType: PropTypes.string
};

export default memo(Content);
