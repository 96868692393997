import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Paper } from 'material-ui';
import VerticalScrollTabs from '@shared/vertical-scroll-tabs';
import { getTabs } from '@utils/form-utils';
import Form from './form';
import Footer from './footer';
import '../../../forms/forms.scss';

const Drawer = ({ formState }) => {
  const { dataType, id, action } = useParams();
  const tabs = getTabs(dataType)?.filter(tab => !tab.hide_internal);
  return (
    <Paper styleName="drawer-form-container" key="drawer" zDepth={2}>
      {/* id is present, it's an existing entity, show an action/messages
          footer below the tab and forms. */}
      {id && action !== 'duplicate' && (
        <div styleName="edit-form-container">
          <div styleName="tab-form-container">
            <VerticalScrollTabs dataType={dataType} tabs={tabs} />
            <Form formState={formState} />
          </div>
          <Footer />
        </div>
      )}
      {/* else it's the new entity page, render only the tab and forms,
          the save/cancel action will appear on the top-bar. */}
      {(!id || action === 'duplicate') && (
        <Fragment>
          <VerticalScrollTabs dataType={dataType} tabs={tabs} />
          <Form formState={formState} />
        </Fragment>
      )}
    </Paper>
  );
};

Drawer.propTypes = {
  formState: PropTypes.object
};

export default Drawer;
