import React from 'react';
import PropTypes from 'prop-types';
import './details.scss';

const DetailsContent = ({ children }) => (
  <div styleName="details-content-container">
    {children}
  </div>
);

DetailsContent.propTypes = {
  children: PropTypes.element
};

export default DetailsContent;
