import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { filterDropDownStyles } from '@constants/mui-theme';
import FilterDropDown from '@shared/filter-dropdown';
import { BaseFilter } from '@shared/top-filter-bar/base-filter';
import { buildFilterMenuItem } from '@utils/filter-utils';

export class BaseSimpleDropdownFilter extends BaseFilter {
  buildStateFromProps(props) {
    return { selected: props.filter };
  }
  getFilters() {
    return this.state.selected;
  }
  filterChange(event, key, value) {
    this.setState({ selected: value });
  }
  getChecked(item) {
    return this.state.selected === item.id;
  }
  getMultiple = () => false;
  render() {
    const { items, overrideStyles } = this.props;
    return (
      <div>
        <FilterDropDown
          isValueSelected={!R.isEmpty(this.state.selected)}
          maxHeight={filterDropDownStyles.maxHeight}
          multiple={this.getMultiple()}
          onChange={this.filterChange}
          onClose={this.filterClose}
          selectionRenderer={this.selectionRenderer}
          value={this.state.selected}
          {...overrideStyles}
        >
          {items.map(item => buildFilterMenuItem(this.getChecked(item), null, item))}
        </FilterDropDown>
      </div>
    );
  }
}

BaseSimpleDropdownFilter.propTypes = {
  // You can specify which dropdown style properties to override.
  overrideStyles: PropTypes.object
};
