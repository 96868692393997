import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DataTypesSelect from '@forms/data-types-select';
import Row from '@components/workflow/cycle/content/section/view-field-row';
import { renderUserListItem } from '@utils/autocomplete-utils';
import { getTeamUsers } from '@utils/workflow-utils';
import '../inline-edit-field.scss';

const InlineAssigneeField = ({
  commonProps,
  data,
  hasErrors,
  label,
  onSelectChange
}) => {
  const { user: users } = useSelector(state => state.dataTypes);

  // Display users from the selected team only:
  const teamUsers = getTeamUsers(data, users);

  const renderUser = useCallback(
    item => renderUserListItem({ value: item }, teamUsers, 32),
    [teamUsers]
  );

  if (!users || Object.values(users).length === 0) {
    // Don't render this control until users are loaded.
    // (This fixes a race condition where we are still waiting for the users
    // to load and this field is rendered, causing a loop and browser crash).
    return null;
  }

  const newCommonProps = { ...commonProps };
  let newHasErrors = hasErrors;

  // Add error when the selected teams has no users:
  if (Object.values(teamUsers).length === 1) {
    // eslint-disable-next-line quotes
    newCommonProps.errorText = "Cannot select assignee, the task's assigned teams have no users.";
    newCommonProps.className = 'inline-edit-select inline-edit-select-error';
    newHasErrors = true;
  }

  const controlWrapper = (
    <div styleName="control-wrapper">
      <DataTypesSelect
        customItemFormatter={renderUser}
        {...newCommonProps}
        labelStyle={{
          // Fixes vertical centering when rendering user cards:
          display: 'flex'
        }}
        dataSource={teamUsers}
        onChange={onSelectChange}
      />
    </div>
  );

  const rowStyles = {
    padding: newHasErrors ? '0 0 1.25rem 0' : '0 0 0.5rem 0'
  };

  return <Row label={label} value={controlWrapper} labelStyle={{}} style={rowStyles} />;
};

InlineAssigneeField.propTypes = {
  commonProps: PropTypes.object,
  data: PropTypes.object,
  hasErrors: PropTypes.bool,
  label: PropTypes.string,
  onSelectChange: PropTypes.func
};

export default InlineAssigneeField;
