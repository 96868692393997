import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './body.scss';

const Subheader = ({ activity, data }) => {
  const { agency: agencies } = useSelector(state => state.dataTypes);
  return (
    <div styleName="subheader-container">
      Shared between&nbsp;
      <div styleName="bold-text">
        {agencies[activity.agency].name}
      </div>
      &nbsp;and&nbsp;
      <div styleName="bold-text">
        {data.owner_agency_name || 'None'}
      </div>
    </div>
  );
};

Subheader.propTypes = {
  activity: PropTypes.object,
  data: PropTypes.object
};

export default Subheader;
