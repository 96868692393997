import React from 'react';
import PropTypes from 'prop-types';
import { isWorkflowEnabled } from '@constants/config';
import DetailsTableBlock from '@shared/details-table-block';
import DetailsTableCyclesList from '@shared/details-table-cycles-list';
import DetailsTableGroupsList from '@shared/details-table-groups-list';
import DetailsTableLocationsList from '@shared/details-table-locations-list';
import DetailsTableOwnersList from '@shared/details-table-owners-list';
import './details-tab.scss';

const getTrayRowData = (entity, block, row) => {
  if (row.is_category && row.key && entity.category_dict) {
    const categoryEntries = entity.category_dict.filter(category => category.name === row.key);
    if (categoryEntries) {
      if (categoryEntries.length === 1) {
        return categoryEntries[0].value;
      }
      return categoryEntries.map(categoryEntry => categoryEntry.value).join(', ');
    }
  }
  const entityData = block.key ? entity[block.key] : entity;
  if (entityData) {
    return entityData[row.key];
  }
  return null;
};

const DetailsTab = ({ detailsTrayConfig, entity }) => (
  <div styleName="details-tab-container">
    <div styleName="details-tab-body">
      {detailsTrayConfig.map((block, id) => {
        const data = block.data.map(row => ({
          label: row.label,
          value: getTrayRowData(entity, block, row),
          showIfEmpty: row.showIfEmpty,
          type: row.type || null
        }));
        if (data[0].type === 'cycles' && isWorkflowEnabled()) {
          return <DetailsTableCyclesList key={id} entity={entity} />;
        }
        if (data[0].type === 'groups') {
          return <DetailsTableGroupsList key={id} entity={entity} />;
        }
        if (data[0].type === 'owners') {
          return <DetailsTableOwnersList key={id} entity={entity} />;
        }
        if (data[0].type === 'locations') {
          return <DetailsTableLocationsList key={id} entity={entity} />;
        }
        return <DetailsTableBlock key={id} fields={data} />;
      })}
    </div>
  </div>
);

DetailsTab.propTypes = {
  detailsTrayConfig: PropTypes.array,
  entity: PropTypes.object,
  type: PropTypes.string
};

export default DetailsTab;
