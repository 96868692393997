import { Component } from 'react';
import { connect } from 'react-redux';
import { authTokenLogin } from '@actions/auth-actions';
import PropTypes from 'prop-types';

// This component will authenticate the user with the backend based on a token received
// from the URL parameter.
class TokenAuth extends Component {
  render() {
    const { token, userId, timestamp } = this.props.match.params;
    this.props.authTokenLogin(this.props.location, token, userId, timestamp);
    return null;
  }
}

TokenAuth.propTypes = {
  authTokenLogin: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object
};

export default connect(null, { authTokenLogin })(TokenAuth);
