import { defineMessages } from 'react-intl';

export default defineMessages({
  titleMessage: {
    id: 'dotmaps.components.auth.signup.title',
    defaultMessage: 'Create new account'
  },
  tosMessage: {
    id: 'dotmaps.components.auth.signup.tos',
    defaultMessage: 'Term of Service'
  },
  privacyMessage: {
    id: 'dotmaps.components.auth.signup.privacy',
    defaultMessage: 'Privacy Policy'
  }
});
