/* eslint-disable max-depth */
import * as R from 'ramda';
import { isAdminDataType } from '@constants/endpoints';
import { VIEW_TYPES } from '@constants/workflow';
import { canCreateTasks, canViewWorkflows, getAgencyId, getUserId } from '@utils/permission-utils';
import { findActivity, isOwnerView } from '@utils/workflow-utils';

const getDefaultPortalPath = dataType => {
  if (dataType === 'cycle') {
    return '/workflow/cycle';
  }
  if (isAdminDataType(dataType)) {
    return `/admin/data/${dataType}`;
  }
  return `/list/data/${dataType}`;
};

// This method builds an URL to return to, when the back arrow button is clicked on the task detail page.
//
// Usually the previous page is stored in the 'state' property of the 'location' object, that always
// takes precedence and is used for the back button action.
//
// However sometimes there's no previous navigation (i.e. if the user clicks on an email link), in that
// case we must build the return URL based on the current URL.
export const getDefaultWorkflowBackURL = (task, activityId, viewType) => {
  // If 'viewType' is defined, we can return to an 'Assigned to' listing page.
  //
  // These two view types means that the user probably came from those pages, also those two pages are
  // always available if we have the workflow functionality enabled.
  if (viewType === VIEW_TYPES.agency) {
    return '/workflow/activity/agency';
  }
  if (viewType === VIEW_TYPES.assignee) {
    return '/workflow/activity/assignee';
  }

  // For the 'owner' view type (or when there's no view type), there's no way to know from which page
  // he came, we must detect it.
  if (isOwnerView(viewType)) {
    const agencyId = getAgencyId();
    const userId = getUserId();

    // To go to any 'Owned by' page, check first if we have permissions:
    if (task && canCreateTasks()) {
      // If the user is the owner of the task, we can safely return him to the 'Owned by me' page.
      if (task.owner && task.owner === userId) {
        return '/workflow/task/owner';
      }
      // Else if the user's agency is the owner of the task, go to the 'Owned by my agency' page.
      if (task.owner_agency_id && task.owner_agency_id === agencyId) {
        return '/workflow/task/agency';
      }
    }

    // Else check for 'Assigned to' pages:
    if (task && task.activities && task.activities.length > 0) {
      let activity = null;
      if (activityId) {
        activity = findActivity(activityId, task.activities);
      } else {
        activity = task.activities[0];
      }
      if (activity) {
        // We are on an activity page that is assigned to me:
        if (activity.assignee === userId) {
          return '/workflow/activity/assignee';
        }
        // Else check if the activity is assigned to my agency:
        if (activity.agency === agencyId) {
          return '/workflow/activity/agency';
        }
      }
    }

    // Else default to the cycle listing page, but only if we have permissions to that page:
    if (canViewWorkflows()) {
      return '/workflow/cycle';
    }
  }

  // We should always detect the page, since we should have handled all possible cases above, but if for some
  // reason, that's not possible, return to the map.
  return '/map';
};

// Returns an URL to return (that can be used on a nav-bar back button
// or as an action, i.e. after deleting an entity).
//
// We should return to the page from which we reached the current location,
// and if such data is no available (because the URL was pasted manually,
// was clicked from an email link, or opened in a new window), default
// it to the library listing page for the specified data type).
export const getDashboardBackURL = (location, dataType, custom = null) => {
  const source = R.path(['state', 'source'], location);
  const defaultPortalPath = getDefaultPortalPath(dataType);
  return source || custom || defaultPortalPath;
};

// Returns the URL for the overlap activity log page, based on the specified
// location, which is a location of an entity activity log page.
export const getOverlapActivityLogURL = location => {
  const pathname = R.propOr(null, ['pathname'], location);
  if (pathname) {
    const idSlice = R.slice(4, 5);
    const id = parseInt(R.pipe(R.split('/'), idSlice)(pathname), 10);
    return `/logs/overlap/conflict/${id}`;
  }
  return null;
};

const getDataId = pathname => {
  const idSlice = R.slice(2, 3);
  return parseInt(R.pipe(R.split('/'), idSlice)(pathname), 10);
};

const getDataType = pathname => {
  const dataTypeSlice = R.slice(1, 2);
  return R.pipe(R.split('/'), dataTypeSlice, R.join('-'))(pathname);
};

// Location changes should clear dashboard details pages, so requesting
// the /<entity-name>/1234 page after visiting /<entity-name>/1111 doesn't display's
// 1111's data until 1234 data is loaded, else while we wait for the fetch request
// to finish, we'll be displaying old data, which after fetch success will change
// to the new data.
//
// So, it's better to clear the data, so the user only see a spinning image until the new data loads.
//
// However, if we are navigating within the same entity type, i.e. by selecting
// permits or opening them into the tray, like /conflict/1234?focus=2222&type=permit&open=2222
// we shouldn't clear the redux store data, since we are still displaying the same data.
export const shouldClearData = (state, pathname) => {
  const dataId = R.pathOr(null, ['data', 'id'], state);
  const dataType = R.pathOr(null, ['dataType'], state);

  if (!dataId && !dataType) {
    return false;  // No data was set, thus no need to clear.
  }

  // Get the id and dataType from the new URL.
  // (i.e. from '/project/1234', get '1234' and 'project').
  const urlId = getDataId(pathname);
  const urlDataType = getDataType(pathname);

  // If the URL is a cycle one (i.e. '/cycle/1234'), it would be just '/cycle/1234',
  // or it will be a task URL '/cycle/1234/task/5678' or an activity one
  // '/cycle/1234/task/5678/activity/{type}/910'.
  //
  // Thus it will pass the above test, but we must actually check if the task
  // id is different.
  if (urlDataType === 'cycle') {
    const newPath = pathname.replace(`/cycle/${urlId}`, '');
    return shouldClearData(state, newPath);
  }

  return dataType !== urlDataType || dataId !== urlId;
};

// Simulate CTRL-CLICK behavior on links (open link in a new tab).
// (works on Chrome and Firefox).
export const openInNewTab = url => {
  const win = window.open(url);
  win.focus();
};
