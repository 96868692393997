import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import ActionButton from '@shared/action-button';
import { NotImplemented } from '@utils/shared-utils';

// Base class for dialogs
export class BaseDialog extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
  }

  getDefaultState = () => ({});

  // Implement this on the real dialog class.
  getFilters = () => ({});

  // Implement in subclass:
  getSetFiltersFunction = () => NotImplemented();

  closeDialog = () => NotImplemented();

  handleCancel = () => {
    this.setState(this.getDefaultState());
    this.closeDialog();
  };

  handleApply = () => {
    this.getSetFiltersFunction()(this.getFilters());
    this.closeDialog();
  };

  getActions = () => ([
    <FlatButton key="cancel" label="Cancel" onClick={this.handleCancel} />,
    <ActionButton
      key="apply"
      label="Apply"
      primary
      onClick={this.handleApply}
    />
  ]);

  render() {
    // Just return a dummy dialog, since implementation classes
    // must implement the real dialog, this is used for tests only.
    const { activeDialogs } = this.props;
    return (
      <Dialog actions={this.getActions()} open={activeDialogs.TEST}>
        Lorem Ipsum
      </Dialog>
    );
  }
}

BaseDialog.propTypes = {
  activeDialogs: PropTypes.object,
  closeDialog: PropTypes.func,
  dialogParams: PropTypes.object,
  setFilter: PropTypes.func
};
