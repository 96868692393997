import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import { entityURLFieldBuilder } from '@utils/entity-utils';
import './fields-link-button.scss';

class FieldsLinkButton extends Component {
  getUrl = () => {
    const { entity, link: { url_fields, url } } = this.props;
    if (url_fields) {
      return entityURLFieldBuilder(url, entity, url_fields);
    }
    return url;
  };

  render() {
    const { link: { icon, label } } = this.props;
    const url = this.getUrl();
    return (
      <a href={url} rel="noopener noreferrer" target="_blank" styleName="fields-link-button-link">
        <div styleName="button-box">
          <FontIcon styleName="icon" className="material-icons">{icon}</FontIcon>
          {label}
        </div>
      </a>
    );
  }
}

FieldsLinkButton.propTypes = {
  entity: PropTypes.object,
  link: PropTypes.object
};

export default FieldsLinkButton;
