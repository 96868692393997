import React from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import {
  BaseTray,
  baseTrayMapDispatchToProps
} from '@components/map/tray/base-tray';
import DateFilter from '@components/map/tray/filter/date-filter';
import EntityFilter from '@components/map/tray/filter/entity-filter';
import GroupsFilter from '@components/map/tray/filter/groups-filter';
import LayersFilter from '@components/map/tray/filter/layers-filter';
import { mapTabsConfig } from '@constants/component-configs';
import { getConfig } from '@utils/config-utils';
import '../tray.scss';

const components = {
  date: DateFilter,
  entity: EntityFilter,
  group: GroupsFilter,
  layer: LayersFilter
};

class Filter extends BaseTray {
  render() {
    const { entityTypes, isFilterTab } = this.props;
    if (!isFilterTab) {
      return null;
    }
    const { filters } = getConfig().filters;
    return (
      <div styleName="map-tray-body-wrapper">
        <div styleName="map-tray-body map-tray-body-filter">
          {/* extra enclosing DIV required for scrollbars. */}
          <div>
            {filters.map(filter => {
              const FilterComponent = components[filter.name];
              if (filter.name === 'entity') {
                // Filters defined as 'entity' must exists not only as
                // filters config, but as an entity type.
                if (!includes(entityTypes, filter.type)) {
                  // A filter for an entity type was defined,
                  // but the entity type no longer exists or was renamed,
                  // thus don't render a filter for it:
                  return null;
                }
              }
              if (FilterComponent) {
                // eslint-disable-next-line react/jsx-key
                return <FilterComponent key={`${filter.name}-${filter.type}`} config={{...filter}} />;
              }
              return null;
            })}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const isFilterTab = state.map.currentTab === mapTabsConfig.tabs.filters.value;
  const { entities: { types } } = state;
  return { isFilterTab, entityTypes: Object.keys(types) };
};

export default connect(mapStateToProps, baseTrayMapDispatchToProps)(Filter);
