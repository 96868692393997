/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  errorDataField,
  updateDataField
} from '@actions/data-detail-actions';
import { getEntityTypeLabel, getOwnersAllowAllUsers } from '@constants/config';
import { detailEdit } from '@constants/mui-theme';
import DateRange from '@forms/date-range';
import FormElement from '@forms/form-element';
import InputDataTypeSearch from '@forms/input-datatype-search';
import SegmentList from '@forms/segment-list';
import Alert from '@material-ui/lab/Alert';
import {
  isFieldVisible
} from '@utils/form-utils';
import ScheduleWarning from './schedule-warning';
import '../../../../../../forms/forms.scss';

const FormField = ({
  errors,
  fieldName,
  formCallbacks,
  formState,
  metadata,
  readOnly,
  tab,
  visibleFields
}) => {
  const { data } = useSelector(state => state.dataDetail);
  const { dataType } = useParams();
  const { map_category_type: categoryTypes, map_type: entityTypes } = useSelector(state => state.dataTypes);
  const dispatch = useDispatch();
  const onChange = (...args) => dispatch(updateDataField(...args));
  const onError = (...args) => dispatch(errorDataField(...args));
  if (isFieldVisible(fieldName, visibleFields)) {
    const dataTypeDisplayName = getEntityTypeLabel(dataType);
    switch (fieldName) {
    case '_date_warning':
      return <ScheduleWarning data={data} dataType={dataType} />;
    // Render a textarea with a configured text.
    case '_textarea':
      return (
        <div
          style={detailEdit.columnStyles.col100}
          styleName="col100 textarea"
          dangerouslySetInnerHTML={{__html: tab._textarea_data}}
        />
      );
    // Render an info message.
    case '_info_message':
      return (
        <div style={detailEdit.columnStyles.col100} styleName="col100">
          <Alert severity="info">
            <div dangerouslySetInnerHTML={{__html: tab._info_message}} />
          </Alert>
        </div>
      );
    case 'owners': {
      const { max_owners: maxOwners } = Object.values(entityTypes).find(type => type.name === dataType) || {};
      // Use a space ' ' as the label if there's data, to make the TextField component
      // think that there's a label, so it still reserves a 16px padding at the top.
      const label = data[fieldName] ? metadata[fieldName].defaultLabel || ' ' : 'Search users';

      let filterFunc = item => data.agency === item.agency;
      if (getOwnersAllowAllUsers()) {
        filterFunc = null;
      }

      return (
        <InputDataTypeSearch
          agency={data.agency}
          dataType="user"
          disabled={metadata[fieldName].read_only || readOnly}
          errors={errors.owners || null}
          fieldName={fieldName}
          filterFunc={filterFunc}
          onChange={(event, newValues) => {
            if (Array.isArray(newValues)) {
              const values = newValues.map(value => value.value);
              onChange(fieldName, values);
            } else {
              onChange(fieldName, [newValues.value]);
            }
          }}
          label={label}
          limit={maxOwners}
          values={data[fieldName] || []}
        />
      );
    }
    case 'segments':
      return (
        <div key={fieldName}>
          <SegmentList
            data={data}
            dataType={dataType}
            formCallbacks={formCallbacks}
            formState={formState}
            style={detailEdit.columnStyles.col100}
            readOnly={metadata[fieldName].read_only || readOnly}
            errors={errors.segments || null}
          />
        </div>
      );
    case '_date_range':
      return (
        <DateRange
          data={data}
          key={fieldName}
          fieldNames={tab.date_range_fields}
          metaData={metadata}
          onChange={onChange}
          onError={onError}
          dataType={dataTypeDisplayName}
          readOnly={readOnly}
          errors={errors || null}
        />
      );
    default:
      return (
        <FormElement
          data={data}
          dataType={dataTypeDisplayName}
          key={fieldName}
          fieldName={fieldName}
          fieldMeta={metadata[fieldName]}
          onChange={onChange}
          onError={onError}
          value={data[fieldName]}
          readOnly={readOnly}
          templateProps={metadata[fieldName] || {}}
          errors={errors[fieldName] || null}
          categoryTypes={categoryTypes}
        />
      );
    } // end switch
  } // end if
  return null;
};

FormField.propTypes = {
  errors: PropTypes.object,
  fieldName: PropTypes.string,
  formCallbacks: PropTypes.object,
  formState: PropTypes.object,
  metadata: PropTypes.object,
  readOnly: PropTypes.bool,
  tab: PropTypes.object,
  visibleFields: PropTypes.array
};

export default FormField;
