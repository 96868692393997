import React, {Component} from 'react';
import PropTypes from 'prop-types';
import transitions from 'material-ui/styles/transitions';

function getStyles(props, context) {
  const {
    open
  } = props;

  const {
    muiTheme: {
      baseTheme: {
        spacing: {
          desktopSubheaderHeight  // eslint-disable-line no-unused-vars
        }
      },
      zIndex  // eslint-disable-line no-unused-vars
    }
  } = context;

  const styles = {
    root: {
      backgroundColor: '#ffffff',
      boxShadow: '0 0 8px 2px rgba(0, 0, 0, 0.4)',
      bottom: 0,
      display: open ? 'flex' : 'none',
      left: 0,
      height: 200,
      position: 'fixed',
      transition: transitions.easeOut('0ms', 'opacity'),
      width: '100%',
      zIndex: 24
    }
  };

  return styles;
}

class HorizontalDrawerContent extends Component {
  render() {
    // Only render the content, if the drawer is open.
    if (!this.props.open) {
      return null;
    }
    const { prepareStyles } = this.context.muiTheme;
    const styles = getStyles(this.props, this.context);
    return (
      <div style={prepareStyles(styles.root)}>
        {this.props.children}
      </div>
    );
  }
}

HorizontalDrawerContent.propTypes = {
  children: PropTypes.element,
  /**
   * @ignore
   * Controls whether the `Drawer` is opened or not.
   */
  open: PropTypes.bool.isRequired
};


HorizontalDrawerContent.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default HorizontalDrawerContent;
