import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { VIEW_TYPES } from '@constants/workflow';
import { findActivity, getViewTypeOrGenerateForURL } from '@utils/workflow-utils';
import List from './list';
import Content from './content';
import './activities-tab.scss';

const ActivitiesTab = ({
  cycle,
  data,
  error,
  scrollTop,
  taskActivityId,
  viewType
}) => {
  const dispatch = useDispatch();

  const onSelectActivity = useCallback(
    activity => {
      const { id, task } = activity;
      const generatedViewType = getViewTypeOrGenerateForURL(viewType || VIEW_TYPES.owner, data, activity);
      dispatch(push(`/cycle/${cycle.id}/task/${task}/activity/${id}${generatedViewType}`));
    },
    [cycle.id, data, dispatch, viewType]
  );

  const getActivities = () => {
    if (data && data.activities && data.activities.length > 0) {
      return data.activities;
    }
    return null;
  };

  const getSelectedActivity = () => {
    // If an activity is selected from the URL path, use that one:
    if (taskActivityId) {
      const activity = findActivity(taskActivityId, data.activities);
      if (activity) {
        return activity;
      }
    }

    // Else select the first activity:
    const activities = getActivities();
    if (activities) {
      return activities[0];
    }
    return null;
  };

  const activity = getSelectedActivity();
  if (!activity) {
    return null;
  }

  return (
    <div styleName="activities-tab-container">
      <List activity={activity} data={data} onClick={onSelectActivity} />
      <Content activity={activity} data={data} error={error} scrollTop={scrollTop} viewType={viewType} />
    </div>
  );
};

ActivitiesTab.propTypes = {
  cycle: PropTypes.object,
  data: PropTypes.object,
  error: PropTypes.object,
  push: PropTypes.func,
  scrollTop: PropTypes.number,
  taskActivityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  viewType: PropTypes.string
};

export default ActivitiesTab;
