import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TextField from 'material-ui/TextField';
import messages from './messages';
import { authSignUp } from '@actions/auth-actions';
import { loginStyles } from '@constants/mui-theme';
import ActionButton from '@shared/action-button';
import '../layouts/not-authenticated/not-authenticated.scss';

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      user: {
        email: '',
        fullname: '',
        password: '',
        confirmPassword: ''
      }
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(event) {
    const field = event.target.name;
    const user = this.state.user;
    user[field] = event.target.value;
    this.setState({user});
  }
  onSubmit(event) {
    event.preventDefault();
    this.props.authSignUp(this.state.user);
  }
  render() {
    const {errors, user} = this.state;
    return (
      <div>
        <div styleName="not-authenticated-container">
          <form action="/" onSubmit={this.onSubmit}>
            <div styleName="auth-heading"><FormattedMessage {...messages.titleMessage} /></div>
            <div styleName="not-authenticated-form-text-field">
              <TextField
                fullWidth
                floatingLabelText="Email"
                name="email"
                errorText={errors.email}
                onChange={this.onChange}
                value={user.email}
              />
            </div>
            <div styleName="not-authenticated-form-text-field">
              <TextField
                fullWidth
                floatingLabelText="Full name"
                name="fullname"
                errorText={errors.fullname}
                onChange={this.onChange}
                value={user.fullname}
              />
            </div>
            <div styleName="not-authenticated-form-text-field">
              <TextField
                fullWidth
                floatingLabelText="Create password"
                type="password"
                name="password"
                onChange={this.onChange}
                errorText={errors.password}
                value={user.password}
              />
            </div>
            <div styleName="not-authenticated-form-text-field">
              <TextField
                fullWidth
                floatingLabelText="Confirm password"
                type="password"
                name="confirmPassword"
                onChange={this.onChange}
                errorText={errors.confirmPassword}
                value={user.confirmPassword}
              />
            </div>
            <div styleName="auth-section">
              By clicking Confirm, I agree to the
              <br/>
              <Link to={'/tos'}><FormattedMessage {...messages.tosMessage} /></Link>
              &nbsp;and&nbsp;
              <Link to={'/privacy'}><FormattedMessage {...messages.privacyMessage} /></Link>.
            </div>
            <div styleName="not-authenticated-form-element">
              <ActionButton
                fullWidth
                {...loginStyles.loginButton}
                type="submit"
                label="Confirm"
              />
            </div>
          </form>
          <div styleName="auth-section">
            Already have an account?<br /><Link to={'/login'}>Login</Link>.
          </div>
        </div>
      </div>
    );
  }
}

SignUp.propTypes = {
  authSignUp: PropTypes.func,
  errors: PropTypes.object,
  user: PropTypes.object
};

export default connect(null, { authSignUp })(SignUp);

export { SignUp as PureSignUp };  // Export the unconnected component (for unit tests).
