import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import { overlayTextColor } from '@constants/colors';
import LoaderIconWrapper from '@shared/loader-icon-wrapper';
import './file-preview.scss';

class FilePreviewHeader extends Component {
  printAsset = () => {
    const printWindow = window.open(this.props.assetUrl);
    printWindow.print();
  };
  render() {
    return (
      <div styleName="file-preview-header">
        <div>{this.props.fileName}</div>
        <div>{this.props.current} / {this.props.total}</div>
        <div>
          <IconButton onClick={this.printAsset}>
            <FontIcon className="material-icons" color={overlayTextColor}>
              print
            </FontIcon>
          </IconButton>
          <LoaderIconWrapper>
            <a href={this.props.downloadUrl} download>
              <IconButton>
                <FontIcon className="material-icons" color={overlayTextColor}>
                  file_download
                </FontIcon>
              </IconButton>
            </a>
          </LoaderIconWrapper>
          <IconButton onClick={this.props.closePreview}>
            <FontIcon className="material-icons" color={overlayTextColor}>
              close
            </FontIcon>
          </IconButton>
        </div>
      </div>
    );
  }
}

FilePreviewHeader.propTypes = {
  assetUrl: PropTypes.string,
  closePreview: PropTypes.func,
  current: PropTypes.number,
  downloadUrl: PropTypes.string,
  fileName: PropTypes.string,
  total: PropTypes.number
};

export default FilePreviewHeader;
