/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import Avatar from 'material-ui/Avatar';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import Popover from '@material-ui/core/Popover';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import Badge from '@material-ui/core/Badge';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import * as colors from '@constants/colors';
import {
  getEntitiesListConfig,
  getEntityTypeLabel
} from '@constants/config';
import {
  canAccessEntity,
  isReadOnly
} from '@utils/permission-utils';
import { topBarStyles } from '@constants/mui-theme';
import LogoutIcon from '@icons/header/logout-icon';
import SettingsIcon from '@icons/header/settings-icon';
import NotificationsList from '@shared/header/notifications-list';
import { getUserAvatar } from '@utils/data-table/user';
import { openHelpPage } from '@utils/shared-utils';
import { withRouter } from '@utils/router-utils';
import AddIcon from 'material-ui/svg-icons/content/add';
import EntityTypeIcon from '@icons/entity-type-icon';
import styles from './action-items.scss';

const CustomBadge = withStyles({
  root: {
    color: topBarStyles.icons.color,
    '& .MuiBadge-badge': {
      backgroundColor: colors.dotmapsBadgeRed
    }
  }
})(Badge);

const ActionItems = ({ notificationsCount, user }) => {
  const dispatch = useDispatch();

  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [userOpen, setUserOpen] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const openNotifications = event => {
    setNotificationsOpen(true);
    setNotificationsAnchorEl(event.currentTarget);
  };

  const closeNotifications = () => setNotificationsOpen(false);

  const openUser = event => {
    setUserOpen(true);
    setUserAnchorEl(event.currentTarget);
  };

  const closeUser = () => setUserOpen(false);

  const goTo = pathname => {
    const source = window.location.pathname;
    closeUser();
    dispatch(push({ pathname, state: { source } }));
  };

  const goToSettings = () => goTo('/settings/profile');

  const goToLogout = () => goTo('/logout');

  const goToAdd = dataType => goTo(`/${dataType}/new`);

  const getAllowedEntitiesList = () => {
    const allowed = [];
    getEntitiesListConfig().forEach(entity => {
      if (canAccessEntity(null, entity, 'add') && !isReadOnly(entity)) {
        allowed.push(entity);
      }
    });
    return allowed;
  };

  const renderAddButton = () => {
    const allowed = getAllowedEntitiesList();
    if (allowed.length > 0) {
      return (
        <IconMenu
          listStyle={topBarStyles.addMenu}
          iconButtonElement={
            <IconButton style={topBarStyles.addIcon}>
              <AddIcon color={colors.overlayTextColor} />
            </IconButton>
          }
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {allowed.map(entity => (
            <MenuItem
              key={entity}
              leftIcon={<EntityTypeIcon type={entity} color={topBarStyles.icons.color} />}
              onClick={() => goToAdd(entity)}
              primaryText={`New ${getEntityTypeLabel(entity)}`}
            />
          ))}
        </IconMenu>
      );
    }
    return null;
  };

  if (!user) {
    return null;
  }
  const userAvatarIcon = getUserAvatar(user, 40, colors.dotmapsBlack20);

  return (
    <Toolbar style={topBarStyles.toolbar}>
      <ToolbarGroup>
        <IconButton onClick={openNotifications}>
          <CustomBadge
            badgeContent={notificationsCount}
            color="secondary"
            max={9}
          >
            <NotificationsIcon />
          </CustomBadge>
        </IconButton>
        <Popover
          open={notificationsOpen}
          anchorEl={notificationsAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={closeNotifications}
          classes={{paper: styles['notifications-list-container']}}
        >
          <NotificationsList visible={notificationsOpen} />
        </Popover>
        <IconButton onClick={openHelpPage}>
          <FontIcon className="material-icons" color={topBarStyles.icons.color}>help</FontIcon>
        </IconButton>
        {renderAddButton()}
        <Avatar
          onClick={openUser}
          size={32}
        >{user && user.name && user.name.length > 0 ? user.name[0].toUpperCase() : ''}</Avatar>
        <Popover
          open={userOpen}
          anchorEl={userAnchorEl}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={closeUser}
        >
          <div className={styles['action-items-container']} >
            <div className={styles['user-card']} >
              <div className={styles.avatar} >{userAvatarIcon}</div>
              <div className={styles.content} >
                <div className={styles.name} >{user.name}</div>
                <div className={styles.email} >{user.email}</div>
                <div>{user.permission}</div>
              </div>
            </div>
            <div className={styles.divider} />
            <div className={styles.list} >
              <div className={styles['list-item']} >
                <div className={styles.icon} >
                  <SettingsIcon />
                </div>
                <div className={styles['link']} onClick={goToSettings} role="presentation">Settings</div>
              </div>
              <div className={styles['list-item']}>
                <div className={styles.icon}>
                  <LogoutIcon />
                </div>
                <div className={styles['link']} onClick={goToLogout} role="presentation">Logout</div>
              </div>
            </div>
          </div>
        </Popover>
      </ToolbarGroup>
    </Toolbar>
  );
};


ActionItems.propTypes = {
  notificationsCount: PropTypes.number,
  push: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = state => {
  const { config: { user } } = state;
  const { unread } = state.notifications;
  return { notificationsCount: unread, user };
};

export default connect(mapStateToProps, {})(withRouter(ActionItems));
