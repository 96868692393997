/* eslint-disable react/display-name */
import React, { Fragment, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import TrayHeader from '@components/map/tray/header/tray-header';
import AddToGroupForm from '@shared/add-to-group';
import DotmapsLoader from '@shared/dotmaps-loader';
import AddToGroupTrayHeader from './add-to-group-tray-header';
import AddToGroupTrayItem from './add-to-group-tray-item';

import { prepareGroupsForBulkAdd } from '@utils/group-utils';
import { canEditGroups } from '@utils/permission-utils';

import { isAnyMapItemLoading } from '@selectors/map-selector';
import { groupListById } from '@selectors/groups-selector';
import { getConfig } from '@components/config/selectors';
import { getAddToGroupTrayData, getOverlapTrayItemsWithEntityDetails } from '../selectors';

import {
  createNewGroupWithEntities,
  createNewGroupWithGeometry,
  addToGroups
} from '@actions/groups-actions';

import { dotmapsGreen } from '@constants/colors';
import '../tray.scss';

const AddToGroupTray = () => {
  const dispatch = useDispatch();

  const loading = useSelector(isAnyMapItemLoading);
  const preFillGroup = useSelector(state => state?.groups?.preFill || null);
  const {shape = null} = useSelector(getAddToGroupTrayData);
  const {entities: entitiesByType = {}} = useSelector(getOverlapTrayItemsWithEntityDetails);
  const {entity_types: entityTypes, group_types: groupTypes} = useSelector(getConfig);
  const groupsById = useSelector(groupListById);

  const previousEntities = useRef(null);
  const previousPreFillGroup = useRef(null);

  const hasEntities = useMemo(() => {
    return Object.values(entitiesByType).some(items => items.length > 0);
  }, [entitiesByType]);
  const editableGroupTypes = useMemo(() => (
    groupTypes.filter(groupType => canEditGroups(groupType.name))
  ), [groupTypes]);
  const [selectedEntityIds, setSelectedEntityIds] = useState({});
  const [selectedGroupTypeId, setSelectedGroupTypeId] = useState(editableGroupTypes.length > 0 ? editableGroupTypes[0].id : null);
  const [selectedGroupIds, setSelectedGroupIds] = useState([]);

  const selectedEntityIdList = useMemo(
    () => (
      Object.entries(selectedEntityIds)
        .filter(([, selected]) => selected)
        .map(([id]) => Number(id))
    ),
    [selectedEntityIds]
  );
  
  const selectedEntityIdsByType = useMemo(() => {
    const entityIdsByType = {};
    Object.entries(entitiesByType).forEach(([typeName, entities]) => {
      const selectedEntities = (
        entities
          .filter(entity => selectedEntityIds[entity.id])
          .map(entity => entity.id)
      );
      if (selectedEntities.length > 0) {
        entityIdsByType[typeName] = selectedEntities;
      }
    });
    return entityIdsByType;
  }, [entitiesByType, selectedEntityIds]);
  const selectedGroupType = useMemo(
    () => editableGroupTypes.find(type => type.id === selectedGroupTypeId),
    [editableGroupTypes, selectedGroupTypeId]
  );
  const areaCapture = useMemo(
    () => selectedGroupType.area_capture > 0,
    [selectedGroupType]
  );
  const shapeAndCapture = useMemo(() => {
    return areaCapture && Boolean(shape);
  }, [shape, areaCapture]);

  const selectionValid = useMemo(() => (
    selectedGroupIds.length > 0 &&
    (shapeAndCapture || selectedEntityIdList.length > 0)
  ), [selectedGroupIds, selectedEntityIdList, shapeAndCapture]);

  const createGroup = useCallback(() => {
    // If a shape is defined and the group type allows or requires a shape,
    // use that geometry to create the new group.
    if (shape && selectedGroupType.area_capture > 0) {
      dispatch(createNewGroupWithGeometry(shape, selectedGroupType.name));
    } else {
      // Else create the new group passing all listed and selected entities:
      dispatch(createNewGroupWithEntities({entities: selectedEntityIdsByType}, selectedGroupType.name, false));
    }
  }, [shape, dispatch, selectedEntityIdsByType, selectedGroupType]);

  const addToGroup = useCallback(() => {
    const payload = prepareGroupsForBulkAdd({selectedGroupIds, entityIds: selectedEntityIdList, includeOverlaps: false});
    dispatch(addToGroups(selectedGroupType.name, payload, null, true));
  }, [dispatch, selectedGroupIds, selectedEntityIdList, selectedGroupType]);

  const updateSelectedEntityIds = useCallback((selections) => {
    setSelectedEntityIds({...selectedEntityIds, ...selections});
  }, [selectedEntityIds, setSelectedEntityIds]);

  if (!previousEntities.current || previousEntities.current !== entitiesByType) {
    const newSelectedEntities = {};
    Object.values(entitiesByType).forEach(entities => entities.forEach(entity => {
      newSelectedEntities[entity.id] = true;
    }));
    setSelectedEntityIds(newSelectedEntities);
    previousEntities.current = entitiesByType;
  }

  if (!previousPreFillGroup.current || previousPreFillGroup.current !== preFillGroup) {
    if (preFillGroup) {
      setSelectedGroupTypeId(preFillGroup.type);
      setSelectedGroupIds([preFillGroup.id]);
    }
    previousPreFillGroup.current = preFillGroup;
  }

  return (
    <div styleName="map-tray-body-wrapper">
      <TrayHeader title="Add to group" />
      <Divider />
      <div styleName="map-tray-form">
        <div styleName="form">
          <AddToGroupForm
            existingGroups={[]}
            groupsById={groupsById}
            groupType={selectedGroupTypeId}
            selectedGroups={selectedGroupIds}
            setGroupType={setSelectedGroupTypeId}
            setSelectedGroups={setSelectedGroupIds}
            showSearch={!shapeAndCapture}
          />
        </div>
        {shapeAndCapture && (
          <div styleName="legend">
            A {selectedGroupType.name} group contains all the records that fall within your map selection and a defined date range.
            Map filters will not be applied to boundary group creation.
          </div>
        )}
        {shapeAndCapture && (
          <div styleName="action">
            <div styleName="left" />
            <div styleName="right">
              <Button
                variant="contained"
                color="primary"
                onClick={createGroup}
                disableElevation
              >
                  CREATE
              </Button>
            </div>
          </div>
        )}
        {!shapeAndCapture && (
          <div styleName="action">
            <div styleName="left">
              {!areaCapture && (
                <Button
                  color="primary"
                  onClick={createGroup}
                >
                  NEW GROUP
                </Button>
              )}
            </div>
            <div styleName="right">
              <Button
                variant="contained"
                color="primary"
                onClick={addToGroup}
                disabled={!selectionValid}
                disableElevation
              >
                ADD
              </Button>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div styleName="map-tray-body map-tray-add-to-group-body">
        {loading && <DotmapsLoader color={dotmapsGreen} display />}
        {!loading && !shapeAndCapture && (
          <Fragment>
            {hasEntities && 
            <ul styleName="list">
              {
                entityTypes.map(({id, name, label}) => {
                  const entities = entitiesByType[name];
                  if (entities && entities.length > 0) {
                    return (
                      <Fragment key={id}>
                        <AddToGroupTrayHeader
                          entities={entities}
                          name={label}
                          onUpdateSelectedEntityIds={updateSelectedEntityIds}
                          selectedEntityIds={selectedEntityIds}
                        />
                        {entities.map(entity => (
                          <AddToGroupTrayItem
                            key={entity.id}
                            entity={entity}
                            onUpdateSelectedEntityIds={updateSelectedEntityIds}
                            checked={selectedEntityIds[entity.id] || false}
                          />
                        ))}
                      </Fragment>
                    );
                  }
                  return null;
                })
              }
            </ul>
            }
            { !(hasEntities) &&
                <div styleName="tray-empty-list">
                  No records found within the selection.
                </div>
            }
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default memo(AddToGroupTray);
