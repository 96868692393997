import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { List } from 'material-ui/List';
import AgencyTypeItem from './agency-type-item';
import './print-map.scss';

// Split the agency types array into groups of three elements:
const getAgencyGroups = agencyType => R.splitEvery(3, R.values(agencyType));

const Footer = ({ agency_type }) => (
  <div styleName="footer">
    {getAgencyGroups(agency_type).map((agencyGroup, index) => (
      <List key={index}>
        {agencyGroup.map(item => <AgencyTypeItem agencyType={item} key={item.id} />)}
      </List>
    ))}
  </div>
);

Footer.propTypes = {
  agency_type: PropTypes.object
};

const mapStateToProps = state => {
  const { agency_type } = state.dataTypes;
  return { agency_type };
};

export default connect(mapStateToProps, {})(Footer);
