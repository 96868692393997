/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import './header-link-button.scss';

const HeaderLinkButton = ({ icon, label, onClick }) => (
  <div onClick={onClick} styleName="link-button" role="presentation">
    <div styleName="link-button-icon">
      {icon}
    </div>
    <div styleName="link-button-text">
      {label}
    </div>
  </div>
);

HeaderLinkButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func
};

export default HeaderLinkButton;
