import * as R from 'ramda';
import { createSelector } from 'reselect';
import { optimizeEntitiesForList } from '@utils/entity-utils';
import { getConfig } from '@components/config/selectors';
import { getAgencyAndEntityTypes } from '@selectors/shared-selector';

const DEFAULT_CONFLICTS = [];
const DEFAULT_ENTITY = [];
const DEFAULT_OPPORTUNITIES = [];
const getConflicts = state => state.dataDetail.data && state.dataDetail.data.conflicts || DEFAULT_CONFLICTS;
const getEntity = state => state.dataDetail.data && [state.dataDetail.data.entity] || DEFAULT_ENTITY;
const getOpportunities = state => state.dataDetail.data && state.dataDetail.data.opportunities || DEFAULT_OPPORTUNITIES;

export const optimizeOverlapConflictsForListSelector = createSelector([getConflicts, getAgencyAndEntityTypes], optimizeEntitiesForList);
export const optimizeOverlapOpportunitiesForListSelector = createSelector([getOpportunities, getAgencyAndEntityTypes], optimizeEntitiesForList);

export const optimizeEntityForListSelector = createSelector([getEntity, getAgencyAndEntityTypes], optimizeEntitiesForList);

export const getEntityDetailsTrayConfig = createSelector([getConfig], configData => R.pathOr([], ['detailTrayConfig'], configData));
