/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { simpleIconButtonStyles } from '@constants/mui-theme';
import IconButton from 'material-ui/IconButton';
import { downloadAttachment } from './actions';
import DownloadIcon from '@icons/download-icon';
import './download-all.scss';

const DownloadAll = ({ attachments }) => {
  const download = () => {
    for (let index = 0; index < attachments.length; index += 1) {
      setTimeout(() => {
        downloadAttachment(attachments[index]);
      }, (index + 1) * 1000);
    }
  };

  return (
    <div styleName="container" onClick={download} role="presentation">
      <IconButton {...simpleIconButtonStyles}>
        <div styleName="control-upload">
          <DownloadIcon />
          <div styleName="control-upload-text">Download all</div>
        </div>
      </IconButton>
    </div>
  );
};

DownloadAll.propTypes = {
  attachments: PropTypes.array
};

export default DownloadAll;
