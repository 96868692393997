// This component renders material-ui badge with a number
// at the bottom right.
import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'material-ui/Badge';
import { badgeCounterStyles } from '@constants/mui-theme';

const BadgeCounter = ({ children, ...other }) => <Badge {...other}>{children}</Badge>;

BadgeCounter.propTypes = {
  badgeContent: PropTypes.number,
  children: PropTypes.node
};

BadgeCounter.defaultProps = badgeCounterStyles.defaultProps;

export default BadgeCounter;
