/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { push } from 'connected-react-router';
import OverflowTooltipableLabel from '@shared/overflow-tooltipable-label';
import { getPortalPath } from '@utils/dashboard-utils';
import { verifyPermissions } from '@utils/permission-utils';
import './menu-section.scss';

const getTopLevelDataType = (dataType, location) => {
  if (location && location.pathname) {
    const { pathname } = location;
    if (pathname.startsWith('/list/data/overlaps/')) {
      return 'overlap';
    }
    if (pathname.startsWith('/list/data/group/')) {
      return 'group';
    }
    if (pathname.startsWith('/workflow/activity/')) {
      return 'activity';
    }
    if (pathname.startsWith('/workflow/task/')) {
      return 'task';
    }

    // Admin paths:
    if (pathname.startsWith('/admin/workflow/')) {
      return 'workflow';
    }
    if (pathname.startsWith('/admin/task_type/')) {
      return 'task_type';
    }
  }
  return dataType;
};

const MenuSectionItem = ({
  item: {
    // children, icon,  // These two properties are unused for now
                        // (we'll use the 'children' one when we make this component
                        // to render overlaps, i.e. a data type with subtypes).
                        // And the new design, doesn't seems to need icons in the menu.
    label, permissions, subtype, type
  }
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const { dataType } = params || {};

  const portal = getPortalPath();
  const subTypeField = subtype ? `/${subtype}` : '';
  const onClick = useCallback(
    () => dispatch(push(`/${portal}/data/${type}${subTypeField}`)),
    [dispatch, portal, subTypeField, type]
  );

  if (!verifyPermissions(permissions)) {
    return null;
  }

  const topLevelDataType = getTopLevelDataType(dataType, location);
  const active = subtype ? subtype === dataType && topLevelDataType === type : type === topLevelDataType;

  return (
    <div
      onClick={onClick}
      role="presentation"
      styleName={active ? 'menu-item active' : 'menu-item'}
    >
      <OverflowTooltipableLabel>{label}</OverflowTooltipableLabel>
    </div>
  );
};

MenuSectionItem.propTypes = {
  item: PropTypes.object
};

export default MenuSectionItem;
