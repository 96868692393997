/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as R from 'ramda';
import moment from 'moment-timezone';
import FontIcon from 'material-ui/FontIcon';
import MoreHorizIcon from 'material-ui/svg-icons/navigation/more-horiz';
import { selectSegment } from '@actions/map-actions';
import { dotmapsBlack60 } from '@constants/colors';
import { getTrayTop } from '@selectors/map-selector';
import { shapeDistance } from '@utils/geometry-utils';
import {
  getSegmentTitle,
  isScheduleEnabled,
  renderScheduleString,
  renderSmallWarning,
  showEntityScheduleDateFilterWarning
} from '@utils/segment-schedule/common';
import { enDash } from '@utils/shared-utils';
import './details-table-locations-list.scss';

class DetailsTableLocationsList extends Component {
  state = { expandAll: false, showAll: false };

  setLocationActive = segmentId => {
    const { selectedSegment } = this.props.tray;
    if (selectedSegment && selectedSegment.id === segmentId) {
      this.props.selectSegment(null);
    } else {
      this.props.selectSegment(segmentId);
    }
  };

  toggleExpandAll = () => this.setState({expandAll: !this.state.expandAll});

  toggleShowAll = () => this.setState({showAll: !this.state.showAll});

  getClickedSegment = () => {
    const { segments } = this.props.entity;
    const { selectedPosition: {lat, lng}, selectedBounds, selectedRadius } = this.props;
    const newSegments = [...segments];

    const distanceSegments = (
      newSegments
        .filter(segment => !segment.bounds || selectedBounds.intersects(segment.bounds))
        .map(segment => ({distance: shapeDistance([lng, lat], segment.shape), segment}))
        .filter(distanceSegment => distanceSegment. distance <= selectedRadius)
    );
    return (
      distanceSegments
        .sort((first, second) => first.distance - second.distance)
        .map(distanceSegment => distanceSegment.segment)
    );
  };

  parseSchedules = () => {
    const scheduleEnabled = isScheduleEnabled(this.props.entity.type_name);
    if (!scheduleEnabled) {
      return null;
    }
    const { segments } = this.props.entity;
    const active = [];
    const upcoming = [];
    const completed = [];
    const noSchedule = [];
    segments.map(segment => {
      if (segment.schedules && segment.schedules.length) {
        const start = moment(segment.schedules[0].start_date).toDate();
        const end = moment(segment.schedules[0].end_date).toDate();
        const today = moment().toDate();
        if (end < today) {
          completed.push(segment);
        } else if (start > today) {
          upcoming.push(segment);
        } else {
          active.push(segment);
        }
      } else {
        noSchedule.push(segment);
      }
      return null;
    });
    return { active, upcoming, completed, noSchedule };
  };

  getLocationCards = segments => {
    const { segmentImpactTypes, segmentDirections } = this.props;
    const { selectedSegment } = this.props.tray;
    return segments.map(segment => {
      const impactType = R.find(R.propEq(segment.impact_type, 'id'))(Object.values(segmentImpactTypes));
      const direction = R.find(R.propEq(segment.direction, 'id'))(Object.values(segmentDirections));

      return (
        <div
          styleName={`location-card ${selectedSegment && selectedSegment.id === segment.id ? 'active-card' : ''}`}
          key={segment.id}
          onClick={() => this.setLocationActive(segment.id)}
          role="presentation"
        >
          <div styleName="title">
            {getSegmentTitle(segment)}
          </div>
          <div styleName="row">{segment.description}</div>
          { direction && impactType &&
            <div styleName="row">{direction.name} &bull; {impactType.name}</div>
          }
          {segment.schedules && segment.schedules.length > 0 &&
            <div styleName="schedule">{renderScheduleString(segment.schedules)}</div>
          }
          {(this.state.expandAll || (selectedSegment && selectedSegment.id === segment.id)) &&
            <div styleName="street-info">
              <div styleName="row">
                <div styleName="street-label">From</div>
                <div styleName="street-value">{segment.from_street || segment.from_address || enDash}</div>
              </div>
              <div styleName="row">
                <div styleName="street-label">To</div>
                <div styleName="street-value">{segment.to_street || segment.to_address || enDash}</div>
              </div>
            </div>
          }
        </div>
      );
    });
  };

  render() {
    const { segments } = this.props.entity;
    const { showAll } = this.state;
    const { mapFilters } = this.props;
    const clickedSegment = this.getClickedSegment();
    const schedules = this.parseSchedules();
    const scheduleEnabled = isScheduleEnabled(this.props.entity.type_name);

    const showHiddenLocationsWarning = showEntityScheduleDateFilterWarning(mapFilters, this.props.entity);

    return (
      <div styleName="locations-container">
        <div styleName="header">
          Locations<div styleName="location-count">{segments.length}</div>
          {showAll &&
            <div styleName="expand-icon">
              {this.state.expandAll ?
                <FontIcon className="material-icons" onClick={this.toggleExpandAll}>unfold_less</FontIcon> :
                <FontIcon className="material-icons" onClick={this.toggleExpandAll}>unfold_more</FontIcon>
              }
            </div>
          }
        </div>
        {showHiddenLocationsWarning && renderSmallWarning('Some locations may be hidden on the map because the date filter is applied.')}
        {!showAll && clickedSegment.length > 0 &&
          <div>
            <div styleName="locations-list">{this.getLocationCards(clickedSegment)}</div>
          </div>
        }
        {scheduleEnabled ?
          <div>
            {showAll && schedules.noSchedule.length > 0 &&
              <div>
                <div styleName="label">FOLLOWING EVENT SCHEDULE</div>
                <div styleName="locations-list">{this.getLocationCards(schedules.noSchedule)}</div>
              </div>
            }
            {showAll && schedules.active.length > 0 &&
              <div>
                <div styleName="label">CURRENTLY ACTIVE</div>
                <div styleName="locations-list">{this.getLocationCards(schedules.active)}</div>
              </div>
            }
            {showAll && schedules.upcoming.length > 0 &&
              <div>
                <div styleName="label">UPCOMING</div>
                <div styleName="locations-list">{this.getLocationCards(schedules.upcoming)}</div>
              </div>
            }
            {showAll && schedules.completed.length > 0 &&
              <div>
                <div styleName="label">COMPLETED</div>
                <div styleName="locations-list">{this.getLocationCards(schedules.completed)}</div>
              </div>
            }
          </div> :
          <div>
            {showAll && segments.length > 0 &&
              <div>
                <div styleName="locations-list">{this.getLocationCards(segments)}</div>
              </div>
            }
          </div>
        }
        {segments.length > 0 &&
          <div styleName="show-more" onClick={this.toggleShowAll} role="presentation">
            <MoreHorizIcon style={{width: '24px', paddingRight: '13px', color: dotmapsBlack60}}/>
            <div>{!showAll ? 'SHOW MORE' : 'SHOW LESS'}</div>
          </div>
        }
      </div>
    );
  }
}

DetailsTableLocationsList.propTypes = {
  entity: PropTypes.object,
  mapFilters: PropTypes.object,
  segmentDirections: PropTypes.object,
  segmentImpactTypes: PropTypes.object,
  selectSegment: PropTypes.func,
  selectedBounds: PropTypes.object,
  selectedPosition: PropTypes.object,
  selectedRadius: PropTypes.number,
  tray: PropTypes.object
};

const mapStateToProps = state => ({
  mapFilters: state.map.filters,
  segmentImpactTypes: state.dataTypes.segment_impact_type,
  segmentDirections: state.dataTypes.segment_direction,
  selectedPosition: state.map.selectedPosition,
  selectedRadius: state.map.selectedRadius,
  selectedBounds: state.map.selectedBounds,
  tray: getTrayTop(state)
});

export default connect(mapStateToProps, { selectSegment })(DetailsTableLocationsList);
