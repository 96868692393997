// This component renders a material-ui FlatButton
// but with the look and feel of a link.
import React from 'react';
import FlatButton from 'material-ui/FlatButton';
import PropTypes from 'prop-types';
import { linkButtonStyles } from '@constants/mui-theme';

const LinkButton = props => <FlatButton disableTouchRipple {...props} />;

LinkButton.propTypes = {
  key: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func
};

LinkButton.defaultProps = linkButtonStyles.defaultProps;

export default LinkButton;
