/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import Checkbox from 'material-ui/Checkbox';
import { detailEdit } from '@constants/mui-theme';
import DateDivider from '@shared/dialogs/edit-schedule-dialog-date-divider';
import FormattedDatePicker from '@shared/formatted-date-picker';
import MomentTimePicker from '@shared/moment-time-picker';
import './edit-schedule-dialog.scss';

const OneTimeScheduleType = ({
  endDate,
  endTime,
  errors,
  getId,
  maxDate,
  minDate,
  onEndDateChange,
  onEndTimeChange,
  onStartDateChange,
  onStartTimeChange,
  segment,
  setSegment,
  startDate,
  startTime
}) => {
  const dateProps = { minDate, maxDate };
  const schedule = segment.schedules[0];

  const toggleAllDay = () => {
    const newSegment = cloneDeep(segment);
    const newSchedule = newSegment.schedules[0];

    // Toggle 'all_day':
    newSchedule.all_day = !newSchedule.all_day;

    if (newSchedule.all_day) {
      // If we are setting 'all_day', grab the start/end dates from the state
      // and store it on the schedule (to enable the SET button).
      if (startDate && endDate) {
        newSchedule.start_date = startDate;
        newSchedule.end_date = endDate;
      }
    } else {
      // If we are turning off 'all_day', we must clear the schedule dates
      // if the time was never set on the state:
      if (!startTime) {
        newSchedule.start_date = null;
      }
      if (!endTime) {
        newSchedule.end_date = null;
      }
    }
    setSegment(newSegment);
  };

  return (
    <div>
      <div styleName="section">
        <div styleName="title">Date and time</div>
        <div styleName="content content-dates">
          <FormattedDatePicker
            {...dateProps}
            clearable={false}
            errorText={errors.start_date}
            floatingLabelText="Start date"
            id={getId('start_date')}
            name="start_date"
            onChange={onStartDateChange}
            value={startDate}
            style={{...detailEdit.segmentEdit.dateRange}}
          />
          {!schedule.all_day && (
            <MomentTimePicker
              id={getId('start_time')}
              onChange={onStartTimeChange}
              value={startTime}
              floatingLabelText="Start time"
              fullWidth
              style={{...detailEdit.segmentEdit.timeRange}}
            />
          )}
          <DateDivider text="to" />
          {!schedule.all_day && (
            <MomentTimePicker
              id={getId('end_time')}
              onChange={onEndTimeChange}
              value={endTime}
              floatingLabelText="End time"
              fullWidth
              style={{...detailEdit.segmentEdit.timeRange}}
            />
          )}
          <FormattedDatePicker
            {...dateProps}
            id={getId('end_date')}
            clearable={false}
            errorText={errors.end_date}
            floatingLabelText="End date"
            name="end_date"
            onChange={onEndDateChange}
            value={endDate}
            style={{...detailEdit.segmentEdit.dateRange}}
          />
        </div>
        <div styleName="checkbox-item">
          <div styleName="checkbox">
            <Checkbox
              {...detailEdit.segmentEdit.check}
              checked={schedule.all_day}
              onCheck={toggleAllDay}
            />
          </div>
          <div
            onClick={toggleAllDay}
            role="presentation"
            styleName="checkbox-label"
          >All day</div>
        </div>
      </div>
    </div>
  );
};

OneTimeScheduleType.propTypes = {
  endDate: PropTypes.object,
  endTime: PropTypes.object,
  errors: PropTypes.object,
  getId: PropTypes.func,
  maxDate: PropTypes.object,
  minDate: PropTypes.object,
  onEndDateChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  onStartDateChange: PropTypes.func,
  onStartTimeChange: PropTypes.func,
  segment: PropTypes.object,
  setSegment: PropTypes.func,
  startDate: PropTypes.object,
  startTime: PropTypes.object
};

export default OneTimeScheduleType;
