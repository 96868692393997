import React from 'react';
import Avatar from 'material-ui/Avatar';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import PropTypes from 'prop-types';
import {
  secBarCompactIconButtonStyles,
  secBarSmallIconButtonStyles,
  secBarToolbarIconButtonStyles,
  tooltipStyles
} from '@constants/mui-theme';
import { overlayTextColor } from '@constants/colors';

const ActionIconButton = ({
  avatarProps,
  avatarize,
  click,
  color,
  compact,
  customIcon,
  customStyle,
  disableTouchRipple,
  icon,
  outlined,
  iconInactive,
  id,
  inactive,
  small,
  style,
  toolbar,
  tooltip,
  tooltipPosition
}) => {
  const getIcon = () => {
    if (customIcon) {
      return customIcon;
    }
    const fontIconColor = color ? color : overlayTextColor;
    const iconClass = outlined ? 'material-symbols-outlined' : 'material-icons';
    const fontIcon = (
      <FontIcon className={iconClass} color={fontIconColor}>
        {icon}
      </FontIcon>
    );

    if (avatarize) {
      return <Avatar {...avatarProps} icon={fontIcon} size={38} alt="" />;
    }
    return fontIcon;
  };

  return (
    <IconButton
      disableTouchRipple={disableTouchRipple || inactive || iconInactive}
      id={id}
      onClick={click}
      style={{
        ...(compact && secBarCompactIconButtonStyles),
        ...(customStyle && customStyle),
        ...(small && secBarSmallIconButtonStyles),
        ...(toolbar && secBarToolbarIconButtonStyles),
        ...(inactive && { opacity: 0.5 }),
        ...(avatarize && { padding: 0 }),
        ...(style)
      }}
      iconStyle={{...(iconInactive && { opacity: 0.5, cursor: 'default' })}}
      tooltip={tooltip}
      tooltipPosition={tooltipPosition}
      tooltipStyles={tooltipStyles}
    >
      {getIcon()}
    </IconButton>
  );
};

ActionIconButton.propTypes = {
  avatarProps: PropTypes.object,
  avatarize: PropTypes.bool,
  click: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  color: PropTypes.any,
  compact: PropTypes.bool,
  customIcon: PropTypes.object,
  customStyle: PropTypes.object,
  disableTouchRipple: PropTypes.bool,
  icon: PropTypes.string,
  iconInactive: PropTypes.bool,
  id: PropTypes.string,
  inactive: PropTypes.bool,
  outlined: PropTypes.bool,
  small: PropTypes.bool,
  style: PropTypes.object,
  toolbar: PropTypes.bool,
  tooltip: PropTypes.string,
  tooltipPosition: PropTypes.string
};

ActionIconButton.defaultProps = {
  tooltipPosition: 'bottom-center'
};

export default ActionIconButton;
