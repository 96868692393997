/* eslint-disable dot-notation */
import React from 'react';
import { useSelector } from 'react-redux';
import ViewListIcon from 'material-ui/svg-icons/action/view-list';
import { noRecordsIconStyles } from '@constants/mui-theme';
import { getCycleGroupCycles } from '@selectors/groups-selector';
import CycleCards from '@shared/cycle-cards';
import commonStyles from '../entity-list/entity-list.scss';  // eslint-disable-line css-modules/no-unused-class
import styles from './cycle-list.scss';

const CycleList = () => {
  const cycles = useSelector(getCycleGroupCycles);
  return (
    <div className={styles['cycle-list']}>
      {cycles.length === 0 && (
        <div className={commonStyles['no-records']}>
          <div className={commonStyles['icon']}>
            <ViewListIcon {...noRecordsIconStyles} />
          </div>
          <div className={commonStyles['title']}>No workflow cycles</div>
        </div>
      )}
      <CycleCards cycles={cycles} />
    </div>
  );
};

export default CycleList;
