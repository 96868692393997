import React from 'react';
import PropTypes from 'prop-types';
import '../../../../../../forms/forms.scss';

const Title = ({ tab }) => (
  <div styleName="form-title" id={`tab-section-${tab.id}-title`}>
    <div styleName="form-title-label">
      {tab.label}
    </div>
    {tab.extra && (
      <div styleName="form-title-label-extra">
        {tab.extra}
      </div>
    )}
  </div>
);

Title.propTypes = {
  tab: PropTypes.object
};

export default Title;
