import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Transition from 'material-ui/styles/transitions';
import FontIcon from 'material-ui/FontIcon';
import { isEqualDate } from 'material-ui/DatePicker/dateUtils';
import EnhancedButton from 'material-ui/internal/EnhancedButton';
import { dotmapsRed } from '@constants/colors';
import BadgeIcon from '@shared/badge-icon';

function getStyles(props, context, state) {
  const { date, disabled, excluded, selected } = props;
  const { hover } = state;
  const { baseTheme, datePicker } = context.muiTheme;

  let labelColor = baseTheme.palette.textColor;
  let buttonStateOpacity = 0;
  let buttonStateTransform = 'scale(0)';

  if (hover || selected) {
    labelColor = datePicker.selectTextColor;
    buttonStateOpacity = selected ? 1 : 0.6;
    buttonStateTransform = 'scale(1)';
  } else if (isEqualDate(date, new Date())) {
    labelColor = datePicker.color;
  }

  if (excluded) {
    buttonStateOpacity = 0.6;
  }

  return {
    root: {
      boxSizing: 'border-box',
      fontWeight: '400',
      opacity: disabled && '0.4',
      padding: '4px 0px',
      position: 'relative',
      WebkitTapHighlightColor: 'rgba(0,0,0,0)', // Remove mobile color flashing (deprecated)
      width: 42
    },
    label: {
      color: labelColor,
      fontWeight: '400',
      position: 'relative'
    },
    buttonState: {
      backgroundColor: datePicker.selectColor,
      borderRadius: '50%',
      height: 34,
      left: 4,
      opacity: buttonStateOpacity,
      position: 'absolute',
      top: 0,
      transform: buttonStateTransform,
      transition: Transition.easeOut(),
      width: 34
    }
  };
}

class DayButton extends Component {
  state = {
    hover: false
  };

  handleMouseEnter = () => {
    if (!this.props.disabled) {
      this.setState({hover: true});
    }
  };

  handleMouseLeave = () => {
    if (!this.props.disabled) {
      this.setState({hover: false});
    }
  };

  handleTouchTap = event => {
    if (!this.props.disabled && this.props.onClick) {
      this.props.onClick(event, this.props.date);
    }
  };

  getLabel = styles => {
    const {
      DateTimeFormat,
      date,
      excluded,
      locale
    } = this.props;
    const { prepareStyles } = this.context.muiTheme;

    if (excluded) {
      return (
        <BadgeIcon
          badgeContent={
            <FontIcon
              className="material-icons"
              color={dotmapsRed}
              style={{ fontSize: '18px' }}
            >
              block
            </FontIcon>
          }
        >
          <span style={prepareStyles(styles.label)}>
            {new DateTimeFormat(locale, {
              day: 'numeric'
            }).format(date)}
          </span>
        </BadgeIcon>
      );
    }
    return (
      <span style={prepareStyles(styles.label)}>
        {new DateTimeFormat(locale, {
          day: 'numeric'
        }).format(date)}
      </span>
    );
  };

  render() {
    const {
      date,
      disabled,
      onClick, // eslint-disable-line no-unused-vars
      selected, // eslint-disable-line no-unused-vars
      excluded, // eslint-disable-line no-unused-vars
      ...other
    } = this.props;

    const { prepareStyles } = this.context.muiTheme;
    const styles = getStyles(this.props, this.context, this.state);

    return date ? (
      <EnhancedButton
        {...other}
        disabled={disabled}
        disableFocusRipple
        disableTouchRipple
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleTouchTap}
        style={styles.root}
      >
        <div style={prepareStyles(styles.buttonState)} />
        {this.getLabel(styles)}
      </EnhancedButton>
    ) : (
      <span style={prepareStyles(styles.root)} />
    );
  }
}

DayButton.propTypes = {
  DateTimeFormat: PropTypes.func.isRequired,
  date: PropTypes.object,
  disabled: PropTypes.bool,
  excluded: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

DayButton.defaultProps = {
  excluded: false,
  selected: false,
  disabled: false
};

DayButton.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default DayButton;
