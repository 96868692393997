import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { getAgencyIcon, renderIconAndLabel } from '@utils/icon-utils';

class ConflictAgency extends Component {
  getIcon = agency => {
    const { agencyTypes } = this.props;
    const agencyType = agencyTypes[agency.type];
    return getAgencyIcon(agencyType);
  };

  getAgency = () => {
    const { conflict } = this.props;
    const { agency } = conflict;
    if (!agency) {
      return null;
    }
    const { agencies } = this.props;
    return Object.values(agencies).find(agencyItem => agencyItem.id === agency);
  };

  render() {
    const agency = this.getAgency();
    if (!agency) {
      return null;
    }
    const { conflict } = this.props;
    return (
      <TruncatedCell tooltip={agency.name} targetId={conflict.id}>
        {renderIconAndLabel(agency.name, this.getIcon(agency), conflict.id)}
      </TruncatedCell>
    );
  }
}

ConflictAgency.propTypes = {
  agencies: PropTypes.object,
  agencyTypes: PropTypes.object,
  conflict: PropTypes.object
};

export default ConflictAgency;
