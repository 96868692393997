import * as R from 'ramda';
import { getFilters } from '@selectors/data-table-selector';
import { BaseDropDownFilter } from '@shared/top-filter-bar/base-dropdown-filter';

export class DashboardBaseDropDownFilter extends BaseDropDownFilter {
  // The map filters are only applied to the map leaf on the store,
  // but the library ones can be applied to different data types,
  // thus the library filters have a special setFilter() method which sets the data type.
  setFilter(filter) {
    const { dataType, subType } = this.props;
    this.props.setFilter(dataType, subType, filter);
  }
}

export const baseMapStateToProps = (state, props) => {
  const { sourceDataType, lazyName, items, sort = null } = props;
  const filters = getFilters(state, props);
  // The name is lazy loaded, since it requires the backend config data loaded.
  const name = lazyName();
  const filter = filters[name];
  let newItems = items || state.dataTypes[sourceDataType];
  if (sort) {
    newItems = R.sortBy(R.compose(R.toLower, R.prop(sort)))(R.values(newItems));
  }
  return {
    items: newItems,
    filter,
    name
  };
};
