import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { resendOnboardingEmail } from '@actions/auth-actions';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import {
  confirmableDeactivateDashboardEntry,
  confirmableReactivateDashboardEntry
} from '@actions/dashboard-actions';
import * as colors from '@constants/colors';
import { PERMISSION_GROUP_ADMIN } from '@constants/permission';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { hasGroups, isAppAdmin, isSuperuser } from '@utils/permission-utils';

class UserActions extends Component {
  deactivate = () => {
    this.props.showConfirmationDialog(
      'Deactivate user',
      'Are you sure you want to deactivate this user?' +
      ' This user will no longer be able to log in or perform actions until reactivated.',
      confirmableDeactivateDashboardEntry('user', Number(this.props.data.id))
    );
  };

  reactivate = () => {
    this.props.showConfirmationDialog(
      'Reactivate user',
      'Are you sure you want to reactivate this user?' +
      ' This user will regain access to the application and perform actions.',
      confirmableReactivateDashboardEntry('user', Number(this.props.data.id))
    );
  };

  resendOnboarding = () => {
    this.props.resendOnboardingEmail(this.props.data.id);
  };

  render() {
    // Only allow deactivation/activation for admins:
    if (!isAppAdmin() && !hasGroups([PERMISSION_GROUP_ADMIN])) {
      return null;
    }
    const { is_active: isActive } = this.props.data;
    const activeLabel = isActive ? <div style={{ color: colors.dotmapsRed }}>DEACTIVATE</div> : 'REACTIVATE';
    const activeCallback = isActive ? this.deactivate : this.reactivate;
    return (
      <IconMenu iconStyles={{ color: colors.overlayTextColor }}>
        <IconMenuItem onClick={activeCallback}>
          {activeLabel}
        </IconMenuItem>
        {isSuperuser() && <IconMenuItem onClick={this.resendOnboarding}>Resend onboarding email</IconMenuItem>}
      </IconMenu>
    );
  }
}

UserActions.propTypes = {
  data: PropTypes.object,
  resendOnboardingEmail: PropTypes.func,
  showConfirmationDialog: PropTypes.func
};

const mapStateToProps = state => {
  const data = R.pathOr({}, ['dashboard', 'details', 'user', 'data'], state);
  return { data };
};

export default connect(mapStateToProps, {
  resendOnboardingEmail,
  showConfirmationDialog
})(UserActions);
