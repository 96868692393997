import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { setBaseline } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import DialogCloseButton from '@shared/dialogs/dialog-close-button';

class SetBaseline extends Component {
  handleCancel = () => this.props.closeDashboardDialog(dialog.SET_BASELINE);

  handleSubmit = () => {
    const { dialogParams } = this.props;
    const payload = {
      ...dialogParams
    };
    this.props.setBaseline(payload);
    this.props.closeDashboardDialog(dialog.SET_BASELINE);
  };

  getActions = () => ([
    <Button key="cancel" onClick={this.handleCancel}>CANCEL</Button>,
    <Button
      color="primary"
      key="set"
      onClick={this.handleSubmit}
      style={{ marginLeft: '0.5rem' }}
      variant="contained"
    >
      SET
    </Button>
  ]);

  render() {
    const { activeDialogs } = this.props;
    if (!activeDialogs[dialog.SET_BASELINE]) {
      return null;
    }
    return (
      <Dialog
        actions={this.getActions()}
        contentStyle={dialogStyles.mediumDialog}
        open={activeDialogs[dialog.SET_BASELINE]}
        title="Set baseline"
      >
        <DialogCloseButton onClose={this.handleCancel} />
        Are you sure you want to save this current state of the group as the baseline?
        You can update the baseline any time.
      </Dialog>
    );
  }
}

SetBaseline.propTypes = {
  activeDialogs: PropTypes.object,
  closeDashboardDialog: PropTypes.func,
  dialogParams: PropTypes.object,
  setBaseline: PropTypes.func
};

const mapStateToProps = state => {
  const { activeDialogs, dialogParams } = state.dashboard;
  return { activeDialogs, dialogParams: dialogParams[dialog.SET_BASELINE] };
};

export default connect(mapStateToProps, { closeDashboardDialog, setBaseline })(SetBaseline);
