import React from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { detailEdit } from '@constants/mui-theme';
import './autocomplete-content.scss';

const AutocompleteContent = ({
  getInputProps,
  getSuggestionItemProps,
  inputProps,
  suggestions
}) => (
  <div data-testid="autocomplete-content" styleName="autocomplete-content-container">
    <TextField
      {...inputProps}
      errorStyle={{...detailEdit.errors}}
      onBlur={getInputProps().onBlur}
      onKeyDown={getInputProps().onKeyDown}
      onChange={getInputProps().onChange}
      {...detailEdit.segmentEdit.textField}
    />
    {suggestions.length > 0 && (
      <div styleName="suggestions-container">
        {suggestions.map(suggestion => (
          <div
            key={suggestion.id}
            styleName={`suggestion-item ${suggestion.active ? 'suggestion-item-active' : ''}`}
            {...getSuggestionItemProps(suggestion, {})}
          >
            <span>{suggestion.description}</span>
          </div>
        ))}
      </div>
    )}
  </div>
);

AutocompleteContent.propTypes = {
  getInputProps: PropTypes.func,
  getSuggestionItemProps: PropTypes.func,
  inputProps: PropTypes.object.isRequired,
  suggestions: PropTypes.array
};

export default AutocompleteContent;
