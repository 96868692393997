import React from 'react';

const PrioritizeIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="prioritize-path-1"
        d={`
            M9 1.5h6V3H9V1.5zm0 4.125h6v1.5H9v-1.5zM5.25 0L7.5 2.25 5.25
            4.5V3h-.375c-.93 0-1.774.379-2.385.99l-.12.125a3.362 3.362
            0 0 0-.87 2.26A3.38 3.38 0 0 0 4.875 9.75h.375v-1.5L7.5
            10.5l-2.25 2.25v-1.5h-.375A4.884 4.884 0 0 1 0 6.375
            4.884 4.884 0 0 1 4.875 1.5h.375V0zM15 9.75v1.5H9v-1.5h6z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 2.25)">
      <mask id="prioritize-path-2" fill="#fff">
        <use xlinkHref="#prioritize-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#prioritize-path-2)">
        <path d="M-1.5-2.25h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default PrioritizeIcon;
