import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import moment from 'moment-timezone';
import { filterDropDownStyles } from '@constants/mui-theme';
import FilterDropDown from '@shared/filter-dropdown';
import { generateDateFilters, generateSingleDateFilters } from '@utils/date-utils';
import { buildFilterMenuItem } from '@utils/filter-utils';
import { shallowEqual } from '@utils/react-utils';
import { BaseFilter } from '../base-filter';

const CUSTOM_RANGE_ID = 7;

// Base date filter class:
class BaseDateFilter extends BaseFilter {
  // Implement these in subclasses:

  // Parse start/end dates from the store, to select the right
  // menu option.
  buildSelectedId(start, end) {}  // eslint-disable-line no-unused-vars

  // Callback for when we select a value on the menu:
  filterChange(event, key, value) {}  // eslint-disable-line no-unused-vars

  // Return the list of date items (Today, Tomorrow, etc):
  getDateItems() {}

  // Return the item id for the 'Custom range' option:
  getCustomRangeId() {
    return CUSTOM_RANGE_ID;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !shallowEqual(nextProps.start, this.props.start) ||
      !shallowEqual(nextProps.end, this.props.end) ||
      !shallowEqual(nextProps.contain, this.props.contain)
    ) {
      const defaultState = this.buildStateFromProps(nextProps);
      this.setState(defaultState);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.label, this.props.label) ||
      !shallowEqual(nextProps.start, this.props.start) ||
      !shallowEqual(nextProps.end, this.props.end) ||
      !shallowEqual(nextProps.contain, this.props.contain) ||
      this.state !== nextState
    );
  }
  buildStateFromProps(props) {
    const { start, end, contain } = props;
    return {
      contain,
      end: end && moment(end).toDate(),
      selected: this.buildSelectedId(start, end),
      start: start && moment(start).toDate()
    };
  }
  getFilters() {
    if (this.props.multiple) {
      return generateDateFilters(this.state);
    }
    return generateSingleDateFilters(this.state, this.props.fieldName || 'created');
  }
  selectionRenderer(values, menuItems) {  // eslint-disable-line no-unused-vars
    const { selected } = this.state;
    const label = this.props.label || 'Date';
    if (selected === this.getCustomRangeId()) {
      const end = moment(this.state.end).format('M/D/YYYY');
      const start = moment(this.state.start).format('M/D/YYYY');
      return `${label}: ${start} - ${end}`;
    }
    return `${label}: ${this.getDateItems()[selected - 1].name}`;
  }
  getChecked(item) {
    return this.state.selected === item.id;
  }
  render() {
    const items = this.getDateItems();
    return (
      <div>
        <FilterDropDown
          isValueSelected={!R.isEmpty(this.state.selected)}
          maxHeight={filterDropDownStyles.maxHeight}
          multiple={false}
          onChange={this.filterChange}
          onClose={this.filterClose}
          selectionRenderer={this.selectionRenderer}
          value={this.state.selected}
        >
          {items.map(item => buildFilterMenuItem(this.getChecked(item), null, item))}
        </FilterDropDown>
      </div>
    );
  }
}

BaseDateFilter.propTypes = {
  label: PropTypes.string,
  multiple: PropTypes.bool
};

BaseDateFilter.defaultProps = {
  multiple: true
};

export default BaseDateFilter;

