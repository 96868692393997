import React from 'react';
import PropTypes from 'prop-types';
import ListRow from './list-row';
import './activities-tab.scss';

const List = ({
  activity: selectedActivity,
  data: { activities },
  data,
  onClick
}) => (
  <div styleName="list">
    {activities && activities.map(activity => (
      <ListRow
        activity={activity}
        data={data}
        key={activity.id}
        selected={activity.id === selectedActivity.id}
        onClick={onClick}
      />
    ))}
  </div>
);

List.propTypes = {
  activity: PropTypes.object,
  data: PropTypes.object,
  onClick: PropTypes.func
};

export default List;
