import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Control } from 'react-redux-form';
import TextField from 'material-ui/TextField';
import { clearDashboardDetailsDataError } from '@actions/dashboard-actions';
import { dashboardDetails } from '@constants/mui-theme';
import './details-text-field.scss';

class DetailsTextField extends Component {
  constructor(props) {
    super(props);
    this.onChange = ({ model, onChange }) => {
      return function update(event, index, value) {
        const [dataType, ...path] = model.split('.');
        props.clearDashboardDetailsDataError(dataType, path);
        onChange(event, index, value);
      };
    };
  }

  render() {
    const { disabled, label, model, required, saveRequested } = this.props;
    const textMapProps = {
      onChange: props => this.onChange(props),
      ...dashboardDetails.input,
      errorText: ({fieldValue: {errors}, ['data-save-requested']: dataSaveRequested}) => (
        dataSaveRequested ? R.values(errors).join(', ') : ''
      )
    };
    const ControlText = Control.text;
    return (
      <div styleName="details-text-field-container">
        <div styleName="label">
          {label}{required || disabled ? '' : ' (Optional)'}
        </div>
        <div styleName="input">
          <ControlText
            disabled={disabled}
            model={`.${model}`}
            mapProps={textMapProps}
            data-save-requested={saveRequested}
            component={TextField}
          />
        </div>
      </div>
    );
  }
}

DetailsTextField.propTypes = {
  clearDashboardDetailsDataError: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  model: PropTypes.string,
  required: PropTypes.bool,
  saveRequested: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    saveRequested: state.dashboard.saveRequested
  };
};

export default connect(mapStateToProps, {clearDashboardDetailsDataError})(DetailsTextField);
