import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FontIcon from 'material-ui/FontIcon';
import { clearLogs, fetchLogsData } from '@actions/logs-actions';
import * as colors from '@constants/colors';
import DotmapsLoader from '@shared/dotmaps-loader';
import { reachingTableEnd } from '@utils/data-table-utils';
import { groupLogs } from '@utils/logs-utils';
import ItemsGroup from './items-group';
import TopBar from './top-bar';
import './activity-logs.scss';

const Empty = () => (
  <div styleName="activity-logs-empty">
    <FontIcon
      className="material-icons"
      color={colors.dotmapsGrayEmptyIcon}
      style={{ fontSize: 72 }}
    >
      history
    </FontIcon>
    <div styleName="activity-logs-empty-text">
      There is no activity yet!<br />
      Once activities happen, you'll see them here.
    </div>
  </div>
);

const Logs = ({ groupedLogs }) => (
  <div>
    { groupedLogs.map((groupedLog, index) => <ItemsGroup key={index} groupedLog={groupedLog} />) }
  </div>
);

Logs.propTypes = {
  groupedLogs: PropTypes.array
};

const ActivityLogs = ({ embedded, params }) => {
  const { list, loading, next, type: entityType } = useSelector(state => state.logs);
  const groupedLogs = groupLogs(list);
  const routerParams = useParams();
  // We get these params from the router when the activity log component is a page
  // on its own, or from the "params" component property when it's embedded, like
  // in the new entity details page.
  //
  // Going forward we should probably create a more generic component that
  // can be used with and without a top-bar, so we don't have to set
  // parameters conditionally and we also won't use the "embedded" property.
  const { app, model, dataId } = params || routerParams;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearLogs());
    dispatch(fetchLogsData(app, model, dataId, null));
  }, [app, model, dataId, dispatch]);

  const onScroll = useCallback(
    event => {
      if (reachingTableEnd(event) && !loading && next) {
        dispatch(fetchLogsData(app, model, dataId, next));
      }
    },
    [app, dataId, dispatch, loading, model, next]
  );

  const hasLogs = groupedLogs && groupedLogs.length > 0;

  return (
    <div styleName="activity-logs-container">
      {!embedded && (
        <div styleName="activity-logs-top-bar">
          <TopBar dataType={entityType} />
        </div>
      )}
      <div styleName="activity-logs-body" onScroll={onScroll}>
        {hasLogs && <Logs groupedLogs={groupedLogs} />}
        {!hasLogs && !loading && <Empty />}
      </div>
      {/* When we first load the page, show a loading indicator at the page's center. */}
      <DotmapsLoader color={colors.dotmapsBlue} display={loading && !next} />
      {/* When we load more through scrolling, show the indicator at the bottom. */}
      {loading && next && (
        <div styleName="activity-logs-spinner">
          <DotmapsLoader color={colors.dotmapsBlue} display={loading && next} />
        </div>
      )}
    </div>
  );
};

ActivityLogs.propTypes = {
  embedded: PropTypes.bool,
  params: PropTypes.object
};

export default ActivityLogs;

export { ActivityLogs as PureActivityLogs };
