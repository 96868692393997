import React from 'react';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import Avatar from 'material-ui/Avatar';
import FontIcon from 'material-ui/FontIcon';
import AccountCircleIcon from 'material-ui/svg-icons/action/account-circle';
import { dotmapsBlue, dotmapsRed } from '@constants/colors';
import { badgeAvatarStyles, userTableStyles } from '@constants/mui-theme';
import BadgeAvatar from '@shared/badge-avatar';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import UserTeams from '@utils/data-table/column-renderers/user-teams';
import { renderCustomIconAndLabel } from '@utils/icon-utils';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const getUserActive = is_active => is_active ? 'Active' : 'Inactive';

const getAvatar = (initial, opacity = 1, size, color) => (
  <Avatar
    backgroundColor={color}
    style={{opacity}}
    size={size}
  >
    {initial ? initial.toUpperCase() : ''}
  </Avatar>
);

// The inactive user icon shows a disabled Avatar (0.2 opacity)
// and red material-ui block icon using a badge component.
const getInactiveAvatar = (initial, size, color) => {
  const icon = (
    <FontIcon
      color={dotmapsRed}
      style={userTableStyles.userInactiveAvatar}
      className="material-icons"
    >
      block
    </FontIcon>
  );
  return (
    <BadgeAvatar
      badgeContent={icon}
      style={badgeAvatarStyles.style}
      badgeStyle={badgeAvatarStyles.badgeStyle[size]}
    >
      {getAvatar(initial, 0.2, size, color)}
    </BadgeAvatar>
  );
};

// The active user icon is just the Avatar component with the
// user's name initial.
const getActiveAvatar = (initial, size, color) => (
  <div style={userTableStyles.userActiveAvatar}>
    {getAvatar(initial, 1, size, color)}
  </div>
);

export const getUserAvatar = (user, size = 30, color = dotmapsBlue) => {
  const initial = user.name ? user.name.substring(0, 1) : '';
  if (user.is_active) {
    return getActiveAvatar(initial, size, color);
  }
  return getInactiveAvatar(initial, size, color);
};

export const getNoUserIconAvatar = (size = 30, color = dotmapsBlue) => (
  <div style={userTableStyles.userActiveAvatar}>
    <AccountCircleIcon
      color={color}
      style={{
        height: size,
        width: size
      }}
    />
  </div>
);

export const renderUsername = (user, id) => (
  <TruncatedCell tooltip={user.name} targetId={id}>
    {renderCustomIconAndLabel(user.name, getUserAvatar(user), id)}
  </TruncatedCell>
);

export const optimizeUserData = (user, agencies, user_roles) => ({
  ...user,
  is_active: getUserActive(user.is_active),
  name: renderUsername(user, uuid()),
  agency: <TruncatedCell>{R.pathOr('DELETED', [user.agency, 'name'], agencies)}</TruncatedCell>,
  last_modified: user.last_modified ? renderTooltipDate(user.last_modified) : enDash,
  last_login: user.last_login ? renderTooltipDate(user.last_login) : enDash,
  roles: user.roles.length ? <UserTeams roles={user.roles} userRoles={user_roles} /> : enDash
});
