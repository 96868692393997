/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import { BaseTrayFilter, baseMapDispatchToProps, baseMapStateToProps } from '../base-filter';
import {
  setLayerVisible,
  fetchLayerConfig
} from '@actions/layers-actions';
import { filterStyles } from '@constants/mui-theme';
import { parentOrderedLayersSelector } from '@selectors/layers-selector';
import LayerIcon from './layer-icon';
import '../filter.scss';

class LayersFilter extends BaseTrayFilter {
  UNSAFE_componentWillMount() {
    if (this.props.layers.length === 0) {
      this.props.fetchLayerConfig();
    }
  }

  onToggleClick = () => {
    this.props.toggleMapFilterToggle('layer');
  };

  toggleLayerVisible = layer => {
    this.props.setLayerVisible(layer.name, (!layer || !layer.visible));
  };

  getLayer = layer => (
    <div styleName="list-item" key={layer.name}>
      {!layer.children && (
        <div styleName="checkbox">
          <Checkbox
            {...filterStyles.check}
            disabled={!this.props.filterEnabled}
            checked={layer.visible}
            onCheck={() => this.toggleLayerVisible(layer)}
          />
        </div>
      )}
      {!layer.children && (
        <div styleName="icon">
          <LayerIcon icon={layer.icon} />
        </div>
      )}
      <div styleName="label">
        {layer.label}
      </div>
    </div>
  );

  getContent = () => (
    <div styleName="content-list-container">
      {this.props.layers.map(layer => this.getLayer(layer))}
    </div>
  );
}

LayersFilter.propTypes = {
  fetchLayerConfig: PropTypes.func,
  layers: PropTypes.array,
  setLayerVisible: PropTypes.func
};

const mapStateToProps = state => {
  const { layer: filterEnabled } = state.map.filterToggles;
  const layers = parentOrderedLayersSelector(state);
  return { ...baseMapStateToProps(state), filterEnabled, layers };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  fetchLayerConfig,
  setLayerVisible
};

export default connect(mapStateToProps, mapDispatchToProps)(LayersFilter);
