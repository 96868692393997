import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { routerWillLeave } from '@actions/confirmation-actions';
import Section from '@components/workflow/cycle/content/section';
import AttachmentsSection from '@components/workflow/cycle/content/section/attachments';
import LinkedEntitiesSection from '@components/workflow/cycle/content/section/linked-entities';
import LocationsSection from '@components/workflow/cycle/content/section/locations';
import {
  TaskBase,
  baseMapStateToProps,
  baseMapDispatchToProps
} from '@components/workflow/cycle/content/task-base';
import { getTaskFields } from '@utils/workflow-utils';
import Header from './header';
import Footer from './footer';
import './edit.scss';

class Edit extends TaskBase {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.taskId) {
      this.props.assignTempTaskId(`temp-${uuid()}`);
    }
  }

  getTaskTypeId = () => {
    const { taskTypeId } = this.props.params;
    return taskTypeId;
  };

  render() {
    const { taskTypeId, viewType } = this.props.params;
    const { cycle, data, error, cycleLoading, params } = this.props;
    if (!taskTypeId || cycleLoading) {
      return null;
    }
    const taskType = this.getTaskType();
    const sections = this.getSections();
    const sectionProps = {
      cycle,
      data,
      edit: true,
      error,
      onChange: this.props.updateDataField
    };
    return (
      <div styleName="edit-container">
        <Header taskType={taskType} taskId={this.props.taskId} />
        <div styleName="edit-sections">
          {sections.map(section => {
            const fields = getTaskFields(section, taskType);
            // Only display a section if it has fields and it's not
            // the "Response" one, since the response fields are edited
            // per activity.
            if (fields.length > 0 && section.name !== 'Response') {
              return (
                <Section
                  {...sectionProps}
                  fields={fields}
                  key={section.id}
                  name={section.name}
                  section={section.id}
                  viewType={viewType}
                />
              );
            }
            return null;
          })}
          <AttachmentsSection {...sectionProps} taskId={this.props.taskId} viewType={viewType} />
          {cycle && cycle.group && <LinkedEntitiesSection {...sectionProps} />}
          {cycle && cycle.entity && <LocationsSection {...sectionProps} />}
        </div>
        <Footer params={params} />
        <Prompt when={this.props.modified && !this.props.discard} message={this.props.routerWillLeave} />
      </div>
    );
  }
}

Edit.propTypes = {
  discard: PropTypes.bool,
  modified: PropTypes.bool,
  routerWillLeave: PropTypes.func,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = state => {
  const { modified } = state.dataDetail;
  const { discard } = state.confirmation;
  return { ...baseMapStateToProps(state), discard, modified };
};

export const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  routerWillLeave
};

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
