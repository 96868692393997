import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';
import { ganttWarningDialogStyles } from '@constants/mui-theme';
import { dismissGanttDialog } from '@actions/groups-actions';

const WarningDialog = ({ dialogType, groupId, text }) => {
  const dispatch = useDispatch();
  const { dialogs } = useSelector(state => state.groups.gantt);
  const dismissed = dialogs[dialogType];
  const open = !includes(dismissed, groupId);

  const handleClose = useCallback(
    () => dispatch(dismissGanttDialog(dialogType, groupId)),
    [dialogType, dispatch, groupId]
  );

  const getActions = () => ([
    <FlatButton key="ok" label="OK" onClick={handleClose} style={ganttWarningDialogStyles.button} />
  ]);

  return (
    <Dialog actions={getActions()} contentStyle={ganttWarningDialogStyles.dialog} open={open}>
      {text}
    </Dialog>
  );
};

WarningDialog.propTypes = {
  dialogType: PropTypes.number,
  dismissGanttDialog: PropTypes.func,
  groupId: PropTypes.string,
  text: PropTypes.string
};

export default WarningDialog;
