/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { push } from 'connected-react-router';
import { MenuItem, SelectField } from 'material-ui';
import Dialog from '@shared/dialogs/dialog';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { fetchDataType } from '@actions/data-types-actions';
import * as dialog from '@constants/dialogs';
import { styleGuide } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import {
  getLatestPublishedTaskTypes,
  getTaskTypesInWorkflow
} from '@utils/workflow-utils';

const AddTaskDialog = () => {
  const [taskType, setTaskType] = useState('');
  const dispatch = useDispatch();
  const { task_type } = useSelector(state => state.dataTypes);
  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { workflow: workflows } = useSelector(state => state.dataTypes);
  const { cycleId, workflow } = dialogParams[dialog.ADD_TASK] || {};
  // Lodash's isEmpty flags numbers as non-empty, thus check if taskType is '' or > 0:
  const active = !isEmpty(taskType) || taskType > 0;
  const isDialogActive = activeDialogs[dialog.ADD_TASK];

  useEffect(() => {
    // When the dialog open, reload the task types, in case there are changes to them:
    if (isDialogActive) {
      dispatch(fetchDataType('task_type'));
    }
  }, [dispatch, isDialogActive]);

  const onClose = useCallback(
    () => {
      setTaskType('');
      dispatch(closeDashboardDialog(dialog.ADD_TASK));
    },
    [dispatch]
  );

  const onContinue = useCallback(
    () => {
      onClose();
      dispatch(push(
        {
          pathname: `/cycle/${cycleId}/task/new/${taskType}/`,
          state: { source: `/cycle/${cycleId}`, clear: true }
        }
      ));
    },
    [onClose, cycleId, dispatch, taskType]
  );

  if (!isDialogActive) {
    return null;
  }

  const actions = (
    <Fragment>
      <Button onClick={onClose}>CANCEL</Button>
      <Button
        color="primary"
        disabled={!active}
        onClick={onContinue}
        variant="contained"
      >
        CONTINUE
      </Button>
    </Fragment>
  );

  const onTaskTypeChange = (event, index, value) => setTaskType(value);

  const getTaskTypeItems = () => {
    if (task_type && workflow) {
      const taskTypes = getTaskTypesInWorkflow(task_type, workflows[workflow]);
      return getLatestPublishedTaskTypes(taskTypes).map(item => (
        <MenuItem key={item.id} value={item.id} primaryText={item.name} />
      ));
    }
    return null;
  };

  return (
    <Dialog
      actions={actions}
      dividers={false}
      onClose={onClose}
      open
      title="Create a task"
    >
      <SelectField
        floatingLabelText="Choose a task type"
        fullWidth
        onChange={onTaskTypeChange}
        value={taskType}
        {...styleGuide.select}
      >
        {getTaskTypeItems()}
      </SelectField>
    </Dialog>
  );
};

export default memo(AddTaskDialog);
