import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import LocationRow from '@components/workflow/cycle/content/section/location-row';
import Scrollable, { mapStateToProps, mapDispatchToProps } from '@shared/scrollable';
import ShowMore from '@shared/show-more';
import Header from './header';
import Map from './map';
import './section.scss';

const MAX_LOCATIONS = 5;

class LocationsSection extends Scrollable {
  state = { showAll: false };

  toggleShowAll = () => this.setState({ showAll: !this.state.showAll });

  getScrollToId = () => 'locations-section';

  checkProp = props => R.pathOr(null, ['data', 'segments', 'length'], props);

  getCount = () => {
    const { data } = this.props;
    if (data && data.segments) {
      return data.segments.length;
    }
    return 0;
  };

  onSegmentDelete = segmentId => {
    const { data, onChange } = this.props;
    if (data && data.segments && segmentId) {
      const newSegments = data.segments.filter(segment => segment.id !== segmentId);
      onChange('segments', newSegments);
    }
  };

  onSegmentSelect = segmentId => {
    const { data, onChange } = this.props;
    if (data && segmentId) {
      onChange('_selectedSegmentId', segmentId);
    }
  };

  getLocations = () => {
    const { data } = this.props;
    const { showAll } = this.state;
    if (data.segments) {
      return showAll ? data.segments : data.segments.slice(0, MAX_LOCATIONS);
    }
    return null;
  };

  render() {
    const { data, edit } = this.props;
    if (!data) {
      return null;
    }
    const count = this.getCount();
    // Skip section in details mode when there are no entries.
    if (!edit && count === 0) {
      return null;
    }
    const { showAll } = this.state;
    const locations = this.getLocations();
    return (
      <div styleName="section" id={this.getScrollToId()}>
        {(!edit || count > 0) && (
          <Header
            edit={false} {...{} /* 'Locations' header is always in read-only mode */}
            headerLabel="Locations"
            count={count}
          />
        )}
        {data.segments && <Map segments={data.segments} selectedSegmentId={data._selectedSegmentId} />}
        {locations && (
          <div styleName="section-field-list">
            {locations.map(segment => (
              <LocationRow
                edit={edit}
                key={segment.id}
                onDelete={this.onSegmentDelete}
                onSelect={this.onSegmentSelect}
                segment={segment}
                selectedSegmentId={data._selectedSegmentId}
              />
            ))}
          </div>
        )}
        <ShowMore list={data.segments} max={MAX_LOCATIONS} onClick={this.toggleShowAll} showAll={showAll} />
      </div>
    );
  }
}

LocationsSection.propTypes = {
  cycle: PropTypes.object,
  data: PropTypes.object,
  edit: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationsSection);
