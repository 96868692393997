import React from 'react';
import { useSelector } from 'react-redux';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import './task-type-status.scss';

const TaskTypeStatus = () => {
  const { data } = useSelector(state => state.dataDetail);
  const { task_status: taskStatuses } = useSelector(state => state.dataTypes);

  if (!data) {
    return null;
  }

  const response = data.response ? 'enabled' : 'disabled';
  const statuses = Object.values(taskStatuses);

  return (
    <div styleName="status-container">
      <div styleName="header">
        <div styleName="title">Status</div>
        <div styleName="subtitle">Response fields are {response}</div>
      </div>
      <div styleName="table">
        <div styleName="row">
          <div styleName="header-cell">name</div>
          <div styleName="header-cell">description</div>
          <div styleName="header-cell">permissions</div>
          <div styleName="header-cell">criteria</div>
        </div>
        {statuses.map(status => (
          <div styleName="row" key={status.id}>
            <div styleName="cell"><TaskStatusChip taskStatus={status} /></div>
            <div styleName="cell">{status.description}</div>
            <div styleName="cell">
              {(status.assigned_agency || !data.response) && <div>Assigned Agency</div>}
              {(status.owner_agency || !data.response) && <div>Owner Agency</div>}
            </div>
            <div styleName="cell">{status.criteria || 'None'}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TaskTypeStatus;
