import React, { Fragment, memo, useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { DATA_TYPES, ENTITY_DATA_TYPE, GROUP_DATA_TYPE, OVERLAP_DATA_TYPE } from '@components/config/constants';

import { Link } from 'react-router-dom';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuIcon from './icon';

import OverflowTooltipableLabel from '@shared/overflow-tooltipable-label';

import { verifyPermissions } from '@utils/permission-utils';
import { getDataTypeConfig } from '@components/config/selectors';

import styles from './section-item.scss';

const SectionItem = ({ sectionItem, currentDataType, currentSubType }) => {
  const [open, setOpen] = useState(false);
  const { children, icon, label, permissions, subType, dataType } = sectionItem;

  const configDataType = useMemo(() => {
    if (dataType === OVERLAP_DATA_TYPE) {
      return ENTITY_DATA_TYPE;
    }
    return dataType;
  }, [dataType]);

  const typeConfig = useSelector(state => (subType && getDataTypeConfig(state, configDataType, subType)) || null);

  const active = (
    dataType === currentDataType && 
    (Boolean(children) || !subType || subType === currentSubType)
  );
  const typeInfo = DATA_TYPES[dataType];

  const link = useMemo(() => {
    if (typeInfo.subType) {
      return `/${typeInfo.portal}/${dataType}/${subType}`;
    }
    return `/${typeInfo.portal}/${dataType}`;
  }, [dataType, subType, typeInfo]);

  const handleToggle = useCallback(() => {
    setOpen(!open);
  }, [open, setOpen]);

  const menuLabel = useMemo(() => {
    if (typeConfig?.label) {
      if (dataType === GROUP_DATA_TYPE) {
        return `${typeConfig.label} Groups`;
      }
      return typeConfig.label;
    }
    return label;
  }, [label, typeConfig, dataType]);

  useEffect(() => {
    setOpen(currentDataType === dataType);
  }, [setOpen, dataType, currentDataType]);

  if (!verifyPermissions(permissions)) {
    return null;
  }

  const listItemContents = (
    <Fragment>
      {icon &&
        <ListItemIcon classes={{root: styles.listIcon}}>
          <MenuIcon
            icon={icon}
            type={subType}
            active={active}
          />
        </ListItemIcon>
      }
      <ListItemText
        classes={{
          root: styles.listText,
          primary: styles.text
        }}
      >
        <OverflowTooltipableLabel>{menuLabel}</OverflowTooltipableLabel>
      </ListItemText>
    </Fragment>
  );

  if (children) {
    return (
      <Fragment>
        <ListItem
          button
          onClick={handleToggle}
          classes={{
            root: styles.listItem
          }}
        >
          <span className={styles.dropdownArrow}>
            {open ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
          </span>
          {listItemContents}
        </ListItem>
        <Collapse
          in={open}
          classes={{
            container: styles.collapse
          }}
        >
          <List>
            {children.map(item => (
              <SectionItem
                key={item.type}
                currentDataType={currentDataType}
                currentSubType={currentSubType}
                sectionItem={item}
              />
            ))}
          </List>
        </Collapse>
      </Fragment>
    );
  }
  return (
    <ListItem
      classes={{
        root: classNames(styles.listItem, {[styles.active]: active})
      }}
      component={Link}
      to={link}
      button
    >
      {listItemContents}
    </ListItem>
  );
};

SectionItem.propTypes = {
  currentDataType: PropTypes.string,
  currentSubType: PropTypes.string,
  sectionItem: PropTypes.object
};

export default memo(SectionItem);
