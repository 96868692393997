import React from 'react';

const LocationIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="location-path-1"
        d={`
            M5.25 0A5.246 5.246 0 0 0 0 5.25C0 9.188 5.25 15 5.25
            15s5.25-5.813 5.25-9.75A5.246 5.246 0 0 0 5.25 0zm0
            7.125a1.876 1.876 0 1 1 .001-3.751 1.876 1.876 0 0
            1-.001 3.751z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3.75 1.5)">
      <mask id="location-path-2" fill="#fff">
        <use xlinkHref="#location-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#location-path-2)">
        <path d="M-3.75-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default LocationIcon;
