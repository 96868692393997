import React, { Fragment, memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuList from '@material-ui/core/MenuList';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';

import ChipButton from './shared/button';
import FilterChipPopover from './shared/popover';
import FilterChipMenuItem from './shared/menu-item';

import styles from './select.scss';

const stopPropagation = event => event.stopPropagation();
const caseInsensitiveFilterOptionLabels = R.useWith(R.filter, [  // eslint-disable-line react-hooks/rules-of-hooks
  R.anyPass([
    R.useWith(R.test, [  // eslint-disable-line react-hooks/rules-of-hooks
      search => new RegExp(search),
      R.compose(R.toLower, R.prop('label'))
    ]),
    R.useWith(R.test, [  // eslint-disable-line react-hooks/rules-of-hooks
      search => new RegExp(search),
      // Use defaultTo('') since 'description' is not always set:
      R.compose(R.toLower, R.defaultTo(''), R.prop('description'))
    ])
  ]),
  R.identity
]);

const SelectFilterChip = ({ label, options, value, onChange, multiSelect = false, search }) => {
  const [modified, setModified] = useState(false);
  const [prevValue, setPrevValue] = useState(null);
  const [pendingValue, setPendingValue] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [optionsFilter, setOptionsFilter] = useState('');

  const [chipValues, filterActive] = useMemo(() => {
    if (pendingValue !== null) {
      if (multiSelect) {
        const labelsArray = options.filter(option => pendingValue.has(option.value)).map(option => option.label);
        if (labelsArray.length !== 0 && labelsArray.length !== options.length) {
          return [labelsArray, true];
        }
      } else {
        const currentLabel = options.find(option => option.value === pendingValue)?.label;
        if (currentLabel) {
          return [[currentLabel], true];
        }
      }
    }
    return [[], false];
  }, [options, multiSelect, pendingValue]);

  const [promotedOptions, filteredOptions] = useMemo(() => {
    if (!multiSelect || (search && optionsFilter.length > 0)) {
      return [[], caseInsensitiveFilterOptionLabels(optionsFilter, options)];
    }
    const promoted = [];
    const remaining = [];
    const promotedValues = multiSelect ? new Set(value) : new Set([value]);
    options.forEach(option => {
      if (promotedValues.has(option.value)) {
        promoted.push(option);
      } else {
        remaining.push(option);
      }
    });
    return [promoted, remaining];
  }, [value, options, multiSelect, search, optionsFilter]);

  const handleClose = useCallback(() => {
    setOpen(false);
    setTimeout(() => { // Allow the menu close animation to run before updating to avoid showing reshuffled options while closing.
      setAnchorEl(null);
      if (modified) {
        if (filterActive) {
          if (multiSelect) {
            const values = Array.from(pendingValue);
            onChange(values);
          } else {
            onChange(pendingValue);
          }
        } else {
          onChange(null);
          setPendingValue(null);
        }
        setModified(false);
      }
      setOptionsFilter('');
    }, 100);
  }, [onChange, multiSelect, modified, setModified, pendingValue, setPendingValue, setAnchorEl, setOpen, setOptionsFilter, filterActive]);

  const handleButtonClick = useCallback(event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (!open) {
      setOpen(true);
    } else {
      handleClose();
    }
  }, [anchorEl, setAnchorEl, open, setOpen, handleClose]);

  const handleSearchChange = useCallback(event => {
    if (search) {
      setOptionsFilter(event.target.value);
    }
  }, [search, setOptionsFilter]);

  const handleSearchClear = useCallback(() => {
    if (search) {
      setOptionsFilter('');
    }
  }, [search, setOptionsFilter]);

  const handleSelectItem = useCallback(optionValue => {
    if (multiSelect) {
      const newValues = new Set(pendingValue);
      if (newValues.has(optionValue)) {
        newValues.delete(optionValue);
      } else {
        newValues.add(optionValue);
      }
      setPendingValue(newValues);
      setModified(true);
      return;
    } 
    if (pendingValue === optionValue) {
      setPendingValue(null);
    } else {
      setPendingValue(optionValue);
    }
    setModified(true);
  }, [multiSelect, pendingValue, setPendingValue]);

  const handleSelectAll = useCallback(() => {
    if (multiSelect) {
      setPendingValue(new Set(options.map(option => option.value)));
      setModified(true);
    }
  }, [options, multiSelect]);

  const handleClearValues = useCallback(() => {
    if (multiSelect) {
      setPendingValue(new Set());
    } else {
      setPendingValue(null);
    }
    setModified(true);
  }, [multiSelect, setPendingValue]);

  if (value !== prevValue) {
    if (multiSelect) {
      setPendingValue(new Set(value));
    } else {
      setPendingValue(value);
    }
    setPrevValue(value);
  }

  return (
    <Fragment>
      <ChipButton
        label={label}
        values={chipValues}
        onClick={handleButtonClick}
      />
      <FilterChipPopover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        classes={{
          root: styles.selectMenu,
          paper: styles.selectContents
        }}
      >
        { search &&
          <TextField
            variant="standard"
            fullWidth
            value={optionsFilter}
            placeholder="Search"
            onChange={handleSearchChange}
            onKeyDown={stopPropagation}
            classes={{root: styles.search}}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  classes={{root: styles.searchAdornments}}
                >
                  {optionsFilter.length > 0 &&
                    <IconButton
                      color="inherit"
                      onClick={handleSearchClear}
                    >
                      <CloseIcon color="inherit"/>
                    </IconButton>
                  }
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        }
        <MenuList classes={{root: styles.options}}>
          {promotedOptions.length > 0 &&
            promotedOptions.map(option => (
              <FilterChipMenuItem
                key={option.value}
                option={option}
                onSelect={handleSelectItem}
                checked={option.value === pendingValue || (multiSelect && pendingValue !== null && pendingValue.has(option.value))}
              />
            ))
          }
          { promotedOptions.length > 0 && filteredOptions.length > 0 &&
            <Divider classes={{root: styles.optionDivider}}/>
          }
          {filteredOptions.length > 0 && filteredOptions.map(option => (
            <FilterChipMenuItem
              key={option.value}
              option={option}
              onSelect={handleSelectItem}
              checked={option.value === pendingValue || (multiSelect && pendingValue !== null && pendingValue.has(option.value))}
            />
          ))}
        </MenuList>
        {optionsFilter === '' &&
          <div className={styles.buttons}>
            {multiSelect &&
              <Fragment>
                <Button
                  onClick={handleSelectAll}
                  disabled={!filterActive && pendingValue !== null && pendingValue.size !== 0}
                  classes={{
                    root: styles.button,
                    disabled: styles.disabledButton
                  }}
                >
                  Select all
                </Button>
                <Divider orientation="vertical" classes={{root: styles.buttonDivider}} flexItem/>
              </Fragment>
            }
            <Button
              onClick={handleClearValues}
              disabled={pendingValue === null || (multiSelect && pendingValue.size === 0)}
              classes={{
                root: styles.button,
                disabled: styles.disabledButton
              }}
            >
              Clear
            </Button>
          </div>
        }
      </FilterChipPopover>
    </Fragment>
  );
};

SelectFilterChip.propTypes = {
  label: PropTypes.string.isRequired,
  multiSelect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
    icon: PropTypes.string
  })).isRequired,
  search: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string, PropTypes.bool])
};

export default memo(SelectFilterChip);
