import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import { topBarStyles } from '@constants/mui-theme';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { withRouter } from '@utils/router-utils';
import './top-bar.scss';

const TopBar = ({ location }) => {
  const dispatch = useDispatch();

  const onBackClick = useCallback(
    () => {
      const source = getDashboardBackURL(location, null, '/map');
      dispatch(push(source));
    },
    [dispatch, location]
  );

  return (
    <div styleName="top-bar-container">
      <div styleName="content">
        <IconButton onClick={onBackClick}>
          <FontIcon className="material-icons" color={topBarStyles.links.color}>
            arrow_back
          </FontIcon>
        </IconButton>
        <FontIcon className="material-icons" color={topBarStyles.links.color}>
          settings
        </FontIcon>
        <div styleName="title">
          Settings
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  location: PropTypes.object
};

export default withRouter(TopBar);

export { TopBar as PureTopBar };
