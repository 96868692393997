/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import { toggleTray } from '@actions/map-actions';
import { getTrayDepth } from '@selectors/map-selector';
import './tab-bar-toggle.scss';

class TabBarToggle extends Component {
  render() {
    const { trayOpen } = this.props;
    return (
      <div styleName={`map-tab-bar-toggle-container ${trayOpen ? 'open' : ''}`}>
        <FontIcon className="material-icons"
          onClick={this.props.toggleTray}>
          { trayOpen ? 'arrow_left' : 'arrow_right' }
        </FontIcon>
      </div>
    );
  }
}

TabBarToggle.propTypes = {
  toggleTray: PropTypes.func,
  trayOpen: PropTypes.bool
};

const mapStateToProps = state => {
  const depth = getTrayDepth(state);
  return { trayOpen: depth > 0 };
};

export default connect(mapStateToProps, { toggleTray })(TabBarToggle);
