import React from 'react';

const AttachmentIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="attachment-path-1"
        d={`
            M7.125 3.75v8.625a3 3 0 1 1-6 0V3a1.876 1.876 0 0 1 3.75 0v7.875c0
            .412-.338.75-.75.75a.752.752 0 0 1-.75-.75V3.75H2.25v7.125a1.876
            1.876 0 0 0 3.75 0V3a3 3 0 1 0-6 0v9.375A4.123 4.123 0 0 0 4.125
            16.5a4.123 4.123 0 0 0 4.125-4.125V3.75H7.125z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(5.25 .75)">
      <mask id="attachment-path-2" fill="#fff">
        <use xlinkHref="#attachment-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#attachment-path-2)">
        <path d="M-5.25-.75h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default AttachmentIcon;
