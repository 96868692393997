/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import {
  deleteAttachment,
  getAttachments
} from '@actions/attachment-actions';
import {
  popTray,
  setOverlapHoverEntity,
  selectSegment
} from '@actions/map-actions';
import { dotmapsGreen } from '@constants/colors';
import { getEntityTypeLabel, isWorkflowEnabled } from '@constants/config';
import { trayHeaderConfig } from '@constants/component-configs';
import { canEditGroups, canAccessWorkflow } from '@utils/permission-utils';
import { daysLong, getStreetViewBackground } from '@utils/map-utils';
import DefaultDate from '@shared/formatted-date-helper';
import {
  BaseTray,
  baseTrayMapDispatchToProps,
  baseTrayMapEntityProps,
  baseTrayMapStateToProps
} from './base-tray';
import DotmapsLoader from '@shared/dotmaps-loader';
import Header from './header';
import './tray.scss';

// Base class for tray entities:
export class BaseTrayEntity extends BaseTray {
  state = { activeTab: 'details', scrolling: false };

  getDataType = () => null;  // Implement in subclass.

  // Override in subclass if needed (i.e. for entities).
  getEntityType = () => this.getDataType();

  addToGroup = () => {};  // Implement in subclass.

  startCycle = () => {};

  deleteAttachment = index => {
    const { attachments } = this.props;
    if (attachments && attachments.length > index) {
      const attachment = attachments[index];
      this.props.deleteAttachment(attachment.id);
    }
  };

  setScrolling = event => {
    // there's an issue where shrinking the header causes the scrollbar to disappear, which
    // then cause it to expand, making it flicker back and forth between the two
    // so don't allow the header to shrink if unless the scrollable area is bigger
    // than the client height + the difference in header size (152px)
    const headerHeightDifference = trayHeaderConfig.fullHeight - trayHeaderConfig.scrollingHeight;
    if ((this.tabsElement.clientHeight + headerHeightDifference < event.target.scrollHeight) || this.state.scrolling) {
      this.setState({scrolling: event.target.scrollTop > 0});
    }
  };

  getSubHeaderDate = () => {
    const { entity } = this.props;
    const { start_date: startDate, end_date: endDate} = entity;
    const days = daysLong(startDate, endDate);
    const hasDate = startDate && endDate;

    if (hasDate) {
      return (
        <div>
          {<span><DefaultDate value={startDate} />–<DefaultDate value={endDate} /></span>}
          {days && !this.state.scrolling && <span>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{days}</span>}
        </div>
      );
    }

    return <div>Start/end dates unspecified.</div>;
  };

  clearSelection = () => {
    this.props.setOverlapHoverEntity();
    this.props.selectSegment();
  };

  // Implement these in subclasses:
  getSubHeader = () => {};

  getTabs = () => {};

  loadTabsData = () => {};  // eslint-disable-line no-unused-vars

  allowDetails = () => {};

  allowAddToGroup = () => canEditGroups();

  isReadOnly = () => false;

  hasOverlaps = () => false;  // Implement in subclass.

  getMoreActions() {
    const actions = [];
    const dataType = this.getDataType();
    const entityType = this.getEntityType();
    const entityTypeLabel = getEntityTypeLabel(entityType);
    const { id } = this.props.entity;
    if (this.allowDetails()) {
      const label = this.isReadOnly() ? 'View' : 'Edit';
      actions.push({ label: `${label} ${entityTypeLabel}`, link: `/${entityType}/${id}` });
      if (!this.isReadOnly()) {
        actions.push({ label: 'Duplicate', link: `/${entityType}/${id}/duplicate` });
      }
    }
    actions.push({ label: 'View activity log', link: `/logs/map/${dataType}/${id}` });
    if (this.allowAddToGroup()) {
      actions.push({ label: 'Add to group', action: this.addToGroup });
    }
    if (isWorkflowEnabled() && canAccessWorkflow('cycle', 'add')) {
      actions.push({ label: 'Start a workflow cycle', action: this.startCycle });
    }
    return actions;
  }

  render() {
    const { entity, position, pending } = this.props;
    if (pending) {
      return (
        <div styleName="map-tray-body-wrapper">
          <Header />
          <DotmapsLoader color={dotmapsGreen} display small />;
        </div>
      );
    }
    if (!entity) {
      return null;
    }
    return (
      <div styleName="map-tray-body-wrapper">
        <Header
          title={this.getHeaderDetailType()}
          iconUrl={this.getHeaderIcon()}
          moreActions={this.getMoreActions()}
        />
        <div styleName={`scrolling-container ${this.state.scrolling ? 'scrolling' : ''}`}>
          {position &&
            <div
              style={getStreetViewBackground(position)}
              styleName={`streetview-header ${this.state.scrolling ? 'scrolling' : ''}`} />
          }
          <div styleName={`map-tray-subheader ${this.state.scrolling ? 'scrolling' : ''}`}>{this.getSubHeader()}</div>
        </div>
        <div styleName="map-tray-tabs" ref={tabsElement => this.tabsElement = tabsElement}>
          {this.getTabs()}
        </div>
      </div>
    );
  }
}

BaseTrayEntity.propTypes = {
  attachments: PropTypes.array,
  deleteAttachment: PropTypes.func,
  getAttachments: PropTypes.func,
  pending: PropTypes.bool,
  popTray: PropTypes.func
};

export const baseTrayEntityMapStateToProps = state => {
  const props = baseTrayMapStateToProps(state);
  const { dataTypes } = state;
  const { agency_type: agencyTypes, agency: agencies } = dataTypes;
  return {
    agencies,
    agencyTypes,
    ...props
  };
};

export const baseTrayEntityMapEntityProps = (props, state, dataType) => {
  const {
    pending,
    entity,
    position
  } = baseTrayMapEntityProps(props, dataType);
  if (!entity) {
    return { entity: null, pending };
  }
  const { id } = entity;
  const { attachments } = state;
  let attch = attachments.attachments[`${dataType}${id}`];
  attch = attch ? attch : [];
  return {
    attachments: attch,
    entity,
    pending,
    position
  };
};

export const baseTrayEntityMapDispatchToProps = {
  ...baseTrayMapDispatchToProps,
  deleteAttachment,
  getAttachments,
  popTray,
  setOverlapHoverEntity,
  selectSegment
};
