import React from 'react';
import FontIcon from 'material-ui/FontIcon';
import { dotmapsInactiveBlue } from '@constants/colors';
import { topBarStyles } from '@constants/mui-theme';
import '../breadcrumb.scss';

const Separator = () => (
  <div styleName="breadcrumb-entry breadcrumb-separator">
    <FontIcon
      className="material-icons"
      color={dotmapsInactiveBlue}
      style={topBarStyles.breadCrumb.separator}
    >
      chevron_right
    </FontIcon>
  </div>
);

export default Separator;
