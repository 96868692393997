import { connect } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { setMapFilters } from '@actions/map-actions';
import BaseDateRangeDialog from '@shared/dialogs/base-date-range-dialog';
import { decodeDateFilters } from '@utils/date-utils';

class MapDateRangeDialog extends BaseDateRangeDialog {
  showContain = () => false;

  getSetFiltersFunction = () => this.props.setFilter;
}

export const mapStateToProps = state => {
  const { activeDialogs } = state.dashboard;
  const { start, end, contain } = decodeDateFilters(state.map.filters);
  return { activeDialogs, start, end, contain };
};

export default connect(mapStateToProps, {
  closeDialog: closeDashboardDialog,
  setFilter: setMapFilters
})(MapDateRangeDialog);

export { MapDateRangeDialog as PureMapDateRangeDialog };
