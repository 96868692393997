/* eslint-disable react/jsx-no-bind */
import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { DrawingManager } from '@react-google-maps/api';
import { mapConfig } from '@constants/component-configs';
import {
  detailLineStyle,
  detailPolygonStyle
} from '@constants/data-detail';
import MapFigure from './map-figure';
import EditMapFigure from './edit-map-figure';
import BaseMap from './base-map';

const EditMap = ({
  activeEnd,
  activeSegmentId,
  activeStart,
  activeType,
  geometryLocked,
  onActiveSegmentChanged,
  onPolygonChanged,
  segments
}) => {
  const onClick = ({ latLng }) => {
    switch (activeType) {
    case 'Point':
      if (!activeStart) {
        onActiveSegmentChanged({start: latLng.toJSON(), end: null});
      }
      break;
    case 'LineString':
      if (!activeStart) {
        onActiveSegmentChanged({start: latLng.toJSON(), end: activeEnd});
      } else if (!activeEnd) {
        onActiveSegmentChanged({start: activeStart, end: latLng.toJSON()});
      }
      break;
    default:
      break;
    }
  };

  const selectedSegmentIds = useMemo(() => activeSegmentId ? [activeSegmentId] : [], [activeSegmentId]);

  const setPolygonRef = ref => {
    window.editMapRef = ref;
  };

  const onPolygonComplete = useCallback(
    mapsShape => {
      onPolygonChanged(mapsShape);
      mapsShape.setMap(null);
    },
    [onPolygonChanged]
  );

  const onMouseUp = useCallback(
    () => onPolygonChanged(window.editMapRef.state.polygon),
    [onPolygonChanged]
  );

  const getComponents = useCallback(
    () => {
      if (segments) {
        return segments.filter(segment => segment.shape || segment.id === activeSegmentId).map(segment => {
          if (segment.id === activeSegmentId) {
            if (activeType !== 'Polygon') {
              return (
                <EditMapFigure
                  key={segment.id}
                  direction={segment.direction}
                  shape={segment.shape}
                  start={activeStart}
                  end={activeType === 'LineString' ? activeEnd : null}
                  onChange={onActiveSegmentChanged}
                  geometryLocked={geometryLocked}
                />
              );
            } else if (activeType === 'Polygon' && !segment.shape) {
              return (
                // eslint-disable-next-line react/jsx-key
                <DrawingManager
                  drawingMode="polygon"
                  onPolygonComplete={onPolygonComplete}
                  options={mapConfig.drawingManager}
                />
              );
            } else if (activeType === 'Polygon' && segment.shape) {
              return (
                <MapFigure
                  key={segment.id}
                  shape={segment.shape}
                  setPolygonRef={setPolygonRef}
                  onMouseUp={onMouseUp}
                  editable={!geometryLocked}
                  options={{...detailLineStyle, ...detailPolygonStyle}}
                />
              );
            }
          }
          return (
            <MapFigure
              key={segment.id}
              shape={segment.shape}
              options={{...detailLineStyle, ...detailPolygonStyle}}
            />
          );
        });
      }
      return [];
    },
    [
      activeEnd,
      activeSegmentId,
      activeStart,
      activeType,
      geometryLocked,
      onActiveSegmentChanged,
      onMouseUp,
      onPolygonComplete,
      segments
    ]
  );

  return (
    <BaseMap
      getComponents={getComponents}
      onClick={onClick}
      segments={segments}
      selectedSegmentIds={selectedSegmentIds}
    />
  );
};

EditMap.propTypes = {
  activeEnd: PropTypes.object,
  activeSegmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  activeStart: PropTypes.object,
  activeType: PropTypes.string,
  geometryLocked: PropTypes.bool,
  onActiveSegmentChanged: PropTypes.func,
  onPolygonChanged: PropTypes.func,
  segments: PropTypes.arrayOf(PropTypes.object)
};

export default memo(EditMap);
