import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FlatButton from 'material-ui/FlatButton';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import { fileAreaStyles } from '@constants/mui-theme';
import {
  dotmapsBackgroundError,
  dotmapsError,
  dotmapsGrayBorder
} from '@constants/colors';
import './file-area.scss';

const getFileFromChangeEvent = R.pathOr(null, ['target', 'files', 0]);
const getFileFromDropEvent = R.pathOr(null, ['dataTransfer', 'files', 0]);

class FileArea extends Component {
  state = { file: null };

  getFileInput = input => {
    this.fileInput = input;
  };

  click = event => {
    if (this.fileInput) {
      this.fileInput.click(event);
    }
  };

  onClear = event => {
    event.stopPropagation();
    this.setState({file: null});
    this.props.onFileClear();
  };

  handleFileChange = event => {
    if (event.target.files.length === 0) {
      // If len == 0, the user cancelled the file
      // upload dialog.
      this.onClear(event);
    } else {
      this.setState(
        {file: getFileFromChangeEvent(event)},
        () => this.fileSelected()
      );
    }
  };

  dragOverFile = event => {
    event.preventDefault();
    event.stopPropagation();
  };

  dropFile = event => {
    this.setState(
      {file: getFileFromDropEvent(event)},
      () => this.fileSelected()
    );
    event.preventDefault();
    event.stopPropagation();
  };

  fileSelected = () => {
    const { onFileSelect } = this.props;
    const { file } = this.state;
    onFileSelect(file);
  };

  getFileName = () => {
    const { file } = this.state;
    return file && file.name;
  };

  getButtonStyles = () => {
    const fileName = this.getFileName();
    if (fileName) {
      const { hasError } = this.props;
      const styles = {
        ...fileAreaStyles,
        style: {
          ...fileAreaStyles.style,
          backgroundColor: dotmapsGrayBorder
        }
      };

      if (hasError) {
        styles.style.backgroundColor = dotmapsBackgroundError;
      }
      delete styles.style.border;
      return styles;
    }
    return { ...fileAreaStyles };
  };

  getCloseButtonProps = () => {
    const { hasError } = this.props;
    const props = {
      onClick: this.onClear
    };
    if (hasError) {
      props.color = dotmapsError;
    }
    return props;
  };

  getTextStyleName = () => {
    const { hasError } = this.props;
    const fileName = this.getFileName();
    let styleName = null;
    if (fileName) {
      styleName = 'name-text';
    } else {
      styleName = 'name-text-placeholder';
    }
    if (hasError) {
      styleName += ' name-error';
    }
    return styleName;
  };

  render() {
    const {
      hasError,  // eslint-disable-line no-unused-vars
      onFileClear,  // eslint-disable-line no-unused-vars
      onFileSelect,  // eslint-disable-line no-unused-vars
      ...other
    } = this.props;
    const fileName = this.getFileName();

    return (
      <div styleName="file-area-wrapper">
        <FlatButton
          onClick={this.click}
          onDragOver={this.dragOverFile}
          onDrop={this.dropFile}
          {...this.getButtonStyles()}
        >
          <div styleName="file-area">
            <div styleName={this.getTextStyleName()}>
              {fileName || 'CHOOSE A FILE'}
            </div>
            {fileName && (
              <div styleName="clear-button">
                <CloseIcon {...this.getCloseButtonProps()} />
              </div>
            )}
          </div>
          <input
            ref={this.getFileInput}
            {...other}
            onChange={this.handleFileChange}
            type="file"
            hidden
          />
        </FlatButton>
      </div>
    );
  }
}

FileArea.propTypes = {
  hasError: PropTypes.bool,
  onFileClear: PropTypes.func,
  onFileSelect: PropTypes.func
};

export default FileArea;

