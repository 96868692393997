/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { reorderWorkflowTaskType } from '@actions/workflow-actions';
import * as colors from '@constants/colors';
import * as dialog from '@constants/dialogs';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddWorkflowTaskTypeDialog from '@shared/dialogs/add-workflow-task-type-dialog';
import DeleteWorkflowTaskTypeDialog from '@shared/dialogs/delete-workflow-task-type-dialog';
import { getWorkflowTaskTypes } from '@utils/workflow-utils';
import TaskTypeRow from './workflow-drawer-task-type-row';
import './details-layout-drawer.scss';

const WorkflowDrawer = ({ selectedTaskType, setSelectedTaskType }) => {
  const { id } = useParams();
  const { data, loading } = useSelector(state => state.dataDetail);
  const { task_type: task_types } = useSelector(state => state.dataTypes);
  const [droppedTaskType, setDroppedTaskType] = useState(null);  // Last moved (dropped) task type row.

  const dispatch = useDispatch();

  const taskTypes = useMemo(() => getWorkflowTaskTypes(data, task_types, droppedTaskType), [data, droppedTaskType, task_types]);

  useEffect(() => {
    // Set the first available task type as the selected one if none is selected.
    if (!selectedTaskType && taskTypes.length > 0) {
      setSelectedTaskType(taskTypes[0]);
    }
  }, [selectedTaskType, setSelectedTaskType, taskTypes]);

  // Drag-and-dropped trick:
  // (See the useEffect code in TaskTypeDrawerList for an explanation).
  useEffect(() => {
    if (loading === false) {
      setDroppedTaskType(null);
    }
  }, [loading, setDroppedTaskType]);

  if (!data) {
    return null;
  }

  const onAdd = () => dispatch(openDashboardDialog(dialog.ADD_WORKFLOW_TASK_TYPE, { id, workflow: data }));

  const onDelete = taskType => {
    setSelectedTaskType(taskType);
    dispatch(openDashboardDialog(dialog.DELETE_WORKFLOW_TASK_TYPE, { id, taskType, workflowName: data.name }));
  };

  const onDragEnd = result => {
    if (!result.destination) {
      // Dropped outside.
      return;
    }

    const taskType = taskTypes[result.source.index];  // Get dragged task type row.
    const new_order = result.destination.index + 1;  // Set new order value for this task type row.
    setDroppedTaskType({ ...taskType, order: new_order });  // Set it on the state to display the new order until data is reloaded.

    const payload = { new_order, order: taskType.order };

    // Call backend to save the new order:
    dispatch(reorderWorkflowTaskType(id, payload));
  };

  return (
    <Fragment>
      <div styleName="header row">
        <div styleName="row-left">Task types</div>
        <div styleName="row-right">
          <IconButton onClick={onAdd} style={{ padding: 0 }}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      {selectedTaskType && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="workflow-task-type-list">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  border: snapshot.isDraggingOver ? `2px dashed ${colors.dotmapsBlue}` : '2px solid white',
                  backgroundColor: snapshot.isDraggingOver ? '#E9F0FD' : colors.overlayTextColor,
                  boxSizing: 'border-box',
                  width: '100%'
                }}
              >
                {taskTypes.map((taskType, index) => (
                  <TaskTypeRow
                    index={index}
                    key={index}
                    onDelete={onDelete}
                    selectedTaskType={selectedTaskType}
                    setSelectedTaskType={setSelectedTaskType}
                    taskType={taskType}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
      <AddWorkflowTaskTypeDialog />
      <DeleteWorkflowTaskTypeDialog />
    </Fragment>
  );
};

WorkflowDrawer.propTypes = {
  selectedTaskType: PropTypes.object,
  setSelectedTaskType: PropTypes.func
};

export default WorkflowDrawer;
