/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AssignmentIcon from 'material-ui/svg-icons/action/assignment';
import { fetchTooltipData } from '@actions/tooltips-actions';
import BaseTooltip from '@components/activity-logs/tooltips';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import './tooltip.scss';

class TaskActivityTooltip extends BaseTooltip {
  getDataType = () => 'task_activity';

  getTitle = () => (
    <Fragment>
      <div styleName="body-title-icon">
        <AssignmentIcon />
      </div>
      <div styleName="body-title-text">
        {this.props.data.task_type_name}
      </div>
    </Fragment>
  );

  renderHeader = () => null;

  renderContent = () => {
    const { data, taskStatuses } = this.props;
    const statuses = Object.values(taskStatuses);
    const taskStatus = statuses.find(status => status.name === data.status_name);
    return (
      <div styleName="body-content">
        <div styleName="body-row body-item">
          {data.task_name}&nbsp;({data.agency_name})
        </div>
        <div styleName="body-row">
          <TaskStatusChip taskStatus={taskStatus} />
        </div>
      </div>
    );
  };

  renderClickLabel = () => 'VIEW TASK ACTIVITY';

  onLinkClick = () => {
    const { id, data } = this.props;
    this.openInNewWindow(`/cycle/${data.cycle_id}/task/${data.task}/activity/${id}/detect`);
  };

  onMouseOver = event => {
    const { id, data } = this.props;
    this.delayHoverOn(event);
    if (!data) {
      this.props.fetchTooltipData('task_activity', null, id);
    }
  };
}

TaskActivityTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskStatuses: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const { task_status: taskStatuses } = state.dataTypes;
  const { task_activity, error: { task_activity: error } } = state.tooltips;
  return { data: task_activity[props.id], error, taskStatuses };
};

const mapDispatchToProps = { fetchTooltipData };

export default connect(mapStateToProps, mapDispatchToProps)(TaskActivityTooltip);
