import moment from 'moment-timezone';
import BaseDateFilter from './base-date-filter';
import * as dialog from '@constants/dialogs';

class PastDateFilter extends BaseDateFilter {
  buildSelectedId(start, end) {
    if (start === null && end === null) {
      return 1; // Any time:
    }
    const startDate = moment(start, 'YYYY-MM-DD');
    const endDate = moment(end, 'YYYY-MM-DD');
    if (startDate.isSame(endDate, 'day')) {
      if (startDate.isSame(moment(), 'day')) {
        return 2; // Today
      }
      if (startDate.isSame(moment().subtract(1, 'days'), 'day')) {
        return 3; // Yesterday
      }
    }
    if (endDate.isSame(moment(), 'day')) {
      if (startDate.isSame(moment().subtract(7, 'days'), 'day')) {
        return 4; // Last 7 days
      }
      if (startDate.isSame(moment().subtract(30, 'days'), 'day')) {
        return 5; // Last 30 days
      }
      if (startDate.isSame(moment().subtract(90, 'days'), 'day')) {
        return 6; // Last 90 days
      }
    }
    // Else it's a custom range date:
    return this.getCustomRangeId();
  }
  filterChange(event, key, value) {
    let contain = true;
    let end = null;
    let start = null;
    switch (value) {
    case 1: { // Any time:
      contain = false;
      break;
    }
    case 2: { // Today:
      start = moment();
      end = moment();
      break;
    }
    case 3: { // Yesterday:
      start = moment().subtract(1, 'days');
      end = moment().subtract(1, 'days');
      break;
    }
    case 4: { // Last 7 days:
      start = moment().subtract(7, 'days');
      end = moment();
      break;
    }
    case 5: { // Last 30 days:
      start = moment().subtract(30, 'days');
      end = moment();
      break;
    }
    case 6: { // Last 90 days;
      start = moment().subtract(90, 'days');
      end = moment();
      break;
    }
    case this.getCustomRangeId(): {
      // The date dialog will set the new date on
      // the store, thus this component will be
      // updated since we listen for start/end/contains.
      const { fieldName = 'created' } = this.props;
      this.props.openDialog(dialog.DATE_RANGE, { fieldName });
      return;
    }
    default:
      break;
    }
    const newState = {
      contain,
      end,
      selected: value,
      start
    };
    this.setState(newState);
  }
  getDateItems() {
    return [
      {id: 1, name: 'Any time'},
      {id: 2, name: 'Today'},
      {id: 3, name: 'Yesterday'},
      {id: 4, name: 'Last 7 days'},
      {id: 5, name: 'Last 30 days'},
      {id: 6, name: 'Last 90 days'},
      {id: this.getCustomRangeId(), name: 'Custom range'}
    ];
  }
}

export default PastDateFilter;

