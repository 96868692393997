/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { replace } from 'connected-react-router';
import { VIEW_TYPES } from '@constants/workflow';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DefaultDate from '@shared/formatted-date-helper';
import Scrollable, { mapStateToProps as baseMapStateToProps, mapDispatchToProps } from '@shared/scrollable';
import DataCard from '@shared/ui-library/card/data-card';
import AttributeLabel from '@shared/ui-library/attribute/attribute-label';
import AttributeLabelContainer from '@shared/ui-library/attribute/attribute-label-container';
import { getAgencyId, getUserId } from '@utils/permission-utils';
import {
  getDefaultActivity,
  goToDefaultTask,
  isOwnerView
} from '@utils/workflow-utils';

class TaskItemCard extends Scrollable {
  getScrollToId = () => `task-card-${this.props.task.id}`;

  skipCheck = () => true;

  isSelected = () => {
    const { task } = this.props;
    // Build the path for this task-card to check if it's selected:
    const path = `/cycle/${task.cycle}/task/${task.id}/`;
    // Get the current location pathname and add a trailing slash if it's not
    // already there, for comparison:
    const url = this.props.location.pathname.replace(/\/?$/, '/');
    return url.startsWith(path);
  };

  goToTask = () => {
    if (!this.isSelected()) {
      const { params: { viewType }, task } = this.props;
      goToDefaultTask(task, viewType || VIEW_TYPES.owner, this.props.replace);
    }
  };

  getTaskTypeName = () => {
    const { task, taskType } = this.props;
    const taskTypeEntry = taskType[task.task_type];
    if (taskTypeEntry) {
      return taskTypeEntry.name;
    }
    return null;
  };

  getCount = name => {
    const { task: { activities } } = this.props;
    return activities.filter(activity => activity.status_name === name).length;
  };

  renderFooterStatusItem = (name, text) => {
    const statusItem = Object.values(this.props.taskStatuses).find(item => item.name === name);
    const icon = (
      <span style={{
        backgroundColor: statusItem.color,
        borderRadius: '50%',
        display: 'inline-flex',
        height: '0.5rem',
        width: '0.5rem'
      }} />
    );
    return (
      <AttributeLabel
        contained={false}
        key={statusItem.id}
        text={<span>{icon}&nbsp;{text}&nbsp;</span>}
        toolTip={name}
      />
    );
  };

  renderSingleAgencyFooter = () => {
    const { agencies, params: { taskActivityId, viewType }, task } = this.props;
    const activity = getDefaultActivity(task, taskActivityId, viewType);
    if (!activity) {
      return null;
    }
    const agencyName = agencies[activity.agency].name;
    return this.renderFooterStatusItem(activity.status_name, agencyName);
  };

  getFooterStatusItem = name => {
    const count = this.getCount(name);
    return this.renderFooterStatusItem(name, count);
  };

  // Tells whether to show the single agency version of the footer or the multiple one.
  showSingle = () => {
    const { params: { viewType }, task } = this.props;
    // If the task has only a single agency, then use the single version, this has
    // the highest precedence.
    if (task.activities.length === 1) {
      return true;
    }

    // This should not happen, since tasks should always have at least one
    // activity (i.e. assigned to at least one agency), but if there are
    // no activities, show the multiple version (which shows all
    // counts in zero), since there's no single activity to show.
    if (task.activities.length === 0) {
      return false;
    }

    // The viewType URL parameter has precedence over other ways of detecting
    // if the user is a task owner or responder.
    if (isOwnerView(viewType)) {
      // Multiple version if it's an owner (from viewType).
      return false;
    }

    if (viewType === VIEW_TYPES.agency || viewType === VIEW_TYPES.assignee) {
      // Use single version (i.e. a responder).
      return true;
    }

    // Task owners should show the multiple version (unless there's a single agency,
    // which is the case handled above).
    const userId = getUserId();
    if (task.owner === userId) {
      // This is the same as falling through the last "return false" statement,
      // but it's good to keep it to know how this method must behave regarding
      // permissions.
      return false;
    }

    // If you are in the same agency as the task owner, you should see the multiple version.
    const agencyId = getAgencyId();
    if (task.owner_agency_id === agencyId) {
      return false;
    }

    // Responders can only see the single version.
    if (task.activities.some(activity => activity.assignee === userId)) {
      return true;
    }

    return false;
  };

  renderFooter = () => {
    if (this.showSingle()) {
      return this.renderSingleAgencyFooter();
    }
    return [
      this.getFooterStatusItem('Assigned'),
      this.getFooterStatusItem('In progress'),
      this.getFooterStatusItem('Submitted'),
      this.getFooterStatusItem('Completed')
    ];
  };

  render() {
    const { task } = this.props;
    return (
      <DataCard
        borderRadius={false}
        icon={<AssignmentIcon />}
        onClick={() => this.goToTask()}
        selected={this.isSelected()}
        title={task.name}
      >
        <span id={this.getScrollToId()}>
          {this.getTaskTypeName()}
          {task.due_date && <span>&nbsp;&bull;&nbsp;Due&nbsp;<DefaultDate value={task.due_date} /></span>}
        </span>
        <AttributeLabelContainer>
          {this.renderFooter()}
        </AttributeLabelContainer>
      </DataCard>
    );
  }
}

TaskItemCard.propTypes = {
  agencies: PropTypes.object,
  location: PropTypes.object,
  params: PropTypes.object,
  replace: PropTypes.func,
  task: PropTypes.object,
  taskStatuses: PropTypes.object,
  taskType: PropTypes.object
};

const mapStateToProps = state => {
  const { agency: agencies, task_status: taskStatuses } = state.dataTypes;
  return { agencies, taskStatuses, ...baseMapStateToProps(state) };
};

export default connect(mapStateToProps, { ...mapDispatchToProps, replace })(TaskItemCard);
