/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog, openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckboxControl from '@shared/form/checkbox-control';
import { getSegmentTitle } from '@utils/segment-schedule/common';
import './bulk-schedule-dialog.scss';

const BulkScheduleDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const [locations, setLocations] = useState([]);

  const { data } = dialogParams[dialog.BULK_SCHEDULE] || {};

  const setAll = useCallback(
    value => setLocations(new Array(data?.segments.length).fill(value)),
    [data?.segments]
  );

  // Initialize checkbox state with "unchecked":
  useEffect(() => {
    if (data?.segments.length > 0) {
      setAll(false);
    }
  }, [data?.segments, setAll]);

  const isDialogActive = activeDialogs[dialog.BULK_SCHEDULE];
  const isAnyChecked = locations.some(isChecked => isChecked);
  const isAllChecked = locations.every(isChecked => isChecked);

  const onNext = () => {
    const selectedIndexes = locations.map((isChecked, index) => isChecked ? index : null).filter(index => index !== null);
    const selected = selectedIndexes.map(index => data.segments[index]);
    const params = {
      isBulk: true,
      start_date: data.start_date,
      end_date: data.end_date,
      selected
    };
    dispatch(openDashboardDialog(dialog.EDIT_SCHEDULE, params));
  };

  const onToggleAll = () => {
    if (isAllChecked) {
      setAll(false);
    } else {
      setAll(true);
    }
  };

  const onToggle = index => {
    setLocations(prevLocations => {
      const updatedLocations = [...prevLocations];
      updatedLocations[index] = !updatedLocations[index];
      return updatedLocations;
    });
  };

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.BULK_SCHEDULE));
    },
    [dispatch]
  );

  if (!isDialogActive || !data) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Bulk edit schedule
        </div>
      </DialogTitle>
      <DialogContent>
        <div styleName="bulk-schedule-container">
          <div styleName="content-text">
            Choose the locations for which you want to bulk edit the schedule:
          </div>
          <div styleName="location-row">
            <CheckboxControl
              label={<div styleName="all-toggle">All locations</div>}
              indeterminate={isAnyChecked && !isAllChecked}
              onChange={() => onToggleAll()}
              value={isAnyChecked}
            />
          </div>
          {data?.segments.map((segment, index) => (
            <div styleName="location-row" key={index}>
              <CheckboxControl
                label={getSegmentTitle(segment)}
                onChange={() => onToggle(index)}
                value={locations[index]}
              />
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          disabled={!isAnyChecked}
          onClick={onNext}
          endIcon={<ChevronRightIcon />}
          variant="contained"
        >
          NEXT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BulkScheduleDialog;
