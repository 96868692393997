import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setOverlapHoverEntity, openEntityTray } from '@actions/map-actions';
import TrayCard from '@components/map/tray/tray-card';
import EntityChips from '@components/map/tray/tray-card/entity-card/entity-chips';
import { getAgencies, getAgencyTypes } from '@selectors/data-table-selector';
import { getHoverEntityId } from '@selectors/map-selector';
import { getAgencyIcon, getCategoryIcon, getEntityCategoryIcon } from '@utils/icon-utils';

class EntityCard extends Component {
  selectEntity = () => {
    this.props.setOverlapHoverEntity(this.props.entity);
  };

  deselectEntity = () => {
    this.props.setOverlapHoverEntity();
  };

  openEntity = () => {
    const segment = this.props.entity.segments[0];
    const entityProps = {
      id: this.props.entity.id,
      position: {lat: segment.center[1], lng: segment.center[0]},
      type: this.props.entity.type_name
    };
    this.props.openEntityTray({...entityProps, fitEntityBounds: true});
  };

  getIcon = () => {
    const { agencyTypes, entity, entityTypes } = this.props;
    const agencyType = agencyTypes[entity.agency_type];
    if (agencyType) {
      return getAgencyIcon(agencyType);
    }
    return getCategoryIcon(entity.type_name, getEntityCategoryIcon(entity, entityTypes));
  };

  render() {
    const { agencies, entity, selectedEntityId } = this.props;
    const agency = agencies[entity.agency];
    const selected = entity.id === selectedEntityId;
    const icon = this.getIcon();
    return (
      <TrayCard
        title={agency ? agency.name : ''}
        subtitle={entity.name}
        iconUrl={icon}
        selected={selected}
        onSelect={this.selectEntity}
        onDeselect={this.deselectEntity}
        onOpen={this.openEntity}
      >
        <EntityChips entity={entity}/>
      </TrayCard>
    );
  }
}

EntityCard.propTypes = {
  agencies: PropTypes.object,
  agencyTypes: PropTypes.object,
  entity: PropTypes.object,
  entityTypes: PropTypes.object,
  openEntityTray: PropTypes.func,
  selectedEntityId: PropTypes.number,
  setOverlapHoverEntity: PropTypes.func
};

const mapStateToProps = state => ({
  agencies: getAgencies(state),
  agencyTypes: getAgencyTypes(state),
  entityTypes: state.dataTypes.map_type,
  selectedEntityId: getHoverEntityId(state)
});

export default connect(mapStateToProps, {setOverlapHoverEntity, openEntityTray})(EntityCard);
