import { Component } from 'react';
import PropTypes from 'prop-types';
import { scrollToField } from '@actions/data-detail-actions';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

// Allows a component to scroll after properties load.
class Scrollable extends Component {
  componentDidMount = () => {
    if (this.skipCheck()) {
      this.scroll();
    }
  };

  componentDidUpdate = prevProps => {
    if (this.skipCheck() || this.checkProp(this.props) !== this.checkProp(prevProps)) {
      this.scroll();
    }
  };

  scroll = () => {
    const elementId = this.getScrollToId();
    const { scrollId } = this.props;
    if (scrollId === elementId) {
      const element = document.getElementById(scrollId);
      scrollIntoView(element, { scrollMode: 'if-needed' });
      this.clearScroll();
    }
  };

  // The DOM 'id' to scroll to.
  getScrollToId = () => null;  // Implement in subclass.

  // Return the value from the specified props that we want to check for changes.
  // eslint-disable-next-line no-unused-vars
  checkProp = props => null;  // Implement in subclass.

  // Clear the scroll id in the store, since we executed the scroll action.
  clearScroll = () => this.props.scrollToField(null);

  // Skip checking for changes, if an 'scrollId' is set, scroll to it
  // as soon as it's set (this is useful for when there's a single render
  // after an action is executed).
  // This is disbled by default, enable on components that have that behavior.
  skipCheck = () => false;
}

Scrollable.propTypes = {
  scrollId: PropTypes.string,
  scrollToField: PropTypes.func
};

export const mapStateToProps = state => {
  const { scrollId } = state.dataDetail;
  return { scrollId };
};

export const mapDispatchToProps = {
  scrollToField
};

export default Scrollable;
