/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { Marker, Polyline, Polygon } from '@react-google-maps/api';
import { openAreaTray, openLayerTray } from '@actions/map-actions';
import { markerConfig, mapConfig } from '@constants/component-configs';
import { getTrayLayer, getHoverLayerId, getTrayLayersIds, isAnyTrayItemHovered } from '@selectors/map-selector';
import { getLayerIcon } from '@utils/icon-utils';
import { generatePath, generatePolygonPaths, getPointShapeCoords } from '@utils/map-utils';
import { shallowEqual } from '@utils/react-utils';
import { isLayerMarkerVisible } from '@utils/markers-utils';

class Figure extends Component {
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    // If any property is not the same, render:
    if (
      !shallowEqual(nextProps.id, this.props.id) ||
      !shallowEqual(nextProps.icon, this.props.icon) ||
      !shallowEqual(nextProps.layer, this.props.layer) ||
      !shallowEqual(nextProps.mapStyle, this.props.mapStyle) ||
      !shallowEqual(nextProps.markerVisible, this.props.markerVisible) ||
      !shallowEqual(nextProps.styleCondition, this.props.styleCondition)
    ) {
      return true;
    }

    // For the entity (layer) on tray property,
    // check first if it changed, then if the computed 'shouldHighlight' value changes,
    // we must render, else don't since this marker is not affected.
    if (!shallowEqual(nextProps.isAnyTrayItemHovered, this.props.isAnyTrayItemHovered) ||
        !shallowEqual(nextProps.trayEntity, this.props.trayEntity) ||
        !shallowEqual(nextProps.trayItemIds, this.props.trayItemIds) ||
        !shallowEqual(nextProps.trayHoverId, this.props.trayHoverId)) {
      const nextIsHighlighted = this.shouldHighlight(nextProps) || false;
      const thisIsHighlighted = this.shouldHighlight(this.props) || false;
      return !shallowEqual(nextIsHighlighted, thisIsHighlighted);
    }
    return false;
  }

  onMouseMove = event => {
    window.highlightCircle.setCenter(event.latLng);
  };

  pointClick = () => {
    const { shape } = this.props.layer;
    if (shape.type === 'Point') {
      this.props.openAreaTray(getPointShapeCoords(shape));
    }
  };

  shouldHighlight = props => {
    const {
      trayEntity,
      trayHoverId
    } = props;
    const { id } = props.layer;
    return id && (
      (trayEntity && trayEntity.id && id === trayEntity.id) ||
      (trayHoverId && id === trayHoverId)
    );
  };

  isHighlighted = () => {
    return this.shouldHighlight(this.props);
  };

  getStyle = () => {
    const { attrs } = this.props.layer;
    const { mapStyle, styleCondition } = this.props;
    const styles = styleCondition ? mapStyle[attrs[styleCondition]] : mapStyle;
    return cloneDeep(styles);
  };

  getMapStyle = () => {
    const mapStyle = this.getStyle();
    if (!mapStyle) {
      return {};
    }
    if (this.isHighlighted()) {
      mapStyle.zIndex = 210;
      if (mapStyle.strokeWeight) {
        mapStyle.strokeWeight = mapStyle.strokeWeight + 3;
      }
      if (mapStyle.fillColor) {
        mapStyle.fillOpacity = 0.6;
      }
      if (mapStyle.icons && mapStyle.icons.length > 0) {
        mapStyle.icons[0].icon.scale = 1.5;
        mapStyle.icons[0].repeat = '12px';
      }
    } else
      // The mapStyle is a new object (because of cloneDeep)
      // but we need to restore opacity if we are not hightlighting
      // the layer, because the existing Google Maps object already
      // has an opacity set.
      if (mapStyle.fillColor) {
        mapStyle.fillOpacity = 0.3;
      }
    return mapStyle;
  };

  render() {
    const { markerVisible } = this.props;
    if (!markerVisible) {
      return null;
    }
    const { id, shape } = this.props.layer;
    const { icon } = this.props;
    const mapStyle = this.getMapStyle();
    const highlighted = this.isHighlighted();
    const figureId = `${this.props.name}-${id}`;
    const layerProps = {
      options: { ...mapStyle, clickable: false },
      onMouseMove: this.onMouseMove,
      onMouseOver: this.onMouseMove
    };
    if (shape.type === 'Point') {
      const markerProps = {
        options: markerConfig.options,
        onClick: this.pointClick,
        onMouseOver: this.onMouseMove
      };
      return (
        <Marker
          key={figureId}
          icon={getLayerIcon(icon, this.isHighlighted() ? 15 : 0)}
          position={getPointShapeCoords(shape)}
          {...markerProps}
        />
      );
    } else
      if (shape.type === 'LineString') {
        const path = generatePath(shape.coordinates);
        const lines = [<Polyline key={figureId} path={path} {...layerProps} />];
        if (highlighted) {
          lines.push(<Polyline key={`${figureId}-1`} path={path} options={mapConfig.hoverStyle.middle} />);
          lines.push(<Polyline key={`${figureId}-2`} path={path} options={mapConfig.hoverStyle.outer} />);
        }
        return lines;
      } else
        if (shape.type === 'MultiLineString') {
          const lines = [];
          shape.coordinates.forEach((coords, index) => {
            const path = generatePath(coords);
            lines.push(<Polyline key={`${figureId}-${index}`} path={path} {...layerProps} />);
            if (highlighted) {
              lines.push(<Polyline key={`${figureId}-${index}-1`} path={path} options={mapConfig.hoverStyle.middle} />);
              lines.push(<Polyline key={`${figureId}-${index}-2`} path={path} options={mapConfig.hoverStyle.outer} />);
            }
          });
          return lines;
        }
    if (shape.type === 'Polygon') {
      const polygonPath = generatePolygonPaths(shape.coordinates);
      const polygonOutlinePath = generatePath(shape.coordinates[0]);
      if (highlighted) {
        return (
          <div>
            <Polygon key={figureId} paths={polygonPath} {...layerProps}/>
            <Polygon key={`${figureId}-1`} 
              paths={polygonPath} 
              options={{...mapConfig.polygonHoverStyle.middle, strokePosition: google.maps.StrokePosition.OUTSIDE}}
            />
            <Polygon key={`${figureId}-2`} 
              paths={polygonPath} 
              options={{...mapConfig.polygonHoverStyle.outer, strokePosition: google.maps.StrokePosition.OUTSIDE}}
            />
            {mapStyle && Object.prototype.hasOwnProperty.call(mapStyle, 'icons') &&
            <Polyline key={`outline-${figureId}`} path={polygonOutlinePath} {...layerProps}/>
            }
          </div>
        );
      }
      return (
        <div>
          <Polygon key={figureId} paths={polygonPath} {...layerProps}/>
          {mapStyle && 
          Object.prototype.hasOwnProperty.call(mapStyle, 'icons') &&
          <Polyline key={`outline-${figureId}`} path={generatePath(shape.coordinates[0])} {...layerProps}/>
          }
        </div>
      );
    } else
      if (shape.type === 'MultiPolygon') {
        if (highlighted) {
          const polygons = [];
          shape.coordinates.forEach((coords, index) => {
            const polygonPath = generatePolygonPaths(coords);
            const polygonOutlinePath = generatePath(coords[0]);
            polygons.push(<div key={`wrapper-${figureId}-${index}`}>
              <Polygon key={figureId} paths={polygonPath} {...layerProps}/>
              <Polygon key={`${figureId}-${index}-1`}
                paths={polygonPath}
                options={{...mapConfig.polygonHoverStyle.middle, strokePosition: google.maps.StrokePosition.OUTSIDE}}
              />
              <Polygon key={`${figureId}-${index}-2`}
                paths={polygonPath}
                options={{...mapConfig.polygonHoverStyle.outer, strokePosition: google.maps.StrokePosition.OUTSIDE}}
              />
              {mapStyle && Object.prototype.hasOwnProperty.call(mapStyle, 'icons') &&
              <Polyline key={`outline-${figureId}-${index}`} path={polygonOutlinePath} {...layerProps}/>
              }
            </div>);
          });
          return polygons;
        }
        return shape.coordinates.map((coords, index) => (
          <div key={`wrapper-${figureId}-${index}`}>
            <Polygon
              key={`${figureId}-${index}`}
              paths={generatePolygonPaths(coords)}
              {...layerProps}
            />
            {mapStyle && Object.prototype.hasOwnProperty.call(mapStyle, 'icons') &&
            <Polyline
              key={`outline-${figureId}-${index}`}
              path={generatePath(coords[0])}
              {...layerProps}
            />
            }
          </div>
        ));
      }
    return null;
  }
}

Figure.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.number,
  isAnyTrayItemHovered: PropTypes.bool,
  layer: PropTypes.object,
  mapStyle: PropTypes.object,
  markerVisible: PropTypes.bool,
  name: PropTypes.string,
  openAreaTray: PropTypes.func,
  openLayerTray: PropTypes.func,
  styleCondition: PropTypes.string,
  trayEntity: PropTypes.object,
  trayHoverId: PropTypes.number,
  trayItemIds: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const trayEntity = getTrayLayer(state);
  const trayHoverId = getHoverLayerId(state);
  const { layers } = state;
  const {
    icon,
    map_style: mapStyle,
    style_condition: styleCondition
  } = layers[props.name];
  const markerVisible = isLayerMarkerVisible(state, props.name, props.layer);
  return {
    icon,
    isAnyTrayItemHovered: isAnyTrayItemHovered(state),
    mapStyle,
    markerVisible,
    styleCondition,
    trayEntity,
    trayItemIds: getTrayLayersIds(state),
    trayHoverId
  };
};

export default connect(mapStateToProps, { openAreaTray, openLayerTray })(Figure);
