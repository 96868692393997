import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { toggleFilterTopBar } from '@actions/dashboard-actions';
import { resetDatatableFilter } from '@actions/data-table-actions';
import { getTopBarConfig } from '@constants/config';
import SharedTopFilterBar from '@shared/top-filter-bar';
import { showFilterBar } from '@utils/filter-utils';
import DashboardFilters from './dashboard-filters';

class TopFilterBar extends Component {
  resetFilters = () => {
    const { dataType } = this.props;
    const { params: { dataType: subType } } = this.props;
    this.props.resetFilters(dataType, subType);
  };

  is = name => {
    const { location: { pathname } } = this.props;
    const path = pathname.split('/');
    return R.pathOr('', [3], path) === name;
  };

  getTopBarFiltering = () => {
    const { params: { dataType: subType } } = this.props;
    if (this.is('group')) {
      return getTopBarConfig().group[subType].filtering;
    } else if (this.is('activity')) {
      return getTopBarConfig().activity[subType].filtering;
    } else if (this.is('task')) {
      return getTopBarConfig().task[subType].filtering;
    }
    return getTopBarConfig()[subType].filtering;
  };

  render() {
    const { dataType } = this.props;
    const { params: { dataType: subType } } = this.props;
    if (!showFilterBar(this.props) || !this.getTopBarFiltering()) {
      return null;
    }
    return (
      <SharedTopFilterBar {...this.props} resetFilters={this.resetFilters}>
        <DashboardFilters dataType={dataType} subType={subType} />
      </SharedTopFilterBar>
    );
  }
}

TopFilterBar.propTypes = {
  dataType: PropTypes.string,
  filterTopBarOpen: PropTypes.bool,
  location: PropTypes.object,
  params: PropTypes.object,
  resetFilters: PropTypes.func,
  toggleFilterTopBar: PropTypes.func
};

const mapStateToProps = state => {
  const { filterTopBarOpen } = state.dashboard;
  return { filterTopBarOpen };
};

export default connect(mapStateToProps, { resetFilters: resetDatatableFilter, toggleFilterTopBar })(TopFilterBar);
