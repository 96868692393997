/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { updateDataField } from '@actions/data-detail-actions';
import Header from '@components/workflow/cycle/content/section/header';
import List from '@components/workflow/cycle/content/section/linked-entities-list';
import Map from '@components/workflow/cycle/content/section/map';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import { getCycleGroupEntities } from '@selectors/workflow-selector';
import InputSearch from '@shared/input-search';
import Scrollable, {
  mapStateToProps as baseMapStateToProps,
  mapDispatchToProps as baseMapDispatchToProps
} from '@shared/scrollable';
import {
  activeEntitiesToOptions,
  renderEntityListItem
} from '@utils/autocomplete-utils';
import { filterSelectedEntities } from '@utils/workflow-utils';
import './section.scss';

class LinkedEntitiesSection extends Scrollable {
  state = {
    entityIds: []
  };

  getScrollToId = () => 'linked-entities-section';

  checkProp = props => R.pathOr(null, ['data', 'segments', 'length'], props);

  getCount = () => {
    const { data } = this.props;
    if (data && data.entities) {
      return data.entities.length;
    }
    return 0;
  };

  onEntitiesChange = (event, newValues) => {
    if (Array.isArray(newValues)) {
      const values = newValues.map(value => value.value);
      this.setState({ entityIds: values });
    } else {
      this.setState({ entityIds: [newValues.value] });
    }
  };

  hideLinkedEntitiesForm = () => {
    this.props.updateDataField('_showLinkedEntitiesForm', false);
  };

  linkClick = () => {
    const { cycleEntities } = this.props;
    const entities = filterSelectedEntities(cycleEntities, this.state.entityIds);
    this.props.updateDataField('entities', entities);
    this.hideLinkedEntitiesForm();
  };

  addEntity = () => {
    this.props.updateDataField('_showLinkedEntitiesForm', true);
  };

  removeEntity = id => {
    const { cycleEntities } = this.props;
    const newEntityList = this.state.entityIds.filter(entityId => entityId !== id);
    this.setState({ entityIds: newEntityList });
    const entities = filterSelectedEntities(cycleEntities, newEntityList);
    this.props.updateDataField('entities', entities);
  };

  getAllSegments = () => {
    const { data: { entities } } = this.props;
    return [].concat(...entities.map(entity => entity.segments));
  };

  render() {
    const { cycleEntities, data, edit, onChange } = this.props;
    if (!data) {
      return null;
    }
    const count = this.getCount();
    // Skip section in details mode when there are no entries.
    if (!edit && count === 0) {
      return null;
    }
    const { entityIds } = this.state;
    const { entities } = data;
    const hasSelected = entityIds.length > 0;
    return (
      <div styleName="section" id={this.getScrollToId()}>
        {(data._showLinkedEntitiesForm || !edit || count > 0) && (
          <Header
            edit={data._showLinkedEntitiesForm ? false : edit}
            headerLabel="Linked entities"
            addLabel="Add entity"
            count={count}
            onAdd={this.addEntity}
          />
        )}
        {entities && entities.length > 0 && <Map segments={this.getAllSegments()} selectedSegmentId={data._selectedSegmentId} />}
        {data._showLinkedEntitiesForm && (
          <InputSearch
            {...autocompleteSearchStyles.normal}
            chipSize="medium"
            avatarProps={{ type: 'entity' }}
            dataTypePath={['workflow', 'cycle', 'group', 'entities']}
            onChange={this.onEntitiesChange}
            label={hasSelected ? '' : 'Search entities in the relational group'}
            renderOption={option => renderEntityListItem(option, cycleEntities)}
            toOptions={activeEntitiesToOptions}
            values={entityIds}
          />
        )}
        {data._showLinkedEntitiesForm && (
          <div styleName="section-actions">
            <Button onClick={this.hideLinkedEntitiesForm}>CANCEL</Button>
            <Button color="primary" disabled={!hasSelected} onClick={this.linkClick}>LINK</Button>
          </div>
        )}
        {entities && entities.length > 0 && (
          <div styleName="section-field-list">
            <List data={data} edit={edit} onChange={onChange} removeEntity={this.removeEntity} />
          </div>
        )}
      </div>
    );
  }
}

LinkedEntitiesSection.propTypes = {
  cycle: PropTypes.object,
  cycleEntities: PropTypes.array,
  data: PropTypes.object,
  edit: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  updateDataField: PropTypes.func
};

const mapStateToProps = state => {
  const cycleEntities = getCycleGroupEntities(state);
  return { ...baseMapStateToProps(state), cycleEntities };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  updateDataField
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedEntitiesSection);
