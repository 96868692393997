import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import FilePreview from '@shared/files/file-preview';
import DeleteFileDialog from '@shared/dialogs/delete-file-dialog';
import AssignTaskDialog from './assign-task-dialog';
import Details from './details';
import Edit from './edit';
import './content.scss';

const Content = () => {
  const [taskId, setTaskId] = useState(null);
  const params = useParams();
  const location = useLocation();

  const assignTempTaskId = (tempTaskId = null) => setTaskId(tempTaskId);

  const routerProps = { location, params };
  const editProps = { ...routerProps, taskId, assignTempTaskId };

  return (
    <div styleName="content-container">
      <Details {...routerProps} />
      <Edit {...editProps} />
      <AssignTaskDialog {...editProps} />
      <FilePreview />
      <DeleteFileDialog />
    </div>
  );
};

export default Content;
