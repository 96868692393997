/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { includes, isString } from 'lodash';
import { dotmapsGrayButtonBackground, overlayTextColor } from '@constants/colors';
import { getDefaultEntity } from '@constants/config';
import { tableFloatingBarStyles } from '@constants/mui-theme';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { getFieldInfo, isRowEditable } from '@utils/data-table-utils';
import './body.scss';

class Body extends Component {
  // Return true if the row can be edited.
  isEditable = row => {
    const { dataType, subType } = this.props;
    return isRowEditable(dataType, subType, row);
  };

  // Returns true if the data type is an entity,
  // since entities have enabled some additional functionality).
  isEntity = () => {
    const { dataType, entityList } = this.props;
    return includes(entityList, dataType);
  };

  // Returns the CSS style for the row, which is a
  // pointer cursor, if the row is editable.
  getRowStyle = row => {
    const { selectedRow } = this.props;
    const rowStyle = {};
    if (this.isEditable(row)) {
      rowStyle.cursor = 'pointer';
    }
    if (selectedRow === row.id) {
      rowStyle.backgroundColor = dotmapsGrayButtonBackground;
    }
    return rowStyle;
  };

  onRowClick = (event, row) => {
    const { dataType, embedded, subType } = this.props;
    const { id } = row;

    if (embedded) {
      const defaultEntityType = getDefaultEntity();
      // For the embedded one, we cannot use push(), or we'll end up in a 404 page,
      // even if the path exists, we must use redux here.
      this.props.push(`/map?${defaultEntityType}=${id}`);
    } else {
      if (!this.isEditable(row)) {
        return;
      }

      let url = `/${dataType}/${id}`;
      if (subType) {
        url = `/${dataType}/${subType}/${id}`;
      }
      if (dataType === 'activity') {
        const { cycle_id, task } = row;
        url = `/cycle/${cycle_id}/task/${task}/activity/${id}/${subType}`;
      }
      if (dataType === 'task') {
        const { cycle_id } = row;
        url = `/cycle/${cycle_id}/task/${id}`;
      }
      // Simulate CTRL or SHIFT CLICK behavior. It might not work on all browsers.
      if (event.ctrlKey) {
        // Simulate CTRL-CLICK behavior on links (open link in a new tab):
        // (works on Chrome and Firefox).
        const win = window.open(url);
        win.focus();
      } else
        if (event.shiftKey) {
          // Simulate SHIFT-CLICK behavior on links (open link in a new window):
          // (works on Chrome, but on Firefox, which opens it on a new tab).
          window.open(url);
        } else {
          // Else, go to the specified link in the current browser window.
          const source = location.pathname || `/list/data/${dataType}`;
          this.props.push({ pathname: url, state: { source } });
        }
    }
  };

  getColumnWidth = width => ({
    maxWidth: `${width}rem`,
    minWidth: `${width}rem`,
    width: `${width}rem`
  });

  getRowId = event => event.currentTarget.id.split('_').pop();

  getRows = event => {
    const id = this.getRowId(event);
    const fixed = document.getElementById(`row_fixed_${id}`);
    const scroll = document.getElementById(`row_scroll_${id}`);
    return { fixed, scroll };
  };

  getColumn = event => {
    const id = this.getRowId(event);
    return document.getElementById(`column_${id}`);
  };

  hoverOn = event => {
    const rows = this.getRows(event);
    rows.fixed.style.backgroundColor = dotmapsGrayButtonBackground;
    rows.scroll.style.backgroundColor = dotmapsGrayButtonBackground;
    if (this.isEntity()) {
      const column = this.getColumn(event);
      if (column) {
        column.style.opacity = 1;
        // Clear any previously selected row:
        this.clearLastHoveredColumn(event);
      }
    }
  };

  hoverOff = event => {
    const { selectedRow } = this.props;
    if (selectedRow) {
      // If there's a selected row, disable hoverOff() until
      // the row is unselected.
      return;
    }
    const rows = this.getRows(event);
    rows.fixed.style.backgroundColor = overlayTextColor;
    rows.scroll.style.backgroundColor = overlayTextColor;
    if (this.isEntity()) {
      const column = this.getColumn(event);
      if (column) {
        column.style.opacity = 0;
      }
    }
  };

  // hoverOn()/hoverOff() will always work correctly and make the
  // 'action' column appear and disappear.
  //
  // However we want that column to still be enabled when we click on
  // the 'more_vert' context menu, and hoverOff() is always triggered
  // in that case.
  //
  // To do that, we set the 'selectedRow' flag on the Redux store,
  // which works, but it keeps it open forever until we 'hover' that
  // row again.
  //
  // Thus we need to store the last selected row id in 'window.hoveredColumnId'
  // so we can clear it as soon as we 'hover' any column (usually after cancelling
  // the dialog about adding an entity to a group).
  clearLastHoveredColumn = event => {
    const id = this.getRowId(event);
    if (window.hoveredColumnId) {
      if (window.hoveredColumnId !== id) {
        const column = document.getElementById(`column_${window.hoveredColumnId}`);
        if (column) {
          column.style.opacity = 0;
        }
      }
    }
    window.hoveredColumnId = id;
  };

  renderColumn = (key, row) => {
    const columnData = getFieldInfo(key, row);
    // Truncate all text columns if they overflow:
    if (isString(columnData)) {
      return <TruncatedCell>{columnData}</TruncatedCell>;
    }
    return columnData;
  };

  render() {
    const { embedded, isFixed, rows, columns } = this.props;
    // Use different styling for when this component is embedded:
    const bodyStyleName = `data-table-body-container${embedded ? '-embedded' : ''}`;
    return (
      <tbody styleName={bodyStyleName}>
        {rows.map(row => (
          <tr
            key={row.id}
            id={`row_${isFixed ? 'fixed' : 'scroll'}_${row.id}`}
            onClick={event => this.onRowClick(event, row)}
            onMouseOver={this.hoverOn}
            onMouseOut={this.hoverOff}
            role="listbox"
            style={this.getRowStyle(row)}
            styleName="data-table-body-row"
          >
            {columns.map(({ key, styleName, width }) => (
              <td
                key={key}
                role="listbox"
                styleName={`data-table-body-column ${styleName ? styleName : ''} ${isFixed ? 'data-table-body-column-fixed' : ''}`}
                style={this.getColumnWidth(width)}
                tabIndex="-1"
              >
                <div styleName="column-wrapper">{this.renderColumn(key, row)}</div>
              </td>
            ))}
            {!isFixed && this.isEntity() &&
              <td
                id={`column_${row.id}`}
                style={tableFloatingBarStyles.wrapper}
              >
                <div style={tableFloatingBarStyles.bar}>
                  {getFieldInfo('action', row)}
                </div>
              </td>
            }
          </tr>
        ))}
      </tbody>
    );
  }
}

Body.propTypes = {
  columns: PropTypes.array,
  dataType: PropTypes.string,
  embedded: PropTypes.bool,
  entityList: PropTypes.array,
  isFixed: PropTypes.bool,
  push: PropTypes.func,
  rows: PropTypes.array,
  selectedRow: PropTypes.number,
  subType: PropTypes.string
};

const mapStateToProps = state => {
  const { selectedRow } = state.dataTables;
  const { entities: { types } } = state;
  const entityList = Object.keys(types);
  return { selectedRow, entityList };
};

export default connect(mapStateToProps, { push })(Body);
