import React, { useState } from 'react';
import LoadingIndicator from '@components/details/loading-indicator';
import Drawer from './drawer';
import Map from './map';
import './info.scss';

const Info = () => {
  const [activeSegmentId, setActiveSegmentId] = useState(null);
  const [activeType, setActiveType] = useState(null);
  const [activeStart, setActiveStart] = useState(null);
  const [activeEnd, setActiveEnd] = useState(null);
  const [geometryLocked, setGeometryLocked] = useState(false);

  const formState = {
    activeSegmentId, setActiveSegmentId,
    activeType, setActiveType,
    activeStart, setActiveStart,
    activeEnd, setActiveEnd,
    geometryLocked, setGeometryLocked
  };

  return (
    <div styleName="info-container">
      <LoadingIndicator />
      <Drawer formState={formState} />
      <Map formState={formState} />
    </div>
  );
};

export default Info;
