/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ActionIconButton from '@shared/action-icon-button';
import BaseListItem from '@shared/list-item/base-list-item';
import { toggleLocationTypeGroup } from '@actions/groups-actions';
import { textColor } from '@constants/colors';
import { listItemStyles } from '@constants/mui-theme';
import {
  calculateLevelStyle,
  calculateLeftContainerLevelStyle,
  renderDays
} from '@utils/gantt-utils';
import '../../../../../../../shared/list-item/list-item.scss';

class LocationListItem extends BaseListItem {
  toggleLocationType = () => {
    const { id } = this.props;
    this.props.toggleLocationTypeGroup(id);
  };
  render() {
    const {
      isOpen,
      level,
      label,
      start_date,
      end_date
    } = this.props;
    if (!label) {
      return null;
    }
    const daysLabel = renderDays(start_date, end_date);
    const style = calculateLevelStyle(level);
    const icon = (
      <ActionIconButton
        click={this.toggleLocationType}
        color={textColor}
        icon={isOpen ? 'expand_less' : 'expand_more'}
        style={listItemStyles.locationTypeButtonStyle}
      />
    );
    const isAllBar = label === 'All';
    const allStyle = isAllBar ? 'gantt-location-list-item-all' : '';
    const rowStyle = `list-item gantt-list-item gantt-location-list-item ${allStyle}`;
    const leftContainerStyle = calculateLeftContainerLevelStyle(level);
    return (
      <div onClick={this.onClick}
        role="presentation"
        style={style}
        styleName={rowStyle}>
        <div styleName="left-container" style={leftContainerStyle}>
          <div styleName="header">
            {icon}
            {label}
          </div>
        </div>
        <div styleName="right-container">
          {daysLabel}
        </div>
      </div>
    );
  }
}

LocationListItem.propTypes = {
  isOpen: PropTypes.bool,
  toggleLocationTypeGroup: PropTypes.func
};

export default connect(null, {toggleLocationTypeGroup})(LocationListItem);
