import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import CommentInput from '@shared/comments/comment-input';
import { getUserAvatar } from '@utils/data-table/user';
import './comment-avatar-input.scss';

const CommentAvatarInput = ({ filterUsers, onComment, user }) => (
  <div styleName="comment-avatar-input-container">
    <div styleName="avatar">
      {getUserAvatar(user, 32, colors.dotmapsBlack20)}
    </div>
    <CommentInput filterUsers={filterUsers} onComment={onComment} />
  </div>
);

CommentAvatarInput.propTypes = {
  filterUsers: PropTypes.func,
  onComment: PropTypes.func,
  user: PropTypes.object
};

const mapStateToProps = state => {
  const { config: { user } } = state;
  return { user };
};

export default connect(mapStateToProps, {})(CommentAvatarInput);
