import React from 'react';
import PropTypes from 'prop-types';
import Section from '@components/workflow/cycle/content/section';
import AttachmentsSection from '@components/workflow/cycle/content/section/attachments';
import LinkedEntitiesSection from '@components/workflow/cycle/content/section/linked-entities';
import LocationsSection from '@components/workflow/cycle/content/section/locations';
import { getTaskFields } from '@utils/workflow-utils';
import './details-tab.scss';

const DetailsTab = ({
  cycle, data, onChange, sections, taskId, taskType, viewType
}) => {
  const sectionProps = { cycle, data, edit: false, onChange };
  return (
    <div styleName="details-tab-container">
      {sections.map(section => {
        const fields = getTaskFields(section, taskType);
        if (fields.length > 0 && section.name !== 'Response') {
          return (
            <Section
              {...sectionProps}
              fields={fields}
              key={section.id}
              name={section.name}
              section={section.id}
              taskId={taskId}
              viewType={viewType}
            />
          );
        }
        return null;
      })}
      <AttachmentsSection {...sectionProps} taskId={taskId} viewType={viewType} />
      {cycle && cycle.group && <LinkedEntitiesSection {...sectionProps} />}
      {cycle && cycle.entity && <LocationsSection {...sectionProps} />}
    </div>
  );
};

DetailsTab.propTypes = {
  cycle: PropTypes.object,
  data: PropTypes.object,
  onChange: PropTypes.func,
  sections: PropTypes.array,
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskType: PropTypes.object,
  viewType: PropTypes.string
};

export default DetailsTab;
