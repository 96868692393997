import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import ViewSegment from './view-segment';
import BaseMap from './base-map';

const ViewMap = ({
  highlightGeometry,
  isOverlap,
  onBoundsChanged,
  segments,
  selectEntity,
  selectedSegmentIds
}) => {
  const getComponents = useCallback(
    () => {
      if (segments) {
        return segments.filter(segment => segment.shape).map(segment => (
          <ViewSegment
            highlightGeometry={highlightGeometry}
            isOverlap={isOverlap}
            key={segment.id}
            onSegmentClick={selectEntity}
            segment={segment}
            selectedSegmentIds={selectedSegmentIds}
          />
        ));
      }
      return [];
    },
    [highlightGeometry, isOverlap, segments, selectEntity, selectedSegmentIds]
  );

  return (
    <BaseMap
      getComponents={getComponents}
      onBoundsChanged={onBoundsChanged}
      segments={segments}
      selectedSegmentIds={selectedSegmentIds}
    />
  );
};

ViewMap.propTypes = {
  highlightGeometry: PropTypes.bool,
  isOverlap: PropTypes.bool,
  onBoundsChanged: PropTypes.func,
  segments: PropTypes.arrayOf(PropTypes.object),
  selectEntity: PropTypes.func,
  selectedSegmentIds: PropTypes.array
};

export default memo(ViewMap);
