import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { replace } from 'connected-react-router';
import * as R from 'ramda';
import { fetchCycle } from '@actions/workflow-actions';
import TopBar from '@components/details/top-bar';
import { VIEW_TYPES } from '@constants/workflow';
import { withRouter } from '@utils/router-utils';
import { getTasks, goToDefaultTask } from '@utils/workflow-utils';
import Tray from './tray';
import Content from './content';
import './cycle.scss';

class Cycle extends Component {
  componentDidMount() {
    const { id } = this.props.params;
    if (id) {
      this.loadCycle(id);
    }
  }

  componentDidUpdate = prevProps => {
    const { id } = this.props.params;
    const { cycle, errors, loading, params: { viewType, taskId, taskTypeId } } = this.props;
    if (cycle && cycle.id === parseInt(id, 10)) {
      const tasks = getTasks(cycle, viewType);
      if (!taskId && !taskTypeId && tasks.length > 0) {
        goToDefaultTask(tasks[0], VIEW_TYPES.owner, this.props.replace);
        return;
      }
    }
    if (id && R.isEmpty(errors) && (
      prevProps.params.id !== id ||
       // If there's no cycle data and we are not waiting for a fetch,
       // trigger loading the cycle (this happens when we reach this
       // component with react-router and it's reused, not mounted).
       (!loading && !cycle))) {
      this.loadCycle(id);
    }
  };

  loadCycle = id => this.props.fetchCycle(id);

  render() {
    return (
      <div styleName="cycle-content-container">
        <TopBar />
        <div styleName="cycle-container">
          <Tray />
          <Content />
        </div>
      </div>
    );
  }
}

Cycle.propTypes = {
  cycle: PropTypes.object,
  errors: PropTypes.object,
  fetchCycle: PropTypes.func,
  loading: PropTypes.bool,
  params: PropTypes.object,
  replace: PropTypes.func
};

const mapStateToProps = state => {
  const { cycle, errors, loading } = state.workflow;
  return { cycle, errors, loading };
};

const mapDispatchToProps = { fetchCycle, replace };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Cycle));
