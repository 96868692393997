import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const DeleteFileDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const isDialogActive = activeDialogs[dialog.DELETE_FILE];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.DELETE_FILE));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    () => {
      const { deleteFile, index } = dialogParams[dialog.DELETE_FILE] || {};
      deleteFile(index);
      onClose();
    },
    [dialogParams, onClose]
  );

  if (!dialogParams || !activeDialogs[dialog.DELETE_FILE]) {
    return null;
  }

  const { filename } = dialogParams[dialog.DELETE_FILE] || {};

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>Delete File?</DialogTitle>
      <DialogContent>
        Are you sure you want to delete file <b>{filename}</b>?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="secondary"
          onClick={onDelete}
          variant="contained"
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(DeleteFileDialog);
