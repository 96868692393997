import React from 'react';
import { useSelector } from 'react-redux';
import { getErrors } from '@utils/form-utils';
import '../../../../forms/forms.scss';

const NonFieldErrors = () => {
  const { error } = useSelector(state => state.dataDetail);
  const errors = getErrors(error);

  if (errors.non_field_errors) {
    return (
      <div styleName="detail-error">
        {errors.non_field_errors.map((msg, index) => <div key={index}>{msg}</div>)}
      </div>
    );
  }
  return null;
};

export default NonFieldErrors;
