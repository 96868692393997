import { connect } from 'react-redux';
import { includes, isEmpty } from 'lodash';
import * as R from 'ramda';
import { baseMapStateToProps, BaseDropDownFilter } from './base-dropdown-filter';
import { setGanttFilter } from '@actions/groups-actions';
import { getGanttEntityLabels } from '@utils/gantt-utils';

class EntityFilter extends BaseDropDownFilter {
  getMultiple = () => true;
  getChecked(item) {
    return includes(this.state.selected, item.id);
  }
  selectionRenderer(val, menuItems) {  // eslint-disable-line no-unused-vars
    const { selected } = this.state;
    const { groupType } = this.props;
    if (isEmpty(selected) || selected.length === R.values(getGanttEntityLabels(groupType)).length) {
      return 'All entities';
    }
    return R.values(getGanttEntityLabels(groupType)).find(entity => entity.id === selected[0]).selectedLabel;
  }
}

const generateItems = groupType => R.values(getGanttEntityLabels(groupType)).map(entity => (
  { id: entity.id, name: entity.label }
));

const mapStateToProps = state => {
  const { type } = state.groups.edit.group;
  const params = {
    lazyItems: () => generateItems(type),
    name: 'entity'
  };
  return {
    ...baseMapStateToProps(state, params),
    groupType: type
  };
};

export default connect(mapStateToProps, { setFilter: setGanttFilter })(EntityFilter);
