/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import TextField from 'material-ui/TextField';
import { authLogin, authSetUserField } from '@actions/auth-actions';
import { secondaryColor } from '@constants/colors';
import { loginStyles } from '@constants/mui-theme';
import ActionButton from '@shared/action-button';
import { clearAuth } from '@utils/auth-utils';
import '../layouts/not-authenticated/not-authenticated.scss';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webkitAutoFillPassword: false,
      webkitAutoFillEmail: false,
      passwordRef: null,
      emailRef: null,
      password: ''
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setPasswordRef = this.setPasswordRef.bind(this);
    this.setEmailRef = this.setEmailRef.bind(this);
  }

  componentDidMount() {
    clearAuth();
    const autoFillCheckInterval = setInterval(() => {
      // Detect if password is being autofilled by webkit
      // In webkit, scripts see password input values as blank until there is user interaction
      // https://bugs.chromium.org/p/chromium/issues/detail?id=669724
      const { passwordRef, emailRef } = this.state;
      try {
        // We can detect autofill using the webkit pseudoclass -webkit-autofill
        if (
          passwordRef && passwordRef.input.parentElement.querySelector('input:-webkit-autofill')
        ) {
          // Let the render code know we have a password despite input being empty
          this.setState({ webkitAutoFillPassword: true, passwordRef: null}, () => {
            // Adjust password TextField to render correctly
            passwordRef.setState({ hasValue: true });
            // Chrome's autofill breaks password fields due to security measures which
            // prevents DOM triggering on autofilled password fields (probably to prevent
            // password stealing).
            //
            // Thus, we should manually focus on it to solve the overlap, once we have
            // detected above that this field is being autofilled.
            //
            // For more information see:
            //
            //     https://github.com/mui-org/material-ui/issues/10410
            //
            passwordRef.focus();
          });
        }
        if (
          emailRef && emailRef.input.parentElement.querySelector('input:-webkit-autofill')
        ) {
          this.setState({ webkitAutoFillEmail: true, emailRef: null}, () => {
            emailRef.setState({ hasValue: true });
          });
        }
      } catch (err) {
        // not webkit
        clearInterval(autoFillCheckInterval);
      }
    }, 100);
    setTimeout(() => {
      clearInterval(autoFillCheckInterval);
    }, 2000);
  }

  setPasswordRef(input) {
    this.setState({passwordRef: input});
  }

  setEmailRef(input) {
    this.setState({emailRef: input});
  }

  onChange(event) {
    const field = event.target.name;
    const value = event.target.value;
    if (field !== 'password') {
      this.props.authSetUserField(field, value);
    } else {
      this.setState({[field]: value});
    }
    this.setState({ webkitAutoFillPassword: false, webkitAutoFillEmail: false });
  }

  onSubmit(event) {
    const { password } = this.state;
    event.preventDefault();
    this.props.authLogin({...this.props.user, password}, this.props.location);
  }

  render() {
    const { config, errors, user } = this.props;
    if (!config.siteLoaded) {
      return null;
    }
    const { password } = this.state;
    const emptyPassword = R.isEmpty(password) && !this.state.webkitAutoFill;
    const emptyEmail = R.isEmpty(user.username) && !this.state.webkitAutoFillEmail;
    const emailLoginDisabled = emptyEmail || emptyPassword;
    const { activeDirectory: { disclaimer, enabled, hint, icon, label, url } } = config;
    return (
      <div styleName="not-authenticated-container">
        <form action="/" onSubmit={this.onSubmit}>
          <div styleName="auth-heading">Login</div>
          { enabled &&
            <div styleName="auth-section">
              {disclaimer && (
                <div
                  style={{ display: 'inline' }}
                  dangerouslySetInnerHTML={{__html: disclaimer}}
                />
              )}
              {!disclaimer && (
                <div>
                  All employees should login with{' '}
                  <Link to={hint.url} target="_blank" >
                    <div
                      style={{ display: 'inline' }}
                      dangerouslySetInnerHTML={{__html: hint.label}}
                    />
                  </Link>
                  {' '}using their existing credentials.
                </div>
              )}
              <ActionButton
                fullWidth
                styleName="sso-sign-in-button"
                icon={<img src={icon} alt={label} {...loginStyles.ssoIcon} />}
                label={label}
                href={url}
                {...loginStyles.loginSSOButton}
              />
              <div styleName="or-rule">
                <div styleName="text"> or </div>
                <div styleName="rule" />
              </div>
            </div>
          }
          { config.isDemo &&
            <div styleName="auth-demo">
              This is a prototype version of Coordinate<br />
              for test and demonstration purposes only.
            </div>
          }
          {errors && errors.non_field_errors && (
            <div styleName="not-authenticated-form-error-container">
              <div
                dangerouslySetInnerHTML={{__html: errors.non_field_errors}}
                styleName="not-authenticated-form-error"
              />
            </div>
          )}
          <div styleName="not-authenticated-form-text-field">
            <TextField
              fullWidth
              floatingLabelText="Email"
              name="username"
              ref={this.setEmailRef}
              errorText={errors.username}
              onChange={this.onChange}
              value={user.username}
            />
          </div>
          <div styleName="not-authenticated-form-text-field">
            <TextField
              fullWidth
              floatingLabelText="Password"
              type="password"
              name="password"
              ref={this.setPasswordRef}
              onChange={this.onChange}
              errorText={errors.password}
              value={password}
            />
          </div>
          {config.city === 'seattle' &&
            <div styleName="auth-section-horizontal">
              <div>
                <Link style={loginStyles.links} to={'/password'}>Forgot password?</Link>
              </div>
              <div>
                <ActionButton
                  fullWidth
                  disabled={emailLoginDisabled}
                  label="LOGIN WITH EMAIL"
                  type="submit"
                  {...loginStyles.loginButton}
                />
              </div>
            </div>
          }
          {config.city !== 'seattle' &&
            <div styleName="auth-section">
              <div styleName="auth-section-left">
                <Link
                  style={{...loginStyles.links, color: secondaryColor}}
                  to={'/password'}
                >
                  Forgot your password?
                </Link>
              </div>
              <div>
                <ActionButton
                  fullWidth
                  disabled={emailLoginDisabled}
                  label="LOG IN"
                  type="submit"
                  {...loginStyles.loginButton}
                />
              </div>
            </div>
          }
        </form>
      </div>
    );
  }
}

Login.propTypes = {
  authActiveDirectoryLogin: PropTypes.func,
  authLogin: PropTypes.func,
  authSetUserField: PropTypes.func,
  config: PropTypes.object,
  errors: PropTypes.object,
  location: PropTypes.object,
  user: PropTypes.object
};

const mapStateToProps = state => {
  const { errors, user } = state.auth;
  const { config } = state;
  return { config, errors, user };
};

export default connect(mapStateToProps, { authLogin, authSetUserField })(Login);

export { Login as PureLogin };  // Export the unconnected component (for unit tests).
