/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { dotmapsBlack60 } from '@constants/colors';
import { simpleIconButtonStyles, simpleMenuItemStyle } from '@constants/mui-theme';
import DownloadButton from '@components/attachments/download-button';
import DeleteButton from '@components/attachments/delete-button';
import { getIconColor, getIconName, getSubHeader } from '@components/attachments/actions';
import { showFilePreview } from '@actions/attachment-actions';
import './card.scss';

const DetailCard = ({
  menuActions, infoIndex, storedInMemory, showSubHeader, alwaysDisplayIcons,
  showFileSize, attachment, downloadButton, deleteButton, deleteIcon, objectId, objectType
}) => {
  const [focused, setFocused] = useState(false);

  const dispatch = useDispatch();
  const click = () => dispatch(showFilePreview(objectType, objectId, infoIndex));

  const fileContentType = attachment.file_content_type ? attachment.file_content_type : attachment.get('file').type;
  // eslint-disable-next-line max-len
  const fileSize = (attachment.file_size !== null && typeof attachment.file_size !== 'undefined') ? attachment.file_size : attachment.get('file').size;
  const fileName = attachment.file_name ? attachment.file_name : attachment.get('file_name');

  return (
    <div styleName="detail-card-container"
      onMouseEnter={() => setFocused(true)}
      onMouseLeave={() => setFocused(false)}
    >
      <FontIcon
        className="material-icons"
        color={getIconColor(fileContentType)}
        onClick={click}
        styleName="detail-card-header-icon"
      >
        {getIconName(fileContentType)}
      </FontIcon>
      <div styleName="detail-card-container-inner">
        <div styleName="detail-card-header">
          <div
            onClick={click}
            role="presentation"
            styleName="detail-card-header-title"
          >
            {fileName}
          </div>
          {showFileSize &&
            <div styleName="file-size">{Math.round(fileSize / 1000)} KB</div>
          }
        </div>
        {showSubHeader &&
          <div styleName="detail-card-content">
            {getSubHeader(attachment)}
          </div>
        }
      </div>
      {downloadButton && (focused || alwaysDisplayIcons) &&
        <DownloadButton attachment={attachment} />
      }
      {deleteButton && (focused || alwaysDisplayIcons) &&
        <DeleteButton 
          attachment={attachment}
          objectId={objectId}
          objectType={objectType}
          infoIndex={infoIndex}
          storedInMemory={storedInMemory}
          icon={deleteIcon}
        />
      }
      {menuActions && (
        <div styleName="detail-card-header-action">
          <IconMenu
            iconButtonElement={
              <IconButton {...simpleIconButtonStyles}>
                <MoreVertIcon style={{width: '24px'}} color={dotmapsBlack60} />
              </IconButton>
            }
            anchorOrigin={{horizontal: 'middle', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'right', vertical: 'top'}}
            desktop
            listStyle={{ paddingBottom: '0.5rem', paddingTop: '0.5rem' }}
          >
            {menuActions.map((action, index) => (
              <MenuItem
                key={index}
                leftIcon={
                  <span className="material-icons">
                    {action.icon}
                  </span>
                }
                onClick={event => {
                  event.preventDefault();
                  dispatch(action.onClick(attachment));
                }}
                primaryText={<div styleName="detail-card-header-action-item">{action.label}</div>}
                style={simpleMenuItemStyle}
              />
            ))}
          </IconMenu>
        </div>
      )}
    </div>
  );
};

DetailCard.propTypes = {
  alwaysDisplayIcons: PropTypes.bool,
  attachment: PropTypes.object,
  deleteButton: PropTypes.node,
  deleteIcon: PropTypes.node,
  downloadButton: PropTypes.node,
  infoIndex: PropTypes.number,
  menuActions: PropTypes.array,
  objectId: PropTypes.number,
  objectType: PropTypes.string,
  showFileSize: PropTypes.bool,
  showSubHeader: PropTypes.bool,
  storedInMemory: PropTypes.bool
};


export default DetailCard;
