import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import TextField from 'material-ui/TextField';
import { scrollToField, updateDataField } from '@actions/data-detail-actions';
import * as colors from '@constants/colors';
import { detailEdit } from '@constants/mui-theme';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InfoIcon from '@material-ui/icons/Info';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import PlaceIcon from '@material-ui/icons/Place';
import FileUpload from '@shared/files/file-upload';
import LinkButton from './header-link-button';
import './header.scss';

const Header = ({ taskId, taskType }) => {
  const dispatch = useDispatch();
  const { data, error } = useSelector(state => state.dataDetail);
  const { cycle } = useSelector(state => state.workflow);

  const onAddLocations = useCallback(
    () => {
      dispatch(updateDataField('segments', cycle.entity.segments));
      dispatch(scrollToField('locations-section'));
    },
    [cycle, dispatch]
  );

  const onLinkEntity = useCallback(
    () => {
      dispatch(scrollToField('linked-entities-section'));
      dispatch(updateDataField('_showLinkedEntitiesForm', true));
    },
    [dispatch]
  );

  const onAttach = useCallback(
    () => dispatch(scrollToField('attachments-section')),
    [dispatch]
  );

  const onChange = useCallback(
    (event, value) => dispatch(updateDataField('name', value)),
    [dispatch]
  );

  if (!cycle) {
    return null;
  }

  const errors = error ? error.name : null;
  const subtextStyle = errors ? { paddingTop: '1rem' } : {};

  return (
    <div styleName="header">
      <div styleName="header-title">
        <div styleName="header-title-textfield">
          <TextField
            errorText={Array.isArray(errors) ? errors.join(', ') : null}
            errorStyle={{ ...detailEdit.errors }}
            fullWidth
            inputStyle={{
              fontSize: '1.375rem',
              lineHeight: '1.375'
            }}
            onChange={onChange}
            placeHolder="Untitled Task"
            value={data && data.name}
          />
        </div>
        <div styleName="header-title-subtext" style={subtextStyle}>
          {taskType.name}
        </div>
      </div>
      <div styleName="header-links">
        {cycle.entity && (
          <LinkButton icon={<PlaceIcon />} label="ADD LOCATIONS" onClick={onAddLocations} />
        )}
        {cycle.group && (
          <LinkButton icon={<InsertLinkIcon />} label="LINK ENTITY" onClick={onLinkEntity} />
        )}
        <FileUpload contentType="task" objectId={taskId}>
          <LinkButton icon={<AttachFileIcon />} label="ATTACH" onClick={onAttach} />
        </FileUpload>
      </div>
      {!taskType.editing && (
        <div styleName="header-info">
          <div styleName="header-info-icon">
            <InfoIcon htmlColor={colors.dotmapsBlack40} />
          </div>
          <div styleName="header-info-text">
            This task cannot be edited after being assigned
          </div>
        </div>
      )}
    </div>
  );
};

Header.propTypes = {
  taskId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  taskType: PropTypes.object
};

export default Header;
