/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import './details-table-header.scss';

const DetailsTableHeaderActions = ({ actions }) => {
  const iconProps = { htmlColor: '#616161', style: { fontSize: '1.25rem', padding: '0 0 0 0.5rem' } };
  return (
    <div styleName="title-selected-actions">
      {actions.map(action => (
        <Tooltip title={action.label} key="header-actions">
          <action.icon {...iconProps} onClick={event => {
            event.stopPropagation();
            action.onClick();
          }} />
        </Tooltip>
      ))}
    </div>
  );
};

DetailsTableHeaderActions.propTypes = {
  actions: PropTypes.array
};

export default DetailsTableHeaderActions;

