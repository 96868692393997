import React from 'react';
import PropTypes from 'prop-types';
import { getBucketEndPoint } from '@constants/endpoints';

const LayerIcon = ({icon: icon}) => {
  const iconUrl = `${getBucketEndPoint()}/layer/icon/v2/${icon}`;
  return <img src={iconUrl} alt={icon} width={15} />;
};

LayerIcon.propTypes = {
  icon: PropTypes.string
};

export default LayerIcon;
