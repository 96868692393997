import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import TaskItemCard from './task-item-card';
import { VIEW_TYPES } from '@constants/workflow';
import { getTasks } from '@utils/workflow-utils';
import './task-list.scss';

const TaskList = () => {
  const { cycle } = useSelector(state => state.workflow);
  const { task_type } = useSelector(state => state.dataTypes);
  const { taskActivityId, viewType } = useParams();
  const location = useLocation();

  if (!cycle) {
    return null;
  }

  const empty = () => {
    if (viewType === VIEW_TYPES.agency) {
      return 'There are no tasks in this cycle for your agency.';
    }
    if (viewType === VIEW_TYPES.assignee) {
      return 'You have no assigned tasks in this cycle.';
    }
    return 'No task created.';
  };

  const tasks = getTasks(cycle, viewType);
  const taskCount = tasks.length;

  return (
    <div styleName="task-list-container">
      {taskCount === 0 && (
        <div styleName="task-list-empty">
          {empty()}
        </div>
      )}
      {taskCount > 0 && (
        <ul styleName="task-list-body">
          {tasks.map(task => (
            <TaskItemCard key={task.id} location={location} params={{ taskActivityId, viewType }} task={task} taskType={task_type} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default TaskList;
