import React from 'react';

const CommentIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <path
      clipRule="evenodd"
      d={`
          M14 0.5C14.825 0.5 15.4925 1.175 15.4925 2L15.5 15.5L12.5 12.5H2C1.175
          12.5 0.5 11.825 0.5 11V2C0.5 1.175 1.175 0.5 2 0.5H14ZM3.5
          9.5H12.5V8H3.5V9.5ZM12.5 7.25H3.5V5.75H12.5V7.25ZM3.5 5H12.5V3.5H3.5V5Z
      `}
      fill="black"
      fillOpacity="0.4"
      fillRule="evenodd"
    />
  </svg>
);

export default CommentIcon;
