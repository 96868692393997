/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import Section from '@components/workflow/cycle/content/section';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { getTaskFields } from '@utils/workflow-utils';
import './task-type-preview-form.scss';

const TaskTypePreviewForm = ({ taskType }) => {
  const sections = getWorkflowCustomFieldSections();

  return (
    <div styleName="task-type-preview-form">
      {sections.map(section => {
        // If the response fields are not enabled, don't render that section:
        if (section.name === 'Response' && !taskType.response) {
          return null;
        }

        const fields = getTaskFields(section, taskType, null, true);
        if (fields.length > 0) {
          return (
            <Section
              edit
              fields={fields}
              key={section.id}
              name={section.name}
              onChange={() => {
                // Inside preview, example form fields should
                // have no functionality.
              }}
              section={section.id}
            />
          );
        }
        return null;
      })}
    </div>
  );
};

TaskTypePreviewForm.propTypes = {
  taskType: PropTypes.object
};

export default TaskTypePreviewForm;
