/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from 'material-ui/svg-icons/navigation/more-horiz';
import { dotmapsBlack60 } from '@constants/colors';
import './show-more.scss';

const ShowMore = ({ list, max, onClick, showAll }) => {
  if (list && list.length > max) {
    return (
      <div onClick={onClick} role="presentation" styleName="show-more-container">
        <MoreHorizIcon style={{ width: '24px', paddingRight: '13px', color: dotmapsBlack60 }}/>
        <div>{!showAll ? 'SHOW MORE' : 'SHOW LESS'}</div>
      </div>
    );
  }
  return null;
};

ShowMore.propTypes = {
  list: PropTypes.array,
  max: PropTypes.number,
  onClick: PropTypes.func,
  showAll: PropTypes.bool
};

export default ShowMore;
