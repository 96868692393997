/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { chain } from 'lodash';
import moment from 'moment-timezone';
import Chip from '@material-ui/core/Chip';
import FontIcon from 'material-ui/FontIcon';
import UserTooltip from '@components/activity-logs/tooltips/user-tooltip';
import { dotmapsBlack60 } from '@constants/colors';
import EntityTypeIcon from '@icons/entity-type-icon';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { OVERLAP_STATUS, CONFLICT_STATUS_ROW } from '@utils/data-detail/conflicts';
import {
  getConflictActionIcon,
  isToday,
  isYesterday,
  renderComment
} from '@utils/logs-utils';
import './notification-utils.scss';

export const getTimeSinceCreated = created => {
  const date = moment(created);
  if (isToday(date)) {
    const difference = date.diff(moment(), 'hours') * -1;
    if (difference < 1) {
      return `${date.diff(moment(), 'minutes') * -1} minute(s) ago`;
    }
    return `${date.diff(moment(), 'hours') * -1} hour(s) ago`;
  }
  return date.format('MM/DD/YYYY');
};

// Return the last notification id from the list of notifications.
export const getLastNotificationId = notifications => {
  if (notifications) {
    return Math.max(...notifications.map(notification => notification.id));
  }
  return null;
};

export const notificationGroups = {
  today: {
    label: 'TODAY',
    dayGroup: '10'
  },
  yesterday: {
    label: 'YESTERDAY',
    dayGroup: '05'
  },
  earlier: {
    label: 'EARLIER',
    dayGroup: '00'
  }
};

// Group notifications by TODAY, YESTERDAY and EARLIER groups
// so we can sort them by TODAY items first, then YESTERDAY ones
// and finally EARLIER ones.
const notificationDayGroup = notification => {
  const date = moment(notification.entry_object.entry.created).startOf('day');
  if (isToday(date)) {
    return notificationGroups.today.dayGroup;
  }
  if (isYesterday(date)) {
    return notificationGroups.yesterday.dayGroup;
  }
  return notificationGroups.earlier.dayGroup;
};

const groupToDay = (notifications, dayGroup) => ({ notifications, dayGroup });

// Group notifications by TODAY, YESTERDAY and EARLIER:
export const groupNotifications = notifications => chain(notifications)
  .groupBy(notificationDayGroup)
  .map(groupToDay)
  .orderBy('dayGroup', 'desc')
  .value();

export const renderNotificationGroupItemDate = dayGroup => {
  return Object.values(notificationGroups).find(notificationGroup => notificationGroup.dayGroup === dayGroup).label;
};

const renderOverlapRow = (entity, isLast = false) => (
  <div styleName={`notification-item-extra-row ${isLast ? 'last' : ''}`}>
    <div styleName="icon">
      <EntityTypeIcon type={entity.entity_type} />
    </div>
    <div>
      <div styleName="name">{entity.name}</div>
      <div styleName="sub-row">
        <div styleName="agency">{entity.agency}</div>
        {entity.optional_field &&
          <div>
            <div styleName="bull">&bull;</div>
            <div styleName="agency">{entity.optional_field}</div>
          </div>
        }
      </div>
    </div>
  </div>
);

const renderOverlaps = attrs => (
  <div styleName="notification-item-extra-overlaps">
    {attrs.entity1 && renderOverlapRow(attrs.entity1)}
    {attrs.entity2 && renderOverlapRow(attrs.entity2, true)}
  </div>
);

const renderEntityRow = (entity) => (
  <div styleName="notification-item-extra-entity">
    <div styleName={'notification-item-extra-row'}>
      <div styleName="icon">
        <EntityTypeIcon type={entity.entity_type} />
      </div>
      <div>
        <div styleName="name">{entity.name}</div>
        <div styleName="sub-row">
          <div styleName="agency">ID {entity.id}</div>
          <div styleName="bull">&bull;</div>
          <div styleName="agency">{entity.agency}</div>
        </div>
      </div>
    </div>
  </div>
);

export const renderCommentWithMentions = (comment, users) => comment.split('|').map(item => {
  if (item.indexOf('@') > -1) {
    const parts = item.split('^');
    if (parts && parts.length > 0) {
      const email = parts[0];
      // eslint-disable-next-line id-length
      let user = Object.values(users).find(u => u.email === email);
      if (!user) {
        // If the user is not found (probably due to permissions), use the parsed name:
        user = { name: parts[1] };
      }
      return (
        <span key={user.id}>
          {user.id && (
            <UserTooltip id={user.id}>
              <Chip label={user.name} size="small" />
            </UserTooltip>
          )}
          {!user.id && <Chip label={user.name} size="small" />}
        </span>
      );
    }
  }
  return item;
});

const renderCommentBox = (attrs, users) => (
  <div styleName="box comment">
    {renderCommentWithMentions(attrs.comment, users)}
  </div>
);

const renderResolutionBox = (attrs, index, users) => (
  <div styleName="box">
    {attrs.reason && (
      <div>
        Resolution: {attrs.reason}
      </div>
    )}
    {attrs.notes && (
      <div>
        {renderComment(index, `Description: ${attrs.notes}`, users)}
      </div>
    )}
  </div>
);

const renderResolveSubheader = (attrs) => {
  if (attrs.both_sides) {
    return (
      <div styleName="subheader">
        <div styleName="subheader-label">
          Resolved from both sides
        </div>
      </div>
    );
  }
  if (attrs.agency) {
    return (
      <div styleName="subheader">
        <div styleName="subheader-label">
          Resolved on behalf of {attrs.agency}
        </div>
      </div>
    );
  }
  return null;
};

const renderRevokeSubheader = (attrs) => {
  if (!attrs.both_sides && attrs.agency) {
    return (
      <div styleName="subheader">
        <div styleName="subheader-label">
          Revoked on behalf of {attrs.agency}
        </div>
      </div>
    );
  }
  return null;
};

const getOverlapStatusRow = overlapStatus => {
  if (overlapStatus === parseInt(OVERLAP_STATUS.new, 10)) {
    return CONFLICT_STATUS_ROW.OPEN;
  } else
    if (overlapStatus === parseInt(OVERLAP_STATUS.pending, 10)) {
      return CONFLICT_STATUS_ROW.PENDING;
    } else
      if (overlapStatus === parseInt(OVERLAP_STATUS.cleared, 10)) {
        return CONFLICT_STATUS_ROW.RESOLVED;
      }
  return null;
};

const renderResolutionBadges = (attrs, action) => {
  if (attrs) {
    const oldRow = getOverlapStatusRow(attrs.old_overlap_status);
    const newRow = getOverlapStatusRow(attrs.new_overlap_status);
    if (oldRow && newRow) {
      const icon = getConflictActionIcon(action, attrs.both_sides, dotmapsBlack60);
      const { badge: badge1 } = oldRow;
      const { badge: badge2 } = newRow;
      return (
        <div styleName="resolution-badges">
          <div styleName="resolution-badge1">
            {badge1}
          </div>
          <div styleName="resolution-icon">
            {icon}
          </div>
          <div styleName="resolution-badge2">
            {badge2}
          </div>
        </div>
      );
    }
  }
  return null;
};

const renderOverlapComment = (attrs, users) => (
  <div styleName="notification-item-extra-overlaps-wrapper">
    {renderOverlaps(attrs)}
    {renderCommentBox(attrs, users)}
  </div>
);

export const OverlapComment = props => renderOverlapComment(props.attrs, props.users);


const renderEntityComment = (attrs, users) => (
  <div styleName="notification-item-extra-overlaps-wrapper">
    {renderEntityRow(attrs.entity)}
    {renderCommentBox(attrs, users)}
  </div>
);
export const EntityComment = props => renderEntityComment(props.attrs, props.users);

const renderResolve = (attrs, model, index, users) => (
  <div styleName="notification-item-extra-overlaps-wrapper">
    {renderResolveSubheader(attrs, 'overlap_resolve')}
    {renderOverlaps(attrs)}
    {renderResolutionBadges(attrs, 'overlap_resolve')}
    {renderResolutionBox(attrs, index, users)}
  </div>
);

export const Resolve = props => renderResolve(props.attrs, props.model, props.index, props.users);

const renderRevoke = (attrs, model, index, users) => (
  <div styleName="notification-item-extra-overlaps-wrapper">
    {renderRevokeSubheader(attrs, 'overlap_revoke')}
    {renderOverlaps(attrs)}
    {renderResolutionBadges(attrs, 'overlap_revoke')}
    {renderResolutionBox(attrs, index, users)}
  </div>
);

export const Revoke = props => renderRevoke(props.attrs, props.model, props.index, props.users);

const renderWorkflowInfo = (attrs) => (
  <div styleName="notification-item-extra-link">
    <FontIcon
      className="material-icons"
      color={dotmapsBlack60}
      style={{ fontSize: '1rem', margin: '0.5rem 0.5rem 0.5rem 0' }}
    >
      assignment
    </FontIcon>
    <div styleName="notification-type-info">
      <span>{attrs.task_type} - {attrs.task_name}</span>
      <span styleName="sub-info">{attrs.cycle_type} - {attrs.cycle_name}</span>
    </div>
  </div>
);

export const WorkflowInfo = props => renderWorkflowInfo(props.attrs);

const renderEntityInfo = entity => (
  <div styleName="notification-item-extra-single-overlap">
    {renderOverlapRow(entity)}
  </div>
);

export const EntityInfo = props => renderEntityInfo(props.entity);

const renderTaskActivityStatus = (attrs, oldStatus, newStatus) => (
  <div styleName="notification-item-extra-link">
    <TaskStatusChip taskStatus={oldStatus} />
    <FontIcon
      className="material-icons"
      color={dotmapsBlack60}
      style={{ fontSize: '.875rem', margin: '.5rem' }}
    >
      arrow_right_alt
    </FontIcon>
    <TaskStatusChip taskStatus={newStatus} />
  </div>
);

export const TaskActivityStatus = props => renderTaskActivityStatus(props.attrs, props.oldStatus, props.newStatus);
