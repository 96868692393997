import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Scrollable, { mapStateToProps, mapDispatchToProps } from '@shared/scrollable';
import { 
  getAttachments,
  showFilePreview
} from '@actions/attachment-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';
import DetailCard from '@components/attachments/detail-card';
import Header from './header';
import './section.scss';

class AttachmentsSection extends Scrollable {
  constructor(props) {
    super(props);
    if (props.taskId && !props.taskId.startsWith('temp')) {
      props.getAttachments('task', props.taskId);
    }
  }

  getScrollToId = () => 'attachments-section';

  checkProp = props => R.pathOr(null, ['data', 'segments', 'length'], props);

  render() {
    const { edit, taskId } = this.props;
    const count = this.props.attachments.length;
    // Skip section in details mode when there are no entries.
    if (!edit && count === 0) {
      return null;
    }
    return (
      <div styleName="section" id={this.getScrollToId()}>
        {count > 0 && (
          <Header
            edit={edit}
            headerLabel="Attachments"
            addLabel="Add attachment"
            count={count}
            taskId={taskId}
            attachmentsHeader
            attachments={this.props.attachments}
          />
        )}
        <div styleName="section-field-list">
          {this.props.attachments && this.props.attachments.map((attachment, id) => {
            return (
              <DetailCard
                deleteButton={taskId.startsWith('temp')}
                downloadButton={!taskId.startsWith('temp')}
                attachment={attachment}
                objectId={taskId}
                objectType={'task'}
                key={id}
                infoIndex={id}
                showSubHeader
              />
            );
          })}
        </div>
      </div>
    );
  }
}

AttachmentsSection.propTypes = {
  cycle: PropTypes.object,
  data: PropTypes.object,
  edit: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  viewType: PropTypes.string
};

const attachmentsMapStateToProps = (state, props) => {
  const { attachments } = state;
  let attach = attachments.attachments[`task${props.taskId}`];
  attach = attach ? attach : [];
  return { ...mapStateToProps(state), attachments: attach };
};

const attachmentsMapDispatchToProps = {
  ...mapDispatchToProps,
  getAttachments,
  openDashboardDialog,
  showFilePreview
};

export default connect(attachmentsMapStateToProps, attachmentsMapDispatchToProps)(AttachmentsSection);
