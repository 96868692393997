/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, {memo, useMemo, useCallback} from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  paginatorDropDownStyles
} from '@constants/mui-theme';

import PageButton from './page-button';
import { generatePageRange } from './utils';
import FilterDropDown from '@shared/filter-dropdown';
import { formatNumber } from '@utils/shared-utils';

import styles from './paginator.scss';

const ROWS_PER_PAGE_ITEMS = [
  {id: 50, name: '50'},
  {id: 100, name: '100'},
  {id: 250, name: '250'}
];

const Paginator = (props) => {
  const { count, limit, offset, onLimitChange, onOffsetChange} = props;

  const currentPage = useMemo(
    () => (Math.floor(offset / limit) + 1),
    [offset, limit]
  );

  const totalPages = useMemo(
    () => (Math.ceil(count / limit)),
    [count, limit]
  );

  const isFirstPage = currentPage === 1;

  const isLastPage = useMemo(
    () => (currentPage === totalPages || offset + limit > count),
    [currentPage, totalPages, offset, limit, count]
  );

  const pages = useMemo(
    () => (generatePageRange(currentPage, totalPages)),
    [currentPage, totalPages]
  );

  const handleLimitChange = useCallback(
    (event, key, value) => onLimitChange(value),
    [onLimitChange]
  );

  const goToPrevPage = useCallback(() => {
    if (!isFirstPage) {
      let newOffset = offset - limit;
      if (newOffset < 0) {
        newOffset = 0; // Should not get here as !isFirstPage will have already prevented this.
      }
      onOffsetChange(newOffset);
    }
  }, [limit, offset, onOffsetChange, isFirstPage]);

  const goToNextPage = useCallback(() => {
    if (!isLastPage) {
      let newOffset = offset + limit;
      if (newOffset > count) {
        newOffset = offset; // Should not get here as !isLastPage will have already prevented this.
      }
      onOffsetChange(newOffset);
    }
  }, [count, limit, offset, onOffsetChange, isLastPage]);

  const goToPage = useCallback((page) => {
    const newOffset = (page - 1) * limit;
    onOffsetChange(newOffset);
  }, [limit, onOffsetChange]);

  const {
    defaultProps,  // eslint-disable-line no-unused-vars
    ...limitStyles
  } = paginatorDropDownStyles;
  return (
    <div className={styles.paginatorContainer}>
      <div className={styles.limit}>
        <div className={styles.limitLabel}>Rows per page:</div>
        <div>
          <FilterDropDown
            multiple={false}
            onChange={handleLimitChange}
            value={limit}
            {...limitStyles}
          >
            {ROWS_PER_PAGE_ITEMS.map(item => (
              <MenuItem
                key={item.id}
                label={item.name}
                primaryText={item.name}
                value={item.id}
              />
            ))}
          </FilterDropDown>
        </div>
      </div>
      <div>
        <div>
          {offset + 1}&ndash;{formatNumber(Math.min(offset + limit, count))} of {formatNumber(count)}
        </div>
      </div>
      <div className={styles.navigator}>
        <div className={styles.navElements}>
          <IconButton
            disabled={isFirstPage}
            onClick={goToPrevPage}
            classes={{root: styles.paginatorButton}}
          >
            <ChevronLeftIcon />
          </IconButton>
          {pages.map((page, index) => (
            <PageButton key={index} page={page} current={currentPage} onSelect={goToPage}/>
          ))}
          <IconButton
            disabled={isLastPage}
            onClick={goToNextPage}
            classes={{root: styles.paginatorButton}}
          >
            <ChevronRightIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
  offset: PropTypes.number,
  onLimitChange: PropTypes.func,
  onOffsetChange: PropTypes.func
};

export default memo(Paginator);
