/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import {
  dotmapsBlack60,
  overlayTextColor
} from '@constants/colors';
import { moreListTooltipStyles } from '@constants/mui-theme';
import Tooltipable from '@shared/tooltipable';
import './user-teams.scss';

class UserTeams extends Tooltipable {
  renderList = list => (
    <div>
      {list.map((item, index) => this.renderItem(item, index))}
    </div>
  );

  renderItem = (item, index) => {
    const { userRoles } = this.props;
    return (
      <div key={index} styleName="tooltip-item">
        {userRoles[item].name}
      </div>
    );
  };

  getAvatar = (list, value) => (
    <div
      onMouseOver={this.hoverOn}
      onMouseOut={this.hoverOff}
    >
      <Avatar
        backgroundColor={overlayTextColor}
        color={dotmapsBlack60}
        size={20}
        style={moreListTooltipStyles.avatarStyle}
      >
        {value}
      </Avatar>
      {this.renderTooltip(this.renderList(list))}
    </div>
  );

  render() {
    const { roles, userRoles } = this.props;
    return (
      <div styleName="column-container">
        <div styleName="team-label">{userRoles[roles[0]].name}</div>
        {roles.length > 1 && this.getAvatar(roles.slice(1), `+${roles.length - 1}`)}
      </div>
    );
  }
}

UserTeams.propTypes = {
  roles: PropTypes.array,
  userRoles: PropTypes.object
};

UserTeams.defaultProps = {
  tooltipStyle: { ...moreListTooltipStyles.tooltip }
};

export default UserTeams;
