import React from 'react';

const CancelIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="cancel-path-1"
        d={`
            M7.5 0A7.493 7.493 0 0 0 0 7.5C0 11.648 3.353 15 7.5 15c4.148 0 7.5-3.352
            7.5-7.5C15 3.353 11.648 0 7.5 0zm3.75 10.193l-1.057 1.057L7.5 8.557 4.808
            11.25 3.75 10.193 6.442 7.5 3.75 4.808 4.808 3.75 7.5 6.442l2.693-2.692
            1.057 1.058L8.557 7.5l2.693 2.693z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
      <mask id="cancel-path-2" fill="#fff">
        <use xlinkHref="#cancel-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#cancel-path-2)">
        <path d="M-1.5-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default CancelIcon;
