import { connect } from 'react-redux';
import { openEntityTray, viewEntityOnMap, setOverlapHoverEntity } from '@actions/map-actions';
import NewEntityListItem from '@shared/new-list-item/entity-list-item';

class Entity extends NewEntityListItem {
  onClick = () => {
    const { id, entity_type } = this.props;
    // const trayProps = {
    //   id,
    //   position,
    //   type: entity_type
    // };
    // Changing from opening the entity tray to viewing the entity on the map.
    // Doing this will also view the map tray. 
    // this.props.openEntityTray(trayProps);
    this.props.viewEntityOnMap(id, entity_type);
  };

  onMouseEnter = () => {
    // eslint-disable-next-line no-unused-vars
    const { position, ...entity } = this.props;
    this.props.setOverlapHoverEntity(entity);
  };

  onMouseLeave = () => {
    this.props.setOverlapHoverEntity();
  };
}

export default connect(null, { openEntityTray, viewEntityOnMap, setOverlapHoverEntity })(Entity);
