// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-entity-info-map-___map__map-container{height:100%;width:100%}
`, "",{"version":3,"sources":["webpack://./src/components/entity/info/map/map.scss"],"names":[],"mappings":"AAGA,sDACE,WAAY,CACZ,UAAW","sourcesContent":["@import '../../../../styles/vars';\n@import '../../../../styles/mixins';\n\n.map-container {\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"map-container": `src-components-entity-info-map-___map__map-container`,
	"mapContainer": `src-components-entity-info-map-___map__map-container`
};
export default ___CSS_LOADER_EXPORT___;
