import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { copyToClipboard } from '@actions/data-detail-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogSnackbar from '@shared/dialogs/dialog-snackbar';
import { buildEntitySchedule } from '@utils/segment-schedule/copy-to-clipboard';
import './view-schedule-dialog.scss';

const ViewScheduleDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams: allDialogParams } = useSelector(state => state.dashboard);
  const dialogParams = allDialogParams[dialog.VIEW_SCHEDULE];
  let schedule = null;
  if (dialogParams) {
    const { dataType, data } = dialogParams;
    schedule = buildEntitySchedule(dataType, data);
  }
  const isDialogActive = activeDialogs[dialog.VIEW_SCHEDULE];

  const onCopy = useCallback(
    () => {
      dispatch(copyToClipboard('view-schedule-textarea', 'view-schedule-display-text'));
    },
    [dispatch]
  );

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.VIEW_SCHEDULE));
    },
    [dispatch]
  );

  if (!isDialogActive || !dialogParams || !schedule) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          View Schedule
        </div>
      </DialogTitle>
      <DialogContent>
        <div styleName="view-schedule-dialog-text-legend">
          Copy and paste the {dialogParams.dataType} schedule to clipboard.
        </div>
        <div
          id="view-schedule-display-text"
          styleName="view-schedule-dialog-text-container"
        >
          {schedule}
        </div>
        {/* hidden textarea (for copying to clipboard) */ }
        <textarea
          id="view-schedule-textarea"
          styleName="view-schedule-dialog-hidden-textarea"
          readOnly
          value={schedule}
        />
        <DialogSnackbar />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          onClick={onCopy}
          variant="contained"
        >
          COPY TO CLIPBOARD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ViewScheduleDialog);
