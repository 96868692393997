import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FontIcon from 'material-ui/FontIcon';
import * as colors from '@constants/colors';
import Tooltipable from '@shared/tooltipable';
import './snap-tooltip.scss';

const renderIcon = props => (
  <FontIcon
    className="material-icons"
    color={colors.dotmapsBlack60}
    styleName="icon"
    style={{fontSize: '18px', display: 'block'}}
    {...props}
  >
    help
  </FontIcon>
);

const SnapTooltip = ({
  config,
  tooltipStyle
}) => (
  <div styleName="snap-tooltip-container">
    <Tooltipable hoverElement={renderIcon} tooltipStyle={tooltipStyle}>
      <div styleName="snap-tooltip-field-tooltip">
        This uses the {config.cityName || 'City'}'s street centerline data.
        When this switch is turned off, the app will use Google's Geocoding service to return an address.
      </div>
    </Tooltipable>
  </div>
);

SnapTooltip.propTypes = {
  config: PropTypes.object,
  tooltipStyle: PropTypes.object
};

SnapTooltip.defaultProps = {
  tooltipStyle: {}  // Override/reset the default 'Tooltipable' style.
};

const mapStateToProps = state => {
  const { config } = state;
  return { config };
};

export default connect(mapStateToProps, {})(SnapTooltip);
