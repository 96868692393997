/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import FontIcon from 'material-ui/FontIcon';
import Popover from 'material-ui/Popover';
import { dotmapsError, dotmapsOrangeWarning } from '@constants/colors';
import {
  scheduleTypes,
  segmentDirections
} from '@constants/component-configs';
import {
  getSegmentTitle,
  isScheduleEnabled,
  renderEmptyScheduleString,
  renderScheduleString,
  showScheduleExceedsWarning
} from '@utils/segment-schedule/common';
import {
  formatLocationDate,
  formatLocationDateTime
} from '@utils/segment-schedule/dates';
import {
  renderPopoverExceptionList,
  renderPopoverRecurrenceList
} from '@utils/segment-schedule/recurrence';
import { enDash } from '@utils/shared-utils';
import './segment-list.scss';

class SegmentListItem extends Component {
  state = {
    // Popover state:
    descriptionOpen: false,
    descriptionAnchorEl: null,
    scheduleOpen: false,
    scheduleAnchorEl: null
  };

  renderDirection = () => {
    const { segment } = this.props;
    const direction = Object.keys(segmentDirections).find(key => segmentDirections[key].value === segment.direction);
    if (direction) {
      return segmentDirections[direction].label;
    }
    return 'Unknown';
  };

  renderImpactType = () => {
    const { segment, segmentImpactTypes } = this.props;
    const segmentImpactType = segmentImpactTypes[segment.impact_type];
    if (segmentImpactType) {
      return segmentImpactType.name;
    }
    return 'Unknown';
  };

  renderSchedule = () => {
    const { data, segment } = this.props;
    const showWarning = showScheduleExceedsWarning(data.start_date, data.end_date, segment);
    const fontProps = {};
    if (showWarning) {
      fontProps.color = dotmapsOrangeWarning;
    }
    const hasData = !(isEmpty(data.start_date) || isEmpty(data.end_date));
    return (
      <div styleName={`schedule ${showWarning ? 'warning' : ''}`}>
        {hasData && <FontIcon className="material-icons" styleName="icon" {...fontProps}>schedule</FontIcon>}
        {hasData && isEmpty(segment.schedules) && renderEmptyScheduleString(data)}
        {!isEmpty(segment.schedules) && renderScheduleString(segment.schedules)}
        {showWarning &&
          <FontIcon className="material-icons" color={dotmapsOrangeWarning} styleName="icon">error</FontIcon>
        }
      </div>
    );
  };

  renderPopoverSchedule = () => {
    const { segment } = this.props;
    const {
      start_date: startDate,
      end_date: endDate,
      exceptions,
      recurrences,
      all_day: allDay,
      type
    } = segment.schedules[0];
    return (
      <div styleName="popover-schedule">
        {(type === scheduleTypes.recurring.value || allDay) &&
          <div styleName="heading">
            {formatLocationDate(startDate)}
            {enDash}
            {formatLocationDate(endDate)}
            {type === scheduleTypes.oneTime.value && allDay && <span>&nbsp;(all day)</span>}
          </div>
        }
        {type === scheduleTypes.oneTime.value && !allDay &&
          <div styleName="heading">
            {formatLocationDateTime(startDate)}
            {enDash}
            {formatLocationDateTime(endDate)}
          </div>
        }
        {type === scheduleTypes.recurring.value &&
          <div styleName="body">
            {renderPopoverRecurrenceList(recurrences)}
          </div>
        }
        {type === scheduleTypes.recurring.value &&
          <div styleName="footer">
            {renderPopoverExceptionList(exceptions)}
          </div>
        }
      </div>
    );
  };

  descriptionPopoverOpen = event => {
    event.preventDefault();

    this.setState({
      descriptionOpen: true,
      descriptionAnchorEl: event.currentTarget
    });
  };

  descriptionPopoverClose = () => {
    this.setState({ descriptionOpen: false });
  };

  schedulePopoverOpen = event => {
    event.preventDefault();

    this.setState({
      scheduleOpen: true,
      scheduleAnchorEl: event.currentTarget
    });
  };

  schedulePopoverClose = () => {
    this.setState({ scheduleOpen: false });
  };

  getShowErrorIcon = () => {
    const { checkAddressErrors, segment } = this.props;
    // If after save a global segment error is caught,
    // it means the 'from' or 'to' addreses are empty,
    // thus show the error icon.
    if (checkAddressErrors &&
       (isEmpty(segment.display_from) ||
        isEmpty(segment.display_to))) {
      return true;
    }
    // Or show it too if there's other kind of error on the 'from/to' fields:
    return segment.display_from_error || segment.display_to_error;
  };

  render() {
    const { dataType, onClick, segment } = this.props;
    const showErrorIcon = this.getShowErrorIcon();
    const scheduleEnabled = isScheduleEnabled(dataType);
    return (
      <div
        onClick={onClick}
        role="presentation"
        styleName="segment-list-item"
      >
        <div styleName="heading">
          <div styleName="street">{getSegmentTitle(segment)}</div>
          {showErrorIcon && (
            <FontIcon
              className="material-icons"
              color={dotmapsError}
              styleName="error"
            >
              error
            </FontIcon>
          )}
          <FontIcon className="material-icons" styleName="icon">unfold_more</FontIcon>
        </div>
        {scheduleEnabled && <div styleName="field-divider" />}
        {scheduleEnabled && segment.description && (
          <div
            onMouseOver={this.descriptionPopoverOpen}
            onMouseOut={this.descriptionPopoverClose}
            styleName="description"
          >
            <FontIcon className="material-icons" styleName="icon">notes</FontIcon>
            <div styleName="text">
              {segment.description}
            </div>
          </div>
        )}
        {scheduleEnabled && (
          <div styleName="body">
            {this.renderDirection()}
            <div styleName="separator">&bull;</div>
            {this.renderImpactType()}
          </div>
        )}
        {scheduleEnabled && (
          <div
            onMouseOver={this.schedulePopoverOpen}
            onMouseOut={this.schedulePopoverClose}
            styleName="footer"
          >
            {this.renderSchedule()}
          </div>
        )}
        {/*
          * See the comments where other component named XYPopover is used,
          * to see why I use useLayerForClickAway and pointerEvents.
          * (it's due to hover event problems).
          */}
        {scheduleEnabled && segment.description && (
          <Popover
            open={this.state.descriptionOpen}
            useLayerForClickAway={false}
            style={{pointerEvents: 'none'}}
            anchorEl={this.state.descriptionAnchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            onRequestClose={this.descriptionPopoverClose}
          >
            <div styleName="segment-list-item-popover">
              <div styleName="title">Description</div>
              <div styleName="content">{segment.description}</div>
            </div>
          </Popover>
        )}
        {scheduleEnabled && !isEmpty(segment.schedules) && (
          <Popover
            open={this.state.scheduleOpen}
            useLayerForClickAway={false}
            style={{pointerEvents: 'none'}}
            anchorEl={this.state.scheduleAnchorEl}
            anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            onRequestClose={this.schedulePopoverClose}
          >
            <div styleName="segment-list-item-popover">
              <div styleName="title">Schedule</div>
              <div styleName="content">{this.renderPopoverSchedule()}</div>
            </div>
          </Popover>
        )}
      </div>
    );
  }
}

SegmentListItem.propTypes = {
  // If true, check if addresses are empty and show the error icon.
  checkAddressErrors: PropTypes.bool,
  data: PropTypes.object,
  dataType: PropTypes.string,
  onClick: PropTypes.func,
  segment: PropTypes.object,
  segmentImpactTypes: PropTypes.object
};

export default SegmentListItem;
