/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import Dialog from 'material-ui/Dialog';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { updateSegment } from '@actions/data-detail-actions';
import { scheduleTypes } from '@constants/component-configs';
import * as dialog from '@constants/dialogs';
import { detailEdit, dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import ScheduleType from '@shared/dialogs/edit-schedule-dialog-schedule';
import { createTemporalId } from '@utils/data-detail-utils';
import { isScheduleComplete } from '@utils/segment-schedule/common';
import './edit-schedule-dialog.scss';

const EditScheduleDialog = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [errors, setErrors] = useState({});
  const [segment, setSegment] = useState({});
  const [exclusionsOpen, setExclusionsOpen] = useState(false);

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { data } = useSelector(state => state.dataDetail);

  const { id, isBulk, start_date: minDate, end_date: maxDate, selected } = dialogParams[dialog.EDIT_SCHEDULE] || {};

  const isDialogActive = activeDialogs[dialog.EDIT_SCHEDULE];

  const isExclusionsOpen = segmentItem => {
    if (segmentItem) {
      const schedule = segmentItem.schedules[0];
      return schedule.exceptions && !isEmpty(schedule.exceptions);
    }
    return false;
  };

  const setError = (key, value) => setErrors({ ...errors, [key]: value });

  const clearError = key => {
    const newErrors = { ...errors };
    delete newErrors[key];
    setErrors(newErrors);
  };

  // When the dialog becomes active, copy the segment into this component's state,
  // so we can work on the temporal copy until we SET or discard it.
  useEffect(() => {
    if (isDialogActive) {
      const editedSegment = id ? data?.segments?.find(seg => seg.id === id) : null;
      // Clone the segment to avoid state mutation errors:
      const tempSegment = editedSegment ? cloneDeep(editedSegment) : {};
      if (isEmpty(tempSegment.schedules)) {
        tempSegment.schedules = [{
          id: createTemporalId(),
          start_date: minDate,
          end_date: maxDate,
          type: scheduleTypes.oneTime.value,
          all_day: false
        }];
      }
      const schedule = tempSegment.schedules[0];
      if (schedule.start_date) {
        setStartDate(moment(schedule.start_date).toDate());
        setStartTime(moment(schedule.start_date));
      } else {
        setStartDate(null);
        setStartTime(null);
      }
      if (schedule.end_date) {
        setEndDate(moment(schedule.end_date).toDate());
        setEndTime(moment(schedule.end_date));
      } else {
        setEndDate(null);
        setEndTime(null);
      }
      setErrors({});
      setSegment(tempSegment);
      setExclusionsOpen(isExclusionsOpen(tempSegment));
    }
  }, [isDialogActive]);  // eslint-disable-line react-hooks/exhaustive-deps

  // Date validation:
  useEffect(() => {
    if (segment?.schedules?.length > 0) {
      const { start_date, end_date } = segment.schedules[0];
      if (start_date && end_date && moment(end_date).isBefore(moment(start_date))) {
        setError('end_date', 'Schedule must end after it starts');
      } else {
        clearError('end_date');
      }
    }
  }, [segment?.schedules]);  // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = () => dispatch(closeDashboardDialog(dialog.EDIT_SCHEDULE));

  const saveSchedule = () => {
    if (isBulk) {
      selected.forEach(bulkSegment => {
        const updatedSegment = cloneDeep(bulkSegment);
        updatedSegment.schedules = cloneDeep(segment.schedules);
        dispatch(updateSegment(updatedSegment));
      });
    } else {
      dispatch(updateSegment(segment));
    }
    dispatch(closeDashboardDialog(dialog.EDIT_SCHEDULE));
    dispatch(closeDashboardDialog(dialog.BULK_SCHEDULE));
  };

  const isComplete = () => isScheduleComplete(segment) && isEmpty(errors);

  const getActions = () => ([
    <Button key="cancel" onClick={onClose}>CANCEL</Button>,
    <Button
      color="primary"
      disabled={!isComplete()}
      key="SET"
      onClick={saveSchedule}
      style={{ marginLeft: '0.5rem' }}
      variant="contained"
    >
      SET
    </Button>
  ]);

  const onScheduleTypeChange = (event, value) => {
    const newSegment = cloneDeep(segment);
    newSegment.schedules[0].type = value;
    setSegment(newSegment);
  };

  if (!isDialogActive || !segment?.schedules?.length) {
    return null;
  }

  const schedule = segment.schedules[0];
  return (
    <Dialog
      actions={getActions()}
      actionsContainerStyle={dialogStyles.customActionContainer}
      autoScrollBodyContent
      bodyStyle={dialogStyles.customBody}
      bodyClassName="ScrollableDialog"
      contentStyle={dialogStyles.largeDialog}
      open={activeDialogs[dialog.EDIT_SCHEDULE]}
      onRequestClose={onClose}
      title={isBulk ? 'Bulk edit schedule' : 'Set Schedule'}
    >
      <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
        <div styleName="edit-schedule-dialog-container">
          <div styleName="section">
            <div styleName="title">Schedule type</div>
            <RadioButtonGroup
              name="type"
              defaultSelected={schedule.type}
              valueSelected={schedule.type}
              onChange={onScheduleTypeChange}
              {...detailEdit.segmentEdit.radioGroup}
            >
              <RadioButton
                value={scheduleTypes.oneTime.value}
                label={scheduleTypes.oneTime.label}
                {...detailEdit.segmentEdit.radio}
              />
              <RadioButton
                value={scheduleTypes.recurring.value}
                label={scheduleTypes.recurring.label}
                {...detailEdit.segmentEdit.radio}
              />
            </RadioButtonGroup>
          </div>
          <div styleName="section-divider" />
          <ScheduleType
            endDate={endDate}
            endTime={endTime}
            errors={errors}
            exclusionsOpen={exclusionsOpen}
            maxDate={maxDate}
            minDate={minDate}
            segment={segment}
            setEndDate={setEndDate}
            setEndTime={setEndTime}
            setExclusionsOpen={setExclusionsOpen}
            setSegment={setSegment}
            setStartDate={setStartDate}
            setStartTime={setStartTime}
            startDate={startDate}
            startTime={startTime}
          />
        </div>
      </MuiPickersUtilsProvider>
    </Dialog>
  );
};

export default memo(EditScheduleDialog);
