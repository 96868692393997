import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Grid } from 'react-flexbox-grid';
import Header from './header';
import Sections from './sections';
import {
  fetchDashboardDetailsData,
  createEmptyDataSet
} from '@actions/dashboard-actions';
import { dotmapsGray } from '@constants/colors';
import { getDetailsConfig } from '@constants/config';
import { PERMISSION_GROUP_ANALYST } from '@constants/permission';
import {
  dashboardDetailsDataSelector
} from '@selectors/dashboard-details-selector';
import ActionIconButton from '@shared/action-icon-button';
import {
  getFieldInfo,
  getInitialValues,
  labeizeDataType
} from '@utils/dashboard-details-utils';
import { isInGroup } from '@utils/permission-utils';
import './details.scss';

class Details extends Component {
  componentDidMount() {
    this.loadData();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.data && !nextProps.data) {
      this.loadData();
    }
  }

  info = (key, select, defaultValue) => getFieldInfo(key, select, defaultValue, this.props.data, this.props.dataTypes);

  loadData = () => {
    const { dataId, dataType } = this.props.match.params;
    if (dataId === 'new') {
      const initialValues = getInitialValues(getDetailsConfig()[dataType]);
      this.props.createEmptyDataSet(dataType, initialValues);
    } else {
      this.props.fetchDashboardDetailsData(dataType, dataId);
    }
  };

  isEdit = () => {
    const { dataId } = this.props.match.params;
    const searchParams = new URLSearchParams(this.props.location.search);
    return searchParams.has('edit') || dataId === 'new';
  };

  render() {
    const { dataType } = this.props.match.params;
    const edit = this.isEdit();
    const { apiError, data } = this.props;
    const fields = getDetailsConfig()[dataType];
    if (!fields) {
      return <div>Missing details fields config</div>;
    }
    const { sections } = fields;
    // Don't show the back arrow on the agency details page
    // for analysts (since they don't see the agency list, they only see the details
    // for their agency, thus they don't have a place to return.
    const showBackArrow = !(isInGroup(PERMISSION_GROUP_ANALYST) && dataType === 'agency');
    return (
      <div styleName="dashboard-details-container">
        {showBackArrow && (
          <div styleName="dashboard-details-top-bar">
            <Link to={`/list/data/${dataType}`}>
              <ActionIconButton color={dotmapsGray} icon="arrow_back" />
            </Link>
            <div>
             Back to {labeizeDataType(dataType)}
            </div>
          </div>
        )}
        {!showBackArrow && <div styleName="dashboard-details-separator" />}
        {data &&
          <Grid fluid>
            {!edit && <Header {...{ dataType, data, info: this.info, fields }} />}
            {sections && <Sections {...{ apiError, dataType, data, edit, info: this.info, fields }} />}
          </Grid>}
      </div>
    );
  }
}

Details.propTypes = {
  apiError: PropTypes.object,
  createEmptyDataSet: PropTypes.func,
  data: PropTypes.object,
  dataTypes: PropTypes.object,
  fetchDashboardDetailsData: PropTypes.func,
  location: PropTypes.object,
  match: PropTypes.object
};

Details.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = (state, props) => {
  const { dataType } = props.match.params;
  const { dataTypes, dashboard } = state;
  const detailData = dashboard.details[dataType] || {};
  const apiError = detailData.error || {};
  return {
    data: dashboardDetailsDataSelector(state, dataType),
    dataTypes,
    apiError
  };
};

export default connect(mapStateToProps, {
  fetchDashboardDetailsData,
  createEmptyDataSet
})(Details);

export { Details as PureDetails };
