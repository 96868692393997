import React from 'react';

const AssignmentIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="assignment-path-1"
        d={`
            M12 1.5H8.865A2.258 2.258 0 0 0 6.75 0c-.975 0-1.8.63-2.115 1.5H1.5C.675
            1.5 0 2.175 0 3v10.5c0 .825.675 1.5 1.5 1.5H12c.825 0 1.5-.675
            1.5-1.5V3c0-.825-.675-1.5-1.5-1.5zm-5.25 0c.412 0 .75.337.75.75S7.162 3
            6.75 3A.752.752 0 0 1 6 2.25c0-.413.338-.75.75-.75zm0 3A2.247 2.247 0 0 1
            9 6.75 2.247 2.247 0 0 1 6.75 9 2.247 2.247 0 0 1 4.5 6.75 2.247 2.247 0 0
            1 6.75 4.5zm4.5 9h-9v-1.05c0-1.5 3-2.325 4.5-2.325s4.5.825 4.5 2.325v1.05z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2.25 .75)">
      <mask id="assignment-path-2" fill="#fff">
        <use xlinkHref="#assignment-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#assignment-path-2)">
        <path d="M-2.25-.75h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default AssignmentIcon;
