import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from '@shared/files/file-upload';
import FlatButton from 'material-ui/FlatButton';
import { fileUploadButton } from '@constants/mui-theme';

const FileUploadButton = ({ objectId }) => (
  <FileUpload contentType="entity" objectId={objectId} isPublic>
    <FlatButton 
      key="files"
      label="CHOOSE FILES"
      {...fileUploadButton} 
    />
  </FileUpload>
);

FileUploadButton.propTypes = {
  objectId: PropTypes.number
};

export default FileUploadButton;
