/* eslint-disable max-depth */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { dotmapsBlue } from '@constants/colors';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import DotmapsLoader from '@shared/dotmaps-loader';
import { segmentsToBounds } from '@utils/map-utils';
import Components from './components';
import './map.scss';

const BaseMap = ({
  getComponents,
  onBoundsChanged,
  onClick,
  segments,
  selectedSegmentIds
}) => {
  const [map, setMap] = useState(null);

  const onMapLoad = mapInstance => {
    if (!window.mapSetup) {
      window.initMap();
    }
    window.mapInstance = mapInstance;
    setMap(mapInstance);
  };

  const fitSegmentBounds = useCallback(
    () => {
      if (segments) {
        let filteredSegments = segments;
        if (selectedSegmentIds) {
          filteredSegments = segments.filter(
            segment => segment.shape && (selectedSegmentIds.length === 0 || includes(selectedSegmentIds, segment.id))
          );
        }
        if (filteredSegments.length > 0) {
          const bounds = segmentsToBounds(filteredSegments);
          if (bounds) {
            map.fitBounds(bounds);
            if (map.getZoom() > 18) {
              map.setZoom(18);
            }
          }
        }
      }
    },
    [selectedSegmentIds, map, segments]
  );

  useEffect(() => {
    if (map) {
      fitSegmentBounds();
    }
  }, [fitSegmentBounds, map, segments?.length, selectedSegmentIds]);

  return (
    <DotMapsGoogleMap
      components={(
        <Fragment>
          {segments && <Components>{getComponents()}</Components>}
          {!segments && <DotmapsLoader color={dotmapsBlue} display key="loader" />}
        </Fragment>
      )}
      onBoundsChanged={onBoundsChanged}
      onClick={onClick}
      onMapLoad={onMapLoad}
    />
  );
};

BaseMap.propTypes = {
  getComponents: PropTypes.func,
  onBoundsChanged: PropTypes.func,
  onClick: PropTypes.func,
  segments: PropTypes.arrayOf(PropTypes.object),
  selectedSegmentIds: PropTypes.array
};

export default memo(BaseMap);
