import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import {
  closeDashboardDialog,
  deleteDashboardEntry,
  openDashboardDialog
} from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import DialogCloseButton from '@shared/dialogs/dialog-close-button';

class DeleteGroupDialog extends Component {
  handleCancel = () => this.props.closeDashboardDialog(dialog.DELETE_GROUP);

  handleSubmit = () => {
    const { dialogParams } = this.props;
    const payload = {
      ...dialogParams
    };
    // Dispatch action to delete group:
    const groupId = payload.group.id;
    this.props.deleteDashboardEntry('group', groupId);
    this.props.closeDashboardDialog(dialog.DELETE_GROUP);
  };

  getActions = () => ([
    <Button key="no" onClick={this.handleCancel}>NO</Button>,
    <Button
      color="secondary"
      key="yes"
      onClick={this.handleSubmit}
      style={{ marginLeft: '0.5rem' }}
      variant="contained"
    >
      YES
    </Button>
  ]);

  render() {
    const { activeDialogs, dialogParams } = this.props;
    if (!dialogParams || !dialogParams.group || !activeDialogs[dialog.DELETE_GROUP]) {
      return null;
    }
    const { group } = dialogParams;
    return (
      <Dialog
        actions={this.getActions()}
        contentStyle={dialogStyles.mediumDialog}
        open={activeDialogs[dialog.DELETE_GROUP]}
        title="Delete group?"
      >
        <DialogCloseButton onClose={this.handleCancel} />
        Are you sure you want to delete group '{group.name}'
      </Dialog>
    );
  }
}

DeleteGroupDialog.propTypes = {
  activeDialogs: PropTypes.object,
  closeDashboardDialog: PropTypes.func,
  deleteDashboardEntry: PropTypes.func,
  dialogParams: PropTypes.object,
  openDashboardDialog: PropTypes.func
};

const mapStateToProps = state => {
  const { activeDialogs, dialogParams } = state.dashboard;
  return { activeDialogs, dialogParams: dialogParams[dialog.DELETE_GROUP] };
};

export default connect(mapStateToProps, { closeDashboardDialog, deleteDashboardEntry, openDashboardDialog })(DeleteGroupDialog);
