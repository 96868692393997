/* eslint-disable react/display-name */
import React from 'react';
import BaseListItem from './base-list-item';
import EntityTypeIcon from '@icons/entity-type-icon';
import { getConfigLabel, getConfigValue } from '@utils/list-item-utils';
import EntityChips from '@components/map/tray/tray-card/entity-card/entity-chips';
import './list-item.scss';

class EntityListItem extends BaseListItem {
  getHeader = () => (
    <div styleName="header">
      {this.getTypeIcon()}
      <div styleName="id">
        ID {this.props.id}
      </div>
      {this.getActions()}
    </div>
  );

  getTitleName = () => getConfigValue(this.props.dataType, 'title', this.props);

  getEntityIcon = () => {
    const { icon } = this.props;
    return (
      <div styleName="icon">
        {icon && <img src={icon} alt="" />}
      </div>
    );
  };

  getTitle = () => {
    const label = getConfigLabel(this.props.dataType, 'title');
    return (
      <div styleName="title-row">
        {label && <div styleName="label">{label}:</div>}
        {this.getEntityIcon()}
        <div styleName="title">{this.getTitleName()}</div>
      </div>
    );
  };

  getType = () => {
    const typeRowValue = getConfigValue(this.props.dataType, 'type-row', this.props);
    if (typeRowValue) {
      return (
        <div styleName="type-row">
          <span>{typeRowValue}</span>
        </div>
      );
    }
    return null;
  };

  getTypeIcon = () => (
    <div styleName="type-icon">
      <EntityTypeIcon type={this.props.dataType} />
    </div>
  );

  getCategoryDict = () => this.props.category_dict || [];

  getStatus = () => this.getCategoryDict().find(
    category => category.name === `${this.props.dataType}-status`
  );

  getPriority = () => this.getCategoryDict().find(category => category.name === 'priority');

  getDate = () => <EntityChips entity={this.props} />;
}

export default EntityListItem;
