import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SegmentTitle from './segment-title';
import Title from './title';

const SectionTitle = ({ readOnly, tab }) => {
  const { data } = useSelector(state => state.dataDetail);
  const { dataType } = useParams();

  if (tab.type === 'segment') {
    return (
      <SegmentTitle
        dataType={dataType}
        data={data}
        readOnly={readOnly}
        tab={tab}
      />
    );
  }
  return <Title tab={tab} />;
};

SectionTitle.propTypes = {
  readOnly: PropTypes.bool,
  tab: PropTypes.object
};

export default memo(SectionTitle);
