/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import AddIcon from 'material-ui/svg-icons/content/add';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import HistoryIcon from 'material-ui/svg-icons/action/history';
import SearchIcon from 'material-ui/svg-icons/action/search';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { downloadCSVFromURL } from '@actions/data-table-actions';
import { setGroupPreFill } from '@actions/groups-actions';
import DeleteGroupDialog from '@components/group/dialogs/delete';
import GroupsAddRecordsDialog from '@components/group/dialogs/add-records';
import { overlayTextColor } from '@constants/colors';
import { getEntitiesListConfig } from '@constants/config';
import { GROUP_DATA_TYPE } from '@components/config/constants';
import * as dialog from '@constants/dialogs';
import { BASE_URL } from '@constants/endpoints';
import { tooltipStyles } from '@constants/mui-theme';
import DrawLineIcon from '@icons/draw-line-icon';
import GanttChartIcon from '@icons/gantt-chart-icon';
import GroupTypeIcon from '@icons/group-type-icon';
import ActionDownloadIconButton from '@shared/action-download-icon-button';
import ActionIconButton from '@shared/action-icon-button';
import FlexSeparator from '@shared/helpers/flex-separator';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canEditGroups } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import './sub-nav.scss';

class SubNav extends Component {
  getGroupType = () => this.props.params.groupType;

  onBackClick = () => {
    const customBackLink = `/library/${GROUP_DATA_TYPE}/${this.getGroupType()}`;
    const source = getDashboardBackURL(this.props.location, 'group', customBackLink);
    this.props.push(source);
  };

  deleteGroup = () => {
    const params = { group: this.props.group };
    this.props.openDashboardDialog(dialog.DELETE_GROUP, params);
  };

  // Set the group pre-fill data for the main map 'Add to group' form.
  setGroupPreFill = () => {
    const { id, type } = this.props.group;
    this.props.setGroupPreFill({ id, type });
  };

  goTo = params => {
    const { id } = this.props.group;
    this.props.push({
      ...params,
      state: {
        source: `/group/${this.getGroupType()}/${id}`
      }
    });
  };

  goToMapSelection = () => {
    this.setGroupPreFill();
    this.goTo({ pathname: '/map', search: '?open=shape' });
  };

  openSearch = () => {
    const params = {
      group: this.props.group,
      groupTypeName: this.getGroupType()
    };
    this.props.openDashboardDialog(dialog.ADD_RECORDS_TO_GROUP, params);
  };

  goToActivityLog = () => {
    const { id } = this.props.group;
    this.goTo({ pathname: `/logs/group/group/${id}` });
  };

  goToGanttChart = () => {
    const { id } = this.props.group;
    this.goTo({ pathname: `/group/gantt/${this.getGroupType()}/${id}` });
  };

  downloadCSV = entityType => {
    const url = `${BASE_URL}/be/group/export_csv/${entityType}/${this.props.group.id}/`;
    const filename = `group-${this.getGroupType()}-${this.props.group.id}-${entityType}.csv`;
    this.props.downloadCSVFromURL(url, filename, 'group');
  };

  hasRecords = () => {
    const { group } = this.props;
    return group && group.entities && group.entities.length > 0;
  };

  exportCSV = () => {
    const { group } = this.props;
    if (group.entities.length > 0) {
      getEntitiesListConfig().forEach(entity => {
        const found = group.entities.find(groupEntity => groupEntity.type_name === entity);
        if (found) {
          this.downloadCSV(entity);
        }
      });
    }
  };

  render() {
    const { group: { area_capture, id, name, status, type_name } } = this.props;
    return (
      <div styleName="group-sub-nav">
        <ActionIconButton icon="arrow_back" click={this.onBackClick} />
        <div styleName="sub-nav-icon">
          <GroupTypeIcon type={this.getGroupType()} size="1.5rem" color="FFFFFF" />
        </div>
        <div>{name}</div>
        <FlexSeparator />
        {/* Only show the '+' icon if the status is 'Open'.
            Check for the status id (i.e. '1') instead of the 'Open' string,
            since the status_name is set on the backend and we can
            edit the status on the frontend, and that doesn't set status_name
            only the id. */}
        {id && canEditGroups(type_name) && status === 1 && (
          <IconMenu
            iconButtonElement={
              <IconButton tooltip="Add records" tooltipStyles={tooltipStyles}>
                <AddIcon color={overlayTextColor} />
              </IconButton>
            }
            anchorOrigin={{horizontal: 'middle', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'middle', vertical: 'top'}}
            desktop
          >
            {area_capture === 0 && (
              <MenuItem leftIcon={<DrawLineIcon />} primaryText="Add records by map selection" onClick={this.goToMapSelection} />
            )}
            <MenuItem leftIcon={<SearchIcon />} primaryText="Add records by searching" onClick={this.openSearch} />
          </IconMenu>
        )}
        {id && <ActionDownloadIconButton disabled={!this.hasRecords()} onClick={this.exportCSV} />}
        {id && (
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color={overlayTextColor} /></IconButton>}
            anchorOrigin={{horizontal: 'middle', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            desktop
          >
            <MenuItem leftIcon={<GanttChartIcon />} primaryText="View gantt chart" onClick={this.goToGanttChart} />
            <MenuItem leftIcon={<HistoryIcon />} primaryText="View activity log" onClick={this.goToActivityLog} />
            {canEditGroups(type_name) && <Divider />}
            {canEditGroups(type_name) && <MenuItem leftIcon={<DeleteIcon />} primaryText="Delete group" onClick={this.deleteGroup} />}
          </IconMenu>
        )}
        <DeleteGroupDialog />
        <GroupsAddRecordsDialog />
      </div>
    );
  }
}

SubNav.propTypes = {
  downloadCSVFromURL: PropTypes.func,
  group: PropTypes.object,
  location: PropTypes.object,
  openDashboardDialog: PropTypes.func,
  push: PropTypes.func,
  setGroupPreFill: PropTypes.func
};

const mapStateToProps = ({ groups }) => ({
  group: groups.edit.group
});

const mapDispatchToProps = {
  downloadCSVFromURL,
  openDashboardDialog,
  push,
  setGroupPreFill
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubNav));
