import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import Paper from 'material-ui/Paper';
import { toggleMarkerModal } from '@actions/map-actions';
import { dialogStyles } from '@constants/mui-theme';
import { getLegendItems } from '@constants/config';
import {
  getEnabledEntities,
  agencyTypesSelector,
  categoriesSelector,
  getEnabledEntityTypes
} from '@selectors/entities-selector';
import { layerTypesSelector } from '@selectors/layers-selector';
import {
  getAgencyIcon,
  getCategoryIcon,
  getDefaultEntityIcon,
  getLayerIcon
} from '@utils/icon-utils';
import './legend.scss';

class Legend extends Component {
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.legendOpen !== nextProps.legendOpen) {
      if (nextProps.legendOpen && this.props.markerModalOpen) {
        this.props.toggleMarkerModal();
      }
    }
  }

  buildItems = (item, list, iconLegend, display = 'name', iconStyle = dialogStyles.item.avatar) => {

    if (list.length === 0) {
      return null;
    }
    const filteredlist = [];
    list.forEach(listItem => {
      const filteredItem = filteredlist.filter(fi => fi.name === listItem.name);
      if (filteredItem.length === 0) { 
        filteredlist.push(listItem); 
      }
    });
    return (
      <div styleName="map-legend-list-group">
        <div styleName="map-legend-title">
          {item.title}
        </div>
        {item.subtitle && (
          <div styleName="map-legend-subtitle">
            {item.subtitle}
          </div>
        )}
        {filteredlist.map(entity => {
          const icon = iconLegend(entity);
          return (
            <div styleName="map-legend-list-item" key={entity.id || entity.name}>
              {item.type !== 'layer' &&
                <Avatar
                  size={24}
                  src={icon}
                  style={iconStyle}
                  alt=""
                />
              }
              {item.type === 'layer' && <img src={icon} alt="" styleName="icon" />}
              <div styleName="text">
                {entity[display]}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  renderAgencies = item => {
    const { agencyTypes, enabledEntities } = this.props;
    const entityType = item.entities[0];
    let filteredAgencyTypes = [];
    if (enabledEntities[entityType] && enabledEntities[entityType].length > 0) {
      filteredAgencyTypes = agencyTypes.filter(type => enabledEntities[entityType].some(entity => entity.agencyTypeId === type.id));
    }
    return this.buildItems(item, filteredAgencyTypes, getAgencyIcon);
  };

  renderCategories = item => {
    const { categories, enabledEntities } = this.props;
    const iconLegend = category => getCategoryIcon(item.id, category.id);
    // Filter categories to return only the values for the current selected category type.
    const categoryTypeId = item['category-type'];
    const currentCategories = categories.filter(category => category.type === categoryTypeId);
    const entityType = item.entities[0];
    let entityCategories = [];
    if (enabledEntities[entityType] && enabledEntities[entityType].length > 0) {
      entityCategories = [...new Set(enabledEntities[entityType]
        .filter(entity => typeof entity.category_dict !== 'undefined')
        .map(entity => entity.category_dict)
        .flat(2)
        .map(category => category.id))];
    }
    const filteredCurrentCategories = currentCategories.filter(category => entityCategories.some(ec => ec === category.id));

    return this.buildItems(item, filteredCurrentCategories, iconLegend);
  };

  renderCityLayers = item => {
    const { layerTypes } = this.props;
    const iconLegend = layerType => getLayerIcon(layerType.icon).url;
    const iconStyle = {
      ...dialogStyles.item.avatar,
      backgroundColor: 'transparent'
    };
    return this.buildItems(item, layerTypes, iconLegend, 'label', iconStyle);
  };

  renderEntityIcon = item => {
    const { entityTypes, enabledEntities } = this.props;
    const entityType = item.id;
    if (enabledEntities[entityType] && enabledEntities[entityType].length > 0) {
      const list = [{
        id: item.id,
        name: ''
      }];
      const iconLegend = () => getCategoryIcon(
        item.id,
        getDefaultEntityIcon(
          { type_name: item.id },
          entityTypes
        )
      );
      return this.buildItems(item, list, iconLegend);
    }
    return null;
  };

  renderGroup = item => {
    if (item.type === 'agency') {
      return this.renderAgencies(item);
    }
    if (item.type === 'entity') {
      return this.renderEntityIcon(item);
    }
    if (item.type === 'entity-category') {
      return this.renderCategories(item);
    }
    if (item.type === 'layer') {
      return this.renderCityLayers(item);
    }
    return null;
  };

  renderEmpty = () => (
    <div styleName="map-legend-list-group">
      <div styleName="map-legend-empty">
        No records shown
      </div>
    </div>
  );

  renderLegends = () => {
    const { hasItems } = this.props;
    if (!hasItems) {
      return this.renderEmpty();
    }
    const items = getLegendItems();

    return items.map((item, index) => {
      const node = this.renderGroup(item);
      if (node) {
        return (
          <Fragment key={item.id}>
            {index > 0 &&
              <div styleName="map-legend-list-separator" />
            }
            { node }
          </Fragment>
        );
      }
      return null;
    });
  };

  render() {
    const { legendOpen } = this.props;
    if (!legendOpen) {
      return null;
    }
    return (
      <Paper styleName="map-legend-container" zDepth={0}>
        <div styleName="map-legend-list">
          {this.renderLegends()}
        </div>
      </Paper>
    );
  }
}

Legend.propTypes = {
  agencyTypes: PropTypes.array,
  categories: PropTypes.array,
  enabledEntities: PropTypes.object,
  entityTypes: PropTypes.object,
  hasItems: PropTypes.bool,
  layerTypes: PropTypes.array,
  legendOpen: PropTypes.bool,
  markerModalOpen: PropTypes.bool,
  toggleMarkerModal: PropTypes.func
};

const getCategories = state => {
  let categories = [];
  const entityTypes = getEnabledEntityTypes(state);
  entityTypes.forEach(entity => {
    categories = [...categories, ...categoriesSelector(state, { type: entity })];
  });
  return categories;
};

const mapStateToProps = state => {
  const { legendOpen } = state.map;
  const { markerModalOpen } = state.map.markers;
  const enabledEntities = getEnabledEntities(state);
  const agencyTypes = agencyTypesSelector(state);
  const layerTypes = layerTypesSelector(state);
  const categories = getCategories(state);
  const allItems = [...agencyTypes, ...layerTypes, ...categories];
  return {
    agencyTypes,
    categories,
    entityTypes: state.dataTypes.map_type,
    layerTypes,
    legendOpen,
    hasItems: allItems.length > 0,
    markerModalOpen,
    enabledEntities
  };
};

export default connect(mapStateToProps, { toggleMarkerModal })(Legend);
