import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './header';
import Body from './body';
import sectionStyles from '../../../../section/section.scss';  // eslint-disable-line no-unused-vars, css-modules/no-unused-class
import activitiesStyles from '../content.scss';  // eslint-disable-line no-unused-vars, css-modules/no-unused-class

const Comments = ({ activity, data, items, scrollTop }) => (
  <div styleName="activitiesStyles.activities-comments">
    <div styleName="sectionStyles.section">
      <Header count={items ? items.length : 0} />
      <Body activity={activity} data={data} scrollTop={scrollTop} />
    </div>
  </div>
);

Comments.propTypes = {
  activity: PropTypes.object,
  data: PropTypes.object,
  items: PropTypes.array,
  scrollTop: PropTypes.number
};

const mapStateToProps = state => {
  const { list: items } = state.logs;
  return { items };
};

export default connect(mapStateToProps, {})(Comments);
