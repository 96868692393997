/*
Chip details section of entity card. This can be customized in the databases entityType config column using frontend.card.chips.
Currently can display text fields, id, priority, and start-end dates.
Sample configuration.
{
  ...,
  "frontend": {
    ...,
    "card": {
      ...,
      "chips": [
        "entityId",
        "entityPriority",
        "entityDateRange",
        {
          "field": "pmac_id",
          "label": true,
          "style": "bull",
          "autoHide": true
        }
      ]
    }
  }
}
*/

/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { getEntityChipConfig } from '@selectors/entities-selector';
import DefaultDate from '@shared/formatted-date-helper';
import { PriorityIcon } from '@utils/icon-utils';
import './entity-chips.scss';

const EntityDate = ({value}) => (
  <DefaultDate value={value} valueFormat="YYYY-MM-DD"/>
);

EntityDate.propTypes = {
  value: PropTypes.string
};

const BullChip = ({children, config}) => ( //eslint-disable-line no-unused-vars
  <div styleName="chip">
    <span styleName="bullet">&bull;</span>
    {children}
  </div>
);

BullChip.propTypes = {
  children: PropTypes.node,
  config: PropTypes.object
};

class EntityTrayChips extends Component {
  constructor(props) {
    super(props);

    this.getPriority = memoize(
      entity => (
        entity.category_dict &&
        entity.category_dict.find(category => category.name === 'priority')
      )
    );

    this.getStatus = memoize(
      entity => (
        entity.category_dict && entity.type_name &&
        entity.category_dict.find(category => category.name === `${entity.type_name}-status`)
      )
    );
  }

  render() {
    const { chipConfig, entity } = this.props;
    const { id, start_date: startDate, end_date: endDate } = entity;
    const priority = this.getPriority(entity);
    const status = this.getStatus(entity);
    let chips = chipConfig;
    if (!chips) {
      chips = [
        {field: 'entityId'},
        {field: 'entityPriority'},
        {field: 'entityDateRange'},
        {field: 'status'}
      ];
    }
    return (
      <div styleName="chip-container">
        { chips.map(chip => {
          const {field, label = true, style = 'bull', autoHide = true, fieldConfig = {}} = chip;
          switch (field) {
          case 'entityId':
            return <BullChip>ID {id}</BullChip>;
          case 'entityPriority':
            return (
              priority &&
              <BullChip>
                <PriorityIcon priority={priority ? priority.id : null} />
              </BullChip>
            );
          case 'entityDateRange':
            return (
              startDate && endDate &&
              <BullChip>
                <EntityDate value={startDate}/>–<EntityDate value={endDate}/>
              </BullChip>
            );
          case 'status':
            return status &&
            <BullChip>{status.value}</BullChip>;
          default: {
            let ChipComponent;
            switch (style) {
            case 'bull':
            default:
              ChipComponent = BullChip;
            }
            const value = entity[field] || (entity.extra && entity.extra[field]);
            if (autoHide && !value) {
              return null;
            }
            if (label) {
              const {serializer_props: {label: labelText = null} = {} } = fieldConfig;
              return <ChipComponent config={chip}>{labelText} {value}</ChipComponent>;
            }
            return <ChipComponent config={chip}>{value}</ChipComponent>;
          }
          }
        })}
      </div>
    );
  }
}

EntityTrayChips.propTypes = {
  chipConfig: PropTypes.array,
  entity: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
  const { entity: { type_name: entityType}} = props;
  return {
    chipConfig: getEntityChipConfig(state, entityType)
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(EntityTrayChips);
