import React from 'react';
import PropTypes from 'prop-types';
import Section from '@components/workflow/cycle/content/section';
import { getWorkflowCustomFieldSections } from '@constants/config';
import { canAccessWorkflow, canRespondTaskActivity } from '@utils/permission-utils';
import { getTaskFields } from '@utils/workflow-utils';
import './content.scss';

const Response = ({
  activity, error, task, taskType, viewType
}) => {
  const section = getWorkflowCustomFieldSections().find(item => item.name === 'Response');
  const { activities } = task;
  const index = activities.findIndex(act => act.id === activity.id);
  const fields = getTaskFields(section, taskType);
  const canEdit = canAccessWorkflow('taskactivity', 'change') && canRespondTaskActivity(activity, task.roles);
  return (
    <div styleName="activities-response">
      <Section
        data={{
          ...activity,
          owner: task.owner,
          roles: task.roles || []  // inject task assigned teams.
        }}
        disabled={!canEdit}
        error={error}
        fieldPrefix={`activities.${index}`}
        fields={fields}
        id={section.id}
        inlineEdit
        name={section.name}
        section={section.id}
        viewType={viewType}
      />
    </div>
  );
};

Response.propTypes = {
  activity: PropTypes.object,
  error: PropTypes.object,
  task: PropTypes.object,
  taskType: PropTypes.object,
  viewType: PropTypes.string
};

export default Response;
