import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { discardTaskType } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const TaskTypeDiscardDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const { id } = dialogParams[dialog.TASK_TYPE_DISCARD] || {};
  const isDialogActive = activeDialogs[dialog.TASK_TYPE_DISCARD];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.TASK_TYPE_DISCARD));
    },
    [dispatch]
  );

  const onDiscard = useCallback(
    () => {
      dispatch(discardTaskType(id));
    },
    [dispatch, id]
  );

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Discard unpublished changes?
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{
          paddingBottom: '1rem'  // Fix for unwanted DialogContent scrollbars.
        }}>
          This will permanently delete your unpublished changes and revert the task type to the last published version. Are you sure?
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          onClick={onDiscard}
          variant="contained"
        >
          DISCARD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TaskTypeDiscardDialog;
