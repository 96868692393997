/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import FormField from './form-field';
import SectionTitle from './section-title';
import '../../../../../forms/forms.scss';

const FormTab = ({
  errors,
  formState,
  metadata,
  readOnly,
  tab,
  visibleFields
}) => {
  const {
    activeSegmentId, setActiveSegmentId,
    activeType, setActiveType,
    activeStart, setActiveStart,
    activeEnd, setActiveEnd,
    geometryLocked, setGeometryLocked
  } = formState;
  const newFormState = { activeSegmentId, activeType, activeStart, activeEnd };
  const formCallbacks = { setActiveSegmentId, setActiveType, setActiveStart, setActiveEnd, geometryLocked, setGeometryLocked };

  return (
    <div key={`tab-section-${tab.id}`} styleName="form-tab">
      <SectionTitle readOnly={readOnly} tab={tab} />
      <div>
        {tab.fields.map(fieldName => (
          <FormField
            formCallbacks={formCallbacks}
            formState={newFormState}
            errors={errors}
            fieldName={fieldName}
            key={fieldName}
            metadata={metadata}
            readOnly={readOnly}
            tab={tab}
            visibleFields={visibleFields}
          />
        ))}
      </div>
    </div>
  );
};

FormTab.propTypes = {
  errors: PropTypes.object,
  formState: PropTypes.object,
  metadata: PropTypes.object,
  readOnly: PropTypes.bool,
  tab: PropTypes.object,
  visibleFields: PropTypes.array
};

export default FormTab;
