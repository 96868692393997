import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import { filterStyles } from '@constants/mui-theme';
import { shallowEqual } from '@utils/react-utils';
import { BaseFilter } from './base-filter';

// Base class for top bar filters that uses a Checkbox:
export class BaseCheckboxFilter extends BaseFilter {
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.filter, this.props.filter) ||
      !shallowEqual(nextProps.name, this.props.name) ||
      this.state !== nextState
    );
  }

  filterClose() {
    const { filter, offValue, onValue } = this.props;
    const newFilter = {
      [this.props.name]: onValue === filter ? offValue : onValue
    };
    this.setFilter(newFilter);
  }

  render() {
    const { filter, label, onValue } = this.props;
    return (
      <div>
        <Checkbox
          {...filterStyles.dashboardCheck}
          checked={filter === onValue}
          label={label}
          onCheck={this.filterClose}
        />
      </div>
    );
  }
}

BaseCheckboxFilter.propTypes = {
  filter: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  // Value for when the checkbox is off.
  offValue: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  // Value for when the checkbox is on.
  onValue: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};
