import React from 'react';
import PropTypes from 'prop-types';
import CoreEditField from './core/edit-field';
import CoreViewField from './core/view-field';
import CoreInlineEditField from './core/inline-edit-field';
import InlineEditField from './inline-edit-field';
import EditField from './edit-field';
import ViewField from './view-field';
import './section.scss';

const Section = ({
  data, disabled, edit, error, fieldPrefix, fields, inlineEdit, name, onChange, section, viewType
}) => {
  const renderField = (field, index) => {
    const editProps = { data, error, onChange, section };

    if (field.core) {
      const coreProps = { ...editProps, ...field };
      if (inlineEdit) {
        return <CoreInlineEditField disabled={disabled} {...coreProps} fieldPrefix={fieldPrefix} viewType={viewType} />;
      }

      if (edit) {
        return <CoreEditField {...coreProps} />;
      }

      return <CoreViewField {...coreProps} />;
    }

    if (inlineEdit) {
      return <InlineEditField {...editProps} field={field} fieldPrefix={fieldPrefix} key={field.id} position={index} />;
    }

    if (edit) {
      return <EditField {...editProps} field={field} key={field.id} position={index} />;
    }
    return <ViewField data={data} field={field} key={field.id} />;
  };

  return (
    <div styleName="section">
      <div styleName="section-header">
        {/* This nesting is needed, since other sections (like attachments and linked entities)
            may have a count and action DIVs. */}
        <div styleName="section-header-title">
          <div styleName="section-header-title-text">
            {name}
          </div>
        </div>
      </div>
      <div styleName="section-field-list">
        {fields.map((field, index) => renderField(field, index))}
      </div>
    </div>
  );
};

Section.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldPrefix: PropTypes.string,
  fields: PropTypes.array,
  inlineEdit: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  section: PropTypes.number,
  viewType: PropTypes.string
};

export default Section;
