import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, CardText } from 'material-ui/Card';
import RefreshIndicator from 'material-ui/RefreshIndicator';
import { authLogout } from '@actions/auth-actions';
import { dotmapsGreen } from '@constants/colors';
import { logoutStyles } from '@constants/mui-theme';
import './layouts/not-authenticated/not-authenticated.scss';

class Logout extends Component {
  UNSAFE_componentWillMount() {
    this.props.authLogout();
  }
  render() {
    return (
      <Card styleName="not-authenticated-container">
        <CardText style={logoutStyles.cardText}>Signing out...</CardText>
        <CardText style={logoutStyles.cardText}>
          <div style={logoutStyles.indicatorContainer}>
            <RefreshIndicator
              size={70}
              left={0}
              top={0}
              loadingColor={dotmapsGreen}
              status="loading"
              style={logoutStyles.indicator}
            />
          </div>
        </CardText>
      </Card>
    );
  }
}

Logout.propTypes = {
  authLogout: PropTypes.func
};

export default connect(null, { authLogout })(Logout);

