/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setMapModes } from '@actions/map-actions';
import { SwitchLabel } from '@shared/components/switch-label';
import { getMapModesSet } from '@selectors/map-selector';
import filterStyles from '../../filter.scss';  // eslint-disable-line css-modules/no-unused-class
import styles from './overlaps.scss';

class OverlapsFilter extends Component {
  updateMode = (mode, enabled) => {
    // Sets the mode. We are now treating it as a radio, so we discard any previously set modes every time.
    const newModes = new Set();
    if (enabled) {
      newModes.add(mode);
    }
    this.props.setMapModes(Array.from(newModes));
  };

  toggleConflict = (event, checked) => this.updateMode('conflict', checked);

  toggleOpportunity = (event, checked) => this.updateMode('opportunity', checked);

  togglePending = (event, checked) => this.updateMode('pending', checked);

  toggleResolved = (event, checked) => this.updateMode('resolved', checked);

  render() {
    const { disabled, label, modes, userAgency } = this.props;
    let agencyName = '';
    if (userAgency && !userAgency.is_admin) {
      agencyName = `${userAgency.name} `;
    }
    return (
      <div className={filterStyles['content-filter-group']}>
        <div className={filterStyles.title}>
          <div className={filterStyles.label}>
            Highlight {agencyName}{label.toLowerCase()} containing:
          </div>
        </div>
        <div className={styles['overlap-row']}>
          <SwitchLabel
            disabled={disabled}
            toggled={!disabled && modes.has('conflict')}
            onToggle={this.toggleConflict}
            label="Open conflicts"
            icon={<img src="https://storage.googleapis.com/coordinate-assets/icon/conflict.svg" alt=""/>}
          />
          <SwitchLabel
            disabled={disabled}
            toggled={!disabled && modes.has('pending')}
            onToggle={this.togglePending}
            label="Pending conflicts"
            icon={<img src="https://storage.googleapis.com/coordinate-assets/icon/pending.svg" alt=""/>}
          />
          <SwitchLabel
            disabled={disabled}
            toggled={!disabled && modes.has('resolved')}
            onToggle={this.toggleResolved}
            label="Resolved conflicts"
            icon={<img src="https://storage.googleapis.com/coordinate-assets/icon/resolved.svg" alt=""/>}
          />
          <SwitchLabel
            disabled={disabled}
            toggled={!disabled && modes.has('opportunity')}
            onToggle={this.toggleOpportunity}
            label="Opportunities"
            icon={<img src="https://storage.googleapis.com/coordinate-assets/icon/opportunity.svg" alt=""/>}
          />
        </div>
      </div>
    );
  }
}

OverlapsFilter.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  modes: PropTypes.instanceOf(Set),
  setMapModes: PropTypes.func,
  userAgency: PropTypes.object
};

const mapStateToProps = state => {
  const { agency } = state.dataTypes;
  const { agencyId } = state.auth.user;
  return {
    modes: getMapModesSet(state),
    userAgency: agency[agencyId]
  };
};

const mapDispatchToProps = { setMapModes };

export default connect(mapStateToProps, mapDispatchToProps)(OverlapsFilter);
