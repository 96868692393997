/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from 'material-ui/svg-icons/navigation/close';
import './dialog-close-button.scss';

const DialogCloseButton = ({ onClose }) => (
  <div
    onClick={onClose}
    role="presentation"
    styleName="dialog-close-button-container"
  >
    <CloseIcon style={{ width: '18px', height: '18px'}} />
  </div>
);

DialogCloseButton.propTypes = {
  onClose: PropTypes.func
};

export default DialogCloseButton;
