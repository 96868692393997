import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import {
  renderWarning,
  showEntityScheduleContainsWarning
} from '@utils/segment-schedule/common';
import '../../../../../../forms/forms.scss';

const ScheduleWarning = ({ data, dataType }) => {
  if (showEntityScheduleContainsWarning(data.start_date, data.end_date, data.segments)) {
    return (
      <div styleName="form-warning">
        {renderWarning(`${capitalize(dataType)} schedule does not contain all location schedules.`)}
      </div>
    );
  }
  return null;
};

ScheduleWarning.propTypes = {
  data: PropTypes.object,
  dataType: PropTypes.string
};

export default ScheduleWarning;
