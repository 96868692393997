import React from 'react';

const CalendarIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="calendar-path-1"
        d={`
           M13.5 1.5h-.75V0h-1.5v1.5h-7.5V0h-1.5v1.5H1.5C.675 1.5 0 2.175 0 3v12c0
           .825.675 1.5 1.5 1.5h12c.825 0 1.5-.675 1.5-1.5V3c0-.825-.675-1.5-1.5-1.5zm0
           13.5h-12V5.25h12V15z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 .75)">
      <mask id="calendar-path-2" fill="#fff">
        <use xlinkHref="#calendar-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#calendar-path-2)">
        <path d="M-1.5-.75h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default CalendarIcon;
