import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog } from 'material-ui';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import {
  addToGroups,
  fetchGroupsData
} from '@actions/groups-actions';
import { getGroupTypesConfig } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { addToGroupListStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import ChipsAddToGroup from '@shared/chips-add-to-group';
import {
  findGroupType,
  prepareGroupsForBulkAdd
} from '@utils/group-utils';

class AddToGroupExcludeOverlapDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupType: getGroupTypesConfig()[0].id,
      selectedGroups: []
    };
  }

  UNSAFE_componentWillMount() {
    this.props.fetchGroupsData();
  }

  handleCancel = () => this.props.closeDashboardDialog(dialog.ADD_TO_GROUP_EXCLUDE_OVERLAP);

  setSelectedGroups = selectedGroups => this.setState({ selectedGroups });

  handleSubmit = () => {
    const { dialog: { addDataId, addDataType, addDataExtra } } = this.props;
    const { groupType, selectedGroups } = this.state;
    const payload = prepareGroupsForBulkAdd({
      selectedGroups,
      entityIds: [addDataId],
      includeOverlaps: false
    });
    const selectedGroupType = findGroupType(groupType);
    this.props.closeDashboardDialog(dialog.ADD_TO_GROUP_EXCLUDE_OVERLAP);
    this.props.addToGroups(selectedGroupType.name, payload, addDataType, false, addDataExtra);
  };

  getActions = () => {
    const { selectedGroups } = this.state;
    const { dialog: dialogItem } = this.props;
    const active = selectedGroups.length > 0 && dialogItem.addDataId && dialogItem.addDataType;
    return [
      <Button key="cancel" onClick={this.handleCancel}>CANCEL</Button>,
      <Button
        color="primary"
        disabled={!active}
        key="save"
        onClick={this.handleSubmit}
        style={{ marginLeft: '0.5rem' }}
        variant="contained"
      >
        SAVE
      </Button>
    ];
  };

  render() {
    const { groupType, selectedGroups } = this.state;
    const { activeDialogs, dialog: { addDataGroups, addDataType } } = this.props;
    if (!activeDialogs[dialog.ADD_TO_GROUP_EXCLUDE_OVERLAP]) {
      return null;
    }
    const selectedGroupType = findGroupType(groupType);
    // Build groups list to display as Chips:
    const groups = addDataGroups.map(group => {
      const { id, name } = group;
      const selected = selectedGroups.includes(group.id);
      return { id, name, selected };
    });
    return (
      <Dialog
        actions={this.getActions()}
        contentStyle={addToGroupListStyles.dialog}
        open={activeDialogs[dialog.ADD_TO_GROUP_EXCLUDE_OVERLAP]}
        title="Exclude overlap"
      >
        <ChipsAddToGroup
          dataType={addDataType}
          groups={groups}
          groupTypeName={selectedGroupType.name}
          setSelectedGroups={this.setSelectedGroups}
        />
      </Dialog>
    );
  }
}

AddToGroupExcludeOverlapDialog.propTypes = {
  activeDialogs: PropTypes.object,
  addToGroups: PropTypes.func,
  closeDashboardDialog: PropTypes.func,
  dialog: PropTypes.object,
  fetchGroupsData: PropTypes.func
};

const mapStateToProps = state => {
  const { activeDialogs } = state.dashboard;
  const { groups } = state;
  return {
    activeDialogs,
    dialog: groups.dialog
  };
};

export default connect(mapStateToProps, {
  addToGroups,
  closeDashboardDialog,
  fetchGroupsData
})(
  AddToGroupExcludeOverlapDialog
);
