import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import CustomEditField from '@components/workflow/cycle/content/section/custom-edit-field';

const EditField = ({ data, error, field, onChange: onChangeField, position, section }) => {
  // Return the first part of the key to save the field's value on the Redux store.
  // We save the task form data inside 'field_values' at the position that
  // the field is displayed in the UI.
  const fieldPath = `${section}.field_values.${position}`;

  // The path where to store the field id:
  const fieldIdPath = `${fieldPath}.field`;

  // The path where to store the field value:
  const fieldValuePath = `${fieldPath}.value`;

  const onChange = useCallback(
    (event, value) => {
      // Store the field id (it's empty if we are creating a new task):
      onChangeField(fieldIdPath, field.id);
      // And store the value we've just edited:
      onChangeField(fieldValuePath, value);
    },
    [field.id, fieldIdPath, fieldValuePath, onChangeField]
  );

  const errors = error ? error[fieldIdPath] : null;

  return (
    <CustomEditField
      data={data}
      errors={errors}
      field={field}
      onChange={onChange}
      section={section}
    />
  );
};

EditField.propTypes = {
  data: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  field: PropTypes.object,
  onChange: PropTypes.func,
  position: PropTypes.number,
  section: PropTypes.number
};

export default memo(EditField);
