/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FontIcon from 'material-ui/FontIcon';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { fetchOverlapEntities } from '@actions/data-detail-actions';
import { openGroupAddEntityDialog } from '@actions/groups-actions';
import {
  BaseTrayEntity,
  baseTrayEntityMapDispatchToProps,
  baseTrayEntityMapEntityProps,
  baseTrayEntityMapStateToProps
} from '@components/map/tray/base-tray-entity';
import DetailsTab from '@components/map/tray/entity/details-tab';
import FilesTab from '@components/map/tray/entity/files-tab';
import OverlapsTab from '@components/map/tray/entity/overlaps-tab';
import { getTrayConfig } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { mapTrayStyles } from '@constants/mui-theme';
import EntityTypeIcon from '@icons/entity-type-icon';
import { getEntityDetailsTrayConfig } from '@selectors/data-detail-selector';
import FieldsLinkButton from '@shared/fields-link-button';
import { Tabs, Tab } from '@shared/tabs';
import { getEntityLegend, PriorityIcon } from '@utils/icon-utils';
import { overlapLink } from '@utils/overlap-utils';
import { canAccessFiles, canAccessOverlaps, canViewEntityPage, isReadOnly } from '@utils/permission-utils';
import '../tray.scss';

class Entity extends BaseTrayEntity {
  getDataType = () => 'entity';

  getEntityType = () => this.props.entityType;

  componentDidMount() {
    const {entity, pending} = this.props;
    if (!pending && entity) {
      this.loadTabsData();
    }
  }

  componentDidUpdate(prevProps) {
    const {entity, pending} = this.props;
    const {entity: prevEntity, pending: prevPending} = prevProps;
    if (
      !pending && entity && (
        prevPending ||
        !prevEntity ||
        entity.id !== prevEntity.id
      )
    ) {
      this.loadTabsData();
    }
  }

  loadTabsData = () => {
    const { entity } = this.props;
    const { activeTab } = this.state;
    const { id } = entity;
    this.props.getAttachments(this.getDataType(), id);
    if (this.hasOverlaps()) {
      this.props.fetchOverlapEntities(id);
    }
    if (activeTab !== 'details') {
      this.setDetailsActive();
    }
  };

  getIcon = () => <EntityTypeIcon type={this.props.entityType} size="0.8125rem" />;

  getTrayConfig = () => {
    const trayConfig = getTrayConfig();
    const entityType = this.getEntityType();
    return trayConfig[entityType] ? trayConfig[entityType] : {};
  };

  getPriority = entity => entity.category_dict && entity.category_dict.find(category => category.name === 'priority');

  getSubHeader = () => {
    const { entity } = this.props;
    const trayConfig = this.getTrayConfig();
    const linkData = trayConfig['link-button'];
    const priority = this.getPriority(entity);
    return (
      <div>
        <div styleName="map-tray-subheader-header">
          <FontIcon
            className="material-icons"
            styleName="icon"
            onClick={this.toggleShowAll}
          >
            {this.getIcon()}
          </FontIcon>
          <span styleName="id">ID {entity.id}</span>
          {priority && <PriorityIcon priority={priority.id} />}
        </div>
        <div styleName={`map-tray-subheader-body ${this.state.scrolling ? 'scrolling' : ''}`}>{entity.name}</div>
        <div styleName="map-tray-subheader-footer">
          {this.getSubHeaderDate()}
        </div>
        {linkData && <FieldsLinkButton entity={entity} link={linkData} />}
      </div>
    );
  };

  getHeaderIcon() {
    const { agencyTypes, entity, entityType } = this.props;
    const agencyType = agencyTypes[entity.agency_type];
    return getEntityLegend(agencyType, entityType, entity.iconId);
  }

  getHeaderDetailType() {
    return this.props.entity.title;
  }

  setDetailsActive = () => this.setState({activeTab: 'details'});

  setOverlapsActive = () => this.setState({activeTab: 'overlaps'});

  setFilesActive = () => this.setState({activeTab: 'files'});

  getConflictsCount = entity => !entity.conflicts || entity.conflicts === '' ? 0 : entity.conflicts.split(',').length;

  getOpportunitiesCount = entity => !entity.opportunities || entity.opportunities === '' ? 0 : entity.opportunities.split(',').length;

  hasOverlaps = () => {
    const { entity } = this.props;
    const conflicts = this.getConflictsCount(entity);
    const opportunities = this.getOpportunitiesCount(entity);
    return (conflicts + opportunities) > 0;
  };

  showFiles = () => {
    const trayConfig = getTrayConfig();
    const entityType = this.getEntityType();
    return R.pathOr(true, [entityType, 'files'], trayConfig);
  };

  getTabs = () => {
    const {
      attachments,
      detailsTrayConfig,
      entity,
      overlapErrors,
      overlapEntities
    } = this.props;
    const { conflicts, opportunities } = overlapEntities[entity.id] || { conflicts: [], opportunities: [] };
    return (
      <Tabs
        {...mapTrayStyles.tabs}
        onChange={this.clearSelection}
        onScroll={this.setScrolling}
        value={this.state.activeTab}
      >
        <Tab
          {...(this.state.activeTab === 'details' ? mapTrayStyles.activeTab : mapTrayStyles.tab)}
          label="Details"
          onActive={this.setDetailsActive}
          value="details"
        >
          <DetailsTab
            detailsTrayConfig={detailsTrayConfig}
            entity={entity}
            type={this.getDataType()}
          />
        </Tab>
        {overlapErrors === null && (this.hasOverlaps() && canAccessOverlaps(entity.agency)) &&
          <Tab
            {...(this.state.activeTab === 'overlaps' ? mapTrayStyles.activeTab : mapTrayStyles.tab)}
            label="Overlaps"
            onActive={this.setOverlapsActive}
            value="overlaps"
          >
            <OverlapsTab
              detailUrl={overlapLink(entity.id)}
              conflicts={conflicts}
              opportunities={opportunities}
              tabActive={this.state.activeTab === 'overlaps'}
              entityType={this.getEntityType()}
            />
          </Tab>
        }
        {(this.showFiles() && canAccessFiles(entity.agency)) && (
          <Tab
            {...(this.state.activeTab === 'files' ? mapTrayStyles.activeTab : mapTrayStyles.tab)}
            label="Files"
            onActive={this.setFilesActive}
            value="files"
          >
            <FilesTab
              dataType={this.getDataType()}
              entity={entity}
              attachments={attachments}
              deleteAttachment={this.deleteAttachment}
            />
          </Tab>
        )}
      </Tabs>
    );
  };

  addToGroup = () => {
    const { entity, entityType } = this.props;
    this.props.openGroupAddEntityDialog(entityType, entity);
  };

  startCycle = () => {
    const { entity } = this.props;
    const params = {
      entityId: entity.id
    };
    this.props.openDashboardDialog(dialog.START_CYCLE, params);
  };

  allowDetails = () => {
    const { entity, entityType } = this.props;
    const { agency } = entity;
    return canViewEntityPage(agency, entityType);
  };

  isReadOnly = () => {
    const { entityType } = this.props;
    return isReadOnly(entityType);
  };
}

Entity.propTypes = {
  detailsTrayConfig: PropTypes.array,
  entityType: PropTypes.string,
  fetchOverlapEntities: PropTypes.func,
  openDashboardDialog: PropTypes.func,
  openGroupAddEntityDialog: PropTypes.func,
  overlapEntities: PropTypes.object,
  overlapErrors: PropTypes.object,
  pending: PropTypes.bool
};

const mapStateToProps = state => {
  const props = baseTrayEntityMapStateToProps(state);
  const entityProps = baseTrayEntityMapEntityProps(props, state, 'entity');
  const { dataDetail } = state;
  const { overlapErrors, overlapEntities } = dataDetail;
  const pending = Boolean(props.tray.data.pending);
  const entityType = props.tray.data.entity.entity_type;
  const detailsTrayConfig = getEntityDetailsTrayConfig(state)[entityType];
  return {
    ...entityProps,
    entityType,
    overlapErrors,
    overlapEntities,
    detailsTrayConfig,
    pending,
    ...props
  };
};

const mapDispatchToProps = {
  ...baseTrayEntityMapDispatchToProps,
  fetchOverlapEntities,
  openDashboardDialog,
  openGroupAddEntityDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(Entity);

export { Entity as PureEntity };
