import React from 'react';
import PropTypes from 'prop-types';
import MuiArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as colors from '@constants/colors';
import { backArrowStyles } from '@constants/mui-theme';

const ArrowBackIcon = ({ color, onClick, style }) => (
  <MuiArrowBackIcon
    color={color}
    onClick={onClick}
    style={{
      ...backArrowStyles,
      ...style
    }}
  />
);

ArrowBackIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
};

ArrowBackIcon.defaultProps = {
  color: colors.textColor,
  style: { padding: '0.75rem' }
};

export default ArrowBackIcon;
