/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import debounce from 'lodash.debounce';
import {
  enableShapeSelection,
  openAreaTray,
  setDrawingDone,
  setDrawingPath,
  setViewport,
  viewEntityOnMap,
  viewGroupOnMap,
  viewMoratoriumOnMap
} from '@actions/map-actions';
import { setInitialEntityBounds } from '@actions/entities-actions';
import { isWorkflowEnabled } from '@constants/config';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import AddToGroupDialog from '@shared/dialogs/add-to-group-dialog';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';
import FilePreview from '@shared/files/file-preview';
import HorizontalDrawer from '@shared/horizontal-drawer';
import {
  didViewportChanged,
  renderHighlightCircle,
  segmentsToBounds,
  setMapStreetViewPosition
} from '@utils/map-utils';
import {
  addVertex,
  initializeMeasurement,
  onInteractiveStop,
  onMeasurementMouseMove
} from '@utils/measurement-utils';
import { isAppAdmin } from '@utils/permission-utils';
import { shallowEqual } from '@utils/react-utils';
import { withRouter } from '@utils/router-utils';
import Components from './components';
import MapDateRangeDialog from './dialogs/date-range-dialog';
import EntityDataTable from './entity-data-table';
import FloatingBar from './floating-bar';
import Legend from './legend';
import Loader from './loader';
import TabBar from './tab-bar';
import TabBarToggle from './tab-bar-toggle';
import Tray from './tray';
import './map.scss';

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = { viewportReady: false };
    this.onMapLoad = mapInstance => {
      if (!window.mapInstance || !window.highlightCircle) {
        window.highlightCircle = new google.maps.Circle();
      }
      if (mapInstance) {
        // Store instance on the windows namespace for faster zoom functionality:
        window.mapInstance = mapInstance;
        renderHighlightCircle();
        setMapStreetViewPosition(mapInstance);
      }
      this.setState({ mapInstance });
    };
    this.updateViewport = debounce(this.updateViewport.bind(this), 600);
  }

  UNSAFE_componentWillMount() {
    this.loadSingle(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.loadSingle(nextProps);
    }
    if (this.props.boundsData !== nextProps.boundsData &&
        nextProps.boundsData &&
        nextProps.boundsData.segments &&
        this.state.mapInstance) {
      const bounds = segmentsToBounds(nextProps.boundsData.segments);
      if (bounds) {
        this.state.mapInstance.fitBounds(bounds);
        if (this.state.mapInstance.getZoom() > 18) {
          this.state.mapInstance.setZoom(18);
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      didViewportChanged(nextProps.viewport, this.props.viewport) ||
      // If the state changes, re-render only if viewportReady changed,
      // don't worry about mapInstance, it's required by the floating bar
      // but it's the viewport change the one that we will re-render the map
      // when needed, by skipping mapInstance, we reduce the number of re-renderings.
      !shallowEqual(nextState.viewportReady, this.state.viewportReady) ||
      !shallowEqual(nextProps.filters, this.props.filters) ||
      !shallowEqual(nextProps.agencyId, this.props.agencyId) ||
      !shallowEqual(nextProps.location, this.props.location)
    );
  }

  isEntity = dataType => {
    const { entityList } = this.props;
    return includes(entityList, dataType);
  };

  // Loads a single entity/group/moratorium on the map.
  loadSingle = props => {
    const searchParams = new URLSearchParams(props.location.search);
    for (const [dataType, value] of searchParams) {
      if (dataType === 'open') {
        if (value === 'shape') {
          this.props.enableShapeSelection();
        }
      } else
        if (dataType === 'group') {
          this.props.viewGroupOnMap(value);
        } else
          if (this.isEntity(dataType)) {
            this.props.viewEntityOnMap(value, dataType);
          } else
            if (dataType === 'moratorium') {
              this.props.viewMoratoriumOnMap(value);
            }
    }
  };

  updateViewport() {
    renderHighlightCircle();
    this.props.setViewport('map', this.state.mapInstance);
    if (!this.state.viewportReady) {
      this.props.setInitialEntityBounds(this.state.mapInstance.getBounds());
      this.setState({viewportReady: true});
    }
  }

  onMeasureDragEnd = () => this.props.setDrawingPath(window.measure.path);

  onMapClick = event => {
    const { done, mode } = this.props.drawing;
    if (mode !== '') {
      // If we are measuring distances, set the start or end positions:
      if (mode === 'measurement' && !done) {
        const position = event.latLng.toJSON();
        if (window.measure && window.measure.interactive) {
          addVertex(position);
        } else {
          initializeMeasurement(position, this.onMeasureDragEnd, this.props.setDrawingDone);
        }
        this.props.setDrawingPath(window.measure.path);
      }
      // Don't open anything on the tray if we are drawing a shape.
      return;
    }
    const position = {
      lng: event.latLng.lng(),
      lat: event.latLng.lat()
    };
    this.props.openAreaTray(position);
  };

  // eslint-disable-next-line no-unused-vars
  onMapDblClick = event => onInteractiveStop(this.onMeasureDragEnd, this.props.setDrawingDone);

  onMouseMove = event => {
    window.highlightCircle.setCenter(event.latLng);
    const { mode } = this.props.drawing;
    if (mode === 'measurement') {
      onMeasurementMouseMove(event);
    }
  };

  // dragstart/dragend workaround for mousemove bug (https://issuetracker.google.com/issues/121006350)
  onDragStart = event => {  // eslint-disable-line no-unused-vars
    window.dragStartCenter = window.mapInstance.getCenter();
  };

  onDragEnd = event => {  // eslint-disable-line no-unused-vars
    const map = window.mapInstance;
    if (window.dragStartCenter && window.dragStartCenter.equals(map.getCenter())) {
      // Execute panBy() if the map has not been moved during dragging.
      map.panBy(0, 0);
    }
  };

  render() {
    const { agencyId } = this.props;
    const { viewportReady } = this.state;
    const showComponents = viewportReady && window.google && (agencyId || isAppAdmin());
    return (
      <div styleName="map-container">
        <div styleName="map-content">
          <Loader />
          <TabBar />
          <TabBarToggle />
          <Tray/>
          {this.state.mapInstance && <Legend />}
          {this.state.mapInstance && <FloatingBar />}
          <DotMapsGoogleMap
            components={showComponents && <Components />}
            onBoundsChanged={this.updateViewport}
            onClick={this.onMapClick}
            onDblClick={this.onMapDblClick}
            onDragEnd={this.onDragEnd}
            onDragStart={this.onDragStart}
            onMapLoad={this.onMapLoad}
            onMouseMove={this.onMouseMove}
          />
          <AddToGroupDialog />
          <MapDateRangeDialog />
          {isWorkflowEnabled() && <StartCycleDialog />}
          <HorizontalDrawer>
            <EntityDataTable />
          </HorizontalDrawer>
        </div>
        <FilePreview />
      </div>
    );
  }
}

Map.propTypes = {
  agencyId: PropTypes.number,
  boundsData: PropTypes.object,
  config: PropTypes.object,
  drawing: PropTypes.object,
  enableShapeSelection: PropTypes.func,
  entityList: PropTypes.array,
  filters: PropTypes.object,
  location: PropTypes.object,
  openAreaTray: PropTypes.func,
  setDrawingDone: PropTypes.func,
  setDrawingPath: PropTypes.func,
  setInitialEntityBounds: PropTypes.func,
  setViewport: PropTypes.func,
  viewEntityOnMap: PropTypes.func,
  viewGroupOnMap: PropTypes.func,
  viewMoratoriumOnMap: PropTypes.func,
  viewport: PropTypes.object
};

const mapStateToProps = state => {
  const {
    boundsData,
    filters,
    viewport
  } = state.map;
  const { entities: { types } } = state;
  const { agencyId } = state.auth.user;
  const entityList = Object.keys(types);
  const { drawing } = state.map;

  return {
    agencyId,
    boundsData,
    drawing,
    entityList,
    filters,
    viewport
  };
};

const mapDispatchToProps = {
  enableShapeSelection,
  openAreaTray,
  setDrawingDone,
  setDrawingPath,
  setInitialEntityBounds,
  setViewport,
  viewEntityOnMap,
  viewGroupOnMap,
  viewMoratoriumOnMap
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Map));

export { Map as PureMap };
