/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import { toggleLayersMenu } from '@actions/map-actions';
import { textColor } from '@constants/colors';
import { layersMenuStyles } from '@constants/mui-theme';
import DefaultMapTypeIcon from '@icons/data-uri-icon/default-map-type-icon';
import SatelliteMapTypeIcon from '@icons/data-uri-icon/satellite-map-type-icon';
import TerrainMapTypeIcon from '@icons/data-uri-icon/terrain-map-type-icon';

import './layers-icon.scss';

class LayersIcon extends Component {
  render() {
    const { mapTypeId } = this.props;
    return (
      <div styleName="map-layers-icon" onClick={this.props.toggleLayersMenu} role="button" tabIndex="0">
        {mapTypeId === 'roadmap' && <DefaultMapTypeIcon style={layersMenuStyles.buttonIcons} />}
        {mapTypeId === 'hybrid' && <SatelliteMapTypeIcon style={layersMenuStyles.buttonIcons} />}
        {mapTypeId === 'terrain' && <TerrainMapTypeIcon style={layersMenuStyles.buttonIcons} />}
        <FontIcon className="material-icons" color={textColor}>
          arrow_drop_down
        </FontIcon>
      </div>
    );
  }
}

LayersIcon.propTypes = {
  mapTypeId: PropTypes.string,
  toggleLayersMenu: PropTypes.func
};

const mapStateToProps = state => {
  const { mapTypeId } = state.map;
  return { mapTypeId };
};

export default connect(mapStateToProps, { toggleLayersMenu })(LayersIcon);
