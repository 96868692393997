import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import { ListItem } from 'material-ui/List';
import { printStyles } from '@constants/mui-theme';
import { getAgencyIcon } from '@utils/icon-utils';

const AgencyTypeItem = ({ agencyType }) => (
  <ListItem
    disabled
    innerDivStyle={printStyles.agencyLegend.listItem}
    leftAvatar={
      <Avatar
        size={24}
        src={getAgencyIcon(agencyType)}
        style={printStyles.agencyLegend.avatar}
        alt=""
      />
    }
  >
    {agencyType.name}
  </ListItem>
);

AgencyTypeItem.propTypes = {
  agencyType: PropTypes.object
};

export default AgencyTypeItem;
