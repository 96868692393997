import React from 'react';

const EditIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="edit-path-1"
        d={`
            M0 11.438v2.812h2.813l8.294-8.295-2.812-2.813L0 11.438zM13.283 3.78a.747.747
            0 0 0 0-1.057L11.527.968a.747.747 0 0 0-1.058 0L9.097 2.34l2.813 2.813
            1.373-1.373z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(2.25 1.5)">
      <mask id="edit-path-2" fill="#fff">
        <use xlinkHref="#edit-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#edit-path-2)">
        <path d="M-2.25-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default EditIcon;
