import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SortIconButton from './sort-icon-button';
import {
  dotmapsBlack50,
  dotmapsBlack80
} from '@constants/colors';
import { getFilters } from '@selectors/data-table-selector';
import { isCurrentFilter } from '@utils/filter-utils';
import './header.scss';

class Header extends Component {
  getColumnWidth = width => ({
    maxWidth: `${width}rem`,
    minWidth: `${width}rem`,
    width: `${width}rem`
  });
  getSortIcon = column => {
    const { dataType, subType } = this.props;
    return <SortIconButton column={column} dataType={dataType} subType={subType} />;
  };
  getTextColor = column => {
    if (column.ordering) {
      const { filters } = this.props;
      if (filters) {
        const { ordering } = column;
        if (isCurrentFilter(filters, ordering)) {
          return dotmapsBlack80;
        }
      }
    }
    return dotmapsBlack50;
  };
  render() {
    const { columns, isFixed } = this.props;
    return (
      <thead styleName="table-header">
        <tr>
          {columns.map(column => {
            const { styleName } = column;
            const isRight = styleName && styleName.includes('right');
            // If we are not sorting, use a hidden SortIcon to maintain space,
            // since if it's the first column, height might be misaligned, since
            // the first column is fixed (i.e. it doesn't scroll).
            const sortIcon = column.ordering ? this.getSortIcon(column) : <SortIconButton hidden />;
            return (
              <td key={column.key}
                style={this.getColumnWidth(column.width)}
                styleName={`column-container ${styleName ? styleName : ''} ${isFixed ? 'column-fixed' : ''}`}
              >
                <div styleName="column-wrapper">
                  {isRight && sortIcon}
                  <span style={{ color: this.getTextColor(column) }}>{column.label}</span>
                  {!isRight && sortIcon}
                </div>
              </td>
            );
          })}
        </tr>
      </thead>
    );
  }
}

Header.propTypes = {
  columns: PropTypes.array.isRequired,
  dataType: PropTypes.string.isRequired,
  filters: PropTypes.object,
  isFixed: PropTypes.bool,
  subType: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const filters = getFilters(state, props);
  return { filters };
};

export default connect(mapStateToProps, {})(Header);

export { Header as PureHeader };
