import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDatatableData } from '@actions/data-table-actions';
import {
  getDataTableColumns,
  getFiltersSelector,
  getLoader,
  optimizeRowsForDataTable
} from '@selectors/data-table-selector';
import Table from '@shared/table';
import { getEmptyLabel, onRowClick } from '@utils/data-table-utils';
import Header from './header';
// import Search from './search';  // TODO
import './data-table.scss';

const getTypes = ({ baseType, dataType }) => {
  if (baseType) {
    return {
      dataType: baseType === 'overlaps' ? 'overlap' : baseType,
      subType: dataType
    };
  }
  return { dataType };
};

const useDataTable = props => {
  const { dataType, embedded, subType } = props;
  const columns = useSelector(state => getDataTableColumns(state, props));
  const filters = useSelector(state => getFiltersSelector(state, props));
  const loading = useSelector(state => getLoader(state, props));
  const rows = useSelector(state => optimizeRowsForDataTable(dataType, subType, embedded)(state));

  return { columns, filters, loading, rows };
};

const DataTable = ({ embedded }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const { dataType, subType } = getTypes(params);
  const { columns, filters, loading, rows } = useDataTable({ dataType, embedded, subType });

  useEffect(() => {
    dispatch(fetchDatatableData(dataType, subType, filters));
  }, [dataType, subType, filters, dispatch]);

  if (rows) {
    const actions = null;  // TODO

    const tableProps = {
      actions,
      columns,
      empty: { message: getEmptyLabel(dataType) },
      header: <Header dataType={dataType} />,
      loading,
      // eslint-disable-next-line no-unused-vars
      onRowClick: (event, row, index) => onRowClick(event, row, dataType, subType, dispatch, embedded),
      rows,
      search: null  // TODO
    };

    return (
      <div styleName="data-table-container">
        <Table {...tableProps} />
      </div>
    );
  }
  return null;
};

DataTable.propTypes = {
  /**
   * Flag to tell if this component will be used
   * embedded in other component.
   */
  embedded: PropTypes.bool
};

DataTable.defaultProps = {
  embedded: false
};

export default DataTable;
