/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { addWorkflowTaskType } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import {
  autocompleteSearchStyles,
  dialogStyles
} from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import InputSearch from '@shared/input-search';
import {
  getCorrelationIds,
  getLatestPublishedTaskTypes,
  getTaskTypesNotInWorkflow
} from '@utils/workflow-utils';

const AddWorkflowTaskTypeDialog = () => {
  const [items, setItems] = useState([]);

  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);
  const { task_type: task_types } = useSelector(state => state.dataTypes);
  const correlationIds = useMemo(() => getCorrelationIds(items, task_types), [items, task_types]);

  const { id, workflow = { task_types: [] } } = dialogParams[dialog.ADD_WORKFLOW_TASK_TYPE] || {};
  const isDialogActive = activeDialogs[dialog.ADD_WORKFLOW_TASK_TYPE];
  const taskTypes = getLatestPublishedTaskTypes(getTaskTypesNotInWorkflow(task_types, workflow));

  useEffect(() => {
    if (!isDialogActive) {
      setItems([]);
    }
  }, [isDialogActive, setItems]);

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.ADD_WORKFLOW_TASK_TYPE));
    },
    [dispatch]
  );

  const onAdd = useCallback(
    () => dispatch(addWorkflowTaskType(id, correlationIds)),
    [correlationIds, dispatch, id]
  );

  const handleTaskTypeChange = (event, values) => setItems(values.map(value => value.value));

  if (!isDialogActive) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Add task types
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{
          paddingBottom: '1rem'  // Fix for unwanted DialogContent scrollbars.
        }}>
          <InputSearch
            {...autocompleteSearchStyles.normal}
            fieldName="published_task_types"
            label="Search published task types"
            onChange={handleTaskTypeChange}
            options={taskTypes}
            values={items}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          onClick={onAdd}
          variant="contained"
        >
          ADD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWorkflowTaskTypeDialog;
