import { connect } from 'react-redux';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getFilters } from '@selectors/data-table-selector';
import FutureDateFilter from '@shared/top-filter-bar/date-filter/future-date-filter';
import { decodeDateFilters } from '@utils/date-utils';

export const mapStateToProps = (state, props) => {
  const filters = getFilters(state, props);
  const { start, end, contain } = decodeDateFilters(filters);
  return { start, end, contain };
};

class DateFilter extends FutureDateFilter {
  setFilter(filter) {
    const { dataType, subType } = this.props;
    this.props.setFilter(dataType, subType, filter);
  }
}

export default connect(mapStateToProps, { openDialog: openDashboardDialog, setFilter: setDatatableFilter })(DateFilter);
