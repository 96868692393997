import React from 'react';
import PropTypes from 'prop-types';
import Menu from './menu';
import TopBar from './top-bar';
import './settings.scss';

const Settings = ({ children }) => (
  <div styleName="settings-content-container">
    <TopBar />
    <div styleName="settings-container">
      <Menu />
      <div styleName="settings-content">
        {children}
      </div>
    </div>
  </div>
);

Settings.propTypes = {
  children: PropTypes.element
};

export default Settings;
