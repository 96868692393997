import React, { useState } from 'react';
import DetailsContent from '@components/details/details-content';
import WorkflowLayout from './workflow-layout';
import WorkflowDrawer from './workflow-drawer';
import WorkflowPreview from './workflow-preview';
import './workflow.scss';

const Layout = WorkflowLayout(WorkflowDrawer, WorkflowPreview);

const Workflow = () => {
  const [taskType, setTaskType] = useState(null);
  return (
    <DetailsContent>
      <div styleName="workflow-container">
        <div styleName="workflow-content-container">
          <Layout selectedTaskType={taskType} setSelectedTaskType={setTaskType} />
        </div>
      </div>
    </DetailsContent>
  );
};

export default Workflow;
