import React from 'react';

const CheckCircleIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="check-circle-path-1"
        d={`
            M7.5 0C3.36 0 0 3.36 0 7.5 0 11.64 3.36 15 7.5 15c4.14 0 7.5-3.36
            7.5-7.5C15 3.36 11.64 0 7.5 0zM6 11.25L2.25 7.5l1.058-1.058L6
            9.127l5.693-5.692L12.75 4.5 6 11.25z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 1.5)">
      <mask id="check-circle-path-2" fill="#fff">
        <use xlinkHref="#check-circle-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#check-circle-path-2)">
        <path d="M-1.5-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default CheckCircleIcon;
