//
// This component renders a date range on a form (i.e. start and end date fields).
//
/* eslint-disable max-depth */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import moment from 'moment-timezone';
import { detailEdit, formStyles } from '@constants/mui-theme';
import FormattedDatePicker from '@shared/formatted-date-picker';
import MomentTimePicker from '@shared/moment-time-picker';
import { shallowEqual } from '@utils/react-utils';
import './forms.scss';

class DateRange extends Component {
  constructor(props) {
    super(props);
    const { data } = props;
    this.state = {
      startDate: data.start_date ? moment(data.start_date) : null,
      startTime: data.start_date ? moment(data.start_date) : null,
      endDate: data.end_date ? moment(data.end_date) : null,
      endTime: data.end_date ? moment(data.end_date) : null,
      allDay: data.all_day
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.errors, this.props.errors) ||
      !shallowEqual(nextProps.metaData, this.props.metaData) ||
      this.state !== nextState
    );
  }

  componentDidUpdate = () => {
    const { errors } = this.props;
    const nullDateMsg = 'This field may not be null.';
    if (errors.start_date && errors.start_date.includes(nullDateMsg) && 
      (!errors.start_time || errors.start_time && !errors.start_time.includes(nullDateMsg))) {
      this.handleError('start_time', !this.state.startTime ? nullDateMsg : null);
      if (this.state.startDate) {
        this.handleError('start_date', null);
      }
    }

    if (errors.end_date && errors.end_date.includes(nullDateMsg) && 
      (!errors.end_time || errors.end_time && !errors.end_time.includes(nullDateMsg))) {
      this.handleError('end_time', !this.state.endTime ? nullDateMsg : null);
      if (this.state.endDate) {
        this.handleError('end_date', null);
      }
    }
  };

  handleError = (fieldName, error) => this.props.onError(fieldName, error, false);

  handleValueChange = (fieldName, value) => {
    const { onChange } = this.props;
    onChange(fieldName, value);
  };

  getFormattedDate = (date, time) => {
    const { allDay } = this.state;
    const { fieldNames } = this.props;

    if ((!fieldNames.includes('all_day') || allDay) && date) {
      return moment(date);
    } else if (fieldNames.includes('all_day') && !allDay && date && time) {
      return moment(date).hours(time.hours())
        .minutes(time.minutes());
    }
    return null;
  };

  handleDateTimeChange = () => {
    const { errors } = this.props;
    const { startDate, startTime, endDate, endTime } = this.state;

    const newStart = this.getFormattedDate(startDate, startTime);
    const newEnd = this.getFormattedDate(endDate, endTime);

    this.validateDate(newStart, newEnd);
    if (!errors.start_date && !errors.start_time) {
      this.handleValueChange('start_date', newStart);
    }
    if (!errors.end_date && !errors.end_time) {
      this.handleValueChange('end_date', newEnd);
    }
  };

  validateDate = (start, end) => {
    const { dataType } = this.props;
    if (start && end) {
      if (end.isBefore(start)) {
        const errorMsg = `${dataType} must end after it starts`;
        // We only set the error message on the 'end date' field:
        this.handleError('end_date', errorMsg);
        this.handleError('end_time', ' ');
      } else {
        this.handleError('end_date', null);
        this.handleError('end_time', null);
      }
    } else {
      // If the value was cleared, clear the error:
      this.handleError('start_date', null);
      this.handleError('start_time', null);
      this.handleError('end_date', null);
      this.handleError('end_time', null);
    }
  };

  onCheck = event => {
    const name = event.target.name;
    const newValue = !this.props.data[name];
    this.handleValueChange(name, newValue);
    if (newValue) {
      this.setState({allDay: newValue, startTime: null, endTime: null}, () => {
        this.handleError('start_time', null);
        this.handleError('end_time', null);
        this.handleDateTimeChange();
      });
    } else {
      this.setState({allDay: newValue});
    } 
  };

  getTimeLabel = dateText => {
    let timeFieldText = dateText.replace('date', 'time');
    timeFieldText = timeFieldText.replace('Date', 'Time');
    if (timeFieldText.search('time') < 0 && timeFieldText.search('Time') < 0) {
      timeFieldText = `${timeFieldText} time`;
    }
    return timeFieldText;
  };

  handleStartDateChange = value => {
    const { fieldNames } = this.props;
    if (value) {
      if (this.state.endDate && fieldNames.includes('end_date')) {
        this.setState({startDate: moment(value).set({hour: '00', minute: '00'})}, this.handleDateTimeChange);
      } else {
        this.setState({
          startDate: moment(value).set({hour: '00', minute: '00'}),
          endDate: moment(value).set({hour: '00', minute: '00'}) 
        }, this.handleDateTimeChange);
      }
    } else {
      this.setState({ startDate: null }, this.handleDateTimeChange);
    }
  };

  handleStartTimeChange = value => {
    if (value) {
      this.setState({ startTime: value }, this.handleDateTimeChange);
    } else {
      this.setState({ startTime: null }, this.handleDateTimeChange);
    }
  };

  handleEndDateChange = value => {
    if (value) {
      this.setState({ endDate: moment(value).set({hour: '00', minute: '00'}) }, this.handleDateTimeChange);
    } else {
      this.setState({ endDate: null }, this.handleDateTimeChange);
    }
  };

  handleEndTimeChange = value => {
    if (value) {
      this.setState({ endTime: value }, this.handleDateTimeChange);
    } else {
      this.setState({ endTime: null }, this.handleDateTimeChange);
    }
  };

  render() {
    const { data, fieldNames, metaData, readOnly, errors } = this.props;
    const { startDate, startTime, endDate, endTime } = this.state;
    const commonProps = {floatingLabelStyle: { whiteSpace: 'nowrap' }};

    const timeVisible = fieldNames.includes('all_day') && !data.all_day;
    const startStyle = timeVisible ? detailEdit.columnStyles.startDateRangeDate : detailEdit.columnStyles.col50;
    const endStyle = timeVisible ? detailEdit.columnStyles.endDateRangeDate : detailEdit.columnStyles.col50;
    if (metaData.all_day && Boolean(metaData.all_day.linebreak)) {
      return (
        <div>
          <FormattedDatePicker
            {...commonProps}
            clearable={!metaData.start_date.required}
            id="start_date"
            name="start_date"
            errorText={Array.isArray(errors.start_date) ? errors.start_date.join(', ') : null}
            onChange={this.handleStartDateChange}
            value={startDate ? startDate.toDate() : null}
            fullWidth
            style={{...detailEdit.columnStyles.DateTimeRange}}
            disabled={metaData.start_date.read_only || readOnly}
            floatingLabelText={(metaData.start_date.label) + (metaData.start_date.required ? ' *' : '')}
            maxDate={fieldNames.includes('end_date') && endDate ? endDate : null}
          />
          {timeVisible &&
            <MomentTimePicker
              {...commonProps}
              onChange={this.handleStartTimeChange}
              id="start_time"
              name="start_time"
              errorText={Array.isArray(errors.start_time) ? errors.start_time.join(', ') : null}
              value={startTime || null}
              fullWidth
              style={{...detailEdit.columnStyles.DateTimeRange}}
              disabled={metaData.start_date.read_only || readOnly}
              floatingLabelText={
                (this.getTimeLabel(metaData.start_date.label)) + (metaData.start_date.required ? ' *' : '')}
            />
          }
          {fieldNames.includes('end_date') &&
          <FormattedDatePicker
            {...commonProps}
            clearable={!metaData.start_date.required}
            id="end_date"
            name="end_date"
            errorText={Array.isArray(errors.end_date) ? errors.end_date.join(', ') : null}
            onChange={this.handleEndDateChange}
            value={endDate ? endDate.toDate() : null}
            fullWidth
            style={{...detailEdit.columnStyles.DateTimeRange}}
            disabled={metaData.end_date.read_only || readOnly}
            floatingLabelText={(metaData.end_date.label) + (metaData.end_date.required ? ' *' : '')}
            minDate={startDate || null}
          />
          }
          {fieldNames.includes('end_date') && timeVisible &&
            <MomentTimePicker
              {...commonProps}
              onChange={this.handleEndTimeChange}
              id="end_time"
              name="end_time"
              errorText={Array.isArray(errors.end_time) ? errors.end_time.join(', ') : null}
              value={endTime || null}
              fullWidth
              style={{...detailEdit.columnStyles.DateTimeRange}}
              disabled={metaData.end_date.read_only || readOnly}
              floatingLabelText={
                (this.getTimeLabel(metaData.end_date.label)) + (metaData.end_date.required ? ' *' : '')}
            />
          }
          {fieldNames.includes('all_day') &&
          <div styleName="checkbox-field">
            <Checkbox
              {...detailEdit.check}
              labelPosition="right"
              label={metaData.all_day.label}
              labelStyle={formStyles(readOnly).checkbox.labelStyle}
              disabled={readOnly}
              checked={data.all_day}
              onCheck={this.onCheck}
              name="all_day"
              style={{...detailEdit.check.style}}
              styleName={metaData.all_day.style || 'col100'}
            />
          </div>
          }
        </div>
      );
    }
    return (
      <div>
        <FormattedDatePicker
          {...commonProps}
          clearable={!metaData.start_date.required}
          id="start_date"
          name="start_date"
          errorText={Array.isArray(errors.start_date) ? errors.start_date.join(', ') : null}
          onChange={this.handleStartDateChange}
          value={startDate ? startDate.toDate() : null}
          style={{...startStyle}}
          styleName="input-field"
          disabled={metaData.start_date.read_only || readOnly}
          floatingLabelText={(metaData.start_date.label) + (metaData.start_date.required ? ' *' : '')}
          maxDate={fieldNames.includes('end_date') && endDate ? endDate : null}
          fullWidth={!timeVisible}
        />
        {timeVisible &&
          <MomentTimePicker
            {...commonProps}
            onChange={this.handleStartTimeChange}
            id="start_time"
            name="start_time"
            errorText={Array.isArray(errors.start_time) ? errors.start_time.join(', ') : null}
            value={startTime || null}
            fullWidth
            style={{...detailEdit.columnStyles.startDateRangeTime}}
            styleName="input-field"
            disabled={metaData.start_date.read_only || readOnly}
            floatingLabelText={
              (this.getTimeLabel(metaData.start_date.label)) + (metaData.start_date.required ? ' *' : '')}
          />
        }
        {fieldNames.includes('end_date') && timeVisible &&
          <MomentTimePicker
            {...commonProps}
            onChange={this.handleEndTimeChange}
            id="end_time"
            name="end_time"
            errorText={Array.isArray(errors.end_time) ? errors.end_time.join(', ') : null}
            value={endTime || null}
            fullWidth
            style={{...detailEdit.columnStyles.endDateRangeTime}}
            styleName="input-field"
            disabled={metaData.end_date.read_only || readOnly}
            floatingLabelText={
              (this.getTimeLabel(metaData.end_date.label)) + (metaData.end_date.required ? ' *' : '')}
          />
        }
        {fieldNames.includes('end_date') &&
        <FormattedDatePicker
          {...commonProps}
          clearable={!metaData.start_date.required}
          id="end_date"
          name="end_date"
          errorText={Array.isArray(errors.end_date) ? errors.end_date.join(', ') : null}
          onChange={this.handleEndDateChange}
          value={endDate ? endDate.toDate() : null}
          style={{...endStyle}}
          styleName="input-field"
          disabled={metaData.end_date.read_only || readOnly}
          floatingLabelText={(metaData.end_date.label) + (metaData.end_date.required ? ' *' : '')}
          minDate={startDate || null}
          fullWidth={!timeVisible}
        />
        }
        {fieldNames.includes('all_day') &&
        <div styleName="checkbox-field">
          <Checkbox
            {...detailEdit.check}
            labelPosition="right"
            label={metaData.all_day.label}
            labelStyle={formStyles(readOnly).checkbox.labelStyle}
            disabled={readOnly}
            checked={data.all_day}
            onCheck={this.onCheck}
            name="all_day"
            style={{...detailEdit.check.style}}
            styleName={metaData.all_day.style || 'col100'}
          />
        </div>
        }
      </div>
    );
  }
}

DateRange.propTypes = {
  data: PropTypes.object,
  dataType: PropTypes.string,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldNames: PropTypes.array,
  metaData: PropTypes.object,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  readOnly: PropTypes.bool,
  style: PropTypes.object
};

export default DateRange;
