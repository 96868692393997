import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TextField from 'material-ui/TextField';
import { loginStyles } from '@constants/mui-theme';
import ActionButton from '@shared/action-button';
import '../../layouts/not-authenticated/not-authenticated.scss';

class PasswordCreate extends Component {
  render() {
    const { formChange, formSubmit, isOnboarding, passwordErrorText,
      fullname, password, passwordTwo } = this.props;

    const passwordCreateHeaderText = isOnboarding ? 'Complete your account' : 'Create new password';
    const submitLabel = isOnboarding ? 'CREATE ACCOUNT' : 'CONFIRM NEW PASSWORD';
    const submitDisabled = (isOnboarding && !fullname) || !password || !passwordTwo;

    return (
      <div>
        <div>
          <div>
            <div styleName="auth-heading">{ passwordCreateHeaderText }</div>
            <div>
              <form onSubmit={formSubmit}>
                {isOnboarding && (
                  <div styleName="not-authenticated-form-text-field">
                    <TextField
                      fullWidth
                      name="fullname"
                      onChange={formChange}
                      floatingLabelText="Full name"
                    />
                  </div>
                )}
                <div styleName="not-authenticated-form-text-field">
                  <TextField
                    fullWidth
                    name="password"
                    type="password"
                    onChange={formChange}
                    floatingLabelText="New password"
                  />
                </div>
                <div styleName="not-authenticated-form-text-field">
                  <TextField
                    fullWidth
                    name="passwordTwo"
                    type="password"
                    onChange={formChange}
                    errorText={passwordErrorText}
                    floatingLabelText="Confirm password"
                  />
                </div>
                <div>
                  <ActionButton
                    fullWidth
                    value="Submit"
                    type="submit"
                    label={submitLabel}
                    {...loginStyles.loginButton}
                    disabled={submitDisabled}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PasswordCreate.propTypes = {
  formChange: PropTypes.func,
  formSubmit: PropTypes.func,
  fullname: PropTypes.string,
  isOnboarding: PropTypes.bool,
  password: PropTypes.string,
  passwordErrorText: PropTypes.string,
  passwordTwo: PropTypes.string
};

export default PasswordCreate;
