import React from 'react';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';
import { baseMapStateToProps, DashboardBaseDropDownFilter } from '../dashboard-base-dropdown-filter';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getFiltersDashboard } from '@constants/config';
import { dialogStyles } from '@constants/mui-theme';
import { getAgencyIcon } from '@utils/icon-utils';

const SOURCE_DATA_TYPE = 'agency_type';

class AgencyTypeFilter extends DashboardBaseDropDownFilter {
  negateFilterIfEmpty = () => true;

  getDisplayName() {
    return 'Agency Type';
  }

  getPrimaryTextIcon(item) {
    const projectIcon = getAgencyIcon(item);
    const icon = (
      <Avatar
        size={25}
        src={projectIcon}
        style={dialogStyles.item.avatar}
        alt=""
      />
    );
    return icon;
  }
}

const mapStateToProps = (state, props) => {
  const { dataType, subType } = props;
  const params = {
    dataType,
    subType,
    sourceDataType: SOURCE_DATA_TYPE,
    lazyName: () => getFiltersDashboard()[dataType].find(filter => filter.name === SOURCE_DATA_TYPE).params[0]
  };
  return baseMapStateToProps(state, params);
};

export default connect(mapStateToProps, { setFilter: setDatatableFilter })(AgencyTypeFilter);
