/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import Toggle from 'material-ui/Toggle';
import PropTypes from 'prop-types';
import { openDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import EntityListItem from '@shared/list-item/entity-list-item';
import TriptychWidget from '@shared/list-item/widgets/triptych-widget';
import OverlapStatusRow from '@shared/overlap-status-row';
import '../../../shared/list-item/list-item.scss';

class OverlapListItem extends EntityListItem {
  getStyleNames = () => 'overlap-list-item';

  getAdditional = () => {
    const {
      highlightGeometry,
      isSelected,
      toggleHighlightGeometry
    } = this.props;
    if (isSelected) {
      return (
        <div styleName="additional">
          <Toggle
            onClick={event => {
              event.stopPropagation();
              toggleHighlightGeometry();
            }}
            iconStyle={{ margin: 0 }}
            style={{
              marginRight: '0.5rem',
              width: 'auto'
            }}
            toggled={highlightGeometry}
          />
          Highlight the overlapping geometry
        </div>
      );
    }
    return null;
  };

  getFooterContent = () => {
    const {
      dataType,
      isOpportunity,
      mainAgency,
      mainEntityType,
      mainOverlapStatus,
      overlap_status: overlapStatus,
      showResolution
    } = this.props;
    if (!showResolution) {
      return null;
    }
    return (
      <OverlapStatusRow
        isOpportunity={isOpportunity}
        itemEntityType={dataType}
        mainAgency={mainAgency}
        mainEntityType={mainEntityType}
        mainOverlapStatus={mainOverlapStatus}
        onClick={this.onViewStatusClick}
        overlapStatus={overlapStatus}
        showShadow
      />
    );
  };

  onViewStatusClick = event => {
    event.stopPropagation();
    const {
      mainId,
      agency_name,
      category_dict,
      id,
      icon,
      name,
      start_date,
      end_date,
      overlap_status,
      type_name
    } = this.props;
    const params = {
      entityId: mainId,
      entity: {
        agency_name,
        category_dict,
        id,
        icon,
        name,
        start_date,
        end_date,
        overlap_status,
        type_name
      },
      overlapEntityId: id,
      overlapId: R.pathOr(null, [0, 'overlap'], overlap_status)
    };
    this.props.openDashboardDialog(dialog.OVERLAP_STATUS, params);
  };

  getType = () => {};

  getDate = () => {
    const priority = this.getPriority();
    return (
      <TriptychWidget
        displayDuration
        priority={priority ? priority.id : null}
        start={this.props.start_date}
        end={this.props.end_date}
      />
    );
  };
}

OverlapListItem.propTypes = {
  highlightGeometry: PropTypes.bool,
  isOpportunity: PropTypes.bool,
  isSelected: PropTypes.bool,
  mainAgency: PropTypes.number,
  mainEntityType: PropTypes.string,
  mainId: PropTypes.number,
  mainOverlapStatus: PropTypes.array,
  showResolution: PropTypes.bool,
  toggleHighlightGeometry: PropTypes.func
};

export default connect(null, { openDashboardDialog })(OverlapListItem);
