import React from 'react';
import PropTypes from 'prop-types';
import MarkersModal from '@components/map/markers-modal';

const Components = ({ children }) => (
  <div>
    <MarkersModal small />
    {children}
  </div>
);

Components.propTypes = {
  children: PropTypes.node
};

export default Components;
