import React from 'react';

const NoUserIcon = () => (
  <svg width="24px" height="24px" version="1.1" viewBox="0 0 24 24">
    <defs>
      <path
        id="no-user-icon-path-1"
        d={`
            M4.375 0A4.372 4.372 0 0 0 0 4.375C0 7.656 4.375
            12.5 4.375 12.5S8.75 7.656 8.75 4.375A4.372 4.372
            0 0 0 4.375 0zm0 5.938a1.563 1.563 0 1 1
            .001-3.127 1.563 1.563 0 0 1-.001 3.127z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <circle cx="12" cy="12" r="12" fill="#3467D6" fillRule="nonzero" />
      <g transform="translate(7.625 5.75)">
        <mask id="no-user-icon-path-2" fill="#fff">
          <use xlinkHref="#no-user-icon-path-1" />
        </mask>
        <g fill="#FFF" mask="url(#no-user-icon-path-2)">
          <path d="M-3.125-1.25h15v15h-15z" />
        </g>
      </g>
    </g>
  </svg>
);

export default NoUserIcon;
