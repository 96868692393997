import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ResetRequest from './reset-request';
import PasswordCreate from './password-create';
import Success from './success';
import {
  authSetUserField,
  sendPasswordChangeRequest,
  sendNewPasswords
} from '@actions/auth-actions';
import { loginStyles } from '@constants/mui-theme';
import { validateEmail, validatePasswords } from '@utils/auth-utils';
import '../layouts/not-authenticated/not-authenticated.scss';

class PasswordRecovery extends Component {
  constructor(props) {
    super(props);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleNewPasswordSent = this.handleNewPasswordSent.bind(this);
    this.handlePasswordRequest = this.handlePasswordRequest.bind(this);

    this.state = {
      password: '',
      passwordTwo: ''
    };
  }

  handleFormChange(event) {
    const { name, value } = event.target;
    if (name !== 'password' && name !== 'passwordTwo') {
      this.props.authSetUserField(name, value);
    } else {
      this.setState({[name]: value});
    }
  }

  handlePasswordRequest() {
    const { email } = this.props;
    const emailErrorText = validateEmail(email) ? '' : 'Invalid email address';
    this.props.authSetUserField('emailErrorText', emailErrorText);
    if (emailErrorText === '') {
      this.props.sendPasswordChangeRequest(email);
    }
  }

  handleNewPasswordSent(token, userPk, onboarding) {
    const { fullname } = this.props;
    const { password, passwordTwo } = this.state;
    const passwordErrorText = validatePasswords(password, passwordTwo);
    this.props.authSetUserField('passwordErrorText', passwordErrorText);
    if (passwordErrorText === '') {
      this.props.sendNewPasswords({
        userPk,
        token,
        new_password1: password,
        new_password2: passwordTwo,
        ...(onboarding && {name: fullname})
      });
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const { token, userPk, onboarding } = this.props.match.params;
    if (token && userPk) {
      this.handleNewPasswordSent(token, userPk, onboarding);
    } else {
      this.handlePasswordRequest();
    }
  }

  render() {
    const { token, userPk } = this.props.match.params;
    const { emailErrorText, passwordErrorText, passwordChangeData, requestedReset, fullname } = this.props;
    const { password, passwordTwo } = this.state;
    const isOnboarding = this.props.match.params.onboarding === 'onboarding';
    if (passwordChangeData.status !== 201) {
      return (
        <div styleName="not-authenticated-container">
          { (token && userPk)
            ? <PasswordCreate
              formChange={this.handleFormChange}
              formSubmit={this.handleFormSubmit}
              isOnboarding={isOnboarding}
              passwordResetSuccessStatus={passwordChangeData.status}
              passwordErrorText={passwordErrorText}
              fullname={fullname}
              password={password}
              passwordTwo={passwordTwo} />
            : <ResetRequest
              emailErrorText={emailErrorText}
              formChange={this.handleFormChange}
              formSubmit={this.handleFormSubmit}
              requestedResetStatus={requestedReset.status} />
          }
          <div styleName="auth-section">
            <div styleName="link-text">
              Already have an account?<br /><Link style={loginStyles.links} to={'/login'}>Login</Link>
              <br/>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div styleName="not-authenticated-container authentication-success-container">
        <Success
          isOnboarding={isOnboarding} />
        <div styleName="auth-section">
          <div>
            <Link style={loginStyles.successLinks} to={'/login'}>Login Now</Link>
          </div>
        </div>
      </div>
    );
  }
}

PasswordRecovery.propTypes = {
  authSetUserField: PropTypes.func,
  email: PropTypes.string,
  emailErrorText: PropTypes.string,
  fullname: PropTypes.string,
  match: PropTypes.object,
  passwordChangeData: PropTypes.object,
  passwordErrorText: PropTypes.string,
  passwordSuccess: PropTypes.object,
  requestedReset: PropTypes.object,
  sendNewPasswords: PropTypes.func,
  sendPasswordChangeRequest: PropTypes.func
};

const mapStateToProps = state => {
  const {
    passwordErrorText,
    fullname,
    email,
    emailErrorText,
    requestedReset,
    passwordChangeData
  } = state.auth.user;
  return {
    email,
    emailErrorText,
    fullname,
    passwordErrorText,
    passwordChangeData,
    requestedReset
  };
};

export default connect(mapStateToProps, {
  authSetUserField,
  sendNewPasswords,
  sendPasswordChangeRequest
})(PasswordRecovery);

export { PasswordRecovery as PurePasswordRecovery };
