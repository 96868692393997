import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import {
  MenuItem,
  SelectField,
  TextField
} from 'material-ui';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { fetchDataDetail } from '@actions/data-detail-actions';
import { saveDataType } from '@actions/data-types-actions';
import { getGroupById } from '@actions/groups-actions';
import { pushApplicationMessage } from '@actions/messages-actions';
import { fetchCycles } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles, styleGuide } from '@constants/mui-theme';
import WorkflowIcon from '@icons/workflow-icon';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './start-cycle-dialog.scss';

const StartCycleDialog = () => {
  // state:
  const [name, setName] = useState('');
  const [workflow, setWorkflow] = useState('');
  const [errors, setErrors] = useState({});

  // dispatch:
  const dispatch = useDispatch();

  // selector:
  const dashboard = useSelector(state => state.dashboard);
  const { activeDialogs } = dashboard;
  const dialogParams = dashboard.dialogParams[dialog.START_CYCLE];
  const isDialogActive = activeDialogs[dialog.START_CYCLE];
  const { workflow: workflows } = useSelector(state => state.dataTypes);
  // Lodash's isEmpty flags numbers as non-empty, thus check if workflow is '' or > 0:
  const saveEnabled = !isEmpty(name) && (!isEmpty(workflow) || workflow > 0) && isEmpty(errors);

  const onClose = useCallback(
    () => {
      setName('');
      setWorkflow('');
      setErrors({});
      dispatch(closeDashboardDialog(dialog.START_CYCLE));
    },
    [dispatch, setErrors, setName, setWorkflow]
  );

  const reloadCycles = useCallback(
    cycle => {
      const { reloadEntity, reloadGroup, reloadGroupTray } = dialogParams;
      const { entity, group } = cycle;
      if (entity) {
        dispatch(fetchCycles('entity', entity.id));
        dispatch(pushApplicationMessage(`Cycle started on ${entity.type_name} ID ${entity.id}`));
      }
      if (group) {
        dispatch(fetchCycles('group', group.id));
        dispatch(pushApplicationMessage(`Cycle started on group ID ${group.id}`));
      }
      if (reloadGroup) {
        dispatch(getGroupById(group.id));
      }
      if (reloadGroupTray) {
        dispatch(getGroupById(group.id, true, true));
      }
      if (reloadEntity) {
        dispatch(fetchDataDetail(entity.type_name, entity.id));
      }
      onClose();
    },
    [onClose, dialogParams, dispatch]
  );

  const saveError = useCallback(
    error => {
      const { response: { data } } = error;
      setErrors({ ...data });
    },
    [setErrors]
  );

  const onSave = useCallback(
    () => {
      const { entityId, groupId } = dialogParams;
      const payload = { name, workflow };
      if (entityId) {
        payload.entity_id = entityId;
      }
      if (groupId) {
        payload.group_id = groupId;
      }
      dispatch(saveDataType('cycle', null, payload, reloadCycles, saveError));
    },
    [dialogParams, dispatch, name, reloadCycles, saveError, workflow]
  );

  const onNameChange = useCallback(
    (event, value) => {
      setName(value);
      setErrors({});
    },
    [setErrors, setName]
  );

  const onWorkflowChange = useCallback(
    (event, index, value) => {
      setWorkflow(value);
      setErrors({});
    },
    [setErrors, setWorkflow]
  );

  if (!isDialogActive) {
    return null;
  }

  const getWorkflowItems = () => Object.values(workflows).map(item => (
    <MenuItem key={item.id} leftIcon={<WorkflowIcon />} primaryText={item.name} value={item.id} />
  ));

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Start a workflow cycle
        </div>
      </DialogTitle>
      <DialogContent>
        <div style={{
          paddingBottom: '1rem'  // Fix for unwanted DialogContent scrollbars.
        }}>
          <TextField
            {...styleGuide.textField}
            floatingLabelText="Name*"
            fullWidth
            onChange={onNameChange}
            value={name}
          />
          {errors.name && <div styleName="error-field">{errors.name}</div>}
          <SelectField
            floatingLabelText="Workflow*"
            fullWidth
            onChange={onWorkflowChange}
            value={workflow}
            {...styleGuide.select}
          >
            {getWorkflowItems()}
          </SelectField>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          disabled={!saveEnabled}
          onClick={onSave}
          variant="contained"
        >
          START
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartCycleDialog;
