import React from 'react';
import { connect } from 'react-redux';
import { getDashboardBackgroundColor } from '@constants/config';
import { VIEW_TYPES } from '@constants/workflow';
import {
  reachingTableEnd
} from '@utils/data-table-utils';
import { findActivity, isOwnerView } from '@utils/workflow-utils';
import DotmapsLoader from '@shared/dotmaps-loader';
import { Tabs, Tab } from '@shared/tabs';
import {
  TaskBase,
  baseMapStateToProps,
  baseMapDispatchToProps
} from '../task-base';
import Header from './header';
import ActivityContent from './activities-tab/content';
import ActivitiesTab from './activities-tab';
import DetailsTab from './details-tab';
import './details.scss';

class Details extends TaskBase {
  state = { activeTab: 'details', scrollTop: 0 };

  componentDidMount() {
    super.componentDidMount();
    const { viewType } = this.props.params;
    // For the 'owner' view, always make the activities tab active, since we need to display one
    // of those activities:
    if (isOwnerView(viewType)) {
      this.setState({ activeTab: 'activities' });
    }
  }

  onTabChange = (value, event, tab) => {  // eslint-disable-line no-unused-vars
    this.setState({ activeTab: value });
  };

  getTaskTypeId = () => {
    const { data } = this.props;
    if (data) {
      const { task_type } = data;
      return task_type;  // The task type id from the task object.
    }
    return null;
  };

  onScroll = event => {
    const { activeTab } = this.state;
    if (activeTab === 'activities' && reachingTableEnd(event)) {
      const { target: { scrollTop } } = event;
      this.setState({ scrollTop });
    }
  };

  render() {
    const { taskActivityId, taskId, viewType } = this.props.params;
    const { cycle, cycleLoading, data, error, loading } = this.props;
    if (cycleLoading) {
      return null;
    }
    if (!data && loading) {
      return (
        <div styleName="task-details-container">
          <DotmapsLoader display color={getDashboardBackgroundColor()} />
        </div>
      );
    }
    if (!taskId || !data) {
      return null;
    }
    const taskType = this.getTaskType();
    if (!taskType) {
      return null;
    }
    const { activeTab, scrollTop } = this.state;
    const sections = this.getSections();

    const commonProps = {
      cycle,
      data,
      // updateDataField() is needed for some interactive features (like selecting
      // locations on the map).
      onChange: this.props.updateDataField,
      taskType
    };

    // If task activity is specified, and the view is the assignee or agency one, render only the details for that task activity:
    if (taskActivityId && (viewType === VIEW_TYPES.agency || viewType === VIEW_TYPES.assignee)) {
      const activity = findActivity(taskActivityId, data.activities);
      return (
        <div styleName="task-details-container task-details-activity-container">
          <Header taskType={taskType} />
          {loading && <DotmapsLoader display color={getDashboardBackgroundColor()} />}
          <div styleName="task-details-form">
            <div styleName="task-details-form-column">
              <DetailsTab {...commonProps} sections={sections} taskId={taskId} viewType={viewType} />
            </div>
            <div styleName="task-details-form-column">
              <ActivityContent activity={activity} data={data} error={error} scrollTop={scrollTop} viewType={viewType} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div styleName="task-details-container">
        <Header showDelete taskType={taskType} />
        {loading && <DotmapsLoader display color={getDashboardBackgroundColor()} />}
        <Tabs tabItemWidth="100px" onChange={this.onTabChange} onScroll={this.onScroll} value={activeTab}>
          <Tab label="DETAILS" value="details">
            <DetailsTab {...commonProps} sections={sections} taskId={taskId} viewType={viewType} />
          </Tab>
          <Tab label="ACTIVITIES" value="activities">
            <ActivitiesTab {...commonProps} viewType={viewType} taskActivityId={taskActivityId} error={error} scrollTop={scrollTop} />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = state => ({ ...baseMapStateToProps(state) });

const mapDispatchToProps = { ...baseMapDispatchToProps };

export default connect(mapStateToProps, mapDispatchToProps)(Details);
