import React from 'react';
import PropTypes from 'prop-types';
import { dotmapsBlack80 } from '@constants/colors';

const DownloadIcon = ({ color, size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 96 960 960" width={size} fill={color}>
    <path d={`
      M220 896q-24 0-42-18t-18-42V693h60v143h520V693h60v143q0 
      24-18 42t-42 18H220Zm260-153L287 550l43-43 120 
      120V256h60v371l120-120 43 43-193 193Z
    `} />
  </svg>
);

DownloadIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number
};

DownloadIcon.defaultProps = {
  color: dotmapsBlack80,
  size: 24
};

export default DownloadIcon;
