// This component renders a material-ui badge with an icon
// at the bottom right.
import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'material-ui/Badge';

const BadgeAvatar = ({ children, ...other }) => <Badge {...other}>{children}</Badge>;

BadgeAvatar.propTypes = {
  badgeContent: PropTypes.object,
  children: PropTypes.node
};

export default BadgeAvatar;
