/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import ListDetail from '@components/data-detail/list-detail';
import { fetchDataDetail } from '@actions/data-detail-actions';
import { openDialog } from '@actions/dialogs-actions';
import OverlapTopBar from '@components/data-detail/top-bar/overlap';
import {
  optimizeOverlapConflictsForListSelector,
  optimizeOverlapOpportunitiesForListSelector,
  optimizeEntityForListSelector
} from '@selectors/data-detail-selector';
import { nonOverlapingEntitiesGroups } from '@selectors/groups-selector';
import { withRouter } from '@utils/router-utils';

class OverlapDetail extends ListDetail {
  getDataType = () => 'overlap';

  getDataTypeDisplayName = () => 'overlap';

  getMainEntityTypeName = () => 'entity';

  getTopBar = () => {
    const { data } = this.props;
    if (data.id) {
      return <OverlapTopBar {...this.getTopBarProps()} />;
    }
    return null;
  };
}

export const mapStateToProps = state => {
  // Only return data if the current data type is 'overlap'.
  // During LOCATION_CHANGE actions we might be receiving
  // temporarily data for other data type, this avoids that:
  if (state.dataDetail.dataType !== 'overlap') {
    return {};
  }
  const { error, loading, options } = state.dataDetail;
  const entity = optimizeEntityForListSelector(state)[0];

  const data = {
    ...state.dataDetail.data,
    conflicts: optimizeOverlapConflictsForListSelector(state),
    opportunities: optimizeOverlapOpportunitiesForListSelector(state),
    entity
  };
  const { open } = state.dialogs;
  const { trays } = state.map;
  return {
    data,
    dialogOpen: open,
    entityType: data.entity ? data.entity.entity_type : null,
    error,
    groups: nonOverlapingEntitiesGroups(state, { entity }),
    loading,
    options,
    trays
  };
};

const mapDispatchToProps = {
  fetchDataDetail,
  openDialog,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OverlapDetail));
