import React from 'react';

const AgencyIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path
        id="agency-path-1"
        d={`
            M6 9.75V9H.758L.75 12c0 .832.667 1.5 1.5 1.5h10.5c.832 0 1.5-.668
            1.5-1.5V9H9v.75H6zM13.5 3h-3.008V1.5L8.992 0h-3l-1.5 1.5V3H1.5C.675
            3 0 3.675 0 4.5v2.25c0 .832.667 1.5 1.5 1.5H6v-1.5h3v1.5h4.5c.825 0
            1.5-.675 1.5-1.5V4.5c0-.825-.675-1.5-1.5-1.5zM9 3H6V1.5h3V3z
        `}
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(1.5 2.25)">
      <mask id="agency-path-2" fill="#fff">
        <use xlinkHref="#agency-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#agency-path-2)">
        <path d="M-1.5-2.25h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default AgencyIcon;
