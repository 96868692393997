import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { ListItem } from 'material-ui/List';
import Divider from 'material-ui/Divider';
import ViewListIcon from 'material-ui/svg-icons/action/view-list';
import { openEntityTray } from '@actions/map-actions';
import { noRecordsIconStyles, detailStyles } from '@constants/mui-theme';
import {
  optimizeEntitiesForListSelector
} from '@selectors/groups-selector';
import AddToGroupDialog from '@shared/dialogs/add-to-group-dialog';
import OverlapStatusDialog from '@shared/dialogs/overlap-status-dialog';
import OverlapRevokeDialog from '@shared/dialogs/overlap-revoke-dialog';
import EntityListItem from '@shared/list-item/entity-list-item';
import { findGroupType } from '@utils/group-utils';
import { pluralize } from '@utils/shared-utils';
import { optimizeSegmentForMap } from '@utils/map-utils';
import ConflictList from './conflict-list';
import MoreButton from './more-button';
import './entity-list.scss';

class EntityList extends Component {
  state = { openItem: null };

  openTray = (type, id, segments = [], name = null, icon = null) => {
    const mapSegments = segments.map(optimizeSegmentForMap);
    const trayProps = {
      id,
      position: mapSegments[0].center,
      source: 'library',
      name,
      icon
    };
    this.props.openEntityTray({
      ...trayProps,
      type,
      fitEntityBounds: true
    });
  };

  handleHeaderClick = listItem => {
    const { objectid } = listItem.props;
    if (objectid === this.state.openItem) {
      this.setState({openItem: null});
    } else {
      this.setState({openItem: objectid});
    }
  };

  renderMoreButton = (dataType, entity) => {
    const { groupId, groupType } = this.props;
    return (
      <MoreButton
        dataType={dataType}
        groupType={groupType}
        entity={entity}
        source={`/group/${groupType}/${groupId}`}
        groupId={groupId}
      />
    );
  };

  renderEmpty = () => {
    const { entities, status } = this.props;
    if (entities.length === 0) {
      return (
        <div styleName="no-records">
          <div styleName="icon">
            <ViewListIcon {...noRecordsIconStyles} />
          </div>
          <div styleName="title">No records to show</div>
          {status === 1 && (
            <div styleName="text">
              Click <span styleName="plus-sign">+</span> at the top to add records
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  render() {
    const { groupTypeId, entities } = this.props;
    const selectedGroupType = findGroupType(groupTypeId);
    return (
      <div styleName="group-entity-list">
        {this.renderEmpty()}
        {entities.map(entity => {
          const conflicts = entity.conflicts ? entity.conflicts.filter(
            entityId => R.pluck('id')(entities).includes(entityId)
          ) : [];
          const isOpen = this.state.openItem === entity.id;
          return (
            <div key={`container-${entity.id}`}>
              <EntityListItem
                {...entity}
                dataType={entity.type_name}
                key={entity.id}
                onClick={this.openTray}
                moreButton={this.renderMoreButton(entity.type_name, entity)}
              />
              {selectedGroupType.generates_overlaps && conflicts.length > 0 && (
                <div
                  style={{
                    ...(isOpen && detailStyles.groupOpenListItem),
                    ...(!isOpen && detailStyles.groupClosedListItem)
                  }}
                >
                  <ListItem
                    autoGenerateNestedIndicator
                    primaryTogglesNestedList
                    onNestedListToggle={this.handleHeaderClick}
                    open={isOpen}
                    key={`header-${entity.id}`}
                    objectid={entity.id}
                    nestedItems={[
                      <ConflictList
                        key={`list-${entity.id}`}
                        entity={entity}
                        conflictList={conflicts}
                        onClick={this.openTray}
                      />
                    ]}
                    innerDivStyle={detailStyles.listItem}
                    nestedListStyle={detailStyles.listItem}
                  >
                    <div styleName="sublist-header">
                      <div>{conflicts.length} {pluralize(conflicts.length, 'conflict')} in group</div>
                    </div>
                    {!isOpen && <Divider />}
                  </ListItem>
                </div>
              )}
            </div>
          );
        })}
        <OverlapRevokeDialog />
        <OverlapStatusDialog opportunities={[]} />
        <AddToGroupDialog />
      </div>
    );
  }
}

EntityList.propTypes = {
  entities: PropTypes.array,
  groupId: PropTypes.number,
  groupType: PropTypes.string,
  groupTypeId: PropTypes.number,
  openEntityTray: PropTypes.func,
  overlaps: PropTypes.object,
  status: PropTypes.number
};

const mapStateToProps = state => ({
  entities: optimizeEntitiesForListSelector(state),
  status: state.groups.edit.group.status,
  groupId: state.groups.edit.group.id,
  groupType: state.groups.edit.group.type_name,
  groupTypeId: state.groups.edit.group.type,
  overlaps: state.groups.edit.overlaps
});

export default connect(mapStateToProps, {
  openEntityTray
})(EntityList);
