/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import NavigationChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import { verifyPermissions } from '@utils/permission-utils';
import { renderEmailSettingsValue } from '@utils/settings-utils';
import './email.scss';

const MenuItem = ({ emailSettings, item }) => {
  const dispatch = useDispatch();

  if (!verifyPermissions(item.permissions)) {
    return null;
  }

  const onMenuClick = type => dispatch(push(`/settings/email/${type}`));

  const row1 = renderEmailSettingsValue(emailSettings, item.type, item.row1);
  const row2 = renderEmailSettingsValue(emailSettings, item.type, item.row2);

  return (
    <div styleName="menu-item-container">
      <div
        onClick={() => onMenuClick(item.type)}
        role="presentation"
        styleName="menu-item-wrapper"
      >
        <div styleName="menu-item">
          <div styleName="title">
            {item.label}
          </div>
          <div styleName="row">
            {row1}
          </div>
          {row1 !== 'Off' && (
            <div styleName="row">
              {row2}
            </div>
          )}
        </div>
        <div styleName="chevron">
          <NavigationChevronRight />
        </div>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  emailSettings: PropTypes.object,
  item: PropTypes.object
};

export default MenuItem;
