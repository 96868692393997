import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from 'material-ui/Dialog';
import {
  closeDashboardDialog,
  revokeConflict as dashboardRevoke
} from '@actions/dashboard-actions';
import {
  revokeConflict as groupsRevoke
} from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import { OVERLAP_STATUS } from '@utils/data-detail/conflicts';
import './overlap-revoke-dialog.scss';

class RevokeDialog extends Component {
  handleCancel = () => this.props.closeDashboardDialog(dialog.OVERLAP_REVOKE);

  handleSubmit = () => {
    const { revokeParams } = this.props;
    const payload = {
      ...revokeParams
    };
    // Dispatch action to revoke the conflict:
    if (location.pathname.startsWith('/group/')) {
      this.props.groupsRevoke(payload);
    } else {
      this.props.dashboardRevoke(payload);
    }
    this.props.closeDashboardDialog(dialog.OVERLAP_REVOKE);
  };

  getActions = () => ([
    <Button key="cancel" onClick={this.handleCancel}>CANCEL</Button>,
    <Button
      color="primary"
      key="revoke"
      onClick={this.handleSubmit}
      style={{ marginLeft: '0.5rem' }}
      variant="contained"
    >
      REVOKE
    </Button>
  ]);

  getStatusName = () => {
    const { dialogParams: { status } } = this.props;
    const { overlap_status: overlapStatus } = status;
    if (overlapStatus === OVERLAP_STATUS.cleared) {
      // Cleared overlaps means it's resolved:
      return 'Resolved';
    }
    if (overlapStatus === OVERLAP_STATUS.pending) {
      return 'Pending';
    }
    // It's not possible to have other status on
    // the revoke dialog:
    return '';
  };

  render() {
    const { activeDialogs } = this.props;
    if (!activeDialogs[dialog.OVERLAP_REVOKE]) {
      return null;
    }
    return (
      <Dialog
        actions={this.getActions()}
        contentStyle={dialogStyles.mediumDialog}
        open={activeDialogs[dialog.OVERLAP_REVOKE]}
        title="Revoke resolution?"
        titleStyle={dialogStyles.actionTitleStyle}
        style={{ zIndex: 5000 }}
      >
        <div styleName="overlap-revoke-dialog-container">
          The conflict status will change
          from <span styleName="standout-text">{this.getStatusName()}</span> to <span styleName="standout-text">Open</span>.
          Are you sure you want to revoke the resolution?
        </div>
      </Dialog>
    );
  }
}

RevokeDialog.propTypes = {
  activeDialogs: PropTypes.object,
  closeDashboardDialog: PropTypes.func,
  dashboardRevoke: PropTypes.func,
  dialogParams: PropTypes.object,
  groupsRevoke: PropTypes.func,
  revokeParams: PropTypes.object
};

const mapStateToProps = state => {
  const { activeDialogs, dialogParams } = state.dashboard;
  return {
    activeDialogs,
    // Revoke parameters are in the status overlap:
    revokeParams: dialogParams[dialog.OVERLAP_STATUS],
    dialogParams: dialogParams[dialog.OVERLAP_REVOKE]
  };
};

const mapDispatchToProps = {
  closeDashboardDialog,
  dashboardRevoke,
  groupsRevoke
};

export default connect(mapStateToProps, mapDispatchToProps)(RevokeDialog);
