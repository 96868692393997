/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { isString } from 'lodash';
import { LocalForm, actions } from 'react-redux-form';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { changeDashboardData, changeDashboardDataValid } from '@actions/dashboard-actions';
import { getDetailsComponent } from '@constants/component-configs';
import { getDetailsConfig } from '@constants/config';
import DetailsBlock from '@shared/details-block';
import DetailsDataTypeSearch from '@shared/details-datatype-search';
import { flattenErrors, buildErrorsFunctions } from '@utils/dashboard-details-utils';
import { allowedFor } from '@utils/permission-utils';
import Input from './input';
import './sections.scss';

class Sections extends Component {
  componentWillUnmount() {
    const { dataType } = this.props;
    this.props.changeDashboardData(dataType, null);
  }

  attachDispatch = dispatch => {
    this.formDispatch = dispatch;
  };

  // Allows updating LocalForm's internal state externally from other components.
  updateExternal = (field, value) => {
    this.formDispatch(actions.change(field, value));
  };

  updateValid = ({$form: {valid}}) => {
    const { dataType } = this.props;
    this.props.changeDashboardDataValid(dataType, valid);
  };

  updateData = data => {
    const { dataType } = this.props;
    if (!R.equals(data, this.props.data)) {
      this.props.changeDashboardData(dataType, data);
    }
  };

  getLabel = (label, data) => {
    if (isString(label)) {
      return label;
    }
    const { field, template } = label;
    if (field && template && data && data[field]) {
      return eval('`' + template + '`');  // eslint-disable-line no-eval, prefer-template
    }
    return label.default;
  };

  render() {
    const { info, edit, dataType, data, apiError } = this.props;
    const detailsFields = getDetailsConfig()[dataType];
    if (!detailsFields) {
      return <div>Missing details fields config</div>;
    }
    const { sections } = detailsFields;

    // Convert api errors into functions that return errors as required by the react redux forms
    const allFields = sections.reduce((all, {fields}) => all.concat(fields), []);
    const errorFunctions = buildErrorsFunctions(allFields, flattenErrors(apiError));

    // Build a list of sections to display.
    // Only display the ones that don't have the 'onlyEdit' flag, or the ones that have it set to true
    // and we are under 'edit' mode.
    const displaySections = sections.filter(({onlyEdit}) => onlyEdit && edit || typeof onlyEdit === 'undefined');
    return (
      <LocalForm
        model={dataType}
        initialState={data}
        getDispatch={dispatch => this.attachDispatch(dispatch)}
        onChange={this.updateData}
        onUpdate={this.updateValid}
        errors={errorFunctions}
      >
        {displaySections.map(({ components, fields, title, subTitle, key: sectionKey }) => (
          <Grid fluid key={sectionKey || title.key}>
            <Row>
              <Col>
                <span styleName={`section-title ${title.styleName ? title.styleName : ''}`}>{title.label}</span>
                { subTitle &&
                  <span styleName={`section-subtitle ${subTitle.styleName ? subTitle.styleName : ''}`}>{this.getLabel(subTitle.label, data)}</span>
                }
              </Col>
            </Row>
            {components &&
              <Row>
                {components.map(({component, key: componentKey, styleName, width}) => {
                  const ComponentClass = getDetailsComponent(component.class);
                  return (
                    <Col styleName={`margin-bottom ${styleName ? styleName : ''}`}
                      key={componentKey} sm={width || 4}
                    >
                      <ComponentClass {...component.props} onUpdate={this.updateExternal} />
                    </Col>
                  );
                })}
              </Row>
            }
            {fields &&
              <Row styleName="dashboard-details-section">
                {fields.map(({
                  key,
                  defaultValue,
                  hidden,
                  label,
                  width,
                  constant,
                  permissions,
                  required,
                  select,
                  type,
                  date
                }) => {
                  if (  // Don't display:
                    hidden ||  // hidden fields,
                        (edit && constant) // and constant (read-only) fields on edit mode.
                  ) {
                    return null;
                  }
                  const parsedLabel = this.getLabel(label, data);
                  return (
                    <Col styleName="margin-bottom" key={key} sm={width || 4}>
                      {edit && type !== 'search' &&
                        <Input
                          disabled={constant || (permissions && !allowedFor(permissions))}
                          label={parsedLabel}
                          model={key}
                          required={required}
                          select={select}
                          type={type}
                        />
                      }
                      {!edit && type !== 'search' &&
                        <DetailsBlock
                          label={parsedLabel}
                          info={info(key, select, defaultValue)}
                          date={date}
                          type={type}
                          edit
                        />
                      }
                      {type === 'search' && 
                        <DetailsDataTypeSearch 
                          disabled={constant || (permissions && !allowedFor(permissions))}
                          label={parsedLabel}
                          model={key}
                          type={type}
                          edit={edit} 
                        />
                      }
                    </Col>
                  );
                }
                )}
              </Row>
            }
          </Grid>
        ))}
      </LocalForm>
    );
  }
}

Sections.propTypes = {
  apiError: PropTypes.object,
  changeDashboardData: PropTypes.func,
  changeDashboardDataValid: PropTypes.func,
  data: PropTypes.object,
  dataType: PropTypes.string,
  edit: PropTypes.bool,
  info: PropTypes.func,
  saveRequested: PropTypes.bool
};

export default connect(null, { changeDashboardData, changeDashboardDataValid })(Sections);

export { Sections as PureSections };
