import React from 'react';
import PropTypes from 'prop-types';
import FlexSeparator from '@shared/helpers/flex-separator';
import ViewActions from './view-actions';
import EditActions from './edit-actions';
import './details-top-bar.scss';

const DetailsTopBar = props => (
  <div styleName="details-top-bar-container">
    <FlexSeparator />
    {props.edit ? <EditActions {...props} /> : <ViewActions {...props} />}
  </div>
);

DetailsTopBar.propTypes = {
  dataId: PropTypes.string,
  dataType: PropTypes.string,
  edit: PropTypes.bool,
  location: PropTypes.object
};

export default DetailsTopBar;
