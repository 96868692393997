import { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { getAttachments } from '@actions/attachment-actions';
import {
  fetchDataDetail,
  updateDataField
} from '@actions/data-detail-actions';
import { getWorkflowCustomFieldSections } from '@constants/config';

export class TaskBase extends Component {
  // Use old style method declaration, to be able to use super() from subclasses:
  componentDidMount() {
    const { taskActivityId, taskId } = this.props.params;
    if (taskId) {
      this.loadTask(taskId);
    }
    if (taskActivityId) {
      this.loadActivityAttachments(taskActivityId);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { error } = nextProps;
    const { taskActivityId, taskId } = nextProps.params;
    if (taskId && isEmpty(error) && this.props.params.taskId !== taskId) {
      this.loadTask(taskId);
    }

    if (taskActivityId && this.props.params.taskActivityId !== taskActivityId) {
      this.loadActivityAttachments(taskActivityId);
    }
  }

  loadTask = taskId => this.props.fetchDataDetail('task', taskId);

  loadActivityAttachments = taskActivityId => this.props.getAttachments('task_activity', taskActivityId);

  getTaskTypeId = () => null;  // Implement in subclass

  getTaskType = () => {
    const taskTypeId = this.getTaskTypeId();
    const { task_type } = this.props;
    return task_type[taskTypeId];
  };

  getSections = () => getWorkflowCustomFieldSections();
}

TaskBase.propTypes = {
  cycle: PropTypes.object,
  cycleLoading: PropTypes.bool,
  data: PropTypes.object,
  error: PropTypes.object,
  fetchDataDetail: PropTypes.func,
  getAttachments: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  params: PropTypes.object,
  task_type: PropTypes.object,
  updateDataField: PropTypes.func
};

export const baseMapStateToProps = state => {
  const { cycle, loading: cycleLoading } = state.workflow;
  const { data, error, loading } = state.dataDetail;
  const { task_type } = state.dataTypes;
  return { cycle, cycleLoading, data, error, loading, task_type };
};

export const baseMapDispatchToProps = {
  fetchDataDetail,
  getAttachments,
  updateDataField
};
