import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import HorizontalDrawerContent from './horizontal-drawer-content';

const HorizontalDrawer = ({ children, drawerOpen }) => (
  <div>
    <HorizontalDrawerContent open={drawerOpen}>
      {children}
    </HorizontalDrawerContent>
  </div>
);

HorizontalDrawer.propTypes = {
  /**
   * The components to render in the HorizontalDrawer section.
   */
  children: PropTypes.element,
  /**
   * Controls whether the `HorizontalDrawer` is expanded or collapsed.
   */
  drawerOpen: PropTypes.bool,
  /**
   * Controls whether the `HorizontalDrawer` is expanded or collapsed.
   */
  open: PropTypes.bool
};


HorizontalDrawer.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

const mapStateToProps = state => {
  const { drawerOpen } = state.map;
  return { drawerOpen };
};

export default connect(mapStateToProps, {})(HorizontalDrawer);

export { HorizontalDrawer as PureHorizontalDrawer };
