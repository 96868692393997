import {
  BATCH_UPLOAD_SUCCESS,
  BATCH_UPLOAD_FAILURE
} from '@constants/action-types';
import axios from 'axios';
import { getAPIRequestUrl } from '@constants/endpoints';

const uploadSuccess = payload => ({
  type: BATCH_UPLOAD_SUCCESS,
  payload
});

const uploadError = error => ({
  type: BATCH_UPLOAD_FAILURE,
  payload: error
});

export const uploadBatch = (upload, onProgress, onFail) => {
  const url = getAPIRequestUrl('batch');
  const formData = new FormData();
  formData.append('file', upload.file);
  formData.append('active', upload.active);
  formData.append('status', upload.status);
  if (upload.layerType) {
    formData.append('layerType', upload.layerType);
    formData.append('type', 2);
  } else
    if (upload.entityType) {
      formData.append('entityType', upload.entityType);
      formData.append('type', 3);
    }
  const config = { onUploadProgress: onProgress };
  const request = axios.post(url, formData, config);
  return dispatch =>
    request.then(
      data => dispatch(uploadSuccess(data)),
      error => {
        if (onFail) {
          onFail();
        }
        return dispatch(uploadError(error));
      }
    );
};
