/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactOverflowTooltip from 'react-overflow-tooltip';
import './tray-card.scss';

export default class TrayCard extends PureComponent {
  toggleActive = () => {
    const { selected, onDeselect, onSelect } = this.props;
    if (selected) {
      onDeselect();
    } else {
      onSelect();
    }
  };

  render() {
    const { title, subtitle, iconUrl, selected, children, onOpen } = this.props;
    return (
      <div styleName={`tray-card-container ${selected ? 'active' : ''}`}
        onClick={this.toggleActive} role="button" tabIndex={0}>
        {iconUrl &&
          <div styleName="icon">
            <img src={iconUrl} alt=""/>
          </div>
        }
        <div styleName="card">
          <ReactOverflowTooltip title={title}>
            <div styleName="title">{title}</div>
          </ReactOverflowTooltip>
          { subtitle &&
            <ReactOverflowTooltip title={subtitle}>
              <div styleName="subtitle">{subtitle}</div>
            </ReactOverflowTooltip>
          }
          <div styleName="contents">
            {children}
          </div>
          {selected && onOpen &&
            <div styleName="view-info" onClick={onOpen} role="presentation">VIEW INFO</div>
          }
        </div>
      </div>
    );
  }
}

TrayCard.propTypes = {
  children: PropTypes.node,
  iconUrl: PropTypes.string,
  onDeselect: PropTypes.func,
  onOpen: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
};
