import { getDashboardConfig } from '@constants/config';

export const getDataType = (params, location) => {
  let { dataType } = params || {};
  if (location && location.pathname) {
    if (location.pathname.startsWith('/list/data/overlaps/')) {
      dataType = 'overlap';
    }
    if (location.pathname.startsWith('/list/data/group/')) {
      dataType = 'group';
    }
    if (location.pathname.startsWith('/workflow/activity/')) {
      dataType = 'activity';
    }
    if (location.pathname.startsWith('/workflow/task/')) {
      dataType = 'task';
    }
  }
  return dataType;
};

export const getSubType = (dataType, props) => {
  const types = ['activity', 'group', 'overlap', 'task'];
  if (types.includes(dataType)) {
    return props.params.dataType;
  }
  return null;
};

export const isListingPage = location => {
  return location && location.pathname && location.pathname.includes('/data/');
};

const getBasePath = () => {
  const { pathname } = window.location;
  if (pathname !== 'blank') {  // 'blank' is used by the unit tests.
    const parts = pathname.split('/');
    if (parts.length > 0 && parts[1] !== '') {
      return parts[1];
    }
  }
  return 'list';
};

export const getPortalList = () => Object.keys(getDashboardConfig());

// Get the portal path based on 'window.location'.
//
// We first obtain the first path of the path (i.e. 'list' in '/list/data/project'),
// and return it if it's a portal name, else we try to resolve it to a portal name
// based on the dashboard configuration.
//
// For example, a user standing on '/list/data/project' is on the entities 'list' dashboard,
// but if it's on '/project/new' or '/cycle/1234', there's no portal name on the path,
// we need to look on the dashboard config and obtain the portal name based on the entity
// name (i.e. 'project' belongs to the 'list' entities portal and 'cycle' to the workflow one.
export const getPortalPath = () => {
  const dashboard = getDashboardConfig();

  // Get the first part of a path.
  const basePath = getBasePath();
  let path = basePath;

  const portals = Object.keys(dashboard);

  // If the first part is a portal name, we found it:
  if (portals.includes(path)) {
    return path;
  }

  // Else, resolve it from the dashboard configuration:
  portals.forEach(key => {
    if (dashboard[key].paths.includes(basePath)) {
      path = key;
    }
  });

  return path;
};
