/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng
} from 'react-places-autocomplete';
import { getConfig } from '@utils/config-utils';
import AutocompleteContent from './autocomplete-content';

const InputAutocomplete = ({
  initName,
  inputProps,
  onGeocodeCompleted,
  onGeocodeError
}) => {
  const onSelect = (address, placeId, suggestion) => {  // eslint-disable-line no-unused-vars
    if (placeId) {
      geocodeByPlaceId(placeId)
        .then(results => getLatLng(results[0]))
        .then(latLng => {
          onGeocodeCompleted(latLng);
          inputProps.onChange(address);
        })
        .catch(error => onGeocodeError(error));
    } else {
      geocodeByAddress(address)
        .then(results => {
          const result = results[0];
          const latLng = getLatLng(result);
          onGeocodeCompleted(latLng, result.formatted_address);
          inputProps.onChange(result.formatted_address);
        })
        .catch(error => onGeocodeError(error));
    }
  };

  const onError = (status, clearSuggestions) => {
    clearSuggestions();
    if (status === 'ZERO_RESULTS') {
      onGeocodeError('Enter a valid address or cross street');
    } else {
      onGeocodeError(status);
    }
  };

  const config = getConfig();
  const searchOptions = {
    location: {
      lat: () => config.mapCenter.lat,
      lng: () => config.mapCenter.lng
    },
    radius: 1000,
    types: ['address']
  };

  return (
    <PlacesAutocomplete
      googleCallbackName={initName}
      highlightFirstSuggestion
      onChange={inputProps.onChange}
      onError={onError}
      onSelect={onSelect}
      searchOptions={searchOptions}
      value={inputProps.value}
    >
      {({
        getInputProps,
        suggestions,
        getSuggestionItemProps,
        loading  // eslint-disable-line no-unused-vars
      }) => (
        <AutocompleteContent
          getInputProps={getInputProps}
          getSuggestionItemProps={getSuggestionItemProps}
          inputProps={inputProps}
          suggestions={suggestions}
        />
      )}
    </PlacesAutocomplete>
  );
};

InputAutocomplete.propTypes = {
  initName: PropTypes.string,
  inputProps: PropTypes.object.isRequired,
  onGeocodeCompleted: PropTypes.func,
  onGeocodeError: PropTypes.func
};

export default InputAutocomplete;
