import React from 'react';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Tab from '@shared/vertical-scroll-tabs/tab';
import './vertical-scroll-tabs.scss';

const Tabs = ({ dataType, tabs }) => (
  <Paper styleName="tabs-container" zDepth={0}>
    {tabs?.map(tab => <Tab key={tab.id} {...tab} dataType={dataType} />)}
  </Paper>
);

Tabs.propTypes = {
  dataType: PropTypes.string,
  tabs: PropTypes.array
};

export default Tabs;
