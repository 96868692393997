/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const SubmitPublicFormDialog = () => {
  const dispatch = useDispatch();

  const dashboard = useSelector(state => state.dashboard);
  const { activeDialogs } = dashboard;
  const dialogParams = dashboard.dialogParams[dialog.SUBMIT_PUBLIC_FORM];
  const isDialogActive = activeDialogs[dialog.SUBMIT_PUBLIC_FORM];

  if (!isDialogActive) {
    return null;
  }

  const onClose = () => {
    dispatch(closeDashboardDialog(dialog.SUBMIT_PUBLIC_FORM));
  };

  const onSubmit = () => {
    const { submitForm } = dialogParams;
    submitForm();
    onClose();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Submit
        </div>
      </DialogTitle>
      <DialogContent style={dialogStyles.mediumDialog}>
        Make sure you have the correct information provided. You cannot view or edit the information once it’s submitted.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="primary"
          onClick={onSubmit}
          variant="contained"
        >
          SUBMIT
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubmitPublicFormDialog;
