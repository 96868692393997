import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { tooltipStyles } from '@constants/mui-theme';
import XYPopover from '@shared/xy-popover';

class Tooltipable extends Component {
  state = {
    open: false  // Tooltip on/off.
  };

  getNameSpace = () => 'default';

  hoverOn = event => {
    event.stopPropagation();
    // Close open tooltips within this namespace
    // before rendering this one open.
    if (window.tooltip[this.getNameSpace()]) {
      window.tooltip[this.getNameSpace()].hoverOff();
    }
    window.tooltip[this.getNameSpace()] = this;
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
      x: event.clientX,  // eslint-disable-line id-length
      y: event.clientY,  // eslint-disable-line id-length
      ...this.getEventField(event)
    });
  };

  // Implement in subclass if needed, to add more
  // data to the state.
  getEventField = event => ({});  // eslint-disable-line no-unused-vars

  hoverOff = () => {
    window.tooltip[this.getNameSpace()] = null;
    this.setState({ open: false });
  };

  // We use isOpen() to be able to override in subclasses
  // for more complex open state logic.
  isTooltipOpen = () => this.state.open;

  // Turning off useLayerForClickAway is needed, else the layer in which
  // the popover is rendered will automatically trigger a mouseout event, hiding
  // the opened window as soon as it's rendered.
  renderTooltip = tooltip => (
    <XYPopover
      open={this.isTooltipOpen()}
      useLayerForClickAway={false}
      anchorEl={this.state.anchorEl}
      onRequestClose={this.hoverOff}
      x={this.state.x}
      y={this.state.y}
      style={{
        // 'pointerEvents' must be 'none' by default, else it will flicker
        // since mountOver and mouseOut will be contantly triggering as we move the mouse.
        // Putting it first it the list makes it overwritable, which might be needed by
        // tooltips that needs user inteaction (i.e. clicks), and won't close by
        // mouseOut.
        pointerEvents: 'none',
        ...this.props.tooltipStyle
      }}
    >
      {tooltip || this.props.children || <div/>}
    </XYPopover>
  );

  render() {
    const {hoverElement: HoverElement} = this.props;
    return (
      <span>
        {HoverElement &&
          <HoverElement
            onMouseEnter={this.hoverOn}
            onMouseLeave={this.hoverOff}
          />
        }
        {this.renderTooltip()}
      </span>
    );
  }
}

Tooltipable.propTypes = {
  children: PropTypes.node,
  hoverElement: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  pointerEvents: PropTypes.string,
  tooltipStyle: PropTypes.object
};

Tooltipable.defaultProps = {
  tooltipStyle: { ...tooltipStyles }
};

export default Tooltipable;
