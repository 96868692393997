/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';
import { getGroupTypesConfig } from '@constants/config';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import GroupTypeIcon from '@icons/group-type-icon';
import SelectField from '@shared/select-field';
import InputSearch from '@shared/input-search';
import {
  findGroupType,
  getGroupAutocompletePlaceholder,
  renderGroupListItem
} from '@utils/group-utils';
import { canEditGroups } from '@utils/permission-utils';
import './add-to-group.scss';

const CustomInputLabel = withStyles({
  root: {
    marginLeft: '0.5rem'
  }
})(InputLabel);

class AddToGroup extends Component {
  getGroupTypeItems = () => getGroupTypesConfig().map(groupType => {
    if (canEditGroups(groupType.name)) {
      return (
        <MenuItem key={groupType.id} value={groupType.id}>
          <div styleName="menu-item-icon">
            <GroupTypeIcon type={groupType.name} size="1.5rem" />
          </div>
          <div styleName="menu-item-label">
            {groupType.label} group
          </div>
        </MenuItem>
      );
    }
    return null;
  });

  handleGroupListChange = values => this.props.setSelectedGroups(values);

  handleGroupTypeChange = event => this.props.setGroupType(event.target.value);

  render() {
    const { existingGroups, groupType, groupsById, selectedGroups, showSearch } = this.props;
    const selectedGroupType = findGroupType(groupType);
    return (
      <FormControl style={{ width: '100%'}}>
        <CustomInputLabel id="group-type-label">Group type</CustomInputLabel>
        <SelectField
          labelId="group-type-label"
          id="group-type-select"
          onChange={this.handleGroupTypeChange}
          renderValue={value => `${findGroupType(value).label} group`}
          value={groupType}
        >
          {this.getGroupTypeItems()}
        </SelectField>
        {showSearch && (
          <InputSearch
            {...autocompleteSearchStyles.normal}
            dataTypePath={['groups', 'list']}
            fieldName="group_ids"
            filterFunc={item => item.type === selectedGroupType.id && item.status_name === 'Open'}
            onChange={(event, newValues) => {
              const values = newValues.map(value => value.value);
              this.handleGroupListChange(values);
            }}
            label={getGroupAutocompletePlaceholder(selectedGroups, selectedGroupType)}
            renderOption={(option, state) => { // eslint-disable-line no-unused-vars
              const group = groupsById[option.value];
              return renderGroupListItem(group, existingGroups);
            }}
            values={selectedGroups}
          />
        )}
      </FormControl>
    );
  }
}

AddToGroup.propTypes = {
  existingGroups: PropTypes.array,
  groupType: PropTypes.number,
  groupsById: PropTypes.object,
  selectedGroups: PropTypes.array,
  setGroupType: PropTypes.func,
  setSelectedGroups: PropTypes.func,
  showSearch: PropTypes.bool
};

AddToGroup.defaultProps = {
  showSearch: true
};

export default AddToGroup;
