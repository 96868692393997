import { BASE_API_URL } from '@constants/endpoints';

export const overlapUrl = id => `${BASE_API_URL}/overlap/${id}/`;

export const overlapLink = id => `/overlap/${id}`;

export const optimizeOverlapForTray = (id, overlap, position) => ({
  position,
  overlapId: id,
  overlap
});
