// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-tray-filter-entity-filter-overlaps-filter-___overlaps__overlap-row{padding-left:0.5em}
`, "",{"version":3,"sources":["webpack://./src/components/map/tray/filter/entity-filter/overlaps-filter/overlaps.scss"],"names":[],"mappings":"AAGA,uFACE,kBAAmB","sourcesContent":["@import '../../../../../../styles/vars';\n@import '../../../../../../styles/mixins';\n\n.overlap-row {\n  padding-left: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlap-row": `src-components-map-tray-filter-entity-filter-overlaps-filter-___overlaps__overlap-row`,
	"overlapRow": `src-components-map-tray-filter-entity-filter-overlaps-filter-___overlaps__overlap-row`
};
export default ___CSS_LOADER_EXPORT___;
