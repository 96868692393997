/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
import React from 'react';
import FontIcon from 'material-ui/FontIcon';
import { dotmapsLightGrayText } from '@constants/colors';
import EntityTypeIcon from '@icons/entity-type-icon';
import {
  calculateLevelStyle,
  calculateLeftContainerLevelStyle,
  renderDays
} from '@utils/gantt-utils';
import Badge from '@shared/badge';
import BaseListItem from '@shared/list-item/base-list-item';
import '@shared/list-item/list-item.scss';

class ListItem extends BaseListItem {
  onClick = () => {
    const { id, entityType } = this.props;
    window.open(`/${entityType}/${id}?target=newWindow`, '_blank');
  };

  getHeader = () => (
    <div styleName="header">
      {this.getTypeIcon()}
      <div styleName="title">
        {this.props.title} <span>&bull; ID {this.props.id}</span>
      </div>
      {this.getActions()}
    </div>
  );

  getTitle = () => null;

  getTypeIcon = () => (
    <div styleName="type-icon">
      <EntityTypeIcon type={this.props.entityType} />
    </div>
  );

  getFooterContent = () => {
    const { on_street } = this.props;
    const streets = on_street ? on_street.split(',') : [];
    return (
      <span>
        {streets[0]}
        {streets.length > 1 && (
          <Badge
            color="gray"
            label={`+${streets.length - 1} more`}
            small
          />
        )}
      </span>
    );
  };

  // Overriding BaseListItem's render() method, to
  // be able to add a right panel with the duration value.
  render() {
    const { id, level, start_date, end_date } = this.props;
    if (!id) {
      return null;
    }
    const daysLabel = renderDays(start_date, end_date);
    const style = calculateLevelStyle(level);
    const leftContainerStyle = calculateLeftContainerLevelStyle(level);
    return (
      <div key={id}
        onClick={this.onClick}
        role="presentation"
        style={style}
        styleName="list-item gantt-list-item">
        <div styleName="left-container" style={leftContainerStyle}>
          {this.getHeader()}
          {this.getContent()}
          {this.getFooter()}
        </div>
        <div styleName="right-container">
          <div styleName="text">
            {daysLabel}
          </div>
          <div styleName="icon">
            <FontIcon className="material-icons" color={dotmapsLightGrayText}>open_in_new</FontIcon>
          </div>
        </div>
      </div>
    );
  }
}

export default ListItem;
