import React from 'react';
import moment from 'moment-timezone';
import MenuItem from 'material-ui/MenuItem';
import { isEmpty } from 'lodash';
import abbrevWeekdayRange from 'abbrev-weekday-range';
import { dotmapsBlack60 } from '@constants/colors';
import { segmentScheduleRecurrenceDropDownStyles } from '@constants/mui-theme';
import {
  createEmptyRecurrence,
  createEmptySchedule,
  createNewException
} from '@utils/data-detail-utils';
import { enDash } from '@utils/shared-utils';
import {
  groupRecurrences,
  isNextDay,
  isStartTimeSet,
  SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS,
  SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS_ID_ARRAY
} from '@utils/segment-schedule/common';
import { formatRecurrenceTimes } from '@utils/segment-schedule/copy-to-clipboard';
import { formatExceptionDate } from '@utils/segment-schedule/dates';
import './segment-schedule.scss';

// Adds an empty recurrence to the segment schedule.
export const addSegmentScheduleRecurrence = segment => {
  if (isEmpty(segment.schedules)) {
    segment.schedules = [createEmptySchedule()];
  }

  const schedule = segment.schedules[0];

  if (isEmpty(schedule.recurrences)) {
    schedule.recurrences = [];
  }
  schedule.recurrences.push(createEmptyRecurrence());

  return segment;
};

// Toggles a schedule exception.
export const toggleSegmentScheduleException = (segment, date) => {
  if (isEmpty(segment.schedules)) {
    segment.schedules = [createEmptySchedule()];
  }

  const schedule = segment.schedules[0];

  if (isEmpty(schedule.exceptions)) {
    schedule.exceptions = [];
  }

  const exceptionIndex = schedule.exceptions.findIndex(exception => moment(exception.exception_date).isSame(moment(date), 'day'));

  if (exceptionIndex === -1) {
    schedule.exceptions.push(createNewException(date));
  } else {
    schedule.exceptions.splice(exceptionIndex, 1);
  }

  return segment;
};

// Deletes a recurrence from a segment schedule:
export const deleteSegmentScheduleRecurrence = (segment, recurrence) => {
  const schedule = segment.schedules[0];
  schedule.recurrences = schedule.recurrences.filter(scheduleRecurrence => scheduleRecurrence.id !== recurrence.id);
  return segment;
};

// Finds the specified recurrence in the segment schedule:
const getSegmentScheduleRecurrence = (segment, recurrence) => {  // eslint-disable-line no-unused-vars
  const schedule = segment.schedules[0];
  if (schedule.recurrences) {
    return schedule.recurrences.find(scheduleRecurrence => scheduleRecurrence.id === recurrence.id);
  }
  return null;
};

// Helper method to modify the recurrence all-day field from the UI control:
export const segmentScheduleRecurrenceAllDayToggle = (segment, recurrence) => {
  // Find the recurrence we modified:
  const modifiedRecurrence = getSegmentScheduleRecurrence(segment, recurrence);

  if (modifiedRecurrence) {
    // Toggle the 'all-day' field:
    modifiedRecurrence.all_day = !modifiedRecurrence.all_day;

    // If we set all day, clear the start/end times.
    if (modifiedRecurrence.all_day) {
      modifiedRecurrence.start_time = null;
      modifiedRecurrence.end_time = null;
    }
  }

  return segment;
};

// Helper method to modify the recurrence weekdays from the UI control:
export const segmentScheduleRecurrenceDayChange = (segment, recurrence, values) => {
  // Find the recurrence we modified:
  const modifiedRecurrence = getSegmentScheduleRecurrence(segment, recurrence);

  if (modifiedRecurrence) {
    // Clear all weekdays:
    SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS_ID_ARRAY.forEach(value => {
      modifiedRecurrence[value] = false;
    });

    // Set the selected ones:
    values.forEach(value => {
      modifiedRecurrence[value] = true;
    });
  }

  return segment;
};

// Helper method to modify the recurrence time from the UI control:
export const segmentScheduleRecurrenceTimeChange = (segment, recurrence, key, value) => {
  // Find the recurrence:
  const modifiedRecurrence = getSegmentScheduleRecurrence(segment, recurrence);

  if (modifiedRecurrence) {
    // Modify the time value:
    modifiedRecurrence[key] = value;
  }

  return segment;
};

// Returns true if the specified weekday is set:
const isWeekdaySelected = (item, recurrence) => {
  // Recurrence contains something like this:
  //
  // {
  //   id: 'temp-54787154-6457-4c4b-9378-8bf1b7623864',
  //   start_time: null,
  //   end_time: null,
  //   monday: false,
  //   tuesday: false,
  //   wednesday: false,
  //   thursday: false,
  //   saturday: false,
  //   friday: false,
  //   sunday: false
  // }
  //
  // Thus, the code below will return the true/false value
  // of the weekday, to ell if the menu item is checked or not:
  return recurrence[item.id];
};

// Builds a single menu item for recurrence week field:
const buildRecurrenceWeekMenuItem = (item, recurrence) => (
  <MenuItem
    checked={isWeekdaySelected(item, recurrence)}
    key={item.id}
    insetChildren
    label={item.name}
    primaryText={item.name}
    value={item.id}
  />
);

// Build the DropDownMenu items for the recurrence weekday field:
export const buildRecurrenceWeekMenu = recurrence => {
  return SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.map(weekday => buildRecurrenceWeekMenuItem(weekday, recurrence));
};

// Builds the initial state for the recurrence week dropdown:
export const buildRecurrenceWeekMenuValues = recurrence => {
  return SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS_ID_ARRAY.filter(weekday => recurrence[weekday]);
};

// Material UI DropDownMenu's selectionRenderer for recurrence weekdays field:
export const recurrenceWeekSelectionRenderer = (values, menuItems) => {
  const items = menuItems.map(item => item.props.label);
  if (!isEmpty(items)) {
    // Convert to numbers:
    const weekNumbers = [];
    items.forEach(item => {
      SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.forEach(weekday => {
        if (weekday.name === item) {
          weekNumbers.push(weekday.number);
        }
      });
    });

    const options = {
      // Use custom format (since the library one starts on Sunday):
      format: SEGMENT_SCHEDULE_RECURRENCE_WEEKDAYS.map(weekday => weekday.name)
    };

    // Take care of handling consecutive days:
    const abbreviatedWeekdays = abbrevWeekdayRange(weekNumbers, options);
    // Use nDash instead of hyphen for consecutive days,
    // since the library that does the abbreviation uses hyphens.
    return abbreviatedWeekdays.replace(/-/, enDash);
  }
  return 'Select days';
};

// Returns true if the specified time is set:
const isTimeSelected = (key, item, recurrence) => {
  return recurrence[key] === item;
};

// Builds a single menu item for recurrence time fields:
const buildRecurrenceTimeMenuItem = (key, item, recurrence) => (
  <MenuItem
    checked={isTimeSelected(key, item, recurrence)}
    key={item.label}
    label={item.label}
    primaryText={item.label}
    value={item.value}
    style={item.style}
  />
);

// Format the hours for the recurrence time dropdown.
// Hours are numbers from 0 to 12, and we must render
// it as a 24 hours clock value (00 to 23).
const formatHour = (hour, suffix) => {
  let formattedHour = hour;
  if (suffix === 'AM' && hour === 12) {
    formattedHour = 0;
  }
  if (suffix === 'PM' && hour !== 12) {
    formattedHour += 12;
  }
  if (formattedHour < 10) {
    return `0${formattedHour}`;
  }
  return formattedHour;
};

// Returns 24 numbers representing the 24 hours clock starting from 0 (from 0 to 23).
//
// If the 'start' parameter is specified, we shift the hours from that start, for
// example if start is 17 (5 pm), the returned list is [17, 18, 19, ..., 16].
const buildHoursList = start => {
  const hours = [...Array(24).keys()];

  // Shift hours by the start time:
  return hours.map(hour => {
    if (!isStartTimeSet(start)) {
      return hour;  // No start time, return 0 to 23
    }
    // Start from start time:
    const newStartHour = start + hour;
    if (newStartHour > 23) {
      // When we pass 12 pm, restart:
      return newStartHour - 24;
    }
    return newStartHour;
  });
};

// Convert a 24 hour click to a 12 one:
const hourTo12 = hour => {
  if (hour > 12) {
    return hour - 12;
  }
  return hour;
};

// Returns AM/PM based on the specified hour:
const buildHourSuffix = hour => hour > 11 ? 'PM' : 'AM';

// Returns a list of hours in the 24 hour click, with the 12 hour clock hour and
// the AM/PM suffix.
const build24HoursList = start => {
  const hours = buildHoursList(start);
  return hours.map(hour => {
    const suffix = buildHourSuffix(hour);
    let displayHour = hourTo12(hour);
    if (displayHour === 0) {
      displayHour = 12;  // Convert 0 hour as 12 AM.
    }
    const nextDay = isNextDay(start - 1, hour) ? ' (Next day)' : '';
    return {
      label: `${displayHour} ${suffix}${nextDay}`,
      value: `${formatHour(displayHour, suffix)}:00:00`
    };
  });
};

// Builds the list of 24 hours items starting from the start hour time.
const buildTimeList = (key, startTime) => {
  // For 'start_time' build a 24 hours list with all dates starting from 12am.
  // Or if the start time is empty, we don't need to shift the list for end_time
  // so also return that list.
  if (key === 'start_time' || isEmpty(startTime)) {
    return build24HoursList();
  }
  // Else it's 'end_time' and 'start_time' is set, the list returned
  // must start after 'start_time'.
  const startHour = parseInt(startTime.split(':')[0], 10) + 1;
  return build24HoursList(startHour);
};

// Build the DropDownMenu items for the recurrence time fields:
export const buildRecurrenceTimeMenu = (key, recurrence) => {
  const times = buildTimeList(key, recurrence.start_time);
  const emptyItem = {
    label: null,
    style: { display: 'none' },  // Don't display the empty menu item.
    value: null
  };
  return [
    // Material-UI's DropDownMenu doesn't render a default value
    // on the dropdown if the value is empty, when the dropdown
    // is not a multiple one.
    //
    // It only calls the selectionRenderer() method, if there's
    // a child item which matches any value (and it's always
    // selectionRenderer() the one that renders a default value
    // when it's null).
    //
    // Thus the only way to make this work (without having to
    // implement our own Dropdown component) is to create a
    // dummy menu item with the 'null' value, thus triggering
    // the selectionRenderer() call.
    buildRecurrenceTimeMenuItem(key, emptyItem, recurrence),
    ...times.map(time => buildRecurrenceTimeMenuItem(key, time, recurrence))
  ];
};

// Material UI DropDownMenu's selectionRenderer for recurrence start/end time fields:
export const recurrenceTimeSelectionRenderer = (value, defaultIdEmpty) => {
  if (!isEmpty(value)) {
    return moment(value, 'HH:mm').format('h A');
  }
  return defaultIdEmpty;
};

// Render a single recurrence row:
const renderPopoverRecurrence = groupedRecurrence => (
  <div styleName="schedule-recurrence-list">
    <div styleName="day">
      {groupedRecurrence[0].day.name}
    </div>
    <div styleName="time">
      {groupedRecurrence.map((recurrence, index) => (
        <div styleName="row" key={`recurrence-${index}`}>
          {recurrence.recurrence.all_day ? 'All day' : formatRecurrenceTimes(recurrence.recurrence)}
        </div>
      ))}
    </div>
  </div>
);

// Render the recurrence list for the schedule string popover:
export const renderPopoverRecurrenceList = recurrences => {
  const recurrenceList = [];

  // Group the recurrences by day:
  const groupedRecurrences = groupRecurrences(recurrences);

  // Sort them to start by Monday:
  const weekNumbers = Object.keys(groupedRecurrences).sort();

  weekNumbers.forEach(weekNumber => {
    const groupedRecurrence = groupedRecurrences[weekNumber];
    recurrenceList.push(renderPopoverRecurrence(groupedRecurrence));
  });

  return recurrenceList;
};

// Render the exception list for the schedule string popover:
export const renderPopoverExceptionList = exceptions => {
  if (isEmpty(exceptions)) {
    return 'No exclusions';
  }
  return (
    <div>
      Excludes: {exceptions.map(exception => formatExceptionDate(exception.exception_date)).join(', ')}
    </div>
  );
};

const isRecurrenceFieldEmpty = (recurrence, field) => {
  if (field === 'day') {
    // To check if the day field is empty means to check for all weekdays being false:
    return isEmpty(buildRecurrenceWeekMenuValues(recurrence));
  }
  // This addresses start_time and end_time:
  return isEmpty(recurrence[field]) && !recurrence.all_day;
};

// Returns the styles that the recurrence day, and start/end time fields should use
// according to the empty/filled state of those fields:
export const getRecurrenceStyles = (recurrence, field) => {
  const styles = { ...segmentScheduleRecurrenceDropDownStyles };
  const isRecurrenceEmpty = isRecurrenceFieldEmpty(recurrence, field);
  if (isRecurrenceEmpty) {
    return {
      ...styles,
      labelStyle: {
        ...styles.labelStyle,
        color: dotmapsBlack60
      }
    };
  }
  return styles;
};
