import React, { memo, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import {
  ADD_TO_GROUP_TRAY,
  ENTITY_TRAY,
  LAYER_TRAY,
  GROUP_TRAY,
  OVERLAP_TRAY,
  RECENT_TRAY,
  SEARCH_TRAY,
  FILTER_TRAY,
  DETAILS_TRAY
} from '@constants/map-trays';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useWatchOverlapEntitiesCache } from './hooks';

import Paper from 'material-ui/Paper';
import AddToGroupTray from './overlap/add-to-group-tray';
import Recent from './recent';
import Search from './search';
import Entity from './entity';
import Layer from './layer';
import Group from './group';
import OverlapTray from './overlap/overlap-tray';
import Filter from './filter';
import Details from './details';

import { isMapPage } from '@utils/filter-utils';

import { getTrayTop, getTrayDepth } from '@selectors/map-selector';
import { openEntityTray, openFilterTray } from '@actions/map-actions';
import styles from './tray.scss'; // eslint-disable-line css-modules/no-unused-class

const Tray = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const tray = useSelector(getTrayTop);
  const depth = useSelector(getTrayDepth);
  const hasBack = depth > 1;
  const [open, type] = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return [
      searchParams.get('open'),
      searchParams.get('type')
    ];
  }, [location]);
  const TrayComponent = useMemo(() => {
    const trayType = tray.trayType || null;
    switch (trayType) {
    case ADD_TO_GROUP_TRAY:
      return AddToGroupTray;
    case ENTITY_TRAY:
      return Entity;
    case LAYER_TRAY:
      return Layer;
    case GROUP_TRAY:
      return Group;
    case OVERLAP_TRAY:
      return OverlapTray;
    case RECENT_TRAY:
      return Recent;
    case SEARCH_TRAY:
      return Search;
    case FILTER_TRAY:
      return Filter;
    case DETAILS_TRAY:
      return Details;
    default:
      return null;
    }
  }, [tray]);

  useEffect(() => {
    const isMapTray = isMapPage(location);
    if (isMapTray) {
      dispatch(openFilterTray());
    }
  }, [dispatch, location]);

  useEffect(() => {
    if (open && type) {
      const id = parseInt(open, 10);
      dispatch(openEntityTray({
        id,
        type
      }));
    }
  }, [dispatch, open, type]);

  useWatchOverlapEntitiesCache();

  if (!TrayComponent) {
    return null;
  }
  // There are problems with the slide out functionality:
  const slideOutStyle = '';
  const isMapTray = isMapPage(location);
  return (
    <Paper 
      className={classNames(
        styles.mapTrayContainer,
        slideOutStyle,
        {
          [styles.mapTrayContainerNoFilter]: !isMapTray,
          [styles.mapTrayContainerAnimationSlideIn]: hasBack
        }
      )}
      zDepth={3}
    >
      { TrayComponent && <TrayComponent /> }
    </Paper>
  );
};

export default memo(Tray);
