/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-key */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { includes } from 'lodash';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import {
  activeToOptions,
  CustomAutocomplete,
  defaultAutoCompleteProps,
  renderInput,
  renderTags
} from '@utils/autocomplete-utils';
import { shallowEqual } from '@utils/react-utils';
import './input-search.scss';

class InputSearch extends Component {
  shouldComponentUpdate(nextProps, nextState) {  // eslint-disable-line no-unused-vars
    return (
      !shallowEqual(nextProps.options, this.props.options) ||
      !shallowEqual(nextProps.errors, this.props.errors) ||
      !shallowEqual(nextProps.values, this.props.values)
    );
  }

  getId = () => `${this.props.fieldName}-input-search-textfield`;

  // Return the options that match the selected values:
  getDefaultValues = () => {
    const { options, values } = this.props;
    return options.filter(option => includes(values, option.value));
  };

  onChange = (event, newValues) => {
    const { limit, onChange } = this.props;

    // If a limit was set, only keep selected the new value:
    if (limit === 1) {
      const oldValues = this.getDefaultValues();
      // Remove from the list of selected options the ones that are already on the old list:
      const newValue = newValues.filter(val => !oldValues.includes(val));
      onChange(event, newValue);
    } else {
      onChange(event, newValues);
    }
  };

  render() {
    const {
      autocompleteContainerStyles,
      containerStyles,
      disabled,
      options,
      errors,
      label,
      placeHolder,
      renderOption,
      variant,
      textReadOnly
    } = this.props;
    if (!options) {
      return null;
    }
    const autoCompleteProps = { ...defaultAutoCompleteProps };
    if (renderOption) {
      autoCompleteProps.renderOption = renderOption;
    }
    const value = this.getDefaultValues();
    return (
      <div styleName="input-search-container" style={{...containerStyles}}>
        <div styleName="input-search-autocomplete-container" style={{...autocompleteContainerStyles}}>
          <CustomAutocomplete
            {...autoCompleteProps}
            disabled={disabled}
            id={this.getId()}
            options={options}
            defaultValue={value}
            value={value}
            onChange={this.onChange}
            onInputChange={this.props.onInputChange}
            renderTags={renderTags(this.props)}
            renderInput={renderInput(errors, label, placeHolder, variant, textReadOnly)}
          />
        </div>
      </div>
    );
  }
}

InputSearch.propTypes = {
  autocompleteContainerStyles: PropTypes.object,
  avatarProps: PropTypes.object,
  containerStyles: PropTypes.object,
  /**
   * Data type within the 'dataTypes' variable in the redux store.
   */
  dataType: PropTypes.string,
  /**
   * An object hierarchy path within the redux store from where to
   * obtain the list of options.
   */
  dataTypePath: PropTypes.array,
  disabled: PropTypes.bool,
  errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fieldName: PropTypes.string,
  filterFunc: PropTypes.func,
  label: PropTypes.string,
  limit: PropTypes.number,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  options: PropTypes.array,
  placeHolder: PropTypes.string,
  renderOption: PropTypes.func,
  textReadOnly: PropTypes.bool,
  toOptions: PropTypes.func,
  values: PropTypes.any,  // eslint-disable-line react/forbid-prop-types
  variant: PropTypes.string
};

InputSearch.defaultProps = {
  autocompleteContainerStyles: {
    maxWidth: '16rem'
  },
  variant: 'standard'
};

const mapStateToProps = (state, props) => {
  if (props.options) {
    return { options: props.options.map(option => ({ title: option.name, value: option.id }))};
  }
  const entries = props.dataTypePath ? R.path(props.dataTypePath, state) : state.dataTypes[props.dataType];
  const convertOptionsFunc = props.toOptions ? props.toOptions : activeToOptions;
  const options = convertOptionsFunc(entries, props.filterFunc);
  return { options };
};

export default connect(mapStateToProps, {})(InputSearch);
