import * as R from 'ramda';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  BATCH_UPLOAD_SUCCESS,
  CLEAR_DATATABLE_ROWS,
  DASHBOARD_DETAIL_DATA_DELETED,
  DATA_DETAIL_DELETE_SUCCESS,
  DATATABLE_AUTOCOMPLETE_SELECTED,
  DATATABLE_FETCH_ROWS_ERROR,
  DATATABLE_FETCH_ROWS_SUCCESS,
  DATATABLE_SET_SELECTED_ROW,
  DATATABLE_TOGGLE_LOADER,
  GROUPS_REMOVE_ENTITY_SUCCESS,
  RESET_DATATABLE_FILTERS,
  SET_DATATABLE_FILTERS
} from '@constants/action-types';
import {
  FETCH_CONFIG_SUCCESS
} from '@components/config/constants';
import { getColumns, getConfigFilters } from '@utils/data-table-utils';
import { buildAllPortalFilters } from '@utils/filter-utils';
import { rejectEmptyStrings } from '@utils/shared-utils';
import initialState from '../store/initial-state';

const dataTableReducer = (state = initialState.dataTables, action) => {
  switch (action.type) {
  case CLEAR_DATATABLE_ROWS: {
    const rows = { ...state.rows };
    const { dataType, subType } = action;
    if (subType && subType !== dataType) {
      if (rows[dataType]) {
        delete rows[dataType][subType];
      }
    } else {
      delete rows[dataType];
    }
    return { ...state, rows };
  }
  case DATATABLE_FETCH_ROWS_SUCCESS: {
    let rows = { ...state.rows };
    let columns = { ...state.columns };
    let count = { ...state.count };
    let next = { ...state.next };
    let loader = { ...state.loader };
    const { data } = action.payload;
    const { dataType, subType } = action;
    let path = null;
    if (subType && subType !== dataType) {
      path = [dataType, subType];
    } else {
      path = [dataType];
    }
    const newColumns = getColumns(null, dataType, subType);
    columns = R.assocPath(path, newColumns, columns);
    count = R.assocPath(path, data.count, count);
    next = R.assocPath(path, data.next, next);
    loader = R.assocPath(path, false, loader);
    rows = R.assocPath(path, [...data.results], rows);
    return { ...state, rows, columns, count, next, loader };
  }
  case DATATABLE_FETCH_ROWS_ERROR:
    return { ...state, errors: [...state.errors, action.payload] };
  case SET_DATATABLE_FILTERS: {
    const filters = { ...state.filters };
    if (action.subType && action.dataType !== action.subType) {
      const subTypeFilters = R.pathOr({}, [action.dataType, action.subType], filters);
      filters[action.dataType] = rejectEmptyStrings({
        ...filters[action.dataType],
        [action.subType]: rejectEmptyStrings({
          ...subTypeFilters,
          ...action.payload
        })
      });
    } else {
      filters[action.dataType] = rejectEmptyStrings({
        ...filters[action.dataType],
        ...action.payload
      });
    }
    return { ...state, filters };
  }
  // Set filters upon receiving the config data:
  case FETCH_CONFIG_SUCCESS: {
    const filters = { ...state.filters, ...buildAllPortalFilters() };
    return { ...state, filters };
  }
  case RESET_DATATABLE_FILTERS: {
    const filters = { ...state.filters };
    const configFilters = getConfigFilters(null, action.dataType, action.subType);
    if (action.subType && action.dataType !== action.subType) {
      if (!filters[action.dataType]) {
        filters[action.dataType] = {};
      }
      filters[action.dataType][action.subType] = configFilters;
    } else {
      filters[action.dataType] = configFilters;
    }
    return { ...state, filters };
  }
  case DATATABLE_AUTOCOMPLETE_SELECTED: {
    const autocomplete = { ...state.autocomplete };
    autocomplete[action.dataType] = action.payload;
    return { ...state, autocomplete };
  }
  case DATATABLE_TOGGLE_LOADER: {
    const { dataType, payload, subType } = action;
    const loader = { ...state.loader };
    if (subType && subType !== dataType) {
      return {
        ...state,
        loader: {
          ...loader,
          [dataType]: {
            ...(state.loader[dataType] || {}),
            [subType]: payload
          }
        }
      };
    }
    return {
      ...state,
      loader: {
        ...loader,
        [dataType]: payload
      }
    };
  }
  case GROUPS_REMOVE_ENTITY_SUCCESS: {
    const { entityId, groupId } = action.payload;
    if (state.rows.group) {
      const groupIndex = state.rows.group.findIndex(group => group.id === groupId);
      if (groupIndex >= 0) {
        const group = state.rows.group[groupIndex];
        const newEntities = group.entities.filter(id => id !== entityId);
        const newGroup = { ...group, entities: newEntities };
        return { ...state, rows: { ...state.rows, group: R.insert(
          groupIndex,
          newGroup,
          R.remove(groupIndex, 1, state.rows.group)
        )}};
      }
    }
    return state;
  }
  case BATCH_UPLOAD_SUCCESS: {
    if (state.rows.batch) {
      const batch = R.prepend(action.payload.data, state.rows.batch);
      return { ...state, rows: { ...state.rows, batch } };
    }
    return state;
  }
  case DASHBOARD_DETAIL_DATA_DELETED: {
    const { type, id } = action.payload;
    const newList = state.rows[type].filter(data => data.id !== id);
    return R.assocPath(['rows', type], newList, state);
  }
  case DATA_DETAIL_DELETE_SUCCESS: {
    const { dataType, dataId } = action;
    // There's no need to take care of subType here, since we don't delete overlaps
    // (and groups which also have subType doesn't use the data-detail delete methods):
    const list = state.rows[dataType];
    if (!list || !Array.isArray(list)) {
      return state;
    }
    const newList = list.filter(data => data.id !== dataId);
    return R.assocPath(['rows', dataType], newList, state);
  }
  case DATATABLE_SET_SELECTED_ROW: {
    const { id } = action;
    return { ...state, selectedRow: id };
  }
  case LOCATION_CHANGE: {
    if (action && action.payload) {
      const pathname = R.pathOr(null, ['payload', 'location', 'pathname'], action);
      if (pathname && pathname.startsWith('/map')) {
        // Skip data table reset if we are using it on the map, since all location
        // changes there are query string changes used to open an entity on the map.
        return state;
      }
    }
    // When we return from an action, clear the selected row:
    return { ...state, selectedRow: null };
  }
  default:
    return state;
  }
};

export default dataTableReducer;
