import React from 'react';
import { connect } from 'react-redux';
import { OVERLAP_DATA_TYPE } from '@components/config/constants';
import PropTypes from 'prop-types';
import MenuItem from 'material-ui/MenuItem';
import { downloadCSVExport } from '@actions/data-table-actions';
import { getConfig } from '@utils/config-utils';
import {
  BaseTopBar,
  mapDispatchToProps as baseMapDispatchToProps
} from '../base-top-bar';

class OverlapTopBar extends BaseTopBar {
  getName = () => `Overlaps of ${this.props.data.name}`;

  getDataType = () => 'overlap';

  getActivityLogDataType = () => 'conflict';

  getCustomBackLink = () => `/library/${OVERLAP_DATA_TYPE}/${this.props.entityType}`;

  getModel = () => 'overlap';

  // eslint-disable-next-line react/display-name
  getContactPCCOItem = () => (
    <MenuItem
      key="contact_pcco"
      primaryText="Contact PCCO"
      href={`mailto:${getConfig().CONTACT_PCCO_EMAIL}`}
    />
  );

  getActionMenuItems = () => [this.getActivityLogItem(), this.getContactPCCOItem()];

  onExportClick = () => {
    // When exporting overlaps as CSV, supply the entity type parameter,
    // so the backend knows the type of the main overlap, so it can
    // pull the entity type configuration from the database.
    const filters = {
      type: this.props.entityType
    };
    this.props.downloadCSVExport('overlap', this.props.entityType, filters, this.props.data.id);
  };
}

OverlapTopBar.propTypes = {
  downloadCSVExport: PropTypes.func
};

export const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  downloadCSVExport
};

export default connect(null, mapDispatchToProps)(OverlapTopBar);
