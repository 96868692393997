import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { capitalize } from 'lodash';
import Divider from 'material-ui/Divider';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import {
  getConfirmableRemoveEntityFromGroupWithMessage,
  openGroupAddEntityDialog
} from '@actions/groups-actions';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { dotmapsGray } from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import { canEditEntities, canEditGroups } from '@utils/permission-utils';

class MoreButton extends Component {
  goToEntityInfo = () => {
    const { entity, dataType, source } = this.props;
    this.props.push({ pathname: `/${dataType}/${entity.id}`, state: { source } });
  };

  goToActivityLog = () => {
    const { entity, source } = this.props;
    this.props.push({ pathname: `/logs/map/entity/${entity.id}`, state: { source } });
  };

  allowAddToGroup = () => canEditGroups(this.props.groupType);

  allowEdit = () => {
    const { dataType, entity } = this.props;
    return canEditEntities(entity.agency, dataType);
  };

  allowRemoveFromGroup = () => canEditGroups(this.props.groupType) && this.props.groupId;

  addToGroup = () => {
    const { dataType, entity } = this.props;
    this.props.openGroupAddEntityDialog(dataType, entity);
  };

  removeFromGroup = () => {
    const { groupId, entity, dataType } = this.props;
    const entityTypeName = capitalize(dataType);
    this.props.showConfirmationDialog(
      `Remove ${entityTypeName} From Group`,
      `Are you sure you want to remove ${dataType} ${entity.id} from the current group?`,
      getConfirmableRemoveEntityFromGroupWithMessage(
        entity.id, groupId, `${entityTypeName} ${entity.id} removed from the group`
      )
    );
  };

  clickHandler = event => event.stopPropagation();

  render() {
    const { dataType } = this.props;
    return (
      <IconMenu
        onClick={this.clickHandler}
        iconButtonElement={
          <IconButton style={{ padding: '0 0 0 10px', border: 'none', width: 'auto', height: 'auto' }}>
            <FontIcon className="material-icons" color={dotmapsGray}>more_vert</FontIcon>
          </IconButton>
        }
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        targetOrigin={{horizontal: 'left', vertical: 'top'}}
        desktop
      >
        {this.allowEdit() &&
          <MenuItem
            primaryText={`Edit ${getEntityTypeLabel(dataType)}`}
            onClick={this.goToEntityInfo}
          />
        }
        <MenuItem primaryText="View activity log" onClick={this.goToActivityLog} />
        {this.allowAddToGroup() &&
          <MenuItem
            primaryText="Add to group"
            onClick={this.addToGroup}
          />
        }
        {this.allowAddToGroup() && this.allowRemoveFromGroup() && <Divider />}
        {this.allowRemoveFromGroup() &&
          <MenuItem
            primaryText="Remove from group"
            onClick={this.removeFromGroup}
          />
        }
      </IconMenu>
    );
  }
}

MoreButton.propTypes = {
  dataType: PropTypes.string,
  entity: PropTypes.object,
  groupId: PropTypes.number,
  groupType: PropTypes.string,
  openGroupAddEntityDialog: PropTypes.func,
  push: PropTypes.func,
  showConfirmationDialog: PropTypes.func,
  source: PropTypes.string
};

export default connect(
  null,
  {
    openGroupAddEntityDialog,
    push,
    showConfirmationDialog
  }
)(MoreButton);
