import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DmListItem from '../ui-library/list/dm-list-item';

const CityLayerListItem = ({
  border = false,
  className = null,
  draggableProps = null,
  onClick = null,
  layerItem = {},
  ...passThroughProps
}) => {
  const layers = useSelector(state => state.layers);
  const icon = useMemo(() => {
    const iconUrl = layerItem?.icon?.url;
    if (iconUrl) {
      const description = layers[layerItem?.attrs?.type_name]?.label || 'City Layer';
      return (
        <img src={iconUrl} width={24} height={24} alt={description}/>
      );
    }
    return null;
  }, [layerItem, layers]);

  return (
    <DmListItem
      beforeList={icon}
      border={border}
      className={className}
      draggableProps={draggableProps}
      onClick={onClick}
      {...passThroughProps}
    >
      {layerItem?.name}
    </DmListItem>
  );
};

CityLayerListItem.propTypes = {
  border: PropTypes.bool,
  className: PropTypes.string,
  draggableProps: PropTypes.object,
  layerItem: PropTypes.object,
  onClick: PropTypes.func
};

export default memo(CityLayerListItem);
