import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import * as colors from '@constants/colors';
import { getUserAvatar } from '@utils/data-table/user';

import SelectFilterChip from '../chips/select';

import { useDataTypeFilterHandleChange } from '../hooks';

const UserFilter = ({filter, value, dataType, subType, filterKey}) => {
  const handleChange = useDataTypeFilterHandleChange(dataType, subType, filterKey);
  const users = useSelector(state => state.dataTypes.user);
  const options = useMemo(() => {
    return Object.values(users).filter(user => user.is_active)
      .map(user => ({
        avatar: getUserAvatar(user, 40, colors.dotmapsBlack20),
        description: user.email,
        label: user.name,
        value: user.id
      }));
  }, [users]);

  return (
    <SelectFilterChip
      label={filter.label || 'User'}
      options={options}
      value={value}
      onChange={handleChange}
      multiSelect
      search
    />
  );
};

UserFilter.propTypes = {
  dataType: PropTypes.string.isRequired,
  filter: PropTypes.object.isRequired,
  filterKey: PropTypes.string.isRequired,
  subType: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

export default memo(UserFilter);
