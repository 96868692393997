import React from 'react';
import PropTypes from 'prop-types';
import Subheader from './subheader';
import Input from './input';
import List from './list';
import './body.scss';

const Body = ({ activity, data, scrollTop }) => (
  <div styleName="body-container">
    <Subheader activity={activity} data={data} />
    <Input activity={activity} task={data} />
    <List activity={activity} data={data} scrollTop={scrollTop} />
  </div>
);

Body.propTypes = {
  activity: PropTypes.object,
  data: PropTypes.object,
  scrollTop: PropTypes.number
};

export default Body;
