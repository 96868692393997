import {
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_UPDATE_SUCCESS
} from '@components/config/constants';
import moment from 'moment-timezone';
import { BASE_URL } from '@constants/endpoints';
import axios from 'axios';
import { getServerErrorAction } from '@actions/shared-actions';
import { mergeConfig, setConfig } from '@utils/config-utils';

const fetchConfigSuccessAction = payload => ({ type: FETCH_CONFIG_SUCCESS, payload });

export const fetchConfig = () => {
  const request = axios.get(`${BASE_URL}/api/public/config/`);
  return dispatch =>
    request.then(
      response => {
        const { data } = response;
        setConfig(data);
        moment.tz.setDefault(data.timezoneName);
        dispatch(fetchConfigSuccessAction(response));
      },
      error => dispatch(getServerErrorAction(name, error))
    );
};

const fetchConfigUpdateSuccessAction = payload => ({ type: FETCH_CONFIG_UPDATE_SUCCESS, payload });

export const fetchConfigUpdate = () => {
  const request = axios.get(`${BASE_URL}/api/config_update/`);
  return dispatch =>
    request.then(
      data => {
        mergeConfig(data.data);
        dispatch(fetchConfigUpdateSuccessAction(data));
      },
      error => dispatch(getServerErrorAction(name, error))
    );
};
