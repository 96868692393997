/* global google */
import { isEmpty } from 'lodash';
import * as R from 'ramda';
import moment from 'moment-timezone';
import {
  mapConfig,
  mapTabsConfig
} from '@constants/component-configs';
import { getMarkerVisibleZoom } from '@constants/config';
import { getAPIRequestUrl } from '@constants/endpoints';
import { googleMapsApiKey, mapStylersPoisOn, mapStylers } from '@constants/google-maps';
import {
  GROUP_TRAY,
  ENTITY_TRAY,
  LAYER_TRAY,
  DETAIL_TRAY_TYPES
} from '@constants/map-trays';
import { isOldIE } from '@utils/browser-detect';
import { shallowEqual } from '@utils/react-utils';
import { pluralize } from '@utils/shared-utils';

export const zipCoordinatePair = R.zipObj(['lng', 'lat']);
// zipCoordinatePair([-141, 35]) will return { lat: 35, lng: -141}

export const generatePath = coordinates => coordinates.map(zipCoordinatePair);

export const createUrlFromFilters = (filters, type) => {
  const url = getAPIRequestUrl(type);
  let filterPairs = R.toPairs(filters);

  const attrsFilters = filterPairs.filter(pair => pair[0].includes('attrs__') && pair[1]).map(pair => {
    return `${pair[0].split('__')[1]}|${pair[1]}`;
  });

  if (attrsFilters.length) {
    filterPairs = filterPairs.concat([['attrs', attrsFilters.join(',')]]);
  }

  const params = filterPairs.map(pair => encodeURI(pair.join('=')))
    .join('&');
  return `${url}&${params}`;
};

export const getPointShapeCoords = shape => zipCoordinatePair(shape.coordinates);

export const parseCenterTuple = center => ({ lng: center[0], lat: center[1] });

const createNewLatLngBounds = () => new google.maps.LatLngBounds();

// Used for when we need to process groups and no Google Maps code is present (i.e. in non-map pages).
export const dummyBoundary = {
  toJSON() {
  }
};

const generatePolygonBounds = ({ shape: { coordinates } }) => {
  if (window.google) {
    const bounds = createNewLatLngBounds();
    coordinates.forEach(ring => {
      generatePath(ring).forEach(coordinate => {
        bounds.extend(coordinate);
      });
    });
    return bounds;
  }
  return dummyBoundary;
};

const generateMultiPolygonBounds = ({ shape: { coordinates } }) => {
  if (window.google) {
    const bounds = createNewLatLngBounds();
    coordinates.forEach(polygon => {
      polygon.forEach(ring => {
        generatePath(ring).forEach(coordinate => {
          bounds.extend(coordinate);
        });
      });
    });
    return bounds;
  }
  return dummyBoundary;
};

const generateLineStringBounds = ({ shape: { coordinates } }) => {
  if (window.google) {
    const bounds = createNewLatLngBounds();
    generatePath(coordinates).forEach(coordinate => {
      bounds.extend(coordinate);
    });
    return bounds;
  }
  return dummyBoundary;
};

const generatePointBounds = ({ shape: { coordinates } }) => {
  if (window.google) {
    const bounds = createNewLatLngBounds();
    bounds.extend(zipCoordinatePair(coordinates));
    return bounds;
  }
  return dummyBoundary;
};

export const generateBounds = R.cond([
  [R.pathEq('Polygon', ['shape', 'type']), generatePolygonBounds],
  [R.pathEq('MultiPolygon', ['shape', 'type']), generateMultiPolygonBounds],
  [R.pathEq('MultiLineString', ['shape', 'type']), generatePolygonBounds],
  [R.pathEq('LineString', ['shape', 'type']), generateLineStringBounds],
  [R.pathEq('Point', ['shape', 'type']), generatePointBounds]
]);

export const segmentsToBounds = segments => {
  if (!isEmpty(segments)) {
    // Remove empty entries with Lodash's isEmpty() (which checks for 'undefined').
    const bounds = (
      segments
        .map(segment => segment.bounds || generateBounds(segment))
        .filter(bound => !isEmpty(bound))
    );
    if (bounds.length > 0) {
      return bounds.reduce((acc, val) => acc.union(val));
    }
  }
  return null;
};

export const calculateOverallBounds = (
  R.useWith(  // eslint-disable-line react-hooks/rules-of-hooks
    R.reduce((bounds, segment) => bounds.union(segment.bounds)),
    [initializer => initializer(), R.identity]
  )(createNewLatLngBounds)
);

const optimizeLineString = ({ center, geohash, shape, id, direction = 0 }) => ({
  id,
  path: generatePath(shape.coordinates),
  center: center && parseCenterTuple(center),
  bounds: generateLineStringBounds({shape}),
  direction,
  geohash,
  shape
});

const optimizePoint = ({ center, geohash, shape, id }) => ({
  id,
  center: center && parseCenterTuple(center),
  bounds: generatePointBounds({shape}),
  geohash,
  shape
});

const optimizePolygon = ({ shape, id, center = null, geohash = null }) => ({
  id,
  rings: shape.coordinates.map(generatePath),
  center: center && parseCenterTuple(center),
  bounds: generatePolygonBounds({shape}),
  geohash,
  shape
});

const optimizeMultiPolygon = ({ shape, id, center = null, geohash = null }) => ({
  id,
  rings: shape.coordinates.map(generatePath),
  center: center && parseCenterTuple(center),
  bounds: generateMultiPolygonBounds({shape}),
  geohash,
  shape
});

export const optimizeSegmentForMap = R.cond([
  [R.pathEq('LineString', ['shape', 'type']), optimizeLineString],
  [R.pathEq('MultiLineString', ['shape', 'type']), optimizePolygon],
  [R.pathEq('MultiPolygon', ['shape', 'type']), optimizeMultiPolygon],
  [R.pathEq('Point', ['shape', 'type']), optimizePoint],
  [R.pathEq('Polygon', ['shape', 'type']), optimizePolygon]
]);

export const getStreetViewImageThumbnail = ({ lat, lng }) =>
  `https://maps.googleapis.com/maps/api/streetview?size=344x160&location=${lat},${lng}&key=${googleMapsApiKey}`;

export const getStreetViewBackground = position => {
  if (isOldIE()) {
    return {background: `url(${getStreetViewImageThumbnail(position)}) center center`};
  }
  return {
    background: `linear-gradient(rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)), url(${getStreetViewImageThumbnail(position)}) center center`
  };
};

const latLngArrayToWKT = coordinates => `POLYGON((${coordinates.map(R.join(' '))}))`;

export const featureToWKT = ({ geometry }) => latLngArrayToWKT(geometry.coordinates[0]);

export const boundsToWKT = bounds => {
  if (!bounds) {
    return null;
  }
  const northEast = bounds.getNorthEast();
  const north = northEast.lat();
  const east = northEast.lng();
  const southWest = bounds.getSouthWest();
  const south = southWest.lat();
  const west = southWest.lng();
  return latLngArrayToWKT([[west, north], [east, north], [east, south], [west, south], [west, north]]);
};

export const getWKTViewportBoundsFromState = R.pipe(
  R.defaultTo(null, R.path(['map', 'viewport', 'bounds'])),
  boundsToWKT
);

export const setMapStreetViewPosition = map => map.getStreetView().setOptions(mapConfig.streetViewOptions);

export const rejectEntityById = (entityKey, id, list) => R.reject(entity => entity[entityKey] === id, list);

const getEntityId = (entityType, entity) => {
  switch (entityType) { // eslint-disable-line default-case
  case GROUP_TRAY: return entity.groupId;
  case LAYER_TRAY: return entity.layerId;
  case ENTITY_TRAY: return entity.entityId;
  }
  return null;
};

const getEntityKey = entityType => {
  switch (entityType) { // eslint-disable-line default-case
  case GROUP_TRAY: return 'groupId';
  case LAYER_TRAY: return 'layerId';
  case ENTITY_TRAY: return 'entityId';
  }
  return null;
};

// Prepends the specified entity to the specified recent list,
// removing it from the list first if it exists.
export const prependToRecent = (recentList, entityType, entity) => {
  if (entity.pending) {
    return recentList;
  }
  const id = getEntityId(entityType, entity);
  const entityKey = getEntityKey(entityType);
  const newRecentList = rejectEntityById(entityKey, id, [...recentList]);
  return [entity, ...newRecentList];
};

// Returns the number of days between two dates:
export const daysDiff = (start, end) => {
  const startDate = moment(start);
  const endDate = moment(end);
  return endDate.diff(startDate, 'days');
};

// Returns a 'x days long' string with the supplied start and end dates
// where 'x' is the difference of days between those dates.
export const daysLong = (start, end) => {
  if (!start && !end) {
    return null;
  }
  try {
    const days = daysDiff(start, end);
    return `${days} ${pluralize(days, 'day')} long`;
  } catch (err) {
    // In case the dates are null or has some invalid value:
    return null;
  }
};

// Returns a string 'x days ago' with the number of days since the specified date.
export const daysAgo = start => {
  try {
    const end = moment();
    const days = daysDiff(start, end);
    return `${days} ${pluralize(days, 'day')} ago`;
  } catch (err) {
    // In case the start date is null or has
    // some garbage from the database.
    return null;
  }
};

//geoJson features are closed, but google does not want closed shapes arrays so we pop off the last element
export const generatePolygonPaths = R.map(R.pipe(path => path.slice(0, -1), generatePath));

export const convertToGoogleMapsShape = shape => {
  if (shape.type === 'Polygon') {
    return new google.maps.Polygon({
      paths: generatePolygonPaths(shape.coordinates)
    });
  }
  throw Error('unsupported shape type');
};

export const calculateRadius = zoom => Math.min(2 ** (Math.max(22 - zoom, 0)), 300);

export const circleBounds = (lng, lat, radius) => {
  const circle = new google.maps.Circle({
    center: {lat, lng}, radius
  });
  return circle.getBounds();
};

export const renderHighlightCircle = () => {
  const zoom = window.mapInstance.getZoom();
  const options = {
    ...mapConfig.highlightCircleOptions,
    map: zoom >= getMarkerVisibleZoom() ? window.mapInstance : null,
    radius: calculateRadius(zoom)
  };
  window.highlightCircle.setOptions(options);
};

// Returns an object for the store (for state.map.trays) with the tray
// data for the current active tray tab.
export const getActiveTabTray = value => {
  const tabs = Object.keys(mapTabsConfig.tabs);
  for (const tab of tabs) {
    if (mapTabsConfig.tabs[tab].value === value) {
      return {
        trayType: mapTabsConfig.tabs[tab].type,
        trayName: mapTabsConfig.tabs[tab].name
      };
    }
  }
  return null;
};

// Returns the tray tab value for the specified tray type:
export const getTrayTabValue = trayType => {
  const tabs = Object.keys(mapTabsConfig.tabs);
  for (const tab of tabs) {
    if (mapTabsConfig.tabs[tab].type === trayType) {
      return mapTabsConfig.tabs[tab].value;
    }
  }
  if (DETAIL_TRAY_TYPES.has(trayType)) {
    return mapTabsConfig.tabs.details.value;
  }
  return null;
};

export const getOverlapEntitiesIds = overlapEntities => {
  const id = Object.keys(overlapEntities)[0];
  let { conflicts = [], opportunities = [] } = overlapEntities[id];
  conflicts = conflicts.map(conflict => conflict.id);
  opportunities = opportunities.map(opportunity => opportunity.id);
  return [parseInt(id, 10), ...conflicts, ...opportunities];
};

export const getMapStylers = (poisOn, transit) => {
  let computedMapStyle = [...mapStylers];
  if (transit && !poisOn) {
    computedMapStyle = [...mapStylers].filter(
      styleField => styleField.featureType.indexOf('transit') < 0
    ).concat([
      {featureType: 'transit', stylers: [{ visibility: 'on' }]},
      {featureType: 'transit.station', stylers: [{visibility: 'on'}]}
    ]);
  } else if (transit && poisOn) {
    computedMapStyle = [...mapStylersPoisOn].push({featureType: 'transit', stylers: [{ visibility: 'on' }]});
  } else if (!transit && poisOn) {
    computedMapStyle = [...mapStylersPoisOn];
  }
  return computedMapStyle;
};

export const didViewportChanged = (nextViewport, viewport) => {
  // If the viewport changed, re-render only if the data actually changed,
  // not if only the object references changed:
  if (!shallowEqual(nextViewport, viewport)) {
    if (!shallowEqual(nextViewport.zoom, viewport.zoom)) {
      return true;
    }
    if (viewport.bounds === null && nextViewport.bounds !== null) {
      // If bounds were null, continue with the next property checks,
      // don't re-render only if the bounds changed from null to something.
    } else {
      if (nextViewport.bounds &&
          !shallowEqual(nextViewport.bounds.toJSON(), viewport.bounds.toJSON())) {
        return true;
      }
      if (!shallowEqual(nextViewport.center, viewport.center)) {
        const nextCenter = nextViewport.center;
        const thisCenter = viewport.center;
        const next = typeof nextCenter.toJSON === 'undefined' ? nextCenter : nextCenter.toJSON();
        const current = typeof thisCenter.toJSON === 'undefined' ? thisCenter : thisCenter.toJSON();
        if (!shallowEqual(next, current)) {  // eslint-disable-line max-depth
          return true;
        }
      }
    }
  }
  return false;
};
