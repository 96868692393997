/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {Fragment, Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import AddIcon from 'material-ui/svg-icons/content/add';
import CircularProgress from 'material-ui/CircularProgress';
import ErrorOutline from 'material-ui/svg-icons/alert/error-outline';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { uploadBatch } from '@actions/batch-actions';
import { fetchLayerConfig } from '@actions/layers-actions';
import { overlayTextColor } from '@constants/colors';
import {
  batchUploadDialogStyle,
  dialogStyles
} from '@constants/mui-theme';
import ActionButton from '@shared/action-button';
import FileArea from '@shared/files/file-area';
import ActionIconButton from '@shared/action-icon-button';
import { canAccessEntity } from '@utils/permission-utils';
import { sortByField } from '@utils/data-types-utils';
import './batch-upload-form.scss';

const defaultState = {
  layerType: null,
  entityType: null,
  typeSelected: null,
  toUpload: null,
  uploadStarted: false,
  progress: 0,
  fileValidationError: false,
  uploadErrored: false
};

// Restrict file uploads to 8 mb:
const MAX_UPLOAD_SIZE = 8388608;

class BatchUploadForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...defaultState,
      open: props.open === null ? false : props.open
    };
  }
  UNSAFE_componentWillMount() {
    if (R.isEmpty(this.props.layers)) {
      this.props.fetchLayerConfig();
    }
  }
  componentDidUpdate = prevProps => {
    const {open: controlledOpen} = this.props;
    const {open: prevControlledOpen} = prevProps;
    if (controlledOpen === null) {
      return;
    }
    if (controlledOpen !== prevControlledOpen) {
      if (controlledOpen === true && this.state.open === false) {
        this.handleOpen();
      }
      if (controlledOpen === false && this.state.open === true) {
        this.handleClose();
      }
    }
  };

  handleUploadClick = () => {
    if (this.state.toUpload) {
      this.setState({ uploadStarted: true }, () => {
        const { entityType, layerType, toUpload } = this.state;
        const upload = {
          active: true,
          file: toUpload,
          entityType,
          layerType,
          status: 0
        };
        this.props.uploadBatch(upload, this.handleProgress.bind(this), this.handleFail.bind(this));
      });
    }
  };
  handleFileClear = () => {
    this.setState({
      fileValidationError: false,
      toUpload: null
    });
  };
  handleFileChange = file => {
    if (file) {
      this.setState({
        toUpload: file,
        fileValidationError: file.size > MAX_UPLOAD_SIZE
      });
    } else if (this.state.toUpload) {
      this.setState({ toUpload: null });
    }
  };
  onLayerChange = (event, index, value) => {
    this.setState({layerType: value});
  };
  handleTypeChange = (event, index, value) => {
    this.setState({
      entityType: this.getEntityTypes()[index].name,
      typeSelected: value
    });
  };
  handleOpen = () => {
    this.setState({
      ...defaultState,
      open: true
    });
  };
  handleClose = () => {
    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
    this.setState({ open: false });
  };
  handleProgress(event) {
    const progress = Math.floor((event.loaded / event.total) * 100);
    this.setState({ progress }, () => {
      if (progress === 100) {
        this.setState({ uploadStarted: false, open: false });
      }
    });
  }
  handleFail() {
    this.setState({uploadErrored: true});
  }
  getLayers = () => {
    const layers = [];
    for (const name in this.props.layers) {
      if (Object.prototype.hasOwnProperty.call(this.props.layers, name)) {
        // Don't list Zoning and Streets Use Permits for now:
        if (name !== 'zoning' && name !== 'street_use_permits') {
          const layer = this.props.layers[name];
          layers.push({
            label: layer.label,
            name: layer.name,
            // Disabled all layers but Hubs (for now),
            // this means, we can only upload Hubs json files.
            disabled: layer.name !== 'hubs'
          });
        }
      }
    }
    return layers;
  };
  getEntityTypes = () => {
    const { entityTypes } = this.props;
    const entityList = sortByField('order')(R.values(entityTypes));
    const list = [
      ...(Object.keys(entityList).map(key => (
        { name: entityList[key].name, label: entityList[key].label}
      ))),
      {
        name: 'layer',
        label: 'City Layer'
      }
    ];
    return list;
  };
  getEntityTypeValue = name => name;
  render() {
    const {open: controlledOpen = null} = this.props;
    if (!canAccessEntity(null, 'batchfile', 'add')) {
      return null;
    }
    const { typeSelected, open, uploadStarted, uploadErrored } = this.state;
    let actions = [];
    if (open && !uploadStarted && !uploadErrored) {
      actions = [
        <FlatButton label="Cancel" onClick={this.handleClose} key="cancel" />,
        <ActionButton
          label="Upload"
          key="upload"
          disabled={!this.state.toUpload || !this.state.typeSelected || this.state.fileValidationError}
          onClick={this.handleUploadClick}
          keyboardFocused
          primary
        />
      ];
    }
    if (uploadErrored) {
      actions = [
        <FlatButton label="Cancel" onClick={this.handleClose} key="cancel" />,
        <FlatButton
          label="Try Again"
          onClick={this.handleOpen} key="retry"
          {...batchUploadDialogStyle.retryButton}
        />
      ];
    }
    return (
      <Fragment>
        {controlledOpen === null &&
          <ActionIconButton
            id="dataTableBatchUploadButton"
            click={this.handleOpen}
            customIcon={<AddIcon color={overlayTextColor} />}
            tooltip="Upload Batch"
          />
        }
        <Dialog
          title={open && !uploadStarted && !uploadErrored ? 'Upload Batch' : ''}
          modal={false}
          open={this.state.open}
          actions={actions}
          actionsContainerStyle={dialogStyles.content.actions}
          titleStyle={dialogStyles.content.title}
          bodyStyle={dialogStyles.content.body}
          contentStyle={dialogStyles.mediumDialog}
        >
          { !uploadStarted && !uploadErrored &&
            <div>
              <div styleName="subheader">
                Select the data you want to create or update in batches:
              </div>
              <div styleName="section section-select">
                <SelectField
                  {...dialogStyles.uploadSelect}
                  floatingLabelText={typeSelected ? null : 'Select'}
                  onChange={this.handleTypeChange}
                  value={typeSelected}
                >
                  {this.getEntityTypes().map(entityType => (
                    <MenuItem
                      key={entityType.name}
                      value={this.getEntityTypeValue(entityType.name)}
                      primaryText={entityType.label}
                    />
                  ))}
                </SelectField>
              </div>
              <div styleName="section section-select" style={{ display: this.state.typeSelected === 'layer' ? 'block' : 'none'}}>
                <SelectField
                  {...dialogStyles.uploadSelect}
                  disabled={this.state.typeSelected !== 'layer'}
                  floatingLabelText={this.state.layerType ? null : 'Select a layer'}
                  onChange={this.onLayerChange}
                  value={this.state.layerType}
                >
                  {this.getLayers().map(layer => (
                    <MenuItem
                      disabled={layer.disabled}
                      key={layer.name}
                      value={layer.name}
                      primaryText={layer.label}
                    />
                  ))}
                </SelectField>
              </div>
              <div styleName="section">
                <div styleName="section-header">
                  Choose a file:
                </div>
                <div styleName="section-row">
                  <FileArea
                    id="batch-upload-input"
                    accept={this.state.typeSelected === 'layer' ? 'application/json,application/geo+json,.geojson' : 'text/csv'}
                    hasError={this.state.fileValidationError}
                    onFileClear={this.handleFileClear}
                    onFileSelect={this.handleFileChange}
                  />
                </div>
              </div>
              {this.state.fileValidationError && this.state.toUpload &&
                <div styleName="footer file-upload-error">
                  The file ({(this.state.toUpload.size / 1048576).toFixed(0)} MB) exceeds the limit.
                </div>
              }
              {!this.state.fileValidationError && (
                <div styleName="footer">
                  Acceptable file type:&nbsp;
                  {this.state.typeSelected !== 'layer' && 'CSV'}
                  {this.state.typeSelected === 'layer' && 'JSON'}
                  &nbsp;&nbsp;&bull;
                  Max file size: 8 MB
                </div>
              )}
            </div>
          }
          { uploadStarted && !uploadErrored &&
            <div styleName="loading-container">
              <CircularProgress
                {...batchUploadDialogStyle.circularProgress}
              />
              <div
                style={dialogStyles.content.title}
              >
                Uploading...
              </div>
            </div>
          }
          { uploadErrored &&
            <div styleName="error-container">
              <ErrorOutline
                {...batchUploadDialogStyle.circularError}
              />
              <div
                style={dialogStyles.content.title}
              >
                Upload Failed
              </div>
            </div>
          }
        </Dialog>
      </Fragment>
    );
  }
}

BatchUploadForm.propTypes = {
  entityTypes: PropTypes.object,
  fetchLayerConfig: PropTypes.func,
  layers: PropTypes.object,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  uploadBatch: PropTypes.func
};

const mapStateToProps = state => {
  const { dataTypes: { map_type }, layers } = state;
  return { entityTypes: map_type, layers };
};

const mapDispatchToProps = {
  fetchLayerConfig,
  uploadBatch
};

export default connect(mapStateToProps, mapDispatchToProps, null, {forwardRef: true})(BatchUploadForm);
