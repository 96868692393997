/* eslint-disable guard-for-in */
/* eslint-disable max-depth */
import { includes, isEmpty } from 'lodash';
import * as R from 'ramda';
import * as colors from '@constants/colors';
import { getDetailsConfig } from '@constants/config';
import {
  applyDynamicStyles,
  getAction,
  getTemplate,
  mergeActionWithTemplate
} from '@utils/data-detail-utils';

// Returns the specified dataType's form tabs config:
export const getTabs = dataType => getDetailsConfig()[dataType]?.tabs;

// Returns frontend and backend form metadata merged into a single object:
export const getMetadata = (data, dataType, options, isPublic = false) => {
  // Get backend fields definitions (i.e. field name, type, label, etc).
  const { action } = getAction(options);

  // Get frontend field definitions (i.e. field name, CSS style, etc).
  // isPublic passed in to bypass usertype check on agency permissions
  // agency should not be readonly if the page is public
  const fieldTemplate = getTemplate(dataType, isPublic);

  // And merge both:
  return applyDynamicStyles(mergeActionWithTemplate(action || {}, fieldTemplate), data || {});
};

// Return all form fields which can be displayed:
export const getVisibleFields = metadata => Object.keys(R.pickBy(value => value.style !== 'hidden', metadata));

// Scroll into the specified error field and return it:
const getErrorElement = field => {
  const errorKey = `error-${field}`;
  const errorElement = document.getElementById(errorKey);
  if (errorElement) {
    errorElement.parentNode.scrollIntoView();
  }
  return errorElement;
};

// Scroll into the first form field with errors (if any).
export const scrollIntoFirstError = (errors, metadata, visibleFields) => {
  if (errors) {
    for (const fieldIndex in visibleFields) {
      const field = visibleFields[fieldIndex];
      if (field in errors) {
        const errorElement = getErrorElement(field);
        if (errorElement) {
          return;  // Return on the first error field found.
        }
        // Check for nested elements:
        const nestedFields = Object.keys(errors[field]);
        for (const nestedIndex in nestedFields) {
          const nestedErrorElement = getErrorElement(`${field}.${nestedFields[nestedIndex]}`);
          if (nestedErrorElement) {
            return;
          }
        }
      }
    }
  }
};

// Catch 'non_field_errors' and if it's a UniqueTogether error about 'external_id'
// and other field, apply the error to the 'external_id' field.
export const getErrors = error => {
  if (error && error.non_field_errors) {
    const key = 'The fields type, external_id must make a unique set.';
    if (includes(error.non_field_errors, key)) {
      const newError = R.without(key, error.non_field_errors);
      newError.external_id = ['External ID already exists.'];
      return newError;
    }
  }

  return error;
};

export const isErrorsEmpty = (data, error, emptySegments = false) => {
  // Segments must be always present, with at least one location
  // to allow saving.
  // unless the 'emptySegments' flag is true, in which case we skip them
  // this allows us to hide the segment section on forms, but still allow saving
  if (!data || !data.segments || (isEmpty(data.segments) && !emptySegments)) {
    return false;
  }

  // The error key is empty:
  if (isEmpty(error)) {
    return true;
  }

  // If it's not empty, check for each entry in the array,
  // it may have nested errors, which might be all empty too:
  return Object.keys(error).every(key => {
    const value = error[key];
    return isEmpty(value) || (Array.isArray(value) && value.every(entry => isEmpty(entry)));
  });
};

// Returns true if the field is visible.
//
// i.e. it's a field starting with '_' (special fields)
// or it's in the visible list.
export const isFieldVisible = (fieldName, visibleFields) => fieldName[0] === '_' || includes(visibleFields, fieldName);

// Set the active tab in the rendered DOM, not throught Redux (i.e. by calling
// the setActiveTab() action).
//
// If we do it using Redux, it will work too, however setting the active tab there
// will cause the whole tab section and form to re-render, triggering also a
// scrolling event, which is hooked on tab activation.
//
// All that processing will cause the user to wait a few milliseconds or a second
// each time we cross through some section, causing some unpleasant UI effect
//
// Although it's always correct to do things in the React way (i.e. through property
// or state changes), in this case since it's due to user scrolling interaction,
// the result must be fast, thus there's no other way than to modify the DOM
// manually.
const setDOMActiveTab = (tabs, currentTab) => {
  // For each tab, manually turn off the styling that set it as the selected one:
  tabs.forEach(tab => {
    const item = document.getElementById(`tab-section-${tab.id}-option-item`);
    item.style.borderLeft = '2px solid transparent';
    item.style.color = colors.dotmapsBlack60;
    item.style.fontWeight = '400';
  });

  // Then, also manually, set the selected tab styling:
  const item = document.getElementById(`tab-section-${currentTab}-option-item`);
  item.style.borderLeft = '2px solid #1761E1';  // color $dotmaps-light-blue
  item.style.color = colors.dotmapsBlack80;
  item.style.fontWeight = '500';
};

// When we scroll the form, activate each tab section as we reach them.
export const onScroll = tabs => {
  const form = document.getElementById('form-container');

  // Find the form container 'y' screen coordinate start (needed to calculate
  // each tab section's top scrolling offset):
  const start = form.getBoundingClientRect().top + 16;  // Add 1rem due to padding.

  let currentTab = null;

  // The current tab is the one nearest to the scrolling value to the offset start of the form.
  tabs.forEach(tab => {
    const tabTitlePosition = document.getElementById(`tab-section-${tab.id}-title`).getBoundingClientRect().top;
    if (tabTitlePosition <= start) {
      currentTab = tab.id;
    }
  });

  // Set the active tab in the DOM, not through Redux state:
  if (currentTab !== null) {
    setDOMActiveTab(tabs, currentTab);
  }
};
