import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BaseTray,
  baseTrayMapDispatchToProps,
  baseTrayMapStateToProps
} from '@components/map/tray/base-tray';
import Input from '@components/map/tray/search/input';
import Empty from '@components/map/tray/search/empty';
import MapSearchResults from '@components/map/tray/search/results';
import { mapTabsConfig } from '@constants/component-configs';
import '../tray.scss';

class Search extends BaseTray {
  render() {
    const { isSearchTab, traySearch } = this.props;
    if (!isSearchTab) {
      return null;
    }
    const hasResults = traySearch.term.length > 0;
    return (
      <div styleName="map-tray-body-wrapper map-tray-body-shadow">
        <Input />
        {!hasResults && <Empty />}
        {hasResults && <MapSearchResults results={traySearch} />}
      </div>
    );
  }
}

Search.propTypes = {
  /**
   * True if the current tab is the search one.
   */
  isSearchTab: PropTypes.bool,
  /**
   * Contains the search result elements.
   */
  traySearch: PropTypes.object
};

const mapStateToProps = state => {
  const props = baseTrayMapStateToProps(state);
  const { traySearch } = state.map;
  const isSearchTab = state.map.currentTab === mapTabsConfig.tabs.search.value;
  return {
    traySearch,
    isSearchTab,
    ...props
  };
};

export default connect(mapStateToProps, baseTrayMapDispatchToProps)(Search);
