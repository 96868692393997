/* eslint-disable no-unused-vars */
import { Component } from 'react';
import PropTypes from 'prop-types';

// Base class for top bar filters:
export class BaseFilter extends Component {
  constructor(props) {
    super(props);
    this.state = this.getDefaultState();
    this.getDefaultState = this.getDefaultState.bind(this);
    this.getFilters = this.getFilters.bind(this);
    this.filterChange = this.filterChange.bind(this);
    this.filterClose = this.filterClose.bind(this);
    this.selectionRenderer = this.selectionRenderer.bind(this);
    this.setFilter = this.setFilter.bind(this);
  }
  getDefaultState() {
    return this.buildStateFromProps(this.props);
  }
  // Implement all these methods in subclasses:
  buildStateFromProps(props) {}
  getFilters() {}
  filterChange(event, key, values) {}
  // This is useful for filters that need to override
  // the set filter function:
  setFilter(filter) {
    this.props.setFilter(filter);
  }
  filterClose() {
    this.setFilter(this.getFilters());
  }
  selectionRenderer(values, menuItems) {}
}

BaseFilter.propTypes = {
  setFilter: PropTypes.func
};
