export const overlayTextColor = '#FFFFFF';
export const textColor = '#555555';
export const secondaryColor = '#406EE3';
export const iconColor = '#666666';

export const dotmapsLighterBlue = '#3E73E7';
export const dotmapsLightBlue = '#1761E1';
export const dotmapsBlue = '#3467D6';
export const dotmapsDarkBlue = '#254DAA';
export const dotmapsInactiveBlue = '#99B3EA';
export const dotmapsClusterBlue = '#4284F4';

export const dotmapsSnackbarAction = '#7694E2';

export const googleMapsHoverBlue = '#2B95FF';
export const googleMapsHover = '#000000';

export const dotmapsGray = '#4A4A4A';
export const dotmapsGrayHover = '#E4E4E4';
export const dotmapsGrayEmptyIcon = '#A4A4A4';
export const dotmapsGrayEmptyIconV2 = '#959595';
export const dotmapsGrayBorder = '#EBEBEB';
export const dotmapsGrayButtonBackground = '#F7F7F7';
export const dotmapsGrayLighter = '#6A6A6A';
export const dotmapsLightGray = '#FAFAFA';
export const dotmapsLightGrayInactive = '#BEBEBE';
export const dotmapsLightGrayText = '#7A7A7A';
export const dotmapsGrayInactive = '#9B9B9B';
export const dotmapsGrayLine = '#D8D8D8';
export const dotmapsGrayText = '#BDBDBD';
export const dotmapsGrayTopBar = '#F2F2F2';
export const dotmapsGrayAvatar = '#BCBCBC';
export const dotmapsVeryLightGray = '#DDDDDD';
export const dotmapsDisabled = '#C8C8C8';
export const dotmapsDisabledText = '#9E9E9E';

export const dotmapsDoneGreen = '#54C07C';
export const dotmapsGreen = '#1DAF54';
export const dotmapsDarkGreen = '#139443';
export const dotmapsLightGreen = '#20C05C';

export const dotmapsAttentionAction = '#F44336';
export const dotmapsRed = '#FF5722';
export const dotmapsDarkRed = '#DA4600';
export const dotmapsConflictRed = '#D55742';

export const dotmapsOrangeWarning = '#E57209';

export const dotmapsSegmentColor = '#27B0C6';
export const dotmapsPointColor = '#4C34C1';
export const dotmapsSelectedSegmentColor = secondaryColor;

export const dotmapsIconImage = '#007DEF';
export const dotmapsIconPDF = '#ED3620';

export const dotmapsError = '#F44336';
export const dotmapsBackgroundError = '#FBE4E4';

export const dotmapsMore = '#977000';

export const dotmapsBlack12 = 'rgba(0, 0, 0, 0.12)';
export const dotmapsBlack14 = 'rgba(0, 0, 0, 0.14)';
export const dotmapsBlack20 = 'rgba(0, 0, 0, 0.2)';
export const dotmapsBlack40 = 'rgba(0, 0, 0, 0.4)';
// Exactly the same as dotmapsBlack40 but with a #RRGGBB value.
// (for when a rgba() value can't be parsed by a library).
export const dotmapsBlack40Alt = '#999999';
export const dotmapsBlack50 = 'rgba(0, 0, 0, 0.5)';
export const dotmapsBlack60 = 'rgba(0, 0, 0, 0.6)';
export const dotmapsBlack80 = 'rgba(0, 0, 0, 0.8)';

export const dotmapsWhite80 = 'rgba(255, 255, 255, 0.8)';

export const dotmapsBadgeRed = '#D32F2F';

export const dotmapsOverlapLead = '#426EE3';
export const dotmapsOverlapOpen = '#D32F2F';
export const dotmapsOverlapPending = '#F9A825';
export const dotmapsOverlapResolved = '#03A9F4';
export const dotmapsOverlapOpportunity = '#00C853';
export const dotmapsOverlapOverlapping = '#FF5252';
