import { connect } from 'react-redux';
import * as R from 'ramda';
import { baseMapStateToProps, BaseDropDownFilter } from './base-dropdown-filter';
import { setGanttFilter } from '@actions/groups-actions';
import { getGanttConfig } from '@constants/config';

class LocationFilter extends BaseDropDownFilter {
  selectionRenderer(val, menuItems) {  // eslint-disable-line no-unused-vars
    const { selected } = this.state;
    return R.values(getGanttConfig().locationTypes).find(locationType => locationType.id === selected).selectedLabel;
  }
}

const generateItems = () => R.values(getGanttConfig().locationTypes).map(locationType => (
  { id: locationType.id, name: locationType.label }
));

const params = {
  lazyItems: () => generateItems(),
  name: 'locationType'
};

const mapStateToProps = state => baseMapStateToProps(state, params);

export default connect(mapStateToProps, { setFilter: setGanttFilter })(LocationFilter);
