import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getConfirmableDeleteAndNavigateAction } from '@actions/data-detail-actions';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { fetchCycle } from '@actions/workflow-actions';
import DeleteIcon from '@material-ui/icons/Delete';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canAccessWorkflow } from '@utils/permission-utils';
import './header.scss';

const Header = ({ showDelete, taskType }) => {
  const dispatch = useDispatch();
  const { cycle } = useSelector(state => state.workflow);
  const { data } = useSelector(state => state.dataDetail);

  const deleteTask = useCallback(
    () => {
      const source = getDashboardBackURL(window.location, null, `/cycle/${cycle.id}/`);

      // Callback to reload the cycle after task deletion.
      // (to refresh the list of tasks).
      const callback = () => dispatch(fetchCycle(cycle.id));
      dispatch(showConfirmationDialog(
        'Delete task?',
        `Are you sure you want to delete task "${data.name}"?`,
        getConfirmableDeleteAndNavigateAction('task', 'task', data.id, source, callback)
      ));
    },
    [cycle, data, dispatch]
  );

  if (!cycle || !data) {
    return null;
  }

  const canDelete = canAccessWorkflow('task', 'delete');
  return (
    <div styleName="header">
      <div styleName="header-title">
        <div styleName="header-title-label">{data.name}</div>
        {showDelete && canDelete && (
          <div styleName="header-title-action">
            <IconMenu>
              <IconMenuItem key="delete" icon={<DeleteIcon />} onClick={deleteTask}>
                Delete task
              </IconMenuItem>
            </IconMenu>
          </div>
        )}
      </div>
      <div styleName="header-subtitle">
        {taskType.name}
      </div>
    </div>
  );
};

Header.propTypes = {
  showDelete: PropTypes.bool,
  taskType: PropTypes.object
};

export default Header;
