import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { fetchDataDetail } from '@actions/data-detail-actions';
import {
  getConfirmableRemoveEntityFromGroupWithMessage,
  openGroupAddEntityDialog
} from '@actions/groups-actions';
import { getEntityTypeLabel } from '@constants/config';
import { GROUPS_TAB_COLUMNS as columns } from '@constants/table';
import DetailsTableHeader from '@shared/details-table-header';
import AddToGroupDialog from '@shared/dialogs/add-to-group-dialog';
import Table from '@shared/table';
import { arrayToObjectIds } from '@utils/data-types-utils';
import { openInNewTab } from '@utils/navigation-utils';
import { canEditGroups } from '@utils/permission-utils';

const Groups = () => {
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  const { list, loading } = useSelector(state => state.groups);
  const dispatch = useDispatch();

  const groupsById = arrayToObjectIds(list);
  const dataTypeDisplayName = getEntityTypeLabel(dataType);
  const groupIds = data.group_ids;
  const rows = Object.values(groupsById)
    .filter(group => groupIds && groupIds.includes(group.id));

  // Callback for when we successfully added this entity to a group.
  const reloadCallback = useCallback(
    () => dispatch(fetchDataDetail(dataType, data.id)),
    [data.id, dataType, dispatch]
  );

  const onAdd = useCallback(
    () => dispatch(openGroupAddEntityDialog(dataType, data, reloadCallback)),
    [data, dataType, dispatch, reloadCallback]
  );

  // eslint-disable-next-line no-unused-vars
  const onRowClick = (event, row, index) => openInNewTab(`/group/${row.type_name}/${row.id}`);

  const entityTypeName = capitalize(dataType);
  const removeGroup = row => dispatch(showConfirmationDialog(
    'Remove From Group',
    `Are you sure you want to remove ${dataType} ${data.id} from group "${row.name}"?`,
    getConfirmableRemoveEntityFromGroupWithMessage(
      data.id,
      row.id,
      `${entityTypeName} ${data.id} removed from the group`,
      reloadCallback
    )
  ));

  const actions = [
    {
      icon: DeleteIcon,
      checkPermission: row => canEditGroups(row.type_name),
      label: 'Remove entity from group',
      onClick: removeGroup
    }
  ];

  const tableProps = {
    actions,
    columns,
    empty: { message: `This ${dataTypeDisplayName} doesn't belong to any groups yet` },
    header: <DetailsTableHeader count={rows.length} label="group" onAdd={onAdd} tooltip="Add to group" />,
    loading,
    onRowClick,
    rows,
    style: { maxWidth: '50rem' }
  };

  return (
    <Fragment>
      <Table {...tableProps} />
      <AddToGroupDialog />
    </Fragment>
  );
};

export default Groups;
