import React from 'react';
import PropTypes from 'prop-types';
import LegendIcon from './legend-icon';
import LayersIcon from './layers-icon';
import Tools from './tools';
import './floating-bar.scss';

const FloatingBar = ({ legend, tools }) => (
  <div styleName="map-floating-bar-container">
    <LayersIcon />
    {tools && <Tools />}
    {legend && <LegendIcon />}
  </div>
);

FloatingBar.propTypes = {
  legend: PropTypes.bool,
  tools: PropTypes.bool
};

FloatingBar.defaultProps = {
  legend: true,
  tools: true
};

export default FloatingBar;
