import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import SourceLink from '@shared/source-link';

import * as colors from '@constants/colors';
import styles from './page-header-small.scss';

export const TITLE_REGULAR = 'regular';
export const TITLE_NEXT_LINE = 'next-line';

const PageHeaderSmall = ({
  addTitle = 'Add',
  backTitle = 'Back',
  moreActions = null,
  onAdd = null,
  onBack = null,
  onClose = null,
  subTitle = null,
  thumbnail = null,
  title = '',
  titleCount = null,
  titleMode = TITLE_REGULAR
}) => {
  const twoLine = titleMode === TITLE_NEXT_LINE;
  const titleContents = (
    <span className={styles.titleArea}>
      <span className={styles.title}>
        {title}
        {titleCount &&
          <span className={styles.count}>
            {titleCount}
          </span>
        }
      </span>
      {subTitle &&
        <span className={styles.subtitle}>
          {subTitle}
        </span>
      }
    </span>
  );

  return (
    <div className={classNames(styles.container, {[styles.twoLine]: twoLine})}>
      <span className={styles.subContainer}>
        {onBack &&
          <IconButton onClick={onBack} classes={{root: styles.button}}>
            <ArrowBackIcon titleAccess={backTitle} color="inherit"/>
          </IconButton>
        }
        {thumbnail &&
          <span className={styles.thumbnail}>
            {thumbnail}
          </span>
        }
        { titleMode === TITLE_REGULAR && titleContents}
        {onAdd &&
          <IconButton onClick={onAdd} classes={{root: styles.button}}>
            <AddIcon titleAccess={addTitle} color="inherit"/>
          </IconButton>
        }
        {moreActions && moreActions.length > 0 &&
          <span className={styles.menuContainer}>
            <IconMenu>
              {moreActions.map(({ label, link, action }, index) => {
                if (link) {
                  return (
                    <IconMenuItem key={index}>
                      <SourceLink to={link} style={{ textDecoration: 'none', color: colors.dotmapsBlack80 }}>
                        {label}
                      </SourceLink>
                    </IconMenuItem>
                  );
                }
                return (
                  <IconMenuItem key={index} onClick={action}>
                    {label}
                  </IconMenuItem>
                );
              })}
            </IconMenu>
          </span>
        }
        {onClose &&
          <IconButton onClick={onClose} classes={{root: styles.button}}>
            <CloseIcon titleAccess="Close" color="inherit" />
          </IconButton>
        }
      </span>
      { twoLine && titleContents }
    </div>
  );
};

PageHeaderSmall.propTypes = {
  addTitle: PropTypes.string,
  backTitle: PropTypes.string,
  moreActions: PropTypes.node,
  onAdd: PropTypes.func,
  onBack: PropTypes.func,
  onClose: PropTypes.func,
  subTitle: PropTypes.string,
  thumbnail: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  titleCount: PropTypes.string,
  titleMode: PropTypes.string
};

export default memo(PageHeaderSmall);
