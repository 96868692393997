import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import { loginStyles } from '@constants/mui-theme';
import '../layouts/not-authenticated/not-authenticated.scss';

class Success extends Component {
  render() {
    const { isOnboarding } = this.props;
    const confirmationMessage = isOnboarding ?
      'You have successfully created a Coordinate account. Go to the login page to sign into the app.' :
      'Your password was successfully reset.';

    return (
      <div styleName="success-section">
        <div styleName="success-check-icon">
          <FontIcon className="material-icons" style={loginStyles.successIcon}>check_circle</FontIcon>
        </div>
        <div styleName="auth-heading">Great</div>
        <div>
          <div styleName="auth-section">
            <div>
              { confirmationMessage }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Success.propTypes = {
  isOnboarding: PropTypes.bool
};

export default Success;
