import React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { createMuiTheme } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { withStyles } from '@material-ui/core/styles';
import * as colors from '@constants/colors';
import LanguageProvider from '@components/language-provider';
import ConfigurationLoader from '@components/config/loader';
import routes from '../routes';

const materialTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.secondaryColor
    }
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.dotmapsBlue
      }
    },
    MuiPickersDay: {
      // Keep most day override styles commented,
      // the default ones are better.
      day: {
        // color: colors.dotmapsGray
      },
      daySelected: {
        backgroundColor: colors.dotmapsBlue
      },
      dayDisabled: {
        // color: colors.dotmapsBlack40
      },
      current: {
        // color: colors.dotmapsBlue
      }
    }
  }
});

const GlobalCss = withStyles({
  // @global is handled by jss-plugin-global.
  '@global': {
    '.MuiInputBase-input': {  // Input TextField.
      color: colors.dotmapsBlack80,
      fontSize: '0.875rem'
    },
    '.MuiFormLabel-root': {
      color: colors.dotmapsBlack60,
      fontSize: '0.875rem'
    },
    '.MuiInputLabel-shrink': {  // Shrinked input label.
      color: colors.dotmapsBlack60,
      transform: 'translate(0, 1.5px) scale(0.85)'
    },
    '.MuiTextField-root .MuiInputAdornment-positionEnd .MuiIconButton-root': {  // TextField icon.
      padding: '0'
    },
    '.MuiAutocomplete-option': {
      boxShadow: 'inset 0 -1px 0 0 #EBEBEB'
    },
    // Customize the Avatar's initials to show them in white instead of
    // the default grey color.
    '.MuiChip-root .MuiChip-avatar': {
      color: 'white'
    }
    // This commented code below might be used when we move
    // to use the Dialog components from the new Material UI version.
    /*
    '.MuiDialogActions-root.withAdditionalAction': {
      // Set justifyContent to default value to fix IE11 layout bug
      // see https://github.com/mui-org/material-ui-pickers/pull/267
      justifyContent: 'flex-start',
      // Make the first action button to be aligned
      // to the left (by adding some margin to the right,
      // when there's an additional action).
      '& > *:first-child': {
        marginRight: 'auto'
      }
    },
    '.MuiDialogActions-root': {
      marginTop: '1rem'  // Add some padding to the dialog actions.
    }
    */
  }
})(() => null);

const Root = ({ history, messages, store }) => (
  <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
    <GlobalCss />
    <Provider store={store}>
      <LanguageProvider messages={messages}>
        <ThemeProvider theme={materialTheme}>
          <ConfigurationLoader>
            <ConnectedRouter history={history}>
              {routes}
            </ConnectedRouter>
          </ConfigurationLoader>
        </ThemeProvider>
      </LanguageProvider>
    </Provider>
  </MuiPickersUtilsProvider>
);

Root.propTypes = {
  history: PropTypes.object.isRequired,
  messages: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default Root;
