/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import DeleteIcon from '@icons/delete-icon';
import { deleteAttachments } from './actions';
import './button.scss';

const DeleteButton = ({
  attachment, objectId, objectType, storedInMemory, infoIndex, icon
}) => {

  const dispatch = useDispatch();

  const deleteAction = () => dispatch(deleteAttachments(attachment, objectId, objectType, storedInMemory, infoIndex));

  return (
    <div>
      <div 
        onClick={deleteAction}
        styleName="action-icon"
        role="presentation"
      >
        {icon}
        {!icon &&
          <DeleteIcon />
        }
      </div>
    </div>
  );
};

DeleteButton.propTypes = {
  attachment: PropTypes.object,
  icon: PropTypes.node,
  infoIndex: PropTypes.number,
  objectId: PropTypes.number,
  objectType: PropTypes.string,
  storedInMemory: PropTypes.bool
};

export default DeleteButton;
