/* eslint-disable react/jsx-no-bind */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateDashboardField } from '@actions/dashboard-actions';
import { autocompleteSearchStyles } from '@constants/mui-theme';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import InputSearch from '@shared/input-search';
import DetailsBlock from '@shared/details-block';
import { simpleToOptions } from '@utils/autocomplete-utils';
import { enDash, squareChipDeleteIcon } from '@utils/shared-utils';
import './details-datatype-search.scss';

class DetailsDataTypeSearch extends Component {
  handleListChange = values => {
    this.props.updateDashboardField('user', 'roles', values);
  };

  render() {
    const { data: { roles }, disabled, edit, label } = this.props;
    // If there's no data use a DetailsBlock component to show a dash.
    if (!edit && (!roles || roles.length === 0)) {
      return <DetailsBlock label={label} info={enDash} edit />;
    }
    return (
      <div styleName="search-container">
        <InputSearch
          autocompleteContainerStyles={autocompleteSearchStyles.normal.autocompleteContainerStyles}
          containerStyles={{
            margin: '0'
          }}
          dataTypePath={['dataTypes', 'user_roles']}
          fieldName={'user_roles'}
          onChange={(event, newValues) => {
            const values = newValues.map(value => value.value);
            this.handleListChange(values);
          }}
          label={<div styleName="search-container-title">{label}</div>}
          toOptions={simpleToOptions}
          values={roles}
          disabled={disabled || !edit}
          deleteIcon={squareChipDeleteIcon}
          squareChips
          textReadOnly
        />
      </div>
    );
  }
}

DetailsDataTypeSearch.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  edit: PropTypes.bool,
  label: PropTypes.string,
  updateDashboardField: PropTypes.func
};

const mapStateToProps = (state) => {
  const data = dashboardDetailsDataSelector(state, 'user');
  return { data };
};

export default connect(mapStateToProps, {updateDashboardField})(DetailsDataTypeSearch);
