import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import {
  utils as xlsxUtils,
  writeFile
} from 'xlsx';
import { ganttBarIconButtonStyles } from '@constants/mui-theme';
import ExcelIcon from '@icons/excel-icon';
import ActionIconButton from '@shared/action-icon-button';
import FixedSeparator from '@shared/helpers/fixed-separator';
import FlexFiller from '@shared/helpers/flex-filler';
import { buildExportRows, buildExcelFilename, ganttToArray } from '@utils/excel-utils';
import EntityFilter from './entity-filter';
import DateFilter from './date-filter';
import LocationFilter from './location-filter';
import './top-bar.scss';

class TopBar extends Component {
  onBackClick = () => {
    const { group: { id, type_name } } = this.props;
    const path = `/group/${type_name}/${id}`;
    this.props.push(path);
  };

  exportToExcel = () => {
    const { agencyTypes, group, exportRows, locationType } = this.props;
    const data = ganttToArray(agencyTypes, group, exportRows);
    const ws = xlsxUtils.aoa_to_sheet(data);
    const wb = xlsxUtils.book_new();
    xlsxUtils.book_append_sheet(wb, ws, 'SheetJS');
    const outputFilename = buildExcelFilename(locationType, group.id);
    writeFile(wb, outputFilename);
  };

  render() {
    const { locationType, group: { name } } = this.props;
    if (typeof locationType === 'undefined') {
      // If the locationType is undefined, we still didn't loaded the config,
      // wait until it's available to render the top bar, else dropdowns
      // will be initialized with no data.
      return null;
    }
    const separatorStyle = {
      minWidth: '1rem',
      width: '1rem'
    };
    const lastSeparatorStyle = {
      borderRight: '1px solid rgba(0,0,0,0.1)',
      height: '100%',
      minWidth: '1.5rem',
      width: '1.5rem'
    };
    return (
      <div styleName="gantt-top-bar">
        <ActionIconButton icon="arrow_back" click={this.onBackClick} />
        <span>Gantt chart of coordination group {name}</span>
        <FlexFiller />
        <EntityFilter />
        <FixedSeparator style={separatorStyle} />
        <LocationFilter />
        <FixedSeparator style={separatorStyle} />
        <DateFilter />
        <FixedSeparator style={lastSeparatorStyle} />
        <ActionIconButton
          click={this.exportToExcel}
          customIcon={<ExcelIcon />}
          style={ganttBarIconButtonStyles}
          tooltip="Export as xls"
        />
      </div>
    );
  }
}

TopBar.propTypes = {
  agencyTypes: PropTypes.object,
  exportRows: PropTypes.object,
  group: PropTypes.object,
  locationType: PropTypes.number,
  push: PropTypes.func
};

const mapStateToProps = state => {
  const { edit, gantt } = state.groups;
  const { group } = edit;
  const { agency_type: agencyTypes } = state.dataTypes;
  const { entity, locationType } = gantt.filters;
  const exportRows = buildExportRows(entity, locationType, edit);
  return {
    agencyTypes,
    exportRows,
    group,
    locationType
  };
};

export default connect(mapStateToProps, { push })(TopBar);
