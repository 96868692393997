// This component renders a material-ui DropDownMenu
// to use as a filter for bars (like the top bar).
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import { filterDropDownStyles } from '@constants/mui-theme';

class FilterDropDown extends Component {
  render() {
    const { children, isValueSelected, ...other } = this.props;
    const labelStyle = {
      ...filterDropDownStyles.defaultProps.labelStyle,
      ...(isValueSelected ? filterDropDownStyles.selectedLabel : {}),
      ...other.labelStyle
    };
    return (
      <DropDownMenu
        {...other}
        labelStyle={labelStyle}
      >
        {children}
      </DropDownMenu>
    );
  }
}

FilterDropDown.propTypes = {
  children: PropTypes.node,
  // Flag to apply a special style if the dropdown has any selected value.
  isValueSelected: PropTypes.bool,
  onChange: PropTypes.func,
  // When multiple=true we'll expect an array here, else a number:
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.number])
};

FilterDropDown.defaultProps = filterDropDownStyles.defaultProps;

export default FilterDropDown;
