/* eslint-disable react/jsx-no-bind */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router';
import { isEntity } from '@constants/endpoints';
import OverlapLinkIcon from '@icons/overlap-link-icon';
import ActionIconButton from '@shared/action-icon-button';
import FlexSeparator from '@shared/helpers/flex-separator';
import {
  getDashboardBackURL,
  getOverlapActivityLogURL
} from '@utils/navigation-utils';
import './top-bar.scss';

const TopBar = ({ dataType }) => {
  const dispatch = useDispatch();
  const { name } = useSelector(state => state.logs);
  const location = useLocation();

  const onBackClick = () => {
    const source = getDashboardBackURL(location, dataType);
    dispatch(push(source));
  };

  const onViewOverlap = () => {
    const pathname = getOverlapActivityLogURL(location);
    dispatch(push(
      {
        pathname,
        state: { source: location.pathname }
      }
    ));
  };

  return (
    <div styleName="activity-logs-top-bar-container">
      <ActionIconButton icon="arrow_back" click={onBackClick} />
      <span>Activity Log: {name}</span>
      <FlexSeparator />
      {isEntity(dataType) && (
        <ActionIconButton
          click={onViewOverlap}
          customIcon={<OverlapLinkIcon />}
          tooltip={<span>View<br />conflict<br />activity log</span>}
        />
      )}
    </div>
  );
};

TopBar.propTypes = {
  dataType: PropTypes.string
};

export default memo(TopBar);
