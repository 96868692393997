import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './file-preview.scss';

const GoogleFilePreview = ({ url }) => (
  <iframe
    src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
    frameBorder="0"
    styleName="pdf-previewer"
    title="google-docs-pdf-preview"
  />
);

GoogleFilePreview.propTypes = {
  url: PropTypes.string
};

const ImagePreview = ({ url }) => <img src={url} alt={url} />;

ImagePreview.propTypes = {
  url: PropTypes.string
};

class FilePreviewDisplay extends Component {
  renderDisplay() {
    const { file_content_type, signed_url } = this.props.asset;

    const mimeTypes = [
      'application/pdf', 'application/msword', 'image/x-dwg', 'text/csv', 'application/zip', 'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/octet-stream',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];

    if (mimeTypes.includes(file_content_type)) {
      return <GoogleFilePreview url={signed_url} />;
    } else if (
      file_content_type === 'image/jpeg' || file_content_type === 'image/png'
    ) {
      return <ImagePreview url={signed_url} />;
    }
    return null;
  }

  render() {
    return (
      <div styleName="file-preview-display">
        {this.renderDisplay()}
      </div>
    );
  }
}

FilePreviewDisplay.propTypes = {
  asset: PropTypes.object
};

export default FilePreviewDisplay;
