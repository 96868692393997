import React from 'react';
import TuneIcon from 'material-ui/svg-icons/image/tune';
import SearchIcon from 'material-ui/svg-icons/action/search';
import PlaceIcon from 'material-ui/svg-icons/maps/place';
import AccessTimeIcon from 'material-ui/svg-icons/device/access-time';
import { dotmapsGray, dotmapsGrayEmptyIcon } from '@constants/colors';
import { mapStylers } from '@constants/google-maps';
import {
  DETAILS_TRAY,
  FILTER_TRAY,
  RECENT_TRAY,
  SEARCH_TRAY
} from '@constants/map-trays';
import { PERMISSION_GROUP_ADMIN } from '@constants/permission';
import DetailsToggleRadioList from '@shared/details-toggle-radio-list';
import DetailLogTable from '@shared/detail-log-table';
import { isOldIE } from '@utils/browser-detect';

export const getDetailsComponent = clazz => {
  if (clazz === 'DetailsToggleRadioList') {
    return DetailsToggleRadioList;
  }
  if (clazz === 'DetailLogTable') {
    return DetailLogTable;
  }
  return null;
};

export const drawingFigureOptions = {
  strokeColor: dotmapsGray,
  fillOpacity: 0,
  editable: true,
  draggable: true,
  clickable: false
};

export const mapConfig = {
  mapOptions: {
    styles: mapStylers,
    disableDefaultUI: false,
    mapTypeControlOptions: { mapTypeIds: [] },
    fullscreenControl: false
  },
  defaultStyle: {
    clickable: false,
    strokeWeight: 3,
    fillOpacity: 0.2,
    zIndex: 5
  },
  hoverStyle: {
    inner: { clickable: false, strokeWeight: 10, zIndex: 202 },
    middle: { clickable: false, strokeWeight: 14, zIndex: 201, strokeColor: 'white' },
    outer: { clickable: false, strokeWeight: 20, zIndex: 200, strokeColor: '#406EE3', strokeOpacity: 0.6 }
  },
  polygonHoverStyle: {
    inner: { clickable: false, strokeWeight: 6, zIndex: 202, fillOpacity: 0.2},
    middle: { clickable: false, strokeWeight: 5, zIndex: 201, strokeColor: 'white', fillOpacity: 0.0 },
    outer: {
      clickable: false, strokeWeight: 8, zIndex: 200, strokeColor: '#406EE3', strokeOpacity: 0.6, fillOpacity: 0.0
    }
  },
  symbol: {
    path: 0,
    scale: 3,
    fillOpacity: 1
  },
  highlightCircleOptions: {
    clickable: false,
    draggable: false,
    editable: false,
    fillColor: '#58FF00',
    fillOpacity: 0.4,
    strokeWeight: 0,
    zIndex: 200
  },
  drawingManager: {
    drawingControl: false, // Hides the drawing tools toolbar.
    polygonOptions: drawingFigureOptions,
    circleOptions: drawingFigureOptions
  },
  streetViewOptions: {
    addressControlOptions: {
      position: 2
    }
  }
};

export const markerConfig = {
  options: {
    // The default for Google Map Markers is to have the optimized option to true,
    // but that causes old IE browsers to show an 'InvalidStateError' error.
    optimized: !isOldIE()
  }
};


// Defines groups alloed to edit a type from the dashboard detail page
export const DASHBOARD_EDIT_GROUPS = {
  agency: [PERMISSION_GROUP_ADMIN],
  user: [PERMISSION_GROUP_ADMIN],
  batch: false
};

export const trayHeaderConfig = {
  fullHeight: 235,
  scrollingHeight: 83
};

export const calendarConfig = {
  initialFilters: {
    selectedDate: new Date(),
    selectedView: 'month'
  }
};

// Map tabs config:
export const mapTabsConfig = {
  tabs: {
    filters: {
      icon: <TuneIcon />,
      name: 'filters',
      tooltip: 'Filter',
      type: FILTER_TRAY,
      value: 1
    },
    search: {
      icon: <SearchIcon />,
      name: 'search',
      tooltip: 'Search',
      type: SEARCH_TRAY,
      value: 2
    },
    details: {
      icon: <PlaceIcon />,
      name: 'results',
      tooltip: 'Identify results',
      type: DETAILS_TRAY,
      value: 3
    },
    recent: {
      icon: <AccessTimeIcon />,
      name: 'recent list',
      tooltip: 'History',
      type: RECENT_TRAY,
      value: 4
    }
  },
  tray: {
    emptyIconProps: {
      color: dotmapsGrayEmptyIcon,
      style: {
        fontSize: 72
      }
    }
  }
};

export const segmentDirections = {
  one: {
    label: 'One direction',
    value: 1
  },
  both: {
    label: 'Both directions',
    value: 2
  }
};

export const scheduleTypes = {
  oneTime: {
    label: 'One-time',
    value: 1
  },
  recurring: {
    label: 'Recurring',
    value: 2
  }
};
