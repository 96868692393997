/**
 * Data table utils for overlaps.
 */
import React from 'react';
import { isString } from 'lodash';
import { conflictStatuses } from '@constants/data-table-config';
import Badge from '@shared/badge';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import ListMore from '@utils/data-table/column-renderers/list-more';
import ConflictAgencies from '@utils/data-table/column-renderers/conflict-agencies';
import ConflictAgency from '@utils/data-table/column-renderers/conflict-agency';
import { insertCategories } from '@utils/data-table/shared';
import { renderTooltipDate } from '@utils/shared-utils';

const getBadge = status => {
  if (!status || isString(status)) {
    const badge = conflictStatuses[status];
    return {
      label: badge ? badge.label : status,
      color: badge ? badge.color : 'primary'
    };
  }
  return { label: 'Unknown', color: 'primary' };
};

export const getConflictStatus = status => {
  const badge = getBadge(status);
  return (
    <Badge label={badge.label} color={badge.color} />
  );
};

const getOwnersTooltip = owners => (
  <div>
    {owners.map((owner, index) => (
      <div key={index}>
        {owner}{index < owners.length - 1 ? ',' : ''}
      </div>
    ))}
  </div>
);

export const optimizeOverlapData = (overlap, agencies, agencyTypes, entityType) => ({
  ...overlap,
  ...insertCategories(entityType, overlap.category_dict),
  name: <TruncatedCell>{overlap.name}</TruncatedCell>,
  last_modified: renderTooltipDate(overlap.last_modified),
  owners: (
    <TruncatedCell tooltip={getOwnersTooltip(overlap.owners)}>
      <ListMore list={overlap.owners} dataType="overlap" />
    </TruncatedCell>
  ),
  status: getConflictStatus(overlap.status),
  agencies: <ConflictAgencies conflict={overlap} agencies={agencies} agencyTypes={agencyTypes} />,
  agency: <ConflictAgency conflict={overlap} agencies={agencies} agencyTypes={agencyTypes} />
});
