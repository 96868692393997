import { connect } from 'react-redux';
import * as R from 'ramda';
import { baseMapStateToProps, BaseDropDownFilter } from './base-dropdown-filter';
import { setGanttFilter } from '@actions/groups-actions';
import { getGanttConfig } from '@constants/config';

class DateFilter extends BaseDropDownFilter {
  selectionRenderer(val, menuItems) {  // eslint-disable-line no-unused-vars
    const { selected } = this.state;
    const { label } = R.values(getGanttConfig().dateUnits).find(dateUnit => dateUnit.id === selected);
    return `View by ${label}`;
  }
}

const generateItems = () => R.values(getGanttConfig().dateUnits).map(dateUnit => (
  {id: dateUnit.id, name: dateUnit.label}
));

const params = {
  lazyItems: () => generateItems(),
  name: 'dateUnit'
};

const mapStateToProps = state => baseMapStateToProps(state, params);

export default connect(mapStateToProps, { setFilter: setGanttFilter })(DateFilter);
