import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import EntityTypeIcon from '@icons/entity-type-icon';
import MoreEntityCard from '@components/shared/list-card/more-entity-card';

const DetailCard = ({ entity, moreButton }) => {

  // TODO:
  const clickAction = useCallback(() => {}, []);

  const getIcon = () => (
    <FontIcon className="material-icons"><EntityTypeIcon type={entity.type_name} /></FontIcon>
  );

  return (
    <MoreEntityCard
      selectAction={clickAction}
      deselectAction={clickAction}
      openAction={clickAction}
      entity={entity}
      icon={getIcon()}
      border={false}
      moreButton={moreButton}
    />
  );
};

DetailCard.propTypes = {
  entity: PropTypes.object,
  moreButton: PropTypes.object
};

export default DetailCard;
