import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FontIcon from 'material-ui/FontIcon';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import Toggle from 'material-ui/Toggle';
import { changeDashboardData } from '@actions/dashboard-actions';
import { dotmapsGray } from '@constants/colors';
import { detailStyles } from '@constants/mui-theme';
import { dashboardDetailsDataSelector } from '@selectors/dashboard-details-selector';
import Tooltipable from '@shared/tooltipable';
import './details-toggle-radio-list.scss';

class DetailsToggleRadioList extends Tooltipable {
  // Return the fields used in the radio group list,
  // skipping the special ones (the ones starting with '_').
  getFields = () => {
    const { fields } = this.props;
    return fields.filter(name => name[0] !== '_');
  };

  // Swap (i.e. 'toggle') the state of the main 'toggle'
  // for this component (which is composed by the boolean
  // state of the component 'fields').
  swapToggle = fields => {
    if (this.isToggleEnabled()) {
      return fields.map(field => ({[field]: false}));
    }
    return [{[fields[0]]: true}];
  };

  // Update the form:
  updateForm = (data, field, value) => {
    let newData = { ...data };
    this.props.onUpdate(field, value);
    // If we are enabling any radio button,
    // turn all associated fields off.
    if (value === true) {
      const { dataType, radioAssocFields } = this.props;
      radioAssocFields.forEach(assocField => {
        this.props.onUpdate(`${dataType}.${assocField}`, false);
        newData = { ...newData, [assocField]: false };
      });
    }
    return newData;
  };

  // eslint-disable-next-line no-unused-vars
  onToggle = event => {
    const { data, dataType } = this.props;
    const fields = this.getFields();

    const newToggleState = this.swapToggle(fields);
    let newData = { ...data };
    newToggleState.forEach(field => {
      newData = { ...newData, ...field };
      const updateFieldName = Object.keys(field)[0];
      newData = this.updateForm(newData, `${dataType}.${updateFieldName}`, newData[updateFieldName]);
    });
    this.props.changeDashboardData(dataType, newData);
  };

  onRadioChange = (event, value) => {
    const { data, dataType } = this.props;
    const fields = this.getFields();
    let newData = { ...data };

    if (value === '_all') {
      // Special field, means to set all.
      fields.forEach(field => {
        newData = { ...newData, [field]: true };
        newData = this.updateForm(newData, `${dataType}.${field}`, true);
      });
    } else {
      // Else, turn off all fields, but the one
      // we clicked.
      fields.forEach(field => {
        if (field === value) {
          newData = { ...newData, [field]: true };
          newData = this.updateForm(newData, `${dataType}.${field}`, true);
        } else {
          newData = { ...newData, [field]: false };
          newData = this.updateForm(newData, `${dataType}.${field}`, false);
        }
      });
    }

    this.props.changeDashboardData(dataType, newData);
  };

  isToggleEnabled = () => {
    const { data } = this.props;
    const fields = this.getFields();

    // If any field is enabled, then the toggle is enabled:
    return fields.some(field => data[field]);
  };

  // Returns the field name of the selected radio button.
  getSelectedRadioButton = () => {
    const { data } = this.props;
    const fields = this.getFields();

    // If all fields are set, return the special '_all' field
    // to flag all fields are selected, thus that 'all' option
    // must be the one set to true.
    if (fields.every(field => data[field])) {
      return '_all';
    }
    // Else return the first field that is set.
    return fields.find(field => data[field]);
  };

  render() {
    const { fieldLabels, fields, label, name, tooltip } = this.props;
    const toggleEnabled = this.isToggleEnabled();
    return (
      <div styleName="details-toggle-radio-list-container">
        <div styleName="toggle-container">
          <Toggle
            label={label}
            labelPosition="right"
            name={name}
            toggled={toggleEnabled}
            onToggle={this.onToggle}
            {...detailStyles.forms.toggle}
          />
          <FontIcon
            className="material-icons"
            onMouseEnter={this.hoverOn}
            onMouseLeave={this.hoverOff}
            hoverColor={dotmapsGray}
            styleName="icon"
            style={{fontSize: '1.25rem'}}
          >
            help
          </FontIcon>
          {this.renderTooltip(<div styleName="details-toggle-radio-list-tooltip">{tooltip}</div>)}
        </div>
        <div styleName="radio-group-container">
          <RadioButtonGroup
            name={`${name}_radio`}
            defaultSelected={toggleEnabled ? fields[0] : null}
            valueSelected={this.getSelectedRadioButton()}
            onChange={this.onRadioChange}
            {...detailStyles.forms.radioGroup}
          >
            {fields.map((field, index) => (
              <RadioButton
                disabled={!toggleEnabled}
                key={`radio_${field}_${index}`}
                value={field}
                label={fieldLabels[index]}
                {...detailStyles.forms.radio}
              />
            ))}
          </RadioButtonGroup>
        </div>
      </div>
    );
  }
}

DetailsToggleRadioList.propTypes = {
  changeDashboardData: PropTypes.func,
  data: PropTypes.object,
  dataType: PropTypes.string,
  fieldLabels: PropTypes.array,
  fields: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onUpdate: PropTypes.func,
  radioAssocFields: PropTypes.array,
  tooltip: PropTypes.string
};

DetailsToggleRadioList.defaultProps = {
  tooltipStyle: {}  // Override (actually reset) the default 'Tooltipable' style,
                    // and use our own, inside the tooltip.
};

const mapStateToProps = (state, props) => {
  const { dataType } = props;
  const data = dashboardDetailsDataSelector(state, dataType);
  return { data };
};

export default connect(mapStateToProps, {changeDashboardData})(DetailsToggleRadioList);
