import { createSelector } from 'reselect';
import * as R from 'ramda';
import { includes, toString } from 'lodash';
import { PERMISSION_GROUP_APP_ADMIN, PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER } from '@constants/permission';
import { getDataTypes } from '@selectors/data-types-selector';
import { optimizeAgencyData } from '@utils/data-table/agency';
import { optimizeBatchUploadData } from '@utils/data-table/batch';
import { optimizeEntityData } from '@utils/data-table/entity';
import { optimizeGroupData } from '@utils/data-table/group';
import { optimizeOverlapData } from '@utils/data-table/overlap';
import { optimizeUserData } from '@utils/data-table/user';
import {
  optimizeActivityData,
  optimizeCycleData,
  optimizeTaskData,
  optimizeTaskTypeData,
  optimizeWorkflowData
} from '@utils/data-table/workflow';

const getRows = (type, subType) => state => {
  const rows = state.dataTables.rows[type];
  if (subType && subType !== type) {
    if (rows) {
      return rows[subType];
    }
    return null;
  }
  return rows;
};
export const getAgencies = state => state.dataTypes.agency;
export const getAgencyTypes = state => state.dataTypes.agency_type;
const getUsers = state => state.dataTypes.user;
const getPermission = state => state.dataTypes.permission;
const getPropsDataType = (state, props) => props.dataType;
const getPropsSubType = (state, props) => props.subType;
const getPropsFilters = (state, props) => props.filters;
const getDataTypeFilter = (state, props) => state.dataTables.filters[props.dataType];
const getDataTables = state => state.dataTables;

export const optimizeRowsForDataTable = (type, subType, embedded) =>
  createSelector(
    getRows(type, subType),
    getAgencyTypes,
    getAgencies,
    getDataTypes,
    (rows, agencyTypes, agencies, dataTypes) => {
      if (!rows) {
        return null;
      }
      switch (type) {
      case 'agency':
        return rows.map(row => optimizeAgencyData(row, agencyTypes));
      case 'group':
        return rows.map(row => optimizeGroupData(row));
      case 'overlap':
        return rows.map(row => optimizeOverlapData(row, agencies, agencyTypes, subType));
      case 'user':
        return rows.map(row => optimizeUserData(row, agencies, dataTypes.user_roles));
      case 'batch':
        return rows.map(row => optimizeBatchUploadData(row, agencies));
      case 'task':
        return rows.map(row => optimizeTaskData(row, agencyTypes));
      case 'activity':
        return rows.map(row => optimizeActivityData(row, agencyTypes, dataTypes.user_roles, dataTypes.task_status));
      case 'cycle':
        return rows.map(row => optimizeCycleData(row));
      case 'task_type':
        return rows.map(row => optimizeTaskTypeData(row));
      case 'workflow':
        return rows.map(row => optimizeWorkflowData(row));
      default:
        return rows.map(row => optimizeEntityData(row, type, agencyTypes, embedded));
      }
    }
  );

const selectAgencyIsAdmin = R.pickBy(agencyItem => agencyItem.is_admin === true);
const getAdminAgencyKeys = createSelector([getAgencies], agencies => R.keys(selectAgencyIsAdmin(agencies)));
export const getAdminAgencyUsers = createSelector(
  [getAdminAgencyKeys, getUsers],
  (agencies, users) => R.pickBy(userItem => includes(agencies, toString(userItem.agency)), users)
);

const selectPermissionAdminManager = R.pickBy(permissionItem => (includes(
  [PERMISSION_GROUP_APP_ADMIN, PERMISSION_GROUP_ADMIN, PERMISSION_GROUP_MANAGER],
  permissionItem.name,
  permissionItem
)));
const getAdminManagerPermissionKeys = createSelector(
  [getPermission], permissions => R.keys(selectPermissionAdminManager(permissions))
);
export const getAdminAgencyAdminAndManagerUsers = createSelector(
  [getAdminManagerPermissionKeys, getAdminAgencyUsers],
  (permissions, users) => R.pickBy(userItem => includes(permissions, toString(userItem.permission)), users)
);

export const getFiltersSelector = createSelector(
  [getPropsDataType, getPropsSubType, getPropsFilters, getDataTypeFilter],
  (dataType, subType, propsFilters, dataTypeFilter) => {
    let filters = propsFilters || dataTypeFilter;
    if (filters && subType && subType !== dataType) {
      filters = filters[subType];
    }

    return filters;
  }
);

// Returns the specified 'key' from the Redux store for dataTables,
// taking care of the dataType and its subType (if it exists).
const getDataTableValues = (dataTables, key, dataType, subType, defaultIfEmpty) => {
  const values = dataTables[key][dataType];
  if (subType && dataType !== subType) {
    if (values) {
      return values[subType] || defaultIfEmpty;
    }
    return defaultIfEmpty;
  }
  return values || defaultIfEmpty;
};

export const getDataTableColumns = createSelector(
  [getDataTables, getPropsDataType, getPropsSubType],
  (dataTables, dataType, subType) => getDataTableValues(dataTables, 'columns', dataType, subType, [])
);

export const getCount = createSelector(
  [getDataTables, getPropsDataType, getPropsSubType],
  (dataTables, dataType, subType) => getDataTableValues(dataTables, 'count', dataType, subType, 0)
);

export const getFilters = createSelector(
  [getDataTables, getPropsDataType, getPropsSubType],
  (dataTables, dataType, subType) => getDataTableValues(dataTables, 'filters', dataType, subType, {})
);

export const getLoader = createSelector(
  [getDataTables, getPropsDataType, getPropsSubType],
  (dataTables, dataType, subType) => getDataTableValues(dataTables, 'loader', dataType, subType, false)
);
