/* eslint-disable react/jsx-no-bind */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import memoize from 'memoize-one';
import buffer from '@turf/buffer';
import lineIntersect from '@turf/line-intersect';
import lineOverlap from '@turf/line-overlap';
import { multiLineString } from '@turf/helpers';
import { Marker } from '@react-google-maps/api';
import * as colors from '@constants/colors';
import { markerConfig } from '@constants/component-configs';
import { detailCircleStyle, detailLineStyle, detailPolyLineStyle } from '@constants/data-detail';
import { getEntityMarker } from '@utils/icon-utils';
import {
  parseCenterTuple,
  zipCoordinatePair
} from '@utils/map-utils';
import MapFigure from './map-figure';

class ViewSegment extends PureComponent {
  constructor() {
    super();
    this.getEntityMarker = memoize(getEntityMarker);
  }

  onMarkerClick = position => {
    const { onSegmentClick, segment } = this.props;
    const center = zipCoordinatePair([position.latLng.lng(), position.latLng.lat()]);
    onSegmentClick(segment.entity, center);
  };

  isActive = () => {
    const { segment, selectedSegmentIds } = this.props;
    return includes(selectedSegmentIds, segment.id);
  };

  buildColor = color => ({
    fillColor: color,
    strokeColor: color
  });

  getOverlapOptions = () => {
    const { segment } = this.props;
    const options = {
      fillColor: colors.dotmapsSegmentColor,
      strokeColor: colors.dotmapsSegmentColor,
      strokeWeight: this.isActive() ? 4 : 5,
      zIndex: this.isActive() ? 211 : 10
    };
    const { isLead, isOpen, isOpportunity, isPending, isResolved } = segment.overlapType;
    if (isLead) {
      return { ...options, ...this.buildColor(colors.dotmapsOverlapLead), zIndex: 210 };
    }
    if (isOpen) {
      return { ...options, ...this.buildColor(colors.dotmapsOverlapOpen) };
    }
    if (isOpportunity) {
      return { ...options, ...this.buildColor(colors.dotmapsOverlapOpportunity) };
    }
    if (isPending) {
      return { ...options, ...this.buildColor(colors.dotmapsOverlapPending) };
    }
    if (isResolved) {
      return { ...options, ...this.buildColor(colors.dotmapsOverlapResolved) };
    }
    return { ...options };
  };

  getStandardOptions = () => {
    const options = { ...detailCircleStyle, ...detailLineStyle, ...detailPolyLineStyle };
    if (this.isActive()) {
      // Show a different color for the current selected segment.
      return { ...options, ...this.buildColor('#0039a8'), zIndex: 210 };
    }
    return { ...options };
  };

  getOptions = () => {
    const { isOverlap } = this.props;
    if (isOverlap) {
      return this.getOverlapOptions();
    }
    return this.getStandardOptions();
  };

  getMode = () => {
    const { segment } = this.props;
    const { isOpen, isOpportunity, isPending, isResolved } = segment.overlapType;
    if (isOpen) {
      return 'conflict';
    }
    if (isOpportunity) {
      return 'opportunity';
    }
    if (isPending) {
      return 'pending';
    }
    if (isResolved) {
      return 'resolved';
    }
    return null;
  };

  getIcon = () => {
    const { segment } = this.props;
    const { iconId, type_name } = segment.entity;
    const active = this.isActive();
    return this.getEntityMarker(
      segment.agency_type,
      type_name,
      iconId,
      active,
      this.getMode()
    );
  };

  toLineString = points => {
    // Convert to a LineString for rendering:
    const coords = points[0].geometry.coordinates;
    return {
      coordinates: [
        coords,
        coords
      ],
      type: 'LineString'
    };
  };

  toMultiSegments = segments => {
    const shapes = [];
    segments.forEach(({ shape }) => {
      if (shape.type === 'Point') {
        shapes.push([shape.coordinates, shape.coordinates]);
      } else
        if (shape.type === 'Polygon') {
          shape.coordinates.map(coords => shapes.push(coords));
        } else
          if (shape.type === 'LineString') {
            shapes.push(shape.coordinates);
          }
    });

    return multiLineString(shapes);
  };

  // Get the overlapping shape between this segment and the main entity one.
  getOverlappingShape = () => {
    const { segment } = this.props;
    const { mainEntitySegments } = segment;
    const multiSegment1 = this.toMultiSegments(mainEntitySegments);
    const multiSegment2 = this.toMultiSegments([segment]);

    // Check if an exact line match exists:
    const geometries = lineOverlap(multiSegment1, multiSegment2, { tolerance: 0.000001 }).features;
    if (geometries.length > 0) {
      return geometries[0].geometry;
    }

    // Else check for point intersections:
    const points = lineIntersect(multiSegment1, multiSegment2).features;
    if (points.length > 0) {
      return this.toLineString(points);
    }

    // It's not matching anything, buffer points and try with point intersections again:
    const bufferedMS1 = buffer(multiSegment1, 0.1, { units: 'meters' });
    const bufferedMS2 = buffer(multiSegment2, 0.1, { units: 'meters' });
    const bufferedPoints = lineIntersect(bufferedMS1, bufferedMS2).features;
    if (bufferedPoints.length > 0) {
      return this.toLineString(bufferedPoints);
    }

    return null;
  };

  renderFigure = (id, shape, options) => (
    <MapFigure
      key={`f-${id}`}
      onClick={this.onMarkerClick}
      options={options}
      shape={shape}
    />
  );

  renderSegment = () => {
    const { highlightGeometry, isOverlap, segment } = this.props;
    const { shape } = segment;
    const options = this.getOptions();
    if (this.isActive() && isOverlap) {
      // Render 'glow' effect for overlap active segments:
      const middle = { strokeWeight: 6, zIndex: 208, strokeColor: 'white' };
      const outer = { strokeWeight: 12, zIndex: 207, strokeOpacity: 0.6, strokeColor: colors.dotmapsOverlapLead };
      const lines = [
        this.renderFigure(segment.id, shape, {...options}),
        this.renderFigure(`${segment.id}-m`, shape, {...options, ...middle}),
        this.renderFigure(`${segment.id}-o`, shape, {...options, ...outer})
      ];
      // Render "overlapping geometry":
      const { isLead } = segment.overlapType;
      if (!isLead && highlightGeometry) {
        const overlapping = {
          fillColor: colors.dotmapsOverlapOverlapping,
          strokeColor: colors.dotmapsOverlapOverlapping,
          strokeOpacity: 0.6,
          strokeWeight: 22,
          zIndex: 220
        };
        const overlappingShape = this.getOverlappingShape();
        // eslint-disable-next-line max-depth
        if (overlappingShape) {
          lines.push(this.renderFigure(`${segment.id}-l`, overlappingShape, {...options, ...overlapping}));
        }
      }
      return lines;
    }
    return this.renderFigure(segment.id, shape, options);
  };

  renderMarker = () => {
    const { segment } = this.props;
    const icon = this.getIcon();
    if (icon && icon.url && segment.showMarker && segment.center) {
      const segmentCenter = parseCenterTuple(segment.center);
      const key = `m-${segment.id}`;
      return (
        <Marker
          icon={icon}
          key={key}
          onClick={this.onMarkerClick}
          position={segmentCenter}
          options={markerConfig.options}
        />
      );
    }
    return null;
  };

  render() {
    const { segment } = this.props;
    return (
      <div key={segment.id}>
        {this.renderMarker()}
        {this.renderSegment()}
      </div>
    );
  }
}

ViewSegment.propTypes = {
  highlightGeometry: PropTypes.bool,
  isOverlap: PropTypes.bool,
  onSegmentClick: PropTypes.func,
  segment: PropTypes.object,
  selectedSegmentIds: PropTypes.array
};

export default ViewSegment;
