/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import NotificationItemsGroup from './notification-items-group';
import { clearNotifications, fetchNotifications } from '@actions/notifications-actions';
import { dotmapsBlue } from '@constants/colors';
import SwitchControl from '@shared/form/switch-control';
import DotmapsLoader from '@shared/dotmaps-loader';
import { reachingTableEnd } from '@utils/data-table-utils';
import { groupNotifications, notificationGroups } from '@utils/notification-utils';
import './notifications-list.scss';

const NotificationsList = () => {
  const dispatch = useDispatch();
  const { list: notifications, loading, next, unread } = useSelector(state => state.notifications);
  const [headerLabel, setHeaderLabel] = useState(null);
  const [showUnread, setShowUnread] = useState(false);
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (notifications.length === 0) {
      dispatch(fetchNotifications());
    }
    if (unread > 0) {
      dispatch(fetchNotifications(next));
      dispatch(clearNotifications());
    }
  }, [dispatch, next, notifications.length, unread]);

  const getNotifications = () => {
    const notificationList = Object.values(notifications);
    if (showUnread) {
      return notificationList.filter(notification => !notification.read);
    }
    return notificationList;
  };

  const renderNotifications = () => {
    const filteredNotifications = getNotifications();
    const groupedNotifications = groupNotifications(filteredNotifications);
    return groupedNotifications.map((notificationList, index) => (
      <NotificationItemsGroup key={index} index={index} notifications={notificationList} />
    ));
  };

  // Get the height of any notification header to set it to sticky if
  // we scrolled past that height:
  const getItemGroupHeaderHeight = () => {
    const headers = document.querySelectorAll('[id^="items-group-header-0"]');
    if (headers && headers.length > 0) {
      // We only need the header height, no matter the header,
      // since all have the same height:
      return headers[0].offsetHeight;
    }
    // Return null if there's no header (i.e. all are within 'EARLIER'
    // so the header is omitted, thus there's no sticky header functionality).
    return null;
  };

  const onScroll = event => {
    // Check if there's any group item header set (i.e. TODAY, YESTERDAY or EARLIER):
    const headerHeight = getItemGroupHeaderHeight();
    const scrollTop = event.target.scrollTop;
    if (headerHeight) {
      // If we have headers, check if we scrolled past the first one:
      const newSticky = scrollTop > headerHeight;
      // And set the sticky flag (if it didn't changed):
      if (newSticky !== sticky) {
        setSticky(newSticky);
      }
      let currentHeaderLabel = null;
      Object.values(notificationGroups).forEach(item => {
        const itemGroupElement = event.target.querySelector(`#items-group-header-${item.dayGroup}`);
        if (itemGroupElement) {
          const itemGroupPosition = itemGroupElement.offsetTop + headerHeight;
          if (itemGroupPosition <= scrollTop) {
            currentHeaderLabel = item.label;
          }
        }
      });
      if (currentHeaderLabel !== headerLabel) {
        setHeaderLabel(currentHeaderLabel);
      }
    }
    if (reachingTableEnd(event) && !loading && next) {
      dispatch(fetchNotifications(next));
    }
  };

  const renderHeader = () => {
    // If we scrolled past the item group header height, set that header as sticky:
    if (headerLabel && sticky) {
      return headerLabel;
    }
    return 'Notifications';
  };

  return (
    <Fragment>
      <div styleName="header">
        <div styleName={`title ${sticky ? 'title-sticky' : ''}`}>
          {renderHeader()}
        </div>
        <div styleName="toggle">
          <SwitchControl label="Show unread only" onChange={setShowUnread} value={showUnread} />
        </div>
      </div>
      <div styleName="list-container" onScroll={onScroll}>
        {!isEmpty(notifications) && renderNotifications()}
        {!loading && !isEmpty(notifications) && (
          <div styleName="last-row">
            <div styleName="label">
              That's all your notifications from the last 30 days.
            </div>
          </div>
        )}
        {!loading && isEmpty(notifications) && (
          <div styleName="row-empty">
            No notifications from the last 30 days.
          </div>
        )}
        {loading && (
          <div styleName="notifications-spinner">
            <DotmapsLoader color={dotmapsBlue} display={loading} />
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default NotificationsList;
