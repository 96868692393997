import React from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
import FontIcon from 'material-ui/FontIcon';
import {
  BaseTray,
  baseTrayMapDispatchToProps,
  baseTrayMapStateToProps
} from '@components/map/tray/base-tray';
import ListItem from '@components/map/tray/recent/list-item';
import { mapTabsConfig } from '@constants/component-configs';
import '../tray.scss';

class Recent extends BaseTray {
  render() {
    const { isRecentTab, trayRecent } = this.props;
    if (!isRecentTab) {
      return null;
    }
    return (
      <div styleName="map-tray-body-wrapper">
        {R.isEmpty(trayRecent) && (
          <div styleName="map-tray-body-empty map-tray-body-shadow">
            <FontIcon
              className="material-icons"
              {...mapTabsConfig.tray.emptyIconProps}
            >schedule</FontIcon>
            <div styleName="map-tray-body-empty-content">
              Recently selected markers will<br />appear here.
            </div>
          </div>
        )}
        {!R.isEmpty(trayRecent) && (
          <div styleName="map-tray-body">
            {trayRecent.map(item => <ListItem item={item} key={item.id} />)}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const props = baseTrayMapStateToProps(state);
  const { trayRecent } = state.map;
  const isRecentTab = state.map.currentTab === mapTabsConfig.tabs.recent.value;
  return {
    trayRecent,
    isRecentTab,
    ...props
  };
};

export default connect(mapStateToProps, baseTrayMapDispatchToProps)(Recent);
