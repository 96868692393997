/* eslint-disable max-depth */
/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import {
  getWorkflowCustomFieldSections
} from '@constants/config';
import { detailEdit } from '@constants/mui-theme';
import {
  ADMIN_DETAILS_FIELDS,
  DETAILS_FIELDS,
  RESPONSE_FIELDS,
  TASK_TYPE_FIELD_TYPE_ICON,
  VIEW_TYPES
} from '@constants/workflow';
import { getConfigPathOr } from '@utils/config-utils';
import {
  canAccessWorkflow,
  canRespondTaskActivity,
  getAgencyId,
  getUserId,
  isAppAdmin,
  verifyPermissions
} from '@utils/permission-utils';

// Get the custom fields list for the specified task type:
const getTaskCustomFields = (taskTypes, taskTypeId) => {
  const { custom_fields } = taskTypes[taskTypeId];
  return custom_fields;
};

// From the specified fields list and section, check if the task type's
// custom fields are for this section and add them to the list of fields:
export const addCustomFields = (fields, section, taskType) => {
  const { custom_fields } = taskType;
  if (custom_fields) {
    return [
      ...fields,
      ...custom_fields.filter(field => field.section === section.id)
    ];
  }
  return fields;
};

// Updates the order field in the specified list if the overrideItem's order
// changed.
const updateOverrideItem = (list, overrideItem) => {
  const index = list.findIndex(item => item.id === overrideItem.id);
  if (index !== -1) {
    const oldOrder = list[index].order;
    const newOrder = overrideItem.order;
    // Only change the order if it changed in the overriden item:
    if (oldOrder !== newOrder) {
      let newList = [...list];
      // If the order of a field changed, we must change the order
      // of all items in the list.
      if (oldOrder > newOrder) {
        newList = [...newList.map(item => {
          if (item.order < oldOrder && item.order >= newOrder) {
            return { ...item, order: item.order + 1 };
          }
          return { ...item };
        })];
      } else {
        newList = [...newList.map(item => {
          if (item.order > oldOrder && item.order <= newOrder) {
            return { ...item, order: item.order - 1 };
          }
          return { ...item };
        })];
      }
      // Override the order on the moved element:
      newList[index] = {
        ...newList[index],
        order: overrideItem.order
      };
      return newList;
    }
  }
  return list;
};

// Return the task fields for the specified section:
export const getTaskFields = (section, taskType, overrideField = null, admin = false) => {
  let fields = [];

  // The details section has the hardcoded core fields:
  if (section.name === 'Details') {
    if (admin) {
      // For the admin page, prepend the 'task name' field:
      fields = ADMIN_DETAILS_FIELDS;
    } else {
      fields = DETAILS_FIELDS;
    }
  } else
    // Under the admin page, display all response fields:
    if (section.name === 'Response') {
      fields = RESPONSE_FIELDS;
    }

  // Add any custom fields:
  fields = addCustomFields(fields, section, taskType);

  // Override the field order (used when waiting for an API call to refresh the screen).
  if (overrideField) {
    fields = updateOverrideItem(fields, overrideField);
  }

  // Return fields sorted by the 'order' property:
  return fields.sort((a, b) => (a.order > b.order) ? 1 : -1);  // eslint-disable-line id-length
};

// Get the max 'order' from the list of fields.
export const getFieldsMaxOrder = fields => Math.max(0, ...fields.map(({ order }) => order).filter(Boolean));

// Returns the name to use for each section on the task type edit page
// to name fields:
export const getTaskSectionTitleFieldName = section => {
  if (section.name === 'Details') {
    return 'Context fields';
  }
  return `${section.name} fields`;
};

// Validate activity response form fields:
export const validateResponseForm = activity => {
  const errors = [];
  ['assignee', 'response'].forEach(field => {
    if (!activity[field]) {
      errors.push({
        field,
        value: 'This field is required'
      });
    }
  });

  return errors;
};

// Validates that all required fields are filled:
export const validateForm = (data, taskTypes, taskTypeId) => {
  const custom_fields = getTaskCustomFields(taskTypes, taskTypeId);
  const sections = getWorkflowCustomFieldSections();
  const errors = [];

  // Validate required core fields:
  ['name', 'owner', 'activities', 'roles'].forEach(field => {
    if (!data[field]) {
      errors.push({
        field,
        value: 'This field is required'
      });
    }
  });

  // Validate custom fields from all sections but the response one:
  sections.forEach(section => {
    if (section.name === 'Response') {
      // Don't validate the response section, since that's empty for the task, it has
      // to be valid for each activity.
      return;
    }

    const customFields = custom_fields.filter(custom_field => custom_field.section === section.id);
    // For each field defined for this task type:
    customFields.forEach((customField, index) => {
      if (customField.required) {
        if (data[section.id]) {
          const { field_values } = data[section.id];
          // If the field is required, find it in the form's values:
          const field = field_values.find(field_value => field_value && field_value.field === customField.id);
          if (field) {
            const { value } = field;
            if (value) {
              // If the value is not empty, then it's valid (since we only validate for required fields):
              return;
            }
          }
        }
        // Else, flag the error, since this field is required:
        errors.push({
          field: `${section.id}.field_values.${index}.field`,
          value: 'This field is required'
        });
      }
    });
  });

  return errors;
};

// Format custom fields values, based on their data type:
const formatValue = value => {
  // Format the date if it's a date object:
  if (moment.isMoment(value)) {
    return moment(value).format('YYYY-MM-DD hh:mm A');
  }
  // Fallback, convert to str:
  return String(value);
};

// Convert the 'value' field of custom fields to str.
const valuesToString = fieldValues => fieldValues.map(({ field, value, ...other }) => ({
  field,
  value: formatValue(value),
  ...other  // 'other' may contain 'id' and 'task' (when it's an update, or nothing on task creation).
}));

// The custom field values are grouped by section in the Redux store, but we need
// to consolidate them into a single 'field_values' field to submit it to the API.
export const buildFieldValues = data => {
  let values = [];
  Object.keys(data).forEach(key => {
    // If the property is a number process the section (non-number are NaN and sections
    // starts with the number 1).
    if (parseInt(key, 10) > 0) {
      const { field_values } = data[key];
      if (field_values) {
        values = [...values, ...valuesToString(field_values.filter(Boolean))];
      }
    }
  });
  return values;
};

// Convert the list of segments into a list of segment ids:
export const buildSegmentIds = data => {
  if (data) {
    let segments = [];
    // It's a task related to an entity, all segments are on the base data object:
    if (data.segments && data.segments.length > 0) {
      segments = data.segments;
    }
    // Else it's a task related to a group, include all segments from all entities:
    const { entities } = data;
    if (entities && entities.length > 0) {
      segments = [].concat(...entities.map(entity => entity.segments));
    }
    return segments.map(segment => ({ id: segment.id }));
  }
  return [];
};

// Filter a list of entities by the specified entity ids:
export const filterSelectedEntities = (entities, entityIds) => entities.filter(entity => entityIds.includes(entity.id));

// Filter statuses to know which ones are allowed based on the current selected
// status and permissions.
const filterStatus = (currentStatus, statusItem, viewType) => {
  // Always include the current status.
  if (statusItem.name === currentStatus) {
    return true;
  }

  // Owners can only transition to 'Completed' or 'Assigned' from the 'Submitted' status.
  if (viewType === VIEW_TYPES.owner || viewType === VIEW_TYPES.all) {
    if (
      currentStatus === 'Submitted' && (
        statusItem.name === 'Completed' || statusItem.name === 'Assigned'
      )
    ) {
      return true;
    }
    // Owners can't transition to other states.
    return false;
  }

  // Responders can only transition to 'In progress' or 'Submitted'.
  if (viewType === VIEW_TYPES.agency || viewType === VIEW_TYPES.assignee) {
    // We can only transition into 'In progress' or 'Submitted' if we are in the 'Assigned' or 'In progress' state.
    if (
      (currentStatus === 'Assigned' || currentStatus === 'In progress') &&
      (statusItem.name === 'In progress' || statusItem.name === 'Submitted')
    ) {
      return true;
    }
  }

  // Disallow any other transition.
  return false;
};

// Filter the list of specified statuses based on the current status and user permissions.
export const getTaskStatuses = (activityStatus, taskStatuses, viewType) => {
  const statuses = Object.values(taskStatuses);
  return statuses.filter(statusItem => filterStatus(activityStatus, statusItem, viewType));
};

export const findActivity = (activityId, activities) => activities.find(activity => activity.id === parseInt(activityId, 10));

export const getTasks = (cycle, viewType) => {
  const { tasks } = cycle;
  if (viewType === VIEW_TYPES.agency || viewType === VIEW_TYPES.assignee) {
    return tasks.filter(task => task.activities.some(activity => {
      // When retrieving the list of tasks, if the view type is agency or assignee, it should
      // behave the same and filter always all tasks to include the ones from the
      // user's agency:
      if (activity.agency === getAgencyId()) {
        return true;
      }
      // Check if the current user has no agency (and he's a superuser), in that case
      // retrieve all the ones assigned to him:
      if (!getAgencyId() && isAppAdmin() && activity.assignee === getUserId()) {
        return true;
      }
      // Else exclude:
      return false;
    }));
  }
  return tasks;
};

export const isPastDue = dueDate => {
  if (dueDate) {
    const today = moment().toDate();
    return moment(dueDate).isBefore(today);
  }
  return false;
};

export const isLate = (activity, taskDueDate) => {
  // If the 'is_late' flag is set on the activity, it's late.
  // (it means it was late when a status transition was made, so
  // we always must show the late label).
  if (activity.is_late) {
    return true;
  }
  // Else the flag is not set, thus check if the status is 'Completed',
  // a completed activity when it was not late, should not display
  // the late label, since it was completed on time.
  if (activity.status_name === 'Completed') {
    return false;
  }
  // For other statuses, if the task's due date is past due, then it's late.
  return isPastDue(taskDueDate) || isPastDue(activity.task_due_date);
};

export const findTaskStatus = (statusName, taskStatuses) =>
  Object.values(taskStatuses).find(taskStatus => taskStatus.name === statusName);

export const isOwnerView = viewType => !viewType || viewType === VIEW_TYPES.all || viewType === VIEW_TYPES.owner;

// Returns the default activity for the specified task.
export const getDefaultActivity = (task, taskActivityId, viewType) => {
  if (task && task.activities.length > 0) {
    const { activities } = task;
    const agencyId = getAgencyId();
    const userId = getUserId();
    let activity = null;

    // If we are retrieving the default activity for the current task in the URL
    // and the activity id is present, then just return that activity.
    if (taskActivityId) {
      activity = activities.find(act => act.id === parseInt(taskActivityId, 10));
      if (typeof activity !== 'undefined') {
        return activity;
      }
    }

    // In the owner view we can see any activity, but we display a single one
    // if there's only one.
    if (isOwnerView(viewType) && activities.length === 1) {
      return activities[0];
    }

    // For the agency/assignee views:
    if (viewType === VIEW_TYPES.agency || viewType === VIEW_TYPES.assignee) {
      activity = activities.find(act => {
        // If we are the assignee, always retrieve that activity:
        if (act.assignee === userId) {
          return true;
        }
        // Else check if the activity matches our agency (there's always zero
        // or one per task).
        if (act.agency === agencyId) {
          return true;
        }
        return false;
      });
      if (typeof activity !== 'undefined') {
        return activity;
      }
      // If it's not found, it means the user hacked the URL.
      return null;
    }

  }
  return null;
};

// Returns a generated 'viewType' for the specified task.
//
// When viewType is specified it has precedence and must be used,
// but when it's not defined, we can calculate it with this method
// to display contents in the most accurate way.
const getViewType = (task, activity) => {
  const userId = getUserId();

  if (task.owner === userId) {
    return VIEW_TYPES.owner;
  }

  if (canRespondTaskActivity(activity, task.roles)) {
    return VIEW_TYPES.assignee;
  }

  if (activity.agency === getAgencyId()) {
    return VIEW_TYPES.agency;
  }

  return null;
};

// Returns the specified viewType if it's not null or generate one
// based on the task and activity.
const getViewTypeOrGenerate = (viewType, task, activity) => {
  if (viewType) {
    return viewType;
  }
  return getViewType(task, activity);
};

// Like getViewTypeOrGenerate() but to use in building an activity URL
// (if there's a view type, it returns a string with a slash to append to an URL).
export const getViewTypeOrGenerateForURL = (viewType, task, activity) => {
  const generatedViewType = getViewTypeOrGenerate(viewType, task, activity);
  if (generatedViewType) {
    return `/${generatedViewType}`;
  }
  return '';
};

export const goToDefaultTask = (task, viewType, replace) => {
  const pathParts = [
    'cycle', task.cycle, 'task', task.id
  ];

  if (task.activities.length > 0) {
    let activity = getDefaultActivity(task, null, viewType);
    if (!activity) {
      activity = task.activities[0];
    }

    if (activity) {
      pathParts.push('activity');
      pathParts.push(activity.id);
      const generatedViewType = getViewTypeOrGenerate(viewType, task, activity);
      if (generatedViewType) {
        pathParts.push(generatedViewType);
      }
    }
  }

  // Don't set "state: { source }" in the push() call for task links,
  // else, we'll be populating the source and the top-bar's back arrow
  // link will navigate us through all tasks instead of going directly
  // to the cycle listing page:
  //
  // const source = this.props.location.pathname;
  replace({
    pathname: `/${pathParts.join('/')}`,
    state: { clear: true }
  });
};

// Returns the task assigned 'to' field for rendering activity log messages.
export const buildAssignedTaskTo = attrs => {
  if (attrs.assignee_name) {
    return `${attrs.assignee_name} (${attrs.assignee_agency})`;
  }
  if (attrs.roles) {
    return <span>{attrs.agency} &ndash; {attrs.roles.join(', ')}</span>;
  }
  return attrs.agency;
};

export const getFirstWorkflowMenuOption = () => {
  // Can't reuse getDashboardMenuConfig() here, since for the second element in the array
  // we need it to always be 'workflow':
  const menuConfig = getConfigPathOr(['dashboard', 'workflow', 'menu']);
  if (menuConfig && menuConfig.length > 0) {
    const { items } = menuConfig[0];
    const allowedItems = items.filter(item => verifyPermissions(item.permissions));
    if (allowedItems.length > 0) {
      const { type, subtype } = allowedItems[0];
      return `/workflow/${type}/${subtype}`;
    }
  }
  // Default to the owner view:
  return '/workflow/task/owner';
};

// Tels if a user can be mentioned on an activity.
const canBeMentioned = (user, task, activity) => {
  // Check if the user's agency is the same one as the activity one:
  if (user.agency === activity.agency) {
    return true;
  }

  // Check if the user's agency is the same as the task's owner one:
  if (task.owner_agency_id === user.agency) {
    return true;
  }

  return false;
};

// Filters the specified user list retrieving only the ones that can be tagged (mentioned)
// on activity comments.
export const filterCommentUsers = (users, task, activity) => R.pickBy(user => canBeMentioned(user, task, activity), users);

// Returns the specified field's icon, or use a default one based on the field's type:
export const getTaskFieldIcon = field => {
  if (field.icon) {
    return field.icon;
  }
  if (field.type) {
    return TASK_TYPE_FIELD_TYPE_ICON[field.type];
  }
  return null;
};

// Checks if the user belongs to the specified teams:
const isInTeam = (user, teams) => user.roles.filter(role => teams.includes(role)).length > 0;

export const getTeamUsers = (data, users) => {
  if (!users || Object.values(users).length === 0) {
    return null;
  }

  const { roles } = data;

  // Display users from the selected team only:
  const teamUsers = {
    0: {
      id: null,
      is_active: true,
      is_unassigned: true,  // Tells to sort this option first in the list.
      select_unassigned: true,  // Tells to allow select the 'unassigned' option
                                // (since by default it's always a disabled entry).
      name: 'Unassigned'
    },
    ...R.pickBy(user => isInTeam(user, roles) && user.is_active, users)
  };

  // If for some reason the assignee is not in the list of users
  // (because it belongs to other agency or is a superuser),
  // add it to the list.
  if (data.assignee && !teamUsers[data.assignee]) {
    teamUsers[data.assignee] = {
      id: data.assignee,
      is_active: true,
      name: data.assignee_name,
      email: data.assignee_email
    };
  }

  return teamUsers;
};

// Get all non-draft (i.e. published) task types that exist in the specified workflow:
export const getTaskTypesInWorkflow = (taskTypes, workflow) => Object.values(taskTypes).filter(item => item.draft === false && workflow.task_types.includes(item.correlation_id));

// Same as above but returns the task types that does not exists in the workflow:
export const getTaskTypesNotInWorkflow = (taskTypes, workflow) => Object.values(taskTypes).filter(item => item.draft === false && !workflow.task_types.includes(item.correlation_id));

// For each task type correlation_id we can have several versions of the task type
// (i.e. old versions and the published one), thus get the one with highest id
// (we can't just query by current=true, since the current one might be in a draft state).
export const getLatestPublishedTaskTypes = taskTypes => {
  const map = new Map();
  for (const item of taskTypes) {
    const { correlation_id, id } = item;
    if (!map.has(correlation_id) || id > map.get(correlation_id).id) {
      map.set(correlation_id, item);
    }
  }

  return Array.from(map.values());
};

// Return the task types for the specified workflow (matching them by correlation_id).
export const getWorkflowTaskTypes = (workflow, taskTypes, overrideTaskType = null) => {
  if (isEmpty(workflow)) {
    return [];
  }

  // Workflow 'task_types' is actually a list of correlation ids that matches each task type:
  const correlationIds = workflow.task_types;

  // Match by correlation id and inject order:
  const order = workflow.task_types_order;

  let taskTypeList = getTaskTypesInWorkflow(taskTypes, workflow);
  taskTypeList = getLatestPublishedTaskTypes(taskTypeList);
  taskTypeList = taskTypeList.map(
    taskType => ({ ...taskType, order: order[correlationIds.indexOf(taskType.correlation_id)]})
  );

  // Override the field order (used when waiting for an API call to refresh the screen).
  if (overrideTaskType) {
    taskTypeList = updateOverrideItem(taskTypeList, overrideTaskType);
  }

  // Return task types sorted by the 'order' property:
  return taskTypeList.sort((a, b) => (a.order > b.order) ? 1 : -1);  // eslint-disable-line id-length
};

// From a list of selected task type items (which only contains a value id)
// find them in the taskTypes list in the store and retrieve their correlation ids.
export const getCorrelationIds = (ids, taskTypes) => {
  const values = Object.values(taskTypes).filter(taskType => ids.includes(taskType.id) && taskType.draft === false);
  return getLatestPublishedTaskTypes(values).map(item => item.correlation_id);
};

// Return true if the responder section of workflow task activities can be edited.
export const isResponderReadOnly = data => {
  const { owner, roles, status_name } = data;
  const isResponder = canRespondTaskActivity(data, roles);
  const canEdit = canAccessWorkflow('taskactivity', 'change') && isResponder;
  const readOnlyStatus = status_name === 'Completed' || status_name === 'Submitted';
  const isOwner = owner === getUserId();
  return !canEdit || readOnlyStatus || (isOwner && !isResponder);
};

// Common properties for inline edit fields.
export const buildInlineEditCommonProps = (isReadOnly, hasErrors, errors) => ({
  disabled: isReadOnly,
  errorText: hasErrors ? errors.join(', ') : null,
  errorStyle: { ...detailEdit.errors, lineHeight: '1.5rem' },
  // Don't display a floating label, since the 'inline' editing already
  // uses a label outside the component, thus keep 'floatingLabelText'
  // commented:
  // floatingLabelText: label,
  fullWidth: true,
  // Hide default TextField underline:
  underlineShow: false,
  // CSS class to show a border onHover:
  className: `inline-edit-select ${hasErrors ? 'inline-edit-select-error' : ''}`
});

export const getFieldValue = (data, field) => data?.[field] || null;

const getCustomFieldValues = (data, section) => {
  const { field_values } = data?.[section] || data || {};
  return field_values;
};

export const getCustomFieldValue = (data, field_id, section) => {
  return getCustomFieldValues(data, section)?.find(value => value?.field === field_id) || null;
};

export const getCustomFieldValueIndex = (data, field_id, section) => {
  return getCustomFieldValues(data, section)?.findIndex(value => value?.field === field_id) || -1;
};
