import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dialog, FlatButton } from 'material-ui';
import { dismissDialog } from '@actions/dialogs-actions';
import { warningDialogStyles } from '@constants/mui-theme';

class WarningDialog extends Component {
  handleClose = () => this.props.dismissDialog(this.props.redirectPath);

  getActions = () => ([
    <FlatButton
      key="ok"
      label="OK"
      onClick={this.handleClose}
      style={warningDialogStyles.button}
    />
  ]);

  render() {
    const { open, text } = this.props;
    return (
      <Dialog
        actions={this.getActions()}
        contentStyle={warningDialogStyles.dialog}
        open={open}
      >
        {text}
      </Dialog>
    );
  }
}

WarningDialog.propTypes = {
  dismissDialog: PropTypes.func,
  open: PropTypes.bool,
  redirectPath: PropTypes.string,
  text: PropTypes.string
};

const mapStateToProps = state => {
  const { open, redirectPath, text } = state.dialogs;
  return { open, redirectPath, text };
};

const mapDispatchToProps = { dismissDialog };

export default connect(mapStateToProps, mapDispatchToProps)(WarningDialog);
