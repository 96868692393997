import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox } from 'material-ui';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import {
  addToGroups,
  createNewGroupWithEntities,
  fetchGroupsData
} from '@actions/groups-actions';
import { getEntityTypeLabel, getGroupTypesConfig } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { groupListById } from '@selectors/groups-selector';
import AddToGroup from '@shared/add-to-group';
import {
  findGroupType,
  prepareGroupsForBulkAdd
} from '@utils/group-utils';
import { isAppAdmin, canEditGroups } from '@utils/permission-utils';

class AddToGroupDialog extends Component {
  constructor(props) {
    super(props);
    const { preFill } = props;
    this.state = {
      groupType: preFill ? preFill.type : this.getDefaultGroupType(),
      includeOverlaps: true,
      selectedGroups: preFill ? [preFill.id] : []
    };
  }

  UNSAFE_componentWillMount() {
    const { agencyId } = this.props;
    // Only load groups if we selected an agency, or we are super users:
    if (agencyId || isAppAdmin()) {
      this.props.fetchGroupsData();
    }
  }

  getDefaultGroupType = () => {
    const groupTypes = getGroupTypesConfig().filter(groupType => canEditGroups(groupType.name));
    if (groupTypes && groupTypes.length > 0) {
      return groupTypes[0].id;
    }
    return null;
  };

  handleCancel = () => this.props.closeDashboardDialog(dialog.ADD_TO_GROUP);

  handleCheckboxChange = () => this.setState({ includeOverlaps: !this.state.includeOverlaps });

  setGroupType = groupType => this.setState({ groupType });

  setSelectedGroups = selectedGroups => this.setState({ selectedGroups });

  // Returns true if the selected group type requires area capture:
  isAreaCapture = () => {
    const { groupType } = this.state;
    const selectedGroupType = findGroupType(groupType);
    return selectedGroupType.area_capture > 0;
  };

  getIncludeOverlaps = () => {
    if (this.isAreaCapture()) {
      return this.state.includeOverlaps;
    }
    return false;
  };

  createGroup = () => {
    const { dialog: { addDataId, addDataType } } = this.props;
    const { groupType } = this.state;
    const selectedGroupType = findGroupType(groupType);
    const entityIds = {
      [addDataType]: [addDataId]
    };
    this.props.closeDashboardDialog(dialog.ADD_TO_GROUP);
    this.props.createNewGroupWithEntities(entityIds, selectedGroupType.name, this.getIncludeOverlaps());
  };

  handleSubmit = () => {
    const { dialog: { addDataId, addDataType, addCallback } } = this.props;
    const { groupType, selectedGroups } = this.state;
    const payload = prepareGroupsForBulkAdd({
      selectedGroups,
      entityIds: [addDataId],
      includeOverlaps: this.getIncludeOverlaps()
    });
    const selectedGroupType = findGroupType(groupType);
    this.props.addToGroups(selectedGroupType.name, payload, addDataType, false, null, false, addCallback);
  };

  getActions = () => {
    const { selectedGroups } = this.state;
    const { dialog: dialogItem } = this.props;
    const active = selectedGroups.length > 0 && dialogItem.addDataId && dialogItem.addDataType;
    const areaCapture = this.isAreaCapture();

    const list = [];
    if (!areaCapture) {
      list.push(<Button color="primary" onClick={this.createGroup}>NEW GROUP</Button>);
      // Add separator to make "New Group" button appear on the left.
      list.push(<div style={{ flex: '1 0 0' }} />);
    }
    return [
      ...list,
      <Button key="cancel" onClick={this.handleCancel}>CANCEL</Button>,
      <Button
        key="add"
        color="primary"
        disabled={!active}
        onClick={this.handleSubmit}
        variant="contained"
      >
        ADD
      </Button>
    ];
  };

  render() {
    const { groupType, selectedGroups } = this.state;
    const { activeDialogs, dialog: { addDataGroups, addDataType }, groupsById } = this.props;
    if (!activeDialogs[dialog.ADD_TO_GROUP]) {
      return null;
    }
    const areaCapture = this.isAreaCapture();
    return (
      <Dialog fullWidth maxWidth="sm" open={activeDialogs[dialog.ADD_TO_GROUP]}>
        <DialogTitle>
          <div style={dialogStyles.title}>
            Add to group
          </div>
        </DialogTitle>
        <DialogContent>
          <AddToGroup
            existingGroups={addDataGroups}
            groupsById={groupsById}
            groupType={groupType}
            selectedGroups={selectedGroups}
            setGroupType={this.setGroupType}
            setSelectedGroups={this.setSelectedGroups}
          />
          {areaCapture && (
            <Checkbox
              checked={this.getIncludeOverlaps()}
              label={`Include all conflicts of the ${getEntityTypeLabel(addDataType)}`}
              onCheck={this.handleCheckboxChange}
            />
          )}
        </DialogContent>
        <DialogActions>
          {this.getActions()}
        </DialogActions>
      </Dialog>
    );
  }
}

AddToGroupDialog.propTypes = {
  activeDialogs: PropTypes.object,
  addToGroups: PropTypes.func,
  agencyId: PropTypes.number,
  closeDashboardDialog: PropTypes.func,
  createNewGroupWithEntities: PropTypes.func,
  dialog: PropTypes.object,
  fetchGroupsData: PropTypes.func,
  groupsById: PropTypes.object,
  preFill: PropTypes.object
};

const mapStateToProps = state => {
  const { activeDialogs } = state.dashboard;
  const { agencyId } = state.auth.user;
  const { groups } = state;
  const { preFill } = groups;
  const groupsById = groupListById(state);
  return {
    activeDialogs,
    agencyId,
    dialog: groups.dialog,
    groupsById,
    preFill
  };
};

export default connect(mapStateToProps, {
  addToGroups,
  closeDashboardDialog,
  createNewGroupWithEntities,
  fetchGroupsData
})(
  AddToGroupDialog
);
