/* eslint-disable id-length */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { MentionsInput, Mention } from 'react-mentions';
import { getAllowAllUsersComment } from '@constants/config';
import { mentionStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import { renderMentionsUser } from '@utils/autocomplete-utils';
import { sortBy } from '@utils/shared-utils';
import './comment-input.scss';

const defaultState = { comment: '', mentions: [] };

const MAX_LEN = 5000;

class CommentInput extends Component {
  state = { ...defaultState };

  onCancel = () => {
    const { onCancel } = this.props;
    if (onCancel) {
      onCancel();
    }
    this.setState(defaultState);
  };

  onComment = () => {
    const { comment, mentions } = this.state;
    this.props.onComment(comment, mentions);
    this.onCancel();
  };

  // eslint-disable-next-line no-unused-vars
  handleChange = (event, value, plainTextValue, mentions) => {
    this.setState({
      comment: value.substring(0, MAX_LEN),
      mentions: mentions.map(mention => mention.id)
    });
  };

  // eslint-disable-next-line no-unused-vars
  renderUserSuggestion = (entry, search, highlightedDisplay, index, focused) => {
    const user = {
      ...entry,
      highlight: highlightedDisplay
    };
    return renderMentionsUser(user);
  };

  // eslint-disable-next-line no-unused-vars
  renderDisplay = (id, display) => `  ${display}  `;  // Add leading/trailing spaces for formatting (chip emulation with styles).

  // Return the users list to use for suggestions, copying the 'email' field into the
  // 'id' one and copying 'name' into 'display', something required by the react-mentions library.
  getUsers = () => {
    const { filterUsers, users } = this.props;
    let filteredUsers = users;
    // If there's a custom method to filter users apply it:
    // We will ignore filters if the city would like everyone to comment.
    if (filterUsers && !getAllowAllUsersComment()) {
      filteredUsers = filterUsers(filteredUsers);
    }

    const activeUsers = Object.values(filteredUsers).filter(user => user.is_active && !user.email.endsWith('dotmapsapp.com'));
    const sortedUsers = sortBy(activeUsers, 'name');
    return sortedUsers.map(user => ({
      ...user,
      id: user.email,
      display: user.name
    }));
  };

  render() {
    const { comment } = this.state;
    const commentNotEmpty = comment && comment.length > 0;
    return (
      <div styleName="comment-input-container">
        <div styleName="controls">
          <MentionsInput
            allowSpaceInQuery
            a11ySuggestionsListLabel="Suggested user mentions"
            onChange={this.handleChange}
            placeholder="Send a comment, use @ to mention other users"
            style={mentionStyles.input}
            value={comment}
          >
            <Mention
              appendSpaceOnAdd
              data={this.getUsers()}
              markup="|__id__^__display__|"
              displayTransform={this.renderDisplay}
              renderSuggestion={this.renderUserSuggestion}
              style={mentionStyles.mention}
              trigger="@"
            />
          </MentionsInput>
          {commentNotEmpty && (
            <div styleName="field-footer">
              <div styleName="hint" />
              <div styleName="counter">
                {comment.length}/{MAX_LEN}
              </div>
            </div>
          )}
        </div>
        {commentNotEmpty && (
          <div styleName="footer">
            <div styleName="footer-grow" />
            <div styleName="footer-button">
              <Button onClick={this.onCancel}>CANCEL</Button>
              <Button
                color="primary"
                disabled={isEmpty(comment)}
                onClick={this.onComment}
                style={{ marginLeft: '0.5rem' }}
                variant="contained"
              >
                SEND
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

CommentInput.propTypes = {
  filterUsers: PropTypes.func,
  onCancel: PropTypes.func,
  onComment: PropTypes.func,
  users: PropTypes.object
};

const mapStateToProps = state => {
  const { user } = state.dataTypes;
  return { users: user };
};

export default connect(mapStateToProps, {})(CommentInput);
