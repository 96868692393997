import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDashboardTitle } from '@constants/config';
import { renderBreadCrumbDataType } from '@utils/dashboard-details-utils';
import Separator from './separator';
import './breadcrumb.scss';

class BreadCrumb extends Component {
  render() {
    const { dataType, detailName, subType } = this.props;
    return (
      <div styleName="breadcrumb-container">
        <div styleName="breadcrumb-entry">
          {getDashboardTitle()}
        </div>
        <Separator />
        <div styleName={`breadcrumb-entry ${detailName ? '' : 'breadcrumb-active'}`}>
          {renderBreadCrumbDataType(dataType, subType, detailName)}
        </div>
        {detailName && <Separator />}
        {detailName && (
          <div styleName="breadcrumb-entry breadcrumb-active">
            {detailName}
          </div>
        )}
      </div>
    );
  }
}

BreadCrumb.propTypes = {
  dataType: PropTypes.string,
  detailName: PropTypes.string,
  subType: PropTypes.string
};

export default BreadCrumb;
