import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DataTypesSelect from '@forms/data-types-select';
import Row from '@components/workflow/cycle/content/section/view-field-row';
import { disabledFormField } from '@constants/mui-theme';
import { getDataForType } from '@selectors/data-types-selector';
import '../inline-edit-field.scss';

const InlineResponseField = ({
  commonProps,
  hasErrors,
  label,
  onSelectChange,
  source
}) => {
  const options = useSelector(state => getDataForType(state, source));

  const responses = {
    0: {
      id: null,
      is_unassigned: true,
      name: 'None'
    },
    ...options
  };

  let text = null;
  if (commonProps.disabled) {
    // If the response field is disabled, show the text instead of the
    // DataTypesSelect control, since it truncates the text.
    if (options && commonProps.value && options[commonProps.value]) {
      text = options[commonProps.value].name;
    } else {
      text = 'None';
    }
    text = <div style={disabledFormField}>{text}</div>;
  } else {
    text = <DataTypesSelect {...commonProps} dataSource={responses} onChange={onSelectChange} />;
  }

  const value = <div styleName="control-wrapper">{text}</div>;

  const rowStyles = {
    padding: hasErrors ? '0 0 1.25rem 0' : '0 0 0.5rem 0'
  };

  return <Row label={label} value={value} labelStyle={{}} style={rowStyles} />;
};

InlineResponseField.propTypes = {
  commonProps: PropTypes.object,
  hasErrors: PropTypes.bool,
  label: PropTypes.string,
  onSelectChange: PropTypes.func,
  source: PropTypes.string
};

export default InlineResponseField;
