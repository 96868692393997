import { Component } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { clearTrays } from '@actions/map-actions';
import { boundsEntitiesSelector } from '@selectors/entities-selector';
import { getTrayTop, getTrayType } from '@selectors/map-selector';

// Base class for map trays:
export class BaseTray extends Component {
  constructor(props) {
    super(props);
    this.clearTrays = () => this.props.clearTrays();
  }
}

BaseTray.propTypes = {
  clearTrays: PropTypes.func,
  entity: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  position: PropTypes.object,
  tray: PropTypes.object
};

export const baseTrayMapStateToProps = state => {
  const tray = getTrayTop(state);
  const dataType = getTrayType(state);
  return { tray, dataType };
};

export const baseTrayMapEntityProps = (props, dataType) => {
  const trayData = (props.dataType === dataType ? props.tray.data : null);
  if (!trayData || R.isEmpty(trayData)) {
    return { entity: null };
  }
  return {
    entity: trayData[dataType],
    tray: props.tray,
    position: trayData.position,
    pending: trayData.pending || false
  };
};

export const baseTrayAreaResultsProps = (state, dataType) => {
  const props = baseTrayMapStateToProps(state);
  const { entity, position } = baseTrayMapEntityProps(props, dataType);
  const { drawing: { shape } } = state.map;
  let entities;
  if (shape) {
    // If a drawing shape is defined, take the entities from all
    // existing entities in the map (which are already filtered
    // by the shape).
    entities = {
      entities: {}  // filling entities below:
    };
    const { types } = state.entities;
    Object.keys(types).forEach(type => {
      const list = boundsEntitiesSelector(state, { type });
      if (list && list.length > 0) {
        entities.entities[type] = list;
      }
    });
  } else {
    // Else, take the entities from the radius selection on the map:
    entities = entity;
  }
  return { entity: entities, position, props, shape };
};

export const baseTrayMapDispatchToProps = { clearTrays };

