/* eslint-disable max-depth */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MapComponents from './map-components';
import DotMapsGoogleMap from '@shared/dotmaps-google-map';
import { segmentsToBounds } from '@utils/map-utils';
import './section.scss';

class Map extends Component {
  state = { mapInstance: null };

  componentDidUpdate = prevProps => {
    const { selectedSegmentId } = this.props;
    if (selectedSegmentId && selectedSegmentId !== prevProps.selectedSegmentId) {
      this.fitSegmentBounds();
    }
  };

  onMapLoad = mapInstance => {
    window.mapInstance = mapInstance;
    this.setState({ mapInstance }, () => {
      this.fitSegmentBounds();
    });
  };

  fitSegmentBounds = () => {
    let { segments } = this.props;
    if (segments) {
      const { selectedSegmentId } = this.props;
      if (selectedSegmentId) {
        segments = segments.filter(segment => segment.shape && segment.id === selectedSegmentId);
      }
      if (segments.length > 0) {
        const bounds = segmentsToBounds(segments);
        if (bounds) {
          this.state.mapInstance.fitBounds(bounds);
          if (this.state.mapInstance.getZoom() > 18) {
            this.state.mapInstance.setZoom(18);
          }
        }
      }
    }
  };

  render() {
    const { segments } = this.props;
    return (
      <div styleName="section-map">
        <DotMapsGoogleMap
          components={<MapComponents segments={segments} />}
          onMapLoad={this.onMapLoad}
        />
      </div>
    );
  }
}

Map.propTypes = {
  segments: PropTypes.array,
  selectedSegmentId: PropTypes.string
};

export default Map;
