/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import Tooltip from '@material-ui/core/Tooltip';
import { push } from 'connected-react-router';
import NotificationContents from './notification-contents';
import { setNotificationRead } from '@actions/notifications-actions';
import { renderItemAvatar } from '@utils/logs-utils';
import './notification-item.scss';

const NotificationLinkWrap = ({ link, setRead, children }) => {
  const dispatch = useDispatch();
  if (link) {
    return (
      <ButtonBase
        onClick={() => {
          if (setRead) {
            setRead();
          }
          let linkValue = link;
          if (typeof link === 'object') {
            linkValue = link.pathname;
          }
          dispatch(push({ pathname: linkValue, state: { source: location.pathname } }));
        }}
        styleName="notification-item-container"
      >
        { children }
      </ButtonBase>
    );
  }
  return (
    <div styleName="notification-item-container">
      { children }
    </div>
  );
};

NotificationLinkWrap.propTypes = {
  children: PropTypes.node,
  link: PropTypes.object,
  setRead: PropTypes.func
};

class NotificationItem extends Component {
  markAsRead = notification => this.props.setNotificationRead(notification.id, !notification.read);

  render() {
    const { agencies, notification } = this.props;
    const { entry_object, read } = notification;
    const { attrs, entry } = entry_object;
    const { action, created } = entry;
    const setRead = () => this.props.setNotificationRead(notification.id, true);
    // Inject the agency name into the user object:
    let { user } = entry;
    if (user && user.agency) {
      user = {
        ...user,
        agency_name: agencies[user.agency].name
      };
    }
    return (
      <NotificationLinkWrap link={attrs.link} setRead={setRead}>
        <div styleName="item-avatar">
          {renderItemAvatar(user, 32)}
        </div>
        <div styleName="item-content">
          <div styleName="item-main item-row">
            <div styleName="item-message">
              <NotificationContents action={action} attrs={attrs} user={user} created={created} />
            </div>
            <div styleName="item-read">
              <div
                onClick={(event) => {
                  this.markAsRead(notification);
                  event.stopPropagation();
                }}
                role="button"
                tabIndex="0"
                styleName="not-read-icon-wrapper"
              >
                {read && (
                  <Tooltip title="mark as unread" placement="top-start">
                    <div styleName="read-icon mark-as-unread" />
                  </Tooltip>
                )}
                {!read && (
                  <Tooltip title="mark as read" placement="top-start">
                    <div styleName="read-icon mark-as-read" />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </NotificationLinkWrap>
    );
  }
}

NotificationItem.propTypes = {
  agencies: PropTypes.object,
  index: PropTypes.string,
  notification: PropTypes.object,
  push: PropTypes.func,
  setNotificationRead: PropTypes.func
};

const mapStateToProps = state => {
  const { agency } = state.dataTypes;
  return { agencies: agency };
};

export default connect(mapStateToProps, { push, setNotificationRead })(NotificationItem);
