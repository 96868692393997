import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { isEmpty } from 'lodash';
import Divider from 'material-ui/Divider';
import { Tabs, Tab } from 'material-ui/Tabs';
import { openDashboardDialog } from '@actions/dashboard-actions';
import EntityCard from '@components/map/tray/tray-card/entity-card';
import Header from '@components/map/tray/header';
import { dotmapsGreen } from '@constants/colors';
import { getEntityType, getGroupTrayConfig, isWorkflowEnabled } from '@constants/config';
import * as dialog from '@constants/dialogs';
import { mapTrayStyles } from '@constants/mui-theme';
import GroupTypeIcon from '@icons/group-type-icon';
import { getTrayTop } from '@selectors/map-selector';
import { canEditGroups, canAccessWorkflow } from '@utils/permission-utils';
import CycleCards from '@shared/cycle-cards';
import DotmapsLoader from '@shared/dotmaps-loader';
import DetailsTableBlock from '@shared/details-table-block';
import './group.scss';

class Group extends Component {
  state = { activeTab: 'list' };

  onListActive = () => this.setState({ activeTab: 'list' });

  onCyclesActive = () => this.setState({ activeTab: 'cycles' });

  editGroupAction = (id, type_name) => ({
    label: 'Edit group',
    link: `/group/${type_name}/${id}`
  });

  startCycle = () => {
    const { group } = this.props;
    const params = { groupId: group.id, reloadGroupTray: true };
    this.props.openDashboardDialog(dialog.START_CYCLE, params);
  };

  renderGroupDetails = () => {
    const { group } = this.props;
    const config = getGroupTrayConfig();
    const fields = config[group.type_name].map(({ field, label }) => {
      const value = Array.isArray(field) ? R.pathOr('', field, group) : group[field];
      return { label, value };
    });
    return (
      <div styleName="map-tray-group-details">
        <DetailsTableBlock fields={fields} />
      </div>
    );
  };

  renderEmpty = msg => (
    <div styleName="empty-elements">
      {msg}
    </div>
  );

  renderEntitiesByType = entities => {
    if (entities.length === 0) {
      return this.renderEmpty('No entities');
    }
    const groupedEntities = {};
    const entityTypes = new Set();
    entities.forEach(entity => {
      const type = entity.type_name;
      if (type) {
        if (!groupedEntities[type]) {
          groupedEntities[type] = [];
        }
        groupedEntities[type].push(entity);
        entityTypes.add(type);
      }
    });
    if (isEmpty(entityTypes)) {
      return <DotmapsLoader color={dotmapsGreen} display small />;
    }
    return [...entityTypes].map((entityType, index) => {
      const type = getEntityType(entityType);
      if (!type) {
        // If there's no type, it means it's an entity type
        // that should not be rendered in the UI (i.e. moratoriums).
        return null;
      }
      const { label } = type;
      const list = groupedEntities[entityType];
      return (
        <div key={label} styleName="elements-section-container">
          {index > 0 && <Divider />}
          <div styleName="elements-section">
            <div styleName="section-header">{label}<span>{list.length}</span></div>
            { list.map(entity => <EntityCard key={entity.id} entity={entity} />) }
          </div>
        </div>
      );
    });
  };

  renderEntities = () => {
    const { group } = this.props;
    const { entities } = group;
    return (
      <div styleName="elements">
        {this.renderEntitiesByType(entities)}
      </div>
    );
  };

  // Like renderEntities() but to use within a <Tab> component.
  // So, it must be rendered without the wrapping <div>,
  // since the scrollbars are added by the Tab.
  renderList = () => {
    const { group } = this.props;
    const { entities } = group;
    return this.renderEntitiesByType(entities);
  };

  renderCycles = () => {
    const { group } = this.props;
    const { cycles } = group;
    if (cycles.length === 0) {
      return this.renderEmpty('No workflow cycles');
    }
    return (
      <div styleName="cycle-elements">
        <CycleCards cycles={cycles} />
      </div>
    );
  };

  tabExists = (tabs, id) => tabs.find(tab => tab.id === id);

  getTabProps = tab => {
    if (this.state.activeTab === tab) {
      return mapTrayStyles.activeTab;
    }
    return mapTrayStyles.tab;
  };

  renderListTab = tabs => {
    if (this.tabExists(tabs, 'list')) {
      return (
        <Tab {...this.getTabProps('list')} label="LIST" onActive={this.onListActive}>
          {this.renderList()}
        </Tab>
      );
    }
    return null;
  };

  renderCyclesTab = tabs => {
    if (this.tabExists(tabs, 'cycles')) {
      return (
        <Tab {...this.getTabProps('cycles')} label="CYCLES" onActive={this.onCyclesActive}>
          {this.renderCycles()}
        </Tab>
      );
    }
    return null;
  };

  renderElements = () => {
    const { group } = this.props;
    if (isWorkflowEnabled() && group.type_name === 'relational') {
      const tabs = [
        { id: 'list' },
        { id: 'cycles' }
      ];
      return (
        <div styleName="map-tray-tabs">
          <Tabs {...mapTrayStyles.tabs}>
            {this.renderListTab(tabs)}
            {this.renderCyclesTab(tabs)}
          </Tabs>
        </div>
      );
    }
    return this.renderEntities();
  };

  render() {
    const { group } = this.props;
    if (!group) {
      return null;
    }
    const headerProps = {};
    const actions = [];
    if (canEditGroups()) {
      actions.push(this.editGroupAction(group.id, group.type_name));
    }
    if (isWorkflowEnabled() && canAccessWorkflow('cycle', 'add') && group.type_name === 'relational') {
      actions.push({ label: 'Start a workflow cycle', action: this.startCycle });
    }
    if (actions.length > 0) {
      headerProps.moreActions = actions;
    }
    return (
      <div styleName="map-tray-group">
        <Header {...headerProps} icon={<GroupTypeIcon type={group.type_name} />} title={group.name} />
        {this.renderGroupDetails()}
        <Divider />
        {this.renderElements()}
      </div>
    );
  }
}

Group.propTypes = {
  agencies: PropTypes.object,
  agencyTypes: PropTypes.object,
  group: PropTypes.object,
  openDashboardDialog: PropTypes.func
};

const mapStateToProps = state => {
  const tray = getTrayTop(state);
  const group = (tray && tray.data && tray.data.group) || null;
  return { group };
};

export default connect(mapStateToProps, { openDashboardDialog })(Group);
