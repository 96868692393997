import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FlatButton from 'material-ui/FlatButton';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { clearGroupDate } from '@actions/groups-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import ActionButton from '@shared/action-button';
import './remove-daterange.scss';

const RemoveDateRange = () => {
  const dispatch = useDispatch();
  const { activeDialogs } = useSelector(state => state.dashboard);

  const handleNo = useCallback(
    () => dispatch(closeDashboardDialog(dialog.REMOVE_DATE_RANGE)),
    [dispatch]
  );

  const handleYes = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.REMOVE_DATE_RANGE));
      dispatch(clearGroupDate());
    },
    [dispatch]
  );

  if (!activeDialogs[dialog.REMOVE_DATE_RANGE]) {
    return null;
  }

  const getActions = () => ([
    <FlatButton key="no" label="NO" onClick={handleNo} />,
    <ActionButton key="yes" label="YES" primary onClick={handleYes} />
  ]);

  return (
    <Dialog fullWidth maxWidth="sm" open>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Clear date range
        </div>
      </DialogTitle>
      <DialogContent>
        <div styleName="remove-daterange-dialog-container">
          By removing the date range you will bring in all records in existence in this area.<br />
          Are you sure you want to proceed?
        </div>
      </DialogContent>
      <DialogActions>
        {getActions()}
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDateRange;
