import React from 'react';
import { useSelector } from 'react-redux';
import { dotmapsGreen } from '@constants/colors';
import { getDefaultEntity } from '@constants/config';
import { entityFiltersDatatableSelector } from '@selectors/entities-selector';
import DataTable from '@shared/data-table';

const EntityDataTable = () => {
  const type = getDefaultEntity();
  const filters = useSelector(state => entityFiltersDatatableSelector(state, { type }));

  return <DataTable dataType={type} embeddedColor={dotmapsGreen} embedded filters={filters} />;
};

export default EntityDataTable;
