import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { changeDashboardData } from '@actions/dashboard-actions';
import {
  dashboardDetailsDataSelector
} from '@selectors/dashboard-details-selector';
import { parseLog } from '@utils/data-detail-utils';
import './detail-log-table.scss';

const DetailLogTable = ({ data, field }) => {
  const entries = data[field];
  if (entries.length > 0) {
    return (
      <div styleName="detail-table-wrapper">
        <TableContainer>
          <Table styleName="detail-table" aria-label="errors">
            <TableHead>
              <TableRow>
                <TableCell styleName="header-cell">ROW</TableCell>
                <TableCell styleName="header-cell">ERROR</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {entries.map((entry) => (
                <TableRow key={entry.id}>
                  <TableCell styleName="table-cell">{entry.line_number}</TableCell>
                  <TableCell styleName="table-cell">{parseLog(entry) || entry.details}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  return null;
};

DetailLogTable.propTypes = {
  data: PropTypes.object,
  dataType: PropTypes.string,
  field: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const { dataType } = props;
  const data = dashboardDetailsDataSelector(state, dataType);
  return { data };
};

export default connect(mapStateToProps, {changeDashboardData})(DetailLogTable);
