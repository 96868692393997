import { createSelector } from 'reselect';
import { parseNullFields } from '@utils/dashboard-details-utils';
import { optimizeBatchUploadDetail } from '@utils/data-table/batch';

const DEFAULT_DETAILS = {};
const getDetails = (state, type) => state.dashboard.details[type] || DEFAULT_DETAILS;
const typeIdentity = (state, type) => type;

export const dashboardDetailsDataSelector = createSelector(
  [getDetails, typeIdentity],
  ({data}, type) => {
    let parsedData;
    switch (type) {
    case 'batch':
      parsedData = data && optimizeBatchUploadDetail(data);
      break;
    default:
      parsedData = data;
      break;
    }
    return parseNullFields(parsedData);
  }
);

export const dasboardDetailsDataValidSelector = createSelector(
  [getDetails],
  ({valid}) => {
    return valid || false;
  });
