import React from 'react';

const WarningIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path id="warning-path-1" d="M0 14.25h16.5L8.25 0 0 14.25zM9 12H7.5v-1.5H9V12zm0-3H7.5V6H9v3z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(.75 1.5)">
      <mask id="warning-path-2" fill="#fff">
        <use xlinkHref="#warning-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#warning-path-2)">
        <path d="M-.75-1.5h18v18h-18z" />
      </g>
    </g>
  </svg>
);

export default WarningIcon;
