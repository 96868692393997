import React from 'react';

const CurrentPMIcon = () => (
  <svg width="16px" height="16px" version="1.1" viewBox="0 0 16 16">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v16H0z" />
      <path
        fill="#3467D6"
        fillRule="nonzero"
        d={`
            M8 0C3.584 0 0 3.584 0 8s3.584 8 8 8 8-3.584 8-8-3.584-8-8-8zm0 2.4c1.328
            0 2.4 1.072 2.4 2.4 0 1.328-1.072 2.4-2.4 2.4a2.397 2.397 0 0 1-2.4-2.4c0-1.328
            1.072-2.4 2.4-2.4zm0 11.36a5.76 5.76 0 0 1-4.8-2.576C3.224 9.592 6.4 8.72 8
            8.72c1.592 0 4.776.872 4.8 2.464A5.76 5.76 0 0 1 8 13.76z
        `}
      />
    </g>
  </svg>
);

export default CurrentPMIcon;
