import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import NotificationItem from './notification-item';
import {
  notificationGroups,
  renderNotificationGroupItemDate
} from '@utils/notification-utils';
import './notification-items-group.scss';

const sortNotificationIdDesc = R.sort(R.descend(R.prop('id')));

class NotificationItemsGroup extends PureComponent {
  renderHeader = () => {
    const { index } = this.props;
    const { dayGroup } = this.props.notifications;
    // If index is zero (it's the first group) and that group is the "EARLIER" one
    // it means there's a single group with all non-TODAY and non-YESTERDAY entries,
    // thus skip rendering the header if we only display old entries:
    if (index === 0 && dayGroup === notificationGroups.earlier.dayGroup) {
      return null;
    }
    return (
      <div styleName="items-group-header" id={`items-group-header-${dayGroup}`}>
        <div styleName="items-group-header-label">
          {renderNotificationGroupItemDate(dayGroup)}
        </div>
      </div>
    );
  };
  render() {
    const { notifications } = this.props.notifications;
    // Sort notifications within each group by id descending (so newer entries
    // appears first):
    const sortedNotifications = sortNotificationIdDesc(notifications);
    return (
      <div styleName="notification-items-group-container">
        {this.renderHeader()}
        <div styleName="items-group-list">
          {sortedNotifications.map((notification, index) => (
            <NotificationItem
              key={`${notification.id}-${index}`}
              index={`${notification.id}-${index}`}
              notification={notification}
            />
          ))}
        </div>
      </div>
    );
  }
}

NotificationItemsGroup.propTypes = {
  index: PropTypes.number,
  notifications: PropTypes.object
};

export default NotificationItemsGroup;
