/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { moreListTooltipStyles } from '@constants/mui-theme';
import Tooltipable from '@shared/tooltipable';
import { enDash } from '@utils/shared-utils';
import './list-more.scss';

class ListMore extends Tooltipable {
  renderList = list => {
    return (
      <div>
        {list.slice(0, 6).map((item, index) => this.renderItem(item, index))}
        {list.length > 6 && (
          <div style={moreListTooltipStyles.seeDetailsStyle}>
            See {this.props.dataType} detail for complete list
          </div>
        )}
      </div>
    );
  };

  renderItem = (item, index) => (
    <div key={index} style={moreListTooltipStyles.itemStyle}>
      {item}
    </div>
  );

  render() {
    const { list, limit } = this.props;

    if (!list) {
      return null;
    }

    let display = [];
    let more = 0;
    if (list.length <= limit) {
      display = list;
    } else {
      display = list.slice(0, limit);
      more = list.length - limit;
    }

    return (
      <div styleName="column-renderer-list-more-container">
        {display.length > 0 && display.map((item, index) => (
          <span key={index} styleName="item">
            {item}
          </span>
        ))
        }
        {more > 0 && (
          <span
            onMouseOver={this.hoverOn}
            onMouseOut={this.hoverOff}
            styleName="last"
          >
            +{more}
          </span>
        )}
        {display.length === 0 && <span>{enDash}</span>}
        {this.renderTooltip(this.renderList(list.slice(limit)))}
      </div>
    );
  }
}

ListMore.propTypes = {
  dataType: PropTypes.string,
  limit: PropTypes.number,
  list: PropTypes.array
};

ListMore.defaultProps = {
  limit: 1,
  tooltipStyle: { ...moreListTooltipStyles.tooltip }
};

export default ListMore;
