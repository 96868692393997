import React from 'react';
import PropTypes from 'prop-types';
import FileUploadIcon from 'material-ui/svg-icons/file/file-upload';
import IconButton from 'material-ui/IconButton';
import { dotmapsBlack60 } from '@constants/colors';
import { simpleIconButtonStyles } from '@constants/mui-theme';
import DownloadAll from '@components/attachments/download-all';
import ChipCard from '@components/attachments/chip-card';
import FileUpload from '@shared/files/file-upload';
import './inline-attachments-control.scss';

const InlineAttachmentControl = ({
  activity, attachments, errors, isReadOnly, viewType
}) => (
  <div styleName="control-container">
    {!isReadOnly && (
      <div styleName="control-header">
        <FileUpload contentType="task_activity" objectId={activity.id} parentId={activity.task}>
          <IconButton {...simpleIconButtonStyles}>
            <div styleName="control-upload">
              <div styleName="control-upload-icon"><FileUploadIcon color={dotmapsBlack60} /></div>
              <div styleName="control-upload-text">Upload</div>
            </div>
          </IconButton>
        </FileUpload>
        {attachments.length > 0 && <DownloadAll attachments={attachments} />}
      </div>
    )}
    <div styleName="control-contents">
      {attachments.map((attachment, index) => (
        <ChipCard
          activity={activity}
          attachment={attachment}
          index={index}
          isReadOnly={isReadOnly}
          key={index}
          viewType={viewType}
        />
      ))}
    </div>
    {isReadOnly && (!attachments || attachments.length === 0) && (
      <div styleName="control-empty">
        None
      </div>
    )}
    {errors && errors.length > 0 && (
      <div styleName="control-error">
        {errors.map((error, index) => <div key={index}>{error}</div>)}
      </div>
    )}
  </div>
);

InlineAttachmentControl.propTypes = {
  activity: PropTypes.object,
  attachments: PropTypes.array,
  errors: PropTypes.array,
  isReadOnly: PropTypes.bool,
  viewType: PropTypes.string
};

export default InlineAttachmentControl;
