import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Prompt } from 'react-router-dom';
import { routerWillLeave } from '@actions/confirmation-actions';
import GroupsDateRangeDialog from '@components/group/dialogs/date-range';
import {
  changeGroupFieldValue,
  createGroupWithEntities,
  createNewGroup,
  getGroupById,
  updateGroup
} from '@actions/groups-actions';
import { dotmapsBlue } from '@constants/colors';
import { getGroupDetailsConfig } from '@constants/config';
import FormElement from '@forms/form-element';
import Button from '@material-ui/core/Button';
import { getAdminAgencyAdminAndManagerUsers } from '@selectors/data-table-selector';
import { getGroupIsLoading } from '@selectors/groups-selector';
import DotmapsLoader from '@shared/dotmaps-loader';
import FlexSeparator from '@shared/helpers/flex-separator';
import {
  applyDynamicStyles,
  getAction,
  mergeActionWithTemplate
} from '@utils/data-detail-utils';
import { getErrors } from '@utils/form-utils';
import { prepareGroupData } from '@utils/group-utils';
import { canEditGroups } from '@utils/permission-utils';
import { withRouter } from '@utils/router-utils';
import { sortBy } from '@utils/shared-utils';
import BoundaryRow from './boundary-row';
import DateRow from './date-row';
import '../drawer.scss';

class Details extends Component {
  UNSAFE_componentWillMount() {
    this.initializePage();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      this.initializePage();
    }
  }

  initializePage = () => {
    const { dataId, groupType } = this.props.params;
    if (dataId !== 'new' && !!+dataId) {
      this.props.getGroupById(dataId);
    }
    if (dataId === 'new') {
      this.props.createNewGroup(groupType);
    }
    const entityIds = R.path(['state', 'entityIds'], this.props.location);
    if (entityIds) {
      const includeOverlaps = R.path(['state', 'includeOverlaps'], this.props.location);
      this.props.createGroupWithEntities(entityIds, includeOverlaps);
    }
  };

  onChange = (field, value) => {
    if (field === 'owner') {
      this.props.changeGroupFieldValue(
        field,
        R.find(R.propEq(value, 'id'))(this.props.user)
      );
    } else {
      this.props.changeGroupFieldValue(field, value);
    }
  };

  clickSave = () => {
    const { groupType } = this.props.params;
    const source = this.props.location.state ? this.props.location.state.source : null;
    this.props.updateGroup(prepareGroupData(this.props.edit), groupType, source);
  };

  getConfig = () => {
    const { groupType } = this.props.params;
    return getGroupDetailsConfig(groupType);
  };

  isBoundary = () => this.getConfig().form.boundary;

  getFormLegend = () => this.getConfig().form.legend;

  getDetailsTab = () => this.getConfig().tabs[0];

  getFormTemplate = () => this.getConfig().form;

  getMetadata = () => {
    const { group, options } = this.props;
    // Get backend fields definition
    // (i.e. field name, type, label, etc).
    const { action } = getAction(options);
    // Get frontend field definition
    // (i.e. field name, CSS style, etc).
    const fieldTemplate = this.getFormTemplate();
    // And merge both:
    return applyDynamicStyles(mergeActionWithTemplate(action || {}, fieldTemplate), group || {});
  };

  getDataSource = field => {
    const { groupStatuses, user } = this.props;
    if (field === 'status') {
      return groupStatuses;
    }
    if (field === 'owner') {
      return R.values(user);
    }
    return null;
  };

  getFieldValue = field => {
    const { group } = this.props;
    if (field === 'owner' && group[field]) {
      return group[field].id;
    }
    return group[field] || '';
  };

  render() {
    const { groupType } = this.props.params;
    const { drawing, group, params, loading, error, saving } = this.props;
    if (loading || ((!group || !group.id) && params.dataId !== 'new')) {
      return (
        <div styleName="group-drawer-tab-loader-container">
          <DotmapsLoader color={dotmapsBlue} display />
        </div>
      );
    }
    const errors = getErrors(error);
    const isReadOnly = !canEditGroups(groupType);
    const detailsTab = this.getDetailsTab();
    const metadata = this.getMetadata();
    const isBoundary = this.isBoundary();
    const legend = this.getFormLegend();
    return (
      <div styleName="group-drawer-tab-content">
        <div styleName="container">
          <div styleName="header">
            <div styleName="title">{detailsTab.label}</div>
            {detailsTab.extra && (
              <div styleName="extra">
                {detailsTab.extra}
              </div>
            )}
            <FlexSeparator />
            {saving &&
              <div styleName="loader"><DotmapsLoader color={dotmapsBlue} display small /></div>
            }
            {!isReadOnly && (
              <Button
                color="primary"
                disabled={saving || drawing}
                onClick={this.clickSave}
                variant="contained"
              >
                SAVE
              </Button>
            )}
          </div>
          <div styleName="form-legend-container">
            {legend && (
              <div styleName="form-legend-text">
                {legend}
              </div>
            )}
          </div>
          <div styleName="form-row">
            {detailsTab.fields.map(field => (
              <FormElement
                data={group}
                key={field}
                fieldName={field}
                fieldMeta={metadata[field]}
                onChange={this.onChange}
                value={this.getFieldValue(field)}
                readOnly={isReadOnly}
                templateProps={metadata[field]}
                dataSource={this.getDataSource(field)}
                errors={errors[field] || null}
              />))}
          </div>
        </div>
        {isBoundary && (
          <div styleName="container">
            <div styleName="title">Boundary and date range</div>
            <div styleName="description">
              A boundary group helps to facilitate coordination, it contains records that fall within a defined area and a date range.
              Records will be automatically loaded after group creation. You can also manually add records.
            </div>
            <BoundaryRow />
            <DateRow />
          </div>
        )}
        {isBoundary && <GroupsDateRangeDialog />}
        <Prompt when={this.props.modified && !this.props.discard} message={this.props.routerWillLeave} />
      </div>
    );
  }
}

Details.propTypes = {
  changeGroupFieldValue: PropTypes.func,
  createGroupWithEntities: PropTypes.func,
  createNewGroup: PropTypes.func,
  discard: PropTypes.bool,
  drawing: PropTypes.bool,
  edit: PropTypes.object,
  error: PropTypes.object,
  getGroupById: PropTypes.func,
  group: PropTypes.object,
  groupStatuses: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  modified: PropTypes.bool,
  options: PropTypes.object,
  params: PropTypes.object,
  routerWillLeave: PropTypes.func,
  saving: PropTypes.bool,
  updateGroup: PropTypes.func,
  user: PropTypes.array
};

const mapStateToProps = state => {
  const { groups: { edit, options } } = state;
  const { discard } = state.confirmation;
  const user = sortBy(R.values(getAdminAgencyAdminAndManagerUsers(state)), 'name');
  const loading = getGroupIsLoading(state);
  const { group_status: groupStatuses } = state.dataTypes;
  return { ...edit, edit, user, loading, discard, options, groupStatuses };
};

const mapDispatchToProps = {
  changeGroupFieldValue,
  createGroupWithEntities,
  createNewGroup,
  getGroupById,
  routerWillLeave,
  updateGroup
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Details));
