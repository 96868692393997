/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import DropDownMenu from 'material-ui/DropDownMenu';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CheckboxControl from '@shared/form/checkbox-control';
import {
  buildRecurrenceTimeMenu,
  buildRecurrenceWeekMenu,
  buildRecurrenceWeekMenuValues,
  getRecurrenceStyles,
  recurrenceTimeSelectionRenderer,
  recurrenceWeekSelectionRenderer
} from '@utils/segment-schedule/recurrence';
import './edit-schedule-dialog-recurrence-list.scss';

const RecurrenceRow = ({
  deleteRecurrence,
  onAllDayToggle,
  onDayChange,
  onEndTimeChange,
  onStartTimeChange,
  recurrence
}) => {
  const onRemove = () => deleteRecurrence(recurrence);

  const onChangeDay = (event, key, values) => onDayChange(recurrence, values);

  const onChangeStart = (event, key, value) => onStartTimeChange(recurrence, value);

  const onChangeEnd = (event, key, value) => onEndTimeChange(recurrence, value);

  const recurrenceStartTimeSelectionRenderer = () => recurrenceTimeSelectionRenderer(recurrence.start_time, 'Start time');

  const recurrenceEndTimeSelectionRenderer = () => recurrenceTimeSelectionRenderer(recurrence.end_time, 'End time');

  const toggleAllDay = () => onAllDayToggle(recurrence);

  const dayStyles = getRecurrenceStyles(recurrence, 'day');
  return (
    <div styleName="recurrence-row">
      <div styleName="day item">
        <DropDownMenu
          {...dayStyles}
          style={{...dayStyles.style, maxWidth: '14rem'}}
          multiple
          onChange={onChangeDay}
          selectionRenderer={recurrenceWeekSelectionRenderer}
          value={buildRecurrenceWeekMenuValues(recurrence)}
        >
          {buildRecurrenceWeekMenu(recurrence)}
        </DropDownMenu>
      </div>
      <div styleName="start item">
        <DropDownMenu
          {...getRecurrenceStyles(recurrence, 'start_time')}
          disabled={recurrence.all_day}
          menuStyle={{ minWidth: '8rem' }}
          multiple={false}
          onChange={onChangeStart}
          selectionRenderer={recurrenceStartTimeSelectionRenderer}
          value={recurrence.start_time}
        >
          {buildRecurrenceTimeMenu('start_time', recurrence)}
        </DropDownMenu>
      </div>
      <div styleName="to item">
        &ndash;
      </div>
      <div styleName="end item">
        <DropDownMenu
          {...getRecurrenceStyles(recurrence, 'end_time')}
          disabled={recurrence.all_day}
          menuStyle={{ minWidth: '8rem' }}
          multiple={false}
          onChange={onChangeEnd}
          selectionRenderer={recurrenceEndTimeSelectionRenderer}
          value={recurrence.end_time}
        >
          {buildRecurrenceTimeMenu('end_time', recurrence)}
        </DropDownMenu>
      </div>
      <div styleName="all-day item">
        <CheckboxControl
          label={<div styleName="label">All day</div>}
          onChange={toggleAllDay}
          value={recurrence.all_day}
        />
      </div>
      <div styleName="action item">
        <IconButton onClick={onRemove} style={{ padding: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
};

RecurrenceRow.propTypes = {
  deleteRecurrence: PropTypes.func,
  onAllDayToggle: PropTypes.func,
  onDayChange: PropTypes.func,
  onEndTimeChange: PropTypes.func,
  onStartTimeChange: PropTypes.func,
  recurrence: PropTypes.object
};

export default RecurrenceRow;
