// This component renders material-ui badge with an icon
// at the bottom right.
import React from 'react';
import PropTypes from 'prop-types';
import Badge from 'material-ui/Badge';
import { badgeIconStyles } from '@constants/mui-theme';

const BadgeIcon = ({ children, ...other }) => <Badge {...other}>{children}</Badge>;

BadgeIcon.propTypes = {
  badgeContent: PropTypes.object,
  children: PropTypes.node
};

BadgeIcon.defaultProps = badgeIconStyles.defaultProps;

export default BadgeIcon;
