import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import Divider from 'material-ui/Divider';
import FontIcon from 'material-ui/FontIcon';
import IconButton from 'material-ui/IconButton';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import {
  getConfirmableDeleteAndNavigateAction
} from '@actions/data-detail-actions';
import { openGroupAddEntityDialog } from '@actions/groups-actions';
import { dotmapsRed } from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import { topBarStyles } from '@constants/mui-theme';
import ActionDownloadIconButton from '@shared/action-download-icon-button';
import AddToGroupExcludeOverlapDialog from '@shared/dialogs/add-to-group-exclude-overlap-dialog';
import AddToGroupDialog from '@shared/dialogs/add-to-group-dialog';
import FlexSeparator from '@shared/helpers/flex-separator';
import { getDashboardBackURL } from '@utils/navigation-utils';
import { canEditGroups } from '@utils/permission-utils';
import { shallowEqual } from '@utils/react-utils';
import './top-bar.scss';

export class BaseTopBar extends Component {
  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowEqual(nextProps.data, this.props.data) ||
      !shallowEqual(nextProps.location, this.props.location) ||
      !shallowEqual(nextProps.modified, this.props.modified) ||
      !shallowEqual(nextProps.params, this.props.params)
    );
  }

  onExportClick = () => {};  // Implement in subclasses.

  getCustomBackLink = () => null;

  onBackClick = () => {
    const dataType = this.getDataType();
    const source = getDashboardBackURL(this.props.location, dataType, this.getCustomBackLink());
    this.props.push(source);
  };

  // Define data type and model in subclasses to call the ActivityLogs API:
  getDataType = () => {};
  getModel = () => {};

  getActivityLogDataType = () => this.getDataType();

  goToActivityLog = () => this.props.push(
    {
      pathname: `/logs/${this.getModel()}/${this.getActivityLogDataType()}/${this.props.data.id}/`,
      state: {source: `/${this.getDataType()}/${this.props.data.id}`}
    }
  );

  // No action button by default, subclasses might add it.
  renderSpinnerIndicator = () => null;
  renderActionButton = () => null;
  onActionButtonClick = () => {};
  getTypeIconName = () => null;
  getTypeIcon = () => null;
  renderNavigationIcons = () => {};

  // Subclasses (like conflicts) might show something else for the label.
  getName = () => {
    const { data } = this.props;
    const { dataId } = this.props.params;

    if (data && dataId) {
      return data.name;
    }

    return `Create ${getEntityTypeLabel(this.getDataType())}`;
  };

  renderExport = () => <ActionDownloadIconButton onClick={this.onExportClick} />;

  showDelete = () => false;  // Always false, override in subclasses.

  deleteItem = () => {
    const dataType = this.getDataType();
    const source = getDashboardBackURL(this.props.location, dataType);

    this.props.showConfirmationDialog(
      `Delete ${dataType}?`,
      `Are you sure you want to delete ${dataType} ${this.props.data.id}?`,
      getConfirmableDeleteAndNavigateAction(dataType, dataType, this.props.data.id, source)
    );
  };

  addToGroup = () => {
    const dataType = this.getDataType();
    this.props.openGroupAddEntityDialog(dataType, this.props.data);
  };

  getDeleteItem = () => <MenuItem key="delete" primaryText="DELETE" onClick={this.deleteItem} style={{ color: dotmapsRed }} />;

  getActivityLogItem = () => <MenuItem key="activity" primaryText="View activity log" onClick={this.goToActivityLog} />;

  getAddToGroupItem = () => <MenuItem key="add-to-group" primaryText="Add to group" onClick={this.addToGroup} />;

  getActionMenuItems = () => {
    // If we add more data types, we must add a check to include getAddToGroupItem()
    // only for entities (conflicts overrides getActionMenuItems(),
    // thus this is not needed.
    const actionItems = [this.getActivityLogItem()];
    if (canEditGroups()) {
      actionItems.push(this.getAddToGroupItem());
    }
    if (this.showDelete()) {
      actionItems.push(<Divider key="divider" />);
      actionItems.push(this.getDeleteItem());
    }
    return actionItems;
  };

  renderActionMenu = () => {
    const items = this.getActionMenuItems();
    if (items) {
      return (
        <div styleName="top-bar-actions">
          <IconMenu
            iconButtonElement={<IconButton><MoreVertIcon color="#FFF" /></IconButton>}
            anchorOrigin={{horizontal: 'middle', vertical: 'bottom'}}
            targetOrigin={{horizontal: 'left', vertical: 'top'}}
            desktop
          >
            {items.map(item => item)}
          </IconMenu>
        </div>
      );
    }
    return null;
  };

  // Tells if we are in edit mode (i.e. when the modified flag is set,
  // or when we don't have an entity id, which means we are on the edit page).
  isEditMode = () => this.props.modified || !this.props.params.dataId;

  render() {
    const { modified } = this.props;
    const { dataId } = this.props.params;
    // Show a different top bar background color, if we are in edit mode:
    const containerStyle = `top-bar-container ${this.isEditMode() ? 'top-bar-edit-mode' : ''}`;
    const backArrowColor = modified ? topBarStyles.editLinks.color : topBarStyles.links.color;
    return (
      <div styleName={containerStyle}>
        {dataId && (
          <IconButton onClick={this.onBackClick}>
            <FontIcon color={backArrowColor} className="material-icons">arrow_back</FontIcon>
          </IconButton>
        )}
        <div styleName="top-bar-icon">{this.getTypeIcon()}</div>
        <div styleName="top-bar-title">{this.getName()}</div>
        {dataId && modified && <div styleName="top-bar-unsaved">(Unsaved changes)</div>}
        <FlexSeparator />
        {this.renderSpinnerIndicator()}
        {this.renderActionButton()}
        {dataId && this.renderExport()}
        {dataId && !modified && this.renderNavigationIcons()}
        {dataId && !modified && this.renderActionMenu()}
        <AddToGroupDialog />
        <AddToGroupExcludeOverlapDialog />
      </div>
    );
  }
}

BaseTopBar.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  modified: PropTypes.bool,
  name: PropTypes.string,
  openGroupAddEntityDialog: PropTypes.func,
  params: PropTypes.object,
  push: PropTypes.func,
  showConfirmationDialog: PropTypes.func
};

export const mapDispatchToProps = {
  openGroupAddEntityDialog,
  push,
  showConfirmationDialog
};
