import React from 'react';
import axios from 'axios';
import CheckCircleIcon from 'material-ui/svg-icons/action/check-circle';
import * as colors from '@constants/colors';
import { BASE_API_URL } from '@constants/endpoints';
import {
  SETTINGS_FETCH_SUCCESS,
  HANDLE_SERVER_ERROR
} from '@constants/action-types';
import { pushApplicationMessage } from './messages-actions';
import { getServerErrorAction } from './shared-actions';
import './settings-actions.scss';

const fetchSettingsSuccess = payload => ({
  type: SETTINGS_FETCH_SUCCESS,
  payload
});

export const fetchUserSettings = () => dispatch => {
  const url = `${BASE_API_URL}/user_settings/`;
  const request = axios.get(url);
  request.then(
    data => dispatch(fetchSettingsSuccess({
      data: {
        email: data.data.email,
        notifications: data.data.notifications
      }
    })),
    error => dispatch(getServerErrorAction('settings', error))
  );
};

const successMessage = {
  message: (
    <div styleName="snackbar-success-message">
      <div styleName="icon">
        <CheckCircleIcon color={colors.dotmapsWhite80} />
      </div>
      <div styleName="text">
        Changes saved.
      </div>
    </div>
  )
};

export const saveUserSettings = payload => async dispatch => {
  const url = `${BASE_API_URL}/user_settings/`;
  try {
    await axios.patch(url, payload);
    dispatch(pushApplicationMessage(successMessage));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const fetchProfile = () => dispatch => {
  const url = `${BASE_API_URL}/profile/`;
  const request = axios.get(url);
  request.then(
    data => dispatch(fetchSettingsSuccess({
      data: {
        user: data.data
      }
    })),
    error => dispatch(getServerErrorAction('settings', error))
  );
};

export const saveProfile = user => async dispatch => {
  try {
    const payload = {
      name: user.name,
      phone: user.phone
    };
    await axios.patch(`${BASE_API_URL}/profile/`, payload);
    dispatch(pushApplicationMessage(successMessage));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};
