import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import NavigationChevronLeft from 'material-ui/svg-icons/navigation/chevron-left';
import NavigationChevronRight from 'material-ui/svg-icons/navigation/chevron-right';
import SlideInTransitionGroup from 'material-ui/internal/SlideIn';
import './toolbar.scss';

class Toolbar extends Component {
  state = {
    transitionDirection: 'up'
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.displayDate !== this.props.displayDate) {
      const nextDirection = this.context.muiTheme.isRtl ? 'right' : 'left';
      const prevDirection = this.context.muiTheme.isRtl ? 'left' : 'right';
      const direction = nextProps.displayDate > this.props.displayDate ? nextDirection : prevDirection;
      this.setState({
        transitionDirection: direction
      });
    }
  }

  handleTouchTapPrevMonth = () => {
    if (this.props.onMonthChange) {
      this.props.onMonthChange(-1);
    }
  };

  handleTouchTapNextMonth = () => {
    if (this.props.onMonthChange) {
      this.props.onMonthChange(1);
    }
  };

  render() {
    const {
      DateTimeFormat,
      locale,
      displayDate,
      showNextMonth,
      showPrevMonth
    } = this.props;

    const dateTimeFormatted = new DateTimeFormat(locale, {
      month: 'long',
      year: 'numeric'
    }).format(displayDate);

    const nextButtonIcon = this.context.muiTheme.isRtl ? <NavigationChevronLeft /> : <NavigationChevronRight />;
    const prevButtonIcon = this.context.muiTheme.isRtl ? <NavigationChevronRight /> : <NavigationChevronLeft />;

    return (
      <div styleName="custom-calendar-toolbar-container">
        {showPrevMonth &&
          <IconButton
            disabled={!this.props.prevMonth}
            onClick={this.handleTouchTapPrevMonth}
          >
            {prevButtonIcon}
          </IconButton>
        }
        <SlideInTransitionGroup
          direction={this.state.transitionDirection}
          styleName="custom-calendar-toolbar-title-container"
        >
          <div
            key={dateTimeFormatted}
            styleName="custom-calendar-toolbar-title-text">
            {dateTimeFormatted}
          </div>
        </SlideInTransitionGroup>
        {showNextMonth &&
          <IconButton
            disabled={!this.props.nextMonth}
            onClick={this.handleTouchTapNextMonth}
          >
            {nextButtonIcon}
          </IconButton>
        }
      </div>
    );
  }
}

Toolbar.propTypes = {
  DateTimeFormat: PropTypes.func.isRequired,
  displayDate: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  nextMonth: PropTypes.bool,
  onMonthChange: PropTypes.func,
  prevMonth: PropTypes.bool,
  showNextMonth: PropTypes.bool,
  showPrevMonth: PropTypes.bool
};

Toolbar.defaultProps = {
  nextMonth: true,
  prevMonth: true
};

Toolbar.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default Toolbar;
