import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import { loginStyles, resetRequestStyles } from '@constants/mui-theme';
import ActionButton from '@shared/action-button';
import '../../layouts/not-authenticated/not-authenticated.scss';

const ResetRequest = ({
  requestedResetStatus, emailErrorText, formChange, formSubmit
}) => {
  const dispatch = useDispatch();

  const gotoLogin = useCallback(() => dispatch(push('/login/')), [dispatch]);

  return (
    <div>
      <div styleName="auth-heading"> Reset Password </div>
      { (requestedResetStatus !== 201)
        ?
        <div>
          <div styleName="auth-section">
            <div>
              Please enter your email address. We will send you an email that will contain a link to a page where you can reset your password.
            </div>
          </div>
          <form onSubmit={formSubmit}>
            <div styleName="not-authenticated-form-text-field">
              <TextField
                fullWidth
                errorText={emailErrorText}
                onChange={formChange}
                name="email"
                floatingLabelText="Email address"
              />
            </div>
            <div>
              <ActionButton
                fullWidth
                value="Submit"
                type="submit"
                {...loginStyles.loginButton}
                label="SEND PASSWORD RESET"
              />
            </div>
          </form>
        </div>
        :
        <div styleName="auth-section">
          <div>
            If the email is correct, we will send a password reset to your email address.
          </div>
          <FlatButton {...resetRequestStyles.doneButton} label="Done" onClick={gotoLogin} />
        </div>
      }
    </div>
  );
};

ResetRequest.propTypes = {
  emailErrorText: PropTypes.string,
  formChange: PropTypes.func,
  formSubmit: PropTypes.func,
  requestedResetStatus: PropTypes.number
};

export default ResetRequest;
