import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Polygon, Polyline } from '@react-google-maps/api';
import wrapSymbolPath from '@components/entity/info/map/symbol-path-wrapper';
import { generatePath, generatePolygonPaths, zipCoordinatePair } from '@utils/map-utils';

class MapFigure extends PureComponent {
  renderPolygon = ({shape: {coordinates}, ...other }) => {
    let drawingProps = {};
    if (this.props.editable) {
      // Properties that are only needed in drawing mode.
      drawingProps = {
        ref: this.props.setPolygonRef,
        onMouseUp: this.props.onMouseUp
      };
    }
    return (
      <Polygon {...other} paths={generatePolygonPaths(coordinates)} {...drawingProps} />
    );
  };

  renderPolyline = ({shape: {coordinates}, ...other }) => (
    <Polyline {...other} path={generatePath(coordinates)} />
  );

  renderPoint = ({shape: {coordinates}, ...other }) => (
    <Polyline {...other} path={[zipCoordinatePair(coordinates), zipCoordinatePair(coordinates)]} />
  );

  render() {
    return R.cond([
      [R.pathEq('Polygon', ['shape', 'type']), this.renderPolygon],
      [R.pathEq('MultiLineString', ['shape', 'type']), this.renderPolygon],
      [R.pathEq('LineString', ['shape', 'type']), this.renderPolyline],
      [R.pathEq('Point', ['shape', 'type']), this.renderPoint]
    ])(this.props);
  }
}

MapFigure.propTypes = {
  editable: PropTypes.bool,
  onMouseUp: PropTypes.func,
  options: PropTypes.object,
  setPolygonRef: PropTypes.func,
  shape: PropTypes.object.isRequired
};

export default wrapSymbolPath(MapFigure);
