/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@icons/download-icon';
import { downloadAttachment } from './actions';
import './button.scss';

const DownloadButton = ({
  attachment
}) => {
  const downloadAction = () => {
    downloadAttachment(attachment);
  };

  return (
    <div 
      onClick={downloadAction}
      styleName="action-icon"
      role="presentation"
    >
      <DownloadIcon />
    </div>
  );
};

DownloadButton.propTypes = {
  attachment: PropTypes.object
};

export default DownloadButton;
