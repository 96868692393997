/* eslint-disable react/display-name */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Checkbox from 'material-ui/Checkbox';
import { BaseTrayFilter, baseMapDispatchToProps, baseMapStateToProps } from '../base-filter';
import {
  fetchGroupsData,
  setGroupsLayerVisible,
  setGroupsLayerBoundariesVisible,
  toggleGroupLayerBoundaries,
  toggleGroupLayerLabels
} from '@actions/groups-actions';
import { filterStyles } from '@constants/mui-theme';
import { getGroupIcon, getGroupLabelIcon } from '@utils/icon-utils';
import '../filter.scss';

class GroupsFilter extends BaseTrayFilter {
  onToggleClick = () => {
    if (this.props.filterEnabled) {
      this.props.setGroupsLayerVisible(false);
    } else {
      // Enabling the filter:
      const hasBounds = this.props.groups.some(group => group.bounds);
      if (!hasBounds) {
        // If the groups doesn't have the bounds set, it means they were loaded first
        // from a non-map page, thus Google Maps code was not there to calculate bounds.
        // So, fetch them again, since we are on a map page now:
        this.props.fetchGroupsData();
      }
      this.props.setGroupsLayerVisible(true);  // Make the layer visible.
      this.props.setGroupsLayerBoundariesVisible(true);  // Make boundaries visible (but not labels).
    }
    this.props.toggleMapFilterToggle('group');
  };

  toggleBoundaries = () => {
    this.props.toggleGroupLayerBoundaries(!this.props.showLayerBoundaries);
  };

  toggleLabels = () => {
    this.props.toggleGroupLayerLabels(!this.props.showLayerLabels);
  };

  getContent = () => {
    const {
      filterEnabled,
      showLayerBoundaries,
      showLayerLabels
    } = this.props;
    const labelIconStyle = { padding: '0 5px', height: '1rem', width: '1rem' };
    return (
      <div styleName="content-list-container">
        <div styleName="list-item">
          <div styleName="checkbox">
            <Checkbox
              {...filterStyles.check}
              disabled={!filterEnabled}
              checked={showLayerBoundaries}
              onCheck={this.toggleBoundaries}
            />
          </div>
          <img styleName="icon" src={getGroupIcon()} alt="" style={{ height: '1.5rem', width: '1.5rem' }} />
          <div styleName="label">
            Show group boundaries
          </div>
        </div>
        <div styleName="list-item">
          <div styleName="checkbox">
            <Checkbox
              {...filterStyles.check}
              disabled={!filterEnabled}
              checked={showLayerLabels}
              onCheck={this.toggleLabels}
            />
          </div>
          <div styleName="icon">
            <img
              alt=""
              src={getGroupLabelIcon()}
              style={!filterEnabled ? { ...labelIconStyle, opacity: '0.3' } : labelIconStyle}
            />
          </div>
          <div styleName="label">
            Show group labels
          </div>
        </div>
      </div>
    );
  };
}

GroupsFilter.propTypes = {
  fetchGroupsData: PropTypes.func,
  filterEnabled: PropTypes.bool,
  groups: PropTypes.array,
  next: PropTypes.string,
  setGroupsLayerBoundariesVisible: PropTypes.func,
  setGroupsLayerVisible: PropTypes.func,
  showLayerBoundaries: PropTypes.bool,
  showLayerLabels: PropTypes.bool,
  toggleGroupLayerBoundaries: PropTypes.func,
  toggleGroupLayerLabels: PropTypes.func
};

const mapStateToProps = state => {
  const { group: filterEnabled } = state.map.filterToggles;
  const { list, next, showLayerBoundaries, showLayerLabels } = state.groups;
  return {
    ...baseMapStateToProps(state),
    filterEnabled,
    groups: list,
    next,
    showLayerBoundaries,
    showLayerLabels
  };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  fetchGroupsData,
  setGroupsLayerBoundariesVisible,
  setGroupsLayerVisible,
  toggleGroupLayerBoundaries,
  toggleGroupLayerLabels
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupsFilter);
