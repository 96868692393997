import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import { openGroupExcludeOverlapDialog } from '@actions/groups-actions';
import { dotmapsGray } from '@constants/colors';
import { canEditGroups } from '@utils/permission-utils';

class MoreButton extends Component {
  allowAddToGroup = () => canEditGroups();

  excludeOverlap = () => {
    const { detailsDataType, entityType, entity, groups } = this.props;
    this.props.openGroupExcludeOverlapDialog(detailsDataType, entityType, entity.id, groups);
  };

  clickHandler = event => event.stopPropagation();

  render() {
    const { groups } = this.props;
    if (isEmpty(groups) || !this.allowAddToGroup()) {
      return null;
    }
    return (
      <IconMenu
        onClick={this.clickHandler}
        iconButtonElement={
          <IconButton style={{ padding: '0 0 0 10px', border: 'none', width: 'auto', height: 'auto' }}>
            <FontIcon className="material-icons" color={dotmapsGray}>more_vert</FontIcon>
          </IconButton>
        }
        anchorOrigin={{horizontal: 'left', vertical: 'top'}}
        targetOrigin={{horizontal: 'left', vertical: 'top'}}
        desktop
      >
        <MenuItem
          primaryText="Exclude overlap"
          onClick={this.excludeOverlap}
        />
      </IconMenu>
    );
  }
}

MoreButton.propTypes = {
  detailsDataType: PropTypes.string,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  groups: PropTypes.object,
  openGroupExcludeOverlapDialog: PropTypes.func
};

export default connect(null, { openGroupExcludeOverlapDialog })(MoreButton);
