import React from 'react';
import PropTypes from 'prop-types';
import '../../../../section/section.scss';

const Header = ({ count }) => (
  <div styleName="section-header">
    <div styleName="section-header-title">
      <div styleName="section-header-title-text">Comments</div>
      <div styleName="section-header-title-count">{count}</div>
    </div>
  </div>
);

Header.propTypes = {
  count: PropTypes.number
};

export default Header;
