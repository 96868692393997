import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import FontIcon from 'material-ui/FontIcon';
import { mapTabsConfig } from '@constants/component-configs';
import '../tray.scss';

const Details = () => {
  const { currentTab } = useSelector(state => state.map);

  const isDetailsTab = currentTab === mapTabsConfig.tabs.details.value;
  if (!isDetailsTab) {
    return null;
  }

  return (
    <div styleName="map-tray-body-wrapper">
      <div styleName="map-tray-body-empty map-tray-body-shadow">
        <FontIcon
          className="material-icons"
          {...mapTabsConfig.tray.emptyIconProps}
        >place</FontIcon>
        <div styleName="map-tray-body-empty-content">
          Click on a marker to see details.
        </div>
      </div>
    </div>
  );
};

export default memo(Details);
