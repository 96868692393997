/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import LateLabel from '@shared/workflow/late-label';
import TaskStatusChip from '@shared/workflow/task-status-chip';
import { findTaskStatus, isLate } from '@utils/workflow-utils';
import './list-row.scss';

const ListRow = ({ activity, data, onClick, selected }) => {
  const { agency: agencies, task_status: taskStatuses } = useSelector(state => state.dataTypes);

  const agency = agencies[activity.agency];
  const taskStatus = findTaskStatus(activity.status_name, taskStatuses);
  const late = isLate(activity, data.due_date);
  const style = `row-container ${selected ? 'row-active' : ''}`;
  const labelStyle = `row-name-label ${late ? 'row-name-label-late' : ''}`;
  const handleClick = useCallback(() => onClick(activity), [activity, onClick]);

  return (
    <div onClick={handleClick} role="presentation" styleName={style}>
      <div styleName="row-name">
        <div styleName={labelStyle}>
          {agency.name}
        </div>
      </div>
      <div styleName="row-status">
        <TaskStatusChip taskStatus={taskStatus} />
        {late && <LateLabel />}
      </div>
    </div>
  );
};

ListRow.propTypes = {
  activity: PropTypes.object,
  data: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool
};

export default ListRow;
