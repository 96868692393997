/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-bind */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import './details-layout-drawer.scss';

export const AddButton = ({ onAdd }) => (
  <div styleName="edit-row" key="add-option-button">
    <Button color="primary" onClick={onAdd} startIcon={<AddIcon />}>ADD OPTION</Button>
  </div>
);

AddButton.propTypes = {
  onAdd: PropTypes.func
};

const TaskTypeDrawerEditOptions = ({ options, setOptions }) => {
  const handleNameChange = (event, index) => {
    const newOptions = [...options];
    newOptions[index] = {
      ...newOptions[index],
      name: event.target.value
    };
    setOptions(newOptions);
  };

  const onAdd = () => setOptions([...options, { name: '' }]);

  const onRemove = index => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  return (
    <Fragment>
      {options.map((option, index) => (
        <div styleName="edit-row" key={`option-row-${index}`}>
          <div styleName="row-left">
            <TextField
              fullWidth
              size="small"
              value={option.name}
              onChange={event => handleNameChange(event, index)}
            />
          </div>
          <div
            onClick={() => onRemove(index)}
            role="presentation"
            styleName="row-right row-action"
          >
            <CloseIcon />
          </div>
        </div>
      ))}
      <AddButton onAdd={onAdd} />
    </Fragment>
  );
};

TaskTypeDrawerEditOptions.propTypes = {
  options: PropTypes.array,
  setOptions: PropTypes.func
};

export default TaskTypeDrawerEditOptions;
