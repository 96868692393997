/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import FontIcon from 'material-ui/FontIcon';
import { setDatatableFilter } from '@actions/data-table-actions';
import {
  dotmapsBlack50,
  dotmapsBlack80
} from '@constants/colors';
import {
  dataTableSortIconButtonStyles
} from '@constants/mui-theme';
import { getFilters } from '@selectors/data-table-selector';
import {
  activateFilter,
  isCurrentFilter,
  isFilterAscending,
  setFirstFilter
} from '@utils/filter-utils';

class SortIconButton extends Component {
  constructor(props) {
    super(props);
    if (props.column) {
      const { column: { ordering }, dataType, subType } = props;
      this.setAscending = () => props.setDatatableFilter(dataType, subType, { ordering: this.setMainFilter(ordering) });
      this.setDescending = () =>
        props.setDatatableFilter(dataType, subType, { ordering: this.setMainFilter(`-${ordering}`) });
      this.setActive = () => props.setDatatableFilter(dataType, subType, { ordering: this.setActiveFilter(ordering) });
    }
  }

  setMainFilter = filter => setFirstFilter(filter, this.props.filters);

  setActiveFilter = filter => activateFilter(filter, this.props.filters);

  getSortIcon = isAscending => {
    const { column: { flipOrderIcon } } = this.props;
    let ascending = isAscending;
    if (flipOrderIcon) {
      ascending = !ascending;
    }
    return ascending ? 'arrow_upward' : 'arrow_downward';
  };

  render() {
    if (this.props.hidden) {
      const hiddenStyles = {
        ...dataTableSortIconButtonStyles,
        style: {
          ...dataTableSortIconButtonStyles.style,
          // Opacity = zero, hides the icon but maintains the space.
          opacity: 0
        }
      };
      return (
        <IconButton {...hiddenStyles}>
          <FontIcon className="material-icons">arrow_upward</FontIcon>
        </IconButton>
      );
    }
    const { filters, column: { ordering } } = this.props;
    if (!filters) {
      return null;
    }
    const isCurrent = isCurrentFilter(filters, ordering);
    const isAscending = isFilterAscending(filters, ordering);
    const color = isCurrent ? dotmapsBlack80 : dotmapsBlack50;
    // First click on the icon button (i.e. isCurrent is false), will set this
    // filter as active, but won't change the direction.
    // Subsequent clicks (i.e. when isCurrent is true) will change the direction.
    return (
      <IconButton
        {...dataTableSortIconButtonStyles}
        onClick={isCurrent ? isAscending ? this.setDescending : this.setAscending : this.setActive}
      >
        <FontIcon color={color} className="material-icons">
          {this.getSortIcon(isAscending)}
        </FontIcon>
      </IconButton>
    );
  }
}

SortIconButton.propTypes = {
  column: PropTypes.object.isRequired,
  dataType: PropTypes.string.isRequired,
  filters: PropTypes.object,
  hidden: PropTypes.bool,
  setDatatableFilter: PropTypes.func,
  subType: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const filters = getFilters(state, props);
  return { filters };
};

export default connect(mapStateToProps, { setDatatableFilter })(SortIconButton);

export { SortIconButton as PureSortIconButton };
