import { connect } from 'react-redux';
import { openDashboardDialog } from '@actions/dashboard-actions';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getFilters } from '@selectors/data-table-selector';
import DateFilter from '@shared/top-filter-bar/date-filter/past-date-filter';
import { decodeSingleDateFilters } from '@utils/date-utils';

class PastDateFilter extends DateFilter {
  setFilter(filter) {
    const { dataType, subType } = this.props;
    this.props.setFilter(dataType, subType, filter);
  }
}

PastDateFilter.defaultProps = {
  multiple: false
};

export const mapStateToProps = (state, props) => {
  const { fieldName } = props;
  const filters = getFilters(state, props);
  const { start, end } = decodeSingleDateFilters(filters, fieldName);
  return { start, end, contain: false };
};

export default connect(mapStateToProps, { openDialog: openDashboardDialog, setFilter: setDatatableFilter })(PastDateFilter);
