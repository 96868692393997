import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { BASE_API_URL } from '@constants/endpoints';
import { getViewTypeOrGenerateForURL } from '@utils/workflow-utils';

const TaskRedirect = ({ match: { params: { id, taskId, taskActivityId } } }) => {
  const [task, setTask] = useState(null);

  const fetch = async url => {
    const response = await axios.get(url);
    setTask(response.data);
  };

  useEffect(() => {
    fetch(`${BASE_API_URL}/task/${taskId}/`);
  }, [taskId]);

  if (task) {
    let viewType = '';
    if (task.activities.length > 0) {
      const activity = task.activities.find(act => act.id === parseInt(taskActivityId, 10));
      viewType = getViewTypeOrGenerateForURL(null, task, activity);
    }
    const pathname = `/cycle/${id}/task/${taskId}/activity/${taskActivityId}${viewType}`;
    location.replace(pathname);
  }

  return null;
};

TaskRedirect.propTypes = {
  match: PropTypes.object
};

export default memo(TaskRedirect);
