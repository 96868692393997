import React, { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import * as dialog from '@constants/dialogs';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const BulkDeleteFilesDialog = () => {
  const dispatch = useDispatch();

  const { activeDialogs, dialogParams } = useSelector(state => state.dashboard);

  const isDialogActive = activeDialogs[dialog.BULK_DELETE_FILES];

  const onClose = useCallback(
    () => {
      dispatch(closeDashboardDialog(dialog.BULK_DELETE_FILES));
    },
    [dispatch]
  );

  const onDelete = useCallback(
    () => {
      const { bulkDeleteFiles, ids } = dialogParams[dialog.BULK_DELETE_FILES] || {};
      bulkDeleteFiles(ids);
      onClose();
    },
    [dialogParams, onClose]
  );

  if (!dialogParams || !activeDialogs[dialog.BULK_DELETE_FILES]) {
    return null;
  }

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>Delete Files?</DialogTitle>
      <DialogContent>
        Are you sure you want to delete the selected files?
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="secondary"
          onClick={onDelete}
          variant="contained"
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(BulkDeleteFilesDialog);
