/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/display-name */
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AssignmentIcon from 'material-ui/svg-icons/action/assignment';
import { fetchTooltipData } from '@actions/tooltips-actions';
import BaseTooltip from '@components/activity-logs/tooltips';
import DefaultDate from '@shared/formatted-date-helper';
import './tooltip.scss';

class TaskTooltip extends BaseTooltip {
  getDataType = () => 'task';

  getTitle = () => (
    <Fragment>
      <div styleName="body-title-icon">
        <AssignmentIcon />
      </div>
      <div styleName="body-title-text">
        {this.props.data.task_type_name}
      </div>
    </Fragment>
  );

  renderHeader = () => null;

  renderContent = () => {
    const { data } = this.props;
    return (
      <div styleName="body-content">
        <div styleName="body-row body-item">
          {data.name}
        </div>
        <div styleName="body-row">
          Due&nbsp;<DefaultDate value={data.due_date} />
        </div>
      </div>
    );
  };

  renderClickLabel = () => 'VIEW TASK';

  onLinkClick = () => {
    const { id, data } = this.props;
    this.openInNewWindow(`/cycle/${data.cycle}/task/${id}`);
  };

  onMouseOver = event => {
    const { id, data } = this.props;
    this.delayHoverOn(event);
    if (!data) {
      this.props.fetchTooltipData('task', null, id);
    }
  };
}

TaskTooltip.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const mapStateToProps = (state, props) => {
  const { task, error: { task: error } } = state.tooltips;
  return { data: task[props.id], error };
};

const mapDispatchToProps = { fetchTooltipData };

export default connect(mapStateToProps, mapDispatchToProps)(TaskTooltip);
