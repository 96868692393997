/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import './fragment-layout.scss';

const FragmentLayout = (
  LoadingComponent, DrawerComponent, ContentComponent, SubNavComponent, TrayComponent, OverlayComponent
) => props => (
  <div styleName="fragment-layout">
    <LoadingComponent />
    {SubNavComponent && (
      <div styleName="fragement-sub-nav">
        <SubNavComponent location={props.location} />
      </div>
    )}
    <div styleName="fragment-content">
      {TrayComponent &&
        <div styleName="fragment-tray">
          <TrayComponent location={props.location} />
        </div>
      }
      {DrawerComponent &&
        <div styleName="fragment-drawer">
          <DrawerComponent {...props} />
        </div>
      }
      <div styleName="fragment-content">
        <ContentComponent {...props} />
      </div>
    </div>
    {OverlayComponent && <OverlayComponent />}
  </div>
);

export default FragmentLayout;
