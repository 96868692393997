/* global google */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { emptyAutocompleteValues, requestAutocompleteValues } from '@actions/dashboard-actions';
import {
  setGoogleAutocompleteSuggestions,
  setSearchTerm,
  showAutocompleteError
} from '@actions/map-actions';
import { dotmapsBlack40, dotmapsBlack60 } from '@constants/colors';
import ActionIconButton from '@shared/action-icon-button';
import { getConfigKey } from '@utils/config-utils';
import './input.scss';

/*
 * Global search input component.
 *
 * We handle in a single component the Google Places Autocomplete API with
 * our own backend autocomplete API.
 */
class Input extends Component {
  constructor(props) {
    super(props);
    this.onError = this.props.showAutocompleteError;
    this.debouncedFetchPredictions = debounce(this.fetchPredictions, 200);
    this.debouncedFetchBackendSuggestions = debounce(this.fetchBackendSuggestions, 500);
  }

  componentDidMount() {
    if (!window.google) {
      // See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library
      throw new Error('Google Maps JavaScript API library must be loaded');
    }

    if (!window.google.maps.places) {
      // If you see this error, add `libraries=places` to the src URL.
      // See: https://github.com/kenny-hibino/react-places-autocomplete#load-google-library
      throw new Error('Google Maps Places library must be loaded.');
    }

    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.autocompleteOK = google.maps.places.PlacesServiceStatus.OK;
  }

  onChange = event => {
    const value = event.target.value;
    this.setTerm(value);
  };

  clear = () => {
    this.setTerm('');
  };

  setTerm = term => {
    this.props.setSearchTerm(term);
    this.debouncedFetchPredictions();
    this.debouncedFetchBackendSuggestions();
  };

  autocompleteCallback = (predictions, status) => {
    if (status !== this.autocompleteOK) {
      // If it's ZERO_RESULTS, skip showing that in
      // the SnackBar, since we already show 'No results found'
      // on the UI.
      // Only call the onError() callback if it's other
      // kind of error.
      if (status !== 'ZERO_RESULTS') {
        this.onError(status);
      }
      this.props.setGoogleAutocompleteSuggestions([]);
      return;
    }
    const stateRestriction = getConfigKey('autocompleteState');
    if (stateRestriction) {
      const filteredPredictions = predictions.filter(prediction => {
        if (prediction?.terms?.length > 1) {
          const stateIndex = prediction.terms.length - 2;
          const state = prediction.terms[stateIndex].value;
          // If we correctly matched the term to the "state" part
          // and it's not the state we have configured, skip the entry.
          if (state.length === 2 && state !== stateRestriction) {
            return false;
          }
        }
        return true;
      });
      this.props.setGoogleAutocompleteSuggestions(filteredPredictions);
    } else {
      this.props.setGoogleAutocompleteSuggestions(predictions);
    }
  };

  fetchPredictions() {
    const { term } = this.props;
    if (term.length) {
      this.autocompleteService.getPlacePredictions({
        locationRestriction: getConfigKey('autocompleteBounds'),
        componentRestrictions: { country: 'us' },
        input: term
      }, this.autocompleteCallback);
    } else {
      // Clear suggestions:
      this.props.setGoogleAutocompleteSuggestions([]);
    }
  }

  fetchBackendSuggestions() {
    const { term } = this.props;
    if (term.length) {
      this.props.requestAutocompleteValues('common', term);
    } else {
      this.props.emptyAutocompleteValues();
    }
  }

  render() {
    const { term } = this.props;
    return (
      <div styleName="search-input">
        <div styleName="search-input-wrapper">
          <ActionIconButton compact icon="search" color={dotmapsBlack40} />
          <input
            type="text"
            onChange={this.onChange}
            placeholder="Search records and locations"
            value={term}
          />
          {term.length > 0 && (
            <ActionIconButton compact icon="close" color={dotmapsBlack60} click={this.clear} />
          )}
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  emptyAutocompleteValues: PropTypes.func,
  requestAutocompleteValues: PropTypes.func,
  setGoogleAutocompleteSuggestions: PropTypes.func,
  setSearchTerm: PropTypes.func,
  showAutocompleteError: PropTypes.func,
  term: PropTypes.string
};

const mapStateToProps = state => {
  const { term } = state.map.traySearch;
  return { term };
};

const mapDispatchToProps = {
  emptyAutocompleteValues,
  requestAutocompleteValues,
  setGoogleAutocompleteSuggestions,
  setSearchTerm,
  showAutocompleteError
};

export default connect(mapStateToProps, mapDispatchToProps)(Input);
