/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { capitalize, chain, includes } from 'lodash';
import * as R from 'ramda';
import moment from 'moment-timezone';
import Avatar from 'material-ui/Avatar';
import FlatButton from 'material-ui/FlatButton';
import FontIcon from 'material-ui/FontIcon';
import ActivityLogsIcon from '@components/activity-logs/icon';
import EntityTooltip from '@components/activity-logs/tooltips/entity-tooltip';
import CycleTooltip from '@components/activity-logs/tooltips/cycle-tooltip';
import TaskTooltip from '@components/activity-logs/tooltips/task-tooltip';
import TaskActivityTooltip from '@components/activity-logs/tooltips/task-activity-tooltip';
import UserTooltip from '@components/activity-logs/tooltips/user-tooltip';
import { dotmapsBlue, dotmapsRed } from '@constants/colors';
import { getEntityTypeLabel } from '@constants/config';
import { badgeAvatarStyles, logEntryLinkButton } from '@constants/mui-theme';
import ConflictBothSidesIcon from '@icons/activity-logs/conflict-both-sides';
import ConflictResolveIcon from '@icons/activity-logs/conflict-resolve';
import ConflictRevokeIcon from '@icons/activity-logs/conflict-revoke';
import NoUserIcon from '@icons/activity-logs/no-user-icon';
import BadgeAvatar from '@shared/badge-avatar';
import { renderCommentWithMentions } from '@utils/notification-utils';
import { parseDate, parseDateTime } from '@utils/shared-utils';
import './logs-utils.scss';

export const renderBold = text => (
  <div styleName="log-item-bold">
    {text}
  </div>
);

export const renderCategory = attrs => renderBold(attrs.category_type.label.toLowerCase());

export const renderEntityId = (id, type) => (
  <EntityTooltip id={id} dataType={type} text={renderBold(`${capitalize(getEntityTypeLabel(type))} ID ${id}`)} />
);

export const renderIdFromAttrs = attrs => {
  const { entity, entity1, id, id1 } = attrs || {};
  if (entity === 'cycle') {
    return <CycleTooltip id={id} text={renderBold(`Cycle ID ${id}`)} />;
  }
  if (entity === 'task') {
    return <TaskTooltip id={id} text={renderBold(`Task ID ${id}`)} />;
  }
  if (entity === 'task activity') {
    return <TaskActivityTooltip id={id} text={renderBold(`Task Activity ID ${id}`)} />;
  }
  return renderEntityId(id || id1, entity || entity1);
};

export const renderOfEntityId = attrs => {
  const { entity, id } = attrs;
  if (entity && id) {
    return <span> of {renderEntityId(id, entity)}</span>;
  }
  return '';
};

export const renderToEntityId = attrs => {
  if (attrs.id) {
    return <span> to {renderEntityId(attrs.id, attrs.entity)}</span>;
  }
  return '';
};

export const renderUpdated = attrs => (
  <div styleName="log-item-updated">
    (Updated: {attrs.fields})
  </div>
);

// Parse the activity log created time and formats it in 0:00 AM/PM format:
export const renderItemTime = time => {
  const parsed = parseDateTime(time);
  return parsed.format('h:mm A');
};

// Like render more time data, since this is the tooltip
// for the time we'll render with reanderItemTime().
export const renderItemTooltipTime = time => {
  const parsed = parseDateTime(time);
  return parsed.format('h:mm:ss A');
};

// Render an avatar for the specified user.
export const renderItemAvatar = (user, size = 24) => {
  if (!user) {
    return <NoUserIcon />;
  }
  const lineHeight = `${size}px`;
  const initial = user.name ? user.name.substring(0, 1) : '';
  if (!user.is_active) {
    const icon = (
      <FontIcon
        color={dotmapsRed}
        style={{fontSize: 14}}
        className="material-icons"
      >
        block
      </FontIcon>
    );
    return (
      <BadgeAvatar
        badgeContent={icon}
        style={badgeAvatarStyles.style}
        badgeStyle={badgeAvatarStyles.badgeStyle[size]}
      >
        <Avatar
          size={size}
          style={{lineHeight, opacity: 0.2}}
        >
          {initial}
        </Avatar>
      </BadgeAvatar>
    );
  }
  return (
    <Avatar
      size={size}
      style={{lineHeight}}
    >
      {initial}
    </Avatar>
  );
};
export const ItemAvatar = (props) => renderItemAvatar(props.user, props.size || 24);

const renderItemUser = (user, current_pms) => {
  if (user) {
    return (
      <div styleName="log-item-username-container">
        <div styleName="log-item-username-name">
          <UserTooltip id={user.id} text={user.name === '' ? 'User' : user.name} />
          {!user.is_active && (
            <div styleName="log-item-user-additional">
              &nbsp;(Inactive)
            </div>
          )}
          {user.agency_name && (
            <div styleName="log-item-user-additional">
              &nbsp;({user.agency_name})
            </div>
          )}
        </div>
        {current_pms && includes(current_pms, user.id) && (
          <div styleName="log-item-username-icon">
            <ActivityLogsIcon action="_pseudo_action_current_pm" />
          </div>
        )}
      </div>
    );
  }
  return '';
};
export const ItemUser = (props) => renderItemUser(props.user, props.current_pms);

// Renders the Activity Log message.
export const renderItemMessage = (actions, user, action, attrs, model, type, current_pms) => {  // eslint-disable-line max-params
  const actionAttributes = actions[action];
  if (actionAttributes) {
    const template = actionAttributes.template(attrs, user, model, type);
    return (
      <div styleName="log-item-message-container">
        {renderItemUser(user, current_pms)} {template}
      </div>
    );
  }
  return null;
};

// Renders log message extra content.
export const renderItemExtra = (actions, props) => {
  const actionEntry = actions[props.action];
  if (actionEntry && actionEntry.extra) {
    return actionEntry.extra(props);
  }
  return null;
};

export const renderItemIsPMIcon = attrs => {
  if (attrs.pm_id) {
    return <ActivityLogsIcon action="_pseudo_action_pm" />;
  }
  return null;
};

const logDay = log => moment(log.entry.created).startOf('day')
  .format('YYYY-MM-DD');

const groupToDay = (logs, day) => ({ logs, day });

// Group the activity log entries by day.
export const groupLogs = logs => chain(logs)
  .filter(item => item.entry.action !== 'entity_comment')
  .groupBy(logDay)
  .map(groupToDay)
  .sortBy('day')
  .orderBy('day', 'desc')
  .value();

export const isToday = date => date.isSame(moment().startOf('day'), 'd');
export const isYesterday = date => date.isSame(moment().subtract(1, 'days')
  .startOf('day'), 'd');

export const renderGroupItemDate = date => {
  const parsed = parseDate(date);
  const displayDate = parsed.format('M/D/YYYY');
  if (isToday(parsed)) {
    return `Today (${displayDate})`;
  } else
    if (isYesterday(parsed)) {
      return `Yesterday (${displayDate})`;
    }
  return `${parsed.format('dddd')}, ${displayDate}`;
};

const renderIdsInTooltip = (entity, ids) => {
  return ids.map((id, index) => (
    <span
      key={`${entity}_${id}_${index}`}
    >
      <EntityTooltip
        id={id}
        text={renderBold(id)}
        dataType={entity}
      />{index < ids.length - 1 ? <span>,&nbsp;</span> : ''}
    </span>
  ));
};

const renderIds = (attrs, ids, entity, hellip = '') => (
  <div styleName="log-ids-list">
    {capitalize(entity)}&nbsp;IDs:&nbsp;{renderIdsInTooltip(entity, ids)}{hellip}
  </div>
);

const showFullIdList = index => {
  document.getElementById(`log-entry-squeezed-${index}`).style.display = 'none';
  document.getElementById(`log-entry-full-${index}`).style.display = 'inline';
  document.getElementById(`log-entry-link-all-${index}`).style.display = 'none';
  document.getElementById(`log-entry-link-less-${index}`).style.display = 'inline';
};

const showLessIdList = index => {
  document.getElementById(`log-entry-squeezed-${index}`).style.display = 'inline';
  document.getElementById(`log-entry-full-${index}`).style.display = 'none';
  document.getElementById(`log-entry-link-all-${index}`).style.display = 'inline';
  document.getElementById(`log-entry-link-less-${index}`).style.display = 'none';
};

const renderTruncatedContent = (index, truncatedText, fullText) => (
  <span>
    <div id={`log-entry-squeezed-${index}`} styleName="log-ids-list">
      {truncatedText}
    </div>
    <div id={`log-entry-full-${index}`} style={{ display: 'none' }}>
      {fullText}
    </div>
    <br />
    <div id={`log-entry-link-less-${index}`} style={{ display: 'none' }}>
      <FlatButton
        {...logEntryLinkButton}
        onClick={() => {
          showLessIdList(index);
        }}
        label="SHOW LESS"
      />
    </div>
    <div id={`log-entry-link-all-${index}`}>
      <FlatButton
        {...logEntryLinkButton}
        onClick={() => {
          showFullIdList(index);
        }}
        label="SHOW ALL"
      />
    </div>
  </span>
);

const COMMENT_TRUNCATE_SIZE = 128;

/* eslint-disable id-length */
const getTruncatedComment = comment => {
  // If the comment has a mention, make sure we include it within the truncated text.
  if (comment.indexOf('|') > -1) {
    const truncated = [];
    let open = false;
    for (let i = 0; i < comment.length; i++) {  // eslint-disable-line no-plusplus
      const c = comment[i];
      truncated.push(c);
      if (c === '|') {
        open = !open;
      }
      if (i < COMMENT_TRUNCATE_SIZE || open) {
        continue;
      }
      break;
    }
    return truncated.join('');
  }

  // No mentions, truncate normally:
  return comment.substring(0, COMMENT_TRUNCATE_SIZE);
};
/* eslint-enable id-length */

// Renders an activity log comment, truncating if it's large (but allowing
// the user to expand it to see the full message).
export const renderComment = (index, comment, users) => {
  if (!comment) {
    return null;
  }
  const truncated = getTruncatedComment(comment);
  // If the comment is small or if the truncated comment has the same
  // size than the full comment (because due to user mentions
  // we had to include all text until the end of the mention), then
  // render without truncation:
  if (comment.length < COMMENT_TRUNCATE_SIZE || truncated === comment) {
    return renderCommentWithMentions(comment, users);
  }
  return renderTruncatedContent(
    index,
    <span>{renderCommentWithMentions(truncated, users)}&hellip;</span>,
    <span>{renderCommentWithMentions(comment, users)}<br /></span>
  );
};

// Render the squeezed ids entry.
export const renderSqueezedIds = (attrs, index, entity) => {
  if (!attrs.ids) {
    return null;
  }
  // Sort ids before display:
  attrs.ids.sort((a, b) => a - b);  // eslint-disable-line id-length

  if (attrs.ids.length > 15) {
    const ids = attrs.ids.slice(0, 15);
    return renderTruncatedContent(
      index,
      renderIds(attrs, ids, entity, renderBold(<span>, &hellip;</span>)),
      renderIds(attrs, attrs.ids, entity)
    );
  }
  return renderIds(attrs, attrs.ids, entity);
};

export const renderOverlapCreatedType = attrs => {
  if (attrs.is_opportunity === true) {
    return '(Type: Opportunity)';
  } else
    if (attrs.is_opportunity === false) {
      return '(Type: Conflict)';
    }
  // If it's not true or false, it's undefined, thus return
  // an empty string, which means we won't render the type.
  // This is for legacy migrated entries for which we don't
  // know the exact overlap type.
  return '';
};

export const getConflictActionIcon = (action, bothSides, color = dotmapsBlue) => {
  if (action === 'overlap_resolve') {
    if (bothSides) {
      return <ConflictBothSidesIcon color={color} />;
    }
    return <ConflictResolveIcon color={color} />;
  }
  return <ConflictRevokeIcon />;
};

export const renderConflictExtra = (index, attrs, action, users) => {
  if (attrs.id1 && attrs.id2) {
    const entity1 = attrs.entity1;
    const entity2 = attrs.entity2;
    const icon = getConflictActionIcon(action, attrs.both_sides);
    const id1Lead = attrs.id1 === attrs.lead_id ? `(Lead ${capitalize(entity1)})` : '';
    const id2Lead = attrs.id2 === attrs.lead_id ? `(Lead ${capitalize(entity2)})` : '';
    return (
      <div styleName="log-item-conflict-extra-container">
        <div styleName="entity-row">
          <div styleName="id1">
            <EntityTooltip id={attrs.id1} dataType={entity1} text={renderBold(`${capitalize(entity1)} ID ${attrs.id1} ${id1Lead}`)} />
          </div>
          <div styleName="icon">
            {icon}
          </div>
          <div styleName="id2">
            <EntityTooltip id={attrs.id2} dataType={entity2} text={renderBold(`${capitalize(entity2)} ID ${attrs.id2} ${id2Lead}`)} />
          </div>
        </div>
        {attrs.reason && (
          <div styleName="resolution-row">
            Resolution: {attrs.reason}
          </div>
        )}
        {attrs.notes && (
          <div styleName="description-row">
            {renderComment(index, `Description: ${attrs.notes}`, users)}
          </div>
        )}
      </div>
    );
  }
  return null;
};

// Appends 'through batch upload' for batch file generated activities.
export const appendBatch = attrs => {
  if (attrs && attrs.batch) {
    return ' through batch upload';
  }
  return '';
};

export const renderOwners = owners => renderBold(owners.map(owner => owner.name).join(', '));

export const getConflicts = attrs => R.propOr([], ['conflicts'], attrs);

export const getOpportunities = attrs => R.propOr([], ['opportunities'], attrs);

export const countOverlaps = attrs => R.pathOr(0, ['conflicts', 'length'], attrs) + R.pathOr(0, ['opportunities', 'length'], attrs);
