import React from 'react';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import transitions from 'material-ui/styles/transitions';
import hexToRgba from 'hex-rgba';
import * as colors from '@constants/colors';
// We got to use a custom icon for the dropdown caret to be darker,
// because the DropDownMenu component (which SelectField uses), doesn't
// have a way to customize the iconButton's colors.
import DropDownArrowIcon from '@icons/arrow-drop-down-icon';

export const muiThemePalette = {
  textColor: colors.dotmapsGray,
  primary1Color: colors.dotmapsBlue,
  primary2Color: colors.dotmapsLightBlue
};

const customMuiTheme = getMuiTheme({ palette: muiThemePalette });

const muiTheme = {
  ...customMuiTheme,
  chip: {
    ...customMuiTheme.chip,
    deleteIconColor: 'rgba(0, 0, 0, 0.26)'
  },
  menuItem: {
    ...customMuiTheme.menuItem,
    selectedTextColor: colors.dotmapsBlue
  },
  textField: {
    ...customMuiTheme.textField,
    textColor: colors.dotmapsBlack80,
    floatingLabelColor: colors.dotmapsBlack60,
    disabledTextColor: colors.dotmapsBlack40,
    errorColor: colors.dotmapsError
  },
  snackbar: {
    ...customMuiTheme.snackbar,
    actionColor: colors.dotmapsSnackbarAction
  },
  tooltip: {
    ...customMuiTheme.tooltip,
    rippleBackgroundColor: colors.dotmapsBlack60
  }
};

// Style guide:
export const defaultInputStyle = {
  fontSize: '0.875rem'
};

const defaultUnderlineDisabledStyle = {
  borderBottomWidth: '1px',
  borderColor: colors.dotmapsBlack20
};

const defaultUnderlineStyle = {
  borderColor: colors.dotmapsBlack40
};

const underlineStyles = {
  underlineDisabledStyle: defaultUnderlineDisabledStyle,
  underlineStyle: defaultUnderlineStyle
};

const inputStyles = {
  inputStyle: defaultInputStyle,
  floatingLabelStyle: {
    fontSize: '0.875rem'
  }
};

const defaultStyles = {
  ...underlineStyles,
  ...inputStyles
};

const defaultFloatingLabelStyle = {
  transform: 'scale(0.85) translate(0, -28px)',
  pointerEvents: 'none'
};

export const styleGuide = {
  select: {
    ...defaultStyles,
    // With 'fullWidth' we make the dropdown popover to match
    // the TextField's width.
    fullWidth: true,
    dropDownMenuProps: {
      iconButton: <span className="select-dropdown-icon"><DropDownArrowIcon color={colors.dotmapsGrayInactive} /></span>,
      menuStyle: {
        // Related to the 'fullWidth' key above, of the dropdown menu
        // contents exceeds the TextField's width, they make
        // scrollbars appear, with 'max-content' we above that,
        // so the dropdown always either matches the TextField's
        // width or exceeds it if it has longer items.
        //
        // Another way to do this is to use 'autoWidth',
        // but that causes the DropDownMenu component
        // (called within SelectField) to calculate
        // the menu's width, which can smaller
        // then the TextField if the items are too
        // short in width, thus the best is to use
        // this 'max-content' trick.
        minWidth: 'max-content'
      }
    },
    iconStyle: {
      padding: 0,
      width: 'auto'
    },
    labelStyle: defaultInputStyle,
    floatingLabelShrinkStyle: { ...defaultFloatingLabelStyle }
  },
  textField: {
    ...defaultStyles,
    textareaStyle: defaultInputStyle,
    floatingLabelShrinkStyle: { ...defaultFloatingLabelStyle }
  }
};

export const disabledFormField = {
  ...styleGuide.select.labelStyle,
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Roboto',
  lineHeight: '3rem',
  whiteSpace: 'noWrap',
  margin: '0 0.5rem',
  width: '100%'
};

const formLabelStyle = {
  alignItems: 'center',
  display: 'flex',
  fontFamily: 'Roboto',
  fontSize: '14px',
  lineHeight: '1.29',
  color: colors.dotmapsBlack80,
  width: 'auto'
};

export const formStyles = (readOnly = false) => ({
  checkbox: {
    iconStyle: {
      height: '1.125rem',
      marginRight: '0.5rem',
      width: '1.125rem'
    },
    labelStyle: {
      ...formLabelStyle,
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      paddingLeft: '1.5rem',
      width: 'auto'
    }
  },
  radioGroup: {
    style: {
      display: 'flex'
    }
  },
  radio: {
    iconStyle: {
      height: '1.0625rem',
      marginRight: '0.5rem',
      width: '1.0625rem'
    },
    inputStyle: {
    },
    labelStyle: {
      ...formLabelStyle,
      lineHeight: 'auto',
      paddingRight: '1.5rem',
      width: 'auto',
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      width: 'auto'
    }
  },
  toggle: {
    labelStyle: {
      ...formLabelStyle,
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      width: 'auto'
    }
  }
});

export const publicFormStyles = (readOnly = false) => ({
  radioGroup: {
    style: {
      display: 'block'
    }
  },
  radio: {
    iconStyle: {
      height: '1.0625rem',
      marginRight: '0.5rem',
      width: '1.0625rem'
    },
    inputStyle: {
    },
    labelStyle: {
      ...formLabelStyle,
      lineHeight: 'auto',
      paddingRight: '1.5rem',
      width: 'auto',
      color: !readOnly ? formLabelStyle.color : 'rgba(0,0,0,0.3)'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      width: 'auto'
    }
  }
});

const ellipsisText = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const secBarSmallIconButtonStyles = {
  width: '38px',
  height: '38px',
  padding: '7px'
};

export const secBarCompactIconButtonStyles = {
  width: '24px',
  height: '24px',
  padding: '0px'
};

export const secBarToolbarIconButtonStyles = {
  width: '24px',
  height: '24px',
  padding: '0px',
  margin: '2px 6px'
};

export const ganttBarIconButtonStyles = {
  height: '2rem',
  padding: '0.5rem 1.5rem 0.5rem 1.5rem',
  width: '5rem'
};

export const dataTableSortIconButtonStyles = {
  iconStyle: {
    fontSize: '18px'
  },
  style: {
    border: 'none',
    height: '1.125rem',
    width: '1.125rem',
    padding: '0.5rem',
    boxSizing: 'content-box'
  }
};

export const simpleIconButtonStyles = {
  iconStyle: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    color: colors.dotmapsBlack80
  },
  style: {
    display: 'flex',
    border: 'none',
    height: 'auto',
    padding: '0',
    width: 'auto'
  }
};

export const simpleMenuItemStyle = {
  fontFamily: 'Roboto',
  fontSize: '14px',
  color: colors.dotmapsBlack80
};

export const dataTableEmptyResultsIconStyles = {
  color: colors.dotmapsGrayEmptyIconV2,
  style: {
    height: '56px',
    width: '56px'
  },
  viewBox: '0 0 56 56'
};

export const noRecordsIconStyles = {
  color: colors.dotmapsBlack40,
  style: {
    height: '70px',
    width: '70px'
  },
  viewBox: '0 0 24 24'
};

export const readOnlyText = {
  color: 'rgba(0, 0, 0, 0.3)',
  cursor: 'not-allowed'
};

export const labelStyles = {
  new: {
    color: '#3D82FF',
    fontWeight: 'bold',
    textAlign: 'left',
    textTransform: 'uppercase'
  },
  caution: {
    color: 'red'
  }
};

export const actionButton = {
  backgroundColor: colors.dotmapsLighterBlue,
  hoverColor: colors.dotmapsLighterBlue,
  rippleColor: colors.dotmapsDarkBlue,
  style: {
    color: 'white',
    margin: '0 0 0 1em'
  }
};

export const actionButtonDisabled = {
  backgroundColor: colors.dotmapsLightGrayInactive,
  style: {
    color: 'white',
    margin: '0 0 0 1em'
  }
};

export const loginStyles = {
  cardText: {
    padding: '0',
    margin: '1.5em 0'
  },
  links: {
    color: '#717171',
    textDecoration: 'none'
  },
  successLinks: {
    color: '#3E73E7',
    textDecoration: 'none'
  },
  buttonLabel: {},
  loginButton: {
    style: {
      display: 'block',
      height: '46px',
      lineHeight: '46px',
      color: '#FFFFFF',
      margin: '0'
    },
    labelStyle: {
      height: '46px',
      lineHeight: '46px',
      fontSize: '1rem',
      fontWeight: '400',
      textTransform: 'none'
    }
  },
  loginSSOButton: {
    style: {
      alignItems: 'center',
      border: '1px solid #8C8C8C',
      borderRadius: '2px',
      backgroundColor: '#FFFFFF',
      justifyContent: 'center',
      flexDirection: 'row',
      display: 'flex',
      height: '40px',
      margin: '0'
    },
    labelStyle: {
      alignItems: 'center',
      color: colors.dotmapsBlack60,
      display: 'flex',
      fontFamily: 'Roboto',
      fontSize: '14px',
      fontWeight: '500',
      justifyContent: 'center',
      lineHeight: '24px',
      textTransform: 'uppercase',
      textAlign: 'center'
    }
  },
  ssoIcon: {
    style: {
      margin: '10px 16px 10px 16px'
    }
  },
  successIcon: {
    fontSize: '5em',
    color: '#1DAF54'
  }
};

export const logoutStyles = {
  cardText: {
    fontSize: '1.2em',
    padding: '2em'
  },
  indicatorContainer: {
    padding: '2em',
    position: 'relative'
  },
  indicator: {
    display: 'inline-block',
    position: 'relative'
  },
  button: {
    width: 256
  }
};

export const topBarStyles = {
  breadCrumb: {
    link: {
      color: colors.dotmapsInactiveBlue,
      textDecoration: 'none'
    },
    separator: {
      fontSize: '1.7em',
      paddingTop: '0.1em'
    }
  },
  details: {
    actionButton: {
      textTransform: 'uppercase'
    }
  },
  editLinks: {
    color: colors.dotmapsBlack80
  },
  links: {
    color: colors.overlayTextColor
  },
  loader: {
    display: 'inline-block',
    height: '30px',
    position: 'relative',
    width: '30px'
  },
  loaderIconWrapper: {
    alignItems: 'center',
    display: 'inline-flex',
    height: '48px',
    justifyContent: 'center',
    position: 'relative',
    width: '48px'
  },
  toolbar: {
    backgroundColor: '#FFFFFF',
    height: '100%',
    padding: 0
  },
  toolbarSeparator: {
    left: {
      height: '100%',
      marginLeft: '1em',
      marginRight: '1em'
    },
    right: {
      height: '100%',
      marginLeft: '1em',
      marginRight: '1em'
    }
  },
  pngIcons: {
    size: 18
  },
  waffleIcons: {
    size: 32
  },
  icons: {
    color: '#616161'
  },
  addIcon: {
    backgroundColor: '#406EE3',
    borderRadius: '50%',
    margin: '0 1.5rem 0 1rem',
    height: '2rem',
    width: '2rem',
    padding: '1px 4px'
  },
  addMenu: {
    boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2)',
    borderRadius: '0.25rem',
    marginTop: '0.5rem'
  }
};

const avatarStyles = {
  marginRight: '0.5rem',
  verticalAlign: 'middle',
  backgroundColor: 'white'
};

const commonDialogField = {
  clear: 'both',
  marginTop: '2em'
};

export const detailStyles = {
  chip: {
    backgroundColor: colors.dotmapsBlue,
    display: 'inline-block'
  },
  chipLabelColor: 'white',
  chipLabelStyle: {
    fontSize: '1em',
    fontWeight: 'bold',
    lineHeight: '1.5em',
    paddingLeft: '0.5em',
    paddingRight: '0.5em'
  },
  dialog: {
    edit: {
      status: {
        checkbox: {
          iconStyle: {
            ...formStyles().checkbox.iconStyle
          },
          labelStyle: {
            ...formStyles().checkbox.labelStyle
          },
          style: {
            ...formStyles().checkbox.style,
            paddingLeft: '0'
          }
        }
      }
    },
    group: commonDialogField,
    groupStatus: {
      ...commonDialogField,
      width: '10em'
    },
    status: commonDialogField
  },
  item: {
    avatar: avatarStyles
  },
  list: {
    padding: 0
  },
  listItem: {
    margin: 0,
    padding: 0
  },
  groupOpenListItem: {
    // eslint-disable-next-line max-len
    boxShadow: `0 1px 18px 0 ${colors.dotmapsBlack12}, 0 6px 10px 0 ${colors.dotmapsBlack14}, 0 3px 5px -1px ${colors.dotmapsBlack20}`,
    borderLeft: `4px solid ${colors.secondaryColor}`,
    margin: '0 0 1rem 0',
    maxHeight: '10000px',
    overflow: 'hidden',
    transition: 'max-height 0.25s ease-in-out'
  },
  groupClosedListItem: {
    maxHeight: '50px'
  },
  conflictListItem: {
    innerDivStyle: {
      padding: '1rem 1rem 0 1rem'
    },
    style: {
      borderBottom: `1px solid ${colors.dotmapsGrayBorder}`
    }
  },
  selectedItem: {
    borderLeft: `4px solid ${colors.secondaryColor}`
  },
  status: {
    common: {
      labelColor: '#FFFFFF',
      labelStyle: {
        textTransform: 'uppercase'
      },
      style: {
        height: 26
      }
    },
    blue: {
      backgroundColor: '#2093FF'
    },
    green: {
      backgroundColor: '#20C05C'
    },
    red: {
      backgroundColor: '#FF4744'
    },
    white: {
      backgroundColor: '#FFFFFF',
      labelColor: '#2093FF',
      buttonStyle: {
        border: '1px solid #2093FF'
      }
    },
    yellow: {
      backgroundColor: '#FBBA10'
    }
  },
  conflictStatus: {
    common: {
      style: {
        height: '22px',
        lineHeight: '20px',
        minWidth: '75px'
      },
      labelStyle: {
        color: '#FFFFFF',
        fontSize: '.8em',
        textTransform: 'capitalize'
      }
    },
    blue: {
      backgroundColor: '#2093FF'
    },
    green: {
      backgroundColor: '#20C05C'
    },
    red: {
      backgroundColor: '#FF4744'
    },
    white: {
      backgroundColor: '#FFFFFF',
      labelStyle: { color: '#2093FF', fontSize: '.8em', textTransform: 'capitalize' },
      style: { border: 'solid 1px #2093FF', height: '22px', lineHeight: '20px', minWidth: '75px' }
    },
    yellow: {
      backgroundColor: '#FBBA10'
    }
  },
  conflictMainStatus: {
    green: {
      color: '#20C05C'
    },
    red: {
      color: '#FF4744'
    },
    yellow: {
      color: '#FBBA10'
    }
  },
  forms: {
    ...formStyles()
  },
  segments: {
    ...formStyles()
  }
};

export const spinnerStyles = {
  height: '6rem',
  marginTop: '3rem',
  position: 'relative'
};

// A select dropdown which is the same as the system
// one we use (i.e. the same as '...styleGuide.select'),
// however the floatingLabel, doesn't move when we click
// on the caret (i.e. 'transform' is disabled), and the
// height of the whole input box must be the same as
// for when we don't have a floating label.
const sameSizeSelect = {
  ...styleGuide.select,
  floatingLabelShrinkStyle: {
    transform: 'none'
  },
  floatingLabelStyle: {
    ...styleGuide.select.floatingLabelStyle,
    lineHeight: 'auto',
    top: 0
  },
  iconStyle: {
    top: 0
  },
  labelStyle: {
    ...styleGuide.select.labelStyle,
    height: '3rem',
    lineHeight: '3rem',
    top: '-4px'
  },
  menuStyle: {
    margin: 0
  },
  style: {
    height: '3rem',
    lineHeight: '3rem',
    margin: '1rem 0 0 0'
  }
};

// Dialog styles:
export const dialogStyles = {
  content: {
    title: {
      padding: '1.5rem 1.5rem 1rem 1.5rem',
      fontFamily: 'Roboto',
      fontSize: '1.375rem',  // 22px
      fontWeight: 'normal',
      lineHeight: '1.27',
      color: colors.dotmapsBlack80
    },
    body: {
      padding: '0 1.5rem'
    },
    actions: {
      padding: '1rem'
    }
  },
  buttons: {
    attention: {
      backgroundColor: colors.dotmapsAttentionAction,
      color: colors.overlayTextColor,
      marginLeft: '1em'
    },
    caution: {
      color: '#EF5552',
      float: 'left'
    },
    primary: {
      marginLeft: '1em'
    }
  },
  form: {
    item: {
      paddingTop: '2em'
    }
  },
  item: {
    avatar: {
      ...avatarStyles,
      display: 'inline-block'
    },
    avatarLessCheckbox: {
      top: '0.2em'
    },
    checkbox: {
      top: '0.5em'
    },
    listItem: {
      display: 'inline-block',
      width: '50%'
    },
    listItemInner: {
      padding: '0.5em 1em 0.5em 3em'
    }
  },
  select: {
    ...sameSizeSelect
  },
  // For batch upload, the select is the same
  // as sameSizeSelect, but without the
  // '1rem' top padding.
  uploadSelect: {
    ...sameSizeSelect,
    style: {
      height: '3rem',
      lineHeight: '3rem'
    }
  },
  customBody: {
    padding: '0'
  },
  customActionContainer: {
    borderTop: `1px solid ${colors.dotmapsGrayBorder}`
  },
  largeDialog: {
    maxWidth: '42.5em'
  },
  mediumDialog: {
    maxWidth: '32em'
  },
  smallDialog: {
    maxWidth: '26em'
  },
  actionTitleStyle: {
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    color: colors.dotmapsBlack80
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '1.375rem',
    fontWeight: '400',
    color: colors.dotmapsBlack80
  }
};

export const mapTrayStyles = {
  tabs: {
    tabItemContainerStyle: {
      background: colors.overlayTextColor,
      flexShrink: 0,
      boxShadow: 'inset 0 -1px 0 0 #EBEBEB'
    },
    inkBarStyle: {
      background: colors.secondaryColor
    },
    contentContainerClassName: 'map-tray-tabs-content-container',
    contentContainerStyle: {
      overflowY: 'auto',
      height: '100%',
      flexGrow: 1,
      flexDirection: 'column'
    },
    style: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: '100%'
    },
    tabTemplateStyle: {
      position: 'static',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  },
  tab: {
    buttonStyle: {
      height: '3em',
      color: colors.dotmapsBlack40
    }
  },
  activeTab: {
    buttonStyle: {
      height: '3em',
      color: colors.secondaryColor
    }
  },
  button: {
    style: {
      minWidth: '2em'
    },
    buttonStyle: {
      background: colors.googleMapsHoverBlue
    }
  }
};

export const dashboardDetails = {
  avatar: {
    size: 60,
    style: {
      margin: '10px 60px',
      border: `1px solid ${colors.dotmapsGrayInactive}`
    },
    iconAvatar: {
      backgroundColor: 'white'
    },
    textAvatar: {
      backgroundColor: colors.dotmapsGrayLine
    }
  },
  input: {
    fullWidth: true,
    underlineStyle: {
      borderColor: colors.dotmapsGray
    }
  },
  select: {
    iconStyle: {
      fill: colors.dotmapsGray
    }
  }
};

export const dashboardTopBarButton = {
  backgroundColor: colors.overlayTextColor,
  hoverColor: colors.dotmapsGrayLine,
  labelStyle: { fontSize: 12, color: colors.dotmapsGray },
  style: { height: 40 }
};

export const dashboardTopBarButtonSecondary = {
  labelStyle: { fontSize: 12, color: colors.overlayTextColor },
  style: { height: 40 }
};

// Detail editing styles
const segmentEditFontSizeEM = 1;
const segmentEditButtonSizeEM = 1.5;

export const fileUploadButton = {
  style: {
    border: '1px solid #EBEBEB',
    borderRadius: '2px',
    height: '36px',
    color: colors.secondaryColor
  }
};

export const overlapButton = {
  style: {
    border: '1px solid #EBEBEB',
    borderRadius: '2px'
  }
};

export const linkButton = {
  hoverColor: colors.overlayTextColor,
  style: {
    border: 'none',
    color: colors.dotmapsLighterBlue,
    height: 'auto',
    lineHeight: 'auto'
  }
};

export const logEntryLinkButton = {
  hoverColor: linkButton.hoverColor,
  labelStyle: {
    float: 'left',
    padding: '0'
  },
  style: {
    ...linkButton.style,
    padding: '0'
  }
};

export const detailEdit = {
  check: {
    iconStyle: {
      height: '1.125rem',
      marginRight: '0.5rem',
      width: '1.125rem'
    },
    labelStyle: {
      lineHeight: '1.0625rem',
      width: 'auto'
    },
    style: {
      marginTop: '1em',
      paddingBottom: '0.5rem'
    }
  },
  chip: {
    deleteIconStyle: {
      width: '20px'
    },
    labelStyle: {
      fontFamily: 'Roboto',
      fontSize: '0.875rem'
    }
  },
  errors: {
    fontSize: '0.75rem',
    lineHeight: '0.75rem'
  },
  toggle: {
    style: {
      marginTop: '1em',
      paddingBottom: '0.5rem'
    }
  },
  textField: {
    ...styleGuide.textField,
    inputStyle: {
      ...styleGuide.textField.inputStyle,
      width: 'calc(100% - 1px)'
    }
  },
  segmentEdit: {
    addButton: {
      style: {
        margin: '0.375em'
      }
    },
    scheduleActionButton: {
      style: {
        display: 'flex',
        alignItems: 'center',
        width: '24px',
        height: '24px',
        padding: 0
      }
    },
    actionButton: {
      style: {
        opacity: '0.6'
      }
    },
    startNewButton: {
      ...linkButton
    },
    clearButton: {
      hoverColor: '#F4F4F4',
      labelStyle: {
        padding: '0'
      },
      style: {
        border: 'none',
        color: colors.dotmapsBlack80,
        width: '3.688rem',
        minWidth: '3.688rem',
        height: '2rem',
        lineHeight: 'auto',
        alignItems: 'center'
      }
    },
    scheduleButton: {
      hoverColor: colors.overlayTextColor,
      labelStyle: {
        padding: '0.5rem 0'
      },
      style: {
        border: 'none',
        color: colors.dotmapsLighterBlue,
        height: 'auto',
        lineHeight: 'auto',
        padding: '0.5rem 0'
      }
    },
    textField: {
      ...styleGuide.textField,
      inputStyle: {
        ...styleGuide.textField.inputStyle,
        width: '100%'
      },
      style: {
        width: '100%'
      }
    },
    openScheduleButton: {
      style: {
        cursor: 'pointer',
        fontSize: '1.125rem',  // 18px
        opacity: '0.6'
      }
    },
    card: {
      fontSize: `${segmentEditFontSizeEM}em`,
      lineHeight: `${segmentEditFontSizeEM}em`,
      padding: 0
    },
    cardContents: {
      padding: 0
    },
    check: {
      iconStyle: {
        height: '1.125rem',
        marginRight: '0.5rem',
        width: '1.125rem'
      }
    },
    delete: {
      float: 'right',
      position: 'relative',
      marginTop: `${segmentEditFontSizeEM - segmentEditButtonSizeEM}em`,
      top: `${-(segmentEditFontSizeEM - segmentEditButtonSizeEM) / 2}em`,
      height: `${segmentEditButtonSizeEM}em`,
      width: `${segmentEditButtonSizeEM}em`
    },
    dateRange: {
      width: '8.5rem',
      marginRight: '1rem',
      display: 'inline-block'
    },
    timeRange: {
      width: '7.5rem',
      marginRight: '1rem',
      display: 'inline-block'
    },
    radioGroup: {
      style: {
        display: 'flex',
        paddingBottom: '0.3rem',
        paddingTop: '1rem'
      }
    },
    radio: {
      ...detailStyles.segments.radio,
      labelStyle: {
        ...detailStyles.segments.radio.labelStyle,
        fontFamily: 'Roboto',
        fontSize: '14px',
        lineHeight: '1.29',
        color: colors.dotmapsBlack80,
        paddingRight: '3rem'
      }
    }
  },
  columnStyles: {
    hide: {
      display: 'none'
    },
    col25: {
      width: 'calc(25% - 2.5em)',
      marginLeft: '2em'
    },
    col33: {
      width: 'calc(33% - 2.55em)',
      marginLeft: '2em'
    },
    col50: {
      width: 'calc(50% - 3em)',
      marginLeft: '2em',
      verticalAlign: 'top',
      whiteSpace: 'nowrap'
    },
    col75: {
      width: 'calc(75% - 3.5em)',
      marginLeft: '2em'
    },
    col100: {
      width: 'calc(100% - 4em)',
      marginLeft: '2em'
    },
    full: {
      width: '100%'
    },
    insideCol50Left: {
      width: 'calc(50% - 0.5em)'
    },
    insideCol50Right: {
      width: 'calc(50% - 0.5em)',
      marginLeft: '1em'
    },
    toggleError: {
      color: colors.dotmapsBlack60,
      fontSize: '0.875em',
      margin: '0.5rem 0 0 0'
    },
    startDatetime: {
      display: 'inline'
    },
    endDatetime: {
      display: 'inline'
    },
    fullDatetime: {
      display: 'block',
      width: 'calc(100% - 4em)',
      marginLeft: '2em'
    },
    startDateRangeDate: {
      width: 'calc(30% - 2em)',
      marginLeft: '2em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    startDateRangeTime: {
      width: 'calc(20% - 2em)',
      marginLeft: '1em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    endDateRangeDate: {
      width: 'calc(30% - 2em)',
      marginLeft: '1em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    endDateRangeTime: {
      width: 'calc(20% - 2em)',
      marginLeft: '2em',
      display: 'inline-block',
      verticalAlign: 'top'
    },
    DateTimeRange: {
      width: 'calc(50% - 3em)',
      display: 'inline-block',
      marginLeft: '2em',
      verticalAlign: 'top',
      whiteSpace: 'nowrap'
    }
  }
};

export const uploadStyle = {
  uploadIconButton: {
    color: colors.secondaryColor
  }
};

export const resetRequestStyles = {
  doneButton: {
    style: {
      backgroundColor: 'white',
      borderRadius: '.2em',
      lineHeight: '2em',
      border: '.1em solid',
      width: '12em',
      borderColor: colors.dotmapsDoneGreen,
      color: colors.dotmapsDoneGreen
    }
  }
};
export const userTableStyles = {
  userActiveAvatar: {
    display: 'flex',
    justifyContent: 'center'
  },
  userInactiveAvatar: {
    fontSize: 16
  }
};

// Print map styles:
export const printStyles = {
  agencyLegend: {
    avatar: {
      left: '4em',
      backgroundColor: 'white'
    },
    listItem: {
      padding: '0.75em 1em 0.75em 5em'
    }
  }
};

export const calendarStyles = {
  event: color => ({
    backgroundColor: hexToRgba(color, 55),
    border: `1px solid ${color}`,
    height: 22,
    borderRadius: 1,
    fontSize: 12,
    fontWeight: 400,
    color: colors.dotmapsGray
  })
};

export const batchUploadDialogStyle = {
  circularProgress: {
    color: '#217AFB',
    size: 66,
    thickness: 6,
    style: {
      margin: 'auto'
    }
  },
  circularError: {
    color: colors.dotmapsGray,
    style: {
      height: 75,
      width: 75
    }
  },
  retryButton: {
    style: {
      color: '#217AFB'
    }
  }
};

export const linkButtonStyles = {
  defaultProps: {
    hoverColor: '#FFFFFF',
    labelStyle: {
      color: colors.dotmapsBlue,
      float: 'right',
      padding: 0
    },
    style: {
      lineHeight: '1em',
      height: '1em'
    }
  }
};

const filterLabelHeight = '34px';

const dropDownStyles = {
  defaultProps: {
    anchorOrigin: {
      horizontal: 'left',
      vertical: 'bottom'
    },
    labelStyle: {
      borderRadius: '2px',
      fontSize: '0.875rem',
      height: filterLabelHeight,
      lineHeight: filterLabelHeight,
      margin: '8px 16px 8px 16px',
      padding: '0 40px 0 8px',
      // When the menu is open, 'opacity' is set to zero and 'top' also
      // changes (inside the Material UI's DropDownMenu component),
      // I'm cancelling those two changes with this, so we still see
      // the label:
      opacity: 1,
      top: '1px'
    },
    multiple: true,
    selectedMenuItemStyle: {
      color: colors.secondaryColor
    },
    iconStyle: {
      fill: colors.dotmapsGrayInactive,
      height: filterLabelHeight,
      margin: '9px 0 0 0',
      padding: '0',
      right: '8px',
      top: '0'
    },
    menuItemStyle: {
      fontSize: '0.8125rem'
    },
    menuStyle: {
      minWidth: '15em'
    },
    style: {
      maxWidth: '19em',
      ...ellipsisText
    },
    underlineStyle: {
      border: 'none'
    }
  },
  // The Material UI's DropDown component
  // validates (via PropTypes) that maxHeight is a
  // number, thus, we cannot specify it as '25em'.
  maxHeight: 400
};

export const segmentScheduleRecurrenceDropDownStyles = {
  ...dropDownStyles.defaultProps,
  labelStyle: {
    ...dropDownStyles.defaultProps.labelStyle,
    margin: '0 16px 0 0'
  },
  iconStyle: {
    ...dropDownStyles.defaultProps.iconStyle,
    margin: '0'
  },
  style: {
    ...dropDownStyles.defaultProps.style,
    height: 'auto'
  },
  maxHeight: dropDownStyles.maxHeight
};

export const commentSortSelect = {
  ...sameSizeSelect,
  style: {
    ...dropDownStyles.defaultProps.style,
    height: 'auto',
    maxWidth: '7.875rem',
    fontSize: '0.875rem',
    fontFamily: 'Roboto'
  },
  labelStyle: {
    padding: '0 2.25rem 0 0'
  },
  iconStyle: {
    padding: '0', // Mess with this to get the button centered. 
    width: 'auto'
  },
  underlineStyle: {
    border: 'none'
  },
  maxHeight: dropDownStyles.maxHeight
};

export const filterDropDownStyles = {
  ...dropDownStyles,
  selectAll: {
    fontWeight: 500
  },
  selectedLabel: {
    backgroundColor: 'rgba(0,0,0,0.1)'
  },
  // Override styles for when the filters are on a blue bar:
  blueBar: {
    iconStyle: {
      fill: '#FFFFFF'
    },
    labelStyle: {
      backgroundColor: 'rgba(255,255,255,0.3)',
      color: '#FFFFFF'
    }
  }
};

export const paginatorDropDownStyles = {
  ...dropDownStyles,
  autoWidth: false,
  iconStyle: {
    ...dropDownStyles.defaultProps.iconStyle,
    margin: '-3px 0 0 0',
    height: '1rem',
    right: '0'
  },
  labelStyle: {
    ...dropDownStyles.defaultProps.labelStyle,
    display: 'inline',
    margin: '0',
    height: '1rem',
    lineHeight: '1rem'
  },
  listStyle: {
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem'
  },
  menuStyle: {
    width: 'auto'
  },
  style: {
    height: '1rem'
  },
  targetOrigin: {
    horizontal: 'left',
    vertical: 'bottom'
  }
};

export const badgeTab = {
  defaultProps: {
    badgeStyle: {
      color: '#FFFFFF',
      top: -4,
      right: -13,
      fontSize: 10,
      fontWeight: 'bold',
      width: 8,
      height: 8,
      backgroundColor: colors.dotmapsRed
    },
    style: {
      padding: 0
    }
  }
};

export const badgeCounterStyles = {
  defaultProps: {
    badgeStyle: {
      top: 28,
      right: 6,
      fontSize: 9,
      width: 16,
      height: 16,
      backgroundColor: '#FFFFFF'
    },
    style: {
      padding: 0
    }
  }
};

export const badgeIconStyles = {
  defaultProps: {
    badgeStyle: {
      top: 10,
      right: -14,
      fontSize: 9,
      width: 16,
      height: 16,
      backgroundColor: '#FFFFFF',
      zIndex: 1000
    },
    style: {
      padding: 0
    }
  }
};

export const badgeAvatarStyles = {
  badgeStyle: {
    // For badges size '24':
    24: {
      top: 12,
      right: -4,
      fontSize: 8,
      width: 16,
      height: 16,
      backgroundColor: '#FFFFFF'
    },
    // For badges size '30':
    30: {
      top: 16,
      right: 0,
      fontSize: 9,
      width: 16,
      height: 16,
      backgroundColor: '#FFFFFF'
    },
    // For badges size '40':
    40: {
      top: 30,
      right: 0,
      fontSize: 12,
      width: 12,
      height: 12,
      backgroundColor: '#FFFFFF'
    }
  },
  style: {
    display: 'flex',
    justifyContent: 'center',
    padding: 0
  }
};

export const addToGroupListStyles = {
  dialog: {
    maxWidth: '32rem'
  }
};

export const warningDialogStyles = {
  dialog: {
    width: '30rem'
  },
  button: {
    color: colors.dotmapsBlue
  }
};

// The warning dialog for the Gantt page:
export const ganttWarningDialogStyles = {
  dialog: {
    width: '30rem'
  },
  button: {
    color: colors.dotmapsBlue
  }
};

export const addToNewGroupStyles = {
  button: {
    color: colors.dotmapsBlue
  }
};

export const listItemStyles = {
  filterButtonStyle: {
    padding: '0',
    height: 'auto',
    width: 'auto'
  },
  locationTypeButtonStyle: {
    padding: '0 0.25rem 0 0',
    height: 'auto',
    width: 'auto'
  },
  typeIcon: {
    fontSize: 16
  }
};

const filterRadio = {
  iconStyle: {
    height: '1.0625rem',
    marginRight: '0.5rem',
    width: '1.0625rem'
  },
  inputStyle: {
  },
  labelStyle: {
    lineHeight: 'auto',
    width: 'auto'
  },
  style: {
    alignItems: 'center',
    display: 'flex',
    padding: '0.5rem',
    width: '100%'
  }
};

export const layersMenuStyles = {
  radioGroup: {
    style: {
      width: '100%'
    }
  },
  radio: {
    inputStyle: {
    },
    labelStyle: {
      lineHeight: 'auto',
      width: '100%'
    },
    style: {
      alignItems: 'center',
      display: 'flex',
      padding: '0.5rem 0',
      width: '100%'
    }
  },
  menuIcons: {
    height: '1.375rem',
    width: '1.375rem',
    borderRadius: '0.25rem',
    border: '1px solid #DDD',
    marginRight: '0.5rem'
  },
  buttonIcons: {
    height: '1.375rem',
    width: '1.375rem',
    borderRadius: '0.25rem',
    border: '1px solid #999'
  }
};


export const fileAreaStyles = {
  fullWidth: true,
  style: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: `dotted 2px ${colors.dotmapsVeryLightGray}`,
    borderRadius: '2px',
    display: 'flex',
    height: '3.5rem',
    width: '100%'
  }
};

export const fileUploadStyles = {
  radio: {
    ...filterRadio,
    style: {
      ...filterRadio.style,
      padding: '0'
    }
  }
};

export const tooltipStyles = {
  backgroundColor: colors.dotmapsBlack60,
  borderRadius: '2px',
  color: colors.overlayTextColor,
  fontFamily: 'Roboto, sans-serif',
  fontSize: '0.75rem',  // 12px
  lineHeight: '1.33',
  padding: '0.25rem 0.5rem',
  pointerEvents: 'none'
};

export const moreListTooltipStyles = {
  avatarStyle: {
    border: `1px solid ${colors.dotmapsGrayBorder}`,
    fontFamily: 'Roboto, sans-serif'
  },
  seeDetailsStyle: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.75rem',
    fontStyle: 'italic',
    lineHeight: '1.33',
    color: colors.dotmapsBlack40,
    padding: '0.6875rem 1rem'
  },
  itemStyle: {
    padding: '0.6875rem 1rem'
  },
  tooltip: {
    backgroundColor: colors.overlayTextColor,
    borderRadius: '2px',
    color: colors.dotmapsBlack80,
    fontFamily: 'Roboto, sans-serif',
    fontSize: '0.875rem',
    padding: '0.5rem 0',
    pointerEvents: 'none'
  }
};

export const filterStyles = {
  dashboardCheck: {
    ...formStyles().checkbox
  },
  check: {
    iconStyle: {
      height: '1.125rem',
      marginRight: '0.5rem',
      width: '1.125rem'
    },
    labelStyle: {
      lineHeight: '1.0625rem',
      width: 'auto'
    }
  },
  radioGroup: {
    style: {
      display: 'flex',
      flexWrap: 'wrap'
    }
  },
  radio: {
    ...filterRadio
  },
  toggle: {
    iconStyle: {
      margin: '0'
    }
  },
  filterGroupRadio: {
    ...filterRadio,
    style: {
      ...filterRadio.style,
      padding: '0'
    }
  },
  dateRadio: {
    ...filterRadio,
    iconStyle: {
      ...filterRadio.iconStyle,
      marginRight: '0'
    },
    style: {
      ...filterRadio.style,
      width: '7.5rem'
    }
  },
  checkAvatar: {
    display: 'inline-block',
    verticalAlign: 'middle',
    backgroundColor: 'white'
  }
};

export const tableFloatingBarStyles = {
  wrapper: {
    opacity: 0,
    position: 'sticky',
    right: 0,
    width: 0,
    zIndex: 4
  },
  bar: {
    alignItems: 'center',
    backgroundColor: colors.dotmapsGrayButtonBackground,
    display: 'flex',
    height: '46px',  // height and top are set like this to not hide the 1px row border,
                     // and address the different behavior between Firefox and Chrome.
    position: 'absolute',
    right: 0,
    top: '1px'
  }
};

export const snackbarStyles = isOpen => ({
  bodyStyle: {
    height: isOpen ? '48px' : '0px',
    transition: transitions.easeOut('500ms', 'height')
  },
  style: {
    transform: 'translate(-50%, 0)',
    transition: transitions.easeOut('500ms', 'visibility')
  }
});

const defaultInputSearchStyles = {
  autocompleteContainerStyles: {
    maxWidth: 'none',
    width: '100%'
  },
  containerStyles: {
    margin: '1rem 0 1rem 0'
  }
};

export const autocompleteSearchStyles = {
  normal: {
    ...defaultInputSearchStyles
  },
  topBar: {
    ...defaultInputSearchStyles,
    autocompleteContainerStyles: {
      ...defaultInputSearchStyles.autocompleteContainerStyles,
      backgroundColor: 'white',
      borderRadius: '4px'
    },
    containerStyles: {
      ...defaultInputSearchStyles.containerStyles,
      maxHeight: '2.5rem',
      width: '512px'
    }
  }
};

export const mentionStyles = {
  // Styles for the wrapping MentionsInput component:
  input: {
    control: {
      backgroundColor: 'white',
      color: colors.dotmapsBlack60,
      fontFamily: 'Roboto',
      fontSize: '0.875rem',
      fontWeight: '400',
      lineHeight: '1.125rem',
      minHeight: '1.125rem'
    },
    '&multiLine': {
      highlighter: {
        padding: '0.75rem',
        border: '1px solid transparent',
        borderRadius: '0.25rem'
      },
      input: {
        padding: '0.75rem',
        border: `1px solid ${colors.dotmapsGrayBorder}`,
        borderRadius: '0.25rem'
      }
    },
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: `1px solid ${colors.dotmapsGrayBorder}`,
        fontFamily: 'Roboto',
        fontSize: '0.875rem',
        overflowY: 'auto',
        height: '11.5rem',  // 184px
        width: '20rem'  // 320px
      },
      item: {
        '&focused': {
          backgroundColor: colors.dotmapsGrayHover
        }
      }
    }
  },
  // Styles for the Mention component:
  mention: {
    // Try to emulate a Material UI Chip with these CSS statements:
    backgroundColor: 'rgb(224, 224, 225)',
    borderRadius: '1rem',
    fontFamily: 'Roboto',
    // We can only add padding at the top and bottom to emulate the look and feel of a Chip component,
    // if we add left/right padding, rendering will fail, since the react-mentions library does
    // some work calculating space. We have to emulate that leading/trailing spaces by adding actual
    // leading/trailing whitespace.
    padding: '0.25rem 0 0.25rem 0'
  }
};

export const squareChipDeleteIconStyles = {
  alignItems: 'center',
  display: 'flex',
  fontSize: '1rem'
};

export const blankIconStyles = {
  alignItems: 'center',
  display: 'flex',
  fontFamily: 'Roboto',
  color: colors.dotmapsBlack80,
  fontSize: '0.875rem',
  justifyContent: 'center',
  width: '20px'
};

export const backArrowStyles = {
  alignItems: 'center',
  display: 'flex',
  flexGrow: 0,
  cursor: 'pointer',
  height: '1.5rem',
  width: '1.5rem'
};

export default muiTheme;
