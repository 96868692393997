import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TimelineChartRow from '@shared/timeline-chart/timeline-chart-row';
import {
  calculateGanttContainerWidth,
  getMinDate
} from '@utils/timeline-chart-utils';
import { isLocationTypeOpen } from '@utils/gantt-utils';
import './timeline-chart.scss';

class TimelineChart extends Component {
  render() {
    const {
      dateUnit,
      locationType,
      locationTypeGroups,
      popoverContent,
      rows
    } = this.props;
    const minDate = getMinDate(rows);
    const containerStyle = {
      width: calculateGanttContainerWidth(rows, dateUnit)
    };
    return (
      <div
        style={containerStyle}
        styleName="timeline-chart-container"
      >
        {rows.map(row => {
          const {
            label,
            nestedLocations,
            rows: groupedRows,
            ...other
          } = row;
          const rowData = {
            name: label,
            ...other
          };
          const isOpen = isLocationTypeOpen(label, locationTypeGroups, locationType);
          return (
            <div key={row.id}>
              <TimelineChartRow
                dateUnit={dateUnit}
                key={row.id}
                isSmall
                minDate={minDate}
                row={rowData}
                popoverContent={popoverContent}
              />
              {isOpen && groupedRows.map(groupedRow => {
                if (nestedLocations) {
                  const isNestedOpen = isLocationTypeOpen(groupedRow.label, locationTypeGroups, locationType);
                  const nestedRowData = {
                    name: groupedRow.label,
                    ...groupedRow
                  };
                  return (
                    <div key={groupedRow.id}>
                      <TimelineChartRow
                        dateUnit={dateUnit}
                        key={groupedRow.id}
                        isSmall
                        minDate={minDate}
                        row={nestedRowData}
                        popoverContent={popoverContent}
                      />
                      {isNestedOpen && groupedRow.rows.map(nestedRow => (
                        <TimelineChartRow
                          dateUnit={dateUnit}
                          key={nestedRow.id}
                          minDate={minDate}
                          row={nestedRow}
                          popoverContent={popoverContent}
                        />
                      ))
                      }
                    </div>
                  );
                }
                return (
                  <TimelineChartRow
                    dateUnit={dateUnit}
                    key={groupedRow.id}
                    minDate={minDate}
                    row={groupedRow}
                    popoverContent={popoverContent}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }
}

TimelineChart.propTypes = {
  dateUnit: PropTypes.number,
  locationType: PropTypes.number,
  locationTypeGroups: PropTypes.object,
  popoverContent: PropTypes.func,
  rows: PropTypes.array
};

const mapStateToProps = state => ({
  locationType: state.groups.gantt.filters.locationType,
  locationTypeGroups: state.groups.gantt.locationTypeGroups
});

export default connect(mapStateToProps, {})(TimelineChart);

