import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FontIcon from 'material-ui/FontIcon';
import DetailCard from '@components/attachments/detail-card';
import DeleteFileDialog from '@shared/dialogs/delete-file-dialog';
import FilePreview from '@shared/files/file-preview';
import FileUploadButton from './file-upload-button';
import './files.scss';

const Files = ({params}) => {
  const { data } = useSelector(state => state.dataDetail);
  const { preUploadAttachments } = useSelector(state => state.attachments);
  const rows = preUploadAttachments[`entity${data.id}`] || [];

  return (
    <Fragment>
      <div styleName="container">
        <div>
          Optional documents to complete your application:
          {params.document_types && params.document_types.map(doc_type => {
            return (<div key={doc_type}>&bull; {doc_type}</div>);
          })}
        </div>
        <div styleName="upload-button"><FileUploadButton objectId={data.id} /></div>
        {rows.length > 0 && rows.map((attachment, id) => {
          return (
            <DetailCard
              key={id}
              attachment={attachment}
              deleteButton
              deleteIcon={
                <FontIcon className="material-icons">
                  close
                </FontIcon>
              }
              alwaysDisplayIcons
              showFileSize
              storedInMemory
              infoIndex={id}
              objectType={'entity'}
              objectId={id}
            />
          );
        })}
      </div>
      <FilePreview />
      <DeleteFileDialog />
    </Fragment>
  );
};

Files.propTypes = {
  params: PropTypes.object
};

export default Files;
