import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ size }) => (
  <svg xmlns="http://www.w3.org/2000/svg" height={size} width={size} viewBox="0 0 32 32" role="img" aria-label="logo">
    <path fillRule="evenodd" clipRule="evenodd" d={`
      M16.938 4.07498C15.5959 4.58268 14.3375 5.37676 13.2571 6.45723C9.18071 10.5336 9.18071 17.1427 13.2571 
      21.219L16.938 24.8999L13.238 28.5999L5.85707 21.219C1.78072 17.1427 1.78072 10.5336 5.85707 6.45723C8.85295 
      3.46135 13.2169 2.66726 16.938 4.07498Z`} fill="#89E1A1"/>
    <path fillRule="evenodd" clipRule="evenodd" d={`
      M11.446 6.45723C15.5074 2.38087 22.0924 2.38087 26.1538 6.45723C30.2153 10.5336 30.2153 17.1427 
      26.1538 21.219L18.7999 28.5999L11.446 21.219C7.38454 17.1427 7.38454 10.5336 11.446 6.45723ZM17.9963 
      9.4464C19.3852 9.19166 20.8715 9.60321 21.9454 10.6811C23.6826 12.4247 23.6826 15.2516 21.9454 
      16.9952L18.7999 20.1522L18.7999 20.1522L18.7998 20.1523L15.6543 16.9952C13.9171 15.2516 13.9171 
      12.4247 15.6543 10.6811C16.3176 10.0153 17.1384 9.60373 17.9963 9.4464Z`} fill="#537CE6"/>
  </svg>
);

Logo.propTypes = {
  size: PropTypes.number
};

Logo.defaultProps = {
  size: 32
};

export default Logo;
