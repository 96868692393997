import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { actionsMap } from '@constants/logs';
import Tooltip from '@material-ui/core/Tooltip';
import {
  renderItemAvatar,
  renderItemIsPMIcon,
  renderItemExtra,
  renderItemTime,
  renderItemTooltipTime,
  renderItemMessage
} from '@utils/logs-utils';
import Icon from './icon';
import './item.scss';

const Item = ({ index, log }) => {
  const { user: users, task_status: taskStatuses } = useSelector(state => state.dataTypes);
  const { current_pms, model, type } = useSelector(state => state.logs);

  const { attrs, entry } = log;
  const { action, comment, created, user } = entry;

  if (!actionsMap[action]) {
    return null;
  }

  const props = { action, attrs, comment, index, model, taskStatuses, users };
  const extra = renderItemExtra(actionsMap, props);

  return (
    <div styleName="activity-logs-item">
      <div styleName="item-date item-column">
        <Tooltip title={renderItemTooltipTime(created)}>
          <span>{renderItemTime(created)}</span>
        </Tooltip>
      </div>
      <div styleName="item-icon item-column">
        <Icon action={action} attrs={attrs} type={type} />
      </div>
      <div styleName="item-avatar item-column">
        {renderItemAvatar(user)}
      </div>
      <div styleName="item-log-message item-column">
        <div styleName="item-log-message-main-container">
          <div styleName="item-log-message-main">
            {renderItemMessage(actionsMap, user, action, attrs, model, type, current_pms)}
          </div>
        </div>
        {extra && (
          <div styleName="item-log-message-extra">
            {extra}
          </div>
        )}
      </div>
      <div styleName="item-pm-icon item-column">
        {attrs && renderItemIsPMIcon(attrs)}
      </div>
    </div>
  );
};

Item.propTypes = {
  index: PropTypes.string,
  log: PropTypes.object
};

export default memo(Item);
