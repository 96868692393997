import * as R from 'ramda';
import moment from 'moment-timezone';
import { getAPIRequestUrl } from '@constants/endpoints';
import { LAYER_REQUEST_LIMIT } from '@constants/layers';
import { getLayerIcon } from '@utils/icon-utils';
import { generateBounds } from '@utils/map-utils';
import { lowerCase, startCase } from 'lodash';

export const optimizeLayerConfig = layer => {
  layer.next = null;
  layer.url = `${getAPIRequestUrl(layer.request_url)}&limit=${LAYER_REQUEST_LIMIT}`;
  layer.list = [];
  layer.inViewport = true;
  layer.loadingComplete = false;
  return layer;
};

// Returns true if the specified word is in
// snake_case with all letters in uppercase
// (i.e. "USER_LOGIN_STRING", etc).
const isSnakeCaseAllCaps = word => {
  const snakeCaseRegex = /^([A-Z]{1,})(_[A-Z0-9]{1,})*$/;
  return snakeCaseRegex.test(word);
};

const lowerAliasKeys = aliases => {
  const keys = {};
  for (const field in aliases) {
    if (Object.prototype.hasOwnProperty.call(aliases, field)) {
      keys[field.toLowerCase()] = field;
    }
  }
  return keys;
};

const getAliases = (attrs, aliases, customAliases) => {
  // If there are no backend aliases, return the attributes the layer has.
  if (R.isEmpty(aliases) && R.isEmpty(customAliases)) {
    return attrs;
  }
  const loweredCustomAliases = lowerAliasKeys(customAliases);
  const aliasedValues = {};
  for (const field in aliases) {
    if (Object.prototype.hasOwnProperty.call(aliases, field)) {
      let key = aliases[field];
      // Convert to StartCase if it's in SNAKE_CASE_ALL_CAPS format:
      if (isSnakeCaseAllCaps(key)) {
        key = startCase(lowerCase(key));
      }

      // Check if there's a custom alias for that key (after transformation):
      if (customAliases) {
        const customAliasKey = loweredCustomAliases[key.toLowerCase()];
        const customKey = customAliases[customAliasKey];
        key = customKey ? customKey : key;
      }

      aliasedValues[key] = attrs[field];
    }
  }
  return aliasedValues;
};

export const optimizeLayerForTray = (layer, aliases, customAliases, type, typeName, icon) => {
  const layerData = {
    id: layer.id,
    layerType: typeName,
    ...getAliases(Object.prototype.hasOwnProperty.call(layer, 'attrs') ? layer.attrs : layer, aliases, customAliases)
  };
  // Incluse these fields if they exists:
  if (layer.external_id) {
    layerData['External ID'] = layer.external_id;
  }
  if (layer.created) {
    layerData['Created'] = moment(layer.created);  // eslint-disable-line dot-notation
  }
  if (layer.modified) {
    layerData['Last updated'] = moment(layer.modified);
  }

  return {
    layerId: layer.id,
    icon: getLayerIcon(icon),
    type,
    layer: { ...layerData }
  };
};

const optimizeLayer = (name, layer) => {
  if (layer.type_name === 'moratorium') {
    // Convert the moratorium entity API response into a layer one:
    const attrs = R.omit(['id', 'segments', 'shape'], layer);
    const shape = R.pathOr({}, ['segments', '0', 'shape'], layer);
    const { center, geohash } = R.pathOr({}, ['segments', '0'], layer);
    return {
      id: layer.id,
      attrs,
      center,
      geohash,
      bounds: generateBounds({ shape }),
      layerType: name,
      shape
    };
  }
  const { shape, ...other } = layer;
  return {
    bounds: generateBounds({ shape }),
    layerType: name,
    ...other,
    shape
  };
};

export const optimizeLayersForMap = (name, layers) => layers.map(layer => optimizeLayer(name, layer));
