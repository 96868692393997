import React, { Fragment, memo, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { BATCH_DATA_TYPE, GROUP_DATA_TYPE } from '@components/config/constants';

import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BatchMenuItem from './batch-menu-item';

import { getDataTypeConfig } from '@components/config/selectors';
import { getDataTypeFilterParams } from '@components/filters/selectors';

import { useCanAccessType, useTypeIsReadOnly } from '@components/config/permissions/hooks';
import { useLegacyTypes } from '../hooks';

import { push } from 'connected-react-router';
import { downloadCSVExport } from '@actions/data-table-actions';

import styles from './header.scss';

const applyLabelPostfix = (label, dataType) => {
  if (dataType === GROUP_DATA_TYPE) {
    return `${label} groups`;
  }
  return label;
};

const PortalHeader = ({ dataType, subType }) => {
  const dispatch = useDispatch();
  const [actionAnchorEl, setActionAnchorEl] = React.useState(null);

  const typeConfig = useSelector(state => getDataTypeConfig(state, dataType, subType));
  const {['top-bar']: {link, permission} = {} } = typeConfig;
  const readOnly = useTypeIsReadOnly(dataType, subType);
  const hasLinkAccess = useCanAccessType(null, dataType, subType, permission);

  const labels = useMemo(() => {
    if (typeConfig.children && subType) {
      const childLabel = typeConfig.children.find(child => child.type === subType)?.label;
      return [
        typeConfig.label,
        childLabel && applyLabelPostfix(childLabel, dataType)
      ].filter(label => Boolean(label));
    }
    return [applyLabelPostfix(typeConfig.label, dataType)];
  }, [typeConfig, dataType, subType]);

  const handleClickActions = useCallback(event => {
    setActionAnchorEl(event.currentTarget);
  }, [setActionAnchorEl]);

  const handleCloseActions = useCallback(() => {
    setActionAnchorEl(null);
  }, [setActionAnchorEl]);

  const handleLink = useCallback(() => {
    handleCloseActions();
    if (link) {
      dispatch(push(link.to));
    }
  }, [dispatch, link, handleCloseActions]);

  const [legacyDataType, legacySubType] = useLegacyTypes(dataType, subType);
  const filterParams = useSelector(state => getDataTypeFilterParams(state, dataType, subType));
  const handleExport = useCallback(() => {
    handleCloseActions();
    dispatch(downloadCSVExport(legacyDataType, legacySubType, filterParams));
  }, [dispatch, handleCloseActions, legacyDataType, legacySubType, filterParams]);

  return (
    <div className={styles.header}>
      <span className={styles.title}>
        {labels.map((label, index) => (
          <Fragment key={label}>
            {index > 0 &&
              <ChevronRightIcon/>
            }
            <span className={styles.label}>{label}</span>
          </Fragment>
        ))}
      </span>
      <IconButton onClick={handleClickActions}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={actionAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(actionAnchorEl)}
        onClose={handleCloseActions}
      >
        { link && hasLinkAccess && !readOnly &&
          <MenuItem onClick={handleLink}>Add</MenuItem>
        }
        { dataType === BATCH_DATA_TYPE &&
          <BatchMenuItem onClick={handleCloseActions} />
        }
        <MenuItem onClick={handleExport}>Export</MenuItem>
      </Menu>
    </div>
  );
};

PortalHeader.propTypes = {
  dataType: PropTypes.string,
  subType: PropTypes.string
};

export default memo(PortalHeader);
