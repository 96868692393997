/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/display-name */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { openGroupsTray } from '@actions/map-actions';
import EntityListItem from '@shared/list-item/entity-list-item';
import TriptychWidget from '@shared/list-item/widgets/triptych-widget';
import '../../../shared/list-item/list-item.scss';

class MainEntityListItem extends EntityListItem {
  openGroup = (event, group) => {
    event.stopPropagation();
    const trayProps = {
      id: group.id,
      item: group,
      position: null
    };
    this.props.openGroupsTray(trayProps);
  };

  renderNoConflictGroups = () => {
    const { groups } = this.props;
    if (isEmpty(groups)) {
      return null;
    }
    return (
      <div styleName="group-list">
        <div styleName="group-list-text">
          The list below excludes overlaps with records in&nbsp;
        </div>
        <div styleName="group-list-bold">
          {groups.map((group, index) => (
            <span key={group.id}>
              <div
                onClick={event => this.openGroup(event, group)}
                role="presentation"
                styleName="group-list-link"
              >
                {group.name}
              </div>
              {index < groups.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      </div>
    );
  };

  getFooterContent = () => {
    const { description } = this.props;
    return (
      <div>
        {description && <div styleName="description">{description}</div>}
        {this.renderNoConflictGroups()}
      </div>
    );
  };

  getType = () => {};

  getDate = () => {
    const priority = this.getPriority();
    return (
      <TriptychWidget
        displayDuration
        priority={priority ? priority.id : null}
        start={this.props.start_date}
        end={this.props.end_date}
      />
    );
  };

  getStyleNames = () => {
    const { isSelected } = this.props;
    const selected = isSelected ? 'list-item-selected' : '';
    return `fixed-main-list-item overlap-list-item ${selected}`;
  };
}

MainEntityListItem.propTypes = {
  isSelected: PropTypes.bool
};

export default connect(null, { openGroupsTray })(MainEntityListItem);
