import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { setDatatableFilter } from '@actions/data-table-actions';
import {
  getCount,
  getDataTableColumns,
  getFilters,
  getLoader,
  optimizeRowsForDataTable
} from '@selectors/data-table-selector';
import Paginator from '@shared/paginator';
import { getDataType, getSubType } from '@utils/dashboard-utils';
import './footer.scss';

class Footer extends Component {
  onLimitChange = limit => {
    const { dataType, subType, offset } = this.props;
    // Clamp offset to the new page ranges.
    const newPage = Math.floor(offset / limit);
    const newOffset = newPage * limit;
    this.props.setDatatableFilter(dataType, subType, { limit, offset: newOffset });
  };

  onOffsetChange = offset => {
    const { dataType, subType } = this.props;
    this.props.setDatatableFilter(dataType, subType, { offset });
  };

  render() {
    const { columns, count, limit, loader, offset, rows } = this.props;
    return (
      <div styleName="dashboard-footer-container">
        <div styleName="menu-section" />
        {!isEmpty(rows) &&
          <div styleName="footer-section">
            {columns &&
              <Paginator
                count={count}
                limit={limit || count}
                offset={offset || 0}
                onLimitChange={this.onLimitChange}
                onOffsetChange={this.onOffsetChange}
              />
            }
          </div>
        }
        {isEmpty(rows) && !loader && <div styleName="empty-footer-section" />}
      </div>
    );
  }
}

Footer.propTypes = {
  columns: PropTypes.array,
  count: PropTypes.number,
  dataType: PropTypes.string.isRequired,
  limit: PropTypes.number,
  loader: PropTypes.bool,
  location: PropTypes.object,
  offset: PropTypes.number,
  rows: PropTypes.array,
  setDatatableFilter: PropTypes.func,
  subType: PropTypes.string
};

const mapStateToProps = (state, props) => {
  const dataType = getDataType(props.params, props.location);
  const subType = getSubType(dataType, props);
  const filters = getFilters(state, { dataType, subType });
  const { offset, limit } = filters;
  return {
    columns: getDataTableColumns(state, { dataType, subType }),
    count: getCount(state, { dataType, subType }),
    dataType,
    limit,
    loader: getLoader(state, { dataType, subType }),
    offset,
    rows: optimizeRowsForDataTable(dataType, subType, false)(state),
    subType
  };
};

const mapDispatchToProps = {
  setDatatableFilter
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
export { Footer as PureFooter };
