import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import hexToRgba from 'hex-rgba';

import AttributeLabel from '@components/shared/ui-library/attribute/attribute-label';
import AttributeLabelContainer from '@components/shared/ui-library/attribute/attribute-label-container';

import { categoryToInt } from '@utils/filter-utils';
import { getAgencyIcon, getEntityLegend } from '@utils/icon-utils';

import { getAgencyTypes } from '@selectors/data-table-selector';
import { getAllEntityTypeConfigs, getEntityChipConfig } from '@selectors/entities-selector';
import { getConfig } from '@components/config/selectors';


const getAgencyProperties = agencyType => ({
  style: {backgroundColor: hexToRgba(agencyType.color, 15)},
  icon: <img alt="card icon" src={getAgencyIcon(agencyType)} />,
  text: agencyType.name,
  toolTip: `Agency: ${agencyType.name}`
});

const getCategoryProperties = (category, entityType, entityTypes) => {
  // Get the category icon. 
  const categoryId = categoryToInt(category.id);
  const icon = getEntityLegend(null, entityType, categoryId);
  // We will default to the entity color first to match the logic within the application.
  const fullEntityType = Object.values(entityTypes).find(e => e.name === entityType);  // eslint-disable-line id-length
  const entityColor = fullEntityType.color;
  const color = category.color ? category.color : '#EEEEEE';
  const finalColor = entityColor ? entityColor : color;
  // Return  the chip with the icon and color. 
  return ({
    style: {backgroundColor: hexToRgba(finalColor, 15)},
    icon: <img alt="" src={icon} />,
    text: category.value,
    toolTip: `${category.name}: ${category.value}`
  });
};

const getPriorityProperties = (priorityItem, iconEndpoint) => {
  return ({
    contained: false,
    icon: <img alt="" src={`${iconEndpoint}/priority/icon/18/${priorityItem.name}.svg`}/>,
    toolTip: priorityItem.tooltip
  });
};

const getCategoryValue = (entity, categoryName) => (
  entity.category_dict && entity.category_dict.find(category => category.name === categoryName)
);

const EntityChips = ({entity}) => {
  const {type_name: entityType} = entity;
  const entityTypes = useSelector(getAllEntityTypeConfigs);
  const chipConfig = useSelector(state => getEntityChipConfig(state, entityType));
  const agencyTypes = useSelector(getAgencyTypes);
  const {priorities, GOOGLE_CLOUD_STORAGE_BUCKET_URL} = useSelector(getConfig);
  const chips = chipConfig || [];

    // const chips = [
    //   {"field": "id", "label": "ID", "type": "field", "autoHide": true},
    //   {"field": "agency", "type": "agency", "autoHide": true},
    //   {"field": "priority", "type": "category", "autoHide": true},
    //   {"field": "pmac_id", "type": "attr", "autoHide": true}
    // ]
  return (
    <AttributeLabelContainer>
      { chips.map(chip => {
        const {field, label = null, type, autoHide = true} = chip;
        switch (type) {
        case 'category': {
          const categoryValue = getCategoryValue(entity, field);
          if (!categoryValue) {
            return null;
          }
          if (field === 'priority') {
            const priorityItem = Object.values(priorities).find(item => item.value === categoryValue.id);
            const priorityProperties = getPriorityProperties(priorityItem, GOOGLE_CLOUD_STORAGE_BUCKET_URL);
            return (
              priorityProperties && <AttributeLabel key={field} {...priorityProperties} />
            );
          }
          if (categoryValue.icon) {
            const categoryProperties = getCategoryProperties(categoryValue, entity.type_name, entityTypes);
            return <AttributeLabel key={field} {...categoryProperties} />;
          }
          return <AttributeLabel key={field} text={categoryValue.value}/>;
        }
        case 'agency': {
          const agencyType = agencyTypes[entity.agency_type];
          if (!agencyType) {
            return null;
          }
          const agencyProperties = getAgencyProperties(agencyType);
          return <AttributeLabel key={field} {...agencyProperties}/>;
        }
        default: {
          const value = entity[field] || (entity.extra && entity.extra[field]);
          if (autoHide && !value) {
            return null;
          }
          if (label) {
            return <AttributeLabel key={field} text={`${label} ${value}`}/>;
          }
          return <AttributeLabel key={field} text={value}/>;
        }
        }
      })}
    </AttributeLabelContainer>
  );
};

EntityChips.propTypes = {
  agencyTypes: PropTypes.object,
  chipConfig: PropTypes.array,
  entity: PropTypes.object.isRequired,
  entityTypes: PropTypes.object
};

export default memo(EntityChips);
