import React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import { SquareChip } from '@utils/autocomplete-utils';
import { getWorkflowCustomFieldTypes } from '@constants/config';
import Column from './view-field-column';
import Row from './view-field-row';
import './view-field.scss';

const ViewField = ({ data, field }) => {
  const dataTypes = useSelector(state => state.dataTypes);

  const getOptions = () => {
    if (field.source) {
      return dataTypes[field.source];
    } else
      if (field.options) {
        return field.options;
      }
    return [];
  };

  const options = getOptions();

  const getValue = () => {
    if (data) {
      const { field_values } = data;
      if (field_values && field_values.length > 0) {
        return field_values.find(field_value => field_value && field_value.field === field.id);
      }
    }
    return null;
  };

  const fieldTypes = getWorkflowCustomFieldTypes();
  const fieldType = fieldTypes[field.type];
  const label = field.name;
  const { value } = getValue() || {};

  const getOptionValue = val => {
    const option = R.find(R.propEq(parseInt(val, 10), 'id'))(R.values(options));
    return option ? option.name : null;
  };

  const getMultipleOptionValue = val => {
    if (val) {
      const values = val.split(',');
      return values.map(item => getOptionValue(item)).filter(item => item !== null);
    }
    return [];
  };

  if (
    fieldType === 'Short text' ||
    fieldType === 'Currency' ||
    fieldType === 'Numeric'
  ) {
    return <Row label={label} value={value} />;
  } else
    if (fieldType === 'Single-select dropdown') {
      const val = getOptionValue(value);
      return <Row label={label} value={val || 'None'} />;
    } else
      if (fieldType === 'Multi-select dropdown') {
        if (value) {
          const optionList = getMultipleOptionValue(value);
          const val = optionList.length > 0 ? optionList.join(', ') : 'None';
          return <Row label={label} value={val} />;
        }
      } else
        if (fieldType === 'Paragraph text') {
          if (value) {
            return <Column label={label} value={<div style={{ whiteSpace: 'pre-wrap' }}>{value}</div>} />;
          }
        } else
          if (fieldType === 'Chip multi-select') {
            const optionList = getMultipleOptionValue(value);
            if (optionList.length === 0) {
              // If there's no value, don't render a chip:
              return <Row label={label} value="None" />;
            }
            const chips = optionList.map(item => <div styleName="chip-sep" key={item}><Chip label={item} size="small" /></div>);
            return <Row label={label} value={chips} />;
          } else
            if (fieldType === 'Square chip multi-select') {
              const optionList = getMultipleOptionValue(value);
              if (optionList.length === 0) {
                // See comment above.
                return <Row label={label} value="None" />;
              }
              const chips = optionList.map(item => <div styleName="chip-sep" key={item}><SquareChip label={item} size="small" /></div>);
              return <Row label={label} value={chips} />;
            } else
              if (fieldType === 'Date picker') {
                // From a 'YYYY-MM-DD hh:mm A' string get only the date part:
                const timeValue = value ? value.slice(0, value.indexOf(' ')) : value;
                return <Row label={label} value={timeValue} />;
              } else
                if (fieldType === 'Time picker') {
                  // From a 'YYYY-MM-DD hh:mm A' string get only the time part:
                  const timeValue = value ? value.slice(value.indexOf(' ') + 1) : value;
                  return <Row label={label} value={timeValue} />;
                }
  return null;
};

ViewField.propTypes = {
  data: PropTypes.object,
  field: PropTypes.object
};

export default ViewField;
