/* eslint-disable max-depth */
import axios from 'axios';
import * as R from 'ramda';
import { push, replace } from 'connected-react-router';
import {
  DATA_DETAIL_FETCH_CYCLE_SUCCESS,
  DATA_DETAIL_FETCH_CYCLE_ERROR,
  HANDLE_SERVER_ERROR,
  WORKFLOW_CYCLE_FETCH_INIT,
  WORKFLOW_CYCLE_FETCH_SUCCESS,
  WORKFLOW_CYCLE_FETCH_ERROR
} from '@constants/action-types';
import * as dialog from '@constants/dialogs';
import { BASE_API_URL } from '@constants/endpoints';
import { pluralize } from '@utils/shared-utils';
import { fetchDataDetail, scrollToField } from './data-detail-actions';
import { fetchDataType } from './data-types-actions';
import { discardChangesAction } from './confirmation-actions';
import { closeDashboardDialog, setDialogParam } from './dashboard-actions';
import { pushApplicationMessage } from './messages-actions';
import { renameAttachments } from './attachment-actions';

const fetchCyclesSuccess = payload => ({ type: DATA_DETAIL_FETCH_CYCLE_SUCCESS, payload });

const fetchCyclesError = (payload, error) => ({ type: DATA_DETAIL_FETCH_CYCLE_ERROR, error });

let fetchCyclesSource = axios.CancelToken.source();

// Fetch the cycles for an entity or group for display on the tray:
export const fetchCycles = (type, id) => {
  const url = `${BASE_API_URL}/cycle/?${type}=${id}&compact=True`;
  fetchCyclesSource.cancel();
  fetchCyclesSource = axios.CancelToken.source();
  const request = axios.get(url, { cancelToken: fetchCyclesSource.token });
  return dispatch => request.then(
    payload => dispatch(fetchCyclesSuccess(payload.data)),
    error => {
      if (axios.isCancel(error)) {
        return;
      }
      dispatch(fetchCyclesError(error));
    }
  );
};

const initFetch = () => dispatch => {
  dispatch({ type: WORKFLOW_CYCLE_FETCH_INIT });
  return Promise.resolve();
};

const fetchCycleSuccess = payload => ({ type: WORKFLOW_CYCLE_FETCH_SUCCESS, payload });

const fetchCycleError = (payload, error) => ({ type: WORKFLOW_CYCLE_FETCH_ERROR, error });

let fetchCycleSource = axios.CancelToken.source();

// Fetch a single cycle:
export const fetchCycle = id => dispatch => dispatch(initFetch()).then(() => {
  const url = `${BASE_API_URL}/cycle/${id}/`;
  fetchCycleSource.cancel();
  fetchCycleSource = axios.CancelToken.source();
  const request = axios.get(url, { cancelToken: fetchCycleSource.token });
  request.then(
    payload => dispatch(fetchCycleSuccess(payload.data)),
    error => {
      if (axios.isCancel(error)) {
        return;
      }
      dispatch(fetchCycleError(error));
    }
  );
});

const getCustomFieldUrl = id => `${BASE_API_URL}/custom_field/${id ? `${id}/` : ''}`;
const getTaskUrl = id => `${BASE_API_URL}/task/${id ? `${id}/` : ''}`;

export const updateTask = task => async dispatch => {
  const { tempTaskId, ...taskData } = task;
  try {
    dispatch(setDialogParam(dialog.ASSIGN_TASK, { loading: true }));
    const method = taskData.id ? 'patch' : 'post';
    const response = await axios[method](getTaskUrl(taskData.id), { ...taskData });
    const { data } = response;
    dispatch(fetchCycle(data.cycle));
    dispatch(closeDashboardDialog(dialog.ASSIGN_TASK));
    dispatch(discardChangesAction());
    dispatch(pushApplicationMessage(`Task successfully ${taskData.id ? 'updated' : 'assigned'}`));
    dispatch(push(`/cycle/${taskData.cycle}/task/${data.id}/`));
    dispatch(scrollToField(`task-item-${data.id}`));
    dispatch(renameAttachments(tempTaskId, data.id, 'task'));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const changeTaskStatus = (activityId, statusName, isLate) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/task_activity/${activityId}/status/`;
    const response = await axios.post(url, { statusName, isLate });
    const { data: { task_id } } = response;
    dispatch(fetchDataDetail('task', task_id));
    dispatch(closeDashboardDialog(dialog.TASK_STATUS));
    dispatch(pushApplicationMessage('Task activity status successfully changed'));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const updateActivity = (activityId, payload, field) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/task_activity/${activityId}/update/`;
    const response = await axios.post(url, { ...payload });
    const { data: { task_id } } = response;
    dispatch(fetchDataDetail('task', task_id));
    dispatch(pushApplicationMessage(`Task activity ${field} successfully updated`));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

// If the id changed (due to versioning) redirect.
const redirectIfNewVersion = (dispatch, portal, type, id, new_id) => {
  if (String(new_id) !== String(id)) {
    dispatch(replace({
      pathname: `/${portal}/${type}/${new_id}`,
      state: { clear: true }
    }));
  }
};

export const saveCustomField = (id, payload, callback, errorCallback) => async dispatch => {
  try {
    const method = id ? 'patch' : 'post';
    const response = await axios[method](getCustomFieldUrl(id), { ...payload });
    dispatch(fetchDataDetail('task_type', response.data.task_type)).then(data => {
      dispatch(pushApplicationMessage(`${id ? 'Updated' : 'Added'} field "${payload.name}".`));
      redirectIfNewVersion(dispatch, 'admin', 'task_type', payload.task_type, data.id);
      if (callback) {
        callback();
      }
    });
  } catch (error) {
    // If we try to save a field whose name already exists, display the error
    if (errorCallback) {
      try {
        const data = R.pathOr(null, ['response', 'data', 'non_field_errors', 0], error);
        if (data === 'The fields name, task_type must make a unique set.') {
          errorCallback('Field already exists, choose a different name.');
          return;
        }
      } catch {
        // If there's some problem parsing the error (maybe other kind
        // of error besides non_field_errors, use the default fallback below.
      }
    }
    // For any other kind of errors, use the Snackbar:
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const deleteCustomField = (id, payload, callback) => async dispatch => {
  try {
    const response = await axios.delete(getCustomFieldUrl(id));
    dispatch(fetchDataDetail('task_type', response.data.task_type_id)).then(data => {
      dispatch(pushApplicationMessage(`Deleted field "${payload.name}".`));
      redirectIfNewVersion(dispatch, 'admin', 'task_type', payload.task_type, data.id);
      if (callback) {
        callback();
      }
    });
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const reorderCustomFields = payload => async dispatch => {
  try {
    const url = `${BASE_API_URL}/custom_field/reorder/`;
    const response = await axios.post(url, { ...payload });
    dispatch(fetchDataDetail('task_type', response.data.task_type_id)).then(data => {
      redirectIfNewVersion(dispatch, 'admin', 'task_type', payload.task_type, data.id);
    });
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const discardTaskType = id => async dispatch => {
  try {
    const url = `${BASE_API_URL}/task_type/${id}/discard/`;
    const response = await axios.post(url);
    const { task_type_id } = response.data;
    dispatch(fetchDataDetail('task_type', task_type_id));  // Reload details page to show the previous data.
    dispatch(fetchDataType('task_type'));  // Reload the listing page, in case the user goes back, also to refresh the data.
    dispatch(replace(`/admin/task_type/${task_type_id}`));
    dispatch(pushApplicationMessage('Unpublised changes discarded.'));
    dispatch(closeDashboardDialog(dialog.TASK_TYPE_DISCARD));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const publishTaskType = (id, name) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/task_type/${id}/publish/`;
    await axios.post(url);
    dispatch(fetchDataDetail('task_type', id));  // Reload details page to show the new 'Published' status.
    dispatch(fetchDataType('task_type'));  // Reload the listing page, in case the user goes back, also to refresh the status.
    dispatch(replace(`/admin/task_type/${id}`));
    dispatch(pushApplicationMessage(`Published task type "${name}".`));
    dispatch(closeDashboardDialog(dialog.TASK_TYPE_PUBLISH));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const addWorkflowTaskType = (id, correlationIds) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/workflow/${id}/add_task_type/`;
    await axios.post(url, { correlationIds });
    const added = correlationIds.length;
    const label = pluralize(added, 'task type');
    dispatch(fetchDataDetail('workflow', id));  // Reload details page to show the new task type.
    dispatch(pushApplicationMessage(`Added ${added} ${label}.`));
    dispatch(closeDashboardDialog(dialog.ADD_WORKFLOW_TASK_TYPE));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const deleteWorkflowTaskType = (id, taskType) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/workflow/${id}/delete_task_type/${taskType.correlation_id}/`;
    await axios.post(url);
    dispatch(fetchDataDetail('workflow', id));  // Reload details page to show the updated task type list.
    dispatch(pushApplicationMessage(`Removed task type "${taskType.name}".`));
    dispatch(closeDashboardDialog(dialog.DELETE_WORKFLOW_TASK_TYPE));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};

export const reorderWorkflowTaskType = (id, payload) => async dispatch => {
  try {
    const url = `${BASE_API_URL}/workflow/${id}/reorder/`;
    await axios.post(url, { ...payload });
    dispatch(fetchDataDetail('workflow', id));
  } catch (error) {
    dispatch({ type: HANDLE_SERVER_ERROR, payload: { error } });
  }
};
