import React from 'react';
import { isString } from 'lodash';
import * as R from 'ramda';
import { connect } from 'react-redux';
import { baseMapStateToProps, DashboardBaseDropDownFilter } from '../dashboard-base-dropdown-filter';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getCategoryIcon, getPriorityIconUrl } from '@utils/icon-utils';
import '../../../../../utils/filter-utils.scss';

const SOURCE_DATA_TYPE = 'map_category';

class CategoryFilter extends DashboardBaseDropDownFilter {
  getItems() {
    const { items } = this.props;
    const categories = R.values(R.pickBy(category => category.id > 0, items));
    const blank = R.values(R.pickBy(category => isString(category.id), items));
    return [...blank, ...categories];
  }
  getDisplayName() {
    return this.props.label;
  }
  getPrimaryTextIcon(item) {
    if (this.props.categoryType === 'priority') {
      const icon = getPriorityIconUrl(item.id);
      return <img src={icon} styleName="icon" alt="" />;
    }
    if (this.props.showIcon) {
      const icon = getCategoryIcon(this.props.dataType, item.id);
      return <img src={icon} styleName="icon" alt="" />;
    }
    return null;
  }
}

const mapStateToProps = (state, props) => {
  const { dataType, categoryType, categoryTypeId, filter, subType } = props;
  const items = R.pickBy(category => category.type === categoryTypeId && category.active, state.dataTypes[SOURCE_DATA_TYPE]);
  const params = {
    dataType,
    subType,
    sourceDataType: SOURCE_DATA_TYPE,
    lazyName: () => `${filter[0]}|${categoryType}`,
    items
  };
  return baseMapStateToProps(state, params);
};

export default connect(mapStateToProps, { setFilter: setDatatableFilter })(CategoryFilter);
