import React from 'react';
import { capitalize } from 'lodash';
import { getEntityTypeLabel } from '@constants/config';
import TruncatedCell from '@shared/data-table/cells/truncated-cell';
import { enDash, renderTooltipDate } from '@utils/shared-utils';

const getCreated = batch => batch.created_by.name ? batch.created_by.name : batch.created_by.email;

const getBatchTypeLabel = typeName => {
  switch (typeName) {
  case 'Layer':
    return 'City Layer';
  default:
    return capitalize(getEntityTypeLabel(typeName.toLowerCase()));
  }
};

export const optimizeBatchUploadData = (batch, agencies) => ({
  ...batch,
  file: <TruncatedCell>{batch.file}</TruncatedCell>,
  agency: agencies[batch.created_by.agency] ? agencies[batch.created_by.agency].name : enDash,
  created: renderTooltipDate(batch.created),
  type_name: getBatchTypeLabel(batch.type_name),
  uploaded_by: <TruncatedCell>{getCreated(batch)}</TruncatedCell>
});

export const optimizeBatchUploadDetail = (batch) => ({
  ...batch,
  type_name: getBatchTypeLabel(batch.type_name)
});
