/* eslint-disable react/display-name */
/* eslint-disable max-len */
import React from 'react';
import * as R from 'ramda';
import AddToGroupIcon from '@icons/activity-logs/add-to-group-icon';
import AgencyIcon from '@icons/activity-logs/agency-icon';
import AssignmentIcon from '@icons/activity-logs/assignment-ind-icon';
import AttachmentIcon from '@icons/activity-logs/attachment-icon';
import AttachmentOffIcon from '@icons/activity-logs/attachment-off-icon';
import BaselineIcon from '@icons/activity-logs/baseline-icon';
import CalendarIcon from '@icons/activity-logs/calendar-icon';
import CancelIcon from '@icons/activity-logs/cancel-icon';
import CheckCircleIcon from '@icons/activity-logs/check-circle-icon';
import CommentIcon from '@icons/activity-logs/comment-icon';
import CurrentPMIcon from '@icons/activity-logs/current-pm-icon';
import EditIcon from '@icons/activity-logs/edit-icon';
import LocationIcon from '@icons/activity-logs/location-icon';
import PMIcon from '@icons/activity-logs/pm-icon';
import PolygonIcon from '@icons/activity-logs/polygon-icon';
import PrioritizeIcon from '@icons/activity-logs/prioritize-icon';
import RemoveFromGroupIcon from '@icons/activity-logs/remove-from-group-icon';
import WarningIcon from '@icons/activity-logs/warning-icon';
import WarningOffIcon from '@icons/activity-logs/warning-off-icon';
import { getEntityTypeLabel, getGlobalAliases } from '@constants/config';
import {
  appendBatch,
  countOverlaps,
  getConflicts,
  getOpportunities,
  renderBold,
  renderCategory,
  renderComment,
  renderConflictExtra,
  renderIdFromAttrs,
  renderOfEntityId,
  renderOverlapCreatedType,
  renderEntityId,
  renderOwners,
  renderSqueezedIds,
  renderToEntityId,
  renderUpdated
} from '@utils/logs-utils';
import {
  TaskActivityStatus,
  WorkflowInfo
} from '@utils/notification-utils';
import { pluralize } from '@utils/shared-utils';
import { buildAssignedTaskTo } from '@utils/workflow-utils';

const updateDetailsTooltip = type => <span>Update in {type} details</span>;
const updatePriorityTooltip = <span>Change in priority</span>;

// Returns the configured alias for 'owners' if defined, else returns the default 'owners' string.
const ownersAlias = type => R.pathOr('owners', ['owners', type], getGlobalAliases()).toLowerCase();

const getCategoryName = attrs => R.pathOr(null, ['category_type', 'name'], attrs);
const isPriorityCategory = attrs => getCategoryName(attrs) === 'priority';

// Returns the icon to show for the log entry.
// It checks if the entry is about the 'priority' category,
// returning a priority icon, else defaults to an edit icon.
const getIcon = attrs => isPriorityCategory(attrs) ? <PrioritizeIcon /> : <EditIcon />;

const getTooltip = (type, attrs) => isPriorityCategory(attrs) ? updatePriorityTooltip : updateDetailsTooltip(type);

// Each entry in actionsMap contains the following fields:
//
//     icon: An SVG icon component to show in the activity log row.
//
//     tooltip: The tooltip content to show when the user hovers the icon.
//
//     template: The template function, it will receive the attributes and will render the
//               action's template.
//
//     extra: A second template function, it renders 'extra' content for each row.
//            This is needed so we can render the template alignment correctly with the
//            row, and if there's extra content, we can render below it, without affecting
//            the main row's alignment (it's mainly used to render the squeeded ids).
//
export const actionsMap = {
  // These two actions are used in the backend to count the number of processed rows,
  // for the listing page or emails.
  // We don't need this in the UI, since we don't show the activity log for batch upload,
  // but leave this uncommented in case we need to start displaying that.
  batch_entity_created: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: attrs => `created the ${getEntityTypeLabel(attrs.entity)} through batch upload.`
  },
  batch_entity_updated: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: attrs => `updated the ${getEntityTypeLabel(attrs.entity)} through batch upload.`
  },
  conflict_to_opportunity: {
    icon: <WarningIcon />,
    tooltip: 'In overlap or change in overlap type.',
    extra: (attrs, model, index) => {
      if (attrs.ids && attrs.ids.length > 1) {
        return renderSqueezedIds(attrs, index, attrs.entity);
      }
      return null;
    },
    template: attrs => {
      const entity = getEntityTypeLabel(attrs.entity);
      if (attrs.ids) {
        if (attrs.ids.length === 1) {
          return <span>{renderEntityId(attrs.ids[0], entity)} is now an {renderBold('opportunity')}.</span>;
        }
        return (
          <span>
            {attrs.ids.length} {entity}s are now opportunities.<br />
          </span>
        );
      }
      return <span>{renderEntityId(attrs.id, entity)} is now an {renderBold('opportunity')}.</span>;
    }
  },
  group_add_entity: {
    icon: <AddToGroupIcon />,
    tooltip: 'Added to group',
    extra: (attrs, model, index) => renderSqueezedIds(attrs, index, attrs.entity),
    template: (attrs, user) => {
      if (attrs.ids) {
        if (user) {
          return (
            <span>
              added {attrs.ids.length} {pluralize(attrs.ids.length, getEntityTypeLabel(attrs.entity))} to the group.<br />
            </span>
          );
        }
        return (
          <span>
            {attrs.ids.length} {pluralize(attrs.ids.length, getEntityTypeLabel(attrs.entity))} are added to the group.<br />
          </span>
        );
      }
      if (user) {
        return <span>added {renderIdFromAttrs(attrs)} to the group.</span>;
      }
      return <span>{renderIdFromAttrs(attrs)} is added to the group.</span>;
    }
  },
  group_created: {
    icon: <EditIcon />,
    tooltip: 'Update in group details',
    template: () => 'created the group.'
  },
  group_created_dates: {
    icon: <CalendarIcon />,
    tooltip: 'Update in date range',
    template: attrs => <span>set the {renderBold('custom date range')} to {renderBold(attrs.start_date)}&ndash;{renderBold(attrs.end_date)}.</span>
  },
  group_removed_dates: {
    icon: <CalendarIcon />,
    tooltip: 'Update in date range',
    template: () => <span>removed the {renderBold('custom date range')}.</span>
  },
  group_removed_polygon: {
    icon: <PolygonIcon />,
    tooltip: 'Update in polygon',
    template: () => <span>removed the {renderBold('polygon')}.</span>
  },
  group_remove_entity: {
    icon: <RemoveFromGroupIcon />,
    tooltip: 'Removed from group',
    extra: (attrs, model, index) => renderSqueezedIds(attrs, index, attrs.entity),
    template: (attrs, user) => {
      if (attrs.ids) {
        if (user) {
          return (
            <span>
              removed {attrs.ids.length} {pluralize(getEntityTypeLabel(attrs.entity), attrs.ids.length)} from the group.<br />
            </span>
          );
        }
        return (
          <span>
            {attrs.ids.length} {pluralize(getEntityTypeLabel(attrs.entity), attrs.ids.length)} are removed from the group.<br />
          </span>
        );
      }
      if (user) {
        return <span>removed {renderIdFromAttrs(attrs)} from the group.</span>;
      }
      return <span>{renderIdFromAttrs(attrs)} is removed from the group.</span>;
    }
  },
  group_set_baseline: {
    icon: <BaselineIcon />,
    tooltip: 'Update in baseline',
    template: () => <span>set the {renderBold('baseline')}.</span>
  },
  group_update_baseline: {
    icon: <BaselineIcon />,
    tooltip: 'Update in baseline',
    template: () => <span>updated the {renderBold('baseline')}.</span>
  },
  group_updated_dates: {
    icon: <CalendarIcon />,
    tooltip: 'Update in date range',
    template: attrs => (
      <span>
        modified the {renderBold('custom date range')} to {renderBold(attrs.start_date)}&ndash;{renderBold(attrs.end_date)}.
      </span>
    )
  },
  group_updated_name: {
    icon: <EditIcon />,
    tooltip: 'Update in group details',
    template: () => <span>updated the group {renderBold('name')}.</span>
  },
  group_updated_owner: {
    icon: <EditIcon />,
    tooltip: 'Update in group details',
    template: () => <span>updated the group {renderBold('owner')}.</span>
  },
  group_created_polygon: {
    icon: <PolygonIcon />,
    tooltip: 'Update in polygon',
    template: () => <span>created the {renderBold('polygon')}.</span>
  },
  group_updated_polygon: {
    icon: <PolygonIcon />,
    tooltip: 'Update in polygon',
    template: () => <span>modified the {renderBold('polygon')}.</span>
  },
  group_updated_status: {
    icon: <EditIcon />,
    tooltip: 'Update in group details',
    template: () => <span>updated the group {renderBold('status')}.</span>
  },
  opportunity_to_conflict: {
    icon: <WarningIcon />,
    tooltip: 'In overlap or change in overlap type',
    extra: (attrs, model, index) => {
      if (attrs.ids && attrs.ids.length > 1) {
        return renderSqueezedIds(attrs, index, attrs.entity);
      }
      return null;
    },
    template: attrs => {
      const entity = getEntityTypeLabel(attrs.entity);
      if (attrs.ids) {
        if (attrs.ids.length === 1) {
          return <span>{renderEntityId(attrs.ids[0], entity)} is now a {renderBold('conflict')}.</span>;
        }
        return (
          <span>
            {attrs.ids.length} {entity}s are now conflicts.<br />
          </span>
        );
      }
      return <span>{renderEntityId(attrs.id, entity)} is now a {renderBold('conflict')}.</span>;
    }
  },
  overlap_created: {
    icon: <WarningIcon />,
    tooltip: 'In overlap or change in overlap type',
    extra: (attrs, model, index) => {
      const entity = getEntityTypeLabel(attrs.entity);
      const count = countOverlaps(attrs);
      if (count > 0) {
        if (count > 1) {
          const newAttrs = {
            ids: [...getOpportunities(attrs), ...getConflicts(attrs)]
          };
          return renderSqueezedIds(newAttrs, index, entity);
        }
      } else
        if (attrs.ids && attrs.ids.length > 1) {
          return renderSqueezedIds(attrs, index, entity);
        }
      return null;
    },
    template: attrs => {
      const entity = getEntityTypeLabel(attrs.entity);
      const count = countOverlaps(attrs);
      if (count > 0) {
        const opportunities = getOpportunities(attrs);
        const conflicts = getConflicts(attrs);
        if (count === 1) {
          if (opportunities.length === 1) {
            return <span>{renderEntityId(opportunities[0], entity)} is an overlap. {renderOverlapCreatedType({ is_opportunity: true })}</span>;
          }
          return <span>{renderEntityId(conflicts[0], entity)} is an overlap. {renderOverlapCreatedType({ is_opportunity: false })}</span>;
        }
        return (
          <span>
            {count} {entity}s are overlaps. ({conflicts.length} conflicts, {opportunities.length} opportunities)<br />
          </span>
        );
      } else
        if (attrs.ids) {
          if (attrs.ids.length === 1) {
            return <span>{renderEntityId(attrs.ids[0], entity)} is an overlap.</span>;
          }
          return (
            <span>
              {attrs.ids.length} {entity}s are overlaps.<br />
            </span>
          );
        }
      return <span>{renderEntityId(attrs.id, entity)} is an overlap. {renderOverlapCreatedType(attrs)}</span>;
    }
  },
  overlap_created_file: {
    icon: <AttachmentIcon />,
    tooltip: 'File attachment',
    template: attrs => <span>{renderIdFromAttrs(attrs)} has a new file {renderBold(attrs.filename)}.</span>
  },
  overlap_deleted: {
    icon: <WarningOffIcon />,
    tooltip: 'Not in overlap',
    extra: (attrs, model, index) => renderSqueezedIds(attrs, index, attrs.entity),
    template: attrs => {
      const entity = getEntityTypeLabel(attrs.entity);
      if (attrs.ids) {
        return (
          <span>
            {attrs.ids.length} {entity}s are no longer overlaps.<br />
          </span>
        );
      }
      return <span>{renderEntityId(attrs.id, entity)} is no longer an overlap.</span>;
    }
  },
  overlap_reassign_pm: {
    icon: <AssignmentIcon />,
    tooltip: 'Change in assigned PM',
    template: attrs => {
      return (
        <span>
          reassigned {renderIdFromAttrs(attrs)} to {renderBold(attrs.pm_name)}{appendBatch(attrs)}.
        </span>
      );
    }
  },
  overlap_comment: {
    icon: <CommentIcon />,
    tooltip: 'Overlap comment',
    extra: props => renderComment(props.index, props.comment, props.users),
    template: attrs => <span>commented on overlap {renderIdFromAttrs(attrs)}.</span>
  },
  cycle_created: {
    icon: <EditIcon />,
    tooltip: () => <span>Cycle update</span>,
    template: attrs => <span>created {renderIdFromAttrs(attrs)}.</span>
  },
  task_activity_comment: {
    icon: <CommentIcon />,
    tooltip: 'Task activity comment',
    extra: props => renderComment(props.index, props.comment, props.users),
    template: attrs => <span>commented on {renderIdFromAttrs(attrs)}.</span>
  },
  task_created: {
    icon: <EditIcon />,
    tooltip: () => <span>Task update</span>,
    template: attrs => <span>created {renderIdFromAttrs(attrs)}.</span>
  },
  task_deleted: {
    icon: <EditIcon />,
    tooltip: () => <span>Task update</span>,
    template: (attrs, user, model, type) => (  // eslint-disable-line no-unused-vars
      <span>
        deleted {renderIdFromAttrs(attrs)}
      </span>
    )
  },
  task_activity_status_update: {
    icon: <EditIcon />,
    tooltip: 'Task activity status update',
    extra: props => {
      const { attrs, taskStatuses } = props;  // eslint-disable-line react/prop-types
      const statuses = Object.values(taskStatuses);
      const oldStatus = statuses.find(status => status.name === attrs.old_status);
      const newStatus = statuses.find(status => status.name === attrs.new_status);
      return (
        <div>
          <WorkflowInfo attrs={attrs} />
          <TaskActivityStatus
            attrs={attrs}
            oldStatus={oldStatus}
            newStatus={newStatus}
          />
        </div>
      );
    },
    template: attrs => <span> transitioned a task to {attrs.new_status}</span>
  },
  task_assign: {
    icon: <EditIcon />,
    tooltip: 'Task activity status update',
    template: attrs => <span>has assigned a task to {renderBold(buildAssignedTaskTo(attrs))}</span>,
    extra: props => <WorkflowInfo attrs={props.attrs} />  // eslint-disable-line react/prop-types
  },
  overlap_resolve: {
    icon: <CheckCircleIcon />,
    tooltip: 'Conflict resolved',
    extra: props => renderConflictExtra(props.index, props.attrs, 'overlap_resolve', props.users),
    template: attrs => (
      <span>
        resolved a {renderBold('conflict')}
        {attrs.both_sides && ' from both sides'}
        {!attrs.both_sides && attrs.agency && <span> on behalf of {renderBold(attrs.agency)}</span>}
        .
      </span>
    )
  },
  overlap_revoke: {
    icon: <CancelIcon />,
    tooltip: 'Resolution revoked',
    extra: props => renderConflictExtra(props.index, props.attrs, 'overlap_revoke', props.users),
    // eslint-disable-next-line no-unused-vars
    template: attrs => (
      <span>
        revoked a {renderBold('resolution')}
        {!attrs.both_sides && attrs.agency && <span> on behalf of {renderBold(attrs.agency)}</span>}
        .
      </span>
    )
  },
  overlap_updated_agency: {
    icon: <AgencyIcon />,
    tooltip: 'Change in agency',
    template: attrs => (
      <span>
        changed the {renderBold('agency')} of {renderIdFromAttrs(attrs)} from {renderBold(attrs.old)} to {renderBold(attrs.new)}{appendBatch(attrs)}.
      </span>
    )
  },
  overlap_updated_dates: {
    icon: <CalendarIcon />,
    tooltip: 'Update in schedule info',
    template: attrs => (
      <span>
        changed the {renderBold('start and end dates')} of {renderIdFromAttrs(attrs)}{appendBatch(attrs)}.
      </span>
    )
  },
  overlap_updated_end_date: {
    icon: <CalendarIcon />,
    tooltip: 'Update in schedule info',
    template: attrs => (
      <span>
        changed the {renderBold('end date')} of {renderIdFromAttrs(attrs)}{appendBatch(attrs)}.
      </span>
    )
  },
  overlap_updated_locations: {
    icon: <LocationIcon />,
    tooltip: 'Update in location info',
    template: attrs => (
      <span>
        updated the {renderBold('location(s)')} of {renderIdFromAttrs(attrs)}{appendBatch(attrs)}.
      </span>
    )
  },
  overlap_updated_category: {
    icon: attrs => getIcon(attrs),
    tooltip: (type, attrs) => getTooltip(type, attrs),
    template: attrs => (
      <span>changed the {renderCategory(attrs)}{renderOfEntityId(attrs)} from {renderBold(attrs.old.name)} to {renderBold(attrs.new.name)}{appendBatch(attrs)}.</span>
    )
  },
  overlap_updated_start_date: {
    icon: <CalendarIcon />,
    tooltip: 'Update in schedule info',
    template: attrs => (
      <span>
        changed the {renderBold('start date')} of {renderIdFromAttrs(attrs)}{appendBatch(attrs)}.
      </span>
    )
  },
  entity_created: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: (attrs, user, model, type) => {
      if (attrs && attrs.batch) {
        return `created the ${getEntityTypeLabel(type)} through batch upload.`;
      }
      return `created the ${getEntityTypeLabel(type)}.`;
    }
  },
  entity_created_file: {
    icon: <AttachmentIcon />,
    tooltip: 'File attachment',
    template: attrs => <span>uploaded a file {renderBold(attrs.filename)}{renderToEntityId(attrs)}.</span>
  },
  entity_deleted_file: {
    icon: <AttachmentOffIcon />,
    tooltip: 'File removal',
    template: attrs => <span>deleted a file {renderBold(attrs.filename)}.</span>
  },
  entity_deleted: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: (attrs, user, model, type) => (  // eslint-disable-line no-unused-vars
      <span>
        deleted {renderEntityId(attrs.id, attrs.entity)}.
      </span>
    )
  },
  // Action to display old entries, in which there were no detail about what the change was about.
  entity_updated: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: attrs => <span>updated the {attrs.entity}.</span>
  },
  entity_updated_agency: {
    icon: <AgencyIcon />,
    tooltip: 'Change in agency',
    template: attrs => <span>changed the {renderBold('agency')}{renderOfEntityId(attrs)} from {renderBold(attrs.old)} to {renderBold(attrs.new)}{appendBatch(attrs)}.</span>
  },
  entity_updated_contact: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: attrs => <span>updated the {renderBold('contact information')}{appendBatch(attrs)}.</span>
  },
  entity_updated_generic: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: (attrs, user, model, type) => (
      <span>updated the {getEntityTypeLabel(type)}{appendBatch(attrs)}. {renderUpdated(attrs)}</span>
    )
  },
  entity_reassign_pm: {
    icon: <AssignmentIcon />,
    tooltip: 'Change in assigned PM',
    template: (attrs, user, model, type) => {
      if (attrs && attrs.id) {
        return <span>reassigned {renderIdFromAttrs(attrs)} to {renderBold(attrs.pm_name)}{appendBatch(attrs)}.</span>;
      }
      return <span>reassigned the {type} to {renderBold(attrs.pm_name)}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_locations: {
    icon: <LocationIcon />,
    tooltip: 'Update in location info',
    template: (attrs, user, model, type) => {
      if (attrs && attrs.id) {
        return <span>updated the {renderBold('location(s)')}{renderOfEntityId(attrs)}{appendBatch(attrs)}.</span>;
      }
      return <span>updated the {getEntityTypeLabel(type)} {renderBold('location(s)')}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_dates: {
    icon: <CalendarIcon />,
    tooltip: 'Update in schedule info',
    template: (attrs, user, model, type) => {
      if (attrs && attrs.id) {
        return <span>updated the {renderBold('start and end dates')}{renderOfEntityId(attrs)}{appendBatch(attrs)}.</span>;
      }
      return <span>updated the {getEntityTypeLabel(type)} {renderBold('start and end dates')}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_end_date: {
    icon: <CalendarIcon />,
    tooltip: 'Update in schedule info',
    template: (attrs, user, model, type) => {
      if (attrs && attrs.id) {
        return <span>updated the {renderBold('end date')}{renderOfEntityId(attrs)}{appendBatch(attrs)}.</span>;
      }
      return <span>updated the {getEntityTypeLabel(type)} {renderBold('end date')}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_start_date: {
    icon: <CalendarIcon />,
    tooltip: 'Update in schedule info',
    template: (attrs, user, model, type) => {
      if (attrs && attrs.id) {
        return <span>updated the {renderBold('start date')}{renderOfEntityId(attrs)}{appendBatch(attrs)}.</span>;
      }
      return <span>updated the {getEntityTypeLabel(type)} {renderBold('start date')}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_name: {
    icon: <EditIcon />,
    tooltip: updateDetailsTooltip,
    template: (attrs, user, model, type) => (
      <span>renamed the {getEntityTypeLabel(type)} to {renderBold(attrs.new)}{appendBatch(attrs)}.</span>
    )
  },
  entity_updated_owners_add: {
    icon: <AssignmentIcon />,
    tooltip: () => <span>Change in assigned {ownersAlias('short')}</span>,
    template: attrs => {
      if (attrs && attrs.id) {
        return <span>added the {ownersAlias('plural')}: {renderOwners(attrs.added)}{appendBatch(attrs)} of {renderIdFromAttrs(attrs)}.</span>;
      }
      return <span>added the {ownersAlias('plural')}: {renderOwners(attrs.added)}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_owners_delete: {
    icon: <AssignmentIcon />,
    tooltip: () => <span>Change in assigned {ownersAlias('short')}</span>,
    template: attrs => {
      if (attrs && attrs.id) {
        return <span>deleted the {ownersAlias('plural')}: {renderOwners(attrs.deleted)}{appendBatch(attrs)} of {renderIdFromAttrs(attrs)}.</span>;
      }
      return <span>deleted the {ownersAlias('plural')}: {renderOwners(attrs.deleted)}{appendBatch(attrs)}.</span>;
    }
  },
  entity_updated_category: {
    icon: attrs => getIcon(attrs),
    tooltip: (type, attrs) => getTooltip(type, attrs),
    template: attrs => (
      <span>changed the {renderCategory(attrs)}{renderOfEntityId(attrs)} from {renderBold(attrs.old.name)} to {renderBold(attrs.new.name)}{appendBatch(attrs)}.</span>
    )
  },
  // Pseudo actions to be able to reuse the ActivityLogsIcon component to display icons and tooltips.
  _pseudo_action_current_pm: {
    icon: <CurrentPMIcon />,
    tooltip: 'Current project manager'
  },
  _pseudo_action_pm: {
    icon: <PMIcon />,
    tooltip: 'Action was done while user was assigned project manager'
  }
};
