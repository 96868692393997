import React, { Component } from 'react';
import PropTypes from 'prop-types';
import transitions from 'material-ui/styles/transitions';

// A material-ui based data-uri icon (based on material-ui SvgIcon class).
class DataUriIcon extends Component {
  state = { hovered: false };

  handleMouseLeave = event => {
    this.setState({hovered: false});
    this.props.onMouseLeave(event);
  };

  handleMouseEnter = event => {
    this.setState({hovered: true});
    this.props.onMouseEnter(event);
  };

  render() {
    const {
      children,
      color,
      data,
      hoverColor,
      onMouseEnter, // eslint-disable-line no-unused-vars
      onMouseLeave, // eslint-disable-line no-unused-vars
      style,
      ...other
    } = this.props;

    const {
      palette,
      prepareStyles
    } = this.context.muiTheme;

    const offColor = color ? color : 'currentColor';
    const onColor = hoverColor ? hoverColor : offColor;

    const mergedStyles = Object.assign({
      display: 'inline-block',
      backgroundImage: `url(data:image/png;base64,${data})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: `${style.height || 24}px ${style.width || 24}px`,
      color: palette.textColor,
      fill: this.state.hovered ? onColor : offColor,
      height: 24,
      width: 24,
      userSelect: 'none',
      transition: transitions.easeOut()
    }, style);

    return (
      <div
        {...other}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        style={prepareStyles(mergedStyles)}
      >
        {children}
      </div>
    );
  }
}

DataUriIcon.propTypes = {
  /**
   * Elements passed into the PNG Icon.
   */
  children: PropTypes.node,
  /**
   * This is the fill color of the PNG icon.
   * If not specified, this component will default
   * to muiTheme.palette.textColor.
   */
  color: PropTypes.string,
  /**
   * The base64 encoded PNG image.
   */
  data: PropTypes.string,
  /**
   * This is the icon color when the mouse hovers over the icon.
   */
  hoverColor: PropTypes.string,
  /** @ignore */
  onMouseEnter: PropTypes.func,
  /** @ignore */
  onMouseLeave: PropTypes.func,
  /**
   * Override the inline-styles of the root element.
   */
  style: PropTypes.object
};

DataUriIcon.defaultProps = {
  onMouseEnter: () => {},
  onMouseLeave: () => {}
};

DataUriIcon.contextTypes = {
  muiTheme: PropTypes.object.isRequired
};

export default DataUriIcon;
