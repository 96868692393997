/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeDashboardDialog } from '@actions/dashboard-actions';
import { deleteCustomField } from '@actions/workflow-actions';
import * as dialog from '@constants/dialogs';
import { dialogStyles } from '@constants/mui-theme';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const DeleteTaskTypeFieldDialog = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);
  const { activeDialogs } = dashboard;
  const isDialogActive = activeDialogs[dialog.DELETE_TASK_TYPE_FIELD];
  if (!isDialogActive) {
    return null;
  }

  const dialogParams = dashboard.dialogParams[dialog.DELETE_TASK_TYPE_FIELD];
  const { id, name, task_type } = dialogParams;

  const onClose = () => dispatch(closeDashboardDialog(dialog.DELETE_TASK_TYPE_FIELD));

  const onDelete = () => dispatch(deleteCustomField(id, { name, task_type }, onClose));

  return (
    <Dialog maxWidth="sm" fullWidth open={isDialogActive}>
      <DialogTitle>
        <div style={dialogStyles.title}>
          Delete field?
        </div>
      </DialogTitle>
      <DialogContent>
        Field "{name}" will be permanently deleted. This cannot be undone.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>CANCEL</Button>
        <Button
          color="secondary"
          onClick={onDelete}
          variant="contained"
        >
          DELETE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteTaskTypeFieldDialog;
