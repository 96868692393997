import React, {memo, useCallback} from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import { PageEllipses } from './utils';

import styles from './page-button.scss';

const PageButton = (props) => {
  const { page, current, onSelect } = props;
  const isCurrent = current === page;
  const isPageEllipses = page instanceof PageEllipses;

  const handleClick = useCallback(() => {
    onSelect(page);
  }, [onSelect, page]);
  
  if (isPageEllipses) {
    return (
      <div className={styles.ellipses}>
        <span>{page.str()}</span>
      </div>
    );
  }

  if (isCurrent) {
    return (
      <div className={styles.current}>
        <span>
          {page}
        </span>
      </div>
    );
  }
  return (
    <div className={styles.page}>
      <Button
        onClick={handleClick}
        classes={{root: styles.page}}
      >
        {page}
      </Button>
    </div>
  );
};

PageButton.propTypes = {
  current: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  page: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(PageEllipses)]).isRequired
};

export default memo(PageButton);
