import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  BicyclingLayer,
  GoogleMap,
  TrafficLayer,
  TransitLayer,
  useJsApiLoader
} from '@react-google-maps/api';
import { mapConfig } from '@constants/component-configs';
import { googleMapsApiKey, libraries, version } from '@constants/google-maps';
import './dotmaps-google-map.scss';

const DotMapsGoogleMap = ({
  components,
  onBoundsChanged,
  onClick,
  onDblClick,
  onDragEnd,
  onDragStart,
  onIdle,
  onMapLoad,
  onMouseMove
}) => {
  const {
    bicycle,
    mapTypeId,
    traffic,
    transit,
    viewport: { center, zoom }
  } = useSelector(state => state.map);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey,
    libraries,
    version
  });

  if (isLoaded) {
    const containerStyle = { width: '100%', height: '100%' };
    return (
      <div styleName="map-wrapper">
        <div styleName="map-element">
          <GoogleMap
            center={center}
            mapContainerStyle={containerStyle}
            mapTypeId={mapTypeId}
            onBoundsChanged={onBoundsChanged}
            onClick={onClick}
            onDblClick={onDblClick}
            onDragEnd={onDragEnd}
            onDragStart={onDragStart}
            onIdle={onIdle}
            onLoad={onMapLoad}
            onMouseMove={onMouseMove}
            options={mapConfig.mapOptions}
            zoom={zoom}
          >
            {traffic && <TrafficLayer />}
            {bicycle && <BicyclingLayer />}
            {transit && <TransitLayer />}
            {components}
          </GoogleMap>
        </div>
      </div>
    );
  }
  return null;
};

DotMapsGoogleMap.propTypes = {
  components: PropTypes.node,
  onBoundsChanged: PropTypes.func,
  onClick: PropTypes.func,
  onDblClick: PropTypes.func,
  onDragEnd: PropTypes.func,
  onDragStart: PropTypes.func,
  onIdle: PropTypes.func,
  onMapLoad: PropTypes.func,
  onMouseMove: PropTypes.func
};

export default memo(DotMapsGoogleMap);
