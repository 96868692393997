import React from 'react';

const BaselineIcon = () => (
  <svg width="18px" height="18px" version="1.1" viewBox="0 0 18 18">
    <defs>
      <path id="baseline-path-1" d="M9 7.5H6.75V0h-1.5v7.5H3l3 3 3-3zM0 12v1.5h12V12H0z" />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(3 2.25)">
      <mask id="baseline-path-2" fill="#fff">
        <use xlinkHref="#baseline-path-1" />
      </mask>
      <g fill="#000" fillOpacity=".4" mask="url(#baseline-path-2)">
        <path d="M-3-2.25h18v18H-3z" />
      </g>
    </g>
  </svg>
);

export default BaselineIcon;
