import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { showConfirmationDialog } from '@actions/confirmation-actions';
import { openDashboardDialog } from '@actions/dashboard-actions';
import {
  fetchDataDetail,
  getConfirmableDeleteAction
} from '@actions/data-detail-actions';
import * as dialog from '@constants/dialogs';
import { CYCLES_TAB_COLUMNS as columns } from '@constants/table';
import DetailsTableHeader from '@shared/details-table-header';
import StartCycleDialog from '@shared/dialogs/start-cycle-dialog';
import Table from '@shared/table';
import { openInNewTab } from '@utils/navigation-utils';
import { canAccessWorkflow } from '@utils/permission-utils';

const Cycles = () => {
  const { dataType } = useParams();
  const { data } = useSelector(state => state.dataDetail);
  const dispatch = useDispatch();

  const onAdd = useCallback(
    () => {
      const params = { entityId: data.id, reloadEntity: true };
      dispatch(openDashboardDialog(dialog.START_CYCLE, params));
    },
    [data.id, dispatch]
  );

  const rows = data.cycles;

  // eslint-disable-next-line no-unused-vars
  const onRowClick = (event, row, index) => openInNewTab(`/cycle/${row.id}`);

  const deleteCycle = row => dispatch(showConfirmationDialog(
    'Delete workflow cycle?',
    `Are you sure you want to delete workflow cycle "${row.name}"?`,
    getConfirmableDeleteAction(
      'cycle',
      'cycle',
      row.id,
      () => dispatch(fetchDataDetail(dataType, data.id))
    )
  ));

  let actions = null;

  if (canAccessWorkflow('cycle', 'delete')) {
    actions = [
      { icon: DeleteIcon, label: 'Delete workflow cycle', onClick: deleteCycle }
    ];
  }

  const tableProps = {
    actions,
    columns,
    empty: { message: 'No cycles started yet' },
    header: <DetailsTableHeader count={rows.length} label="cycle" onAdd={onAdd} tooltip="Start a workflow cycle" />,
    onRowClick,
    rows,
    style: { maxWidth: '50rem' }
  };

  return (
    <Fragment>
      <Table {...tableProps} />
      <StartCycleDialog />
    </Fragment>
  );
};

export default Cycles;
