/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import FontIcon from 'material-ui/FontIcon';
import ArrowBackIcon from 'material-ui/svg-icons/navigation/arrow-back';
import { clearTrays, popTray } from '@actions/map-actions';
import * as colors from '@constants/colors';
import { getTraySecond } from '@selectors/map-selector';
import IconMenu from '@shared/icon-menu';
import IconMenuItem from '@shared/icon-menu-item';
import SourceLink from '@shared/source-link';
import './header.scss';

const Header = ({ title, icon, iconUrl, moreActions }) => {
  const dispatch = useDispatch();

  let trayBack = useSelector(getTraySecond);
  if (R.isEmpty(trayBack)) {
    trayBack = null;
  }

  const onClose = () => dispatch(clearTrays());
  const onPop = () => dispatch(popTray());

  return (
    <div styleName={`map-tray-header ${trayBack ? 'source' : ''}`}>
      <div styleName="header-info">
        {trayBack &&
          <div styleName="map-tray-header-back">
            <ArrowBackIcon
              color={colors.dotmapsBlack80}
              onClick={onPop}
              style={{width: '1em', height: '1em', flex: '0 0 auto'}}
            />
            <div onClick={onPop} role="presentation" styleName="map-tray-header-back-to">
              Back to <span>{trayBack.trayName}</span>
            </div>
          </div>
        }
        <div styleName="title-info">
          { icon && <div styleName="map-tray-header-icon">{icon}</div>}
          { iconUrl && <div styleName="map-tray-header-icon"><img src={iconUrl} alt=""/></div>}
          <div styleName="map-tray-header-title">{ title }</div>
        </div>
      </div>
      {moreActions && moreActions.length > 0 &&
        <div styleName="map-tray-header-button">
          <IconMenu>
            {moreActions.map(({ label, link, action }, index) => {
              if (link) {
                return (
                  <IconMenuItem key={index}>
                    <SourceLink to={link} style={{ textDecoration: 'none', color: colors.dotmapsBlack80 }}>
                      {label}
                    </SourceLink>
                  </IconMenuItem>
                );
              }
              return (
                <IconMenuItem key={index} onClick={action}>
                  {label}
                </IconMenuItem>
              );
            })}
          </IconMenu>
        </div>
      }
      <div styleName="map-tray-header-button">
        <FontIcon className="material-icons" color={colors.dotmapsBlack80} onClick={onClose}>
          close
        </FontIcon>
      </div>
    </div>
  );
};

Header.propTypes = {
  icon: PropTypes.node,
  iconUrl: PropTypes.string,
  moreActions: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default memo(Header);
