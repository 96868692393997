import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Control } from 'react-redux-form';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { clearDashboardDetailsDataError } from '@actions/dashboard-actions';
import { dashboardDetails } from '@constants/mui-theme';
import { sortBy } from '@utils/shared-utils';
import './details-select-field.scss';

class DetailsSelectField extends Component {
  constructor(props) {
    super(props);
    this.onChange = ({ model, onChange }) => {
      return function update(event, index, value) {
        const [dataType, ...path] = model.split('.');
        props.clearDashboardDetailsDataError(dataType, path);
        onChange(value);
      };
    };
  }

  render() {
    const { disabled, label, model, required, saveRequested, select } = this.props;
    let { types } = this.props;
    types = sortBy(types, select.key);
    const selectMapProps = {
      onChange: props => this.onChange(props),
      ...dashboardDetails.input,
      ...dashboardDetails.select,
      errorText: ({fieldValue: {errors}, ['data-save-requested']: dataSaveRequested}) => (
        dataSaveRequested ? R.values(errors).join(', ') : ''
      )
    };
    const ControlSelect = Control.select;
    return (
      <div styleName="details-select-field-container">
        <div styleName="label">
          {label}{required || disabled ? '' : ' (Optional)'}
        </div>
        <div styleName="input">
          {types &&
            <ControlSelect
              disabled={disabled}
              model={`.${model}`}
              mapProps={selectMapProps}
              data-save-requested={saveRequested}
              component={SelectField}
            >
              {types.map(type => (
                <MenuItem
                  key={type.id}
                  value={type.id}
                  primaryText={type[select.key]}
                />
              ))}
            </ControlSelect>}
        </div>
      </div>
    );
  }
}

DetailsSelectField.propTypes = {
  clearDashboardDetailsDataError: PropTypes.func,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  model: PropTypes.string,
  required: PropTypes.bool,
  saveRequested: PropTypes.bool,
  select: PropTypes.object,
  types: PropTypes.array
};

const mapStateToProps = (state, props) => {
  const { type } = props.select;
  const types = R.values(state.dataTypes[type]);
  return { types, ...{ saveRequested: state.dashboard.saveRequested }};
};

export default connect(mapStateToProps, {clearDashboardDetailsDataError})(DetailsSelectField);
