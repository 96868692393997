import { includes } from 'lodash';
import * as R from 'ramda';
import { createSelector } from 'reselect';
import { getFiltersMap } from '@constants/config';
import { getSortedUniqueDataType } from '@utils/data-types-utils';
import { processCategories } from '@utils/filter-utils';
import {
  createUrlFromFilters,
  getOverlapEntitiesIds
} from '@utils/map-utils';
import { sortBy } from '@utils/shared-utils';
import { getDataForTypeSelector } from '@selectors/data-types-selector';
import { getConfig, getDataTypeConfig } from '@components/config/selectors';

const getAgencyTypes = state => state.dataTypes.agency_type;
const getAllEntities = state => state.entities.types;
const getEntity = (state, props) => state.entities.types[props.type] || {};
const getEntities = (state, props) => getEntity(state, props).list || [];
export const getBounds = state => state.map.viewport.bounds;
const getOnlyShowOverlaps = state => state.map.onlyShowOverlaps;
const getOverlapEntities = state => state.dataDetail.overlapEntities;
const getEntityFilters = (state, props) => getEntity(state, props).filters || null;
const getEntitySuppressedFilters = (state, props) => getEntity(state, props).suppressedFilters || null;
const getEntityNext = (state, props) => getEntity(state, props).next || '';
export const getMapFilters = state => state.map.filters;
const getLoadViewport = (state, props) => state.entities.types[props.type].queryViewport;
const getLoadViewportState = (state, props) => state.entities.types[props.type].inViewport;
const getDataTypes = state => state.dataTypes;
const getEntityType = (state, props) => props.type;

export const getEnabledEntityTypes = createSelector(
  state => getConfig(state)?.entities,
  state => state.map?.filterToggles,
  (entities, filterToggles) => entities.filter(type => filterToggles[type])
);

export const getEnabledEntities = createSelector([
  getAllEntities,
  getEnabledEntityTypes
], (
  entities,
  enabledTypes
) => {
  const enabledEntities = {};
  Object.keys(entities).forEach(type => {
    if (includes(enabledTypes, type)) {
      enabledEntities[type] = entities[type].list;
    }
  });
  return enabledEntities;
});

export const boundsEntitiesSelector = createSelector([
  getEntities,
  getBounds,
  getOnlyShowOverlaps,
  getOverlapEntities,
  getEnabledEntityTypes,
  getEntityType
], (
  entities,
  bounds,
  onlyShowOverlaps,
  overlapEntities,
  enabledTypes,
  type
) => {
  if (bounds && includes(enabledTypes, type)) {
    const result = entities.filter(({ bounds: entityBounds }) => bounds.intersects(entityBounds));
    if (onlyShowOverlaps) {
      if (R.isEmpty(overlapEntities)) {
        return [];
      }
      const ids = getOverlapEntitiesIds(overlapEntities);
      return result.filter(({ id }) => includes(ids, id));
    }
    return result;
  }
  return [];
});

export const boundsAllEntitiesSelector = createSelector([
  getEnabledEntities,
  getBounds,
  getOnlyShowOverlaps,
  getOverlapEntities
], (
  entities,
  bounds,
  onlyShowOverlaps,
  overlapEntities
) => {
  if (bounds) {
    let flatEntities = [];
    Object.keys(entities).forEach(entityType => {
      flatEntities = [...flatEntities, ...entities[entityType]];
    });
    const result = flatEntities.filter(({ bounds: entityBounds }) => bounds.intersects(entityBounds));
    if (onlyShowOverlaps) {
      if (R.isEmpty(overlapEntities)) {
        return [];
      }
      const ids = getOverlapEntitiesIds(overlapEntities);
      return result.filter(({ id }) => includes(ids, id));
    }
    return result;
  }
  return [];
});

const getSortedUniqueCategory = (list, types, sortField) => {
  // Build a list of unique ids:
  const uniqueIds = new Set();

  list.forEach(entity => {
    if (entity.category_dict) {
      entity.category_dict.forEach(category => uniqueIds.add(category.id));
    }
  });

  // And return all data types for those unique ids:
  const existing = [...uniqueIds].map(id => types[id]);
  return sortBy(R.values(existing), sortField);
};

// Selector that returns all category type objects for the categories referenced in all
// bound entities.
export const categoriesSelector = createSelector([
  boundsEntitiesSelector,
  getDataForTypeSelector('map_category')
], (
  entities,
  categories
) => getSortedUniqueCategory(entities, categories, 'name')
);

// Selector that returns all AgencyTypes objects for the agencies referenced in all
// bound entities
export const agencyTypesSelector = createSelector([
  boundsAllEntitiesSelector,
  getAgencyTypes
], (
  entities,
  agencyTypes
) => getSortedUniqueDataType('agencyTypeId', entities, agencyTypes, 'name')
);

// Return the entity filters, removing the suppressed ones
// (i.e. ones disabled in the UI due to radio button selections).
export const entityFiltersSelector = createSelector(
  [getEntityFilters, getEntitySuppressedFilters],
  (entityFilters, suppressed) => {
    const newFilters = { ...entityFilters };
    if (suppressed) {
      Object.keys(suppressed).forEach(key => {
        const { name, filter } = suppressed[key];
        if (filter !== null) {
          delete newFilters[name];
        }
      });
    }
    return newFilters;
  }
);

export const entityFiltersUrlSelector = createSelector(
  [entityFiltersSelector, getMapFilters, getEntityNext, getLoadViewport, getLoadViewportState, getEntityType],
  (entityFilters, map, next, viewport, inViewport, type) => {
    if (next) {
      return next;
    }
    const newFilters = { ...entityFilters, ...map, type };
    const { shape_selection } = newFilters;
    if (viewport && !shape_selection) {
      if (inViewport) {
        newFilters.geom_in = viewport;
        delete newFilters.geom_not_in;
      } else {
        newFilters.geom_not_in = viewport;
        delete newFilters.geom_in;
      }
    }
    const filters = processCategories(type, { ...newFilters });
    return createUrlFromFilters(filters, 'map');
  }
);

export const entityFiltersDatatableSelector = createSelector(
  [entityFiltersSelector, getMapFilters, getLoadViewport, getLoadViewportState, getEntityType],
  (entityFilters, map, viewport, inViewport, type) => {
    const newFilters = { ...entityFilters, ...map, type };
    const { shape_selection } = newFilters;
    if (viewport && !shape_selection) {
      if (inViewport) {
        newFilters.geom_in = viewport;
        delete newFilters.geom_not_in;
      } else {
        newFilters.geom_not_in = viewport;
        delete newFilters.geom_in;
      }
    }
    // Datatables needs more fields, remove the 'compact' filter if it's present.
    delete newFilters.compact;
    return processCategories(type, { ...newFilters });
  }
);

// Used to determine if entity filters have changed from default
// for use in displaying a warning on the overlaps tab in entity trays.
export const getEntityFiltersChanged = createSelector(
  [entityFiltersSelector, getDataTypes, getEntityType],
  (entityFilters, dataTypes, type) => {
    const filter = getFiltersMap()[`${type}Filters`];
    let validAgencyTypes = false;
    let validCategories = false;
    if (filter) {
      if (filter.agency_type && filter.agency_type[0]) {
        const selectedAgencyTypes = entityFilters[filter.agency_type[0]];
        validAgencyTypes = (
          (!selectedAgencyTypes && typeof selectedAgencyTypes !== 'string') ||
          Object.keys(dataTypes.agency_type).map(
            id => String(selectedAgencyTypes).split(',')
              .indexOf(id) > -1
          )
            .indexOf(false) === -1
        );
      }
      if (filter.map_category && filter.map_category[0]) {
        const selectedCategories = entityFilters[filter.map_category[0]];
        validCategories = (
          (!selectedCategories && typeof selectedCategories !== 'string') ||
          Object.keys(dataTypes.map_category).map(
            id => String(selectedCategories).split(',')
              .indexOf(id) > -1
          )
            .indexOf(false) === -1
        );
      }
    }
    return !(validAgencyTypes && validCategories);
  }
);

export const getEntityNames = createSelector(
  [getConfig],
  ({entities}) => entities
);

export const getAllEntityTypeConfigs = createSelector(
  [state => state, getEntityNames],
  (state, entities) => {
    const configs = {};
    entities.forEach(entityTypeName => {
      configs[entityTypeName] = getDataTypeConfig(state, 'entity', entityTypeName);
    });
    return configs;
  }
);

export const getEntityChipConfig = createSelector(
  [(state, entityType) => getDataTypeConfig(state, 'entity', entityType)],
  ({config: entityConfig = {}}) => {
    const { frontend: { card: { chips: chipsConfig = null } = {} } = {}, fields = [] } = entityConfig;
    if (chipsConfig) {
      return chipsConfig.map(chipConfig => {
        if (!(chipConfig.type || chipConfig.field)) {
          return {
            type: 'field',
            field: chipConfig,
            autoHide: true,
            fieldConfig: fields[chipConfig] || {}
          };
        }
        return {
          ...chipConfig,
          fieldConfig: fields[chipConfig.field] || {}
        };
      });
    }
    return null;
  }
);

/*
Details section of entity list item.
This can be customized in the databases entityType config column using frontend.list.detail.
Currently can display text fields, categories, and agency name.
Sample configuration.
{
  ...,
  "frontend": {
    ...,
    "list": {
      ...,
      "detail": [
        "entityId",           // Strings are treated as field values with no label and autoHide true.
        "entityPriority",
        "entityDateRange",
        {
          "type": "field",    // one of field, category, agency.
          "field": "pmac_id", // if type is field or category, the backend name for the data.
          "label": "PMAC ID", // prefix the data value with this label or leave empty for no label.
          "autoHide": true    // if true (default true), removes the detail element from display if the value is empty or null.
        }
      ]
    }
  }
}
*/
export const getEntityListDetailConfig = createSelector(
  [(state, entityType) => getDataTypeConfig(state, 'entity', entityType)],
  ({config: entityConfig = {}}) => {
    const { frontend: { list: { detail: detailsConfig = null } = {} } = {}, fields = [] } = entityConfig;

    if (detailsConfig) {
      return detailsConfig.map(detailConfig => {
        if (!(detailConfig.type || detailConfig.field)) {
          return {
            type: 'field',
            field: detailConfig,
            autoHide: true,
            fieldConfig: fields[detailConfig] || {}
          };
        }
        return {
          ...detailConfig,
          fieldConfig: fields[detailConfig.field] || {}
        };
      });
    }
    return [
      {
        type: 'field',
        field: 'id',
        label: 'ID',
        autoHide: true
      },
      {
        type: 'agency',
        autoHide: true
      }
    ];
  }
);
