/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'material-ui/Avatar';
import {
  dotmapsBlack60,
  overlayTextColor
} from '@constants/colors';
import { moreListTooltipStyles } from '@constants/mui-theme';
import Tooltipable from '@shared/tooltipable';
import Tooltip from '@material-ui/core/Tooltip';
import { getAgencyIcon, renderCustomIconAndLabel } from '@utils/icon-utils';
import './conflict-agencies.scss';

class ConflictAgencies extends Tooltipable {
  getIcon = agencyName => {
    const { agencies, agencyTypes } = this.props;
    const agency = Object.values(agencies).find(agencyItem => agencyItem.name === agencyName);
    if (agency) {
      const agencyType = agencyTypes[agency.type];
      const icon = getAgencyIcon(agencyType);
      return (
        <Tooltip title={agencyName}>
          <img src={icon} alt="" styleName="item" />
        </Tooltip>
      );
    }
    return null;
  };

  renderList = list => (
    <div>
      {list.slice(0, 5).map((item, index) => this.renderItem(item, index))}
      {list.length > 5 && (
        <div style={moreListTooltipStyles.seeDetailsStyle}>
          See overlaps detail for complete list
        </div>
      )}
    </div>
  );

  renderItem = (item, index) => (
    <div key={index} styleName="tooltip-item">
      {renderCustomIconAndLabel(item, this.getIcon(item))}
    </div>
  );

  getAvatar = (list, value) => (
    <div
      onMouseOver={this.hoverOn}
      onMouseOut={this.hoverOff}
    >
      <Avatar
        backgroundColor={overlayTextColor}
        color={dotmapsBlack60}
        size={20}
        style={moreListTooltipStyles.avatarStyle}
      >
        {value}
      </Avatar>
      {this.renderTooltip(this.renderList(list))}
    </div>
  );

  render() {
    const { conflict } = this.props;
    const { agencies } = conflict;
    if (!agencies) {
      return null;
    }
    const list = agencies.split(',');
    return (
      <div styleName="column-renderer-conflict-agencies-container">
        {list.slice(0, 5).map(agencyName => this.getIcon(agencyName))}
        {list.length > 5 && this.getAvatar(list.slice(5), `+${list.length - 5}`)}
      </div>
    );
  }
}

ConflictAgencies.propTypes = {
  agencies: PropTypes.object,
  agencyTypes: PropTypes.object,
  conflict: PropTypes.object
};

ConflictAgencies.defaultProps = {
  tooltipStyle: { ...moreListTooltipStyles.tooltip }
};

export default ConflictAgencies;
