import { connect } from 'react-redux';
import { baseMapStateToProps } from '../dashboard-base-dropdown-filter';
import { setDatatableFilter } from '@actions/data-table-actions';
import { getFiltersDashboard } from '@constants/config';
import { BaseCheckboxFilter } from '@shared/top-filter-bar/base-checkbox-filter';

class CheckboxFilter extends BaseCheckboxFilter {
  setFilter(filter) {
    const { dataType, subType } = this.props;
    this.props.setFilter(dataType, subType, filter);
  }
}

const mapStateToProps = (state, props) => {
  const { dataType, subType } = props;
  const params = {
    dataType,
    subType,
    lazyName: () => {
      let filters = getFiltersDashboard()[dataType];
      const types = ['activity', 'group', 'overlap', 'task'];
      if (types.includes(dataType)) {
        filters = filters[subType];
      }
      return filters.find(filter => filter.name === props.name).params[0];
    }
  };
  return baseMapStateToProps(state, params);
};

export default connect(mapStateToProps, { setFilter: setDatatableFilter })(CheckboxFilter);
