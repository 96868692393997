// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-map-floating-bar-separator-___separator__separator{border-right:solid #ebebeb 1px;margin:2px 5.5px;width:1px;height:100%}
`, "",{"version":3,"sources":["webpack://./src/components/map/floating-bar/separator/separator.scss"],"names":[],"mappings":"AAAA,mEACE,8BAA+B,CAC/B,gBAAiB,CACjB,SAAU,CACV,WAAY","sourcesContent":[".separator {\n  border-right: solid #ebebeb 1px;\n  margin: 2px 5.5px;\n  width: 1px;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"separator": `src-components-map-floating-bar-separator-___separator__separator`
};
export default ___CSS_LOADER_EXPORT___;
