// Component to render categories as radio button options.
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton';
import { publicFormStyles } from '@constants/mui-theme';
import { getDataForType } from '@selectors/data-types-selector';
import './forms.scss';

const Radio = ({
  categoryTypes, dataName, disabled, label, name, onChange, value
}) => {
  let options = useSelector(state => getDataForType(state, dataName));

  // Filter by category type (since categories are all on the same database table).
  if (categoryTypes && name.startsWith('|')) {
    const categoryTypeName = name.substring(1);
    const categoryType = parseInt(Object.keys(categoryTypes).find(key => categoryTypes[key].name === categoryTypeName), 10);
    options = R.pickBy(option => option.type === categoryType, options);

    // For radio, skip the '(blank)/TBD' value.
    options = R.pickBy(option => option.id > 0, options);
  }

  return (
    <div styleName="radio-container">
      <span>{label}</span>
      <RadioButtonGroup
        disabled={disabled}
        name={name}
        floatingLabelText={label}
        onChange={onChange}
        valueSelected={value}
        {...publicFormStyles().radioGroup}
      >
        {Object.values(options).map(option => (
          <RadioButton
            disabled={disabled}
            key={option.id}
            label={option.name}
            value={option.id}
            {...publicFormStyles(disabled).radio}
          />
        ))}
      </RadioButtonGroup>
    </div>
  );
};

Radio.propTypes = {
  categoryTypes: PropTypes.object,
  dataName: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any  // eslint-disable-line react/forbid-prop-types
};

export default memo(Radio);
