// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-shared-dialogs-___dialog-close-button__dialog-close-button-container{cursor:pointer;padding:1rem;position:absolute;top:0;right:0}
`, "",{"version":3,"sources":["webpack://./src/components/shared/dialogs/dialog-close-button.scss"],"names":[],"mappings":"AAGA,qFACE,cAAe,CACf,YAAa,CACb,iBAAkB,CAClB,KAAM,CACN,OAAQ","sourcesContent":["@import '../../../styles/vars';\n@import '../../../styles/mixins';\n\n.dialog-close-button-container {\n  cursor: pointer;\n  padding: 1rem;\n  position: absolute;\n  top: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog-close-button-container": `src-components-shared-dialogs-___dialog-close-button__dialog-close-button-container`,
	"dialogCloseButtonContainer": `src-components-shared-dialogs-___dialog-close-button__dialog-close-button-container`
};
export default ___CSS_LOADER_EXPORT___;
