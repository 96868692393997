import React from 'react';
import PropTypes from 'prop-types';
import * as colors from '@constants/colors';
import ActionIconButton from '@shared/action-icon-button';
import FlexSeparator from '@shared/helpers/flex-separator';
import LinkButton from '@shared/link-button';
import './top-filter-bar.scss';

const TopFilterBar = ({
  children,
  filterTopBarOpen,
  resetFilters,
  toggleFilterTopBar
}) => {
  if (!filterTopBarOpen) {
    return null;
  }
  return (
    <div styleName="top-filter-bar-container">
      <div name="title">Filters</div>
      {children}
      <FlexSeparator />
      <LinkButton hoverColor={colors.dotmapsGrayTopBar} label="RESET" onClick={resetFilters} />
      <div name="last" />
      <ActionIconButton avatarize click={toggleFilterTopBar} color="#000000" icon="filter_list" />
    </div>
  );
};

TopFilterBar.propTypes = {
  // Children contains the filter components
  // used by the classes that reuse this component.
  children: PropTypes.node,
  // Flag to tell whether to render this component or not.
  filterTopBarOpen: PropTypes.bool,
  // Function to call to reset the filters.
  resetFilters: PropTypes.func,
  // Function to call to show/hide this filter bar.
  toggleFilterTopBar: PropTypes.func
};

export default TopFilterBar;
