import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
import { OverlayView, Polygon, Polyline } from '@react-google-maps/api';
import withShouldHighlight from '@components/map/with-should-highlight';
import { mapConfig } from '@constants/component-configs';
import {
  getMapBoundariesVisible,
  getMapLabelsVisible
} from '@selectors/groups-selector';
import { getHoverGroupId, getTrayGroupIds } from '@selectors/map-selector';
import { generatePath, generatePolygonPaths } from '@utils/map-utils';
import './group-figure.scss';

const icons = [{
  icon: {
    path: 'M 0,-2 0,2',
    strokeOpacity: 1,
    strokeColor: '#FF0000',
    scale: 2
  },
  offset: '0',
  repeat: '20px'
}];

const highlightIcons = [{
  icon: {
    path: 'M 0,-1 0,1',
    strokeOpacity: 1,
    strokeColor: '#FF0000',
    scale: 4
  },
  offset: '0',
  repeat: '20px'
}];

const groupOptions = {
  fillColor: '#FF0000', strokeColor: '#FF0000', strokeOpacity: 0.0, icons, fillOpacity: 0.1
};

const groupHighlightOptions = {
  ...groupOptions, icons: highlightIcons, fillOpacity: 0.3, zIndex: 200
};

class MapGroup extends Component {
  constructor() {
    super();
    this.getRings = memoize(coordinates => coordinates.map(generatePath));
    this.getPaths = memoize(coordinates => generatePolygonPaths(coordinates));
    this.getOptions = memoize(other => ({...mapConfig.defaultStyle, ...other}));
  }

  render() {
    const { autoArea, boundariesVisible, center, shouldHighlight, labelsVisible, name} = this.props;
    const rings = this.getRings(autoArea.shape.coordinates);
    const paths = this.getPaths(autoArea.shape.coordinates);
    const options = shouldHighlight ? this.getOptions(groupHighlightOptions) : this.getOptions(groupOptions);
    const labelClass = `group-label ${shouldHighlight ? 'active' : ''}`;
    return (
      <div>
        {boundariesVisible && (
          <div>
            <div>
              {rings.map((path, index) => <Polyline key={`polyline-${index}`} path={path} options={options}/>)}
            </div>
            <Polygon paths={paths} options={options}/>
          </div>
        )}
        {labelsVisible && (
          <OverlayView
            mapPaneName={OverlayView.FLOAT_PANE}
            position={{ lng: center[0], lat: center[1] }}
            zIndex={200}
          >
            <div styleName={labelClass}>
              {name}
            </div>
          </OverlayView>
        )}
      </div>
    );
  }
}

MapGroup.propTypes = {
  autoArea: PropTypes.shape({
    shape: PropTypes.shape({
      coordinates: PropTypes.array,
      type: PropTypes.string
    })
  }),
  boundariesVisible: PropTypes.bool,
  center: PropTypes.array,
  id: PropTypes.number,
  labelsVisible: PropTypes.bool,
  name: PropTypes.string,
  shouldHighlight: PropTypes.bool
};

const mapStateToProps = state => ({
  boundariesVisible: getMapBoundariesVisible(state),
  labelsVisible: getMapLabelsVisible(state)
});

export default withShouldHighlight(
  connect(mapStateToProps, {})(MapGroup),
  getTrayGroupIds,
  getHoverGroupId
);
