import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePolygon,
  updateSegmentEndpoints
} from '@actions/data-detail-actions';
import FloatingBar from '@components/map/floating-bar';
import { convertFromGoogleMapsShape } from '@utils/map-tools-utils';
import EditMap from './edit-map';
import './map.scss';

const Map = ({ formState }) => {
  const { data } = useSelector(state => state.dataDetail);
  const dispatch = useDispatch();

  const {
    activeSegmentId,
    activeType,
    activeStart, setActiveStart,
    activeEnd, setActiveEnd,
    geometryLocked
  } = formState;

  const activeSegment = data && data.segments && data.segments.find(seg => seg.id === activeSegmentId);

  const onActiveSegmentChanged = useCallback(
    endPoints => {
      const newEndPoints = {
        ...{ start: activeStart, end: activeEnd },
        ...endPoints
      };
      setActiveStart(newEndPoints.start);
      setActiveEnd(newEndPoints.end);
      dispatch(updateSegmentEndpoints(newEndPoints, activeSegmentId, activeSegment.override_street_centering, 'map'));
    },
    [activeEnd, activeStart, activeSegment, activeSegmentId, dispatch, setActiveEnd, setActiveStart]
  );

  const onActivePolygonChanged = useCallback(
    mapsShape => {
      setActiveStart(null);
      setActiveEnd(null);
      const shape = convertFromGoogleMapsShape(mapsShape);
      dispatch(updatePolygon(activeSegmentId, shape, 'map'));
    },
    [activeSegmentId, dispatch, setActiveEnd, setActiveStart]
  );

  const segments = data && data.segments ? data.segments : null;

  return (
    <div styleName="map-container">
      <FloatingBar legend={false} tools={false} />
      <EditMap
        activeEnd={activeEnd}
        activeSegmentId={activeSegmentId}
        activeStart={activeStart}
        activeType={activeType}
        segments={segments}
        geometryLocked={geometryLocked}
        onActiveSegmentChanged={onActiveSegmentChanged}
        onPolygonChanged={onActivePolygonChanged}
      />
    </div>
  );
};

Map.propTypes = {
  formState: PropTypes.object
};

export default Map;
